import React, { useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonNewItem, OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";
import { OmisGrid } from "../../shared/OmisGrids";
import { OmisDialog } from "../../shared/OmisDisplays";
import { CreateAddress } from "../Miscellaneous/CreateAddress";
import AuthService from "../../../services/auth.service";
import { OmisCheckBox } from "../../shared/OmisInputs";

export function CreateContactPerson(props) {

    var addressurl = "/api/settings/settingsobjects/getpossibleaddresses";

    const navigate = useNavigate();
    const formRef = useRef();
    
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);
    
    const [initialValues, setInitialValues] = useState({
        id:  props.id ?? null,
        companySiteID: props.companySiteID ?? null,
        addressIDs: props.addressIDs ?? [props.rowVals?.addressID] ?? [],
        customerEditable: props.rowVals?.customerEditable === false ? false : true,
        uiHidden: props.UiHidden ?? false
    });

    function handleSave(values) {
        httpclientService.post("/api/settings/companysite/savecontactperson", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(0);
                }
            }
        });
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(0);
        }
    }
    function handleNewAddressClick() {
        setModalContent(<CreateAddress onClose={handleModalClose} handleEditCancel={handleModalCancel} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = (response) => {
        handleAddressSelect(response)
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    function handleAddressSelect(rowIDs) {
        formRef.current.setFieldValue("addressIDs", rowIDs ?? null);
    }

    const [schema, setSchema] = useState({
        addressIDs: Yup.array()
            .required(t("GenericRequiredText"))
            .min(1, t('GenericRequiredText')),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("Action_New")}</h1>
                                :
                                null
                        }

                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={12}>
                                    <OmisGrid
                                        headerText={t("Address")} noExportButton
                                        newButton={<OmisButtonNewItem id={"newItemButton"} onClick={handleNewAddressClick} text={t("Action_New")} />}
                                        apiUrl={addressurl}
                                        showCheckBox
                                        rowSelectionCallback={handleAddressSelect}
                                        change={changeCounter}
                                        checkedRows={formik.values.addressIDs}
                                    />
                                </OmisCol>
                                {
                                    AuthService.isFSMMaster() ?
                                        <OmisCol xs={6}><br />
                                            <OmisCheckBox labeltext={t("CustomerEditable")} name="customerEditable" />
                                        </OmisCol>
                                        :
                                        null
                                }
                                <OmisCol xs={6}>
                                    <br />
                                    <OmisCheckBox labeltext={t("ContactPersonUIVisible")} name="uiHidden" />
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"xl"}
                content={modalContent}
                contentHeight={700}
            />
        </>
    );
}