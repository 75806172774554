import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import AuthorizeRoute from './components/profile/AuthorizeRoute';
import Layout from './components/Layout';
import './custom.scss';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/urbanist';
import {ThemeProvider} from "@mui/material";
import {omisTheme} from "./omisTheme";
import {LicenseInfo} from "@mui/x-data-grid-pro";
import Home from './components/Home';
import AuthService from './services/auth.service';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

export default function App() {

    const location = useLocation();

    useEffect(() => {
        if (location.path !== "/robots.txt") {
            AuthService.getSessionUser();
            AuthService.getUrlCheck();
        }
    }, [location]);

    return (
        <ThemeProvider theme={omisTheme}>
            <Layout>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, allowAnonymous, ...rest } = route;
                        return <Route key={index} {...rest} element={!allowAnonymous ? <AuthorizeRoute {...rest} element={element} /> : element} />;
                    })}
                    <Route key={"defaultRoute"} path='*' exact={true} element={<Home />} />
                </Routes>
            </Layout>
        </ThemeProvider>
    );
}
