import { Avatar } from "@mui/material";
import {createTheme} from "@mui/material/styles";
import zIndex from "@mui/material/styles/zIndex";
import { positions } from "@mui/system";
import {deDE, enUS, plPL, svSE, itIT, frFR, daDK, nbNO, esES} from "@mui/x-data-grid-pro";
import { renderTimeViewClock } from '@mui/x-date-pickers-pro';

const PRIMARY_ORANGE = '#F39200';
const SECONDARY_BLUE = '#517DDA';

const COLOR_BLACK = '#000000';
const COLOR_WHITE = '#FFFFFF';

const COLOR_SUCCESS = '#16C79A';
const COLOR_ERROR = '#F05454';
const COLOR_WARNING = '#FFC453';
const COLOR_INFO = '#2773FF';

const COLOR_TEXT_LIGHT = '#F4F8FF';

const langObj = {
    'de-AT': deDE,
    'en-US': enUS,
    "en-GB": enUS,
    "pl-PL": plPL,
    "sv-SV": svSE,
    "it-CH": itIT,
    "fr-CH": frFR,
    "de-CH": deDE,
    "da-DK": daDK,
    "nb-NO": nbNO,
    "es-ES": esES
}

const omisTheme = createTheme({
    palette: {
        primary: {
            main: PRIMARY_ORANGE,
            contrastText: COLOR_TEXT_LIGHT
        },
        secondary: {
            main: COLOR_WHITE
        },
        success: {
            main: COLOR_SUCCESS
        },
        error: {
            main: COLOR_ERROR
        },
        warning: {
            main: COLOR_WARNING
        },
        info: {
            main: COLOR_INFO
        }
    },
    typography: {
        fontFamily: ['Urbanist']
    },
	components: {
		MuiInputLabel: {
			styleOverrides: {
				asterisk: {
					color: '#F05454 !important'
				},
				root: {
					color: '#48515B !important'
				}
			}
		},
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#FFFFFF !important',

					borderRadius: '16px',
					border: 'none !important',
					maxHeight: '100% !important',
					height: 'auto !important',
					boxShadow: 'none !important',
					"&.MuiPaper-outlined": {
						"&.MuiPaper-rounded": {
							"&.MuiCard-root": {
								paddingBottom: '3em !important',
								paddingTop: '2em !important',
								minHeight: '2.973em !important'
							},
						},
					},
                }
            }
		},
        MuiCard: {
            styleOverrides: {
                root: {
					backgroundColor: '#FFFFFF !important',
					padding: '1.5em',
					gap: '1em',
					borderRadius: '24px',
					border: 'none !important'
                }
            }
        },
        MuiAvatar:{
            styleOverrides:{
                circular:{
                    width:'1.7em',
                    height:'1.5em',
					marginLeft: '0.1em',
					marginRight: '0.1em',                    
                }
            }
		},
		MuiDataGrid: {
			styleOverrides: {
				root: {
					borderRadius: '16px !important',
					border: '16px !important'
                }
            }
        },
		MuiGrid:{
            styleOverrides:{
                container:{
                    marginTop: '0em !important',                    
				},
				root: {
					"&.MuiGrid-item": {
						paddingTop: "0px !important"
                    }
                }
            }
        },
		MuiSelect:{
            styleOverrides:{
                root:{
                    fontFamily: "'Urbanist' !important",
					fontStyle: 'normal !important',
					fontWeight: '400 !important',
					fontSize: '14px !important',
					lineHeight: '24px !important',
					letterSpacing: '0.0025em !important',
					color: '#333C47 !important',
					textAlign: 'center !important',
					"&.MuiOutlinedInput-notchedOutline": {
						color: '#FE0000 !important'
					}      
                }
            }
        },
		MuiPopover:{
            styleOverrides:{
                paper:{
                    fontFamily: "'Urbanist' !important",
					fontStyle: 'normal !important',
					fontWeight: '400 !important',
					fontSize: '14px !important',
					lineHeight: '2em !important',
					letterSpacing: '0.01em !important',
					color: '#333C47 !important',
					minWidth: '24px !important',
					minHeight: '6.2em !important',
					paddingTop: '0.5em !important',
					borderRadius: '16px !important',
					maxHeight: '50vh !important'     
                }
            }
		},
		MuiFormGroup: {
			styleOverrides: {
				root: {
					boxSizing: 'border-box !important',
					
					padding: '10px 10px 10px 20px !important',
					gap: '4px !important',

					background: '#FFFFFF !important',
					border: '1px solid #F0F2F5 !important',
					boxShadow: '0px 1px 3px rgba(103, 122, 142, 0.24) !important',
					borderRadius: '16px !important',

					marginBottom: '1em !important',
				},
				MuiFormControlLabel: {
					root: {
						border: 'none!important',
					},
				}

			}
		},
		MuiInput:{
            styleOverrides:{
                root:{
				
					fontFamily: "'Urbanist' !important",
					fontStyle: 'normal !important',
					fontWeight: '400 !important',
					fontSize: '14px !important',

					letterSpacing: '0.0025em !important',
					minWidth: '100% !important',

					color: '#171E27 !important',

					"&.MuiInput-underline:before":{
						border: 'none !important',
					},
				
					"&.MuiInput-underline:hover":{
						border: 'none !important',
					},
				
					"&.MuiInput-underline:focus":{
						border: 'none !important',
					},
				
					"&.MuiInput-underline:active":{
						border: 'none !important',
					}                   
                }
				
            }
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					width: '-webkit-fill-available !important',
					height: '-webkit-fill-available !important',
				}

			}
		},
		MuiNativeSelect: {
			styleOverrides:{
				select: {
					boxSizing: 'border-box !important',
					background: '#FFFFFF !important',
					border: '1px solid #F0F2F5 !important',
					boxShadow: '0px 1px 3px rgba(103, 122, 142, 0.24) !important',
					borderRadius: '16px !important',
					height: '3em !important',
					
					marginRight: '0.5em !important',
					padding: '0px 8px !important',
					gap: '4px !important',

					option:{
						padding: '12px 8px !important',
						gap: '12px !important',
						background: '#FFFFFF !important',
						boxShadow: '0px 4px 12px rgba(103, 122, 142, 0.24) !important',
						borderRadius: '12px !important',
						fontFamily: 'Urbanist !important',
						fontStyle: 'normal !important',
						fontWeight: '400 !important',
						fontSize: '14px !important',
						lineHeight: '24px !important',
						alignItems: 'center !important',
						letterSpacing: '0.0025em !important',
						color: '#2D343D !important',
					}
				}
				
			}
		},
		MuiFormControl: {
			styleOverrides:{
				root:{
					//backgroundColor: 'green !important',
					"&.MuiInputBase-input-MuiInput-input:not([multiple]) option":{
						backgroundColor: 'green !important',
					} 
					/*.css-1ssmeqm-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input:not([multiple]) option, .css-1ssmeqm-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input:not([multiple]) optgroup {
						background-color: #db0c0c;
					}*/
				},
				select:{
					//backgroundColor: 'green !important',
					"&option":{
						backgroundColor: 'green !important',
					} 
				},
				
			}
		},
		MuiAutocomplete: {
			styleOverrides: {
				root: {

					"&.MuiOutlinedInput-notchedOutline": {
						border: 'none !important',
					},

					"&.MuiInput-underline:before":{
						border: 'none !important',
					},
				
					"&.MuiInput-underline:hover":{
						border: 'none !important',
					},
				
					"&.MuiInput-underline:focus":{
						border: 'none !important',
					},
				
					"&.MuiInput-underline:active":{
						border: 'none !important',
					},
					".MuiOutlinedInput-notchedOutline":{
						border: '0 !important',
					},
					".MuiOutlinedInput-root":{
						minHeight: '2em !important',
						maxHeight: '2em !important',
						height: '2em !important',
					},
					".MuiFilledInput-root": {
						backgroundColor: '#FFFF  !important',
					},
				},
				tag: {
					boxSizing: 'border-box !important',
					justifyContent: 'center !important',
					alignItems: 'center !important',
					padding: '0px 8px !important',
					gap: '4px !important',

					backgroundColor: '#FAFBFE  !important',

					border: '1px solid #F0F2F5 !important',
					borderRradius: '8px !important',
					
				}
			}
		},
		MuiTextField: {
			styleOverrides:{
                root:{
					boxSizing: 'border-box !important',
					display: 'flex !important',
					flexDirection: 'column !important',
					justifyContent: 'center !important',
					alignItems: 'flex-start !important',
					padding: '10px 10px 10px 12px !important',
					gap: '4px !important',

					background: '#FFFFFF',
					border: '1px solid #F0F2F5 !important',
					boxShadow: '0px 1px 3px rgba(103, 122, 142, 0.24) !important',
					borderRadius: '16px !important',

					//minHeight: '3.5em !important',
			
					maxWidth: '100vw !important',
					marginBottom: '1em !important',  

                }
            }
		},
		MuiTabs: {
			styleOverrides:{
				indicator: {
					display: 'none !important',
					height: '32px !important',
					marginTop: '10px !important',
				},
				root: {
					background: '#F0F2F5 !important',
					borderRadius: '12px !important',
					marginLeft: '2em !important',
					marginRight: '2em !important',
					marginTop: '0.5em !important',
					width: '100% !important',
				}
			}
		},
		MuiDesktopTimePicker: {
			defaultProps: {
				viewRenderers: {
					hours: renderTimeViewClock,
					minutes: renderTimeViewClock,
					seconds: renderTimeViewClock,
				},
			},
		},
		MuiDesktopDateTimePicker: {
			defaultProps: {
			  	viewRenderers: {
					hours: renderTimeViewClock,
					minutes: renderTimeViewClock,
					seconds: renderTimeViewClock,
			  	},
			},
		},
		MuiTab:{
            styleOverrides:{
				root: {

					background: '#F0F2F5 !important',
					borderRadius: '8px !important',

					fontFamily: 'Urbanist! important',
					fontStyle: 'normal !important',
					fontWeight: '500 !important',
					fontSize: '14px !important',
					lineHeight: '24px !important',
					textAlign: 'center !important',
					letterSpacing: '0.0025em  !important',
					color: '#FF7043 !important',


					marginLeft: '3px !important',
					marginTop: '3px !important',
					minHeight: '32px !important',
					

					"&.Mui-selected": {

						background: '#FFFFFF !important',
						boxShadow: '0px 1px 2px rgba(97, 97, 97, 0.2) !important',
						
					},
					"&:hover": {
	
						background: '#FFFFFF !important',
						boxShadow: '0px 1px 2px rgba(97, 97, 97, 0.2) !important',
					},
                },

				MuiButtonBase: {
					backgroundColor: 'red !important',
					height: '20px !important',
				},
				
            }
		},
		MuiChip: {
			styleOverrides: {
				root: {
					"& .MuiChip-avatar": {
						overflow: "visible",
					}
                }

			} 
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					width: '100px !important',
				}

			}
		},
		MuiTablePagination: {
			styleOverrides: {
				root: {
					minWidth: '375px !important',
					marginRight: '20px  !important',
				}

			}
		},
		"& .MuiSelect-select" : {
			styleOverrides: {
				root: {
					"& .MuiInputBase-input": {
						marginTop: '20px  !important',
						minWidth: '40px  !important',
					}
				}

			}
		},
		"& .MuiTablePagination-select": {
			styleOverrides: {
				root: {
					minWidth: '60px  !important',
				}

			}
		},
		"& .svg.MuiSvgIcon": {
			styleOverrides: {
				root: {
					"& .MuiTablePagination-selectIcon": {
						styleOverrides: {
							root: {
								"& .MuiSelect-iconStandard": {
									styleOverrides: {
										root: {
											"& .MuiSelect-icon": {
												styleOverrides: {
													root: {
														left: '20px  !important',
													}

												}
											}
										}

									}
								}
							}

						}
					}
				}

			}
		},

    }
}, langObj[localStorage.getItem("i18nLng")])

export {
    omisTheme
}