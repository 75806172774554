import React from "react";
import { t } from "i18next";
import { OmisSliderNoFormWithIOSStyle } from "../shared/OmisInputs";
import { OmisSecButtonWithStartIcon } from "../shared/OmisButtons";
import { mdiPlusBoxOutline } from "@mdi/js";
import InspectionsCheckList from './InspectionsCheckList'
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";

function generateUniqueRandom(haveIt) {
    let random = (Math.random() * 255).toFixed();
    random = -1 * Number(random);

    if (!haveIt.includes(random)) {
        return random;
    } else {
        return generateUniqueRandom(haveIt);
    }
}

export default function DynamicStepBuilder(props)  {
    const defaultSteps = [
        {
            itemID: generateUniqueRandom(props.steps.map(x => x.itemID)),
            definitionID: null,
            name: "",
            okText: "",
            notOkText: "",
            description: "",
            valueVisible: false,
            valueRequired: false,
            noteVisible: false,
            noteRequired: false,
            okVisible: false,
            notOkVisible: false,
            okNotOkRequired: false,
            showCreateReportButton: false,
        }
    ];

    function addNewStep() {        
        if (props.handleCheckListItemsChange) {
            props.handleCheckListItemsChange([...props.steps, ...defaultSteps]);
        }
    };

    const handleDeleteStepCallback = (stepKey) => {
        if (stepKey && props.steps.length > 0) {
            var newSteps = props.steps.filter((f) => f.itemID !== stepKey);
            if (props.handleCheckListItemsChange) {
                props.handleCheckListItemsChange(newSteps);
            }
        }
    };

    const stepValueChanged = (event) => {
        var steps = props.steps;
        var currentStep = steps.filter(f => f.itemID === parseInt(event.target.id))[0];
        var columnName = event.target.name;
        var newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        currentStep[columnName] = newValue;

        if (props.handleCheckListItemsChange) {
            props.handleCheckListItemsChange(steps);
        }
    };

    return (
        <>
            <OmisRow style={{ display: 'flex', justifyContent: 'left' }}>
                <OmisCol xs={2}>

                    <OmisSliderNoFormWithIOSStyle
                        labeltext={t("Action_Checklist")}
                        name={t("Action_Checklist")}
                        defaultChecked={props.checklistCompatible}
                        disabled
                    />
                </OmisCol>
            </OmisRow>
            <OmisContainer fluid>
                {
                    props.checklistCompatible ? 
                        <>
                            <OmisContainer fluid>
                                <RenderSteps steps={props.steps} stepValueChanged={stepValueChanged} handleDeleteStepCallback={handleDeleteStepCallback} />

                                <OmisRow className="justify-content-md-center">
                                    <OmisCol xs={2}>
                                        <OmisSecButtonWithStartIcon id={"addStepButton"} iconpath={mdiPlusBoxOutline} text={t("Action_Add")} onClick={() => addNewStep()}></OmisSecButtonWithStartIcon>
                                    </OmisCol>
                                </OmisRow>
                            </OmisContainer>
                        </>
                        :
                        null
                }
            </OmisContainer>
        </>
    );
}

function RenderSteps(props) {
    return (
        props.steps !== null ? props.steps.map((step, index) => (
            <div key={index.toString() + "-cont"} >
                <OmisRow key={index.toString() + "-cont"} sx={{ paddingBottom: 2 }}>
                    <InspectionsCheckList
                        {...props}
                        stepObject={step}
                        stepKey={index} />
                </OmisRow>
                <br />
            </div>
        ))
            :
            null
    );
}