import React, { useEffect, useState } from "react";
import { t } from "i18next";
import httpclientService from "../../services/httpclient.service";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { mdiPencil, mdiTrashCanOutline, mdiDotsHorizontal, mdiPlusBoxOutline } from '@mdi/js';
import { OmisButtonNewItem, OmisDropDownMenu} from '../shared/OmisButtons';
import { OmisSearchBar } from "../shared/OmisInputs";
import urlEncryptService from "../../services/urlencrypt.service";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../shared/OmisLayouts";

export function Tab_Groups(props) {

    const [groups, setGroups] = useState([]);
    const [filteredValues, setFilteredValues] = useState([]);

    useEffect(() => {
        loadList();
    }, []);

    useEffect(() => {
        setFilteredValues(groups);
    }, [groups]);

    function loadList() {
        httpclientService.get(`/api/profile/getgroups`).then((response) => {
            if (response) {
                setGroups(response);
            }
        });
    }

    function PrepareRowLink(item) {
        var encrptedProps = urlEncryptService.encrypt(item);
        return (
            <Link color="inherit" className="darkColor" component={RouterLink} to={{ pathname: "/users/groupdetails", search: `${encrptedProps}` }} state={item} underline="none" >
                <strong> {item.description} </strong>
            </Link>
        );
    }

    function deleteClick(event) {
        httpclientService.post(`api/profile/deletegroup?groupID=${event.target.id}`).then((response) => {
            if (response) {
                loadList();
            }
        });
    }

    const childItemsForDotsMenu = [
        {
            subMenuIcon: mdiPencil,
            toLink: `/users/groupdetails`,
            menuName: t("Action_Edit"),
        },
        {
            subMenuIcon: mdiTrashCanOutline,
            onClick: deleteClick.bind(this),
            menuName: t("Action_Delete"),
        },
    ]

    return (
        <>
            
                <OmisContainer fluid style={{ marginTop: '5em' }} >
                    <OmisRow className="usersGroupSearchCard">
                        <OmisStack direction="horizontal" gap={2}>
                            <OmisCol style={{ justifyContent: 'left' }} xs={10}>
                                <OmisSearchBar getSearchResultItems={setFilteredValues} listItems={groups} listAttr={["id", "description"]} />
                            </OmisCol>
                            <OmisCol xs={2} style={{ display: 'flex', justifyContent: 'right' }} style={{ marginTop: '0.5em' }}>
                                <OmisButtonNewItem id={"newItemButton"} key={`addButton`} {...props} iconpath={mdiPlusBoxOutline} component={RouterLink} to={"/users/addnewgroup"} text={t("Action_New")} size={"small"} />
                            </OmisCol>
                        </OmisStack>
                    </OmisRow>
                    
                <OmisStack gap={3} style={{ marginTop: '1em', marginBottom: '2em' }}>
                        {
                            filteredValues && filteredValues.length > 0 ?
                            filteredValues.map((item, index) =>
                            <OmisRow key={`${index}_Group`} className="usersGroupSearchCard">
                                <OmisCol xs={12}>
                                    <OmisStack direction="horizontal" gap={1}>
                                            <OmisCol style={{ display: 'flex', justifyContent: 'left' }} xs={10}>
                                                <PrepareRowLink {...item} />
                                        </OmisCol>
                                            <OmisCol style={{ display: 'flex', justifyContent: 'right' }} xs={2}>
                                            <OmisDropDownMenu rowid={item.id} menuicon={mdiDotsHorizontal} state={item} childitems={childItemsForDotsMenu} />
                                        </OmisCol>
                                    </OmisStack>
                                </OmisCol>
                            </OmisRow>
                            )
                            :
                            null
                        }
                    </OmisStack>
                </OmisContainer>


            
        </>
    );
}