import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { OmisButtonSecondary, OmisIconButton } from "../../shared/OmisButtons";
import { Card } from "@mui/material";
import { ArrowBack as ArrowBackIcon, Check as CheckIcon, Clear as ClearIcon } from "@mui/icons-material";
import { OmisCol, OmisContainer, OmisRow, OmisTabs } from "../../shared/OmisLayouts";
import { OmisDialog, OmisLabel } from "../../shared/OmisDisplays";
import HttpClientService from "../../../services/httpclient.service";
import { CreateCompanySite } from "./CreateCompanySite";
import { AddressDetailsInfo } from "../../objects/Object_TabContent_Details";
import { SettingsCompanySiteSkills } from "./SettingsCompanySiteSkills";
import { SettingsObjectContractors } from "../Objects/SettingsObjectCompanySites";
import { SettingsDispatchParameter } from "./SettingsDispatchParameter";
import { SettingsDispatchParameterVariety } from "./SettingsDispatchParameterVariety";
import { SettingsCompanySiteContactPersons } from "./SettingsCompanySiteContactPersons";

export function CompanySiteDetails(props) { 

    var { state } = useLocation();
    const navigate = useNavigate();

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [selectedTabValue, setSelectedTabValue] = useState(0);
    const [details, setDetails] = useState(null);

    const tabs = [
        {
            title: t("Skills"),
            content: <SettingsCompanySiteSkills gridView companySiteID={state?.id} selectedtabvalue={selectedTabValue} />
        },
        {
            title: t("Settings_ObjectContractor"),
            content: <SettingsObjectContractors companySiteID={state?.id} selectedtabvalue={selectedTabValue} hideBack />
        },
        {
            title: t("Settings_ContactPersons"),
            content: <SettingsCompanySiteContactPersons companySiteID={state?.id} selectedtabvalue={selectedTabValue} hideBack />
        },
        {
            title: t("Settings_DispatchParameter"),
            content: <SettingsDispatchParameter companySiteID={state?.id} selectedtabvalue={selectedTabValue} companySiteEmail={details?.address?.email} hideBack />
        },
        {
            title: t("Settings_DispatchParameterVariety"),
            content: <SettingsDispatchParameterVariety companySiteID={state?.id} selectedtabvalue={selectedTabValue} hideBack />
        },
    ];

    useEffect(() => {
        if (state && state.selectedtabvalue) {
            if (/[0-9]/.test(state.selectedtabvalue)) {
                setSelectedTabValue(parseInt(state.selectedtabvalue));
            } else {
                setSelectedTabValue(0);
            }
        }

        if (state?.id) {
            HttpClientService.get(`/api/settings/companysite/getcompanysitedetails?companySiteID=${state?.id}`).then((response) => {
                if (response) {
                    setDetails(response);
                }
            });
        }

    }, []);

    function tabChanged(e) {
        setSelectedTabValue(e);
    }

    function handleEditClick(rowID, e) {
        setModalTitle(t("Action_Edit"));
        setModalContent(<CreateCompanySite onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID} rowVals={e} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = (newEquiClassVals) => {
        setModalOpen(false);
        var newState = { ...state };
        if (newEquiClassVals) {
            newState.description = newEquiClassVals.description;
            newState.equiClass = newEquiClassVals.equiClass;
        }

        navigate(0, { replace: true, state: { ...newState } });
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };


    return (
        <>
            <div className="omis-header-box">
                <OmisCol xs={1}>
                    <OmisIconButton id={"backButton"}
                        iconcontent={<ArrowBackIcon fontSize="inherit" />}
                        text="Back" onClick={() => { navigate(-1); }} />
                </OmisCol>
            </div>
            <Card>
                <OmisContainer fluid>
                    <OmisRow>
                        <OmisCol xs={10}>
                            <OmisRow>
                                <OmisCol xs={3}>
                                    <strong>{t("Name")}:</strong>
                                    <OmisLabel label={details?.companySiteName} />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <strong>{t("Contractor")}:</strong>
                                    <OmisLabel label={details?.Contractor} />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <strong>{t("CompanySite_SMSAuthentication")}:</strong>
                                    <OmisLabel label={details?.smsAuthentication} />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <strong>{t("CompanySite_Headquarter")}:</strong>
                                    <OmisLabel label={details?.headQuarter ? <CheckIcon color={"success"} /> : null} />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <strong>{t("CompanySite_SMSReactionTime1")}:</strong>
                                    <OmisLabel label={details?.reactionTime1.toString()} />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <strong>{t("CompanySite_SMSReactionTime2")}:</strong>
                                    <OmisLabel label={details?.reactionTime2.toString()} />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <strong>{t("CompanySite_SMSReactionTime2Contacts")}:</strong>
                                    <OmisLabel label={details?.smsReactionTime2Contacts} />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <strong>{t("LockFlag")}:</strong>
                                    <OmisLabel label={details?.lockFlag ? <CheckIcon color={"success"} /> : null} />
                                </OmisCol>
                            </OmisRow>
                        </OmisCol>
                        <OmisCol xs={{ span: 2, offset: 0 }}>
                            <OmisButtonSecondary id={"editButton"} onClick={handleEditClick.bind(this, state?.id, details)} text={t("Action_Edit")} />
                        </OmisCol>
                    </OmisRow>
                </OmisContainer>
                <br/>
                <AddressDetailsInfo data={details?.address} noCard />

                <OmisRow>
                    <OmisTabs tabs={tabs} onChange={tabChanged} tabvalue={selectedTabValue} />
                </OmisRow>
            </Card>

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"lg"}
                title={modalTitle}
                content={modalContent}
                contentHeight={500}
            />
        </>
    );
}