import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { Card } from "@mui/material";
import { OmisSelectedCatalogueCard } from "../../shared/OmisDisplays";
import { OmisGrid } from "../../shared/OmisGrids";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

const SelectionStep = {
    Object: 1,
    User: 2,
    Done: 3
}

export function CreateObjectUser(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const [selectedObjectID, setSelectedObjectID] = useState(null);
    const [selectedObjectText, setSelectedObjectText] = useState('');
    const [selectedUserID, setSelectedUserID] = useState(null);
    const [selectedUserText, setSelectedUserText] = useState('');
    const [selectionStep, setSelectionStep] = useState(SelectionStep.Object);

    const [initialValues, setInitialValues] = useState({
        id: props.rowVals?.id ?? null,
        objectID: props.rowVals?.objectid ?? 0,
        userID: props.rowVals?.userid ?? 0,
    });

    useEffect(() => {
        if (props.rowVals) {
            handleSingleEntry(SelectionStep.Object, props.rowVals);
            handleSingleEntry(SelectionStep.User, props.rowVals);
        }

    },[]);

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsobjects/saveobjectuser", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate("/settings/objects/objectusers", { replace: true, state: { selectedtabvalue: TabConstants.Objects } });
                }
            }
        });
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate("/settings/objects/objectusers", { replace: true, state: { selectedtabvalue: TabConstants.Objects } });
        }
    }

    const [schema, setSchema] = useState({
        objectID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        userID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),      
    });

    const validationSchema = Yup.object(schema);

    function handleObjectSelect(selection) {
        setSelectionStep(SelectionStep.User);
        updateSelection(selection);
    }

    function handleObjectDeselect() {        
        setSelectionStep(SelectionStep.Object);
        clearSelection(SelectionStep.Object);
    }

    function handleUserSelect(selection) {
        setSelectionStep(SelectionStep.Done);
        updateSelection(selection);
    }

    function handleUserDeselect() {
        setSelectionStep(SelectionStep.User);
        clearSelection(SelectionStep.User);
    }

    function handleSingleEntry(selectionGrid, row) {
        switch (selectionGrid) {
            case SelectionStep.Object:
                setSelectedObjectID(row?.objectid ?? row?.id ?? null);
                setSelectedObjectText(row?.objectnrinternal ?? '');
                formRef.current.setFieldValue("objectID", row?.objectid ?? row?.id ?? null);
                setSelectionStep(SelectionStep.User);
                break;
            case SelectionStep.User:
                setSelectedUserID(row?.userid ?? row?.id ?? null);
                setSelectedUserText(row?.name ?? '');
                formRef.current.setFieldValue("userID", row?.userid ?? row?.id ?? null);
                setSelectionStep(SelectionStep.Done);
                break;
            default:
                break;
        }
    }

    function clearSelection(selectedStep) {
        var clearObject = selectedStep < SelectionStep.User;
        var clearUser = selectedStep < SelectionStep.Done;

        if (clearObject) {
            setSelectedObjectID(null);
            setSelectedObjectText('');
            formRef.current.setFieldValue("objectID", null);
        }

        if (clearUser) {
            setSelectedUserID(null);
            setSelectedUserText('');
            formRef.current.setFieldValue("userID", null);
        }
    }

    function updateSelection(row) {
        switch (selectionStep) {
            case SelectionStep.Object:
                setSelectedObjectID(row?.id ?? null);
                setSelectedObjectText(row?.objectnrinternal ?? '');
                formRef.current.setFieldValue("objectID", row?.id ?? null);
                break;
            case SelectionStep.User:
                setSelectedUserID(row?.userid ?? row?.id ?? null);
                setSelectedUserText(row?.name ?? '');
                formRef.current.setFieldValue("userID", row?.userid ?? row?.id ?? null);
                break;
            default:
                break;
        }
    }

    function CatalogueGrid(props) {
        const { step } = { ...props };

        switch (step) {
            case SelectionStep.Object:
                var url = "/api/objects/getobjectlist";

                if (props.objectid) {
                    url = `${url}?objectid=${props.objectid}`;
                }

                return (
                    <OmisGrid
                        rowclickCallback={handleObjectSelect} noButtons noToolbar
                        apiUrl={url}
                        singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.Object)}
                        disableChipClick
                    />);
            case SelectionStep.User:
                return (
                    <OmisGrid
                        rowclickCallback={handleUserSelect} noButtons noHeader
                        apiUrl={`/api/settings/settingsobjects/getpossibleusers?objectid=${selectedObjectID}`}
                        singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.User)}    
                        disableChipClick
                    />);
            default:
                break;
        }
    }

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{`${t("Action_New")} ${t("Settings_UserWizard_ObjectUser")}`}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={4}>
                                    <Card variant={"outlined"}>
                                        <OmisSelectedCatalogueCard
                                            text={`${t("Object_Details_Title")} (${selectedObjectText})`}
                                            id={selectedObjectText}
                                            handleDeselect={handleObjectDeselect}
                                            disabled={props.rowVals}
                                            required
                                        />
                                    </Card>                                      
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <Card variant={"outlined"}>
                                        <OmisSelectedCatalogueCard
                                            text={`${t("User")} (${selectedUserText})`}
                                            id={selectedUserText}
                                            handleDeselect={handleUserDeselect}
                                            disabled={props.rowVals}
                                            required
                                        />
                                    </Card>
                                </OmisCol>
                                <OmisContainer fluid>
                                    <CatalogueGrid step={selectionStep} {...props} />
                                </OmisContainer>
                            </OmisRow>
                            <br />
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}