import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonNewItem, OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { Card, CardActionArea, CardContent } from "@mui/material";
import { CheckCircle, HighlightOff } from "@mui/icons-material";
import { OmisTextBox } from "../../shared/OmisInputs";
import { OmisDialog, OmisLabel } from "../../shared/OmisDisplays";
import { OmisGrid } from "../../shared/OmisGrids";
import { ObjectAttributeTabConstants, TabConstants } from "../../../constants/SettingConstants";
import { CreateObjectAttributeDef } from "./CreateObjectAttributeDef";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

const SelectionStep = {
    Object: 1,
    ObjectAttributeDef: 2,
}

export function CreateObjectAttribute(props) {

    var objectlisturl = "/api/objects/getobjectlist";

    if (props.objectid) {
        objectlisturl = `${objectlisturl}?objectid=${props.objectid}`;
    }

    const navigate = useNavigate();
    const formRef = useRef();
    const [selectedObjectText, setSelectedObjectText] = useState('');
    const [selectedAttributeDefText, setSelectedAttributeDefText] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    const [initialValues, setInitialValues] = useState({
        id: props.rowVals && props.rowVals.id ? props.rowVals.id : null,
        objectID: props.rowVals && props.rowVals.objectid ? props.rowVals.objectid : props.objectid ?? 0,
        attributeVal: props.rowVals && props.rowVals.objattribval ? props.rowVals.objattribval : "",
        attributeDefID: props.rowVals && props.rowVals.attributedefid ? props.rowVals.attributedefid : 0,
    });

    useEffect(() => {
        setSelectedObjectText(props.rowVals?.objectnrinternal ?? '');
        setSelectedAttributeDefText(props.rowVals?.attributedeftext ?? '');
    },[]);

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsobjects/saveobjectattribute", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate("/settings/objects/objectattributes", { replace: true, state: { selectedtabvalue: TabConstants.Objects, selectedObjectAttributeTabValue: ObjectAttributeTabConstants.ObjectAttribute } });
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate("/settings/objects/objectattributes", { replace: true, state: { selectedtabvalue: TabConstants.Objects, selectedObjectAttributeTabValue: ObjectAttributeTabConstants.ObjectAttribute } });
        }
    }

    const [schema, setSchema] = useState({
        attributeVal: Yup.string()
            .notRequired()
            .test("attributeVal control", t("Value_Exists"),()=> {
                if (formRef.current.values.attributeVal && formRef.current.values.attributeDefID > 0) {
                    return httpclientService.get(`/api/settings/settingsobjects/isobjectattributevalueavailable?attributeVal=${formRef.current.values.attributeVal}&attributeDefID=${formRef.current.values.attributeDefID}&objectID=${formRef.current.values.objectID}`).then((response) => {
                        return response;
                    });
                } else {
                    return true;
                }
            }),
        objectID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        attributeDefID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
    });

    const validationSchema = Yup.object(schema);

    function handleObjectSelect(selection) {
        formRef.current.setFieldValue("objectID", selection?.id);
        setSelectedObjectText(selection?.objectnrinternal ?? '');
    }

    function handleObjectDeselect() {
        formRef.current.setFieldValue("objectID", 0);
        setSelectedObjectText('');
    }

    function handleAttributeDefSelect(selection) {
        formRef.current.setFieldValue("attributeDefID", selection?.id);
        setSelectedAttributeDefText(selection?.objattribdesc ?? '');
    }

    function handleAttributeDefDeselect() {
        formRef.current.setFieldValue("attributeDefID", 0);
        setSelectedAttributeDefText('');
    }

    function handleSingleEntry(selectionGrid, row) {
        switch (selectionGrid) {
            case SelectionStep.Object:
                handleObjectSelect(row);
                break;
            case SelectionStep.ObjectAttributeDef:
                handleAttributeDefSelect(row);
                break;
            default:
                break;
        }
    }

    function handleNewAttDefClick(rowID, e) {
        setModalTitle(t("Action_Edit"));
        setModalContent(<CreateObjectAttributeDef onClose={handleModalClose} handleEditCancel={handleModalCancel} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("Action_New")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={t("Value")} name="attributeVal" />
                                </OmisCol>
                                <OmisCol xs={12}>
                                    <OmisLabel label={t("Objects") } />
                                    <Card variant={"outlined"}>
                                        <SelectedCatalogueCard
                                            text={`${t("Object_Details_Title")} (${selectedObjectText})`}
                                            id={selectedObjectText}
                                            handleDeselect={handleObjectDeselect}
                                            required
                                        />
                                    </Card>
                                    {
                                        selectedObjectText === '' ?
                                            <OmisGrid rowclickCallback={handleObjectSelect} noButtons noToolbar noHeaderText
                                                apiUrl={objectlisturl}
                                                singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.Object)}
                                                disableChipClick
                                            />
                                            :
                                            null
                                    }
                                </OmisCol>
                                <OmisCol xs={12}>
                                    <OmisLabel label={t("ObjectAttributeDef")} />
                                    <Card variant={"outlined"}>
                                        <SelectedCatalogueCard
                                            text={`${t("ObjectAttributeDef")} (${selectedAttributeDefText})`}
                                            id={selectedAttributeDefText}
                                            handleDeselect={handleAttributeDefDeselect}
                                            required
                                        />
                                    </Card>
                                    {
                                        selectedAttributeDefText === '' ?
                                            <OmisGrid rowclickCallback={handleAttributeDefSelect} noToolbar noHeaderText noExportButton
                                                apiUrl={"/api/settings/settingsobjects/getobjectattributedefs"}
                                                singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.ObjectAttributeDef)}
                                                newButton={<OmisButtonNewItem id={"newItemButton"} onClick={handleNewAttDefClick.bind(this, null, null)} text={t("Action_New")} />}
                                                change={changeCounter}
                                                disableChipClick
                                            />
                                            :
                                            null
                                    }
                                </OmisCol>
                                <OmisRow>
                                    <OmisCol xs={6}>
                                        <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                    </OmisCol>
                                    <OmisCol xs={6}>
                                        <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                    </OmisCol>
                                </OmisRow>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"lg"}
                title={modalTitle}
                content={modalContent}
                contentHeight={600}
            />
        </>
    );
}

function SelectedCatalogueCard(props) {
    return (
        <OmisCol xs={4}>
            <Card onClick={props.handleDeselect}>
                <CardActionArea>
                    <CardContent>
                        <OmisRow>
                            <OmisCol xs={1}>
                                {
                                    props.id ?
                                        <CheckCircle color={"success"} />
                                        :
                                        <HighlightOff color={"error"} />
                                }
                            </OmisCol>
                            <OmisCol xs={10}>
                                <OmisLabel label={props.text} required={props.required} />
                            </OmisCol>
                        </OmisRow>
                    </CardContent>
                </CardActionArea>
            </Card>
        </OmisCol>);
}