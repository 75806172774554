import { t } from "i18next";
import { Card, Grid } from "@mui/material";
import { OmisDate } from "../shared/OmisDisplays";
import { DetailsCardWithSkeleton } from "../shared/Shared";
import { Object_TabContent_Details } from "../objects/Object_TabContent_Details";
import { Equipment_TabContent_Details } from "../equipment/Equipment_TabContent_Details";
import Icon from "@mdi/react";
import { mdiAccount, mdiCalendarMonthOutline, mdiClockTimeThreeOutline, mdiCog, mdiPencilOutline, mdiTrashCanOutline } from "@mdi/js";
import { OmisErrButtonWithStartIcon, OmisSecButtonWithStartIcon } from "../shared/OmisButtons";
import { OmisObjectChip } from "../shared/OmisChips";
import React from "react";
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";

export function InspectionDetailsInfo(props) {

    if (!props.data) {
        return <></>;
    }

    return (
        <OmisContainer fluid>
            <p className={"bold detailsCardTitle"} >{`${t("TestDates_Settings")} ${t("Details")}`}</p>
            <Card variant={"outlined"}>
                <p className={"bold"}>{t("TestDates_Settings")}</p>
                <OmisRow>
                    <OmisCol xs={12}>
                        <p>{props.data.description}</p>
                    </OmisCol>
                    <OmisRow>
                        <OmisCol xs={2}>
                            <p>{t("DatePlanned")}</p>
                            <p className={"bold"}>
                                <OmisDate date={props.data.plannedDate} />
                            </p>
                        </OmisCol>
                        <OmisCol xs={2}>
                            <p>{t("DateCompletion")}</p>
                            <p className={"bold"}>
                                <OmisDate date={props.data.dateCompletion} />
                            </p>
                        </OmisCol>
                        <OmisCol xs={2}>
                            <p>{t("LastDateCompletion")}</p>
                            <p className={"bold"}>
                                <OmisDate date={props.data.lastDateCompletion} />
                            </p>
                        </OmisCol>
                        <OmisCol xs={2}>
                            <p>{t("Periodicity")}</p>
                            <p className={"bold"}>{`${props.data.periodicity} ${props.data.periodicityType}`}</p>
                        </OmisCol>
                        <OmisCol xs={2}>
                            <p>{t("TestDate_AllowedTimeBefore")}</p>
                            <p className={"bold"}>{`${props.data.allowedTimeBefore}`}</p>
                        </OmisCol>
                        <OmisCol xs={2}>
                            <p>{t("TestDate_AllowedTimeAfter")}</p>
                            <p className={"bold"}>{`${props.data.allowedTimeAfter}`}</p>
                        </OmisCol>
                        {
                            props.data.origin ?
                                <OmisCol xs={2}>
                                    <p>{t("TestDateOrigin")}</p>
                                    <p className={"bold"}>{`${props.data.origin}`}</p>
                                </OmisCol>
                                :
                                null
                        }
                    </OmisRow>
                </OmisRow>
                <OmisRow>
                    <DetailsCardWithSkeleton
                        url={`api/inspections/getreminders?totdID=${props.data.totdid}`}
                        skeletonheight={"100px"}
                        content={
                            <Reminders {...props} />}{...props} />
                </OmisRow>
            </Card>
            <OmisCol xs={12}>
                <p className={"bold detailsCardTitle"} >{t("Object_Details_Title")}</p>
                <Card variant={"outlined"}>
                    <Object_TabContent_Details {...props} objectNrInternal={props.state?.objectNrInternal} objectid={props.state?.objectid} />
                </Card>
            </OmisCol>
            {
                props.state?.equipid ?
                    <OmisCol xs={12}>
                        <p className={"bold detailsCardTitle"}>{`${t("Equipment")} ${t("Details")}`}</p>
                        <h6>{t("Equipment")}</h6>
                        <Card variant={"outlined"}>
                            <Equipment_TabContent_Details {...props} />
                        </Card>
                    </OmisCol>
                    :
                    null
            }
        </OmisContainer>
    );

    function Reminders(props) {

        return (
            <>
                {props.data && props.data.length > 0 ? props.data.map((item) =>
                    <OmisCol xs={6}>
                        <p className={"bold"}>{t("Urgency")}</p>
                        <Card>
                            <OmisRow>
                                <OmisCol xs={3}>
                                    <Icon style={{ color: '#2655B9' }} path={mdiCalendarMonthOutline} size={1}></Icon>
                                    <p style={{ color: '#2655B9' }} className={"bold"}>
                                        <OmisDate date={item.plannedDate} />
                                    </p>
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <Icon style={{ color: '#2655B9' }} path={mdiClockTimeThreeOutline} size={1}></Icon>
                                    <p style={{ color: '#2655B9' }} className={"bold"}>
                                        <OmisDate date={item.plannedDate} />
                                    </p>
                                </OmisCol>
                                <OmisCol xs={3}> </OmisCol>
                                <OmisCol xs={3}>
                                    <OmisRow>
                                        <OmisCol xs={6}>
                                            <OmisSecButtonWithStartIcon iconpath={mdiPencilOutline} /*onClick={() => addNewStep()}*/ />
                                        </OmisCol>
                                        <OmisCol xs={6}>
                                            <OmisErrButtonWithStartIcon iconpath={mdiTrashCanOutline} /*onClick={() => addNewStep()}*/ />
                                        </OmisCol>
                                    </OmisRow>
                                </OmisCol>
                            </OmisRow>

                            <OmisRow>
                                <OmisCol xs={4}>
                                    <OmisObjectChip objectid={item.objectID} text={item.objectID} />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisSecButtonWithStartIcon iconpath={mdiCog} text={'Technicals_A35'} />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisSecButtonWithStartIcon iconpath={mdiAccount} text={'controller?'} />
                                </OmisCol>
                            </OmisRow>
                        </Card>
                    </OmisCol>
                )
                    :
                    null
                }
            </>
        );
    }
}