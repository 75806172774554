import React, { useState } from "react";
import { t } from "i18next";
import httpclientService from "../../../services/httpclient.service";
import { OmisButtonNewItem } from "../../shared/OmisButtons";
import { OmisDialog } from "../../shared/OmisDisplays";
import { OmisGrid } from "../../shared/OmisGrids";
import { CreateFaultEquiClassRecipient } from "./CreateFaultEquiClassRecipient";

export function FaultEquiClassRecipients(props) {
    var url = "/api/settings/faultequiclassrecipient/getfaultequiclassrecipients";
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    if (props.faultEquiClassDefID) {
        url = `${url}?faultEquiClassDefID=${props.faultEquiClassDefID}`;
    }

    function handleEditClick(rowID, e) {
        if (e) {
            setModalTitle(t("Action_Edit"));
        } else {
            setModalTitle(null);
            if (typeof rowID !== 'number') {
                rowID = null
            }
        }

        var rowVals = { ...e };
        if (props.faultEquiClassDefID && props.faultEquiClassDefID > 0) {
            rowVals.faultEquiClassDefID = props.faultEquiClassDefID;
        }

        setModalContent(<CreateFaultEquiClassRecipient onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID} rowVals={rowVals} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    const handleDelete = (rowID) => {
        httpclientService.post(`/api/settings/faultequiclassrecipient/deletefaultequiclassrecipient?id=${rowID}`).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    }

    return (
        <div>
            <OmisGrid actiondropdown={{ edit: handleEditClick, delete: handleDelete }} headerText={t("FaultEquiClassRecipient")}
                newButton={<OmisButtonNewItem id={"newItemButton"} onClick={handleEditClick} text={t("Action_New")} />}
                apiUrl={url}
                change={changeCounter}
                noHeaderText={props.faultEquiClassDefID}
                noExportButton={props.faultEquiClassDefID}
                {...props} />

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"md"}
                title={modalTitle}
                content={modalContent}
                contentHeight={300}
            />
        </div>
    );
}