import React, { useEffect, useRef } from "react";
import { t } from "i18next";
import { OmisIconButton} from "../../shared/OmisButtons";
import { Card } from "@mui/material";
import { OmisTimePickerNoForm } from "../../shared/OmisInputs";
import { OmisDropdownMultiselectV2 } from "../../shared/OmisDropdowns";
import { Delete as DeleteIcon, Check as CheckIcon } from '@mui/icons-material';
import { OmisCol, OmisRow } from "../../shared/OmisLayouts";

export function DynamicOpeningTimeBuilder(props) {
    
    const timePickerRefs = useRef([]);
    function handleOpeningTimeChange(id, event) {
        const {
            target: { value },
        } = event;

        // On autofill we get a stringified value.
        var _newArr = typeof value === 'string' ? value.split(',') : value;//gives selected values in array
        if (props.handleOpeningTimeChange) {
            props.handleOpeningTimeChange(id, _newArr,'selectedDays');
        }
    }

    function handleFromTimeChange(id, value) {
        if (props.handleOpeningTimeChange) {
            props.handleOpeningTimeChange(id, value, 'fromTime');
        }
    }

    function handleToTimeChange(id, value) {
        if (props.handleOpeningTimeChange) {
            props.handleOpeningTimeChange(id, value, 'toTime');
        }
    }

    function handleOnChange(id, type, value) {
        if (value !== null && value.toString() !== "Invalid Date") {
            switch (type) {
                case "From":
                    handleFromTimeChange(id, value);
                    break;
                case "To":
                    handleToTimeChange(id, value);
                    break;
                default:
                    break;
            }
        } else {
            switch (type) {
                case "From":
                    handleFromTimeChange(id, null);
                    break;
                case "To":
                    handleToTimeChange(id, null);
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        // Update refs array to match the number of items
        timePickerRefs.current = timePickerRefs.current.slice(0, props.openingTimes.length*3);
    }, [props.openingTimes]);

    const focusNextElement = (index) => {
        // Focus the next element in the array
        if (timePickerRefs.current[index]) {
            timePickerRefs.current[index].focus();
        }
    };

   return (
        props.openingTimes && props.openingTimes.length > 0 ?
            props.openingTimes.map((item, index) =>
                <OmisRow key={item.id}>
                    <Card variant={"outlined"}>
                        <OmisRow>
                            <OmisCol xs={3}>
                                <OmisDropdownMultiselectV2 inputRef={el => timePickerRefs.current[index * 3] = el} key={`${item.id}_days`} labeltext={t("Periodicity_Days")} placeholder={t("Periodicity_Days")} value={item.selectedDays} onChange={handleOpeningTimeChange.bind(this, item.id)} items={props.daysList} disableitemlist={props.removeFromDaysList} onKeyDown={() => focusNextElement((index * 3) + 1)} />
                            </OmisCol>
                            <OmisCol xs={3}>
                                <OmisTimePickerNoForm inputRef={el => timePickerRefs.current[(index * 3) + 1] = el} key={`${item.id}_From`} id={`${item.id}_From`} label={t("From")} value={item.fromTime} onAccept={handleFromTimeChange.bind(this, item.id)} onChange={handleOnChange.bind(this, item.id, "From")} onKeyDown={() => focusNextElement((index * 3)+2)}/*disableTyping={props.disableTypingDate} */ />
                            </OmisCol>
                            <OmisCol xs={3}>
                                <OmisTimePickerNoForm inputRef={el => timePickerRefs.current[(index * 3) + 2] = el} key={`${item.id}_To`} id={`${item.id}_To`} label={t("To")} value={item.toTime} onAccept={handleToTimeChange.bind(this, item.id)} onChange={handleOnChange.bind(this, item.id, "To")} onKeyDown={() => focusNextElement((index * 3) + 3)}/*disableTyping={props.disableTypingDate} */ />
                            </OmisCol>
                            {
                                props.handleRemoveOpeningTime ?
                                    <OmisCol xs={1}>
                                <OmisIconButton id={"deleteRowButton"}
                                            iconcontent={<DeleteIcon fontSize="inherit" />}
                                            onClick={() => {
                                                if (props.handleRemoveOpeningTime) {
                                                    props.handleRemoveOpeningTime(item.id);
                                                }
                                            }} />
                                    </OmisCol>
                                    :
                                    null
                            }
                            {
                                props.handleTimeConfirm ?
                                    <OmisCol xs={1}>
                                        <OmisIconButton id={"handleTimeConfirmButton"}
                                            iconcontent={<CheckIcon fontSize="inherit" />}
                                            onClick={props.handleTimeConfirm} />
                                    </OmisCol>
                                    :
                                    null
                            }
                        </OmisRow>
                    </Card>
                </OmisRow>
            )
            :
            null
    );
}