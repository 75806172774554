import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisAutosizeTextArea,OmisTextBox } from "../../shared/OmisInputs";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { OmisLabel } from "../../shared/OmisDisplays";
import { ObjectAttributeTabConstants, TabConstants } from "../../../constants/SettingConstants";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateObjectAttributeDef(props) {

    const navigate = useNavigate();
    const formRef = useRef();
    const [attributeOrders, setAttributeOrders] = useState([]);

    useEffect(() => {
        httpclientService.get("/api/settings/settingsobjects/getattributedeforders").then((response) => {
            if (response && response.length > 0) {
                var result = response;

                if (props.rowVals && props.rowVals.objattriborder) {
                    result = [{
                        label: `${props.rowVals.objattriborder}`, id: props.rowVals.objattriborder, key: props.rowVals.objattriborder, keyStr: `${props.rowVals.objattriborder}`, displayText: `${props.rowVals.objattriborder}` },...result]
                }
                setAttributeOrders(result);
            }
        });
    },[]);

    const [initialValues, setInitialValues] = useState({
        id: props.rowVals && props.rowVals.id ? props.rowVals.id : null,
        shortDescription: props.rowVals && props.rowVals.objattribshortdesc ? props.rowVals.objattribshortdesc : "",
        description: props.rowVals && props.rowVals.objattribdesc ? props.rowVals.objattribdesc : "",
        attributeOrder: "",
    });

    useEffect(() => {
        if (attributeOrders.length > 0) {
            if (props.rowVals && props.rowVals.objattriborder) {
                formRef.current.setFieldValue("attributeOrder", props.rowVals.objattriborder);
            }
        }
    }, [attributeOrders]);

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsobjects/saveobjectattributedef", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose(response, props.rowID);
                } else {
                    navigate("/settings/objects/objectattributes", { replace: true, state : { selectedtabvalue: TabConstants.Objects, selectedObjectAttributeTabValue: ObjectAttributeTabConstants.ObjectAttributeDef } });
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate("/settings/objects/objectattributes", { replace: true, state : { selectedtabvalue: TabConstants.Objects, selectedObjectAttributeTabValue: ObjectAttributeTabConstants.ObjectAttributeDef } });
        }
    }

    const [schema, setSchema] = useState({
        description: Yup.string()
            .required(t("GenericRequiredText"))
            .max(100, t('Error_TextTooLong', {1:100})),
        shortDescription: Yup.string()
            .required(t("GenericRequiredText"))
            .max(5, t('Error_TextTooLong', { 1: 5 })),
        attributeOrder: Yup.string()
            .required(t("GenericRequiredText"))
            .max(5, t('Error_TextTooLong', { 1: 5 })),        
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("Action_New")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={t("ObjectAttributeDef_ObjAttribShort")} name="shortDescription" required />
                                    <OmisLabel label={t("ObjectAttributeDef_ObjAttribOrder")} required />
                                    <OmisDropdown size={"small"} id={"attributeorders"} name={"attributeOrder"} items={attributeOrders} />
                                </OmisCol>
                                <OmisCol xs={8}>
                                    <OmisAutosizeTextArea labeltext={t("Description")} name="description" required />
                                </OmisCol>
                            </OmisRow>
                            <br/>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}