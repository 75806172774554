export const FaultReportMessage = {
    INITIALIZE: 1,
    ACCEPT: 2,
    DECLINE: 3,
    PROCESS: 4,
    SEND: 5,
    INSTRUCT_SUBCONTRACTOR: 6,
    PAUSE: 7,
    RESUME: 8,
    CANCEL: 9,
    SPLIT: 10,
    ADD_CONTRACTOR: 11,
    SELECT_OFFER: 12,
    SEND_TO_MAINTENANCE_RESPONSIBLE: 13,
    SEND_TO_MAINTENANCE_CONTROLLER: 14,
    MANUAL_REQUEST: 15,
    //FINISH_INITIAL_WORK : 16,
    SELF_REPAIR: 17,
    PROCESS_IN_GUARANTEE: 18,
    PROCESS_WITH_TENDERSPEC: 19,
    SEND_TO_FAULT_CLASS_RESPONSIBLE: 20,
    RESET: 21,
    REMIND: 22,
    DISPATCH: 23,
    FORWARD: 24,
    FORWARD_IGNORE_GUARANTEE: 25,
    PROCESS_WITHOUT_CONTRACTOR: 26,
    FINISH_TECHNICALLY: 27,
    SELECT_MANUAL_OFFER: 28,
    FINISH_IGN_WORKFLOW: 29,
    SECURE_OBJECT: 30,
    FINISH: 31,
    ARCHIVE: 32,
    REOPEN: 33,
    NOTCOVERED: 34,
    SEND_POLLUTER_KNOWN: 35,
    SEND_POLLUTER_UNKNOWN: 36,
    SEND_TO_AUTHORITY: 37,
    SIGNED: 38,
    CHANGE_INVOICE: 39,
    PROCESS_WITHOUT_ORDER: 40,
    CHECK_RESPONSIBLE: 41,
    SEND_COST_ESTIMATION: 42,
    CONFIRM: 43,
    COMPLAINT: 44,
    SEND_TO_RESPONSIBLE: 45,
    SEND_BACK_TO_ORDER: 46,
    PAID: 47,
    LINK: 48,
    NO_ORDER: 49,
    PROCESS_ONWT: 50,
    PROCESS_NWT: 51,
    PROCESS_WITH_DPV: 52,
    PROCESS_WITHOUT_DPV: 53,
    PROCESS_REFUSE_APPROVAL: 54,
    PROCESS_APPROVED: 55,
    PROCESS_GET_APPROVAL: 56,
    RECLASSIFY: 57,
    PLANING: 58,
    INVOICE_TO_COME: 59,
    NO_CHARGE: 60,
    KIMOCE_ATT: 10001,
    KIMOCE_CRS: 10002,
    KIMOCE_PLA: 10003,
    KIMOCE_SUS: 10004,
    KIMOCE_GES: 10005,
    KIMOCE_NOK: 11900,
    KIMOCE_TRA: 11901,
    KIMOCE_ITV: 11902,
    KIMOCE_PDA: 11903,
    KIMOCE_WAE: 11904,
    KIMOCE_FZP: 11905,
    KIMOCE_AIB: 11906,
    KIMOCE_EWB: 11907,
    KIMOCE_REMIND: 10022,
    KIMOCE_CANCEL: 10009
}