/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Card, Grid, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { t } from "i18next";
import { OmisBadgeForGridTitle, OmisLabel } from "../shared/OmisDisplays";
import { OmisButtonSecondary, OmisButtonPrimarySubmit, OmisButtonPrimary, OmisProgress } from "../shared/OmisButtons";
import { Form, Formik } from "formik";
import { DetailsCheckListsInfo } from "./DetailsCheckListsInfo";
import AuthService from "../../services/auth.service";
import { OmisDateTimePicker, OmisTextBox } from "../shared/OmisInputs";
import httpClientService from "../../services/httpclient.service";
import urlEncryptService from '../../services/urlencrypt.service';
import { useEffect } from "react";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../shared/OmisLayouts";

export default function InspectionControl(props) {
    const navigate = useNavigate();
    const location = useLocation();
    var { state } = useLocation();
    const formRef = useRef();
    const currentUser = AuthService.getCurrentUser();
    const [currentInspectionStep, setCurrentInspectionStep] = useState(1);
    const [selectionsCompleted, setSelectionsCompleted] = useState(null);

    const [initialValues, setInitialValues] = useState({
        inspectionID: props.rowVals?.inspectionid ?? state?.inspectionid ?? null,
        checklistTester: currentUser ? currentUser.fullName : '',
        testDateOriginID: props.rowVals?.testDateOriginID ?? null,
        date: props.rowVals?.date ?? new Date(),
        equiOk: props.rowVals?.equiOk ?? false,
        checkList: props.rowVals?.checkList ?? [],
        selectionsCompleted: selectionsCompleted,
        currentInspectionStep: 1,
    });

    const validationSchema = Yup.object().shape({
        selectionsCompleted: Yup.boolean()
            .required("The terms and conditions must be accepted.")
            .test("selectionValidation", (value) => {
                return formRef.current.values.selectionsCompleted;
            }),
        checklistTester: Yup.lazy(() => { // optional yup required. for rendering, there should be one field change on values, thats why i used currentInspectionStep
            //if(currentInspectionStep>1) {
                return Yup.string()
                    .required(t("GenericRequiredText"))
                    .max(100, t('Error_TextTooLong', { 1: 100 }));
            //}else{
                return Yup.string().notRequired();                    
            //}
            }),
    });  

    const selectionsUpdated = (result) => {
        setSelectionsCompleted(result);
        formRef.current.setFieldValue("selectionsCompleted", result ?? null);
    }

    const handleEquipOKChanged = (radioResultValue) => {
        formRef.current.setFieldValue("equiOk", /^true$/i.test(radioResultValue) );
    }

    const handleFormSubmit = (values) => {
        httpClientService.post("/api/inspections/savechecklist", JSON.stringify(values)).then((response) => {
            if (response) {
                if (values.generateReport) {
                    navigate("/reports/create/", { replace: true, state: { ...state, ...response} });
                } else {
                    navigate("/inspections", { replace: true, state: { state } });
                }
            }
        });
    }

    const handleGenerateReport = (values) => {

        httpClientService.post(`/api/inspections/savechecklistitemforreport?testDateID=${state?.inspectionid}`, JSON.stringify(values)).then((response) => {
            if (response) {
                var encrptedProps = urlEncryptService.encrypt({ testDatesChecklistID :values.testDatesChecklistID });
                const url = '/reports/create/';
                window.open(`${url}?${encrptedProps}`, '_blank');
            }
        });
        //values.generateReport = true;
        //handleFormSubmit(values);
    }

    const handleCheckListValueChange = (checkList) => {
        var isValid = true;
        checkList.map((item) => {
            if (item.noteVisible && item.noteRequired && !item.note) {
                isValid = false;
            }

            if (item.valueVisible &&item.valueRequired && !item.value) {
                isValid = false;
            }

            if (item.okNotOkRequired && item.status === null) {
                isValid = false;
            }
        });

        formRef.current.setFieldValue("selectionsCompleted", isValid);
        formRef.current.setFieldValue("checkList", checkList ?? null);
        setSelectionsCompleted(isValid);
    }

    useEffect(() => {
        formRef.current.setFieldValue("currentInspectionStep", currentInspectionStep);
    }, [currentInspectionStep])

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
            >
                {formik => (
                    <Form>
                        <div className="omis-header-box">
                            <OmisContainer fluid>
                                <OmisRow>
                                    <OmisCol xs={6} >
                                        <h1>
                                            {t("Inspection")} #{state?.inspectionid}
                                        </h1>
                                    </OmisCol>
                                    <OmisCol style={{ display: 'flex', justifyContent: 'right' }} xs={6}>
                                        {currentInspectionStep === 1 ? <>
                                            <OmisStack direction="horizontal" gap={1}>
                                                <div className="p-2">
                                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={() => { navigate(location?.key !== "default" ? -1 : "/inspections"); }} state={state} />
                                                </div>
                                                <div className="p-2">
                                                    {/*<OmisButtonPrimary id={"nextStepButton"} disabled={!formik.isValid} text={t("NextStep")} onClick={() => setCurrentInspectionStep(2)} />*/}
                                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")} />
                                                </div>
                                            </OmisStack>

                                        </> : <> {/* We don't need second step anymore 
                                                
                                            <OmisStack direction="horizontal" gap={1}>
                                                    <div className="p-2">
                                                        <OmisButtonSecondary id={"previousStepButton"} text={t("Action_Back")} onClick={() => setCurrentInspectionStep(1)} />
                                                    </div>
                                                    <div className="p-2">
                                                        <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Finish")} />
                                                    </div>
                                            </OmisStack>
                                            */}
                                        </>}
                                    </OmisCol>
                                </OmisRow>
                                <OmisRow>
                                    <OmisCol>
                                        <OmisProgress variant="determinate" value={currentInspectionStep >1 || formik.isValid ? 100 : 0} sx={{marginBottom:'1em'}} />
                                        {
                                            currentInspectionStep === 1 ?
                                                <OmisBadgeForGridTitle text={currentInspectionStep} content={<h6>{`${t("Action_Checklist")}`}</h6>} />
                                                :
                                                null
                                        }
                                    </OmisCol>
                                    {/*<OmisCol>*/}
                                    {/*    <OmisProgress variant="determinate" value={currentInspectionStep === 2 && formik.isValid ? 100 : 0} sx={{ marginBottom: '1em' }} />*/}
                                    {/*    {*/}
                                    {/*        currentInspectionStep === 2 ?*/}
                                    {/*            <OmisBadgeForGridTitle text={currentInspectionStep} content={<h6>{t("FinishInspection")}</h6>} />*/}
                                    {/*            :*/}
                                    {/*            null*/}
                                    {/*    }*/}
                                    {/*</OmisCol>*/}
                                </OmisRow>
                            </OmisContainer>
                        </div>
                        {
                            currentInspectionStep === 1 ?
                                <>
                                    <Card>
                                        <OmisRow>
                                            <OmisCol xs={6}>
                                                <OmisTextBox labeltext={t("CheckList_Tester")} name={"checklistTester"} placeholder={t("CheckList_Tester")} required />
                                            </OmisCol>
                                        </OmisRow>
                                    </Card>

                                    <DetailsCheckListsInfo checkList={formik.values.checkList} selectionsUpdated={selectionsUpdated} isEdit={true} handleCheckListValueChange={handleCheckListValueChange} state={state} handleGenerateReport={handleGenerateReport} />
                                </>
                                :
                                <>
                                    { /*
                                    <Card variant={"outlined"}>
                                        <OmisRow>
                                            <OmisCol xs={6}>
                                                <Card variant={"outlined"}>
                                                    <OmisRow>
                                                        <OmisCol xs={6}>
                                                            <OmisTextBox labeltext={t("CheckList_Tester")} name={"checklistTester"} placeholder={t("CheckList_Tester")} required />
                                                        </OmisCol>
                                                        <OmisCol xs={6}>
                                                            <OmisLabel label={t("Date")} />
                                                            <OmisDateTimePicker name={"date"} />
                                                        </OmisCol>
                                                    </OmisRow>
                                                </Card>
                                                <Card variant={"outlined"}>
                                                    <OmisRow>
                                                        <h6>{t("DeficiencyDetected") }</h6>
                                                    </OmisRow>
                                                    <OmisRow>
                                                        <RadioGroup
                                                            id={"resultRadioGroup"}
                                                            name={"equiOk"}
                                                            value={formik.values.equiOk}
                                                            onChange={e => handleEquipOKChanged(e.target.value)}
                                                        >
                                                            <FormControlLabel value={true} control={<Radio />} label={t("No")} />
                                                            <FormControlLabel value={false} control={<Radio />} label={t("Yes")} />
                                                        </RadioGroup>
                                                    </OmisRow>
                                                </Card>
                                                <Card>
                                                    <OmisRow>
                                                        {
                                                            !formik.values.equiOk && formik.values.checkList.some(x => x.showCreateReportButton) ?
                                                                <OmisCol xs={4}>
                                                                    <OmisButtonPrimary id={"generateReportButton"} text={t("Generate_Report")} onClick={handleGenerateReport.bind(this, formik.values)} />
                                                                </OmisCol>
                                                                :
                                                                null
                                                        }
                                                        <OmisCol xs={4}>
                                                            <OmisButtonSecondary id={"downloadResultButton"} text={t("Download_Result")} />
                                                        </OmisCol>
                                                    </OmisRow>
                                                </Card>
                                            </OmisCol>
                                        </OmisRow>
                                    </Card >
                                    */}
                                </>
                        }
                    </Form>
                )}
            </Formik>
        </>
    );
}