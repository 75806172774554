import React, { useEffect, useState } from "react";
import { t } from "i18next";
import httpclientService from "../../services/httpclient.service";
import { OmisHorizontalBarChart, OmisVerticalBarChart } from "../shared/OmisCharts";
import { OmisCol, OmisRow } from "../shared/OmisLayouts";

export function TabContent_Dashboard(props) {
    const {state} = {...props};

    const [verLabels, setVerLabels] = useState([]);
    const horLabels = [t("Action_Cancel"), t("TestDate_Done"), t("Open")];

    const [verBarDatas, setVerBarDatas] = useState({
        labels: verLabels,
        datasets: [{
            data: [],
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },],
        legendPosition: 'top',
        titleText: t("Dashboard_Title_ReportsPerMonthForLastMonths")
    });

    const [horBarDatas, setHorBarDatas] = useState({
        labels: horLabels,
        datasets: [{
            data: [],
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },],
        legendPosition: 'right',
        titleText: t("Dashboard_Title_ReportPerStatus")
    });

    useEffect(() => {
        httpclientService.get(`/api/objects/getobjectreportscount?objectID=${ state?.objectid }`)
            .then((data) => {
                //vertical chart bar
                if (data.monthlyCounts) {
                    setVerLabels([...data.monthlyCounts.values()].map((item) => item.month));

                    setVerBarDatas({
                        labels: [...data.monthlyCounts.values()].map((item) => item.month),
                        datasets: [{
                            data: [...data.monthlyCounts].map((item) => item.count),
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        },],
                        legendDisplay: false,
                        titleText: t("Dashboard_Title_ReportsPerMonthForLastMonths"),
                        titleAlign: 'start',
                        datalabels: {
                            display: false,
                        }
                    });
                }

                if (data.cancelledCount || data.completedCount ||data.openedCount) {
                    //hor chart bar
                    setHorBarDatas({
                        labels: horLabels,
                        datasets: [{
                            data: [data.cancelledCount, data.completedCount, data.openedCount],
                            backgroundColor: ['#FF7366', '#66CC88', '#FFD34D',],
                        },],
                        legendDisplay: false,
                        titleText: t("Dashboard_Title_ReportPerStatus"),
                        titleAlign: 'start',
                        datalabels: {
                            display: function (context) {
                                var dataset = context.dataset;
                                var value = dataset.data[context.dataIndex];
                                context.dataset.data[context.dataIndex] = !`${ value }`.includes('reports') ? `${ value } reports` : value;
                                return true;
                            },
                            color: "black",
                        }
                    });
                }
            });
    }, []);

    const horScales = {
        y: {  // not 'yAxes: [{' anymore (not an array anymore)
            ticks: {
                color: function (context) {
                    return context.tick.label === 'Cancelled' ? '#FF7366' :  // draw negative values in red
                        context.tick.label === 'Completed' ? '#66CC88' :    // else, alternate values in blue and green
                            '#FFD34D';
                },
            },
            stacked: true,
        }
    }

    return (
        <OmisRow>
            <OmisCol xs={ 12 }>
                <OmisVerticalBarChart barDatas={ verBarDatas }/>
            </OmisCol>
            <OmisCol xs={ 12 }>
                <OmisHorizontalBarChart barDatas={ horBarDatas } scales={ horScales } barHeight={ horLabels.length * 20 }/>
            </OmisCol>
        </OmisRow>
    );
}