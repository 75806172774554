import React, { useState } from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import httpclientService from "../../services/httpclient.service";
import { Link as RouterLink } from "react-router-dom";
import { mdiSend, mdiAccountSwitch, mdiSwapHorizontal, mdiArchive } from '@mdi/js';
import { OmisGrid } from "../shared/OmisGrids";
import AuthService from "../../services/auth.service";
import { TRole } from "../../constants/RoleConstants";
import { OmisDialog } from "../shared/OmisDisplays";
import EditUser from "./EditUser";
import SetUserReplacement from "./SetUserReplacement";
import { OmisButtonNewItem, OmisProgress } from "../shared/OmisButtons";
import AddNewUser from "./AddNewUser";

var url = "/api/profile/";

export function Tab_UsersList(props) {
    return (
        <>
            <AllUserList {...props} />
        </>
    );
}

export function AllUserList(props) {
    var apiUrl = `${url}getuserslist`;

    const { i18n } = useTranslation();

    const [modalWidth, setModalWidth] = useState("sm");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState("");
    const [changeCounter, setChangeCounter] = useState(0);

    if (props.contractorID) {
        apiUrl = `${apiUrl}?contractorID=${props.contractorID}`
    }

    const handleModalOpen = (content, title, e) => {
        if (title === t("Action_Edit") || title === null) {
            setModalWidth("xl");
        }
        setModalContent(content);
        setModalTitle(title);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setModalContent(null);
        setModalTitle(null);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
        setModalContent(null);
        setModalTitle(null);
    };

    const addUser = (rowID) => {
        handleModalOpen(<AddNewUser userID={rowID} contractorID={props.contractorID} handleModalClose={handleModalClose} handleModalCancel={handleModalCancel} />,null, rowID);
    };

    const editUser = (rowID) => {
        handleModalOpen(<EditUser userID={rowID} handleModalClose={handleModalClose} handleModalCancel={handleModalCancel} />, t("Action_Edit"), rowID);
    };

    const sendLoginData = (rowID) => {
        handleModalOpen(<OmisProgress />, null, null);
        httpclientService.post(`api/profile/sendlogindata?userID=${rowID}`).then((response) => {
            handleModalCancel();
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    };

    const setReplacement = (rowID,e) => {
        handleModalOpen(<SetUserReplacement userID={rowID} fromuser={ e?.fullname ?? ''} handleModalClose={handleModalClose} handleModalCancel={handleModalCancel} />, "Set replacement", rowID);
    };

    const archiveClicked = (rowID) => {        
        httpclientService.post(`api/profile/setuserarchive?userID=${rowID}`).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    };

    const deleteContractorClicked = (rowID) => {
        httpclientService.post(`api/profile/deleteusercontractor?userID=${rowID}`).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    };

    const handleEmptyList = (e) => {
    };

    const loginToAnotherUser = (rowID) => {
        httpclientService.post(`api/profile/logintoanotheruser?userID=${rowID}`).then((response) => {
            if (response) {
                if (response.status && response.status > 200) {
                    alert(t("HttpError_401"));
                    return false;
                } else {
                    localStorage.removeItem("user");
                    localStorage.setItem("user", JSON.stringify(response));
                    var lang = response.language;
                    if (lang && localStorage.getItem("i18nLng") !== lang) {
                        i18n.changeLanguage(lang).then(() => {
                            localStorage.setItem("i18nLng", lang);
                        }).then(() => {
                            try {
                                httpclientService.post("/api/profile/changelanguage?isUserSwitch=1", lang).then((response) => {
                                    AuthService.getUrlCheck();
                                    window.location.reload();
                                });
                            } catch (err) {
                                AuthService.getUrlCheck();
                                window.location.reload();
                            }
                        });
                    } else {
                        AuthService.getUrlCheck();
                        window.location.reload();
                    }
                }
            }
        }).catch((err) => {
            console.error(err.response.data.errormessage);
            window.location.reload();
        });
    };

    var dropDownMenuItems = [
        {
            action: sendLoginData,
            iconpath: mdiSend,
            icontext: t("Settings_SendUserLogins"),
        },
        {
            action: setReplacement,
            iconpath: mdiAccountSwitch,
            icontext: t("Replacement_Set"),
        },
        {
            action: archiveClicked,
            iconpath: mdiArchive,
            icontext: t("Action_Archive"),
        }
    ]

    if (AuthService.hasRole(TRole.FSMWorkflowUser)) {
        dropDownMenuItems.splice(3, 0, {
            action: loginToAnotherUser,
            iconpath: mdiSwapHorizontal,
            icontext: t("Account_Login_Title"),
        });
    }

    return (
        <>
            <OmisGrid
                headerText={t("Users")}
                apiUrl={apiUrl}
                newButton={props.openInPopup ? <OmisButtonNewItem id={"newItemButton"} key={`addButton`} onClick={addUser} text={t("Action_New")} size={"small"} /> : <OmisButtonNewItem id={"newItemButton"} key={`addButton`} component={RouterLink} to={"/users/addnewuser"} text={t("Action_New")} size={"small"} />}
                showCheckBox={props.showCheckBox ?? true}
                checkedRows={props && props.initialValues ? props.initialValues.userIDs : []}
                rowSelectionCallback={props.handleSelectedUsersList}
                actiondropdown={props.noActionButtons ? null : props.contractorID ? { delete: deleteContractorClicked } :  { edit: editUser, dropDownMenuItems: dropDownMenuItems }}
                groupsDropDownHasItems={[]}
                userObjectsDropDownHasItems={[]}
                noEntriesCallback={handleEmptyList}
                change={changeCounter}
                hasdetailview
                detailsroute="/user/userdetails"
                {...props} />

            <OmisDialog onClose={handleModalCancel} open={modalOpen} maxWidth={modalWidth} title={modalTitle} content={modalContent} />
        </>
        );

}