import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisTextBox } from "../../shared/OmisInputs";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateEquiAttributeDef(props) {

    var { state } = useLocation();
    const navigate = useNavigate();
    const formRef = useRef();

    const [equiClasses, setEquiClasses] = useState([]);
    const [initialValues, setInitialValues] = useState({
        id: props.id ? props.id : null,
        description: props.rowVals?.description ?? "",
        attributeName: props.rowVals?.attributeName ?? "",
        //orderNr: props.rowVals?.orderNr ?? 0,
        equiClassID: props.rowVals?.equiClassID ?? state?.equiclassid ?? null,
    });

    useEffect(() => {
        httpclientService.get("/api/settings/settingsequipments/getequiclassesfordropdown").then((response) => {
            var equiClassID = props.rowVals?.equiClassID ?? state?.equiclassid ?? -1;
            if (response && response.length > 0) {
                setEquiClasses(response.map(x => {
                    return {
                        ...x,
                        disabled: (x.id !== equiClassID)
                    };
                }));
            }
        });
    }, []);

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsequipments/saveequiattributedef", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(-1);
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(-1);
        }
    }

    const [schema, setSchema] = useState({
        description: Yup.string()
            .required(t("GenericRequiredText"))
            .max(50, t('Error_TextTooLong', { 1: 50 })),
        attributeName: Yup.string()
            .required(t("GenericRequiredText"))
            .max(30, t('Error_TextTooLong', { 1: 30 })),
        equiClassID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1)
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("Settings_AttributeDef")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisTextBox labeltext={t("Description")} name="description" required />
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisTextBox labeltext={t("ObjectAttributeDef_ObjAttribShort")} name="attributeName" required />
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisDropdown labeltext={t("EquiClass")} name="equiClassID" items={equiClasses} required />
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}