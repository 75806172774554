import { DocumentUpload } from "../documents/Documents";
import { DMSDocumentReferer } from "../../constants/DMSDocumentReferer";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { Object_TabContent_Details } from "./Object_TabContent_Details";
import { TabContent_Dashboard } from "./TabContent_Dashboard";
import { TabContent_Reports } from "./TabContent_Reports";
import { TabContent_Equipment } from "./TabContent_Equipment";
import { TabContent_Documents } from "./TabContent_Documents";
import { TabContent_Inspections } from "./TabContent_Inspections";
//import { TabContent_Suppliers } from "./TabContent_Suppliers";
//import { TabContent_Users } from "./TabContent_Users";
//import { TabContent_ActivityHistory } from "./TabContent_ActivityHistory";
import { OmisObjectChip } from "../shared/OmisChips";
import {
    //mdiCheckboxMarkedCirclePlusOutline, mdiDotsHorizontal, mdiExportVariant,
    mdiFileDocument,
    //mdiFlagVariant,
    mdiInboxFull,
    //mdiPencil, mdiPlusBox,
    mdiPlusBoxOutline,
    mdiCheckboxMarkedOutline, mdiFileDocumentOutline, mdiGauge, mdiHammerWrench, mdiInformationOutline
} from "@mdi/js";
import { OmisDropDownMenu, OmisIconButton } from "../shared/OmisButtons";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { OmisCol, OmisContainer, OmisRow, OmisStack, OmisTabsWithIcon } from "../shared/OmisLayouts";
import { OmisDialog } from "../shared/OmisDisplays";
import AuthService from "../../services/auth.service";
import { TRole } from "../../constants/RoleConstants";
import urlEncryptService from "../../services/urlencrypt.service";
import { TabContent_ObjectAttributes } from "./TabContent_ObjectAttributes";

export function ObjectDetails() {
    const navigate = useNavigate();
    var {state} = useLocation();
    const [modalWidth, setModalWidth] = useState("md");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModaContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);
    const [tabIndex, setTabIndex] = useState(0);

    var location = useLocation();
    if (!state) {

        var encryptedString = "";
        if (location?.search) {
            encryptedString = location?.search.replace("#", "").replace("?", "").replaceAll("%22", "\"");
        } else if (location?.hash) {
            encryptedString = location?.hash.replace("#", "").replace("?", "").replaceAll("%22", "\"");
        }

        if (encryptedString && encryptedString != "") {
            state = urlEncryptService.decrypt(encryptedString);
        }
    }

    var addReportTitle = `${t("Action_Add")} ${t("Report_Title")}`,
        addDocumentTitle = t("AddDocuments"),
        addChecklistTitle = "Add checklist";

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        navigate(0);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    const tabs = [
        {
            iconpath: mdiInformationOutline,
            iconposition: "start",
            title: t("Details"),
            content:
                <Object_TabContent_Details state={state} />
        },
        {
            iconpath: mdiGauge,
            iconposition: "start",
        title: t("Dashboard"),
        content:
            <TabContent_Dashboard state={state} />,
        hideTab: !AuthService.hasRole(TRole.DashboardAvailable)
        },
        {
            iconpath: mdiInboxFull,
            iconposition: "start",
        title: t("FaultReports"),
        content:
            <TabContent_Reports state={state} />,
        hideTab: !AuthService.hasRole(TRole.FaultReportRead)
        },
        {
            iconpath: mdiHammerWrench,
            iconposition: "start",
        title: t("Equipment"),
        content:
            <TabContent_Equipment state={state} />,
        hideTab: !AuthService.hasRole(TRole.EquipmentRead)
        },
        {
            iconpath: mdiFileDocumentOutline,
            iconposition: "start",
        title: t("Documents"),
        content:
            <TabContent_Documents state={state} />,
            hideTab: !AuthService.hasRole(TRole.DMSObject),
            removeFromAddMenu: addDocumentTitle
        },
        {
            iconpath: mdiCheckboxMarkedOutline,
            iconposition: "start",
            title: t("Settings_TestDates"),
        content:
            <TabContent_Inspections state={state} />,
        hideTab: !AuthService.hasRole(TRole.TestdatesRead)
        },
        {
            iconpath: mdiCheckboxMarkedOutline,
            iconposition: "start",
            title: t("ObjectAttributes_Title"),
            content:
                <TabContent_ObjectAttributes {...state} noActions />,
            hideTab: !AuthService.hasRole(TRole.ObjectAttributes)
        },
        //{
        //title: t("Contractors"),
        //content:
        //    <TabContent_Suppliers state={state} />
        //},
        //{
        //title: t("User"),
        //content:
        //    <TabContent_Users state={ state }/>
        //},
        //{
        //title: t("Activity") + " " + t("FaultReport_History"),
        //content:
        //    <TabContent_ActivityHistory state={ state }/>
        //}
    ]


    const handleAddReportClick = () => {
        navigate("/reports/create/", { replace: true, state: { ...state } });
    };

    function PrepareModalTitle(props) {
        return (
            <OmisRow>
                <OmisCol xs={ 5 }><span>{ props.title }</span> { state.objectnrinternal ?
                    <OmisObjectChip text={state.objectnrinternal} objectnrinternal={state.objectnrinternal} objectid={ state.objectnrinternal }/> : null }
                </OmisCol>
            </OmisRow>);
    }

    const handleModalOpenForDropdownMenuActions = (title, content, e) => {
        var modalwidth = "md";

        setModalWidth(modalwidth);
        setModalTitle(
            <PrepareModalTitle modalwidth={ modalwidth } reportid={ state?.reportid } title={ title }/>);
        setModaContent(content);
        handleModalOpen();
    };

    const childItemsForActionsMenu = [
        //{
        //subMenuIcon: mdiPencil,
        //onClick: handleModalOpenForDropdownMenuActions.bind(this, t("Action_Edit"), "test"),
        //menuName: t("Action_Edit"),
        //hideMenuItem: !(AuthService.hasRole(TRole.ObjectEdit))
        //},
        //{
        //subMenuIcon: mdiPlusBox,
        //onClick: handleModalOpenForDropdownMenuActions.bind(this, "Add to project", "test"),
        //menuName: "Add to project",
        //hideMenuItem: !(AuthService.hasRole(TRole.ProjectEdit))
        //},
        //{
        //subMenuIcon: mdiExportVariant,
        //onClick: handleModalOpenForDropdownMenuActions.bind(this, t("ExcelExport"), "test"),
        //menuName: t("ExcelExport"),
        //},
    //    {
    //    subMenuIcon: mdiFlagVariant,
    //    onClick: null,
    //    menuName: t("Action_Archive"),
    //    hideMenuItem: !(AuthService.hasRole(TRole.ObjectArchive))
        //}
    ]

    const childItemsForAddMenu = [{
        subMenuIcon: mdiInboxFull,
        onClick: handleAddReportClick,
        menuName: addReportTitle,
        //routerLink: {RouterLink},
        //toLink: '/reports/create',
        hideMenuItem: !(AuthService.hasRole(TRole.FaultReportWrite))
    }, {
        subMenuIcon: mdiFileDocument,
        onClick: handleModalOpenForDropdownMenuActions.bind(this, addDocumentTitle,
            <AddDocuments cancelAction={handleModalCancel} closeAction={handleModalClose}{ ...state } />),
        menuName: addDocumentTitle,
        hideMenuItem: !(AuthService.hasRole(TRole.DMSObject))
        },
       //{
       //subMenuIcon: mdiCheckboxMarkedCirclePlusOutline,
       //onClick: handleModalOpenForDropdownMenuActions.bind(this, addChecklistTitle, "test"),
       //menuName: addChecklistTitle,
       //hideMenuItem: !(AuthService.hasRole(TRole.FaultReportObjectChecklist))
       //}
    ]

    return (
        <div className="omis-header-box">
            <OmisContainer fluid>
                <OmisStack direction="horizontal" gap={5}>
                    <div className="me-auto">
                        <OmisRow className="gy-5">
                            <OmisCol xs={1}>
                                <OmisIconButton id={"backButton"} iconcontent={
                                    <ArrowBackIcon fontSize="inherit" />} onClick={() => { navigate(location.key !== "default" ? -1 : "/objects"); }}
                                />
                            </OmisCol>
                            <OmisCol xs={11}>
                                <div className="omis-header-title-details" style={{ paddingLeft: '1.5em' }}>{t("Object_Details_Title")} #{state?.objectnrinternal}</div>
                            </OmisCol>
                        </OmisRow>
                    </div>
                    <div className="ms-auto">
                        <OmisRow>
                            {/*<OmisCol xs={5}>*/}
                                {/*<OmisDropDownMenu menuicon={mdiDotsHorizontal} childitems={childItemsForActionsMenu} />*/}
                            {/*</OmisCol>*/}
                            <OmisCol xs={7}>
                                <OmisDropDownMenu id={"addMenuDropdown"} menutitle={t("Action_Add")} menuicon={mdiPlusBoxOutline} childitems={childItemsForAddMenu.filter(f => f.menuName !== tabs[tabIndex].removeFromAddMenu)} />
                            </OmisCol>
                        </OmisRow>

                    </div>
                </OmisStack>
            </OmisContainer>
            <OmisTabsWithIcon tabs={tabs} onChange={setTabIndex} />
            <OmisDialog onClose={handleModalCancel } open={ modalOpen }
                        maxWidth={ modalWidth }
                        title={ modalTitle }
                        content={ modalContent }
            />
        </div>
    );
}

function AddDocuments(props) {
    return (
        <OmisRow>
            <OmisCol xs={12}><DocumentUpload handleModalCancel={props.cancelAction} fileUploadCallback={props.closeAction} id={props.objectid} referer={DMSDocumentReferer.Object} /></OmisCol>
        </OmisRow>);
}