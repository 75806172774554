import React, { useState } from "react";
import { t } from "i18next";
import httpclientService from "../../../services/httpclient.service";
import { OmisGrid } from "../../shared/OmisGrids";
import { OmisButtonNewItem } from "../../shared/OmisButtons";
import { OmisDialog } from "../../shared/OmisDisplays";
import AddUserRoles from "./AddUserRoles";

export function TabContent_UserRoles(props) {
    var url = "/api/profile/getuserroles";
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    if (props.userid) {
        url = `${url}?userID=${props.userid}`;
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    function handleAddUserRoles() {
        setModalTitle(`${t("Action_New")} ${t("Settings_Roles")}`);
        setModalContent(<AddUserRoles onClose={handleModalClose} onCancel={handleModalCancel} userID={props.userid} userIDForRemove={props.userid } />);
        handleModalOpen();
    }

    const deleteContactPerson = (rowID) => {
        httpclientService.post(`api/profile/deleteuserrole?userID=${props.userid}&roleID=${rowID}`).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    };

    return (
        <>
            <OmisGrid
                headerText={t("Settings_Roles")}
                apiUrl={url}
                noExportButton
                newButton={<OmisButtonNewItem id={"newItemButton"} text={t("Action_New")} onClick={handleAddUserRoles} />}
                actiondropdown={{ delete: deleteContactPerson }}
                change={changeCounter}
                {...props} />

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"md"}
                title={modalTitle}
                content={modalContent}
                contentHeight={300}
            />
        </>
        );

}