import React, { useRef, useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, getElementsAtEvent } from 'react-chartjs-2';
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend, ChartDataLabels
);

export function OmisVerticalBarChart(props) {
    //https://react-chartjs-2.js.org/examples/vertical-bar-chart/

    const chartRef = useRef();
    const onClick = (event) => {
        var clickedBar = getElementsAtEvent(chartRef.current, event);
        if (clickedBar && clickedBar.length > 0) {
            if (props.onClick) {
                props.onClick(clickedBar[0].index);
            }
        }
    }

    useEffect(() => {
        function handleResize() {
            if (chartRef.current) {
                chartRef.current._responsiveListeners.resize();
            }
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const options = {
        indexAxis: 'x',
        responsive: true,
        onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement[0] && props.onClick ? 'pointer' : 'default';
        },
        plugins: {
            legend: {
                display: props.barDatas.legendDisplay ? props.barDatas.legendDisplay : false,
                position: props.barDatas.legendPosition ? props.barDatas.legendPosition :"top",
            },
            title: {
                align: props.barDatas.titleAlign ? props.barDatas.titleAlign : "start",
                display: props.barDatas.titleText ? true:false,
                text: props.barDatas.titleText ? props.barDatas.titleText :"",
            },
            maintainAspectRatio: false,
            datalabels: props.barDatas.datalabels,

        }
    };
    //Max dataset val is 885
    return <Bar ref={chartRef} options={options} data={props.barDatas} height={100} onClick={onClick} />;
}

export function OmisHorizontalBarChart(props) {
    //https://react-chartjs-2.js.org/examples/vertical-bar-chart/
    const chartRef = useRef();
    const onClick = (event) => {
        var clickedBar = getElementsAtEvent(chartRef.current, event);
        if (clickedBar && clickedBar.length > 0) {
            if (props.onClick) {
                props.onClick(clickedBar[0].index);
            }
        }
    }

    const options = {
        indexAxis: 'y',
        responsive: true,
        onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement[0] && props.onClick ? 'pointer' : 'default';
        },
        plugins: {
            legend: {
                display: props.barDatas.legendDisplay ? props.barDatas.legendDisplay : false,
                position: props.barDatas.legendPosition ? props.barDatas.legendPosition : "right",
            },
            title: {
                align: props.barDatas.titleAlign ? props.barDatas.titleAlign:"start",
                display: props.barDatas.titleText ? true : false,
                text: props.barDatas.titleText ? props.barDatas.titleText : "",
            },
            maintainAspectRatio: false,
            datalabels: props.barDatas.datalabels
        },
        scales: props.scales ? props.scales : null,

    };

    //Max dataset val is 885

    useEffect(() => {
        function handleResize() {
            if (chartRef.current) {
                chartRef.current._responsiveListeners.resize();
            }
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Bar ref={chartRef} options={options} data={props.barDatas} height={props.barHeight ? props.barHeight : 500} onClick={onClick} />
    );
}