import React, { useEffect, useState } from "react";
import { t } from "i18next";
import httpclientService from "../../services/httpclient.service";
import { OmisEditTextBoxNoForm } from "../shared/OmisInputs";
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";

export function TabContent_EquiAttributes(props) {
    const { state } = { ...props };
    const [attributesList, setAttributesList] = useState([]);
    const [changeCounter, setChangeCounter] = useState(0);

    useEffect(() => {
        LoadEquiAttributes();
    }, [changeCounter]);

    function LoadEquiAttributes() {
        httpclientService.get(`/api/equipments/getequiattributebyequipid?equipID=${state?.equipid}`).then((response) => {
            if (response) {
                setAttributesList(response);
            }
        });
    }

    function handleSave(orderNr, value) {

        httpclientService.post("/api/equipments/saveequipattributeedit",
            JSON.stringify({
                equiID: state?.equipid ?? 0,
                orderNr: orderNr ?? 0,
                attributeValue: value ?? 0,
            })
        ).then((response) => {
            setChangeCounter((prevState) => prevState + 1);
        });
    }

    return (
        <>
            <OmisContainer fluid>
                <OmisRow>
                    {
                        attributesList.length > 0 ?
                            attributesList.map((item, index) => (
                                <OmisCol xs={4} container direction={"row"} key={index}>
                                    <OmisCol xs={10}>
                                        <OmisEditTextBoxNoForm labeltext={item.displayText} value={item.attributeValue ?? ""} placeholder={item.displayText} onSave={handleSave.bind(this, item.orderNr)} />
                                    </OmisCol>
                                </OmisCol>
                            ))
                            :
                            <strong>{t("Grid_Empty")}</strong>
                    }
                </OmisRow>              
            </OmisContainer>
        </>
    );
}