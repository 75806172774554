import React, { useEffect, useState } from "react";
import httpclientService from "../../services/httpclient.service";
import { Card } from "@mui/material";
import { SortStringArray } from "../shared/Shared";
import { t } from "i18next";
import { OmisDialog } from "../shared/OmisDisplays";
import { OmisDropdownMultiselectV2 } from "../shared/OmisDropdowns";
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";
import { Link } from "@mui/material";

export function Roles(props) {

    const [roles, setRoles] = useState([]);
    const [selectedItems, setSelectedItems] = useState();
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        var url = `api/profile/getroleslist`;
        if (props.userIDForRemove) {
            url = `${url}?userIDForRemove=${props.userIDForRemove}`;
        }

        httpclientService.get(url).then((response) => {
            if (response) {
                setRoles(SortStringArray(
                    response.map((item) => ({
                        key: item.id,
                        content: item.nameRole,
                        displayText: item.nameRole,
                        isSelected: props.selectedRoles ? props.selectedRoles.filter((f) => (f.id ? f.id === item.id : f === item.id)).length>0 : false
                    }))
                )
                );
            }
        });
    }, []);

    useEffect(() => {
        if (props.selectedRoles && props.selectedRoles.length > 0) {
            setSelectedItems(props.selectedRoles.map((item)=> item.id ? item.id : item));
        }
    }, [props.selectedRoles]);

    const handleSelectedListItems = (event) => {
        const {
            target: { value },
        } = event;

        // On autofill we get a stringified value.
        var _newArr = typeof value === 'string' ? value.split(',') : value;//gives selected values in array
        setSelectedItems(_newArr.sort((a, b) => a - b));
        if (props.handleSelectedRoles) {
            props.handleSelectedRoles(_newArr.sort((a, b) => a - b).map((item) =>
                ({ id: item, nameRole: roles.filter((f)=> f.key === item)[0]?.content ?? "" })
            ));
        }
    }


    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };


    return (
        props.isEdit ?
            //<OmisCheckboxList checked={selectedItems} itemlist={roles} listAttr={["key", "content"]} onChange={handleSelectedListItems} showAllButton showSearchBox withCardView />
            <OmisDropdownMultiselectV2 id="roles" name={"roles"} className="rolesDropDown" items={roles} onChange={handleSelectedListItems} value={selectedItems} labeltext={t("Settings_Roles")} />

            :
            <>

                <OmisRow>
                    <OmisCol xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
                        {
                            props.selectedRoles && props.selectedRoles?.length > 5 ?
                                <Link
                                    component="button"
                                    underline="none"
                                    variant="body2"
                                    onClick={handleOpen}
                                >
                                    {`${t("Criteria_DisplayAll")} (${props.selectedRoles.length})`}
                                </Link>
                                :
                                null
                        }
                    </OmisCol>
                    <OmisCol xs={12}>
                        <Card variant={"outlined"}>
                            <RenderRolesList shortList={true} listLimit={5} data={props.selectedRoles} />
                        </Card>
                    </OmisCol>
                </OmisRow>

                <OmisDialog onClose={handleClose} open={modalOpen}
                    title={`${t("Settings_Roles")}`}
                    content={<RenderRolesList shortList={false} listLimit={5} data={props.selectedRoles} />}
                    maxWidth={"md"}
                />
            </>

    );
}


function RenderRolesList(props) {

    var limit = props.shortList ? props.listLimit : (props.data ? props.data.length : 0);

    return (
        props.data && props.data.length > 0 ?
            <>
                {
                    props.data.map((d, index) =>
                        index < limit ?
                            <OmisContainer key={`${index}_RolesList`} fluid style={{ display: 'flex', justifyContent: 'start', alignItems: 'left' }}>
                                <OmisCol xs={12}>
                                    {
                                        d.nameRole ?
                                        <OmisCol xs={12}><p><span className=" roleTag">{d.nameRole}</span></p></OmisCol>
                                            :
                                        <OmisCol xs={12}><p><span className=" roleTag">{d.content}</span></p></OmisCol>
                                    }
                                </OmisCol>
                            </OmisContainer>
                            :
                            null
                    )
                }
            </>
            : null
    );
}