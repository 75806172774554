import React, { useEffect, useRef, useState } from "react";
import httpclientService from "../../services/httpclient.service";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { t } from "i18next";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit } from "../shared/OmisButtons";
import { OmisButtonSecondary } from "../shared/OmisButtons";
import { OmisDropdown } from "../shared/OmisDropdowns";
import { OmisDateRangePicker } from "../shared/OmisInputs";
import { OmisLabel } from "../shared/OmisDisplays";
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";

var api = "/api/profile/";

export default function SetUserReplacement(props) {

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [toUsers, setToUsers] = useState([]);

    const formRef = useRef();
    const [initialValues, setInitialValues] = useState({
        userReplacementFrom: props.userID ?? "0",
        userReplacementTo: "0",
        fromDate: null,
        toDate: null
    });

    useEffect(() => {
        httpclientService.get(`${api}findalluserslist`).then((response) => {
            if (response && response.length) {
                setUsers(response.filter((f) => f.key !== `${props.userID}`).map((user) => ({
                    key: user.key, displayText: user.displayText
                })));

                setToUsers(response.filter((f) => f.key !== `${props.userID}`).map((user) => ({
                    key: user.key, displayText: user.displayText
                })));
            }
        });
    }, []);

    function saveReplacement(values) {
        httpclientService.post(`${api}saveuserreplacement`, JSON.stringify(values)).then((response) => {
            if (response) {
                if (props.handleModalClose) {
                    props.handleModalClose();
                } else {
                    navigate("/users");
                }
            }
        });
    }

    function rangeOnChange(val) {
        formRef.current.setFieldValue("fromDate", val.fromDate);
        formRef.current.setFieldValue("toDate", val.toDate);
    }

    const validationSchema = Yup.object({
        userReplacementFrom: Yup.number()
            .moreThan(0, t("GenericRequiredText"))
            .required(t("GenericRequiredText")),
        userReplacementTo: Yup.number()
            .moreThan(0, t("GenericRequiredText"))
            .required(t("GenericRequiredText")),
        fromDate: Yup.date()
            .required(t("GenericRequiredText")),
        toDate: Yup.date()
            .required(t("GenericRequiredText")),
    });

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                    saveReplacement(values)
                }}
            >
                {formik => (
                    <Form>
                        <OmisContainer fluid>
                            <OmisRow>
                                {
                                    props.userID ?
                                        <OmisCol xs={6}>
                                            <OmisLabel label={t(`${t("From")} ${t("User")}`)} />
                                            <strong>{props.fromuser ?? ''}</strong>
                                        </OmisCol>
                                        :
                                        <OmisCol xs={6}>
                                            <OmisDropdown size={"small"} id={"users"} name={"userReplacementFrom"} labeltext={t(`${t("From")} ${t("User")}`)} items={users.map(x => { x.disabled = x.key === formik.values.userReplacementTo; return x; })} required />  
                                        </OmisCol>
                                }
                                <OmisCol xs={6}>
                                    <OmisDropdown size={"small"} id={"userTo"} name={"userReplacementTo"} items={toUsers.map(x => { x.disabled = x.key === formik.values.userReplacementFrom; return x; })} labeltext={t(`${t("To")} ${t("User")}`)} required />                                    
                                </OmisCol>
                                <OmisCol xs={12}>
                                    <OmisDateRangePicker fromDate={initialValues.fromDate} toDate={initialValues.toDate } onChange={rangeOnChange} />
                                </OmisCol>

                                <OmisCol xs={6}>
                                    {
                                        props.handleModalCancel ?
                                            <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={props.handleModalCancel}></OmisButtonSecondary>
                                            :
                                            <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} component={RouterLink} to={"/users"}></OmisButtonSecondary>
                                    }
                                </OmisCol>

                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>)}
            </Formik>
        </>
    );
};