import React, { useEffect, useRef, useState } from "react";
import httpclientService from "../../services/httpclient.service";
import * as Yup from "yup";
import { t } from "i18next";
import { Form, Formik } from "formik";
import { OmisButtonPrimary, OmisButtonPrimarySubmit, OmisButtonSecondary, OmisProgress } from "../shared/OmisButtons";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardActionArea, CardContent } from "@mui/material";
import { OmisDateTimePicker, OmisTextArea, OmisTextBox } from "../shared/OmisInputs";
import { OmisGrid } from "../shared/OmisGrids";
import { CheckCircle, HighlightOff } from "@mui/icons-material";
import { OmisDropdown } from "../shared/OmisDropdowns";
import { TRole } from "../../constants/RoleConstants";
import AuthService from "../../services/auth.service";
import { OmisDialog, OmisLabel } from "../shared/OmisDisplays";
import { FaultReportMessage } from "../../constants/FaultReportMessageConstants";
import {CustomerCheck} from "../shared/CustomerCheck";
import {TCustomer} from "../../constants/TCustomer";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../shared/OmisLayouts";

export function ReportsReclassify(props) {

    var { state } = useLocation();
    const SelectionStep = {
        Object: 1,
        FaultReportKind: 2,
        FaultEquiClassGroup: 3,
        FaultEquiClassDef: 4,
        Equipment: 5,
        FaultKind: 6,
        Done: 7
    }

    const formRef = useRef();

    const [modalWidth, setModalWidth] = useState("md");
    const [modalOpen, setModalOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [selectionStep, setSelectionStep] = useState(1);

    const [selectedObjectID, setSelectedObjectID] = useState(null);
    const [selectedFaultReportKindID, setSelectedFaultReportKindID] = useState(null);
    const [selectedFaultEquiClassGroupID, setSelectedFaultEquiClassGroupID] = useState(null);
    const [selectedFaultEquiClassDefID, setSelectedFaultEquiClassDefID] = useState(null);
    const [selectedEquipmentID, setSelectedEquipmentID] = useState(null);
    const [selectedFaultKindID, setSelectedFaultKindID] = useState(null);

    const [selectedObjectText, setSelectedObjectText] = useState('');
    const [selectedFaultReportKindText, setSelectedFaultReportKindText] = useState('');
    const [selectedFaultEquiClassGroupText, setSelectedFaultEquiClassGroupText] = useState('');
    const [selectedFaultEquiClassDefText, setSelectedFaultEquiClassDefText] = useState('');
    const [selectedEquipmentText, setSelectedEquipmentText] = useState('');
    const [selectedFaultKindText, setSelectedFaultKindText] = useState('');

    const [equipmentHidden, setEquipmentHidden] = useState(false);

    const [imminentDanger, setImminentDanger] = useState(false);
    const [selectedCompanySite, setSelectedCompanySite] = useState({ value: 0, name: '' });
    const companySiteSelectable = useState(AuthService.hasRole(TRole.CompanySite_Selectable)
                                                                            && state.selectedMessage === FaultReportMessage.MANUAL_REQUEST);
    const [companySites, setCompanySites] = useState([]);

    const [maintenanceDetails, setMaintenanceDetails] = useState('');
    const [responseTime, setResponseTime] = useState('');

    const currentUser = AuthService.getCurrentUser();

    const [schema, setSchema] = useState({
        announciator: Yup.string()
            .required(t("GenericRequiredText"))
            .max(100, t('Error_TextTooLong', { 1: 100 })),
        faultdescription: Yup.string()
            .required(t("GenericRequiredText"))
            .max(4000, t('Error_TextTooLong', { 1: 4000 })),
        objectid: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        faultkindid: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        equipmentid: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        marginalcosts: Yup.number()
            .nullable()
    });

    const navigate = useNavigate();

    function handleReclassifyReport(values) {

        handleModalOpen();
        if (values.companysiteid === '') {
            values.companysiteid = 0;
        }
        
        httpclientService.post("/api/reports/savereportreclassify", values).then((response) => {
            console.log(response.statusCode);
            handleModalClose();
            if (response.statusCode === 200) {
                navigate("/reports");
            }
        });
    }

    useEffect(() => {
        clearSelection(null);
    }, [selectionStep]);


    const [initialValues, setInitialValues] = useState({
        announciator:  '',
        faultReportID: state?.FaultReportID,
        faultdescription: '',
        directorderdescription: '',
        reportnrextern:'',
        marginalcosts: null,
        objectid: selectedObjectID,
        faultreportkindid: selectedFaultReportKindID,
        faultequiclassgroupid: selectedFaultEquiClassGroupID,
        faultequiclassdefid: selectedFaultEquiClassDefID,
        equipmentid: selectedEquipmentID,
        faultkindid: selectedFaultKindID,
        companysiteid: '',
        datetocompleteaction: null,
        selectedMessage: state?.selectedMessage
    });

    useEffect(() => {
        httpclientService.get(`/api/reports/reclassificationdetails?reportid=${state?.FaultReportID}`).then((response) => {
            if (response) {
                setInitialValues({
                    announciator: response.announciator ? response.announciator : currentUser ? currentUser.fullName : '',
                    faultReportID: response.faultReportID,
                    faultdescription: response.faultdescription,
                    directorderdescription: '',
                    reportnrextern: response.reportnrextern ? response.reportnrextern:'',
                    marginalcosts: null,
                    objectid: response.objectid,
                    faultreportkindid: response.faultreportkindid,
                    faultequiclassgroupid: response.faultequiclassgroupid,
                    faultequiclassdefid: response.faultequiclassdefid,
                    equipmentid: response.equipmentid,
                    faultkindid: response.faultkindid,
                    companysiteid: response.companysiteid,
                    datetocompleteaction: response.datetocompleteaction ? new Date(response.datetocompleteaction) : null,
                    selectedMessage: state?.selectedMessage
                });
                setImminentDanger(response.imminentdanger);

                setSelectedObjectID(response.objectid);
                setSelectedFaultReportKindID(response.faultreportkindid);
                setSelectedFaultEquiClassGroupID(response.faultequiclassgroupid);
                setSelectedFaultEquiClassDefID(response.faultequiclassdefid);
                setSelectedEquipmentID(response.equipmentid);
                setSelectedFaultKindID(response.faultkindid);

                setSelectedObjectText(response.objectnrinternal ?? '');
                setSelectedFaultReportKindText(response.kinddescription ?? '');
                setSelectedFaultEquiClassGroupText(response.faultequiclassgroupdesc ?? '');
                setSelectedFaultEquiClassDefText(response.faultequiclassdefdesc ?? '');
                setSelectedEquipmentText(response.equidescription ?? '');
                if (!response.equipmentid) {
                    setEquipmentHidden(true);
                    schema.equipmentid = null;
                }
                setSelectedFaultKindText(response.faultkinddesc ?? '');
                setSelectionStep(SelectionStep.Done)
            }
        });
    }, []);

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        if (currentStep === 2 ) {

            if (companySiteSelectable) {
                handleModalOpen();
                httpclientService.get(`/api/reports/getsuppliers`).then((response) => {
                    if (response) {
                        setCompanySites(response.map((item) => ({
                            key: item.id,
                            displayText: item.companyName
                        })));
                        handleModalClose();
                    }
                });
            }

            if (selectedObjectID && selectedFaultEquiClassDefID && selectedFaultReportKindID) {

                var url = `/api/reports/getgenerateddetails?objectID=${selectedObjectID}&faultKindID=${selectedFaultKindID}&faultEquiClassDefID=${selectedFaultEquiClassDefID}&faultReportKindID=${selectedFaultReportKindID}&imminentDanger=${imminentDanger}`;
                if (selectedEquipmentID) {
                    url = url + `&equipmentID=${selectedEquipmentID}`;
                }
                if (initialValues.companysiteid) {
                    url = url + `&companysiteid=${initialValues.companysiteid}`;
                }

                httpclientService.get(url).then((response) => {
                    if (response) {
                        if (response.companySite) {
                            setSelectedCompanySite(response.companySite);
                            formRef.current.setFieldValue("companysiteid", response.companySite.value ?? 0);
                        }
                        if (response.maintenanceDetails) {
                            setMaintenanceDetails(response.maintenanceDetails);
                        }
                        if (response.responseTime) {
                            setResponseTime(response.responseTime);
                        }
                    }
                });
            }
        }
    }, [currentStep]);

    const validationSchema = Yup.object(schema);

    return (<>
        <Formik
            innerRef={formRef}
            validateOnMount
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                handleReclassifyReport(values)
            }}
            enableReinitialize={true}
        >
            {formik => (

                <Form>
                    <div className="omis-header-box">
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={6} >
                                    <h1>
                                        {(() => {
                                            switch (state?.selectedMessage) {
                                                case parseInt(FaultReportMessage.RECLASSIFY):
                                                    return t("Reclassify");
                                                case parseInt(FaultReportMessage.MANUAL_REQUEST):
                                                    return t("Commission_Contractor");
                                                default:
                                                    return "";
                                            }
                                        })()}
                                    </h1>
                                </OmisCol>
                                <OmisCol style={{ display: 'flex', justifyContent: 'right' }} xs={6}>
                                    {currentStep === 1 ? <>
                                        <OmisStack direction="horizontal" gap={1}>
                                            <div className="p-2"><OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={() => state.fromDetails ? navigate("/reports/details", { replace: true, state: { reportid: state.FaultReportID, objectid: state.ObjectID, equipid: state.EquipID } }) : navigate("/reports")}></OmisButtonSecondary></div>
                                            <div className="p-2"><OmisButtonPrimary id={"nextStepButton"} disabled={!formik.isValid} text={t("NextStep")} onClick={() => setCurrentStep(2)}></OmisButtonPrimary></div>
                                        </OmisStack>

                                    </> : <>
                                        <OmisStack direction="horizontal" gap={1}>
                                            <div className="p-2"><OmisButtonSecondary id={"previousStepButton"} disabled={!formik.isValid} text={t("PreviousStep")} onClick={() => setCurrentStep(1)}></OmisButtonSecondary></div>
                                            <div className="p-2"><OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={state.selectedMessage === FaultReportMessage.MANUAL_REQUEST ? t("Send_Commission") : t("Reclassify")}></OmisButtonPrimarySubmit></div>
                                        </OmisStack>
                                    </>}
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol> <OmisProgress variant="determinate" value={formik.isValid ? 100 : 0} />(1) Base Details </OmisCol>
                                <OmisCol> <OmisProgress variant="determinate" value={currentStep === 2 && formik.isValid ? 100 : 0} />(2) Additional Details </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                        
                    </div>
                    <h3>
                        {t("Details")}
                    </h3>
                    {
                        currentStep === 1 ?
                            <Card variant={"outlined"}>
                                <OmisStack gap={3}>
                                    <OmisCol xs={12}>
                                        <FirstStep />
                                    </OmisCol>
                                    <OmisCol xs={12}>
                                        <FaultCatalogueGrid step={selectionStep} />
                                    </OmisCol>
                                </OmisStack>

                                <br />
                                <br />
                            </Card> : 
                            <Card variant={"outlined"}>
                                <OmisCol xs={12}>
                                    <SecondStep companySites={companySites} selectedCompanySite={selectedCompanySite} />
                                </OmisCol>
                            </Card>
                    }
                    <br />
                    <br />
                </Form>
                )}
        </Formik>

        {/* formik rerenders on every change / blur, so separate not needed components from form*/}
        <OmisDialog open={modalOpen}
            maxWidth={modalWidth}
            title={""}
            content={<OmisProgress />}
        />
    </>);

    //region helper functions

    function handleObjectSelect(selection) {
        setSelectionStep(SelectionStep.FaultReportKind);
        updateSelection(selection);
    }

    function handleFaultReportKindSelect(selection) {
        setSelectionStep(SelectionStep.FaultEquiClassGroup);
        updateSelection(selection);
    }

    function handleFaultEquiClassGroupSelect(selection) {
        setSelectionStep(SelectionStep.FaultEquiClassDef);
        updateSelection(selection);
    }

    function handleFaultEquiClassDefSelect(selection) {
        setSelectionStep(SelectionStep.Equipment);
        updateSelection(selection);
    }

    function handleEquipmentSelect(selection) {
        setSelectionStep(SelectionStep.FaultKind);
        updateSelection(selection);
    }

    function handleFaultKindSelect(selection) {
        setSelectionStep(SelectionStep.Done);
        updateSelection(selection);
    }

    function handleEquipmentEmpty() {
        setSelectionStep(SelectionStep.FaultKind);
        setEquipmentHidden(true);
        schema.equipmentid = null;
    }

    function clearSelection(row) {
        var clearObject = selectionStep < 2;
        var clearFaultReportKind = selectionStep < 3;
        var clearFaultEquiClassGroup = selectionStep < 4;
        var clearFaultEquiClassDef = selectionStep < 5;
        var clearEquipment = selectionStep < 6;
        var clearFaultKind = selectionStep < 7;

        if (clearObject) {
            setSelectedObjectID(null);
            setSelectedObjectText('');
            formRef.current.setFieldValue("objectid", null);
            setEquipmentHidden(false);
        }

        if (clearFaultReportKind) {
            setSelectedFaultReportKindID(null);
            setSelectedFaultReportKindText('');
            formRef.current.setFieldValue("faultreportkindid", null);
            setEquipmentHidden(false);
        }

        if (clearFaultEquiClassGroup) {
            setSelectedFaultEquiClassGroupID(null);
            setSelectedFaultEquiClassGroupText('');
            formRef.current.setFieldValue("faultequiclassgroupid", null);
            setEquipmentHidden(false);
        }

        if (clearFaultEquiClassDef) {
            setSelectedFaultEquiClassDefID(null);
            setSelectedFaultEquiClassDefText('');
            formRef.current.setFieldValue("faultequiclassdefid", null);
            setEquipmentHidden(false);
        }

        if (clearEquipment) {
            setSelectedEquipmentID(null);
            setSelectedEquipmentText('');
            formRef.current.setFieldValue("equipmentid", null);
        }

        if (clearFaultKind) {
            setSelectedFaultKindID(null);
            setSelectedFaultKindText('');
            formRef.current.setFieldValue("faultkindid", null);
        }
    }

    function updateSelection(row) {
        switch (selectionStep) {
            case SelectionStep.Object:
                setSelectedObjectID(row?.id ?? null);
                setSelectedObjectText(row?.objectnrinternal ?? '');
                formRef.current.setFieldValue("objectid", row?.id ?? null);
                break;
            case SelectionStep.FaultReportKind:
                setSelectedFaultReportKindID(row?.id ?? null);
                setSelectedFaultReportKindText(row?.kinddescription ?? '');
                formRef.current.setFieldValue("faultreportkindid", row?.id ?? null);
                break;
            case SelectionStep.FaultEquiClassGroup:
                setSelectedFaultEquiClassGroupID(row?.id ?? null);
                setSelectedFaultEquiClassGroupText(row?.faultequiclassgroupdesc ?? '');
                formRef.current.setFieldValue("faultequiclassgroupid", row?.id ?? null);
                break;
            case SelectionStep.FaultEquiClassDef:
                setSelectedFaultEquiClassDefID(row?.id ?? null);
                setSelectedFaultEquiClassDefText(row?.faultequiclassdefdesc ?? '');
                formRef.current.setFieldValue("faultequiclassdefid", row?.id ?? null);
                break;
            case SelectionStep.Equipment:
                setSelectedEquipmentID(row?.id ?? null);
                setSelectedEquipmentText(row?.equidescription ?? '');
                formRef.current.setFieldValue("equipmentid", row?.id ?? null);
                break;
            case SelectionStep.FaultKind:
                setSelectedFaultKindID(row?.id ?? null);
                setSelectedFaultKindText(row?.faultkinddesc ?? row.faultKindDesc ?? '');
                formRef.current.setFieldValue("faultkindid", row?.id ?? null);
                break;
            default:
                break;
        }
    }

    //endregion

    function FaultCatalogueGrid(props) {
        const {step} = {...props};

        switch (step) {
            case SelectionStep.Object:
                return (
                    <OmisGrid rowclickCallback={ handleObjectSelect } noButtons noToolbar
                              apiUrl={ "/api/objects/getobjectlist" }/>);
            case SelectionStep.FaultReportKind:
                return (
                    <OmisGrid rowclickCallback={ handleFaultReportKindSelect } noButtons noToolbar
                              apiUrl={ `/api/settings/faultreportkind/getfaultreportkinds?objectid=${ selectedObjectID }` }/>);
            case SelectionStep.FaultEquiClassGroup:
                return (
                    <OmisGrid rowclickCallback={ handleFaultEquiClassGroupSelect } noButtons noToolbar
                              apiUrl={ `/api/settings/faultequiclassgroup/getfaultequiclassgroups?faultreportkindid=${ selectedFaultReportKindID }` }/>);
            case SelectionStep.FaultEquiClassDef:
                return (
                    <OmisGrid rowclickCallback={ handleFaultEquiClassDefSelect } noButtons noToolbar
                              apiUrl={ `/api/settings/faultequiclassdef/getfaultequiclassdefs?faultequiclassgroupid=${ selectedFaultEquiClassGroupID }` }/>);
            case SelectionStep.Equipment:
                return (
                    <OmisGrid rowclickCallback={ handleEquipmentSelect } noButtons noToolbar
                              apiUrl={ `/api/equipments/getequipmentcreatereport?objectid=${ selectedObjectID }&faultequiclassdefid=${ selectedFaultEquiClassDefID }` }
                              noEntriesCallback={ handleEquipmentEmpty }
                    />);
            case SelectionStep.FaultKind:
                return (
                    <OmisGrid rowclickCallback={ handleFaultKindSelect } noButtons noToolbar
                        apiUrl={`/api/settings/faultkind/getfaultkinds?faultequiclassdefid=${selectedFaultEquiClassDefID}`} />);
            default:
                break;
        }
    }

    function FirstStep(props) {

        return (
            <>

                <OmisStack direction="horizontal" gap={3}>
                    <OmisContainer fluid className="faultReportCreateDetailsBox">
                        <OmisRow>
                            <OmisCol xs={12}>
                                <OmisTextBox labeltext={t("Annunciator")} name={"announciator"} placeholder={t("Annunciator")} disabled required />
                            </OmisCol>
                            <OmisCol xs={12}>
                                <OmisTextArea labeltext={t("FaultDescription")} name={"faultdescription"} placeholder={t("FaultDescription")} disabled required />
                            </OmisCol>
                        </OmisRow>
                    </OmisContainer>
                    {
                        state?.selectedMessage === FaultReportMessage.MANUAL_REQUEST ?
                            <OmisContainer fluid style={{ display: 'flex', justifyContent: 'center' }} className="faultReportCreateDetailsBox">
                                <OmisRow>
                                    <OmisCol xs={12}>
                                        <OmisTextArea labeltext={t("Description")} name={"directorderdescription"} />
                                    </OmisCol>
                                    <CustomerCheck allowed={[TCustomer.CUSTOMER_SUPPORT]}>
                                        <OmisCol xs={12}>
                                            <OmisTextBox labeltext={t("FaultReport_ReportNrExtern")} name={"reportnrextern"} />
                                        </OmisCol>
                                    </CustomerCheck>
                                    <CustomerCheck denied={[TCustomer.CUSTOMER_SUPPORT]}>
                                        <OmisCol xs={12}>
                                            <OmisTextBox labeltext={t("Tender_MarginalCosts")} name={"marginalcosts"} placeholder={t("Tender_MarginalCosts")} />
                                        </OmisCol>
                                    </CustomerCheck>
                                </OmisRow>
                            </OmisContainer>
                            :
                            <OmisContainer fluid>
                            </OmisContainer>
                    }
                    
                </OmisStack>
                <OmisContainer fluid>
                    <OmisRow>
                        <OmisCol xs={12}>
                            <h5>
                                {t("FaultReport_Details")}
                            </h5>
                        </OmisCol>
                        <OmisCol xs={12} className="faultReportCreateDetailsBox">
                            <OmisStack direction="horizontal">
                                <OmisCol xs={4}>
                                    <SelectedFaultCatalogueCard disabled
                                        text={`Objekt Details (${selectedObjectText})`}
                                        id={selectedObjectID}
                                        selectionstep={SelectionStep.Object}
                                        required
                                    />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <SelectedFaultCatalogueCard
                                        text={`Meldungsart (${selectedFaultReportKindText})`}
                                        id={selectedFaultReportKindID}
                                        selectionstep={SelectionStep.FaultReportKind}
                                    />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <SelectedFaultCatalogueCard
                                        text={`Bereich (${selectedFaultEquiClassGroupText})`}
                                        id={selectedFaultEquiClassGroupID}
                                        selectionstep={SelectionStep.FaultEquiClassGroup}
                                    />
                                </OmisCol>
                            </OmisStack>
                            <OmisStack direction="horizontal" gap={1}>
                                <OmisCol xs={4}>
                                    <SelectedFaultCatalogueCard
                                        text={`Fehlerklasse (${selectedFaultEquiClassDefText})`}
                                        id={selectedFaultEquiClassDefID}
                                        selectionstep={SelectionStep.FaultEquiClassDef}
                                    /> </OmisCol>{equipmentHidden ? null :
                                        <OmisCol xs={4}>
                                            <SelectedFaultCatalogueCard
                                                text={`Equipment (${selectedEquipmentText})`}
                                                id={selectedEquipmentID}
                                                selectionstep={SelectionStep.Equipment}
                                                required
                                            />
                                        </OmisCol>}
                                <OmisCol xs={4}>
                                    <SelectedFaultCatalogueCard
                                        text={`Fehlerart (${selectedFaultKindText})`}
                                        id={selectedFaultKindID}
                                        selectionstep={SelectionStep.FaultKind}
                                        required
                                    />
                                </OmisCol>
                            </OmisStack>
                        </OmisCol>
                    </OmisRow>
                </OmisContainer>
            </>
        )
    }

    function SecondStep(props) {

        return (

            <OmisStack direction="horizontal" gap={3}>
                <Card className="createReportStepTwoCardBox">
                    <OmisContainer fluid style={{ display: 'flex', justifyContent: 'center' }} >
                        <OmisRow>
                            <OmisCol xs={12}>
                                <strong>{t("FaultReport_DateToCompleteAction")}</strong>
                            </OmisCol>

                            <OmisCol xs={12} className="faultReportCreateDetailsBox">
                                <OmisDateTimePicker name="datetocompleteaction" />
                            </OmisCol>
                        </OmisRow>
                    </OmisContainer>
                </Card>
                <Card className="createReportStepTwoCardBox">
                    <OmisContainer fluid style={{ display: 'flex', justifyContent: 'center' }} >
                        <OmisRow>
                            <OmisCol xs={12}>
                                <strong>{t("GeneratedDetails")}</strong>
                            </OmisCol>
                            <OmisCol xs={12} className="faultReportCreateDetailsBox">
                                <OmisCol xs={12}>
                                    {t("Contractor")}
                                    {
                                        props.companySites && props.companySites.length > 0 ?
                                            <OmisCol xs={12}>
                                                <OmisDropdown size={"small"} id={"companySites"} name={"companysiteid"} items={props.companySites} />
                                            </OmisCol>
                                            :
                                            <OmisCol xs={12}>
                                                <strong>{selectedCompanySite && selectedCompanySite.name ? selectedCompanySite.name : null}</strong>
                                            </OmisCol>
                                    }
                                    {t("MaintenanceContractType")}
                                    <OmisCol xs={12}>
                                        <strong>{maintenanceDetails}</strong>
                                    </OmisCol>
                                    {t("FaultCategory_TimeToCompleteAction")}
                                    <OmisCol xs={12}>
                                        <strong>{responseTime}</strong>
                                    </OmisCol>
                                </OmisCol>
                            </OmisCol>
                        </OmisRow>
                    </OmisContainer>
                </Card>
            </OmisStack>
        );
    }

    function SelectedFaultCatalogueCard(props) {
        return (

            <OmisContainer fluid style={{ display: 'flex', justifyContent: 'center' }}>
                <OmisRow>
                    <OmisCol xs={12}>
                        <Card onClick={() => selectionStep >= props.selectionstep && !props.disabled ? setSelectionStep(props.selectionstep) : null}>
                            <CardActionArea>
                                {
                                    props.id ?
                                    <CardContent className="createReportCatalogueCard">
                                        <OmisRow className="justify-content-md-center">
                                            <OmisStack direction="horizontal" gap={3}>
                                                <OmisCol xs={1}>
                                                    {
                                                        props.id ?
                                                            <CheckCircle color={"success"} />
                                                            :
                                                            <HighlightOff color={"error"} />
                                                    }
                                            </OmisCol>
                                            <OmisCol xs={11}>
                                                    <OmisLabel label={props.text} required={props.required} />
                                            </OmisCol>
                                            </OmisStack>
                                        </OmisRow>    
                                        </CardContent>
                                        :
                                        <CardContent className={props.active ? "createReportCatalogueCardActive" : "createReportCatalogueCardDisabled"}>
                                            <OmisRow className="justify-content-md-center">
                                                <OmisStack direction="horizontal" gap={2}>
                                                    <OmisCol xs={1}>
                                                        {
                                                            props.id ?
                                                                <CheckCircle color={"success"} />
                                                                :
                                                                <HighlightOff color={"error"} />
                                                            }
                                                    </OmisCol>
                                                    <OmisCol xs={11}>
                                                        <OmisLabel label={props.text} required={props.required} />
                                                    </OmisCol>
                                                </OmisStack>
                                            </OmisRow>    
                                        </CardContent>
                                }
                            </CardActionArea>
                        </Card>
                    </OmisCol>
                </OmisRow>
            </OmisContainer>
            );
    }
}