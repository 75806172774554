import React, { useState } from "react";
import { t } from "i18next";
import { OmisGrid } from "../shared/OmisGrids";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from 'react-router';
import { OmisCol, OmisRow, OmisTabsWithIcon } from "../shared/OmisLayouts";
import { OmisObjectChip } from "../shared/OmisChips";
import { DetailsCardWithSkeleton, MapWithMultiMarker } from "../shared/Shared";
import {
    mdiArchive, //mdiCheckCircle, mdiFolder, mdiInboxArrowUp,
    mdiInboxFull, mdiListBoxOutline, mdiMapMarkerOutline, mdiPlusBox
} from "@mdi/js";
import { OmisButtonNewItem, OmisCopyButton } from "../shared/OmisButtons";
import { TRole } from "../../constants/RoleConstants";
import AuthService from "../../services/auth.service";
import httpClientService from "../../services/httpclient.service";
import { PrepareModalTitle } from "../reports/ReportsDetails";
import { ObjectCreate } from "./create/ObjectCreate";
import { OmisDialog } from "../shared/OmisDisplays";

export default function Objects(props) {

    var url = "/api/objects/getobjectlist";

    if (props.projectid) {
        url = `${url}?projectid=${props.projectid}`;
    }

    const tabs = [
        {
            iconpath: mdiListBoxOutline,
            iconposition: "start",
            title: t("List View"),
            content: <TabContent_ListView url={url} {...props} />
        },
        {
            iconpath: mdiMapMarkerOutline,
            iconposition: "start",
            title: t("Map View"),
            content:
                
            <DetailsCardWithSkeleton
                url={`${url}formap`}
                skeletonheight={"400px"}
                    content={<TabContent_MapView {...props} />}
                {...props}
                />,
            hideTab: !AuthService.hasRole(TRole.MapsAvailable)
        }
    ]

    return (
        <div>
            {
                url.includes("?") ?
                    <TabContent_ListView url={url} {...props} />
                    :
                    <OmisRow>                        
                        <OmisCol xs={12}>
                            <OmisTabsWithIcon tabs={tabs} />
                        </OmisCol>
                    </OmisRow>
            }
        </div>
    );
}

export function TabContent_ListView(props) {

    const navigate = useNavigate();

    const [modalHeight, setModalHeight] = useState(800);
    const [modalWidth, setModalWidth] = useState("xl");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModaContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    function handleRedirectToCreateReport() {
        navigate("/reports/create");
    }


    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };


    var subMenuItems = [
    ];

    if (AuthService.hasRole(TRole.FaultReportWrite)) {
        subMenuItems = [
            {
                action: handleRedirectToCreateReport,
                iconpath: mdiInboxFull,
                icontext: `${t("Action_Add")} ${t("Report_Title")}`,
                disableItem: false
            }, ...subMenuItems];
    }

    //if (AuthService.hasRole(TRole.DMSObject)) {
    //    subMenuItems = [...subMenuItems,
    //        {
    //            action: null,
    //            iconpath: mdiFileDocument,
    //            icontext: "Add document",
    //            disableItem: false
    //        }];
    //}

    //if (AuthService.hasRole(TRole.FaultReportObjectChecklist)) {
    //    subMenuItems = [...subMenuItems,
    //        {
    //            action: null,
    //            iconpath: mdiCheckCircle,
    //            icontext: "Add checklist",
    //            disableItem: false
    //        }
    //    ];
    //}

    var dropDownMenuItems = [

        {
            action: null,
            iconpath: mdiPlusBox,
            icontext: t("Action_Add"),
            expand: false,
            subMenuItems: subMenuItems,
            disableItem: false
        },        
        //{
        //    action: null,
        //    iconpath: mdiInboxArrowUp,
        //    icontext: "Export CSV",
        //    disableItem: false
        //}        
    ]

    //if (AuthService.hasRole(TRole.ProjectEdit)) {
    //    dropDownMenuItems = [...dropDownMenuItems,
    //        {
    //            action: null,
    //            iconpath: mdiFolder,
    //            icontext: "Add to project",
    //            disableItem: false
    //        }
    //    ];
    //}

    if (AuthService.hasRole(TRole.ObjectArchive)) {
        dropDownMenuItems = [...dropDownMenuItems,
            {
                action: handleArchivClick,
                iconpath: mdiArchive,
                icontext: t("Action_Archive"),
                disableItem: false
            }
        ];
    }

    function handleEditClick(rowid) {
        setModalTitle(<PrepareModalTitle title={t("Action_Edit")} />);
        setModaContent(<ObjectCreate handleModalCancel={handleModalCancel} handleModalClose={handleModalClose} objectID={rowid} {...props} />);
        handleModalOpen();
    }


    function handleArchivClick(rowid) {
        httpClientService.post(`/api/objects/archivobject?objectID=${rowid}`)
            .then((response) => {
                if (response) {
                    setChangeCounter((prevState) => prevState + 1);
                }
            });
    }

    return (
        <>
            <OmisGrid
                actiondropdown={AuthService.hasRole(TRole.ObjectEdit) ? { edit: handleEditClick, dropDownMenuItems: dropDownMenuItems } : { dropDownMenuItems: dropDownMenuItems }}
                objectgrid
                headerText={t("Objects")}
                newButton={AuthService.hasRole(TRole.ObjectEdit) ? <OmisButtonNewItem id={"newObjectButton"} component={RouterLink} to={"/objects/create/"} text={t("Action_New")} /> : null}
                apiUrl={props.url}
                change={changeCounter}
                {...props} />

            <OmisDialog onClose={handleModalClose} open={modalOpen}
                maxWidth={modalWidth}
                title={modalTitle}
                content={modalContent}
                contentHeight={modalHeight}
            />
        </>
    );
}

function TabContent_MapView(props) {

    function PrepareMapMarker(item) {
        return {
            position: { latitude: item.latitude, longitude: item.longitude },
            content:
                <span>
                    <OmisRow>
                        {item.objectID ? <OmisCol xs={12}><OmisObjectChip text={item.objectID} objectid={item.objectID} /></OmisCol> : null}
                        {item.phone ? <OmisCol xs={12} direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>{item.phone}<OmisCopyButton text={item.phone} /></OmisCol> : null}
                        {item.email ? <OmisCol xs={12} direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>{item.email}<OmisCopyButton text={item.email} /></OmisCol> : null}
                        {item.address ? <OmisCol xs={12}>{item.address}</OmisCol> : null}
                    </OmisRow>
                </span>
        };
    }

    const markers = [...props.data.values()].map((x, i) => PrepareMapMarker(x) );

    return (
        <MapWithMultiMarker markers={markers} />
    );
}