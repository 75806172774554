import React, { useState, useEffect } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../shared/OmisButtons";
import { OmisDateTimePicker, OmisTextBox } from "../shared/OmisInputs";
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";

export function ProjectEdit(props) {
    var { state } = { ...props };

    const [initialValues, setInitialValues] = useState({
        projectID: 0,
        description: '',
        beginDate: '',
        endDate: ''
    });

    useEffect(() => {
        if (state) {
            setInitialValues({
                projectID: state.projectID,
                description: state.description,
                beginDate: state.beginDate ? new Date(state.beginDate) : '',
                endDate: state.endDate ? new Date(state.endDate) : '',
            });
        }
    }, [state]);

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object({
                description: Yup.string()
                    .required(t("GenericRequiredText"))
            })}
            validate={(values) => {
                const errors = {};
                if (!values.description) {
                    errors.description = 'Required';
                }
                if (errors.description) {
                    return console.log(errors);
                }
            }}
            onSubmit={(values) => {
                props.handleSave(values);
            }}
        >
            <Form>
                <OmisContainer fluid>
                    <OmisRow>
                        <OmisCol xs={12}><OmisTextBox labeltext={t("Name")} name="description" placeholder={t("Name")} required /> </OmisCol>
                        <OmisCol xs={6}><OmisDateTimePicker label={t("BeginDate")} name="beginDate" />  </OmisCol>
                        <OmisCol xs={6}><OmisDateTimePicker label={t("EndDate")} name="endDate" />  </OmisCol>
                    </OmisRow>
                    <br/>
                    <OmisRow>
                        <OmisCol xs={6}><OmisButtonPrimarySubmit id={"submitButton"} text={t("Action_Save")}></OmisButtonPrimarySubmit> </OmisCol>
                        <OmisCol xs={6}><OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={props.handleModalCancel}></OmisButtonSecondary> </OmisCol>
                    </OmisRow>
                </OmisContainer>
            </Form>
        </Formik>
    );
}