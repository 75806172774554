import React, {useState, useEffect} from "react";
import {OmisCircularProgress} from "../shared/OmisProgressDisplays";
import httpclientService from "../../services/httpclient.service";
import { OmisReportChip, OmisUserChip} from "../shared/OmisChips";
import {OmisBox, OmisDate} from "../shared/OmisDisplays";
import { t } from "i18next";
import { SpaceComponent } from "../shared/Shared";
import { mdiDotsHorizontal } from "@mdi/js";
import { OmisButtonPrimary, OmisDropDownMenu } from "../shared/OmisButtons";
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";

export default function Notifications(props) {

    const [notificationList, setNotificationList] = useState([]);
    const [notificationDisplayList, setNotificationDisplayList] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(20);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return(() => {
            window.removeEventListener("scroll", handleScroll);
        })
    }, [])

    const showMoreCount = 5;

    function handleShowMoreClick() {
        setEndIndex(prevEndIndex => prevEndIndex + showMoreCount);
    }

    function handleScroll() {
        var isAtBottom = document.documentElement.scrollHeight - document.documentElement.scrollTop <= (document.documentElement.clientHeight + 5);
        if (isAtBottom) {
            setLoadingMore(true);
            setEndIndex(prevEndIndex => prevEndIndex + showMoreCount);
        }
    }

    useEffect(() => {
        setNotificationDisplayList(notificationList.slice(startIndex, endIndex));
        setLoadingMore(false);
    }, [endIndex]);

    useEffect(() => {
        httpclientService.get("/api/notifications/getnotifications").then((response) => {
            if (response) {
                setNotificationList(response);
                setNotificationDisplayList(response.slice(startIndex, endIndex))
            }
            setLoading(false);
        });

        return(() => {
            setNotificationList([]);
            setNotificationDisplayList([]);
        })
    }, [])

    return (
        <div>
            <OmisContainer fluid>
                <OmisRow>
                    <OmisCol xs={4}>
                        <h1>{t("Notification")}</h1>
                    </OmisCol>

                    <OmisCol className="ms-auto" xs={3} style={{ display: 'flex', justifyContent: 'right', alignItems: "center" }} sx={{ height: '40px;' }}>
                        <OmisButtonPrimary id={"configureButton"} text={t("ConfigureNotifications")} />
                    </OmisCol>
                </OmisRow>
            </OmisContainer>
            {
                loading ? <OmisCircularProgress size={40} /> : (notificationDisplayList.map((n) => <NotificationRow key={n.activityID} {...n} />))
            }
            {
                loadingMore ? <OmisCircularProgress size={40} /> : null
            }
            {/*{*/}
            {/*    loading ? "" :*/}
            {/*        <OmisRow>*/}
            {/*            <OmisCol xs={3}>*/}
            {/*                <OmisButtonSecondary id={"showMoreButton"} text={"Mehr anzeigen"} onClick={handleShowMoreClick}/>*/}
            {/*            </OmisCol>*/}
            {/*        </OmisRow>*/}
            {/*}*/}
        </div>
    );
}

function NotificationRow(props) {

    const childItemsForDotsMenu = [
        {
            menuName: t("Details"),
            toLink: "/notifications/details",
            state: { ...props }
        }, {
            menuName: t("Action_Edit"),
            toLink: "/notifications/edit",
            state: { ...props }
        },
    ]

    return (

		<OmisBox className={"omis-notification-row"}>
            <OmisRow>
                <OmisCol xs={8} style={{ display: 'flex', justifyContent: 'left', alignItems: "center" }}>
                    <OmisUserChip userFullName={props.userFullName} userID={props.userID} />
                    <SpaceComponent />
                    <span>{props.activityType}</span>
                    <SpaceComponent />
                    <OmisReportChip text={props.reportID} reportid={props.reportID} objectid={props.objectID} equipid={props.equipmentID} />
                </OmisCol>

                <OmisCol xs={4} style={{ display: 'flex', justifyContent: 'right', alignItems: "center" }}>
                    <OmisRow style={{ display: 'flex', alignItems: "center" }}>
                        <OmisCol xs={9} >
                            <OmisDate date={props.creationDate} />
                        </OmisCol>
                        <OmisCol xs={1}>
                            <OmisDropDownMenu menuicon={mdiDotsHorizontal} childitems={childItemsForDotsMenu} />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>
			</OmisRow>
		</OmisBox>
    );
}