import React, { useState } from "react";
import { t } from "i18next";
import { Link as RouterLink } from "react-router-dom";
import httpclientService from "../../../services/httpclient.service";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisButtonNewItem, OmisIconButton } from "../../shared/OmisButtons";
import { OmisDialog } from "../../shared/OmisDisplays";
import { OmisGrid } from "../../shared/OmisGrids";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { CreateEquiContractor } from "./CreateEquiContractor";
import { OmisCol, OmisContainer } from "../../shared/OmisLayouts";

var state = { selectedtabvalue: TabConstants.Equipments }

export function SettingsEquipmentContractors(props) {
    var url = "/api/settings/settingsequipments/getequicontractors";
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    function handleEditClick(rowID, e) {
        if (e) {
            setModalTitle(t("Action_Edit"));
        } else {
            setModalTitle(null);
            if (typeof rowID !== 'number') {
                rowID = null
            }
        }
        setModalContent(<CreateEquiContractor onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID} rowVals={e} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    const handleDelete = (rowID) => {
        httpclientService.post(`/api/settings/settingsequipments/deleteequicontractor?id=${rowID}`).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    }

    return (
        <OmisContainer fluid>

            <div className="omis-header-box">
                <OmisCol xs={1}>
                    <OmisIconButton id={"backButton"}
                        iconcontent={<ArrowBackIcon fontSize="inherit" />}
                        text="Back" component={RouterLink} to={"/settings"} state={{ selectedtabvalue: TabConstants.Equipment }} />
                </OmisCol>

                <OmisGrid actiondropdown={{ edit: handleEditClick, delete: handleDelete }} headerText={t("Settings_EquiContractors")}
                    newButton={<OmisButtonNewItem id={"newItemButton"} onClick={handleEditClick} text={t("Action_New")} />}
                    apiUrl={url}
                    change={changeCounter}
                    noExportButton
                    {...props} />

                <OmisDialog onClose={handleModalCancel} open={modalOpen}
                    maxWidth={"lg"}
                    title={modalTitle}
                    content={modalContent}
                    contentHeight={600}
                />
            </div>
        </OmisContainer>
    );
}