import React from "react";
import { t } from "i18next";
import { OmisGrid } from "../../shared/OmisGrids";

export function SettingsFaultEquiClassDefs(props) {

    var url = "/api/settings/settingsequipments/getfaultequiclassdef";

    if (props.equiclassid) {
        url = `${url}?equiclassid=${props.equiclassid}`;
    }

    return (
        <div>

            <OmisGrid headerText={t("FaultEquiClassDefs")}
                apiUrl={url}
                noButtons noHeader
                detailsroute="/settings/settingsreports/faultequiclassdefdetails"
                hasdetailview
                {...props} />
        </div>
    );
}