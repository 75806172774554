import React from "react";
import { OmisDropdownNoForm } from "../../shared/OmisDropdowns";
import { t } from "i18next";
import { OmisTextBoxNoForm } from "../../shared/OmisInputs";
import { OmisIconButton } from "../../shared/OmisButtons";
import { Clear as ClearIcon } from "@mui/icons-material";
import { OmisCol, OmisRow } from "../../shared/OmisLayouts";

export function CostsList(props) {

    function handleCostValueChange(e){
        if (props.costValueChangedCallback) {
            props.costValueChangedCallback(e);
        }
    }

    return (props?.costlist?.map((cost, index) => index < 1 ?

        <OmisCol key={`${index}_CostRow`} xs={12} style={{justifyContent:'flex-start', alignItems: 'center'} }>
            <OmisRow style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                {
                    props.costTypes && props.costTypes.length > 0 ?
                        <OmisCol xs={4}>
                            <OmisDropdownNoForm value={cost.costType} id={cost.key.toString()} name={"costType"} onChange={handleCostValueChange} labeltext={t("CostType")} items={props.costTypes} />
                        </OmisCol>
                        :
                        null
                }
                <OmisCol xs={4}>
                    <OmisTextBoxNoForm value={cost.costValue} id={cost.key.toString()} name={"costValue"} onChange={handleCostValueChange} label={t("Tender_MarginalCosts")} type="number" />
                </OmisCol>
                <OmisCol xs={4}>
                    <br />
                    <OmisDropdownNoForm value={cost.currencyType} id={cost.key.toString()} name={"currencyType"} onChange={handleCostValueChange} labeltext={t("Currency")} items={props.currencies} />
                </OmisCol>
            </OmisRow>
            
        </OmisCol>
        :
        <OmisCol key={`${index}_CostRowWithX`} style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
            <OmisRow style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
            {
                props.costTypes && props.costTypes.length > 0 ?
                        <OmisCol xs={4} >
                        <OmisDropdownNoForm value={cost.costType} id={cost.key.toString()} name={"costType"} onChange={handleCostValueChange} labeltext={t("CostType")} items={props.costTypes} />
                    </OmisCol>
                    :
                    null
            }
            <OmisCol xs={4}>
                <OmisTextBoxNoForm value={cost.costValue} id={cost.key.toString()} name={"costValue"} onChange={handleCostValueChange} label={t("Tender_MarginalCosts")} type="number" />
            </OmisCol>
            <OmisCol xs={3}>
                <br />
                <OmisDropdownNoForm value={cost.currencyType} id={cost.key.toString()} name={"currencyType"} onChange={handleCostValueChange} labeltext={t("Currency")} items={props.currencies} />
                </OmisCol>
                <OmisCol xs={1}>
                    <OmisIconButton id={"deleteRowButton"} iconcontent={
                        <ClearIcon />} onClick={() => { props.handleRemoveLastCost(cost.key) }}></OmisIconButton>
                </OmisCol>
            </OmisRow>
        </OmisCol>
        ));
}