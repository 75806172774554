import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { OmisDialog } from "../shared/OmisDisplays";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { OmisDropDownMenu, OmisIconButton, OmisPriButtonWithStartIcon } from "../shared/OmisButtons";
import { OmisInspectionChip } from "../shared/OmisChips";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { TabContent_Details } from "./TabContent_Details";
import {
    //mdiBell,
    mdiCheckCircleOutline,
    //mdiClipboardList, mdiCreditCardChip,
    mdiDotsHorizontal, mdiFileDocument,
    //mdiFileDocumentMultiple,
    mdiFileDocumentOutline,
    //mdiFlagVariant,
    mdiFlagVariantOutline, mdiHistory, 
    //mdiInboxFull,
    mdiInformationOutline,
    //mdiLinkVariant,
    mdiPencil, mdiPlusBoxOutline, mdiTimerOutline
} from "@mdi/js";
import { TabContent_PastInspections } from "./TabContent_PastInspections";
import { TabContent_CheckLists } from "./TabContent_CheckLists";
import { TabContent_Documents } from "./TabContent_Documents";
import { DetailsCardWithSkeleton } from "../shared/Shared";
import { TabContent_ActivityHistory } from "./TabContent_ActivityHistory";
import { AddDocuments } from "./AddDocuments";
//import { AddNote } from "./AddNote";
import { OmisCol, OmisContainer, OmisRow, OmisStack, OmisTabsWithIcon } from "../shared/OmisLayouts";
import httpclientService from "../../services/httpclient.service";
import urlEncryptService from '../../services/urlencrypt.service';
import AuthService from "../../services/auth.service";
import { TRole } from "../../constants/RoleConstants";
import { InspectionsCreate } from "./InspectionsCreate";
import { DischargeTestDate } from "./DischargeTestDate";

export function InspectionDetails(props) {
    const navigate = useNavigate();
    var {state} = useLocation();
    const [modalWidth, setModalWidth] = useState("md");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModaContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);


    var location = useLocation();
    if (!state) {

        var encryptedString = "";
        if (location?.search) {
            encryptedString = location?.search.replace("#", "").replace("?", "").replaceAll("%22", "\"");
        } else if (location?.hash) {
            encryptedString = location?.hash.replace("#", "").replace("?", "").replaceAll("%22", "\"");
        }

        if (encryptedString && encryptedString !== "") {
            state = urlEncryptService.decrypt(encryptedString);
        }
    }
    
    const [selectedTabValue, setSelectedTabValue] = useState(0);
    const [tabs, setTabs] = useState([{
        title: t("Details"),
        content:
            <TabContent_Details state={{ ...state, selectedtabvalue: selectedTabValue }} />,
        iconpath: mdiInformationOutline,
        iconposition: "start"
    }]);

    var addDocumentTitle = t("AddDocuments"),
        addSupplierRequestsTitle = "Add supplier requests";

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    function handleDischargeTestDate() {
        handleModalOpenForDropdownMenuActions(t("Inspection_Discharged"), <DischargeTestDate ids={[state?.inspectionid]} handleModalCancel={handleModalCancel} handleModalClose={handleModalClose} />);
    }

    useEffect(() => {
        if (changeCounter > 0) {
            navigate(0); //refresh page
        }
    }, [changeCounter]);

    function GetTabByTitle(title) {
        var result = {};

        switch (title) {
            case `${t("PastInspections")}`:
                result = {
                    title: `${t("PastInspections")}`,
                    content:
                        <TabContent_PastInspections state={{ ...state, selectedtabvalue: selectedTabValue }} />,
                    iconpath: mdiHistory,
                    iconposition: "start"
                };
                break;
            case t("Checklists"):
                result = {
                    title: t("Checklists"),
                    content:
                        <TabContent_CheckLists state={{ ...state, selectedtabvalue: selectedTabValue }} />,
                    iconpath: mdiCheckCircleOutline,
                    iconposition: "start"
                };
                break;
            case t("Documents"):
                result = {
                    title: t("Documents"),
                    content:
                        <TabContent_Documents closeAction={handleModalClose} onCancel={handleModalCancel} state={{ ...state, selectedtabvalue: selectedTabValue }} />,
                    iconpath: mdiFileDocumentOutline,
                    iconposition: "start",
                    removeFromAddMenu: `${t("AddDocuments")}`
                };
                break;
            case `${t("Activity")} ${t("FaultReport_History")}`:
                result = {
                    title: `${t("Activity")} ${t("FaultReport_History")}`,
                    content:
                        <DetailsCardWithSkeleton
                            url={`/api/inspections/getinspectionhistory?id=${state?.inspectionid}`}
                            skeletonheight={"100px"}
                            content={
                                <TabContent_ActivityHistory state={{ ...state, selectedtabvalue: selectedTabValue }} />} {...state} />,
                    iconpath: mdiTimerOutline,
                    iconposition: "start"
                };
                break;
            default:
                break;
        }
        return result;
    }
    useEffect(() => {
        if (state && state.selectedtabvalue) {
            if (/[0-9]/.test(state.selectedtabvalue)) {
                setSelectedTabValue(parseInt(state.selectedtabvalue));
            } else {
                setSelectedTabValue(0);
            }
        }

        var tmp = [...tabs];
        httpclientService.get(`/api/inspections/getdetailstabcounts?inspectionid=${state?.inspectionid}`)
            .then((tabcounts) => {
                if (tabcounts.length > 0) {
                    tabcounts.map((tab) => {
                        if (tab.count > 0 && tmp.filter((item) => item.title === tab.title).length === 0) {
                            var tabContent = GetTabByTitle(tab.title);
                            if (tabContent) {
                                tmp.push({ ...tabContent, disableaction:tab.disableaction });
                            }
                        }
                    });
                }
            }).then(() => {
                setTabs(tmp);
            });      

    }, []);


    function PrepareModalTitle(props) {
        return (
            <OmisRow>
                <OmisCol xs={ 12}><span>{ props.title }</span>
                    <OmisInspectionChip text={ props.inspectionid } inspectionid={ props.inspectionid } objectid={ props.objectid } objectnrinternal={ props.objectnrinternal } equipid={ props.equipid }/></OmisCol>
            </OmisRow>);
    }

    const handleModalOpenForDropdownMenuActions = (title, content, e) => {
        var modalwidth = "md";
        if (title === addSupplierRequestsTitle || title === t("Action_Edit")) {
            modalwidth = "xl";
        } else if (title === t("Inspection_Discharged")) {
            modalwidth = "lg";
        }
        setModalWidth(modalwidth);
        setModalTitle(
            <PrepareModalTitle modalwidth={ modalwidth } title={ title } { ...state } />);
        setModaContent(content);
        handleModalOpen();
    };

    const childItemsForDotsMenu = [
        {
            subMenuIcon: mdiPencil,
            onClick: handleModalOpenForDropdownMenuActions.bind(this, t("Action_Edit"), <InspectionsCreate handleModalCancel={handleModalCancel} handleModalClose={handleModalClose} tdid={props.inspectionid ?? state?.inspectionid}/>),
            menuName: t("Action_Edit"),
            hideMenuItem: !AuthService.hasRole(TRole.TestdatesEdit)
        },
        //{
        //    onClick: handleDischargeTestDate,
        //    subMenuIcon: mdiFileSign,
        //    menuName: t("Inspection_Discharged"),
        //},
    ]

    const addMenuChildItems = [
        {
            subMenuIcon: mdiFileDocument,
            onClick: handleModalOpenForDropdownMenuActions.bind(this, addDocumentTitle,
                <AddDocuments closeAction={handleModalClose} onCancel={handleModalCancel} {...state} />),
            menuName: `${t("AddDocuments")}`,
            hideMenuItem: !AuthService.hasRole(TRole.DMSFaultReport)
        },
    ];

    const [childItemsForAddMenu, setChildItemsForAddMenu] = useState(addMenuChildItems);

    function tabChanged(e) {
        setSelectedTabValue(e);
        var addMenus = addMenuChildItems.filter((item) => item.menuName !== tabs[e].removeFromAddMenu);
        setChildItemsForAddMenu(addMenus);
    }

    return (
        <div className="omis-header-box">

            <OmisContainer fluid>
                <OmisStack direction="horizontal" gap={5}>
                    <OmisRow>
                        <OmisCol xs={ 6} style={{ display: 'flex', justifyContent: 'left' }}>
                            <OmisRow>
                                <OmisCol xs={1} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                    <OmisIconButton id={"backButton"} iconcontent={
                                        <ArrowBackIcon fontSize="inherit" />} onClick={() => { navigate(location.key !== "default" ? -1 : "/inspections"); }}
                                    />
                                </OmisCol>
                                <OmisCol xs={11} style={{ display: 'flex', justifyContent: 'left', alignItems:'center' }}  >
                                    <div className="omis-header-title-details" style={{ paddingLeft: '1.5em', fontWeight: "bold" }} >{t("Settings_TestDates")} #{state?.inspectionid}</div>
                                </OmisCol>
                            </OmisRow>
                        </OmisCol>
                        <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'right' }}>
                            <OmisRow style={{ display: 'flex', justifyContent: 'right' }}>
                                {
                                    childItemsForDotsMenu.length > 0 ?
                                        <OmisCol xs={3} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }} >
                                            <OmisDropDownMenu menuicon={mdiDotsHorizontal} childitems={childItemsForDotsMenu} />
                                        </OmisCol>
                                        :
                                        null
                                }
                                {
                                    childItemsForAddMenu.length > 0 ?
                                        <OmisCol xs={3} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }} >
                                            <OmisDropDownMenu menutitle={t("Action_Add")} menuicon={mdiPlusBoxOutline} childitems={childItemsForAddMenu} />
                                        </OmisCol>
                                        :
                                        null
                                }
                                {
                                    tabs.some(x => x.title === t("Checklists")) ?
                                        <OmisCol xs={6} style={{ height: '2.5835em', display: 'flex', justifyContent: 'right', alignItems: 'center' }} >
                                            <OmisPriButtonWithStartIcon id={"fillInButton"} disabled={tabs.filter(x => x.title === t("Checklists"))[0]?.disableaction} state={{ ...state, selectedtabvalue: selectedTabValue }} iconpath={mdiFlagVariantOutline} component={RouterLink} to={"/inspections/inspectioncontrol"} text={t("FillIn")} size={"small"} />
                                        </OmisCol>
                                        :
                                        null
                                }
                            </OmisRow>
                        </OmisCol>
                    </OmisRow>
                </OmisStack>
            </OmisContainer>
            <OmisTabsWithIcon tabs={tabs} onChange={tabChanged} tabvalue={selectedTabValue} />
            <OmisDialog onClose={ handleModalCancel } open={ modalOpen }
                        maxWidth={ modalWidth }
                        title={ modalTitle }
                        content={ modalContent }
            />
        </div>);
}