import React, { useState} from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {OmisTextBox} from "../shared/OmisInputs";
import {OmisButtonPrimarySubmit, OmisButtonSecondary} from "../shared/OmisButtons";
import {useTranslation} from "react-i18next";
import { LoginFailedDisplay } from "./Login";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../shared/OmisLayouts";

export default function ResetPassword(props) {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState(null);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const navigate = useNavigate();

    const handleResetPassword = (username) => {

        if (username === '') {
            setErrorMessage(t("Account_InvalidNewPasswordCredentials"));
        } else {
            setErrorMessage(null);
            setSubmitButtonDisabled(true);

            AuthService.resetpassword(username).then(
                (resetResult) => {
                    if (resetResult.status === 200) {
                        navigate("/login");
                    } else {
                        setErrorMessage(resetResult.data.errormessage);
                        setSubmitButtonDisabled(false);
                    }
                }
            );
        }
    };

    return (
            <Formik
                initialValues={{ username: '' }}
                validationSchema={Yup.object({
                    username: Yup.string()
                        .required(t("GenericRequiredText"))
                        .min(3, 'Benutzername muss mindestens 3 Zeichen lang sein!')
                })}
                onSubmit={(values) => { handleResetPassword(values.username) }}
                validateOnMount
            >{formik => (
                <Form>
                    <OmisCol className="justify-content-center d-flex align-items-center align-self-center align-content-center flex-wrap">
                        <OmisCol xs={12} md={{ span: 10 }} xxl={8} style={{ marginTop: '3em' }}>
                            <div className="loginRightHeader" style={{ marginBottom: '2em' }}>
                                        <h1 className={"font-orange center"}>{t("ResetPassword")}</h1>
                            </div>
                        </OmisCol>

                        <OmisCol xs={12} md={{ span: 10 }} xxl={8} style={{ marginBottom: '2em' }}>
                                <OmisTextBox labeltext={t("UserName")} name="username" placeholder={t("UserName")} required />
                        </OmisCol>

                        <OmisCol xs={12} md={{ span: 10 }} xxl={8} style={{ marginTop: '4.5em' }}>
                            <OmisStack direction="horizontal" gap={0}>
                                            <OmisButtonSecondary id={"cancelButton"} text={t("Action_Back")} onClick={() => window.location.href = "login"} />
                                            <OmisButtonPrimarySubmit id={"submitButton"} text={t("Action_Reset")} disabled={!formik.isValid || submitButtonDisabled} />
                            </OmisStack>
                        </OmisCol>

                        <OmisCol xs={12} md={{ span: 10 }} xxl={8}>
                            <LoginFailedDisplay errorMessage={errorMessage} />
                        </OmisCol>
                    </OmisCol>
                </Form>)}
            </Formik>
    );
}