import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { t } from "i18next";
import httpclientService from "../../services/httpclient.service";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit } from "../shared/OmisButtons";
import { OmisButtonSecondary } from "../shared/OmisButtons";
import { Chip} from "@mui/material";
import { OmisAutosizeTextArea } from "../shared/OmisInputs";
import { AllUserList } from "./UsersList";
import { OmisCheckboxList } from "../shared/OmisLists";
import { PageNames } from "../../constants/PageNameConstants";
import { Roles } from "./Roles";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../shared/OmisLayouts";

var api = "/api/profile/";

export default function AddNewGroup(props) {

    const formRef = useRef();
    const navigate = useNavigate();
    const selectedTab = { selectedTab: 1 };

    const [initialValues, setInitialValues] = useState({
        id: 0,
        //groupName: '',
        description: '',
        startPage: '',
        groupRoles: [],
        groupModules: [],
        userIDs: []
    });

    const [pages, setPages] = useState([]);
    const [selectedPage, setSelectedPage] = useState([]);    

    useEffect(() => {
        var tmpPages = PageNames();
        if (tmpPages && tmpPages.length>0) {
            setPages(tmpPages);
        }
    },[]);    

    const validationSchema = Yup.object({
        description: Yup.string()
            .required(t("GenericRequiredText"))
            .max(200, t('Error_TextTooLong', { 1: 200 })),
    });

    const handleSelectedListItems = (selectedItems) => {
        var selectedPages = selectedItems.length > 0 ? pages.filter((item) => item.key === selectedItems[0]).map((val) => val.content)[0] : initialValues.startPage;
        formRef.current.setFieldValue("startPage", selectedPages);
    }

    const handleSelectedUsersList = (selectedIDs) => {
        formRef.current.setFieldValue("userIDs", selectedIDs.sort((a, b) => a - b));
    }

    const handleSelectedRoles = (selectedItems) => {
        formRef.current.setFieldValue("groupRoles", selectedItems);
    }

    function handleCreateGroup(values) {
        httpclientService.post(`${api}savegroupdetails`, values).then((response) => {
            if (response) {
                values.id = response;
                navigate("/users/groupdetails", { state: values });
            }
        });
    }

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    handleCreateGroup(values)
                }}
            >
                {formik => (
                    <Form>
                        <div className="omis-header-box">
                            <OmisContainer fluid>
                                <OmisRow>
                                    <OmisCol xs={6} >
                                        <h1>
                                            {t("NewCustomGroup")}
                                        </h1>
                                    </OmisCol>
                                    <OmisCol style={{ display: 'flex', justifyContent: 'right' }} xs={6}>
                                        <OmisStack direction="horizontal" gap={1}>
                                            <div className="p-2"><OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} component={RouterLink} to={"/users"} state={selectedTab} ></OmisButtonSecondary></div>
                                            <div className="p-2"><OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit></div>  
                                        </OmisStack>
                                    </OmisCol>
                                </OmisRow>
                            </OmisContainer>
                        </div>

                        <OmisStack gap={3}>
                            <strong>
                                {t("Group")} {t("Details")}
                            </strong>
                            <OmisContainer fluid className="usersGroupCreateCard">
                                <OmisRow>
                                    <OmisCol xs={6}>
                                        <OmisCol xs={11}>
                                            <OmisCheckboxList label={t("Group_StartPage")} itemlist={pages} checked={selectedPage} onChange={handleSelectedListItems} listAttr={["key", "content"]} maxheight={100} multiSelect={false} {...props} showSearchBox />
                                        </OmisCol>
                                    </OmisCol>
                                    <OmisCol xs={6}>
                                        <OmisCol xs={11}>
                                            <OmisAutosizeTextArea labeltext={t("GroupName")} name="description" required />
                                            <strong className="detailsCardTitle">{t("Settings_Roles")}</strong>
                                                <Roles isEdit selectedRoles={initialValues.groupRoles} handleSelectedRoles={handleSelectedRoles} />
                                        </OmisCol>
                                    </OmisCol>
                                </OmisRow>
                            </OmisContainer>
                            <OmisContainer fluid style={{ display: 'flex', justifyContent: 'center' }} className="usersGroupCreateCard">
                                <OmisRow>
                                    <OmisCol xs={12}>
                                        {
                                            initialValues && initialValues.modules && initialValues.modules.length > 0 ?
                                                <>
                                                    <strong>{t("Modules")}</strong>
                                                    <OmisCol xs={12}>
                                                        {
                                                            initialValues.modules.map((item) =>
                                                                <Chip clickable={false} variant="outlined" label={item.description} />
                                                            )
                                                        }
                                                    </OmisCol>
                                                </>
                                                :
                                                null
                                        }
                                        {
                                            initialValues && initialValues.permissions && initialValues.permissions.length > 0 ?
                                                <>
                                                    <strong>{t("Permissions")}</strong>
                                                    <OmisCol xs={12}>
                                                        {
                                                            initialValues.permissions.map((item, index) =>
                                                                <OmisCol xs={12} key={`${index}_PermissionGrid`}>
                                                                    <span>{item.title}</span>
                                                                    {
                                                                        item.subitems && item.subitems.length > 0 ?
                                                                            item.subitems.map((subitem) => <Chip clickable={false} variant="outlined" label={subitem} />)
                                                                            :
                                                                            null
                                                                    }
                                                                </OmisCol>
                                                            )
                                                        }
                                                    </OmisCol>
                                                </>
                                                :
                                                null
                                        }

                                        <OmisCol xs={12 }>
                                            <OmisCol xs={12}>
                                                <strong><h1>{t("Users")}</h1></strong>
                                                    <AllUserList noHeader noButtons initialValues={initialValues} handleSelectedUsersList={handleSelectedUsersList} />
                                                
                                            </OmisCol>
                                        </OmisCol>
                                    </OmisCol>
                                    <OmisCol xs={12}>
                                        
                                    </OmisCol>
                                </OmisRow>
                            </OmisContainer>
                        </OmisStack>

                      
                        
                    </Form>)}
            </Formik>           
        </>
    );
};