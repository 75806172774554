import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import httpClientService from "../../../services/httpclient.service";
import { t } from "i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { OmisCol, OmisRow } from "../../shared/OmisLayouts";
import { OmisGrid } from "../../shared/OmisGrids";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";


export function SettingsRoles_Assign(props) {
    var baseUrl = "/api/settings/system/";
    var url = "";
    if (props.user) {
        url = `${baseUrl}getmissinguserslistforrole?roleid=${props.roleid}`
    } else {
        url = `${baseUrl}getmissinggroupslistforrole?roleid=${props.roleid}`
    }

    const navigate = useNavigate();
    const [selectedIDs, setSelectedIDs] = useState([]);

    const formRef = useRef();
    const [initialValues, setInitialValues] = useState({
        assignmentType: props.user ? "user" : "group",
        roleID: props.roleid,
        selectedIDs: selectedIDs
    });

    function assignRoleToSelections(values) {
        httpClientService.post(`${baseUrl}assignroletoselections`, values).then((response) => {
            if (response) {
                if (props.handleModalClose) {
                    props.handleModalClose();
                } else {
                    navigate("/settings/system/roles");
                }
            }
        });
    }

    function handleRowSelection(rowIDs) {
        setSelectedIDs(rowIDs.sort((a, b) => a - b));
        formRef.current.setFieldValue("selectedIDs", rowIDs.sort((a, b) => a - b));
    }


    const [schema, setSchema] = useState({
        selectedIDs: Yup.array()
            .min(1, t('GenericRequiredText')),
    });

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                initialValues={initialValues}
                validationSchema={Yup.object(schema)}
                enableReinitialize={true}
                onSubmit={(values) => {
                    assignRoleToSelections(values)
                }}
            >
                {formik => (
                    <Form>
                        <OmisGrid
                            headerText={props.user ? t("Users") : t("Group_Title")}
                            apiUrl={url}
                            noButtons
                            showCheckBox
                            checkedRows={selectedIDs}
                            rowSelectionCallback={handleRowSelection}
                            {...props} />

                        <OmisRow>
                            <OmisCol xs={3}>
                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={props.handleModalCancel}></OmisButtonSecondary>
                            </OmisCol>
                            <OmisCol xs={3}>
                                <OmisButtonPrimarySubmit id={"saveButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                            </OmisCol>
                        </OmisRow>
                    </Form>)}
            </Formik>
        </>
    );
}