import React, { useEffect,useState } from "react";
import { t } from "i18next";
import { Link as RouterLink, useLocation } from "react-router-dom";
import httpclientService from "../../../services/httpclient.service";
import { ObjectAttributeTabConstants, TabConstants } from "../../../constants/SettingConstants";
import { OmisButtonNewItem, OmisIconButton } from "../../shared/OmisButtons";
import { OmisDialog } from "../../shared/OmisDisplays";
import { OmisGrid } from "../../shared/OmisGrids";
import { Grid } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { CreateObjectAttribute } from "./CreateObjectAttribute";
import { SettingsObjectAttributeDefsTab } from "./SettingsObjectAttributeDef";
import { OmisCol, OmisTabs } from "../../shared/OmisLayouts";

var state = null;

export function SettingsObjectAttributes(props) {
    var { state } = useLocation();
    const [selectedTabValue, setSelectedTabValue] = useState(0);

    useEffect(() => {
        if (state && state.selectedObjectAttributeTabValue) {
            if (/[0-9]/.test(state.selectedObjectAttributeTabValue)) {
                setSelectedTabValue(parseInt(state.selectedObjectAttributeTabValue));
            } else {
                setSelectedTabValue(0);
            }
        }
    }, []);

    function tabChanged(e) {
        setSelectedTabValue(e);
    }

    var objectAttributeTabs = [
        {
            title: t("ObjectAttributes_Title"),
            content: <SettingsObjectAttributesTab {...state} />
        },
        {
            title: t("Settings_ObjectAttributeDef"),
            content: <SettingsObjectAttributeDefsTab {...state} />
        }
    ];

    if (props.isobjectdetails) {
        objectAttributeTabs.pop(objectAttributeTabs[1]);
    }

    return (
        <div className="omis-header-box">
            {
                props.objectid ?
                    null
                    :
                    <OmisCol xs={1}>
                        <OmisIconButton id={"backButton"} 
                            iconcontent={<ArrowBackIcon fontSize="inherit" />}
                            text="Back" component={RouterLink} to={"/settings"} state={state ?? { selectedtabvalue: TabConstants.Objects }} />
                    </OmisCol>
            }
            <OmisCol xs={12}>
                <OmisTabs tabs={objectAttributeTabs} onChange={tabChanged} tabvalue={selectedTabValue} />
            </OmisCol>
        </div>
    );
}

export function SettingsObjectAttributesTab(props) {

    state = { selectedtabvalue: TabConstants.Objects, selectedObjectAttributeTabValue: ObjectAttributeTabConstants.ObjectAttribute }
    var url = "/api/settings/settingsobjects/getobjectattributes";

    if (props.objectid) {
        url = `${url}?objectid=${props.objectid}`;
    }

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    function handleEditClick(rowID, e) {
        if (e) {
            setModalTitle(t("Action_Edit"));
        } else {
            setModalTitle(null);
            if (typeof rowID !== 'number') {
                rowID = null
            }
        }
        setModalContent(<CreateObjectAttribute onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID} rowVals={e} objectid={props.objectid} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    const handleDelete = (rowID) => {
        httpclientService.post(`/api/settings/settingsobjects/deleteobjectattribute?id=${rowID}`).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    }

    return (
        <>
            <OmisGrid actiondropdown={props.noActions ? null : { edit: handleEditClick, delete: handleDelete }} headerText={t("ObjectAttributes")}
                newButton={props.noActions ? null : <OmisButtonNewItem id={"newItemButton"} onClick={handleEditClick} text={t("Action_New")} />}
                apiUrl={url}
                change={changeCounter}
                noExportButton
                {...props} />

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"lg"}
                title={modalTitle}
                content={modalContent}
                contentHeight={600}
            />
        </>
    );
}