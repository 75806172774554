import React, { useState } from "react";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import { OmisButtonSecondary, OmisIconButton } from "../../shared/OmisButtons";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { OmisCol, OmisContainer, OmisRow, OmisTabs } from "../../shared/OmisLayouts";
import { OmisDialog, OmisLabel } from "../../shared/OmisDisplays";
import FaultKind from "./FaultKind";
import { SettingsEquiClasses } from "../Equipments/SettingsEquiClasses";
import { FaultEquiClassRecipients } from "./FaultEquiClassRecipients";
import { SettingsAutoDispatchSettings } from "./SettingsAutoDispatchSettings";
import FaultEquiClassDefPopup_Content from "./FaultEquiClassDefPopup_Content";
import { useEffect } from "react";
import HttpClientService from "../../../services/httpclient.service";

export function FaultEquiClassDefDetails(props) {

    var { state } = useLocation();
    const navigate = useNavigate();

    const [details, setDetails] = useState(state);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);

    const tabs = [
        {
            title: t("FaultKinds"),
            content: <FaultKind faultReportKindID={details?.faultReportKindID} faultEquiClassDefID={details?.id} faultEquiClassGroupID={details?.faultEquiClassGroupID} hideBack />
        },
        {
            title: t("EquiClasses"),
            content: <SettingsEquiClasses faultReportKindID={details?.faultReportKindID} faultEquiClassDefID={details?.id} faultEquiClassGroupID={details?.faultEquiClassGroupID} hideBack />
        },
        {
            title: t("FaultEquiClassRecipient"),
            content: <FaultEquiClassRecipients faultReportKindID={details?.faultReportKindID} faultEquiClassDefID={details?.id} faultEquiClassGroupID={details?.faultEquiClassGroupID} />
        },
        {
            title: t("AutoDispatchSettings"),
            content: <SettingsAutoDispatchSettings faultReportKindID={details?.faultReportKindID} faultEquiClassDefID={details?.id} faultEquiClassGroupID={details?.faultEquiClassGroupID} />
        },
    ];

    useEffect(() => {
        HttpClientService.get(`/api/settings/faultequiclassdef/getfaultequiclassdefdetails?id=${state?.id}`).then((response) => {
            if (response) {
                setDetails(response);
            }
        });
    },[])
    
    function handleEditClick(rowID, e) {
        if (e) {
            setModalTitle(t("Action_Edit"));
        } else {
            setModalTitle(null);
            if (typeof rowID !== 'number') {
                rowID = null
            }
        }

        var rowVals = { ...e };
        if (props.faultEquiClassDefID && props.faultEquiClassDefID > 0) {
            rowVals.faultEquiClassDefID = props.faultEquiClassDefID;
        }
        setModalContent(<FaultEquiClassDefPopup_Content selectedRowID={details?.id} handleModalClose={handleModalClose} handleModalCancel={handleModalCancel} faultEquiClassGroups={details?.faultEquiClassGroupID} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = (newEquiClassVals) => {
        setModalOpen(false);
        navigate(0);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    return (
        <>
            <div className="omis-header-box">
                <OmisCol xs={1}>
                    <OmisIconButton id={"backButton"}
                        iconcontent={<ArrowBackIcon fontSize="inherit" />}
                        text="Back" onClick={() => { navigate(-1); }} />
                </OmisCol>
            </div>
            <Card>
                <OmisContainer fluid>
                    <OmisRow>
                        <OmisCol xs={6}>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <strong>{t("Description")}:</strong>
                                    <OmisLabel label={details?.description ?? details?.faultequiclassdefdesc} />
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <strong>{t("FaultReportKind")}:</strong>
                                    <OmisLabel label={details?.faultReportKind ?? details?.faultreportkind} />
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <strong>{t("FaultKind_SortIndex")}:</strong>
                                    <OmisLabel label={details?.sortIndex ?? details?.sortindex} />
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <strong>{t("FaultEquiClassGroup")}:</strong>
                                    <OmisLabel label={details?.faultEquiClassGroup ?? details?.faultequiclassgroup} />
                                </OmisCol>
                            </OmisRow>
                        </OmisCol>
                        <OmisCol xs={{ span: 2, offset: 4 }}>
                            <OmisButtonSecondary id={"editButton"} text={t("Action_Edit")} onClick={handleEditClick } />
                        </OmisCol>
                    </OmisRow>
                </OmisContainer>

                <OmisRow>
                    <OmisTabs tabs={tabs} />
                </OmisRow>

            </Card>

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                title={modalTitle}
                content={modalContent}
                maxWidth={"lg"}
            />
        </>
    );
}