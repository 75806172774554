import React, { useState } from "react";
import { t } from "i18next";
import { Link as RouterLink } from "react-router-dom";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisIconButton } from "../../shared/OmisButtons";
import { OmisGrid } from "../../shared/OmisGrids";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { OmisDialog } from "../../shared/OmisDisplays";
import { EditEntityMapping } from "./EditEntityMapping";
import { OmisCol, OmisContainer } from "../../shared/OmisLayouts";

export function SettingsEntityMappings(props) {
    var url = "/api/settings/miscellaneous/getentitymappings";

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    function handleEditClick(rowID, e) {
        if (e) {
            setModalTitle(t("Action_Edit"));
        } else {
            setModalTitle("");
            if (typeof rowID !== 'number') {
                rowID = null
            }
        }
        setModalContent(<EditEntityMapping onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID} rowVals={e} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    return (
        <OmisContainer fluid>

            <div className="omis-header-box">
                {
                    props.hideBack ?
                        null
                        :
                        <OmisCol xs={1}>
                            <OmisIconButton id={"backButton"}
                                iconcontent={<ArrowBackIcon fontSize="inherit" />}
                                text="Back" component={RouterLink} to={"/settings"} state={{ selectedtabvalue: TabConstants.Miscellaneous }} />
                        </OmisCol>

                }

                <OmisGrid actiondropdown={{ edit: handleEditClick }} headerText={"EntityMapping"}                
                    apiUrl={url}
                    change={changeCounter}
                    noExportButton
                    {...props} />

                <OmisDialog onClose={handleModalCancel} open={modalOpen}
                    maxWidth={"md"}
                    title={modalTitle}
                    content={modalContent}
                    contentHeight={400}
                />
            </div>
        </OmisContainer>
    );
}