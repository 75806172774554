import React, {useState, useEffect} from 'react';
import NavMenu from './NavMenu';
import Icon from "@mdi/react";
import {mdiChevronLeft, mdiChevronRight} from "@mdi/js";
import AuthService from "../services/auth.service";
import httpclientService from '../services/httpclient.service';
import { OmisCol, OmisContainer, OmisRow, OmisStack } from './shared/OmisLayouts';
import { useTranslation } from "react-i18next";
import { Image } from "react-bootstrap";

const useViewport = () => {
	const [width, setWidth] = React.useState(window.innerWidth);
  
	React.useEffect(() => {
	  const handleWindowResize = () => setWidth(window.innerWidth);
	  window.addEventListener("resize", handleWindowResize);
	  return () => window.removeEventListener("resize", handleWindowResize);
	}, []);

	return { width };
}

export default function Layout(props) {

	const { t, i18n } = useTranslation();
	const [collapsed, setCollapsed] = useState(false);
	const [customerName, setCustomerName] = useState("");
    const [loggedIn, setLoggedIn] = useState(AuthService.isAuthenticated());

	const { width } = useViewport();
	const breakpoint = 768;

	useEffect(() => {
		if (customerName === "" && !loggedIn) {
			httpclientService.get("/api/profile/getcustomername").then((response) => {
				setCustomerName(response);
			});
		}
	},[]);
    function toggleNavbar() {
        setCollapsed((prevState) => !prevState);
    }

	if(width < breakpoint && collapsed == false)
	{
		setCollapsed(true);
	}

    if (!loggedIn) {
		return (

			<OmisContainer fluid className="loginBackground">
                <OmisRow>
					<OmisCol xs={6} className="d-none d-sm-none d-md-block">
						<OmisRow className='loginLeftHeader justify-content-start' style={{ width: '100% !important' }}>
							<div className="loginLeftHeaderItems">
								<OmisStack direction="horizontal">
									<OmisCol className="d-none d-sm-none d-md-block align-self-start">
										<Image src="https://www.omis.at/uploads/images/omis-logo.png" style={{ width: 210, height: 45, marginRight: 5 }} /> 
									</OmisCol>
								</OmisStack>
							</div>
                        </OmisRow>
                        <OmisRow>
							<OmisCol xs={12} className="loginLeft d-none d-sm-none d-md-block">
								<div className='loginLeftText'>
									<h2>{`omis ${customerName}`}</h2>
									<p>{t("Login_Starttext")}</p>
								</div>

								<div className='loginLeftBackgroundLogo'>

									<Image src={require('../omis_cube_multi.png')} style={{ width: 300, height: 300, marginRight: 5, marginTop: 50 }} /> 
								</div>
							</OmisCol>
                        </OmisRow>
                    </OmisCol>
					<OmisCol xs={12} md={6}>
                        {props.children}
                    </OmisCol>
                </OmisRow>
            </OmisContainer>
			
			
        );
    }

    return (
      <div>
          <OmisContainer fluid>
              <OmisRow xs={12}>
                  <OmisCol className={collapsed ? "omis-navbar closed" : "omis-navbar"}>
                      <div className={"navbar-toggler d-none d-sm-none d-md-block"}>
                          <button id="toggleNavBarButton" onClick={() => toggleNavbar()}>
                              <Icon path={collapsed ? mdiChevronRight : mdiChevronLeft} size={1}></Icon>
                          </button>
                      </div>
                        <NavMenu collapsed={collapsed} />
                  </OmisCol>
                  <OmisCol className='topSpacingContent' xs={collapsed ? {offset: 2} : {offset: 5}} sm={collapsed ? {offset: 2} : {offset: 4}} md={collapsed ? {offset: 2} : {offset: 4}} lg={collapsed ? {offset: 1} : {offset: 3}} xl={collapsed ? {offset: 1} : {offset: 3}} xxl={collapsed ? {offset: 1} : {offset: 2}}>
                      <OmisContainer fluid>
                          {props.children}
                      </OmisContainer>
                  </OmisCol>
              </OmisRow>
          </OmisContainer>
      </div>
    );
}
