import { mdiArchiveSettings, mdiDotsHorizontal } from "@mdi/js";
import { Card, Chip } from "@mui/material";
import { OmisObjectChip } from "../shared/OmisChips";
import { OmisLabel, OmisTimeGap, OmisTimeGapWithWords } from "../shared/OmisDisplays";
import { OmisDropDownMenu } from "../shared/OmisButtons";
import React, { useEffect, useState } from "react";
import httpclientService from "../../services/httpclient.service";
import { t } from "i18next";
import DateHelperService from "../../services/datehelper.service";
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";

export function DetailsPastInspectionsInfo(props) {
    const [_list, setPastInspections] = useState([]);
    const [changeCounter, setChangeCounter] = useState(0);

    useEffect(() => {
        if (props.data?.totdid && props.data?.totdid>0) {
            httpclientService.get(`api/inspections/getpastinspections?totdID=${props.data.totdid}`).then((response) => {
                setPastInspections(response);
            });
        }
    }, [changeCounter]);

    useEffect(() => {
        <RenderPastInspections list={ _list } { ...props } />;
    }, [_list]);

    function ArchivTestDate(e) {
        httpclientService.post(`api/inspections/archivtestdate`, [e.target.id]).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    };

    return (
        <RenderPastInspections list={_list} archivTestDate={ArchivTestDate} { ...props } />);
}

function RenderPastInspections(props) {

    //function DownloadTestDate(e) {
    //    httpclientService.get(`api/inspections/downloadtestdate?totdID=${e.target.id}`);
    //};

    const childItemsForRowDotsMenu = [
        //{
        //    subMenuIcon: mdiInboxArrowDown,
        //    onClick: DownloadTestDate,
        //    menuName: t("Action_Download"),
        //},
        {
            subMenuIcon: mdiArchiveSettings,
            onClick: props.archivTestDate,
            menuName: t("Action_Archive"),
        },
    ];


    let lastWrittenDate = new Date();

    return props.list !== null ? props.list.map((item, index) => {
        let itemDate = new Date(item.processDate);
        let writeDate = false;
        if (!DateHelperService.IsSameDay(lastWrittenDate, itemDate)) {
            lastWrittenDate = itemDate;
            writeDate = true;
        }

        return (

            <OmisContainer fluid key={index.toString() + "-mainGrid"} name={index.toString() + "-mainGrid"}>
                {
                    writeDate || index === 0 ?
                        <OmisCol xs={12}>
                            <strong className="detailsCardTitle"><OmisTimeGapWithWords date={item.processDate} /></strong>
                        </OmisCol> : null
                }
                <Card
                    key={index.toString() + "-mainCard"} name={index.toString() + "-mainCard"}
                    style={{
                        alignItems: "center"
                    }}
                >
                    <OmisLabel label={item.description} />
                    <OmisRow>
                        <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                            <OmisRow>
                                <OmisCol xs={3} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                    <span>{t("ExecutedBy")}:</span>
                                </OmisCol>
                                <OmisCol xs={4} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                    <span>{item.executedBy}</span>
                                </OmisCol>
                                <OmisCol xs={1} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                    <span>{t("At")}</span>
                                </OmisCol>
                                <OmisCol xs={4} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                    <OmisObjectChip objectid={item.objectID} text={item.objectID} />
                                </OmisCol>
                            </OmisRow>
                        </OmisCol>
                        <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <OmisRow>
                                <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <Chip clickable={false} color={item.hasDoneOnTime ? "success" : "error"} className={"execTimeChip"} label={item.hasDoneOnTime ? t("ExecutedInTime") : t("ExecutedNotInTime")} />
                                </OmisCol>
                                <OmisCol xs={4} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <OmisTimeGap date={item.processDate}></OmisTimeGap>
                                </OmisCol>
                                <OmisCol xs={2} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <OmisDropDownMenu id={"dotsDropdownButton"} rowid={item.id} menuicon={mdiDotsHorizontal} childitems={childItemsForRowDotsMenu} />
                                </OmisCol>
                            </OmisRow>
                        </OmisCol>
                    </OmisRow>
                </Card>
                <br/>
            </OmisContainer>
        );
    }
    ) : null;
}