import React from "react";
import { Card} from "@mui/material";
import { OmisTimeGap, OmisTimeGapWithWords } from "../shared/OmisDisplays";
import { OmisUserChip } from "../shared/OmisChips";
import DatehelperService from "../../services/datehelper.service";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../shared/OmisLayouts";

export function NoteHistory(props) {
    
    return (
        props.itemsList && props.itemsList.length > 0 ?
            props.itemsList.map((item, index) => {
                return (
                    <OmisContainer fluid key={`${index}Div`}>
                        <OmisRow>
                            {
                                index !== 0 && !DatehelperService.IsSameDay(new Date(item.date), new Date(props.itemsList[index - 1].date)) ?
                                    <OmisCol xs={12} key={`${index}Grid`}>
                                        <strong key={`${index}Strong`} className="detailsCardTitle"><OmisTimeGapWithWords key={`${index}Date`} date={item.created} />
                                        </strong>
                                    </OmisCol> : index === 0 ?
                                    <OmisCol xs={12} key={`${index}Grid`}>
                                        <strong key={`${index}Strong`} className="detailsCardTitle"><OmisTimeGapWithWords key={`${index}Date`} date={item.created} />
                                        </strong>
                                    </OmisCol> : null
                            }
                        </OmisRow>
                        <OmisRow>
                            <OmisCol key={item.noteID} xs={12}>
                                <div key={`${index}_RowCard`}>
                                    <Card variant={"outlined"}>
                                            <OmisCol xs={12}>
                                                <OmisStack direction="horizontal">
                                                    <OmisCol xs={10}>
                                                        <strong>{`${item.caption}`}</strong>
                                                    </OmisCol>
                                                    <OmisCol xs={4}>
                                                        <OmisTimeGap date={item.date} />
                                                    </OmisCol>
                                                </OmisStack>
                                            </OmisCol>
                                            <OmisCol xs={12}>
                                                <p>{item.note}</p>
                                            </OmisCol>
                                            <OmisUserChip userFullName={item.user} userName={item.userName} userID={item.userID} />
                                    </Card>
                                    <br></br>
                                </div>
                            </OmisCol>
                        </OmisRow>
                    </OmisContainer>
                );
            })            
            :
            null
    );
}