import React from 'react';

export const OmisLogo = () => {
	return (
		<svg width="32" height="37" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1809_4973)">
			<path d="M24.224 8.00016C25.5123 7.17171 25.5123 5.82853 24.224 5.00008C22.9358 4.17163 20.847 4.17163 19.5587 5.00008C18.2704 5.82853 18.2704 7.17171 19.5587 8.00016C20.847 8.82861 22.9358 8.82861 24.224 8.00016Z" fill="#FF7043"/>
			<path d="M11.4565 6.21011C12.0194 6.30245 12.5344 6.48873 12.9494 6.75012C13.5681 7.14796 13.9156 7.68754 13.9156 8.25017C13.9156 8.81279 13.5681 9.35237 12.9494 9.75021C12.3308 10.148 11.4917 10.3715 10.6168 10.3715C9.74185 10.3715 8.90277 10.148 8.28411 9.75021C7.87762 9.48333 7.58794 9.15216 7.44435 8.79018L4.00757 11.0002L13.3382 17.0004C13.7506 17.2656 14.31 17.4146 14.8933 17.4146C15.4766 17.4146 16.036 17.2656 16.4484 17.0004L25.779 11.0002L14.8933 4.00005L11.4565 6.21011Z" fill="#FF7043"/>
		</g>
		<g clipPath="url(#clip1_1809_4973)">
			<path d="M27.5841 14.5016C28.8724 13.6732 29.9168 14.3448 29.9168 16.0017C29.9168 17.6586 28.8724 19.6733 27.5841 20.5018C26.2958 21.3302 25.2515 20.6586 25.2515 19.0017C25.2515 17.3448 26.2958 15.3301 27.5841 14.5016Z" fill="#C2502C"/>
			<path d="M22.5923 27.7119C22.8019 27.1228 22.9146 26.5329 22.9188 26.0019C22.9188 25.2062 22.6731 24.6012 22.2356 24.3199C21.7982 24.0386 21.2048 24.104 20.5862 24.5019C19.9675 24.8997 19.3742 25.5973 18.9367 26.4412C18.4993 27.2852 18.2535 28.2063 18.2535 29.002C18.2578 29.5275 18.3704 29.9725 18.5801 30.292L15.1433 32.5021L15.1433 20.5017C15.1433 19.9713 15.3072 19.3572 15.5988 18.7946C15.8904 18.232 16.286 17.7669 16.6984 17.5017L26.029 11.5015L26.029 25.5019L22.5923 27.7119Z" fill="#C2502C"/>
		</g>
		<g clipPath="url(#clip2_1809_4973)">
			<path d="M2.33263 14.5016C1.04434 13.6732 -3.142e-05 14.3448 -3.14527e-05 16.0017C-3.14855e-05 17.6586 1.04433 19.6733 2.33263 20.5018C3.62092 21.3302 4.66528 20.6586 4.66528 19.0017C4.66528 17.3448 3.62092 15.3301 2.33263 14.5016Z" fill="#FFA082"/>
			<path d="M7.32449 27.7119C7.11483 27.1228 7.00218 26.5329 6.99791 26.0019C6.99791 25.2062 7.24367 24.6012 7.68113 24.3199C8.11859 24.0386 8.71191 24.104 9.33057 24.5019C9.94923 24.8997 10.5426 25.5973 10.98 26.4412C11.4175 27.2852 11.6632 28.2063 11.6632 29.002C11.659 29.5275 11.5463 29.9725 11.3367 30.292L14.7734 32.5021L14.7734 20.5017C14.7734 19.9713 14.6096 19.3572 14.318 18.7946C14.0263 18.232 13.6308 17.7669 13.2183 17.5017L3.8877 11.5015L3.8877 25.5019L7.32449 27.7119Z" fill="#FFA082"/>
		</g>
		<defs>
			<clipPath id="clip0_1809_4973">
				<rect width="18.4889" height="18.4889" fill="white" transform="matrix(0.8411 -0.540879 0.8411 0.540879 0.897461 10.0002)"/>
			</clipPath>
			<clipPath id="clip1_1809_4973">
				<rect width="18.4889" height="20.0006" fill="white" transform="matrix(0.8411 -0.540879 -1.97793e-08 -1 14.3657 36.0022)"/>
			</clipPath>
			<clipPath id="clip2_1809_4973">
				<rect width="18.4889" height="20.0006" fill="white" transform="matrix(-0.8411 -0.540879 1.97793e-08 -1 15.551 36.0022)"/>
			</clipPath>
		</defs>
	</svg>
	)
}