import React, { useEffect, useState } from "react";
import { t } from "i18next";
import httpclientService from "../../../services/httpclient.service";
import { Link as RouterLink } from "react-router-dom";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisIconButton } from "../../shared/OmisButtons";
import { ArrowBack as ArrowBackIcon} from "@mui/icons-material";
import { OmisEditTextBoxNoForm } from "../../shared/OmisInputs";
import { OmisCol, OmisRow } from "../../shared/OmisLayouts";

var state = { selectedtabvalue: TabConstants.System }

export function SettingsPasswordPolicy(props) {

    const [policies, setPolicies] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    useEffect(() => {
        LoadPolicy();
    }, [changeCounter]);

    function LoadPolicy() {
        httpclientService.get("/api/profile/getpasswordpolicy").then((response) => {
            if (response) {
                setPolicies(response);
            }
        });
    }

    function handleSave(column, value) {
        httpclientService.post("/api/settings/system/savepolicyedit", JSON.stringify({ columnName: column ?? "", newPolicyVal: value ?? 0 })).then((response) => {
            setChangeCounter((prevState) => prevState + 1);
        });
    }

    return (
        <>
            <OmisRow>
                <OmisCol xs={1}>
                        <OmisIconButton id={"backButton"}
                            iconcontent={<ArrowBackIcon fontSize="inherit" />}
                            text="Back" component={RouterLink} to={"/settings"} state={state} />
                </OmisCol>
                <OmisCol xs={8}>
                        <h3>{t("PasswordPolicy")}</h3>
                </OmisCol>
            </OmisRow>

            <br/>
            <OmisRow>
                <OmisCol xs={4}>
                    <OmisCol xs={10}>
                        <OmisEditTextBoxNoForm labeltext={t("PasswordPolicy_Length")} value={policies?.length ?? 0} placeholder={t("PasswordPolicy_Length")} onSave={handleSave.bind(this, "Length")} />
                    </OmisCol>
                </OmisCol>
                <OmisCol xs={4}>
                    <OmisCol xs={10}>
                        <OmisEditTextBoxNoForm labeltext={t("PasswordPolicy_Digits")} value={policies?.digits ?? 0} placeholder={t("PasswordPolicy_Digits")} onSave={handleSave.bind(this, "Digits")} />
                    </OmisCol>
                </OmisCol>
                <OmisCol xs={4}>
                    <OmisCol xs={10}>
                        <OmisEditTextBoxNoForm labeltext={t("PasswordPolicy_Special")} value={policies?.special ?? 0} placeholder={t("PasswordPolicy_Special")} onSave={handleSave.bind(this, "Special")} />
                    </OmisCol>
                </OmisCol >

                <OmisCol xs={4}>
                    <OmisCol xs={10}>
                        <OmisEditTextBoxNoForm labeltext={t("PasswordPolicy_UpperCase")} value={policies?.upperCase ?? 0} placeholder={t("PasswordPolicy_UpperCase")} onSave={handleSave.bind(this, "UpperCase")} />
                    </OmisCol>
                </OmisCol>
                <OmisCol xs={4}>
                    <OmisCol xs={10}>
                        <OmisEditTextBoxNoForm labeltext={t("PasswordPolicy_LowerCase")} value={policies?.lowerCase ?? 0} placeholder={t("PasswordPolicy_LowerCase")} onSave={handleSave.bind(this, "LowerCase")} />
                    </OmisCol>
                </OmisCol>
                <OmisCol xs={4}>
                    <OmisCol xs={10}>
                        <OmisEditTextBoxNoForm labeltext={t("PasswordPolicy_DaysUntilExpiration")} value={policies?.daysUntilExpiration ?? 0} placeholder={t("PasswordPolicy_DaysUntilExpiration")} onSave={handleSave.bind(this, "DaysUntilExpiration")} />
                    </OmisCol>
                </OmisCol>
                <OmisCol xs={4}>
                    <OmisCol xs={10}>
                        <OmisEditTextBoxNoForm labeltext={t("PasswordPolicy_MaxLoginAttempts")} value={policies?.maxLoginAttempts ?? 0} placeholder={t("PasswordPolicy_MaxLoginAttempts")} onSave={handleSave.bind(this, "MaxLoginAttempts")} />
                    </OmisCol>
                </OmisCol>
                <OmisCol xs={4}>
                    <OmisCol xs={10}>
                        <OmisEditTextBoxNoForm labeltext={t("PasswordPolicy_HistoryCount")} value={policies?.historyCount ?? 0} placeholder={t("PasswordPolicy_HistoryCount")} onSave={handleSave.bind(this, "HistoryCount")} />
                     </OmisCol>
                </OmisCol>
                <OmisCol xs={4}>
                    <OmisCol xs={10}>
                        <OmisEditTextBoxNoForm labeltext={t("UserLockedMessage")} value={policies?.userLockedMessage ?? ""} placeholder={t("UserLockedMessage")} onSave={handleSave.bind(this, "UserLockedMessage")} />
                    </OmisCol>
                </OmisCol>
            </OmisRow>
        </>
    );
}