import React, { useState } from "react";
import { t } from "i18next";
import { Link as RouterLink } from "react-router-dom";
import httpclientService from "../../../services/httpclient.service";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisButtonNewItem, OmisIconButton } from "../../shared/OmisButtons";
import { OmisGrid } from "../../shared/OmisGrids";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { OmisDialog } from "../../shared/OmisDisplays";
import { CreateWebServiceSettings } from "./CreateWebServiceSettings";
import { OmisCol } from "../../shared/OmisLayouts";

export function SettingsWebServiceSettings(props) {
    var url = "/api/settings/settingsfsmintern/getwebservicesettings";
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    function handleEditClick(rowID, e) {
        setModalTitle(t("Action_Edit"));
        setModalContent(<CreateWebServiceSettings onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID} rowVals={e} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    const handleDelete = (rowID) => {
        httpclientService.post(`/api/settings/settingsfsmintern/deletewebservicesettings?id=${rowID}`).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    }

    return (
        <div className=" omis-header-box">
            <OmisCol xs={1}>
                <OmisIconButton id={"backButton"}
                    iconcontent={<ArrowBackIcon fontSize="inherit" />}
                    text="Back" component={RouterLink} to={"/settings"} state={{ selectedtabvalue: TabConstants.FSMIntern }} />
            </OmisCol>

            <OmisGrid headerText={t("Settings_WebServiceSettings")}
                actiondropdown={{ edit: handleEditClick, delete: handleDelete }}
                newButton={<OmisButtonNewItem id={"newItemButton"} component={RouterLink} to={"/settings/settingsfsmintern/createwebservicesettings/"} text={t("Action_New")} />}
                apiUrl={url}
                change={changeCounter}
                noExportButton
                {...props} />

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"lg"}
                title={modalTitle}
                content={modalContent}
                contentHeight={400}
            />
        </div>
    );
}