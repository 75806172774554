import React, {Component, useEffect, useState} from 'react';
import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet";
import httpclientService from "../../services/httpclient.service";
import { t } from "i18next";
import 'leaflet.markercluster';
import { createPathComponent } from '@react-leaflet/core';
import { Link as RouterLink } from "react-router-dom";
import { mdiDotsHorizontal, mdiInboxArrowUpOutline, mdiPlusBoxOutline } from "@mdi/js";
import { OmisDropDownMenu, OmisPriButtonWithStartIcon } from './OmisButtons';
import { OmisSkeleton } from "./OmisProgressDisplays";
import { OmisCol, OmisStack } from './OmisLayouts';
const Leaflet = window.L;

export class Shared extends Component {
    constructor(props) {
        super(props);
    }
}

export function DetailsCardWithSkeleton(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [showSkeleton, setShowSkeleton] = useState(false);

    useEffect(() => {
        setTimeout(function() {
            setShowSkeleton(true);
        }, 0);

        if (props.url) {
            httpclientService.get(props.url)
                .then((data) => {
                    setData(data);
                    setIsLoading(false);
                });
        }

        return () => {
            setData(null);
            setIsLoading(true);
            setShowSkeleton(false);
        };
    }, []);

    useEffect(() => {
        setTimeout(function () {
            setShowSkeleton(true);
        }, 0);

        if (props.url) {
            httpclientService.get(props.url)
                .then((data) => {
                    setData(data);
                    setIsLoading(false);
                });
        }

        return () => {
            setData(null);
            setIsLoading(true);
            setShowSkeleton(false);
        };
    }, [props.reload]);

    return (
        <>
            {
                isLoading ?
                    showSkeleton ? <OmisSkeleton height={props.skeletonheight} /> : null
                    :
                    <>
                        { React.cloneElement(props.content, {data})}
                    </>
            }
        </>
    );
}

export function SmallMap(props) {
    const { latitude, longitude } = { ...props };

    const center = [48.25681315947919, 13.257844839165404];

    return (        
        <div className={"smallmap"}>
            {
                latitude && longitude ?
                    <MapContainer center={[latitude, longitude]} zoom={13} scrollWheelZoom={false} className={"smallmap"}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[latitude, longitude]}>
                            {/*<Popup>*/}
                            {/*    A pretty CSS3 popup. <br /> Easily customizable.*/}
                            {/*</Popup>*/}
                        </Marker>
                    </MapContainer>
                    :
                    <MapContainer center={center} zoom={1} scrollWheelZoom={false} className={"smallmap"}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {/*<Grid container justifyContent="center" alignItems="center"> <strong>There is no map data</strong></Grid>*/}
                    </MapContainer>
            }
        </div>
    );
}

export function MapWithMultiMarker(props) {

    const [markers, setMarkers] = useState([]);
    const [outerBounds, setOuterBounds] = useState([]);

    useEffect(() => {
        if (props.markers && props.markers.length > 0) {
            setMarkers(props.markers);
            setOuterBounds([...props.markers.values()].map((x, i) => [x.position.latitude, x.position.longitude]));
        }
    }, [props.markers]);

    const bounds = Leaflet.latLngBounds(outerBounds); // centralize map to all markers

    return (
        <div className={"bigmap"}>
            {
                markers && markers.length > 0 ?
                    <MapContainer scrollWheelZoom={true} className={"bigmap"} bounds={bounds} >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <MarkerClusterGroup>
                            {markers.map((item, idx) =>
                                item.position.latitude && item.position.longitude ?
                                    <Marker key={`marker-${idx}`} position={[item.position.latitude, item.position.longitude]}>
                                        {
                                            item.content ?
                                                <Popup>
                                                    {item.content}
                                                </Popup>
                                                :
                                                null
                                        }
                                    </Marker>
                                    : null
                            )}
                        </MarkerClusterGroup>
                    </MapContainer>
                    : null
            }
        </div>
    );
}

const MarkerClusterGroup = createPathComponent(({ children: _c, ...props }, ctx) => {
    const clusterProps: Record<string, any> = {};
    const clusterEvents: Record<string, any> = {};

    // Splitting props and events to different objects
    Object.entries(props).forEach(([propName, prop]) => propName.startsWith('on') ? (clusterEvents[propName] = prop)
        : (clusterProps[propName] = prop));

    // Creating markerClusterGroup Leaflet element
    const markerClusterGroup = Leaflet.markerClusterGroup(clusterProps);

    // Initializing event listeners
    Object.entries(clusterEvents).forEach(([eventAsProp, callback]) => {
        const clusterEvent = `cluster${eventAsProp.substring(2).toLowerCase()}`;
        markerClusterGroup.on(clusterEvent, callback);
    });

    return {
        instance: markerClusterGroup,
        context: { ...ctx, layerContainer: markerClusterGroup },
    };
});

export default MarkerClusterGroup;

export function SpaceComponent() {
    return (<>&nbsp;</>);
}

export function PrepareTopNavByContent(props) {
    const { state } = { ...props};
    return (
        <OmisStack direction="horizontal" className="ms-auto gap-1" style={{ marginRight: props.contentList?.length === 0 ? '1.5em' : '' }}>
            <OmisCol xs={"auto"}
                sm={"auto"}
                key={`childItemsForDotsMenuGrid`}>
                <OmisDropDownMenu id={"dotsDropdownButton"} menuicon={mdiDotsHorizontal} childitems={props.childItemsForDotsMenu} />
            </OmisCol>
            <OmisCol xs={"auto"}
                //sm={{ span: 4, offset: 2 }}
                key={`childItemsForAddMenuGrid`}>
                <OmisDropDownMenu id={"addDropdownButton"} menutitle={t("Action_Add")} menuicon={mdiPlusBoxOutline} childitems={props.childItemsForAddMenu} />
            </OmisCol>
            {
                props.contentList && props.contentList.length > 0 ?
                    props.contentList.map((item) => item)
                    :
                    props.showExportCVS ?
                        <OmisCol xs={5} sm={5} md={4} lg={4} xl={3} style={{ height: 45, paddingRight: '2em !important' }} key={`buttonsGrid`}>
                            <OmisPriButtonWithStartIcon state={state} iconpath={mdiInboxArrowUpOutline} component={RouterLink} to={"/reports/details/"} text={"Export CVS"} size={"small"} />
                        </OmisCol>
                        :
                        null
            }
        </OmisStack>
    );
}

export const TabChanged = (tabs, state, childItemsForDotsMenu, childItemsForAddMenu, callbackFunction, value) => {
    var contentList = tabs && tabs.length > 0 && tabs[value].topButtonsContentList ? tabs[value].topButtonsContentList : [];
    var showExportCVS = tabs && tabs.length > 0 && tabs[value].showExportCVS;
    var addMenus = childItemsForAddMenu.filter((item) => item.menuName !== tabs[value].removeFromAddMenu);
    return callbackFunction(<PrepareTopNavByContent contentList={contentList} state={state} showExportCVS={showExportCVS} childItemsForDotsMenu={childItemsForDotsMenu} childItemsForAddMenu={addMenus} />);
};

export function SortStringArray(arr) {
    return arr.sort(function (a, b) {
        let x = "";
        let y = "";
        if (a.content) {
            x = a.content.toLowerCase();
            y = b.content.toLowerCase();
        }

        if (a.displayText) {
            x = a.displayText.toLowerCase();
            y = b.displayText.toLowerCase();
        }

        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
    });
}

export function generateUniqueRandom(haveIt) {
    let random = (Math.random() * 255).toFixed();
    random = -1 * Number(random);

    if (!haveIt.includes(random)) {
        return random;
    } else {
        return generateUniqueRandom(haveIt);
    }
}

export function checkIsNumeric(str) {
    return /[0-9]/.test(str);
}

export function SetEmptyValuesToNull(values) {
    const keys = Object.keys(values);
    keys.forEach(key => {
        if (values[key] === '') {
            values[key] = null;
        }
    });
}

export function ReformatDateString(dateStr, locale) { //for fix the date format example:from "15.1.2022 15:44" to "15.01.2022 15:44"
    if (dateStr.includes(" ")) {
        let dateTimeArr = dateStr.split(" ");
        if (dateTimeArr?.length > 0) {
            let dateArr = locale.includes("de") ? dateTimeArr[0].split(".") : dateTimeArr[0].split("/");
            if (dateArr?.length > 0) {
                let parser = locale.includes("de") ? "." : "/";
                let newDate = `${dateArr[0].padStart(2, '0')}${parser}${dateArr[1].padStart(2, '0')}${parser}${dateArr[2]}`;
                let newDateStr = `${newDate} ${dateTimeArr[1]}`;
                return newDateStr;
            }
        }
    } else {
        return dateStr;
    }
}

export function changeTimezone(date, ianatz) {

  // suppose the date is 12:00 UTC
  var invdate = new Date(date.toLocaleString('en-US', {
    timeZone: ianatz
  }));

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  var diff = date.getTime() - invdate.getTime();

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff); // needs to substract

}

export function convertToDate(dateValue) {
  if (dateValue.includes("T")) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Create a new date in the specified timezone
    const tmpDate = changeTimezone(new Date(), timeZone);

    // Split the date and time from the input
    const dateArr = dateValue.split("T")[0].split("-");
    const timeArr = dateValue.split("T")[1].split(":");

    // Set the date components, adjusting month to zero-index
    tmpDate.setFullYear(parseInt(dateArr[0]), parseInt(dateArr[1]) - 1, parseInt(dateArr[2]));
    tmpDate.setHours(parseInt(timeArr[0]), parseInt(timeArr[1]), parseInt(timeArr[2]), 0);

    return tmpDate;
  } else if (dateValue.includes(" ") && dateValue.includes(".")) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Create a new date in the specified timezone
    const tmpDate = changeTimezone(new Date(), timeZone);

    // Split the date and time from the input
    const dateArr = dateValue.split(" ")[0].split(".");
    const timeArr = dateValue.split(" ")[1].split(":");

    // Set the date components, adjusting month to zero-index
    tmpDate.setFullYear(parseInt(dateArr[2]), parseInt(dateArr[1]) - 1, parseInt(dateArr[0]));
    tmpDate.setHours(parseInt(timeArr[0]), parseInt(timeArr[1]), parseInt(timeArr[2]), 0);

    return tmpDate;
  } else if (dateValue.includes(" ") && dateValue.includes("/")) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Create a new date in the specified timezone
    const tmpDate = changeTimezone(new Date(), timeZone);

    // Split the date and time from the input
    const dateArr = dateValue.split(" ")[0].split("/");
    const timeArr = dateValue.split(" ")[1].split(":");

    // Set the date components, adjusting month to zero-index
    tmpDate.setFullYear(parseInt(dateArr[2]), parseInt(dateArr[1]) - 1, parseInt(dateArr[0]));
    tmpDate.setHours(parseInt(timeArr[0]), parseInt(timeArr[1]), parseInt(timeArr[2]), 0);

    return tmpDate;
  }

}