import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {OmisGrid} from "../../shared/OmisGrids";
import {OmisButtonNewItem, OmisIconButton, OmisButtonPrimarySubmit, OmisButtonSecondary} from "../../shared/OmisButtons";
import {OmisDeleteQuestionDialog, OmisDialog} from "../../shared/OmisDisplays";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {OmisTextArea, OmisTextBox} from "../../shared/OmisInputs";
import DialogActions from "@mui/material/DialogActions";
import {OmisDropdown} from "../../shared/OmisDropdowns";
import { Link as RouterLink } from "react-router-dom";
import httpclientService from "../../../services/httpclient.service";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisCol, OmisRow } from "../../shared/OmisLayouts";

var state = { selectedtabvalue: TabConstants.Templates }
export default function TextTemplates(props) {
    const { t } = useTranslation();

    const [changeCounter, setChangeCounter] = useState(0);
    const [teams, setTeams] = useState([]);

    const [newModalOpen, setNewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [selectedRowID, setSelectedRowID] = useState(0);

    const handleModalOpen = () => {
        setNewModalOpen(true);
    };

    const handleModalClose = () => {
        setNewModalOpen(false);
        setEditModalOpen(false);
        setDeleteModalOpen(false);
        setSelectedRowID(0);
    };

    useEffect(() => {
        httpclientService.get("/api/settings/texttemplates/getteamslist").then((response) => {
            setTeams(response)
        })
    }, [])

    function handleEditClick(item) {
        setSelectedRowID(item);
        setEditModalOpen(true);
    }

    function handleDeleteClick(item) {
        setSelectedRowID(item);
        setDeleteModalOpen(true);
    }

    return (
        <div className="omis-header-box">

            <OmisCol xs={1}>
                <OmisIconButton id={"backButton"}
                    iconcontent={<ArrowBackIcon fontSize="inherit" />}
                    text="Back" component={RouterLink} to={"/settings"} state={state} />
            </OmisCol>

            <OmisGrid actiondropdown={{ edit: handleEditClick, delete: handleDeleteClick }}
                      change={changeCounter}
                      newButton={<OmisButtonNewItem id={"newItemButton"} onClick={handleModalOpen} text={t("Action_New")} />}
                      headerText={t("SettingsTextModules")}
                      apiUrl={"/api/settings/texttemplates/gettexttemplatelist"}
            />
            <OmisDialog onClose={handleModalClose} open={newModalOpen}
                        title={t("New_TextModule")}
                        content={<TextTemplatePopup_Content />}
            />
            <OmisDialog onClose={handleModalClose} open={editModalOpen}
                        title={"Textbaustein bearbeiten"}
                        content={<TextTemplatePopup_Content />}
            />
            <OmisDeleteQuestionDialog open={deleteModalOpen}
                                      handleModalClose={handleModalClose}
                                      deleteFunction={handleDeleteItem}
            />
        </div>
    );

    function handleDeleteItem() {
        httpclientService.post(`/api/settings/texttemplates/delete?id=${selectedRowID}`).then((response) => {
            setChangeCounter((prevState) => prevState + 1);
            handleModalClose();
        })
    }

    function TextTemplatePopup_Content(props) {
        var categories = [
            {
                key: "Notes",
                displayText: "Notizen" //t("TextTemplateCategoryEnum_Notes")
            },
            {
                key: "DirectOrder",
                displayText: "Beauftragung" //t("TextTemplateCategoryEnum_DirectOrder")
            },
            {
                key: "NewTender",
                displayText: "Neue Anfrage" //t("TextTemplateCategoryEnum_NewTender")
            },
            {
                key: "AcceptTender",
                displayText: "Anfrage annehmen" //t("TextTemplateCategoryEnum_AcceptTender")
            },
            {
                key: "Remind",
                displayText: "Erinnerung" //t("TextTemplateCategoryEnum_Remind")
            },
            {
                key: "Cancel",
                displayText: "Storno" //t("TextTemplateCategoryEnum_Cancel")
            },
        ];

        const [initialValues, setInitialValues] = useState({
            id: 0,
            title: '',
            text: '',
            category: '',
            teamid: ''
        });

        const editItem = Boolean(selectedRowID > 0);
        useEffect(() => {
            if (editItem) {
                httpclientService.get(`/api/settings/texttemplates/get?id=${selectedRowID}`).then((response) => {
                    if (response.teamid === null) {
                        response.teamid = '';
                    }
                    setInitialValues(response);
                })
            }
        }, [])

        function handleSave(values) {
            if (values.teamid === '') {
                values.teamid = null;
            }
            httpclientService.post("/api/settings/texttemplates/savetexttemplate", JSON.stringify(values)).then((response) => {
                switch (response.statusCode) {
                    case 200:
                        handleModalClose();
                        setChangeCounter((prevState) => prevState + 1);
                        break;
                    default:
                        break;
                }
            })
        }

        return(
            <>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        title: Yup.string()
                            .required(t("GenericRequiredText")) //TODO: wie funktioniert string.format bei t?
                            .max(100, t('Error_TextTooLong', { 1: 100 })),
                        text: Yup.string()
                            .required(t("GenericRequiredText"))
                            .max(2048, t('Error_TextTooLong', { 1: 2048 })),
                        category: Yup.string()
                            .required(t("GenericRequiredText"))
                    })}
                    onSubmit={(values) => { handleSave(values) }}
                >
                    <Form>
                        <OmisRow>
                            <OmisCol xs="12">
                                <OmisTextBox labeltext={t("Title")} name="title" placeholder={t("SettingsTextModule") + " " + t("Title")} required />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol xs="12">
                                <OmisTextArea labeltext={t("Description")} name="text" placeholder={t("SettingsTextModule") + " " + t("Description")} required />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol xs={6}>
                                <OmisDropdown labeltext={t("Category")} name={"category"} items={categories} required />
                            </OmisCol>
                            <OmisCol xs={6}>
                                <OmisDropdown labeltext={t("Team")} name={"teamid"} items={teams} />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <DialogActions>
                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleModalClose}></OmisButtonSecondary>
                                <OmisButtonPrimarySubmit id={"submitButton"} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                            </DialogActions>
                        </OmisRow>
                    </Form>
                </Formik>
            </>
        );
    }
}