import React, {useEffect, useRef, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthService from "../../services/auth.service";
import httpclientService from "../../services/httpclient.service";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {OmisTextBox, OmisPasswordTextBoxWithEye} from "../shared/OmisInputs";
import {OmisButtonPrimarySubmit} from "../shared/OmisButtons";
import {OmisErrorMessage} from "../shared/OmisDisplays";
import {useTranslation} from "react-i18next";
import ChangePasswordRequest from "./ChangePassword";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../shared/OmisLayouts";

export function LoginFailedDisplay(props) {
    if (!props.errorMessage) {
        return (<div></div>);
    }
    return (
        <OmisCol xs={12}>
            <OmisErrorMessage text={props.errorMessage}/>
        </OmisCol>
    );
}

export default function Login() {
    const { t, i18n } = useTranslation();
    const formRef = useRef();
    //const [dbName, setDbName] = useState(null);
    const [customerNr, setCustomerNr] = useState(localStorage.getItem("customerNr") ?? "");
    const [errorMessage, setErrorMessage] = useState(null);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const navigate = useNavigate();

    const handleLogin = (username, password, customerNr) => {

        if (typeof customerNr === 'string' || customerNr instanceof String) {        
        }
        else {
            customerNr = `${customerNr}`
        }

        setErrorMessage(null);
        setSubmitButtonDisabled(true);
        AuthService.login(username, password, customerNr, localStorage.getItem("i18nLng")).then(
            (loginResult) => {

                if (loginResult.data?.isFirstLogin) {
                    navigate("/firstloginchangepassword", { replace: true, state: { username: username } });
                }

                if (loginResult.status === 200) {
                    const returnurl = new URLSearchParams(window.location.search).get('returnUrl');
                    var lang = loginResult.data.language;
                    if (lang && localStorage.getItem("i18nLng") !== lang) {
                        i18n.changeLanguage(lang).then(() => {
                            localStorage.setItem("i18nLng", lang);
                        }).then(() => {
                            httpclientService.post("/api/profile/changelanguage?isUserSwitch=1", lang).then(() => {
                                if (returnurl) {
                                    navigate(returnurl);
                                } else {
                                    navigate("/");
                                }
                                window.location.reload();
                            });
                        });
                    } else {
                        if (returnurl) {
                            navigate(returnurl);
                        } else {
                            navigate("/");
                        }
                        window.location.reload();
                    }
                } else {
                    setErrorMessage(loginResult.data.errormessage);
                    setSubmitButtonDisabled(false);
                }
            }
        );         
    };

    useEffect(() => {
        httpclientService.get("/api/profile/getcustomernr").then((response) => {
            localStorage.setItem("customerNr", response);
            formRef.current.setFieldValue("customerNr", response);
            setCustomerNr(response);
        });
    },[]);

    return (
       
            <Formik
                innerRef={formRef}
                initialValues={{ username: '', password: '', customerNr: customerNr }}
                validationSchema={Yup.object({
                    username: Yup.string()
                        .required(t("GenericRequiredText"))
                        .min(3, 'Benutzername muss mindestens 3 Zeichen lang sein!'),
                    password: Yup.string()
                        .required('Passwort ist erforderlich')
                        .min(5, 'Passwort muss mindestens 5 Zeichen lang sein!'),
                    customerNr: Yup.string()
                        .required(t("GenericRequiredText"))
                })}
                onSubmit={(values) => {
                    handleLogin(values.username, values.password, values.customerNr);
                }}
                enableReinitialize={true}
                validateOnMount
            >{formik => (
                <Form>
                    <OmisCol className="justify-content-center d-flex align-items-center align-self-center align-content-center flex-wrap">

                        <OmisCol xs={12} md={{ span: 10 }} xxl={8} style={{ marginTop: '3em' }}>
                            <div className="loginRightHeader" style={{ marginBottom: '2em' }}>
                                <h1 className="font-orange center">{t("Login_Welcome")}</h1>
                            </div>
                        </OmisCol>

                        <OmisCol xs={12} md={{ span: 10 }} xxl={8}>
                            <h1 style={{ marginBottom: '1em' }}>Login</h1>
                        </OmisCol>

                        {
                            customerNr === "" ?

                                    <OmisCol xs={12} md={{ span: 10 }} xxl={8}>
                                        <OmisTextBox labeltext={t("Customer_Nr")} name="customerNr" placeholder={t("Customer_Nr")} />
                                    </OmisCol>

                                :
                                null
                        }

                        <OmisCol xs={12} md={{ span: 10 }} xxl={8}>
                            <OmisTextBox className="loginInputs" labeltext={t("UserName")} name="username" placeholder={t("UserName")} />
                        </OmisCol>

                        <OmisCol xs={12} md={{ span: 10 }} xxl={8}>
                            <OmisPasswordTextBoxWithEye className="loginInputs" data-testid={"txtLoginPassword"} labeltext={t("Password")} name="password" placeholder={t("Password")} />
                        </OmisCol>

                        <OmisCol xs={12} md={{ span: 10 }} xxl={8}>
                            {t("Account_NewPassword")} <a id="resetPassword" className="font-orange" href="resetpassword">{t("ClickHere")}</a>
                        </OmisCol>

                        <OmisCol xs={12} md={{ span: 10 }} xxl={8} style={{ marginTop: '4.5em' }}>
                            <OmisButtonPrimarySubmit text={t("Login")} id="loginButton" disabled={!formik.isValid || submitButtonDisabled} />
                        </OmisCol>

                        <OmisCol xs={12} md={{ span: 10 }} xxl={8}>
                            <LoginFailedDisplay errorMessage={errorMessage} />
                        </OmisCol>

                    </OmisCol>
                    
                </Form>)}
            </Formik>
    );
}

export function FirstLoginChangePassword(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currentUser = AuthService.getCurrentUser();
    function returnLogin() {
        AuthService.logout().then(() => {
            navigate("/login");
        });
    }

    return (
        <OmisCol className="justify-content-center d-flex align-items-center align-self-center align-content-center flex-wrap">
            <OmisCol xs={12} md={{ span: 10 }} xxl={8} style={{ marginTop: '3em' }}>
                <div className="loginRightHeader" style={{ marginBottom: '2em' }}>
                    <h1 className="font-orange center">{t("WelcomeToOmis")}</h1>
                </div>
            </OmisCol>

            <OmisCol xs={12} md={{ span: 10 }} xxl={8} style={{ marginTop: '3em' }}>
                <div className="loginRightHeader" style={{ marginBottom: '2em' }}>
                    <ChangePasswordRequest username={currentUser?.username} fromFirstLoginChangePassword backButtonAction={returnLogin} />
                </div>
            </OmisCol>
        </OmisCol>
    );
}