export const TabConstants = {
    System: 0,
    Objects: 1,
    Equipment: 2,
    CompanySites: 3,
    Reports: 4,
    InterfaceConsole: 5,
    Automation: 6,
    Templates: 7,
    Miscellaneous: 8,
    FSMIntern:9,
}

export const ObjectAttributeTabConstants = {
    ObjectAttribute: 0,
    ObjectAttributeDef: 1,
}