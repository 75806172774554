import React, { useEffect, useRef, useState } from "react";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { t } from "i18next";
import { Form, Formik } from "formik";
import { OmisCol, OmisRow } from "../../shared/OmisLayouts";
import { OmisButtonPrimarySubmit } from "../../shared/OmisButtons";
import { OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisDropdown } from "../../shared/OmisDropdowns";

var api = "/api/profile/";

export default function AddUserContractor(props) {

    const navigate = useNavigate();

    const formRef = useRef();
    const [initialValues, setInitialValues] = useState({
        userID: props.userID ?? null,
        contractorID: null,
    });

    const [contractors, setContractors] = useState([]);

    useEffect(() => {
        httpclientService.get(`${api}getcontractorsfordropdown?userIDForRemove=${props.userID}`).then((response) => {
            if (response && response.length>0) {
                setContractors(response);
            }
        });
    }, []);

    const validationSchema = Yup.object({
        userID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1, t('GenericRequiredText')),
        contractorID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1, t('GenericRequiredText')),
    });

    function handleAddUserContractor(values) {
        httpclientService.post(`${api}addusercontractor`, values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(-1);
                }
            }
        });
    }

    function handleCancel() {
        if (props.onCancel) {
            props.onCancel();
        } else {
            navigate(-1);
        }
    }

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    handleAddUserContractor(values)
                }}
            >
                {formik => (
                    <Form>
                        <OmisRow>
                            <OmisCol xs={6}>
                                <OmisDropdown id="contractor" name={"contractorID"} className="contractorsDropDown" items={contractors} labeltext={t("Contractors")} required />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol xs={6}>
                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel} />
                            </OmisCol> 
                            <OmisCol xs={6}>
                                <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                            </OmisCol>                            
                        </OmisRow>
                    </Form>)}
            </Formik>           
        </>
    );
};