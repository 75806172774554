import React, { useState } from "react";
import { t } from "i18next";
import { Link as RouterLink } from "react-router-dom";
import httpClientService from "../../../services/httpclient.service";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisButtonNewItem, OmisIconButton } from "../../shared/OmisButtons";
import { OmisGrid } from "../../shared/OmisGrids";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { CreateRating } from "./CreateRating";
import { OmisDialog } from "../../shared/OmisDisplays";
import { OmisCol, OmisContainer } from "../../shared/OmisLayouts";

export function SettingsRatings(props) {
    var url = "/api/settings/companysite/getratings";

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);
    const [rowsCount, setRowsCount] = useState(0);

    function handleEditClick(rowID, e) {
        setModalTitle(t("Action_Edit"));
        setModalContent(<CreateRating onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    const handleDelete = (rowID) => {
        httpClientService.post(`/api/settings/companysite/deleterating?id=${rowID}`).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    }

    return (
        <OmisContainer fluid>
            {
                props.hideBack ?
                    null
                    :
                    <OmisCol xs={1}>
                        <OmisIconButton id={"backButton"}
                            iconcontent={<ArrowBackIcon fontSize="inherit" />}
                            text="Back" component={RouterLink} to={"/settings"} state={{ selectedtabvalue: TabConstants.CompanySites }} />
                    </OmisCol>

            }

            <OmisGrid actiondropdown={{ edit: handleEditClick, delete: handleDelete }} headerText={t("Rating_Rating")}
                newButton={rowsCount === 0 ? < OmisButtonNewItem id={"newRatingButton"} component={RouterLink} to={"/settings/companysite/createrating"} text={t("Action_New")} />:null}
                apiUrl={url}
                change={changeCounter}
                noExportButton
                getrowcount={setRowsCount}
                {...props} />

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"xl"}
                title={modalTitle}
                content={modalContent}
                contentHeight={800}
            />
        </OmisContainer>
    );
}