import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisTextBox } from "../../shared/OmisInputs";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateCostType(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const [initialValues, setInitialValues] = useState({
        id: props.rowVals?.id ?? null,
        description: props.rowVals?.description ?? "",
        sortIndex: props.rowVals?.sortIndex ?? 0,
        label: props.rowVals?.label ?? null,
        lastChangedField: null
    });

    useEffect(() => {
        if (!props.rowVals?.id) {
            httpclientService.get("/api/settings/settingsreports/getmaxcosttypeindex").then((response) => {
                if (response) {
                    formRef.current.setFieldValue("sortIndex", response);
                }
            });
        }
    }, []);

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsreports/savecosttype", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(-1);
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(-1);
        }
    }

    const [schema, setSchema] = useState({
        description: Yup.string()
            .required(t("GenericRequiredText"))
            .max(255, t('Error_TextTooLong', { 1: 255 })),
        label: Yup.string()
            .notRequired()
            .max(10, t('Error_TextTooLong', { 1: 10 })),
        sortIndex: Yup.number()
            .required(t("GenericRequiredText"))
            .test("sortindexvalidation", t("SortindexInUse"), function (value) { // Use function
                if (formRef.current.values.lastChangedField === "sortIndex") {
                    return httpclientService.get(`/api/settings/settingsreports/issortindexavailable?type=${"costtype"}&value=${value}`).then((response) => {
                        return response;
                    });
                } else if (formRef.current.values.lastChangedField === null) {
                    return true;
                } else {
                    return !formRef.current.errors.sortIndex ?? true;
                }
            })
            .min(0, t("GenericRequiredText")),
    });

    const handleInput = (e) => {
        formRef.current.setFieldValue("lastChangedField", e.target.name);
    };

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("CostType")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisTextBox labeltext={t("Label")} name="label" onInput={handleInput} />
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisTextBox labeltext={t("Description")} name="description" onInput={handleInput} required />
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisTextBox labeltext={t("FaultKind_SortIndex")} name="sortIndex" type="number" onInput={handleInput} required />
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}