import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { OmisLabel } from "../../shared/OmisDisplays";
import { OmisCheckBoxNoForm, OmisTextBox, OmisTimePicker } from "../../shared/OmisInputs";

export function CreateDispatchParameter(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const [priorities, setPriority] = useState([]);
    const [companySiteEmail, setCompanySiteEmail] = useState("");

    const [initialValues, setInitialValues] = useState({
        id: props.id ?? null,
        companySiteID: props.companySiteID ?? props.rowVals?.companySiteID ?? null,

        workDayNTFrom: props.rowVals?.workDayNTFrom ?? null,
        workDayNTTo: props.rowVals?.workDayNTTo ?? null,
        workDayNTEMail: props.rowVals?.workDayNTEMail ?? "",
        workDayOTEMail: props.rowVals?.workDayOTEMail ?? "",
        workDayNTLimitedPriorityEmail: props.rowVals?.workDayNTLimitedPriorityEmail ?? null,
        workDayOTLimitedPriorityEmail: props.rowVals?.workDayOTLimitedPriorityEmail ?? null,

        workDayFrNTFrom: props.rowVals?.workDayFrNTFrom ?? null,
        workDayFrNTTo: props.rowVals?.workDayFrNTTo ?? null,
        workDayFrNTEMail: props.rowVals?.workDayFrNTEMail ?? "",
        workDayFrOTEMail: props.rowVals?.workDayFrOTEMail ?? "",
        workDayFrNTLimitedPriorityEmail: props.rowVals?.workDayFrNTLimitedPriorityEmail ?? null,
        workDayFrOTLimitedPriorityEmail: props.rowVals?.workDayFrOTLimitedPriorityEmail ?? null,

        workDaySaNTFrom: props.rowVals?.workDaySaNTFrom ?? null,
        workDaySaNTTo: props.rowVals?.workDaySaNTTo ?? null,
        workDaySaNTEMail: props.rowVals?.workDaySaNTEMail ?? "",
        workDaySaOTEMail: props.rowVals?.workDaySaOTEMail ?? "",
        workDaySaNTLimitedPriorityEmail: props.rowVals?.workDaySaNTLimitedPriorityEmail ?? null,
        workDaySaOTLimitedPriorityEmail: props.rowVals?.workDaySaOTLimitedPriorityEmail ?? null,

        publicHolidayNTFrom: props.rowVals?.publicHolidayNTFrom ?? null,
        publicHolidayNTTo: props.rowVals?.publicHolidayNTTo ?? null,
        publicHolidayNTEMail: props.rowVals?.publicHolidayNTEMail ?? "",
        publicHolidayOTEMail: props.rowVals?.publicHolidayOTEMail ?? "",
        publicHolidayNTLimitedPriorityEmail: props.rowVals?.publicHolidayNTLimitedPriorityEmail ?? null,
        publicHolidayOTLimitedPriorityEmail: props.rowVals?.publicHolidayOTLimitedPriorityEmail ?? null,
    });

    useEffect(() => {
        
        httpclientService.get("api/settings/faultkind/getfaultcategorylist").then(response => {
            setPriority(response);
        });

        httpclientService.get(`/api/settings/companysite/getcompanysiteemail?companySiteID=${props.companySiteID}`).then(response => {
            setCompanySiteEmail(response);
        });

        if (props.companySiteID && props.companySiteID > 0) {

            const keys = Object.keys(initialValues);
            keys.forEach(key => {
                if (initialValues[key]) {
                    formRef.current.setFieldTouched(`${key}`, true, true);
                }
            });
        }
    }, []);

    function handleSave(values) {
        httpclientService.post("/api/settings/companysite/savedispatchparameter", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(0);
                }
            }
        });
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(-1);
        }
    }

    function emailCheckBoxClicked(event) {

        const checkType = event?.target?.name;
        const checkValue = event?.target?.checked;

        switch (checkType) {
            case "chkworkDayNTEMail":
            case "chkworkDayOTEMail":
            case "chkworkDayFrNTEMail":
            case "chkworkDayFrOTEMail":
            case "chkworkDaySaNTEMail":
            case "chkworkDaySaOTEMail":
            case "chkpublicHolidayNTEMail":
            case "chkpublicHolidayOTEMail":
                if (checkValue) {
                    const fieldStr = checkType.replace("chk", "");
                    formRef.current.setFieldValue(fieldStr, companySiteEmail);
                }
                break;


            case "fridayUseMoThValues":
                setFieldValuesFromMoTh("workDayFr", checkValue);
                break;
            case "saturdayUseMoThValues":
                setFieldValuesFromMoTh("workDaySa", checkValue);
                break;
            case "sundayUseMoThValues":
                setFieldValuesFromMoTh("publicHoliday", checkValue);
                break;
            default:
                break;
        }
    }

    function setFieldValuesFromMoTh(dayCode,checked) {
        if (checked) {
            formRef.current.setFieldValue(`${dayCode}NTFrom`,                   formRef.current.values.workDayNTFrom);
            formRef.current.setFieldValue(`${dayCode}NTTo`,                     formRef.current.values.workDayNTTo);
            formRef.current.setFieldValue(`${dayCode}NTEMail`,                  formRef.current.values.workDayNTEMail);
            formRef.current.setFieldValue(`${dayCode}OTEMail`,                  formRef.current.values.workDayOTEMail);
            formRef.current.setFieldValue(`${dayCode}NTLimitedPriorityEmail`,   formRef.current.values.workDayNTLimitedPriorityEmail);
            formRef.current.setFieldValue(`${dayCode}OTLimitedPriorityEmail`,   formRef.current.values.workDayOTLimitedPriorityEmail);
            formRef.current.setFieldValue(`chk${dayCode}NTEMail`,               formRef.current.values.chkworkDayNTEMail);
            formRef.current.setFieldValue(`chk${dayCode}OTEMail`, formRef.current.values.chkworkDayOTEMail);

            const obj = Object();
            obj[`${dayCode}NTFrom`] =  new Date(formRef.current.values.workDayNTFrom);
            obj[`${dayCode}NTTo`] = new Date(formRef.current.values.workDayNTTo);
            var tmpInit = {
                ...formRef.current.values,
                ...obj
            };
            
            setInitialValues(tmpInit);

        }
    }

    const [schema, setSchema] = useState({
        workDayNTFrom: Yup.date()
            .typeError(t("GenericRequiredText"))
            .required(t("GenericRequiredText")),
        workDayNTTo: Yup.date()
            .min(
                Yup.ref('workDayNTFrom'),
                ({ min }) => t("OnlineImport_InvalidTime"),
            )
            .typeError(t("GenericRequiredText"))
            .required(t("GenericRequiredText")),

        workDayFrNTFrom: Yup.date()
            .typeError(t("GenericRequiredText"))
            .required(t("GenericRequiredText")),
        workDayFrNTTo: Yup.date()
            .min(
                Yup.ref('workDayFrNTFrom'),
                ({ min }) => t("OnlineImport_InvalidTime"),
            )
            .typeError(t("GenericRequiredText"))
            .required(t("GenericRequiredText")),

        workDaySaNTFrom: Yup.date()
            .typeError(t("GenericRequiredText"))
            .required(t("GenericRequiredText")),
        workDaySaNTTo: Yup.date()
            .min(
                Yup.ref('workDaySaNTFrom'),
                ({ min }) => t("OnlineImport_InvalidTime"),
            )
            .typeError(t("GenericRequiredText"))
            .required(t("GenericRequiredText")),

        publicHolidayNTFrom: Yup.date()
            .typeError(t("GenericRequiredText"))
            .required(t("GenericRequiredText")),
        publicHolidayNTTo: Yup.date()
            .min(
                Yup.ref('publicHolidayNTFrom'),
                ({ min }) => t("OnlineImport_InvalidTime"),
            )
            .typeError(t("GenericRequiredText"))
            .required(t("GenericRequiredText")),
    });
    function formatDate(date) {
        return new Date(date).toLocaleDateString()
    }

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                validateOnChange
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("Settings_DispatchParameter")}</h1>
                                :
                                null
                        }

                        <OmisContainer fluid>

                            <DispatchParameterFields emailCheckBoxClicked={emailCheckBoxClicked} priorities={priorities} values={formik.values} companySiteEmail={companySiteEmail} />
                            <br />
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}


export function DispatchParameterFields(props) {
    return (
        <OmisContainer fluid>
            <OmisRow>
                <strong>{`${t("Calendar_Monday")} - ${t("Calendar_Thursday")}`}</strong>
                <br />
                <br />
                <OmisCol xs={6}>
                    <OmisRow>
                        <OmisCol xs={12}>
                            <OmisLabel label={t("DispatchParameter_NormalWorkingTime")} />
                        </OmisCol>
                        <OmisCol xs={6}>
                            <OmisLabel label={<strong>{t("From_Time")}</strong>} required />
                            <OmisTimePicker labeltext={t("From_Time")} id={"workDayNTFrom"} name="workDayNTFrom" required />
                        </OmisCol>
                        <OmisCol xs={6}>
                            <OmisLabel label={<strong>{t("To_Time")}</strong>} required />
                            <OmisTimePicker labeltext={t("To_Time")} id={"workDayNTTo"} name="workDayNTTo" required />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>

                <OmisCol xs={6}>
                    <OmisRow>
                        <OmisCol xs={12}>
                            <OmisLabel label={t("DispatchParameter_OutNormalTime")} />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>

                <OmisCol xs={6}>
                    <OmisRow>
                        <OmisCol xs={6}>
                            <OmisTextBox labeltext={t("Email")} name="workDayNTEMail" />
                            <OmisCheckBoxNoForm labeltext={t("CompanySite_EmailAccept")} id="chkworkDayNTEMail" name="chkworkDayNTEMail" onChange={props.emailCheckBoxClicked} defaultValue={props.values.workDayNTEMail === props.companySiteEmail} disabled={props.values.workDayNTEMail === props.companySiteEmail} />
                        </OmisCol>
                        <OmisCol xs={6}>
                            <OmisDropdown labeltext={t("Priority")} name="workDayNTLimitedPriorityEmail" items={props.priorities} />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>

                <OmisCol xs={6}>
                    <OmisRow>
                        <OmisCol xs={6}>
                            <OmisTextBox labeltext={t("Email")} name="workDayOTEMail" />
                            <OmisCheckBoxNoForm labeltext={t("CompanySite_EmailAccept")} id="chkworkDayOTEMail" name="chkworkDayOTEMail" onChange={props.emailCheckBoxClicked} checked={props.values.workDayOTEMail === props.companySiteEmail} disabled={props.values.workDayOTEMail === props.companySiteEmail} />
                        </OmisCol>
                        <OmisCol xs={6}>
                            <OmisDropdown labeltext={t("Priority")} name="workDayOTLimitedPriorityEmail" items={props.priorities} />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>
            </OmisRow>

            <hr />
            <OmisRow>
                <OmisRow>
                    <OmisCol xs={1} style={{ display: 'flex', justifyContent: "right", paddingTop: "0.8em" }} > <strong>{`${t("Calendar_Friday")}`}</strong>(</OmisCol>
                    <OmisCol xs={3} style={{ display: 'flex', justifyContent: "left" }} ><OmisCheckBoxNoForm labeltext={t("DispatchParameter_Friday_UseMoThValues")} id="fridayUseMoThValues" name="fridayUseMoThValues" onChange={props.emailCheckBoxClicked} /> <span style={{ paddingLeft: "1em", paddingTop: "0.8em" }}> )</span> </OmisCol>
                </OmisRow>
                <br />
                <br />
                <OmisCol xs={6}>
                    <OmisRow>
                        <OmisCol xs={12}>
                            <OmisLabel label={t("DispatchParameter_NormalWorkingTime")} />
                        </OmisCol>
                        <OmisCol xs={6}>
                            <OmisLabel label={<strong>{t("From_Time")}</strong>} required />
                            <OmisTimePicker labeltext={t("From_Time")} id={"workDayFrNTFrom"} name="workDayFrNTFrom" required />
                        </OmisCol>
                        <OmisCol xs={6}>
                            <OmisLabel label={<strong>{t("To_Time")}</strong>} required />
                            <OmisTimePicker labeltext={t("To_Time")} id={"workDayFrNTTo"} name="workDayFrNTTo" required />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>

                <OmisCol xs={6}>
                    <OmisRow>
                        <OmisCol xs={12}>
                            <OmisLabel label={t("DispatchParameter_OutNormalTime")} />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>

                <OmisCol xs={6}>
                    <OmisRow>
                        <OmisCol xs={6}>
                            <OmisTextBox labeltext={t("Email")} name="workDayFrNTEMail" />
                            <OmisCheckBoxNoForm labeltext={t("CompanySite_EmailAccept")} id="chkworkDayFrNTEMail" name="chkworkDayFrNTEMail" onChange={props.emailCheckBoxClicked} checked={props.values.workDayFrNTEMail === props.companySiteEmail} disabled={props.values.workDayFrNTEMail === props.companySiteEmail} />
                        </OmisCol>
                        <OmisCol xs={6}>
                            <OmisDropdown labeltext={t("Priority")} name="workDayFrNTLimitedPriorityEmail" items={props.priorities} />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>

                <OmisCol xs={6}>
                    <OmisRow>
                        <OmisCol xs={6}>
                            <OmisTextBox labeltext={t("Email")} name="workDayFrOTEMail" />
                            <OmisCheckBoxNoForm labeltext={t("CompanySite_EmailAccept")} id="chkworkDayFrOTEMail" name="chkworkDayFrOTEMail" onChange={props.emailCheckBoxClicked} checked={props.values.workDayFrOTEMail === props.companySiteEmail} disabled={props.values.workDayFrOTEMail === props.companySiteEmail} />
                        </OmisCol>
                        <OmisCol xs={6}>
                            <OmisDropdown labeltext={t("Priority")} name="workDayFrOTLimitedPriorityEmail" items={props.priorities} />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>
            </OmisRow>

            <hr />
            <OmisRow>
                <OmisRow>
                    <OmisCol xs={1} style={{ display: 'flex', justifyContent: "right", paddingTop: "0.8em" }} > <strong>{`${t("Calendar_Saturday")}`}</strong>(</OmisCol>
                    <OmisCol xs={3} style={{ display: 'flex', justifyContent: "left" }} ><OmisCheckBoxNoForm labeltext={t("DispatchParameter_Friday_UseMoThValues")} id="saturdayUseMoThValues" name="saturdayUseMoThValues" onChange={props.emailCheckBoxClicked} /> <span style={{ paddingLeft: "1em", paddingTop: "0.8em" }}> )</span> </OmisCol>
                </OmisRow>
                <br />
                <br />
                <OmisCol xs={6}>
                    <OmisRow>
                        <OmisCol xs={12}>
                            <OmisLabel label={t("DispatchParameter_NormalWorkingTime")} />
                        </OmisCol>
                        <OmisCol xs={6}>
                            <OmisLabel label={<strong>{t("From_Time")}</strong>} required />
                            <OmisTimePicker labeltext={t("From_Time")} id={"workDaySaNTFrom"} name="workDaySaNTFrom" required />
                        </OmisCol>
                        <OmisCol xs={6}>
                            <OmisLabel label={<strong>{t("To_Time")}</strong>} required />
                            <OmisTimePicker labeltext={t("To_Time")} id={"workDaySaNTTo"} name="workDaySaNTTo" required />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>

                <OmisCol xs={6}>
                    <OmisRow>
                        <OmisCol xs={12}>
                            <OmisLabel label={t("DispatchParameter_OutNormalTime")} />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>

                <OmisCol xs={6}>
                    <OmisRow>
                        <OmisCol xs={6}>
                            <OmisTextBox labeltext={t("Email")} name="workDaySaNTEMail" />
                            <OmisCheckBoxNoForm labeltext={t("CompanySite_EmailAccept")} id="chkworkDaySaNTEMail" name="chkworkDaySaNTEMail" onChange={props.emailCheckBoxClicked} checked={props.values.workDaySaNTEMail === props.companySiteEmail} disabled={props.values.workDaySaNTEMail === props.companySiteEmail} />
                        </OmisCol>
                        <OmisCol xs={6}>
                            <OmisDropdown labeltext={t("Priority")} name="workDaySaNTLimitedPriorityEmail" items={props.priorities} />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>

                <OmisCol xs={6}>
                    <OmisRow>
                        <OmisCol xs={6}>
                            <OmisTextBox labeltext={t("Email")} name="workDaySaOTEMail" />
                            <OmisCheckBoxNoForm labeltext={t("CompanySite_EmailAccept")} id="chkworkDaySaOTEMail" name="chkworkDaySaOTEMail" onChange={props.emailCheckBoxClicked} checked={props.values.workDaySaOTEMail === props.companySiteEmail} disabled={props.values.workDaySaOTEMail === props.companySiteEmail} />
                        </OmisCol>
                        <OmisCol xs={6}>
                            <OmisDropdown labeltext={t("Priority")} name="workDaySaOTLimitedPriorityEmail" items={props.priorities} />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>
            </OmisRow>

            <hr />
            <OmisRow>
                <OmisRow>
                    <OmisCol xs={1} style={{ display: 'flex', justifyContent: "right", paddingTop: "0.8em" }} > <strong>{`${t("Calendar_Sunday")}, ${t("Calendar_PublicHoliday")}`}</strong>(</OmisCol>
                    <OmisCol xs={3} style={{ display: 'flex', justifyContent: "left" }} ><OmisCheckBoxNoForm labeltext={t("DispatchParameter_Friday_UseMoThValues")} id="sundayUseMoThValues" name="sundayUseMoThValues" onChange={props.emailCheckBoxClicked} /> <span style={{ paddingLeft: "1em", paddingTop: "0.8em" }}> )</span> </OmisCol>
                </OmisRow>
                <br />
                <br />
                <OmisCol xs={6}>
                    <OmisRow>
                        <OmisCol xs={12}>
                            <OmisLabel label={t("DispatchParameter_NormalWorkingTime")} />
                        </OmisCol>
                        <OmisCol xs={6}>
                            <OmisLabel label={<strong>{t("From_Time")}</strong>} required />
                            <OmisTimePicker labeltext={t("From_Time")} id={"publicHolidayNTFrom"} name="publicHolidayNTFrom" required />
                        </OmisCol>
                        <OmisCol xs={6}>
                            <OmisLabel label={<strong>{t("To_Time")}</strong>} required />
                            <OmisTimePicker labeltext={t("To_Time")} id={"publicHolidayNTTo"} name="publicHolidayNTTo" required />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>

                <OmisCol xs={6}>
                    <OmisRow>
                        <OmisCol xs={12}>
                            <OmisLabel label={t("DispatchParameter_OutNormalTime")} />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>

                <OmisCol xs={6}>
                    <OmisRow>
                        <OmisCol xs={6}>
                            <OmisTextBox labeltext={t("Email")} name="publicHolidayNTEMail" />
                            <OmisCheckBoxNoForm labeltext={t("CompanySite_EmailAccept")} id="chkpublicHolidayNTEMail" name="chkpublicHolidayNTEMail" onChange={props.emailCheckBoxClicked} checked={props.values.publicHolidayNTEMail === props.companySiteEmail} disabled={props.values.publicHolidayNTEMail === props.companySiteEmail} />
                        </OmisCol>
                        <OmisCol xs={6}>
                            <OmisDropdown labeltext={t("Priority")} name="publicHolidayNTLimitedPriorityEmail" items={props.priorities} />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>

                <OmisCol xs={6}>
                    <OmisRow>
                        <OmisCol xs={6}>
                            <OmisTextBox labeltext={t("Email")} name="publicHolidayOTEMail" />
                            <OmisCheckBoxNoForm labeltext={t("CompanySite_EmailAccept")} id="chkpublicHolidayOTEMail" name="chkpublicHolidayOTEMail" onChange={props.emailCheckBoxClicked} checked={props.values.publicHolidayOTEMail === props.companySiteEmail} disabled={props.values.publicHolidayOTEMail === props.companySiteEmail} />
                        </OmisCol>
                        <OmisCol xs={6}>
                            <OmisDropdown labeltext={t("Priority")} name="publicHolidayOTLimitedPriorityEmail" items={props.priorities} />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>
            </OmisRow>
        </OmisContainer>

    );
}