export const TRole = {
    ObjectRead: "ObjectRead_40",
    ObjectEdit: "ObjectEdit_40",
    ObjectArchive: "ObjectArchive_40",
    EquipmentRead: "EquipmentRead_40",
    EquipmentEdit: "EquipmentEdit_40",
    ContractorRead: "ContractorRead_40",
    Reporting: "Reporting_40",
    TestdatesRead: "TestdatesRead_40",
    TestdatesWrite: "TestdatesWrite_40",
    TestdatesEdit: "TestdatesEdit_40",
    TestdatesArchiv: "TestdatesArchiv_40",
    CivilEngineerEdit: "CivilEngineerEdit_40",
    TestdatesPlannedDateCustom: "TestdatesPlannedDateCustom_40",
    SMSRegistration: "SMSRegistration_40",
    FaultReportRead: "FaultReportRead_40",
    FaultReportEdit: "FaultReportEdit_40",
    FaultReportWrite: "FaultReportWrite_40",
    FaultReportWriteBulk_40: "FaultReportWriteBulk_40",
    FaultReportChangeFinishdate: "FaultReportChangeFinishdate_40",
    FaultReportChangeDateToCompleteAction: "FaultReportChangeDateToCompleteAction_40",
    FaultReportDispatch: "FaultReportDispatch_40",
    FaultReportNote: "FaultReportNote_40",
    MobileAvailable: "MobileAvailable_40",
    FaultReportTenderingProcedure: "FaultReportTenderingProcedure_40",
    FaultReportDirectOrder: "FaultReportDirectOrder_40",
    FaultReportObjectChecklist: "FaultReportObjectChecklist_40",
    FaultReportDeficiencyList: "FaultReportDeficiencyList_40",
    MapsAvailable: "MapsAvailable_40",
    BudgetAvailable: "BudgetAvailable_40",
    DMSObject: "DMSObject_40",
    DMSFaultReport: "DMSFaultReport_40",
    DMSEquipment: "DMSEquipment_40",
    DMSTestdate: "DMSTestdate_40",
    CustomerSettings: "CustomerSettings_40",
    FilterContraktor: "FilterContraktor_40",
    ProjectRead: "ProjectRead_40",
    ProjectEdit: "ProjectEdit_40",
    DashboardAvailable: "DashboardAvailable_40",
    FinishIgnoreWorkflow: "FinishIgnoreWorkflow_40",
    FaultReportArchive: "FaultReportArchive_40",
    DMSDeleteDocument: "DMSDeleteDocument_40",
    DMSRenameDocument:"DMSRenameDocument_40",
    SwitchToFreeMaintenance: "SwitchToFreeMaintenance_40",
    ClaimmanagementAvailable: "ClaimmanagementAvailable_40",
    ClaimManagementResponsible: "ClaimManagementResponsible_40",
    ClaimManagementNote: "ClaimManagementNote_40",
    ObjectAttributes: "ObjectAttributes_40",
    ObjectCommentVisible: "ObjectCommentVisible_40",
    QRCodeGeneration: "QRCodeGeneration_40",
    DMSTender: "DMSTender_40",
    FaultCategory_Searchable: "FaultCategory_Searchable_40",
    NoteEdit: "NoteEdit_40",
    FSMWorkflowUser: "FSMWorkflowUser_40",
    IHCUser: "IHCUser_40",
    FSMSettings: "FSM_Settings_40",
    SendReset_FaultReport: "SendReset_FaultReport_40",
    AreaManagerUser: "AreaManagerUser_40",
    HasMasterLogin: "HasMasterLogin_40",
    NoteDelete: "NoteDelete_40",
    CreateRelatedFaultReport: "CreateRelatedFaultReport_40",
    Settings_User: "Settings_User_40",
    Settings_Object: "Settings_Object_40",
    Settings_Object_DisableSubSettings: "Settings_Object_DisableSubSettings_40",
    Settings_Equipments: "Settings_Equipments_40",
    Settings_Suppliers: "Settings_Suppliers_40",
    Settings_FaultReports: "Settings_FaultReports_40",
    Settings_Misc: "Settings_Misc_40",
    FaultReportHistory: "FaultReportHistory_40",
    MultiLanguage: "MultiLanguage_40",
    TypePlateRecognition: "TypePlateRecognition_40",
    NoteMandatory: "NoteMandatory_40",
    FaultReport_Pause: "FaultReportPause_40",
    DispatchParameter_Fax: "DispatchParameter_Fax_40",
    DispatchParameter_SMS: "DispatchParameter_SMS_40",
    DispatchParameter_Phone: "DispatchParameter_Phone_40",
    Polls: "Polls_40",
    Equipment_Review: "Equipment_Review_40",
    Commissioning: "Commissioning_40",
    Commissioning_AddItem: "Commissioning_AddItem_40",
    Commissioning_RemoveItem: "Commissioning_RemoveItem_40",
    Invoice_Download: "Invoice_Download_40",
    Invoice_Edit: "Invoice_Edit_40",
    Settings_ResourceManagement: "Resource_Management_40",
    Hide_TradeLevelInfo: "Hide_TradeLevelInfo_40",
    ChangeAssignedTechnician: "ChangeAssignedTechnician_40",
    Feedback: "Feedback_40",
    FaultMessagingHistory: "FaultMessagingHistory_40",
    SeverityEdit: "SeverityEdit_40",
    MailNote: "MailNote_40",
    ReleaseNote: "ReleaseNote_40",
    Visibility: "Visibility_40",
    GetGeoData: "GetGeoData_40",
    GenericDocuments: "GenericDocuments_40",
    RatingRead: "Rating_Read_40",
    RatingSettings: "Rating_Settings_40",
    DisplayArchived: "DisplayArchived_40",
    Import: "Import_40",
    Import_Reset: "ImportReset_40",
    FSMVisibility: "FSMVisibility_40",
    FollowUp: "FollowUp_40",
    BulkAssignToObject: "BulkAssignToObject_40",
    BulkFinishReport: "BulkFinishReport_40",
    Spectator: "Spectator_40",
    SupportOmisUser: "SupportOmisUser_40",
    FaultClassResponsible: "FaultClassResponsible_40",
    SmartAnalytics: "SmartAnalytics_40",
    SubReports: "SubReports_40",
    SubReports_View: "SubReports_View_40",
    SubReports_Create: "SubReports_Create_40",
    Settings_ContactPersonTab: "Settings_ContactPersonTab_40",
    Settings_EquiClassTab: "Settings_EquiClassTab_40",
    Import_Disable_Contractor: "Import_Disable_Contractor_40",
    Import_Disable_User: "Import_Disable_User_40",
    Import_Disable_Object: "Import_Disable_Object_40",
    Import_Disable_ObjectContactPerson: "Import_Disable_ObjectContactPerson_40",
    Import_Disable_Equipment: "Import_Disable_Equipment_40",
    Import_Disable_FaultCatalogue: "Import_Disable_FaultCatalogue_40",
    Import_Disable_TestDates: "Import_Disable_TestDates_40",
    Settings_User_Object: "Settings_User_Object_40",
    FaultReportApproval: "FaultReportApproval_40",
    DisableReactionTime: "DisableReactionTime_40",
    CompanySite_Selectable: "CompanySite_Selectable_40",
    TextTemplates_Edit: "TextTemplates_Edit_40",
    TextTemplates: "TextTemplates_40",
    InterfaceConsole: "InterfaceConsole_40",
    WorkType: "ChangeWorktype_40",
    Teams: "Teams_40",
    Resubmission: "Resubmission_40",
    Resubmission_Admin: "Resubmission_Admin_40",
    Crawford_User_40: "Crawford_User_40",
    ViewAllEquisForContractor: "ViewAllEquisForContractor_40",
    ObjectNote: "ObjectNote_40",
    IndexCostTypeCostCenter_Filter: "IndexCostTypeCostCenter_Filter_40"
}

export const NavBarRoles = () => {
    return [
        { role: "DashboardAvailable_40", pageurl: "dashboard" },
        { role: "FaultReportRead_40", pageurl:"reports"},
        { role: "TestdatesRead_40", pageurl:"inspections"},
        { role: "GenericDocuments_40", pageurl:"documents"},
        { role: "ObjectRead_40", pageurl:"objects"},
        { role: "EquipmentRead_40", pageurl:"equipment"},
        { role: "ProjectRead_40", pageurl:"projects"},
        { role: "Import_40", pageurl:"systemdata"},
        { role: "CustomerSettings_40", pageurl:"settings"}
    ];
}