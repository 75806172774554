
import React, { useState } from 'react';
import { OmisButtonNewItem } from "../../shared/OmisButtons";
import { OmisGrid } from "../../shared/OmisGrids";
import { mdiTrashCan, mdiPencil } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { OmisDeleteQuestionDialog, OmisDialog } from '../../shared/OmisDisplays';
import httpClientService from '../../../services/httpclient.service';
import { OmisIconButton } from "../../shared/OmisButtons";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisCol } from '../../shared/OmisLayouts';
import FaultEquiClassDefPopup_Content from './FaultEquiClassDefPopup_Content';

export default function FaultEquiClassDef() {
    const { t } = useTranslation();

    const [changeCounter, setChangeCounter] = useState(0);
    const [newModalOpen, setNewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedRowID, setSelectedRowID] = useState(0);
    
    const handleModalOpen = () => {
        setNewModalOpen(true)
    }

    const handleModalClose = () => {
        setChangeCounter(prevState => prevState + 1);
        setNewModalOpen(false);
        setEditModalOpen(false);
        setDeleteModalOpen(false);
        setSelectedRowID(0);
    }

    const handleModalCancel = () => {
        setNewModalOpen(false);
        setEditModalOpen(false);
        setDeleteModalOpen(false);
        setSelectedRowID(0);
    }

    function handleEditClick(item) {
        setSelectedRowID(item);
        setEditModalOpen(true);
    }

    function handleDeleteClick(item) {
        setSelectedRowID(item);
        setDeleteModalOpen(true);
    }

    function handleDeleteItem() {
        httpClientService.post(`api/settings/faultequiclassdef/delete?id=${selectedRowID}`).then(response => {
            handleModalClose();
        })
    }

    const dropDownMenuItems = [
        {
            action: handleEditClick,
            iconpath: mdiPencil,
            icontext: t("Action_Edit")
        },
        {
            action: handleDeleteClick,
            iconpath: mdiTrashCan,
            icontext: t("Action_Delete")
        }
    ]


    return (
        <div className=" omis-header-box">
            <OmisCol xs={1}>
                <OmisIconButton id={"backButton"}
                    iconcontent={<ArrowBackIcon fontSize="inherit" />}
                    text="Back" component={RouterLink} to={"/settings"} state={{ selectedtabvalue: TabConstants.Reports }} />
            </OmisCol>

            <OmisGrid actiondropdown={{ dropDownMenuItems: dropDownMenuItems }}
                change={changeCounter}
                headerText={t("FaultEquiClassDefs")}
                newButton={<OmisButtonNewItem id={"newItemButton"} onClick={handleModalOpen} text={t("Action_New")} />}
                apiUrl={"api/settings/faultequiclassdef/getallfaultequiclassdefs"}
                detailsroute="/settings/settingsreports/faultequiclassdefdetails"
                hasdetailview
            />
            <OmisDialog onClose={handleModalClose} open={newModalOpen}
                title={t("FaultEquiClassDef_Create")}
                content={<FaultEquiClassDefPopup_Content selectedRowID={selectedRowID} handleModalClose={handleModalClose} handleModalCancel={handleModalCancel} />}
                maxWidth={"lg"}
            />
            <OmisDialog onClose={handleModalClose} open={editModalOpen}
                title={"Fehlerklasse bearbeiten"}
                content={<FaultEquiClassDefPopup_Content selectedRowID={selectedRowID} handleModalClose={handleModalClose} handleModalCancel={handleModalCancel} />}
                maxWidth={"lg"}
            />
            <OmisDeleteQuestionDialog open={deleteModalOpen}
                handleModalClose={handleModalClose}
                deleteFunction={handleDeleteItem}
            />
        </div>
    );    
}