import React, { useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisCheckBox, OmisTextBox } from "../../shared/OmisInputs";
import AuthService from "../../../services/auth.service";
import { useEffect } from "react";
import { OmisDropdownMultiselect } from "../../shared/OmisDropdowns";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateContractor(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const [users, setUsers] = useState([]);
    const [selectedUserIDs, setSelectedUserIDs] = useState([]);

    const [initialValues, setInitialValues] = useState({
        id: props.id ? props.id : null,
        description: props.rowVals?.description ?? '',
        supplierNumber: props.rowVals?.supplierNumber ?? '',
        additionalDescription: props.rowVals?.additionalDescription ?? '',
        userIDs: selectedUserIDs,
        customerEditable: props.rowVals?.customerEditable ?? true,
    });

    useEffect(() => {
        httpclientService.get(`/api/profile/findalluserslist`).then((response) => {
            if (response && response.length) {
                setUsers(response.filter((f) => f.key !== `${props.userID}`).map((user) => ({
                    key: user.key, displayText: user.displayText
                })));
            }
        }).then(() => {
            if (props.id) {
                httpclientService.get(`/api/settings/companysite/getcontractorusers?contractorID=${props.id}`).then((response) => {
                    if (response && response.length) {
                        setSelectedUserIDs(response);
                        formRef.current.setFieldValue("userIDs", response);
                    }
                });
            }
        }).then(() => {
            if (props.id) {
                const keys = Object.keys(initialValues);
                keys.forEach(key => {
                    if (initialValues[key]) {
                        formRef.current.setFieldTouched(`${key}`, true, true);
                    }
                });
            }
        });
    },[]);

    function handleSave(values) {
        httpclientService.post("/api/settings/companysite/savecontractor", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(-1);
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(-1);
        }
    }

    const [schema, setSchema] = useState({
        description: Yup.string()
            .required(t("GenericRequiredText"))
            .max(100, t('Error_TextTooLong', { 1: 100 })),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("Settings_NewSupplier")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={t("Name")} name={"description"} placeholder={t("Name")} required />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={t("Contractor_SupplierNumber")} name={"supplierNumber"} placeholder={t("Contractor_SupplierNumber")} />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <br/>
                                    <OmisDropdownMultiselect size={"small"} id={"users"} name={"userIDs"} items={users} labeltext={t("User")} />
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={t("Contractor_AdditionalDescription")} name={"additionalDescription"} placeholder={t("Contractor_AdditionalDescription")} />
                                </OmisCol>

                                {
                                    AuthService.isFSMMaster() ?
                                        <OmisCol xs={4}>
                                            <br />
                                            <OmisCheckBox labeltext={t("CustomerEditable")} name="customerEditable" />
                                        </OmisCol>
                                        :
                                        null
                                }
                            </OmisRow>
                            <br/>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}