import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import httpclientService from "../../services/httpclient.service";
import { t } from "i18next";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../shared/OmisLayouts";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../shared/OmisButtons";
import { OmisCheckBox, OmisDatePicker, OmisSlider, OmisTextBox } from "../shared/OmisInputs";
import { OmisLabel } from "../shared/OmisDisplays";
import { OmisDropdown } from "../shared/OmisDropdowns";
import { AttributesList } from "./create/EquipmentCreate";

export function EquipmentBulkEdit(props) {    

    const navigate = useNavigate();
    const formRef = useRef();

    const [years, setYears] = useState([]);
    const [yesNoDropdown, setYesNoDropdown] = useState([]);
    const [equipmentOwners, setEquipmentOwners] = useState([]);
    const [equiTypes, setEquiTypes] = useState([]);
    const [objects, setObjects] = useState([]);
    const [companySites, setCompanySites] = useState([]);
    const [statIDs, setStatIDs] = useState([]);
    const [equiPriorities, setEquiPriorities] = useState([]);

    const [initialValues, setInitialValues] = useState({
        equipIDs: props.ids,
        guaranteeFrom_Change: false,
        guaranteeFrom: null,
        guaranteeTo_Change: false,
        guaranteeTo: null,
        yearOfConstruction_Change: false,
        yearOfConstruction: null,
        owner_Change: false,
        equiOwnerID: null,
        producer_Change: false,
        producer: null,
        tSpecActive_Change: false,
        tSpecActive: null,
        vendor_Change: false,
        vendor: null,
        equiTypeID_Change: false,
        equiTypeID: null,
        objectID_Change: false,
        objectID: null,
        companySiteID_Change: false,
        companySiteID: null,
        guaranteeCompanySiteID_Change: false,
        guaranteeCompanySiteID: null,
        statID_Change: false,
        statID: null,
        scrappingDate_Change: false,
        scrappingDate: null,
        equiPriority_Change: false,
        equiPriorityID: null,
        equiAttributes_Change: false,
        equiAttributeModels:[]
    });

    useEffect(() => {
        if (props.selectedEquiClassID) {
            httpclientService.get(`/api/equipments/getequiattributeslist?equiClassID=${props.selectedEquiClassID}`).then((response) => {
                if (response) {
                    var tmpInitialValue = { ...formRef.current.values };
                    var tmpMidArr = [
                        ...response.filter((item) => tmpInitialValue.equiAttributeModels.map((a) => a.id).indexOf(item.id) == -1),
                        ...tmpInitialValue.equiAttributeModels.filter((item) => response.map((a) => a.id).indexOf(item.id) > -1)
                    ];
                    tmpInitialValue.equiAttributeModels = tmpMidArr;
                    setInitialValues(tmpInitialValue);
                    formRef.current.setFieldValue("equiAttributeModels", tmpMidArr);
                }
            });
        }

        httpclientService.get(`/api/equipments/getyears`).then((response) => {
            if (response) {
                setYears(response.map((item) => ({ key: item.keyStr, displayText: item.displayText })));
            }
        });

        httpclientService.get(`/api/equipments/getyesnodropdown`).then((response) => {
            if (response) {
                setYesNoDropdown(response.map((item) => ({ key: item.keyStr, displayText: item.displayText })));
            }
        });

        httpclientService.get(`/api/equipments/getequipmentownerlist`).then((response) => {
            if (response) {
                setEquipmentOwners(response);
            }
        });

        httpclientService.get(`/api/equipments/getequitypes`).then((response) => {
            if (response) {
                setEquiTypes(response);
            }
        });

        httpclientService.get(`/api/objects/getobjectlistfordropdown`).then((response) => {
            if (response) {
                setObjects(response.map((item) => ({
                    key: item.key,
                    displayText: item.label
                })));
            }
        });

        httpclientService.get(`/api/settings/companysite/getcompanysitesfordropdown`).then((response) => {
            if (response) {
                setCompanySites(response);
            }
        });

        httpclientService.get(`/api/equipments/getstatuses`).then((response) => {
            if (response) {
                setStatIDs(response);
            }
        });

        httpclientService.get(`/api/equipments/getequipriorities`).then((response) => {
            if (response) {
                setEquiPriorities(response);
            }
        });
    },[]);

    function handleAttributeValueChange(attrID, event) {
        var tmpList = [...initialValues.equiAttributeModels];
        var existingAttribute = tmpList.filter((f) => f.id && f.id === attrID);
        if (existingAttribute.length > 0) {
            existingAttribute[0].attributeValue = event?.target?.value ?? "";
        }
        formRef.current.setFieldValue("equiAttributeModels", tmpList);
    }

    function handleSave(values) {
        httpclientService.post("/api/equipments/equipmentsbulkedit", values).then((response) => {
            if (response?.statusCode === 200) {
                if (props.handleModalClose) {
                    props.handleModalClose();
                } else {
                    navigate("/equipment");
                }
            } else {
                alert(t("GenericErrorText"));
            }
        })
    }

    return (
        <Formik
            innerRef={formRef}
            validateOnMount
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values) => { handleSave(values) }}
        >
            {formik => (
                <Form>
                    <OmisContainer fluid>
                        <OmisRow>
                            <OmisCol xs={12}>
                                <OmisLabel label={t("Import_YouHaveChosen") } />
                            </OmisCol>
                            {
                                props.selectedRowDatas?.length > 0 &&

                                props.selectedRowDatas.map((item)=>
                                    <OmisCol xs={12}>
                                        <li>{`${item.equidescription ?? item.equitypedescription} [${item.equiclassdescription}]`}</li>
                                    </OmisCol>
                                )
                            }
                        </OmisRow>
                        <OmisRow>
                            <OmisCol xs={4}>
                                <OmisLabel label={t("Equipment_GuaranteeFrom")} />
                                <OmisStack direction="horizontal" gap={1}>
                                    <OmisCol xs={2}>
                                        <OmisCheckBox id={"guaranteeFrom_Change"} name={"guaranteeFrom_Change"} className={"OmisComponent"} /> 
                                    </OmisCol>
                                    <OmisCol xs={10}>
                                        <OmisDatePicker id="guaranteeFrom" name="guaranteeFrom" sx={{ height: '2.973em !important' }} disabled={!formik.values.guaranteeFrom_Change} />
                                    </OmisCol>
                                </OmisStack>                                
                            </OmisCol>
                            <OmisCol xs={4}>
                                <OmisLabel label={t("Equipment_GuaranteeTo")} />
                                <OmisStack direction="horizontal" gap={1}>
                                    <OmisCol xs={2}>
                                        <OmisCheckBox id={"guaranteeTo_Change"} name={"guaranteeTo_Change"} className={"OmisComponent"} />
                                    </OmisCol>
                                    <OmisCol xs={10}>
                                        <OmisDatePicker id="guaranteeTo" name="guaranteeTo" sx={{ height: '2.973em !important' }} disabled={!formik.values.guaranteeTo_Change} />
                                    </OmisCol>
                                </OmisStack> 
                            </OmisCol>
                            <OmisCol xs={4}>
                                <OmisLabel label={t("Equipment_YearOfConstruction")} />
                                <OmisStack direction="horizontal" gap={1}>
                                    <OmisCol xs={2}>
                                        <OmisCheckBox id={"yearOfConstruction_Change"} name={"yearOfConstruction_Change"} className={"OmisComponent"} />
                                    </OmisCol>
                                    <OmisCol xs={10}>
                                        <OmisDropdown id="yearOfConstruction" name="yearOfConstruction" items={years} disabled={!formik.values.yearOfConstruction_Change} />
                                    </OmisCol>
                                </OmisStack> 
                            </OmisCol>
                            <OmisCol xs={4}>
                                <OmisLabel label={t("Equipment_Owner")} />
                                <OmisStack direction="horizontal" gap={1}>
                                    <OmisCol xs={2}>
                                        <OmisCheckBox id={"owner_Change"} name={"owner_Change"} className={"OmisComponent"} />
                                    </OmisCol>
                                    <OmisCol xs={10}>
                                        <OmisDropdown id="equiOwnerID" name="equiOwnerID" items={equipmentOwners} disabled={!formik.values.owner_Change} />
                                    </OmisCol>
                                </OmisStack>
                            </OmisCol>
                        </OmisRow>

                        <OmisRow>
                            <OmisCol xs={4}>
                                <OmisLabel label={t("Equipment_Producer")} />
                                <OmisStack direction="horizontal" gap={1}>
                                    <OmisCol xs={2}>
                                        <OmisCheckBox id={"producer_Change"} name={"producer_Change"} className={"OmisComponent"} />
                                    </OmisCol>
                                    <OmisCol xs={10}>
                                        <OmisTextBox id="producer" name="producer" disabled={!formik.values.producer_Change} />
                                    </OmisCol>
                                </OmisStack>
                            </OmisCol>
                            <OmisCol xs={4}>
                                <OmisLabel label={t("FaultReport_LumpSum")} />
                                <OmisStack direction="horizontal" gap={1}>
                                    <OmisCol xs={2}>
                                        <OmisCheckBox id={"tSpecActive_Change"} name={"tSpecActive_Change"} className={"OmisComponent"} />
                                    </OmisCol>
                                    <OmisCol xs={10}>
                                        <OmisDropdown id="tSpecActive" name="tSpecActive" items={yesNoDropdown} disabled={!formik.values.tSpecActive_Change} />
                                    </OmisCol>
                                </OmisStack>
                            </OmisCol>
                        </OmisRow>

                        <OmisRow>
                            <OmisCol xs={4}>
                                <OmisLabel label={t("Equipment_Vendor")} />
                                <OmisStack direction="horizontal" gap={1}>
                                    <OmisCol xs={2}>
                                        <OmisCheckBox id={"vendor_Change"} name={"vendor_Change"} className={"OmisComponent"} />
                                    </OmisCol>
                                    <OmisCol xs={10}>
                                        <OmisTextBox id="vendor" name="vendor" disabled={!formik.values.vendor_Change} />
                                    </OmisCol>
                                </OmisStack>
                            </OmisCol>
                        </OmisRow>

                        <OmisRow>
                            <OmisCol xs={4}>
                                <OmisLabel label={t("EquiType")} />
                                <OmisStack direction="horizontal" gap={1}>
                                    <OmisCol xs={2}>
                                        <OmisCheckBox id={"equiTypeID_Change"} name={"equiTypeID_Change"} className={"OmisComponent"} />
                                    </OmisCol>
                                    <OmisCol xs={10}>
                                        <OmisDropdown id="equiTypeID" name="equiTypeID" items={equiTypes} disabled={!formik.values.equiTypeID_Change} />
                                    </OmisCol>
                                </OmisStack>
                            </OmisCol>
                            <OmisCol xs={4}>
                                <OmisLabel label={t("Object")} />
                                <OmisStack direction="horizontal" gap={1}>
                                    <OmisCol xs={2}>
                                        <OmisCheckBox id={"objectID_Change"} name={"objectID_Change"} className={"OmisComponent"} />
                                    </OmisCol>
                                    <OmisCol xs={10}>
                                        <OmisDropdown id="objectID" name="objectID" items={objects} disabled={!formik.values.objectID_Change} />
                                    </OmisCol>
                                </OmisStack>
                            </OmisCol>
                            <OmisCol xs={4}>
                                <OmisLabel label={t("CompanySite")} />
                                <OmisStack direction="horizontal" gap={1}>
                                    <OmisCol xs={2}>
                                        <OmisCheckBox id={"companySiteID_Change"} name={"companySiteID_Change"} className={"OmisComponent"} />
                                    </OmisCol>
                                    <OmisCol xs={10}>
                                        <OmisDropdown id="companySiteID" name="companySiteID" items={companySites} disabled={!formik.values.companySiteID_Change} />
                                    </OmisCol>
                                </OmisStack>
                            </OmisCol>
                            <OmisCol xs={4}>
                                <OmisLabel label={t("CompanySite_Guarantee")} />
                                <OmisStack direction="horizontal" gap={1}>
                                    <OmisCol xs={2}>
                                        <OmisCheckBox id={"guaranteeCompanySiteID_Change"} name={"guaranteeCompanySiteID_Change"} className={"OmisComponent"} />
                                    </OmisCol>
                                    <OmisCol xs={10}>
                                        <OmisDropdown id="guaranteeCompanySiteID" name="guaranteeCompanySiteID" items={companySites} disabled={!formik.values.guaranteeCompanySiteID_Change} />
                                    </OmisCol>
                                </OmisStack>
                            </OmisCol>
                            <OmisCol xs={4}>
                                <OmisLabel label={t("Status")} />
                                <OmisStack direction="horizontal" gap={1}>
                                    <OmisCol xs={2}>
                                        <OmisCheckBox id={"statID_Change"} name={"statID_Change"} className={"OmisComponent"} />
                                    </OmisCol>
                                    <OmisCol xs={10}>
                                        <OmisDropdown id="statID" name="statID" items={statIDs} disabled={!formik.values.statID_Change} />
                                    </OmisCol>
                                </OmisStack>
                            </OmisCol>
                            <OmisCol xs={4}>
                                <OmisLabel label={t("ScrappingDate")} />
                                <OmisStack direction="horizontal" gap={1}>
                                    <OmisCol xs={2}>
                                        <OmisCheckBox id={"scrappingDate_Change"} name={"scrappingDate_Change"} className={"OmisComponent"} />
                                    </OmisCol>
                                    <OmisCol xs={10}>
                                        <OmisDatePicker id="scrappingDate" name="scrappingDate" sx={{ height: '2.973em !important' }} disabled={!formik.values.scrappingDate_Change} />
                                    </OmisCol>
                                </OmisStack>
                            </OmisCol>
                            <OmisCol xs={4}>
                                <OmisLabel label={t("EquiPriority")} />
                                <OmisStack direction="horizontal" gap={1}>
                                    <OmisCol xs={2}>
                                        <OmisCheckBox id={"equiPriority_Change"} name={"equiPriority_Change"} className={"OmisComponent"} />
                                    </OmisCol>
                                    <OmisCol xs={10}>
                                        <OmisDropdown id="equiPriorityID" name="equiPriorityID" items={equiPriorities} disabled={!formik.values.equiPriority_Change} />
                                    </OmisCol>
                                </OmisStack>
                            </OmisCol>
                        </OmisRow>

                        {
                            formik?.values?.equiAttributeModels?.length > 0 ?
                                <>
                                    <OmisRow>
                                        <OmisCol xs={12}>
                                            <OmisLabel label={t("ObjectAttributes_Title")} />
                                        </OmisCol>

                                        <OmisCol xs={4}>
                                            <OmisSlider id="equiAttributes_Change" name="equiAttributes_Change" />
                                        </OmisCol>
                                    </OmisRow>

                                    {
                                        formik.values.equiAttributes_Change ?
                                            <AttributesList items={formik.values.equiAttributeModels} handleAttributeValueChange={handleAttributeValueChange} />
                                            :
                                            null
                                    }
                                </>
                                :
                                null
                        }
                        <br/>
                        <OmisRow>
                            <OmisCol xs={6}>
                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={props.handleModalCancel}></OmisButtonSecondary>
                            </OmisCol>
                            <OmisCol xs={6}>
                                <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                            </OmisCol>
                        </OmisRow>
                    </OmisContainer>
                </Form>
            )}
        </Formik>
    );
}