import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { useNavigate } from 'react-router';
import DateHelperService from "../../services/datehelper.service";
import httpclientService from "../../services/httpclient.service";
import { OmisEquipmentChip, OmisObjectChip, OmisUserChip } from "../shared/OmisChips";
import { OmisTimeGap, OmisTimeGapWithWords } from "../shared/OmisDisplays";
import { FilterTab } from "./Reports";
import { Card, Chip, Checkbox } from "@mui/material";
import { mdiDotsHorizontal, mdiTrashCanOutline } from "@mdi/js";
import { OmisTimeFilterBox } from "../shared/OmisInputs";
import { OmisDropDownMenu } from "../shared/OmisButtons";
import { OmisCol, OmisRow } from "../shared/OmisLayouts";

export function TabContent_LinkedReports(props) {
    const { state } = { ...props };
    const navigate = useNavigate();
    const [checked, setChecked] = useState([]);
    const [userItems, setUserItems] = useState([]);
    const [selectedUserItems, setSelectedUserItems] = useState([]);

    const [changedStatusItems, setChangedStatusItems] = useState([]);
    const [selectedChangedStatusItems, setSelectedChangedStatusItems] = useState([]);

    const [objectItems, setObjectItems] = useState([]);
    const [selectedObjectItems, setSelectedObjectItems] = useState([]);

    const [linkedReports, setLinkedReports] = useState([]);
    const [filteredLinkedReports, setFilteredVal] = useState([]);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startStr, setStartStr] = useState("start");
    const [endStr, setEndStr] = useState("end");

    let lastWrittenDate = new Date();

    function loadList() {
        httpclientService.get(`/api/reports/getlinkedreports?reportid=${state?.reportid}`).then((response) => {
            if (response) {
                setLinkedReports(response.linkedReports);
                setChangedStatusItems(response.statusList?.map((item) => ({ key: item.keyStr, displayText: item.displayText })));
                setUserItems(response.usersList?.map((item) => ({ key: item.keyStr, displayText: item.displayText })));
                setObjectItems(response.objectsList?.map((item) => ({ key: item.keyStr, displayText: item.displayText })));

                if ((response.linkedReports?.length === 0) || (props.rowCountFromTab >= 20 && response.linkedReports?.length < 20)) {
                    navigate(0);
                }
            }
        });
    }

    useEffect(() => {
        loadList();
    }, []);

    useEffect(() => {
        setFilteredVal(linkedReports);
    }, [linkedReports]);

    function DeleteLinkedReport(event) {
        var subreportID = event?.target?.id ?? event;
        httpclientService.post(`/api/reports/unlinkreports?reportid=${state?.reportid}&subreportid=${subreportID}`).then((response) => {
            if (response) {
                loadList();
            }
        });
    }   

    const childItemsForDotsMenu = [
        {
            subMenuIcon: mdiTrashCanOutline,
            onClick: DeleteLinkedReport.bind(this),
            menuName: t("Action_Delete"),
        }
    ]

    const handleDateChange = (dateType, startDate, endDate, callbackFunction, e) => {
        if (dateType === startStr) {
            if (endDate && endDate < e) {
                alert("Start Date can not be later than End Date");
            } else callbackFunction(e);
        } else if (dateType === endStr) {
            if (startDate && startDate > e) {
                alert("End Date can not be earlier than Start Date");
            } else callbackFunction(e);
        }
    };

    const handleTimeBoxButtonClicks = (clickType, columnField, filterID, selectedStartDate, selectedEndDate, setStartDateFunctionCallback, setEndDateFunctionCallback) => {
        if (clickType === 'applyClick') {
            var results = linkedReports.filter(x => (selectedStartDate ? new Date(x.dueTime) >= selectedStartDate : true) && (selectedEndDate ? new Date(x.dueTime) <= selectedEndDate : true));
            setFilteredVal(results);
        } else if (clickType === 'resetClick') {
            setStartDateFunctionCallback("");
            setEndDateFunctionCallback("");
            setFilteredVal(linkedReports);
        }
    };

    const handleDropDownChange = (setDropdownCallback, event) => {
        const {
            target: { value,name },
        } = event;

        // On autofill we get a stringified value.
        var _newArr = typeof value === 'string' ? value.split(',') : value;//gives selected values in array
        setDropdownCallback(_newArr.sort((a, b) => a - b));
        if (_newArr.length > 0) {
            var results = linkedReports.filter(x => _newArr.indexOf(x[name].toString()) !== -1); // giving attribute field as name for each dropdown and filtering with the name and value. Example : userName="annunciator",objectName="objectID"
            setFilteredVal(results);
        } else {
            setFilteredVal(linkedReports);
        }
    };

    const checkBoxChange = (selectedBox, event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, selectedBox];
        } else {
            updatedList.splice(checked.indexOf(selectedBox), 1);
        }
        setChecked(updatedList);
    }

    const handleDropDownChangeForChangedStatus = (setDropdownCallback, event) => {
        const {
            target: { value },
        } = event;

        // On autofill we get a stringified value.
        var _newArr = typeof value === 'string' ? value.split(',') : value;//gives selected values in array
        setDropdownCallback(_newArr.sort((a, b) => a - b));
        if (_newArr.length > 0) {
            var results = linkedReports.filter(x => _newArr.indexOf(x.status) !== -1 );
            setFilteredVal(results);
        } else {
            setFilteredVal(linkedReports);
        }
    };

    const childItemsDateTimePicker = [{
        content:
            <OmisTimeFilterBox id={"linkedreporttime"} selectedStartDate={startDate} setStartDate={setStartDate} onChange={handleDateChange}
                selectedEndDate={endDate} setEndDate={setEndDate}
                applyClicked={handleTimeBoxButtonClicks} resetClicked={handleTimeBoxButtonClicks}
                columnField={"linkedreporttime"} filterID={"linkedreporttime"}{...props} />
    }];

    return (
        <>
            <br />

            <Card>
                <FilterTab smallSearchBar
                    getSearchResultItems={setFilteredVal} listItems={linkedReports} listAttr={["reportID", "desc", "objectID", "equipID", "status", "annunciator"]}
                    changedStatusItems={changedStatusItems} handleDropDownChangeForChangedStatus={handleDropDownChange} setSelectedChangedStatusItems={setSelectedChangedStatusItems} selectedChangedStatusItems={selectedChangedStatusItems} statusName="status"
                    userItems={userItems} handleDropDownChange={handleDropDownChange} setSelectedUserItems={setSelectedUserItems} selectedUserItems={selectedUserItems} userName="annunciator"
                    objectItems={objectItems} handleDropDownChange={handleDropDownChange} setSelectedObjectItems={setSelectedObjectItems} selectedObjectItems={selectedObjectItems} objectName="objectID"
                    childItemsDateTimePicker={childItemsDateTimePicker} timetitle={t("Criteria_FromCompletionDate")} />
            </Card>
            <br />

            {
                filteredLinkedReports?.map((item, index) => {
                    let itemDate = new Date(item.dueTime);
                    let writeDate = false;
                    if (!DateHelperService.IsSameDay(lastWrittenDate, itemDate)) {
                        lastWrittenDate = itemDate;
                        writeDate = true;
                    }
                    return (
                        <OmisCol key={`${index}Grid`} xs={12}>
                            {
                                writeDate ?
                                    <OmisCol xs={12}>
                                        <span className="userProfilCardText detailsCardTitle"><OmisTimeGapWithWords date={item.dueTime} /></span>
                                    </OmisCol> : null
                            }
                            <Card sx={{ paddingRight: '0 !important', paddingLeft: '48px !important' }}>
                                <OmisRow>
                                    <OmisCol xs={12}>
                                        <OmisRow>
                                            <OmisCol xs={10} style={{ display: 'flex', alignItems: 'center' }}>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(item.reportID) !== -1}
                                                    tabIndex={-1}
                                                    onChange={checkBoxChange.bind(this, item.reportID)}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${item.reportID}` }}
                                                />
                                                <strong>{`${t("Report_Title")} #${item.reportID}`}</strong>
                                            </OmisCol>

                                            <OmisCol xs={2} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                                <OmisDropDownMenu id={item.reportID} rowid={item.reportID} menuicon={mdiDotsHorizontal} childitems={childItemsForDotsMenu} />
                                            </OmisCol>
                                        </OmisRow>
                                    </OmisCol>
                                </OmisRow>
                                <OmisRow>
                                    <OmisCol xs={12}>
                                        <p>{item.desc}</p>
                                    </OmisCol>
                                </OmisRow>

                                <OmisRow>
                                    <Card sx={{ marginLeft: '3vw !important' }}>
                                        <OmisRow>
                                            <OmisCol xs={2}>
                                                <OmisCol xs={12}>
                                                    <strong>{t("Object")}</strong>
                                                </OmisCol>
                                                {
                                                    item.objectID ?
                                                        <OmisObjectChip objectid={`${item.objectID}`} text={`${item.objectID}`} />
                                                        :
                                                        <span>{t("Grid_Empty")}</span>
                                                }
                                            </OmisCol>
                                            <OmisCol xs={2}>
                                                <OmisCol xs={12}>
                                                    <strong>{t("Equipment")}</strong>
                                                </OmisCol>
                                                {
                                                    item.equipID ?
                                                        <OmisEquipmentChip equipid={`${item.equipID}`} text={`${item.equipID}`} />
                                                        :
                                                        <span>{t("Grid_Empty")}</span>
                                                }
                                            </OmisCol>
                                            <OmisCol xs={2}>
                                                <OmisCol xs={12}>
                                                    <strong>{t("Status")}</strong>
                                                </OmisCol>
                                                {
                                                    item.status !== "" ?
                                                        <Chip clickable={false} variant="outlined" label={`${item.statusDisplayed}`} />
                                                        :
                                                        <span>{t("Grid_Empty")}</span>
                                                }
                                            </OmisCol>
                                            <OmisCol xs={3}>
                                                <OmisCol xs={12}>
                                                    <strong>{t("Annunciator")}</strong>
                                                </OmisCol>
                                                <OmisUserChip userFullName={item.annunciator} userID={0} />
                                            </OmisCol>
                                            <OmisCol xs={3}>
                                                <OmisCol xs={12}>
                                                    <strong>{t("DateCompletion")}</strong>
                                                </OmisCol>
                                                <OmisTimeGap date={item.dueTime} />
                                            </OmisCol>
                                        </OmisRow>
                                    </Card>
                                </OmisRow>
                            </Card>
                        </OmisCol>
                    );
                })
            }
        </>);
}