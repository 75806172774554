export const TenderMessage = {
    INITIALIZE: 1,
    ACCEPT: 2,
    DECLINE: 3,
    PROCESS: 4,
    START_BARGAINING: 5,
    CANCEL: 6,
    SEND_OFFER: 7,
    MANUAL_REQUEST: 8,
    SEND: 9,
    DECLINE_OFFER: 10,
    REMIND: 11,
    SELECT_OFFER: 12,
}