import React, { useState, useCallback ,useEffect} from 'react';
import ImageViewer from 'react-simple-image-viewer';
import { t } from "i18next";
import httpclientService from "../../services/httpclient.service";
import { OmisProgress } from './OmisButtons';
import { OmisErrorMessage } from './OmisDisplays';
import { OmisCol, OmisRow } from './OmisLayouts';

export function OmisImagePreviewer(props) {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [images, setImages] = useState([]);
    const [failed, setFailed] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    useEffect(() => {
        var arr = [];
        var imagetype = "png";
        props.images.map((item, index) => {
            let model = { fileName: item.content.fileName, fullLocation: item.content.fullLocation };
            httpclientService.post(`api/documents/displaydocumentsforpreview`, JSON.stringify(model)).then((response) => {
                imagetype = item.content.fileName.split(".").pop();
                arr.push({ name: item.content.fileName, content: `data:image/${imagetype};base64,${response}` });
                if (props.images.length === arr.length) {
                    setImages(arr);
                }
            });
        });
    }, [props.images]);

    useEffect(() => {
        if (images.length > 0 && props.selectedImage && props.images.length === images.length) {
            if (images.map((item) => item.name).indexOf(props.selectedImage) > -1) {
                openImageViewer(images.map((item) => item.name).indexOf(props.selectedImage));
            } else {
                openImageViewer(props.images.map((item) => item.content.fileName).indexOf(props.selectedImage));
            }
            setLoading(false);
        } else if (images.length > 0 && props.images.length !== images.length) {
            setFailed(true);
        }
    }, [images]);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
        if (props.modalClose) {
            props.modalClose();
        }
    };

    return (
        <div>
            {
                loading ?
                    <OmisProgress />
                    :
                    failed ? 
                        <OmisCol xs={12}>
                            <OmisErrorMessage text={t("HttpError_500_Text")} />
                        </OmisCol>
                        :
                        null
            }
            {/*{*/}
            {/*    images.length > 0 ?*/}
            {/*        images.map((src, index) => (*/}
            {/*            <img*/}
            {/*                src={src.content}*/}
            {/*                onClick={() => openImageViewer(index)}*/}
            {/*                width="300"*/}
            {/*                key={index}*/}
            {/*                style={{ margin: '2px' }}*/}
            {/*                alt={src.name}*/}
            {/*            />*/}
            {/*        ))*/}
            {/*        :*/}
            {/*        null*/}
            {/*}*/}
            {
                isViewerOpen && images.length > 0 ?
                    <ImageViewer
                        src={images.map((item) => item.content)}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                    />
                    :
                    null
            }
        </div>
    );
}

export function OmisPDFViewer(props) {
    const [pdfName, setPdfName] = useState("");
    const [pdfLocation, setPdfLocation] = useState("");
    const [pdfData, setPDFData] = useState("");

    useEffect(() => {
        setPdfName(props.pdfName);
        setPdfLocation(props.pdfLocation);
    }, []);

    useEffect(() => {
        if (pdfName != "" && pdfLocation != "") {
            setPDFData(`api/documents/displaydocuments?fileName=${pdfName}&fullLocation=${pdfLocation}&download=${false}`);
        }
    }, [pdfLocation, pdfName]);
    
    return (
        <OmisRow>
            <OmisCol xs={12} style={{ display: 'flex', justifyContent:"center", alignItems:"center" }}>
                <object data={pdfData} /*"https://www.africau.edu/images/default/sample.pdf"*/ type="application/pdf" width="600" height="600">
                </object>
            </OmisCol>
        </OmisRow> 
    );
}