import React, { useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { Card, IconButton } from "@mui/material";
import Icon from "@mdi/react";
import { mdiTrashCan } from "@mdi/js";
import { OmisAutosizeTextArea, OmisTextBox } from "../../shared/OmisInputs";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { useEffect } from "react";
import { DocumentUploadForNewReport } from "../../documents/Documents";
import { DMSDocumentReferer } from "../../../constants/DMSDocumentReferer";
import { OmisCol, OmisRow } from "../../shared/OmisLayouts";

export function CreateOffer(props) {

    const navigate = useNavigate();
    const formRef = useRef();
    const [currencies, setCurrencies] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [initialValues, setInitialValues] = useState({
        tenderID: props.rowVals?.id ?? null,
        faultReportID: props.rowVals?.faultReportID ?? null,
        requestDescription: props.rowVals?.requestDescription ?? "",
        offerDescription: props.rowVals?.offerDescription ?? "",
        price: props.rowVals?.price ?? "",
        currencyType: props.rowVals?.currencyType ?? "",
        selectedMessage: props.selectedMessage,
        attachedFiles: [],
    });

    useEffect(() => {
        httpclientService.get(`/api/reports/getcurrencies`)
            .then((data) => {
                if (data && data.length > 0) {
                    setCurrencies(data);
                    if (data.length === 1) {
                        formRef.current.setFieldValue("currencyType", data[0].key ?? "0");
                    }
                }
            });
    }, []);

    function handleSave(values) {
        httpclientService.post("/api/reports/savetenderoffer", values).then((response) => {
            if (response) {
                if (props.handleModalClose) {
                    props.handleModalClose();
                } else {
                    navigate(0);
                }
            }
        });
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(0);
        }
    }

    function handleUploadedFiles(file) {
        var newUploadedFiles = [...uploadedFiles, ...file];
        setUploadedFiles(newUploadedFiles);
        formRef.current.setFieldValue("attachedFiles", newUploadedFiles);
    }

    function deleteFileButtonClicked(file) {
        var filteredSelectedFiles = uploadedFiles.filter(s => s.name !== file);
        setUploadedFiles(filteredSelectedFiles);
        formRef.current.setFieldValue("attachedFiles", filteredSelectedFiles);
    }

    const [schema, setSchema] = useState({
        offerDescription: Yup.string()
            .required(t("GenericRequiredText"))
            .max(2048, t('Error_TextTooLong', { 1: 2048 })),
        price: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        <OmisCol>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisTextBox labeltext={t("OfferHistory_RequestDescription")} name="requestDescription" disabled />
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisCol xs={12}>
                                        <OmisAutosizeTextArea labeltext={t("Tender_OfferDescription")} name="offerDescription" required />
                                    </OmisCol>
                                    <OmisCol xs={6}>
                                        <OmisTextBox labeltext={t("Price")} name="price" type="number" required />
                                    </OmisCol>
                                    <OmisCol xs={6}>
                                        <OmisDropdown name={"currencyType"} labeltext={t("Currency")} items={currencies} disabled={true} />
                                    </OmisCol>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <Card variant={"outlined"}>
                                        <DocumentUploadForNewReport multiFilesUploadCallback={handleUploadedFiles} referer={DMSDocumentReferer.Tender} />
                                        <OmisRow>
                                            
                                            <OmisCol xs={12}>
                                                {
                                                    uploadedFiles && uploadedFiles.length > 0 ?
                                                        <>
                                                            <span>{`${t("Import_UploadedFiles")} :`}</span>
                                                            {
                                                                uploadedFiles.map((file, index) =>
                                                                    <OmisCol key={index.toString()} xs={12}>
                                                                        <strong>{file.name}</strong>
                                                                        <IconButton id={"deleteUploadedFileButton"} variant={"outlined"}
                                                                            color={"error"}
                                                                            onClick={() => deleteFileButtonClicked(file.name)}
                                                                        >
                                                                            <Icon path={mdiTrashCan} size={1} />
                                                                        </IconButton>
                                                                    </OmisCol>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        null
                                                }
                                            </OmisCol>
                                        </OmisRow>
                                    </Card>
                                </OmisCol>
                                <OmisCol xs={12} key={`buttonsGrid`} >
                                    <OmisRow>
                                        <OmisCol xs={6}>
                                            <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                        </OmisCol>
                                        <OmisCol xs={6}>
                                            <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                        </OmisCol>
                                    </OmisRow>
                                    
                                </OmisCol>
                            </OmisRow>
                        </OmisCol>
                    </Form>
                )}
            </Formik>
        </>
    );
}