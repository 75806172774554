import React, { useState, useEffect } from 'react';
import { OmisButtonNewItem, OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisGrid } from "../../shared/OmisGrids";
import { mdiTrashCan, mdiPencil } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { OmisDeleteQuestionDialog, OmisDialog } from '../../shared/OmisDisplays';
import { OmisCheckBox, OmisTextBox } from '../../shared/OmisInputs';
import * as Yup from "yup";
import { Form, Formik } from "formik";
import DialogActions from "@mui/material/DialogActions";
import { OmisDropdown } from '../../shared/OmisDropdowns';
import httpClientService from '../../../services/httpclient.service';
import { OmisIconButton } from "../../shared/OmisButtons";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisCol, OmisRow } from '../../shared/OmisLayouts';

export default function FaultEquiReportGroup() {
    const { t } = useTranslation();

    const [changeCounter, setChangeCounter] = useState(0);
    const [newModalOpen, setNewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [faultReportKinds, setFaultReportKinds] = useState([]);

    const [selectedRowID, setSelectedRowID] = useState(0);

    const handleModalOpen = () => {
        setNewModalOpen(true)
    }

    const handleModalClose = () => {
        setNewModalOpen(false);
        setEditModalOpen(false);
        setDeleteModalOpen(false);
        setSelectedRowID(0);
    }

    function handleEditClick(item) {
        setSelectedRowID(item);
        setEditModalOpen(true);
    }

    function handleDeleteClick(item) {
        setSelectedRowID(item);
        setDeleteModalOpen(true);
    }

    function handleDeleteItem(){
        httpClientService.post(`api/settings/faultequiclassgroup/delete?id=${selectedRowID}`).then(response => {
            setChangeCounter(prevState => (prevState + 1));
            handleModalClose();
        })
    }

    useEffect(() => {
        httpClientService.get("/api/settings/faultequiclassgroup/getfaultreportkindslist").then(response => {
            setFaultReportKinds(response)
        })
    }, [])

    const dropDownMenuItems = [
        {
            action: handleEditClick,
            iconpath: mdiPencil,
            icontext: t("Action_Edit")
        },
        {
            action: handleDeleteClick,
            iconpath: mdiTrashCan,
            icontext: t("Action_Delete")
        }
    ]

    return (
        <div class=" omis-header-box">
            <OmisCol xs={1}>
                <OmisIconButton id={"backButton"}
                    iconcontent={<ArrowBackIcon fontSize="inherit" />}
                    text="Back" component={RouterLink} to={"/settings"} state={{ selectedtabvalue: TabConstants.Reports }} />
            </OmisCol>

            <OmisGrid actiondropdown={{ dropDownMenuItems: dropDownMenuItems }}
                change={changeCounter}
                headerText={t("FaultEquiClassGroups")}
                newButton={<OmisButtonNewItem id={"newItemButton"} onClick={handleModalOpen} text={t("Action_New")}/> }
                apiUrl={"/api/settings/faultequiclassgroup/getallfaultequiclassgroups"}
            />
            <OmisDialog onClose={handleModalClose} open={newModalOpen}
                title={t("FaultEquiClassGroup_Create")}
                content={<FaultEquiClassGroupPopup_Content/>}
            />
            <OmisDialog onClose={handleModalClose} open={editModalOpen}
                title={"Bereich bearbeiten"}
                content={<FaultEquiClassGroupPopup_Content /> }
            />
            <OmisDeleteQuestionDialog open={deleteModalOpen}
                handleModalClose={handleModalClose}
                deleteFunction={handleDeleteItem}
            />
        </div>
    );

    function FaultEquiClassGroupPopup_Content() {

        const [initialValues, setInitialValues] = useState({
            id: 0,
            archive: false,
            faultEquiClassGroupDesc: '',
            faultReportKindID: 0,
            autoRemind: false
        })

        const editItem = Boolean(selectedRowID > 0)
        useEffect(() => {
            if (editItem) {
                httpClientService.get(`api/settings/faultequiclassgroup/get?id=${selectedRowID}`).then((response) => {
                    setInitialValues(response);
                })
            }
        }, [])

        function handleSave(values) {
            httpClientService.post("api/settings/faultequiclassgroup/savefaultequiclassgroup", JSON.stringify(values)).then((response) => {
                switch (response.statusCode) {
                    case 200:
                        handleModalClose();
                        setChangeCounter(prevState => prevState + 1);
                        break;
                    default:
                        break;
                }
            })
        }

        return (
            <>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        faultEquiClassGroupDesc: Yup.string()
                            .required(t("GenericRequiredText"))
                            .max(2048),

                        faultReportKindID: Yup.string()
                            .required(t("GenericRequiredText"))
                    })}
                    onSubmit={values => { handleSave(values) }}
                >
                    <Form>
                        <OmisRow>
                            <OmisCol xs="12">
                                <OmisTextBox labeltext={t("Description")} name="faultEquiClassGroupDesc" />
                            </OmisCol>
                        </OmisRow>

                        <OmisRow>   
                            <OmisCol xs={6}>
                                <OmisDropdown labeltext={t("FaultReportKind")} name="faultReportKindID" items={faultReportKinds }/>
                            </OmisCol>
                            <OmisCol xs={6}>
                                <OmisCheckBox labeltext={t("AutoRemind")} name="autoRemind" />
                            </OmisCol>
                        </OmisRow>

                        <OmisRow>
                            <DialogActions>
                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleModalClose}></OmisButtonSecondary>
                                <OmisButtonPrimarySubmit id={"submitButton"} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                            </DialogActions>
                        </OmisRow>
                    </Form>
                </Formik>
            </>
        );
    }
}