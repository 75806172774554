import React, { useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary, OmisIconButton } from "../../shared/OmisButtons";
import { OmisTimePickerNoForm } from "../../shared/OmisInputs";
import { OmisLabel } from "../../shared/OmisDisplays";
import { useEffect } from "react";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function EditObjectOpeningTime(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const [openFrom, setOpenFrom] = useState(null);
    const [openTo, setOpenTo] = useState(null);
    const [daysList, setDaysList] = useState([]);

    const [initialValues, setInitialValues] = useState({
        objectid: props.objectid ?? 0,
        day: props.rowVals?.day ?? 0,
        openFrom: null,
        openTo:null
    });

    useEffect(() => {
        if (props.rowVals) {
            handleFromTimeChange(new Date(props.rowVals?.openFromDate) ?? null);
            handleToTimeChange(new Date(props.rowVals?.openToDate) ?? null);
        }
    }, [props.rowVals]);

    useEffect(() => {
        if (!props.rowVals?.day) {
            var daysurl = `/api/shared/getweekdays?includePublicHoliday=true`;
            if (props.removeexistedopeningdays) {
                daysurl = `${daysurl}&objectid=${props.objectid ?? 0}`
            }

            httpclientService.get(daysurl).then((response) => {
                if (response && response.length > 0) {
                    setDaysList(response);
                }
            });
        } 
    }, [props.objectid]);

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsobjects/saveopeningtime", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate("/settings/objects/");
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate("/settings/objects/");
        }
    }

    function handleFromTimeChange(value) {
        if (value.toString() !== "Invalid Date") {
            formRef.current.setFieldValue("openFrom", value ?? null);
            setOpenFrom(value ?? null);
        }
    }

    function handleToTimeChange(value) {
        if (value.toString() !== "Invalid Date") {
            formRef.current.setFieldValue("openTo", value ?? null);
            setOpenTo(value ?? null);
        }
    }

    function handleClearTime(column) {
        switch (column) {
            case "openFrom":
                formRef.current.setFieldValue("openFrom", null);
                setOpenFrom( null);
                break;
            case "openTo":
                formRef.current.setFieldValue("openTo", null);
                setOpenTo(null);
                break;
            default:
                break;
        }
    }

    const [baseSchema, setBaseSchema] = useState({
        objectid: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        day: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        openTo: Yup.date(t("GenericRequiredText")).typeError(t("GenericRequiredText")).when(['day', 'openFrom'], {
            is: ((day, openFrom) => {
                return (day && openFrom) ? true : false;
            }),
            then: Yup.date(t("GenericRequiredText")).min(Yup.ref('openFrom'),
                "Die Öffnungszeit 'bis' kann nicht vor der Öffnungszeit 'von' liegen.").required().typeError(t("GenericRequiredText"))
        }).nullable(),
    });

    const [schema, setSchema] = useState(baseSchema);

    useEffect(() => {
        if (openTo) {
            setSchema({
                ...baseSchema,
                openFrom: Yup.date()
                    .required(t("GenericRequiredText"))
                    .min(1)
            });
        } else {
            setSchema(baseSchema);
        }
    }, [openTo]);

    const [validationSchema, setValidationSchema] = useState(Yup.object(schema));

    useEffect(() => {
        setValidationSchema(Yup.object(schema));
    }, [schema]);

    useEffect(() => {
        formRef.current.validateForm();
    }, [validationSchema]);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={5}>
                                    {
                                        !initialValues.day ?
                                            <h1>{`${t("Action_New")} ${t("OpeningTime")}`}</h1>
                                            :
                                            null
                                    }
                                </OmisCol>
                            </OmisRow>
                            <br/>
                            <OmisRow>
                                <OmisCol xs={3}>
                                    <OmisLabel label={t("Day")} required />
                                    {
                                        !initialValues.day ?
                                            <OmisDropdown size={"small"} id={"day"} name={"day"} items={daysList} />
                                            :
                                            <><strong>{`${props.rowVals?.daystr}`}</strong></>
                                    }
                                </OmisCol>                                
                            </OmisRow>
                            <br />
                            <OmisRow>
                                <OmisCol xs={6} >
                                    <OmisLabel label={t("From")} required={openTo != null} />
                                    <OmisRow>
                                        <OmisCol xs={10}>
                                            <OmisTimePickerNoForm value={openFrom} onAccept={handleFromTimeChange} onChange={handleFromTimeChange} />
                                        </OmisCol>
                                        {
                                            openFrom ?
                                                <OmisCol xs={1}>
                                                    <OmisIconButton id={"deleteFromButton"}
                                                        iconcontent={<DeleteIcon fontSize="inherit" />}
                                                        text="" onClick={handleClearTime.bind(this, "openFrom")}
                                                        sx={{ marginTop: '12px;' }} />
                                                </OmisCol>
                                                :
                                                null
                                        }
                                    </OmisRow>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisLabel label={t("To")} required={openFrom != null} />
                                    <OmisRow>
                                        <OmisCol xs={10}>
                                            <OmisTimePickerNoForm value={openTo} onAccept={handleToTimeChange} onChange={handleToTimeChange} errorMessage={formik?.errors?.openTo} />
                                        </OmisCol>
                                        {
                                            openTo ?
                                                <OmisCol xs={1}>
                                                    <OmisIconButton id={"deleteToButton"}
                                                        iconcontent={<DeleteIcon fontSize="inherit" />}
                                                        text="" onClick={handleClearTime.bind(this, "openTo")}
                                                        sx={{ marginTop: '12px;' }}
                                                    />
                                                </OmisCol>
                                                :
                                                null
                                        }
                                    </OmisRow>
                                </OmisCol>
                            </OmisRow>
                            <br />
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}