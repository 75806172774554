import { t } from "i18next";
import { Card, Grid, Link } from "@mui/material";
import React, { useState } from "react";
import { DetailsCardWithSkeleton, SmallMap } from "../shared/Shared";
import { OmisDialog, OmisLabel, OmisTime } from "../shared/OmisDisplays";
import { OmisUserChip } from "../shared/OmisChips";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../shared/OmisLayouts";

export function Object_TabContent_Details(props) {
    const {state} = {...props};

    if (!state?.objectid) {
        return <></>
    }

    return (
        <>
        <OmisRow>
            <OmisCol xs={12 }>
                <DetailsCardWithSkeleton
                    url={`/api/objects/details?objectid=${state?.objectid}`}
                    skeletonheight={"200px"}
                    content={
                        <ObjectDetailsInfo {...props} />}{...props} />
            </OmisCol>
        </OmisRow>
        <OmisRow>
            <OmisCol xs={12}>
                {
                    props.hidecontactlist ?
                        null
                        :
                        <DetailsCardWithSkeleton
                            url={`/api/objects/contactlist?objectid=${state?.objectid}`}
                            skeletonheight={"400px"}
                            content={
                                <ContactsList {...props} />}{...props} />
                }
            </OmisCol>
        </OmisRow>
        <OmisRow>
            <OmisCol xs={12}>
                <DetailsCardWithSkeleton
                    url={`/api/objects/addressdetails?objectid=${state?.objectid}`}
                    skeletonheight={"100px"}
                    content={
                        <AddressDetailsInfo {...props} />}{...props} />
            </OmisCol>
        </OmisRow>
        <br/>
        <OmisRow>
            <OmisCol xs={12}>
                {
                    props.hideopeningtimes ?
                        null
                        :
                        <DetailsCardWithSkeleton
                            url={`/api/objects/openingtimes?objectid=${state?.objectid}`}
                            skeletonheight={"100px"}
                            content={
                                <OpeningTimeDetailsInfo {...props} />}{...props} />
                }
            </OmisCol>
        </OmisRow></>);
}

export function ObjectDetailsInfo(props) {
    return (<>
            <OmisCol xs={12}>
                <strong className="detailsCardTitle">{t("Details")}</strong>
            </OmisCol>
            <Card variant={"outlined"}>
                <OmisStack direction="horizontal">
                    <OmisCol xs={3}>
                        <span className="userProfilCardContentText3">{t("Object_ObjectNrInternal")}</span>
                        <p className={"userProfilCardContentText4 bold"}>{props.data.objectNrInternal}</p>
                    </OmisCol>
                    <OmisCol xs={3}>
                        <span className="userProfilCardContentText3">{t("OperationLabel")}</span>
                        <p className={"userProfilCardContentText4 bold"}>{props.data.opLabelDescription}</p>
                    </OmisCol>
                    <OmisCol xs={3}>
                        <span className="userProfilCardContentText3">{t("OperationKind")}</span>
                        <p className={"userProfilCardContentText4 bold"}>{props.data.opKindDescription}</p>
                    </OmisCol>
                    <OmisCol xs={3}>
                        <span className="userProfilCardContentText3">{t("Object_Contractlabel")}</span>
                        <p className={"userProfilCardContentText4 bold"}>{props.data.contractLabel}</p>
                    </OmisCol>
                    
                </OmisStack>
                <OmisCol xs={3}>
                    <span className="userProfilCardContentText3">{t("TradeLevel")}</span>
                    <p className={"userProfilCardContentText4 bold"}>{props.data.tradelevelDescription}</p>
                </OmisCol>
            </Card>
        </>);
}

export function ContactsList(props) {

    const [listLimit, setListLimit] = useState(3);

    const [chipModalOpen, setChipModalOpen] = useState(false);

    const handleChipClickOpen = () => {
        setChipModalOpen(true);
    };

    const handleChipClose = () => {
        setChipModalOpen(false);
    };

    return (
        props.data?.length > 0 ?
            <>
                <br />
                <Card>
                    <OmisStack direction="horizontal" gap={5}>
                        <OmisRow className="gy-5">
                            <OmisCol xs={12}>
                                <h6 className="detailsCardTitle">{t("Object_ContactPersonList")}</h6>
                            </OmisCol>
                        </OmisRow>

                        <div className="ms-auto">
                            {props.data && props.data.length > listLimit ?
                                <Link
                                    component="button"
                                    underline="none"
                                    variant="body2"
                                    onClick={handleChipClickOpen}
                                > {t("ViewAllContacts")} {"->"} {`(${props.data.length})`}
                                </Link> : null}
                        </div>
                    </OmisStack>
                    <OmisRow>
                        <OmisCol xs={12}>
                            <Card variant={"outlined"}>
                                <RenderContactsList shortList={true} listLimit={listLimit} {...props} />
                            </Card>
                        </OmisCol>
                    </OmisRow>
                </Card>

                <OmisDialog onClose={handleChipClose} open={chipModalOpen}
                    title={`${t("Contacts")}`}
                    content={
                        <RenderContactsList shortList={false} listLimit={listLimit} {...props} />}
                    maxWidth={"md"}
                />
            </>
            :
            null
        );
}

function RenderContactsList(props) {

    var limit = props.shortList ? props.listLimit : (props.data ? props.data.length : 0);

    return (props.data && props.data.length > 0 ? <>

            <OmisRow>
                <OmisCol xs={3}>
                    <p>{t("Name")}</p>
                </OmisCol>
                <OmisCol xs={3}>
                    <p>{t("ContactType")}</p>
                </OmisCol>
                <OmisCol xs={3}>
                    <p>{t("Phone")}</p>
                </OmisCol>
                <OmisCol xs={3}>
                    <p>{t("Email")}</p>
                </OmisCol>
            </OmisRow>
            { props.data.map((d, index) => index < limit ?

                <OmisRow key={ d.userID }>
                    <OmisCol xs={ 3 }>
                        <OmisUserChip userFullName={d.name} userName={d.userName} userID={ d.userID }/>
                    </OmisCol>
                    <OmisCol xs={ 3 }>
                        <p className={ "userProfilCardContentText3 bold" }>{ d.contactType }</p>
                    </OmisCol>
                    <OmisCol xs={ 3 }>
                        <p className={ "userProfilCardInfoText bold" }>{ d.phone }</p>
                    </OmisCol>
                    <OmisCol xs={ 3 }>
                            <p className={"userProfilCardInfoText bold"}><a href={`mailto:${d.email}`}>{d.email}</a></p>
                    </OmisCol>
                </OmisRow> : null) }
        </> : null);
}

export function AddressDetailsInfo(props) {
    return (
        props.data?.state !== null || props.data?.city !== null || props.data?.zipCode !== null || (props.data?.streetstring?.trim() !== '') || (props.data?.latitude !== null && props.data?.longitude !== null)
            ?
            props.noCard ?
                <OmisContainer fluid>
                    <br />
                    <OmisRow>
                        <OmisCol xs={9}>
                            <OmisRow>
                                <strong>{t("Address")}</strong>
                            </OmisRow>
                        </OmisCol>
                    </OmisRow>
                    <OmisRow>
                        <OmisCol xs={9}>
                            <OmisRow>
                                <OmisCol xs={4}>
                                    <span className="userProfilCardContentText3">{t("Address_State")}</span>
                                    <p className={"userProfilCardContentTextWB1 bold"}>{props.data?.state}</p>
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <span className="userProfilCardContentText3">{t("City")}</span>
                                    <p className={"userProfilCardContentTextWB1 bold"}>{props.data?.city}</p>
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <span className="userProfilCardContentText3">{t("ZipCode")}</span>
                                    <p className={"userProfilCardContentTextWB1 bold"}>{props.data?.zipCode}</p>
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <span className="userProfilCardContentText3">{t("Street")}</span>
                                    <p className={"userProfilCardContentTextWB1 bold"}>{props.data?.streetstring}</p>
                                </OmisCol>
                            </OmisRow>
                        </OmisCol>
                        <OmisCol xs={{ span: 3, offset: 0 }}>
                            <SmallMap latitude={props.data?.latitude} longitude={props.data?.longitude} />
                        </OmisCol>
                    </OmisRow>
                </OmisContainer>
                :
                <>
                    <br />
                    <Card>
                        <OmisRow>
                            <OmisCol xs={12}>
                                <h6 className="detailsCardTitle">{t("Address")}</h6>
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol xs={8} className="detailsCardTitle">
                                <OmisStack direction="horizontal" gap={1}>
                                    <OmisCol xs={4}>
                                        <span className="userProfilCardContentText3">{t("Address_State")}</span>
                                        <p className={"userProfilCardContentTextWB1 bold"}>{props.data?.state}</p>
                                    </OmisCol>
                                    <OmisCol xs={4}>
                                        <span className="userProfilCardContentText3">{t("City")}</span>
                                        <p className={"userProfilCardContentTextWB1 bold"}>{props.data?.city}</p>
                                    </OmisCol>
                                    <OmisCol xs={4}>
                                        <span className="userProfilCardContentText3">{t("ZipCode")}</span>
                                        <p className={"userProfilCardContentTextWB1 bold"}>{props.data?.zipCode}</p>
                                    </OmisCol>
                                </OmisStack>
                                <OmisCol xs={4}>
                                    <span className="userProfilCardContentText3">{t("Street")}</span>
                                    <p className={"userProfilCardContentTextWB1 bold"}>{props.data?.streetstring}</p>
                                </OmisCol>

                            </OmisCol>
                            <OmisCol xs={3}>
                                <SmallMap latitude={props.data?.latitude} longitude={props.data?.longitude} />
                            </OmisCol>
                        </OmisRow>
                    </Card>
                </>
            :
            null        
    );
}

function OpeningTimeDetailsInfo(props) {

    const {
        moOpenFrom,
        moOpenTo,
        tuOpenFrom,
        tuOpenTo,
        weOpenFrom,
        weOpenTo,
        thOpenFrom,
        thOpenTo,
        frOpenFrom,
        frOpenTo,
        saOpenFrom,
        saOpenTo,
        suOpenFrom,
        suOpenTo,
        phOpenFrom,
        phOpenTo
    } = {...props.data};

    return (<>
        <Card>
            <OmisRow >
                <OmisCol xs={12}>
                    <h6 >{t("OpeningTime")}</h6>
                </OmisCol>
        </OmisRow>
            <OmisRow >
            
                <OmisStack direction="horizontal" gap={1}>
                <OmisCol xs={3}>
                    <span className="userProfilCardContentText3">{t("Calendar_Monday")}</span>
                    <p className={"userProfilCardContentTextWB1 bold"}>
                        <OmisTime date={moOpenFrom} /> - <OmisTime date={moOpenTo} />
                    </p>
                </OmisCol>
                <OmisCol xs={3}>
                    <span className="userProfilCardContentText3">{t("Calendar_Tuesday")}</span>
                    <p className={"userProfilCardContentTextWB1 bold"}>
                        <OmisTime date={tuOpenFrom} /> - <OmisTime date={tuOpenTo} />
                    </p>
                </OmisCol>
                <OmisCol xs={3}>
                    <span className="userProfilCardContentText3">{t("Calendar_Wednesday")}</span>
                    <p className={"userProfilCardContentTextWB1 bold"}>
                        <OmisTime date={weOpenFrom} /> - <OmisTime date={weOpenTo} />
                    </p>
                </OmisCol>
                <OmisCol xs={3}>
                    <span className="userProfilCardContentText3">{t("Calendar_Thursday")}</span>
                    <p className={"userProfilCardContentTextWB1 bold"}>
                        <OmisTime date={thOpenFrom} /> - <OmisTime date={thOpenTo} />
                    </p>
                    </OmisCol>
                </OmisStack>
                <OmisStack direction="horizontal" gap={1}>
                <OmisCol xs={3}>
                    <span className="userProfilCardContentText3">{t("Calendar_Friday")}</span>
                    <p className={"userProfilCardContentTextWB1 bold"}>
                        <OmisTime date={frOpenFrom} /> - <OmisTime date={frOpenTo} />
                    </p>
                </OmisCol>
                <OmisCol xs={3}>
                    <span className="userProfilCardContentText3">{t("Calendar_Saturday")}</span>
                    <p className={"userProfilCardContentTextWB1 bold"}>
                        <OmisTime date={saOpenFrom} /> - <OmisTime date={saOpenTo} />
                    </p>
                </OmisCol>
                <OmisCol xs={3}>
                    <span className="userProfilCardContentText3">{t("Calendar_Sunday")}</span>
                    <p className={"userProfilCardContentTextWB1 bold"}>
                        <OmisTime date={suOpenFrom} /> - <OmisTime date={suOpenTo} />
                    </p>
                </OmisCol>
                <OmisCol xs={3}>
                    <span className="userProfilCardContentText3">{t("Calendar_PublicHoliday")}</span>
                    {
                        phOpenFrom && phOpenTo ?
                            <p className={"userProfilCardContentTextWB1 bold"}>
                                <OmisTime date={phOpenFrom} /> - <OmisTime date={phOpenTo} />
                            </p>
                            :
                            <p className={"userProfilCardContentTextWB1 bold"}>
                                {t("Grid_Empty")}
                            </p>
                    }
                    </OmisCol>
                </OmisStack>
        </OmisRow>
        </Card>
        </>);
}