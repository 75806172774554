import React, { useState } from "react";
import { t } from "i18next";
import httpclientService from "../../services/httpclient.service";
import { mdiArchive } from '@mdi/js';
import { OmisGrid } from "../shared/OmisGrids";
import { OmisDialog } from "../shared/OmisDisplays";
import SetUserReplacement from "./SetUserReplacement";
import { OmisButtonNewItem } from "../shared/OmisButtons";
import EditHolidayReplacement from "./EditHolidayReplacement";

var url = "/api/profile/";

export function HolidayReplacementList(props) {

    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState("");
    const [changeCounter, setChangeCounter] = useState(0);

    const handleModalOpen = (content, title, e) => {
        setModalContent(content);
        setModalTitle(title);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setModalContent(null);
        setModalTitle(null);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
        setModalContent(null);
        setModalTitle(null);
    };

    const editReplacement = (rowID,e) => {
        handleModalOpen(<EditHolidayReplacement id={rowID} rowVals={e} handleModalClose={handleModalClose} handleModalCancel={handleModalCancel} />, t("Action_Edit"), rowID);
    };

    const newReplacement = () => {
        handleModalOpen(<SetUserReplacement handleModalClose={handleModalClose} handleModalCancel={handleModalCancel} />, t("Action_New"));
    };

    const archiveClicked = (rowID) => {        
        httpclientService.post(`api/profile/setholidayreplacementarchive?id=${rowID}`).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    };

    var dropDownMenuItems = [
        {
            action: archiveClicked,
            iconpath: mdiArchive,
            icontext: t("Action_Archive"),
        }
    ]

    return (
        <>
            <OmisGrid
                headerText={t("HolidayReplacement_Title")}
                apiUrl={`${url}getholidayreplacementlist`}
                actiondropdown={{ edit: editReplacement, dropDownMenuItems: dropDownMenuItems }}
                newButton={<OmisButtonNewItem id={"newItemButton"} key={`addButton`} {...props} onClick={newReplacement} text={t("Action_New")} size={"small"} />}
                noExportButton
                change={changeCounter}
                {...props} />

            <OmisDialog onClose={handleModalCancel} open={modalOpen} maxWidth={"md"} title={modalTitle} content={modalContent} />
        </>
        );

}