import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisTextBox } from "../../shared/OmisInputs";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateMapping(props) {

    const navigate = useNavigate();
    const formRef = useRef();
    const [initialValues, setInitialValues] = useState({
        id: props.rowVals?.id ?? null,
        mappingGroupID: props.rowVals?.mappingGroupID ?? null,
        valueOmis: props.rowVals?.valueOMIS ?? props.rowVals?.valueOmis ?? "",
        valueExtern: props.rowVals?.valueExtern ?? "",
    });

    const [mappingGroups, setMappingGroups] = useState([]);

    useEffect(() => {
        httpclientService.get("/api/settings/settingsfsmintern/getmappinggroups").then((response) => {
            if (response && response.length > 0) {
                setMappingGroups(response);
            }
        });
    },[]);

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsfsmintern/savemapping", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate("/settings/settingsfsmintern/mappings");
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate("/settings/settingsfsmintern/mappings");
        }
    }

    const [schema, setSchema] = useState({
        mappingGroupID: Yup.string()
            .required(t("GenericRequiredText"))
            .min(1),
        valueOmis: Yup.string()
            .required(t("GenericRequiredText"))
            .max(2048, t('Error_TextTooLong', { 1: 2048 })),
        valueExtern: Yup.string()
            .required(t("GenericRequiredText"))
            .max(2048, t('Error_TextTooLong', { 1: 2048 })),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("New_Mapping")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>

                            <OmisRow>
                                <OmisCol xs={4}>
                                    <span>{t("Settings_MappingGroup")}</span>
                                    <OmisDropdown size={"small"} id={"mappingGroupID"} name={"mappingGroupID"} items={mappingGroups} required />
                                </OmisCol>
                            </OmisRow>

                            <OmisRow>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={"ValueOMIS"} name="valueOmis" required />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={"ValueExtern"} name="valueExtern" required />
                                </OmisCol>
                            </OmisRow>

                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>

                    </Form>
                )}
            </Formik>
        </>
    );
}