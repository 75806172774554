import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonNewItem, OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { OmisDialog, OmisSelectedCatalogueCard } from "../../shared/OmisDisplays";
import { CreateEquiClass } from "../Equipments/CreateEquiClass";
import { SettingsEquiClasses } from "../Equipments/SettingsEquiClasses";

const SelectionStep = {
    EquiClass: 1,
    Done: 2
}
export function AddEquiClass(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [catalogueCardClicked, setCatalogueCardClicked] = useState(false);
    const [selectionStep, setSelectionStep] = useState(SelectionStep.EquiClass);
    const [selectedEquiClassText, setSelectedEquiClassText] = useState("");
    const [selectedEquiClassID, setSelectedEquiClassID] = useState(null);

    const [initialValues, setInitialValues] = useState({
        id: props.equiClassID ?? props.rowVals?.equiClassID ?? null,
        description: "",
        equiClass: "",
        faultEquiClassDefID: props.faultEquiClassDefID ?? props.rowVals?.faultEquiClassDefID ?? null,
        customerEditable: props.rowVals?.customerEditable === false ? false : true,
    });

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsequipments/addequiclasstodefinition", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(-1);
                }
            }
        });
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(-1);
        }
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    function handleNewEquiClassClick(rowID, rowVals) {
        if (rowID && typeof (rowID) === 'number') {
            setModalTitle(t("Action_Edit"));
        } else {
            setModalTitle("");
        }

        if (props.faultEquiClassDefID && props.faultEquiClassDefID > 0) {
            rowVals.faultEquiClassDefID = props.faultEquiClassDefID;
        }
        setModalContent(<CreateEquiClass onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID && typeof (rowID) === 'number' ? rowID : null} rowVals={rowVals ?? props.rowVals} />);
        handleModalOpen();
    }

    function handleEquiClassSelect(row) {
        setSelectionStep(SelectionStep.Done);
        setSelectedEquiClassID(row?.id ?? null);
        setSelectedEquiClassText(row?.equiClass ?? row.description ?? '');
        formRef.current.setFieldValue("id", row?.id ?? null);
        setCatalogueCardClicked(false);
    }
    function handleEquiClassDeselect() {
        setSelectionStep(SelectionStep.EquiClass);
        setSelectedEquiClassID( null);
        setSelectedEquiClassText( '');
        formRef.current.setFieldValue("id",null);
        setCatalogueCardClicked(true);
    }

    const [schema, setSchema] = useState({
        faultEquiClassDefID: Yup.string()
            .required(t("GenericRequiredText"))
            .min(1, t('GenericRequiredText')),
        id: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1, t('GenericRequiredText')),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("EquiClasss")}</h1>
                                :
                                null
                        }

                        <OmisContainer fluid>
                            <OmisRow>
                                {
                                    selectionStep < SelectionStep.Done ?
                                        <OmisCol xs={12} style={{ display: 'flex', justifyContent: 'right' }}>
                                            <OmisCol xs={3}>
                                                <OmisButtonNewItem id={"newCompanysiteButton"} onClick={handleNewEquiClassClick} text={t("Action_New")} />
                                            </OmisCol>
                                        </OmisCol>
                                        :
                                        null
                                }
                                <OmisCol xs={6}>
                                    <OmisSelectedCatalogueCard
                                        text={`${t("EquiClass")} (${selectedEquiClassText})`}
                                        id={selectedEquiClassID}
                                        selectionstep={SelectionStep.EquiClass}
                                        required
                                        active={selectionStep < SelectionStep.Done}
                                        handleDeselect={() => {
                                            if (selectionStep >= SelectionStep.EquiClass) {
                                                handleEquiClassDeselect();
                                            }
                                        }}
                                    />
                                </OmisCol>
                                {
                                    selectionStep < SelectionStep.Done ?
                                        <OmisCol xs={12}>
                                            <SettingsEquiClasses
                                                hideBack
                                                noButtons noToolbar
                                                rowclickCallback={handleEquiClassSelect}
                                                singleEntriesCallback={handleEquiClassSelect}
                                                catalogueCardClicked={catalogueCardClicked}
                                            />
                                        </OmisCol>
                                        :
                                        null
                                }
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"md"}
                title={modalTitle}
                content={modalContent}
                contentHeight={300}
            />
        </>
    );
}