import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { Card } from "@mui/material";
import { OmisAutosizeTextAreaV2, OmisInputSlider, OmisTextArea, OmisTextBox } from "../../shared/OmisInputs";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { OmisLabel } from "../../shared/OmisDisplays";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateRating(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const [timeEnums, setTimeEnums] = useState([]);
    const marks = [
        {
            value: 0,
            label: '0%',
        },
        {
            value: 50,
            label: '50%',
        },
        {
            value: 100,
            label: '100%',
        },
    ]

    const [initialValues, setInitialValues] = useState({
        id:  props.id ?? null,
        ratingTypeID: props.rowVals?.ratingTypeID ?? 0,
        ratingTimeWeight: props.rowVals?.ratingTimeWeight ?? 0,
        ratingCostsWeight: props.rowVals?.ratingCostsWeight ?? 0,
        ratingSustainabilityWeight: props.rowVals?.ratingSustainabilityWeight ?? 0,
        ratingSubjectiveRatingWeight: props.rowVals?.ratingSubjectiveRatingWeight ?? 0,
        ratingTimeWeightPartFactorReactionTime: props.rowVals?.ratingTimeWeightPartFactorReactionTime ?? 0,
        ratingTimeWeightPartFactorReminder: props.rowVals?.ratingTimeWeightPartFactorReminder ?? 0,
        ratingTimeWeightPartFactorReactionTimeLowest: props.rowVals?.ratingTimeWeightPartFactorReactionTimeLowest ?? 0,
        ratingTimeWeightPartFactorReminderHighest: props.rowVals?.ratingTimeWeightPartFactorReminderHighest ?? 0,
        ratingCostsWeightPartFactorCostEstimation: props.rowVals?.ratingCostsWeightPartFactorCostEstimation ?? 0,
        ratingCostsWeightPartFactorCostEstimationLowest: props.rowVals?.ratingCostsWeightPartFactorCostEstimationLowest ?? 0,
        ratingSustainabilityWeightPartFactorReopen: props.rowVals?.ratingSustainabilityWeightPartFactorReopen ?? 0,
        ratingSustainabilityWeightPartFactorReopenHighest: props.rowVals?.ratingSustainabilityWeightPartFactorReopenHighest ?? 0,
        ratingSustainabilityWeightPartFactorRepairTime: props.rowVals?.ratingSustainabilityWeightPartFactorRepairTime ?? 0,
        ratingSubjectiveRatingWeightPartFactorFriendliness: props.rowVals?.ratingSubjectiveRatingWeightPartFactorFriendliness ?? 0,
        ratingSubjectiveRatingWeightPartFactorQuality: props.rowVals?.ratingSubjectiveRatingWeightPartFactorQuality ?? 0,
        ratingSubjectiveRatingWeightPartFactorReliability: props.rowVals?.ratingSubjectiveRatingWeightPartFactorReliability ?? 0,
        ratingSubjectiveRatingWeightPartFactorCommunication: props.rowVals?.ratingSubjectiveRatingWeightPartFactorCommunication ?? 0,
        ratingSubjectiveRatingWeightPartFactorConsultation: props.rowVals?.ratingSubjectiveRatingWeightPartFactorConsultation ?? 0,
        ratingSubjectiveRatingWeightPartFactorFriendlinessDescription: props.rowVals?.ratingSubjectiveRatingWeightPartFactorFriendlinessDescription ?? "",
        ratingSubjectiveRatingWeightPartFactorQualityDescription: props.rowVals?.ratingSubjectiveRatingWeightPartFactorQualityDescription ?? "",
        ratingSubjectiveRatingWeightPartFactorReliabilityDescription: props.rowVals?.ratingSubjectiveRatingWeightPartFactorReliabilityDescription ?? "",
        ratingSubjectiveRatingWeightPartFactorCommunicationDescription: props.rowVals?.ratingSubjectiveRatingWeightPartFactorCommunicationDescription ?? "",
        ratingSubjectiveRatingWeightPartFactorConsultationDescription: props.rowVals?.ratingSubjectiveRatingWeightPartFactorConsultationDescription ?? "",
        ratingMaxRateTime: props.rowVals?.ratingMaxRateTime ?? 0,
        ratingMaxRateTimeEnum: props.rowVals?.ratingMaxRateTimeEnum ?? null,
        ratingMaxEditRateTime: props.rowVals?.ratingMaxEditRateTime ?? 0,
        ratingMaxEditRateTimeEnum: props.rowVals?.ratingMaxEditRateTimeEnum ?? null,
        hintText: props.rowVals?.hintText ?? "",
        ratingWeightSum: 0,
        ratingTimeSum: 0,
        ratingCostSum: 0,
        ratingSustainabilitySum: 0,
        ratingSubjectiveSum: 0,
    });

    useEffect(() => {

        httpclientService.get("api/shared/getperiodselectionlist").then((response) => {
            if (response?.length>0) {
                setTimeEnums(response);
            }
        });

        if (props.id && props.id > 0) {
            httpclientService.get(`/api/settings/companysite/getratingdetails?id=${props.id}`).then((response) => {
                if (response) {
                    setInitialValues(response);
                    formRef.current.setValues(response);
                }
            });

            const keys = Object.keys(initialValues);
            keys.forEach(key => {
                if (initialValues[key]) {
                    formRef.current.setFieldTouched(`${key}`, true, true);
                }
            });
        }
    }, []);

    function handleSave(values) {
        httpclientService.post("/api/settings/companysite/saverating", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(-1);
                }
            }
        });
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(-1);
        }
    }

    function handleSumFieldChange(e) {
        var fieldValue = e.target.value;
        var fieldName = e.target.name;
        setSliderValue(fieldName, null, fieldValue);
    }

    function setSliderValue(field, event, tmpvalue) {
        var value = parseInt(tmpvalue);
        if (field && value>-1 ) {
            formRef.current.setFieldValue(field, value);
            var tmp = { ...formRef.current.values };
            tmp[field] = value;

            //Weight
            if (field === "ratingTimeWeight" || field === "ratingCostsWeight"
                || field === "ratingSustainabilityWeight" || field === "ratingSubjectiveRatingWeight") {

                var sum = value;
                if (field !== "ratingTimeWeight") {
                    sum += parseInt(formRef.current.values.ratingTimeWeight);
                }

                if (field !== "ratingCostsWeight") {
                    sum += parseInt(formRef.current.values.ratingCostsWeight);
                }

                if (field !== "ratingSustainabilityWeight") {
                    sum += parseInt(formRef.current.values.ratingSustainabilityWeight);
                }

                if (field !== "ratingSubjectiveRatingWeight") {
                    sum += parseInt(formRef.current.values.ratingSubjectiveRatingWeight);
                }

                formRef.current.setFieldValue("ratingWeightSum", sum);
                tmp["ratingWeightSum"] = sum;
            }

            //Time
            if (field === "ratingTimeWeightPartFactorReactionTime" || field === "ratingTimeWeightPartFactorReminder") {

                var sumTime = value;
                if (field !== "ratingTimeWeightPartFactorReactionTime") {
                    sumTime += parseInt(formRef.current.values.ratingTimeWeightPartFactorReactionTime);
                }

                if (field !== "ratingTimeWeightPartFactorReminder") {
                    sumTime += parseInt(formRef.current.values.ratingTimeWeightPartFactorReminder);
                }
                formRef.current.setFieldValue("ratingTimeSum", sumTime);
                tmp["ratingTimeSum"] = sumTime;
            }

            //Cost
            if (field === "ratingCostsWeightPartFactorCostEstimation" ) {
                formRef.current.setFieldValue("ratingCostSum", value);
                tmp["ratingCostSum"] = value;
            }

            //Sustainability
            if (field === "ratingSustainabilityWeightPartFactorReopen") {
                formRef.current.setFieldValue("ratingSustainabilitySum", value);
                tmp["ratingSustainabilitySum"] = value;
            }

            //Subjective
            if (field === "ratingSubjectiveRatingWeightPartFactorFriendliness" || field === "ratingSubjectiveRatingWeightPartFactorQuality"
                || field === "ratingSubjectiveRatingWeightPartFactorReliability" || field === "ratingSubjectiveRatingWeightPartFactorCommunication"
                || field === "ratingSubjectiveRatingWeightPartFactorConsultation") {

                var sumSubjective = value;
                if (field !== "ratingSubjectiveRatingWeightPartFactorFriendliness") {
                    sumSubjective += parseInt(formRef.current.values.ratingSubjectiveRatingWeightPartFactorFriendliness);
                }

                if (field !== "ratingSubjectiveRatingWeightPartFactorQuality") {
                    sumSubjective += parseInt(formRef.current.values.ratingSubjectiveRatingWeightPartFactorQuality);
                }

                if (field !== "ratingSubjectiveRatingWeightPartFactorReliability") {
                    sumSubjective += parseInt(formRef.current.values.ratingSubjectiveRatingWeightPartFactorReliability);
                }

                if (field !== "ratingSubjectiveRatingWeightPartFactorCommunication") {
                    sumSubjective += parseInt(formRef.current.values.ratingSubjectiveRatingWeightPartFactorCommunication);
                }

                if (field !== "ratingSubjectiveRatingWeightPartFactorConsultation") {
                    sumSubjective += parseInt(formRef.current.values.ratingSubjectiveRatingWeightPartFactorConsultation);
                }

                formRef.current.setFieldValue("ratingSubjectiveSum", sumSubjective);
                tmp["ratingSubjectiveSum"] = sumSubjective;
            }

            setInitialValues(tmp);
        }
    }

    const [schema, setSchema] = useState({

        ratingMaxRateTimeEnum: Yup.number()
            .required(t("GenericRequiredText"))
            .min(0),
        ratingMaxEditRateTimeEnum: Yup.number()
            .required(t("GenericRequiredText"))
            .min(0),

        //Weight
        ratingTimeWeight: Yup.number()
            .required(t("GenericRequiredText"))
            .min(-1),
        ratingCostsWeight: Yup.number()
            .required(t("GenericRequiredText"))
            .min(-1),
        ratingSustainabilityWeight: Yup.number()
            .required(t("GenericRequiredText"))
            .min(-1),
        ratingSubjectiveRatingWeight: Yup.number()
            .required(t("GenericRequiredText"))
            .min(-1)
            .test( function (value) {
                const sum = formRef.current.values.ratingTimeWeight + formRef.current.values.ratingCostsWeight + formRef.current.values.ratingSustainabilityWeight + formRef.current.values.ratingSubjectiveRatingWeight;
                return sum === 100;
            }),

        //Time
        ratingTimeWeightPartFactorReactionTime: Yup.number()
            .required(t("GenericRequiredText"))
            .min(-1),
        ratingTimeWeightPartFactorReminder: Yup.number()
            .required(t("GenericRequiredText"))
            .min(-1)
            .test(function (value) {
                const sum = formRef.current.values.ratingTimeWeightPartFactorReactionTime + formRef.current.values.ratingTimeWeightPartFactorReminder;
                return sum === 100;
            }),

        //Cost
        ratingCostsWeightPartFactorCostEstimation: Yup.number()
            .required(t("GenericRequiredText"))
            .min(100),

        //Sustainability
        ratingSustainabilityWeightPartFactorReopen: Yup.number()
            .required(t("GenericRequiredText"))
            .min(100),

        //Subjective
        ratingSubjectiveRatingWeightPartFactorFriendliness: Yup.number()
            .required(t("GenericRequiredText"))
            .min(-1),
        ratingSubjectiveRatingWeightPartFactorQuality: Yup.number()
            .required(t("GenericRequiredText"))
            .min(-1),
        ratingSubjectiveRatingWeightPartFactorReliability: Yup.number()
            .required(t("GenericRequiredText"))
            .min(-1),
        ratingSubjectiveRatingWeightPartFactorCommunication: Yup.number()
            .required(t("GenericRequiredText"))
            .min(-1),
        ratingSubjectiveRatingWeightPartFactorConsultation: Yup.number()
            .required(t("GenericRequiredText"))
            .min(-1)
            .test(function (value) {
                const sum = formRef.current.values.ratingSubjectiveRatingWeightPartFactorFriendliness + formRef.current.values.ratingSubjectiveRatingWeightPartFactorQuality + formRef.current.values.ratingSubjectiveRatingWeightPartFactorReliability + formRef.current.values.ratingSubjectiveRatingWeightPartFactorCommunication + formRef.current.values.ratingSubjectiveRatingWeightPartFactorConsultation;
                return sum === 100;
            }),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("Rating_Rating")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <Card>
                                    <OmisRow>
                                        <OmisCol xs={6}>
                                            <OmisAutosizeTextAreaV2 labeltext={t("Rating_HintText")} name={"hintText"} placeholder={t("Rating_HintText")} />
                                        </OmisCol>
                                        <br />
                                        <strong>{"General"}</strong>
                                        <OmisRow>
                                            <OmisCol xs={3}>
                                                <OmisTextBox labeltext={t("Rating_MaxRateTime")} name={"ratingMaxRateTime"} placeholder={t("Rating_MaxRateTime")} type="number" />
                                            </OmisCol>
                                            <OmisCol xs={2}>
                                                <OmisDropdown labeltext={t("SensorData_Unit")} name={"ratingMaxRateTimeEnum"} placeholder={t("SensorData_Unit")} items={timeEnums} required />
                                            </OmisCol>


                                            <OmisCol xs={3}>
                                                <OmisTextBox labeltext={t("Rating_MaxEditRateTime")} name={"ratingMaxEditRateTime"} placeholder={t("Rating_MaxEditRateTime")} type="number" />
                                            </OmisCol>
                                            <OmisCol xs={2}>
                                                <OmisDropdown changecolorbyval={1} labeltext={t("SensorData_Unit")} name={"ratingMaxEditRateTimeEnum"} placeholder={t("SensorData_Unit")} items={timeEnums} required />
                                            </OmisCol>
                                        </OmisRow>
                                    </OmisRow>
                                </Card>
                            </OmisRow>
                            <br/>

                            <OmisRow>
                                <Card>
                                    <strong>{t("Rating_Weight_SumTitle")}</strong>
                                    <OmisCol xs={12}>
                                        <OmisLabel label={t("Rating_Weight_SumText")} />
                                        <br/>
                                        <OmisInputSlider changecolorbyval={1} key={`${"ratingWeightSum"}_Slider`} value={formik.values.ratingWeightSum ?? 0} marks={marks} valueLabelDisplay="on" />
                                    </OmisCol>
                                </Card>
                            </OmisRow>

                            <br />
                            
                            <OmisRow>
                                <Card>
                                    <OmisRow>
                                        <OmisCol xs={6}>
                                            <OmisCol xs={12}>
                                                <OmisTextBox onBlur={handleSumFieldChange} labeltext={<strong>{t("Rating_Weight_Time")}</strong>} name={"ratingTimeWeight"} placeholder={t("Rating_Weight_Time")} type="number" required />
                                            </OmisCol>
                                            <OmisCol xs={12}>
                                                <OmisInputSlider changecolorbyval={1} key={`${"ratingTimeWeight"}_Slider`} onChange={setSliderValue.bind(this, "ratingTimeWeight")} onBlur={setSliderValue.bind(this, "ratingTimeWeight")} value={formik.values.ratingTimeWeight && formik.values.ratingTimeWeight !== '' ? formik.values.ratingTimeWeight : 0} />
                                            </OmisCol>
                                        </OmisCol>
                                        <OmisCol xs={6}>
                                            <OmisLabel label={t("Rating_Weight_Time_SumText")} />
                                            <br />
                                            <OmisInputSlider changecolorbyval={1} key={`${"ratingTimeSum"}_Slider`} value={formik.values.ratingTimeSum ?? 0} marks={marks} valueLabelDisplay="on" />
                                        </OmisCol>
                                    </OmisRow>
                                    <OmisRow>
                                        <OmisCol xs={6}>
                                            <OmisCol xs={12}>
                                                <OmisTextBox onBlur={handleSumFieldChange} labeltext={t("Rating_Time_Weight_PartFactor_ReactionTime")} name={"ratingTimeWeightPartFactorReactionTime"} placeholder={t("Rating_Time_Weight_PartFactor_ReactionTime")} type="number" required />
                                            </OmisCol>
                                            <OmisCol xs={12}>
                                                <OmisInputSlider changecolorbyval={1} key={`${"ratingTimeWeightPartFactorReactionTime"}_Slider`} onChange={setSliderValue.bind(this, "ratingTimeWeightPartFactorReactionTime")} value={formik.values.ratingTimeWeightPartFactorReactionTime ?? 0} />
                                            </OmisCol>
                                        </OmisCol>

                                        <OmisCol xs={6}>
                                            <OmisCol xs={12}>
                                                <OmisTextBox onBlur={handleSumFieldChange} labeltext={t("Rating_Time_Weight_PartFactor_Reminder")} name={"ratingTimeWeightPartFactorReminder"} placeholder={t("Rating_Time_Weight_PartFactor_Reminder")} type="number" required />
                                            </OmisCol>
                                            <OmisCol xs={12}>
                                                <OmisInputSlider changecolorbyval={1} key={`${"ratingTimeWeightPartFactorReminder"}_Slider`} onChange={setSliderValue.bind(this, "ratingTimeWeightPartFactorReminder")} value={formik.values.ratingTimeWeightPartFactorReminder ?? 0} />
                                            </OmisCol>
                                        </OmisCol>

                                        <OmisCol xs={6}>
                                            <OmisCol xs={12}>
                                                <OmisTextBox labeltext={t("Rating_Time_Weight_PartFactor_ReactionTime_Lowest")} name={"ratingTimeWeightPartFactorReactionTimeLowest"} placeholder={t("Rating_Time_Weight_PartFactor_ReactionTime_Lowest")} type="number" />
                                            </OmisCol>
                                            <OmisCol xs={12}>
                                                <OmisInputSlider changecolorbyval={1} key={`${"ratingTimeWeightPartFactorReactionTimeLowest"}_Slider`} onChange={setSliderValue.bind(this, "ratingTimeWeightPartFactorReactionTimeLowest")} value={formik.values.ratingTimeWeightPartFactorReactionTimeLowest ?? 0} />
                                            </OmisCol>
                                        </OmisCol>

                                        <OmisCol xs={6}>
                                            <OmisCol xs={12}>
                                                <OmisTextBox labeltext={t("Rating_Time_Weight_PartFactor_Reminder_Highest")} name={"ratingTimeWeightPartFactorReminderHighest"} placeholder={t("Rating_Time_Weight_PartFactor_Reminder_Highest")} type="number" />
                                            </OmisCol>
                                            <OmisCol xs={12}>
                                                <OmisInputSlider changecolorbyval={1} key={`${"ratingTimeWeightPartFactorReminderHighest"}_Slider`} onChange={setSliderValue.bind(this, "ratingTimeWeightPartFactorReminderHighest")} value={formik.values.ratingTimeWeightPartFactorReminderHighest ?? 0} />
                                            </OmisCol>
                                        </OmisCol>
                                    </OmisRow>
                                </Card>
                            </OmisRow>

                            <br />

                            <OmisRow>
                                <OmisCol xs={6}>
                                    <Card>
                                        <OmisRow>
                                            <OmisCol xs={6}>
                                                <OmisCol xs={12}>
                                                    <OmisTextBox onBlur={handleSumFieldChange} labeltext={<strong>{t("Rating_Weight_Costs")}</strong>} name={"ratingCostsWeight"} placeholder={t("Rating_Weight_Costs")} type="number" required />
                                                </OmisCol>
                                                <OmisCol xs={12}>
                                                    <OmisInputSlider changecolorbyval={1} key={`${"ratingCostsWeight"}_Slider`} onChange={setSliderValue.bind(this, "ratingCostsWeight")} onBlur={setSliderValue.bind(this, "ratingCostsWeight")} value={formik.values.ratingCostsWeight ?? 0} />
                                                </OmisCol>
                                            </OmisCol>
                                            <OmisCol xs={6}>
                                                <OmisLabel label={t("Rating_Weight_Costs_SumText")} />
                                                <br />
                                                <OmisInputSlider changecolorbyval={1} key={`${"ratingCostSum"}_Slider`} value={formik.values.ratingCostSum ?? 0} marks={marks} valueLabelDisplay="on" />
                                            </OmisCol>
                                        </OmisRow>
                                        <OmisRow>
                                            <OmisCol xs={12}>
                                                <OmisTextBox onBlur={handleSumFieldChange} labeltext={t("Rating_Costs_Weight_PartFactor_CostEstimation")} name={"ratingCostsWeightPartFactorCostEstimation"} placeholder={t("Rating_Costs_Weight_PartFactor_CostEstimation")} type="number" required />
                                            </OmisCol>
                                            <OmisCol xs={12}>
                                                <OmisInputSlider changecolorbyval={1} key={`${"ratingCostsWeightPartFactorCostEstimation"}_Slider`} onChange={setSliderValue.bind(this, "ratingCostsWeightPartFactorCostEstimation")} value={formik.values.ratingCostsWeightPartFactorCostEstimation ?? 0} />
                                            </OmisCol>

                                            <OmisCol xs={12}>
                                                <OmisTextBox labeltext={t("Rating_Costs_Weight_PartFactor_CostEstimation_Lowest")} name={"ratingCostsWeightPartFactorCostEstimationLowest"} placeholder={t("Rating_Costs_Weight_PartFactor_CostEstimation_Lowest")} type="number" />
                                            </OmisCol>
                                            <OmisCol xs={12}>
                                                <OmisInputSlider changecolorbyval={1} key={`${"ratingCostsWeightPartFactorCostEstimationLowest"}_Slider`} onChange={setSliderValue.bind(this, "ratingCostsWeightPartFactorCostEstimationLowest")} value={formik.values.ratingCostsWeightPartFactorCostEstimationLowest ?? 0} />
                                            </OmisCol>
                                        </OmisRow>
                                    </Card>
                                </OmisCol>

                                <OmisCol xs={6}>
                                    <Card>
                                        <OmisRow>
                                            <OmisCol xs={6}>
                                                <OmisCol xs={12}>
                                                    <OmisTextBox onBlur={handleSumFieldChange} labeltext={<strong>{t("Rating_Weight_Sustainability")}</strong>} name={"ratingSustainabilityWeight"} placeholder={t("Rating_Weight_Sustainability")} type="number" required />
                                                </OmisCol>
                                                <OmisCol xs={12}>
                                                    <OmisInputSlider changecolorbyval={1} key={`${"ratingSustainabilityWeight"}_Slider`} onChange={setSliderValue.bind(this, "ratingSustainabilityWeight")} onBlur={setSliderValue.bind(this, "ratingSustainabilityWeight")} value={formik.values.ratingSustainabilityWeight ?? 0} />
                                                </OmisCol>
                                            </OmisCol>
                                            <OmisCol xs={6}>
                                                <OmisLabel label={t("Rating_Weight_Sustainability_SumText")} />
                                                <br />
                                                <OmisInputSlider changecolorbyval={1} key={`${"ratingSustainabilitySum"}_Slider`} value={formik.values.ratingSustainabilitySum ?? 0} marks={marks} valueLabelDisplay="on" />
                                            </OmisCol>
                                        </OmisRow>
                                        <OmisRow>
                                            <OmisCol xs={12}>
                                                <OmisCol xs={12}>
                                                    <OmisTextBox onBlur={handleSumFieldChange} labeltext={t("Rating_Sustainability_Weight_PartFactor_Reopen")} name={"ratingSustainabilityWeightPartFactorReopen"} placeholder={t("Rating_Sustainability_Weight_PartFactor_Reopen")} type="number" required />
                                                </OmisCol>
                                                <OmisCol xs={12}>
                                                    <OmisInputSlider changecolorbyval={1} key={`${"ratingSustainabilityWeightPartFactorReopen"}_Slider`} onChange={setSliderValue.bind(this, "ratingSustainabilityWeightPartFactorReopen")} value={formik.values.ratingSustainabilityWeightPartFactorReopen ?? 0} />
                                                </OmisCol>
                                            </OmisCol>

                                            <OmisCol xs={12}>
                                                <OmisCol xs={12}>
                                                    <OmisTextBox labeltext={t("Rating_Sustainability_Weight_PartFactor_Reopen_Highest")} name={"ratingSustainabilityWeightPartFactorReopenHighest"} placeholder={t("Rating_Sustainability_Weight_PartFactor_Reopen_Highest")} type="number" />
                                                </OmisCol>
                                                <OmisCol xs={12} sx={{ paddingLeft: '1em', paddingRight: '1em' }}>
                                                    <OmisInputSlider changecolorbyval={1} key={`${"ratingSustainabilityWeightPartFactorReopenHighest"}_Slider`} onChange={setSliderValue.bind(this, "ratingSustainabilityWeightPartFactorReopenHighest")} value={formik.values.ratingSustainabilityWeightPartFactorReopenHighest ?? 0} />
                                                </OmisCol>
                                            </OmisCol>
                                        </OmisRow>
                                    </Card>
                                </OmisCol>
                            </OmisRow>

                            <br/>

                            <OmisRow>
                                <Card>
                                    <OmisRow>
                                        <OmisCol xs={6}>
                                            <OmisCol xs={6}>
                                                <OmisTextBox onBlur={handleSumFieldChange} labeltext={<strong>{t("Rating_Weight_SubjectiveRating")}</strong>} name={"ratingSubjectiveRatingWeight"} placeholder={t("Rating_Weight_SubjectiveRating")} type="number" required />
                                            </OmisCol>
                                            <OmisCol xs={6}>
                                                <OmisInputSlider changecolorbyval={1} key={`${"ratingSubjectiveRatingWeight"}_Slider`} onChange={setSliderValue.bind(this, "ratingSubjectiveRatingWeight")} value={formik.values.ratingSubjectiveRatingWeight ?? 0} />
                                            </OmisCol>
                                        </OmisCol>
                                        <OmisCol xs={6}>
                                            <OmisLabel label={t("Rating_Weight_SubjectiveRating_SumText")} />
                                            <br />
                                            <OmisInputSlider changecolorbyval={1} key={`${"ratingSubjectiveSum"}_Slider`} value={formik.values.ratingSubjectiveSum ?? 0} marks={marks} valueLabelDisplay="on" />
                                        </OmisCol>
                                    </OmisRow>
                                    <OmisRow>
                                        <OmisCol xs={6}>
                                            <OmisCol xs={12}>
                                                <OmisTextBox onBlur={handleSumFieldChange} labeltext={t("Rating_SubjectiveRating_Weight_PartFactor_Friendliness")} name={"ratingSubjectiveRatingWeightPartFactorFriendliness"} placeholder={t("Rating_SubjectiveRating_Weight_PartFactor_Friendliness")} type="number" required />
                                            </OmisCol>
                                            <OmisCol xs={12}>
                                                <OmisInputSlider changecolorbyval={1} key={`${"ratingSubjectiveRatingWeightPartFactorFriendliness"}_Slider`} onChange={setSliderValue.bind(this, "ratingSubjectiveRatingWeightPartFactorFriendliness")} value={formik.values.ratingSubjectiveRatingWeightPartFactorFriendliness ?? 0} />
                                            </OmisCol>
                                        </OmisCol>
                                        <OmisCol xs={6}>
                                            <OmisTextArea labeltext={t("Description")} name={"ratingSubjectiveRatingWeightPartFactorFriendlinessDescription"} placeholder={t("Description")} />
                                        </OmisCol>

                                        <OmisCol xs={6}>
                                            <OmisCol xs={12}>
                                                <OmisTextBox onBlur={handleSumFieldChange} labeltext={t("Rating_SubjectiveRating_Weight_PartFactor_Quality")} name={"ratingSubjectiveRatingWeightPartFactorQuality"} placeholder={t("Rating_SubjectiveRating_Weight_PartFactor_Quality")} type="number" required />
                                            </OmisCol>
                                            <OmisCol xs={12}>
                                                <OmisInputSlider changecolorbyval={1} key={`${"ratingSubjectiveRatingWeightPartFactorQuality"}_Slider`} onChange={setSliderValue.bind(this, "ratingSubjectiveRatingWeightPartFactorQuality")} value={formik.values.ratingSubjectiveRatingWeightPartFactorQuality ?? 0} />
                                            </OmisCol>
                                        </OmisCol>
                                        <OmisCol xs={6}>
                                            <OmisTextArea labeltext={t("Description")} name={"ratingSubjectiveRatingWeightPartFactorQualityDescription"} placeholder={t("Description")} />
                                        </OmisCol>

                                        <OmisCol xs={6}>
                                            <OmisCol xs={12}>
                                                <OmisTextBox onBlur={handleSumFieldChange} labeltext={t("Rating_SubjectiveRating_Weight_PartFactor_Reliability")} name={"ratingSubjectiveRatingWeightPartFactorReliability"} placeholder={t("Rating_SubjectiveRating_Weight_PartFactor_Reliability")} type="number" required />
                                            </OmisCol>
                                            <OmisCol xs={12}>
                                                <OmisInputSlider changecolorbyval={1} key={`${"ratingSubjectiveRatingWeightPartFactorReliability"}_Slider`} onChange={setSliderValue.bind(this, "ratingSubjectiveRatingWeightPartFactorReliability")} value={formik.values.ratingSubjectiveRatingWeightPartFactorReliability ?? 0} />
                                            </OmisCol>
                                        </OmisCol>
                                        <OmisCol xs={6}>
                                            <OmisTextArea labeltext={t("Description")} name={"ratingSubjectiveRatingWeightPartFactorReliabilityDescription"} placeholder={t("Description")} />
                                        </OmisCol>

                                        <OmisCol xs={6}>
                                            <OmisCol xs={12}>
                                                <OmisTextBox onBlur={handleSumFieldChange} labeltext={t("Rating_SubjectiveRating_Weight_PartFactor_Communication")} name={"ratingSubjectiveRatingWeightPartFactorCommunication"} placeholder={t("Rating_SubjectiveRating_Weight_PartFactor_Communication")} type="number" required />
                                            </OmisCol>
                                            <OmisCol xs={12}>
                                                <OmisInputSlider changecolorbyval={1} key={`${"ratingSubjectiveRatingWeightPartFactorCommunication"}_Slider`} onChange={setSliderValue.bind(this, "ratingSubjectiveRatingWeightPartFactorCommunication")} value={formik.values.ratingSubjectiveRatingWeightPartFactorCommunication ?? 0} />
                                            </OmisCol>
                                        </OmisCol>
                                        <OmisCol xs={6}>
                                            <OmisTextArea labeltext={t("Description")} name={"ratingSubjectiveRatingWeightPartFactorCommunicationDescription"} placeholder={t("Description")} />
                                        </OmisCol>

                                        <OmisCol xs={6}>
                                            <OmisCol xs={12}>
                                                <OmisTextBox onBlur={handleSumFieldChange} labeltext={t("Rating_SubjectiveRating_Weight_PartFactor_Consultation")} name={"ratingSubjectiveRatingWeightPartFactorConsultation"} placeholder={t("Rating_SubjectiveRating_Weight_PartFactor_Consultation")} type="number" required />
                                            </OmisCol>
                                            <OmisCol>
                                                <OmisInputSlider changecolorbyval={1} key={`${"ratingSubjectiveRatingWeightPartFactorConsultation"}_Slider`} onChange={setSliderValue.bind(this, "ratingSubjectiveRatingWeightPartFactorConsultation")} value={formik.values.ratingSubjectiveRatingWeightPartFactorConsultation ?? 0}  />
                                            </OmisCol>
                                        </OmisCol>
                                        <OmisCol xs={6}>
                                            <OmisTextArea labeltext={t("Description")} name={"ratingSubjectiveRatingWeightPartFactorConsultationDescription"} placeholder={t("Description")} />
                                        </OmisCol>
                                    </OmisRow>
                                </Card>
                                <br />
                            </OmisRow>

                            <OmisRow >
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}