import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { OmisButtonSecondary, OmisIconButton } from "../../shared/OmisButtons";
import { Card } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { OmisCol, OmisContainer, OmisRow, OmisTabs } from "../../shared/OmisLayouts";
import { OmisDialog, OmisLabel } from "../../shared/OmisDisplays";
import Equipment from "../../equipment/Equipment";
import { SettingsEquiTypes } from "./SettingsEquiTypes";
import { SettingsEquiAttributeDefs } from "./SettingsEquiAttributeDefs";
import { SettingsEquiTestDates } from "./SettingsEquiTestDates";
import { SettingsFaultEquiClassDefs } from "./SettingsFaultEquiClassDefs";
import { CreateEquiClass } from "./CreateEquiClass";
import HttpClientService from "../../../services/httpclient.service";

export function SettingsEquiClassDetails(props) { 

    var { state } = useLocation();
    const navigate = useNavigate();

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [selectedTabValue, setSelectedTabValue] = useState(0);
    const [details, setDetails] = useState(null);

    const tabs = [
        {
            title: t("Equipments"),
            content: <OmisRow><Equipment equiclassdescription={state?.description} equiclassid={state?.id} selectedtabvalue={selectedTabValue} noExportButton noHeaderText /></OmisRow>,
        },
        {
            title: t("EquiTypes"),
            content: <SettingsEquiTypes equiclassid={state?.id} selectedtabvalue={selectedTabValue} />
        },
        {
            title: t("Settings_AttributeDef"),
            content: <SettingsEquiAttributeDefs equiclassid={state?.id} selectedtabvalue={selectedTabValue} />
        },
        {
            title: t("Settings_TestDates"),
            content: <SettingsEquiTestDates equiclassid={state?.id} selectedtabvalue={selectedTabValue} />
        },
        {
            title: t("FaultEquiClassDefs"),
            content: <SettingsFaultEquiClassDefs equiclassid={state?.id} />
        },
    ];

    useEffect(() => {
        if (state && state.selectedtabvalue) {
            if (/[0-9]/.test(state.selectedtabvalue)) {
                setSelectedTabValue(parseInt(state.selectedtabvalue));
            } else {
                setSelectedTabValue(0);
            }
        }

        if (state?.id) {
            HttpClientService.get(`/api/settings/settingsequipments/getequiclassdetails?equiclassid=${state?.id}`).then((response) => {
                if (response) {
                    setDetails(response);
                }
            });
        }

    }, []);

    function tabChanged(e) {
        setSelectedTabValue(e);
    }

    function handleEditClick(rowID, e) {
        if (e) {
            setModalTitle(t("Action_Edit"));
        } else {
            setModalTitle(null);
            if (typeof rowID !== 'number') {
                rowID = null
            }
        }

        var rowVals = { ...e };
        if (props.faultEquiClassDefID && props.faultEquiClassDefID > 0) {
            rowVals.faultEquiClassDefID = props.faultEquiClassDefID;
        }

        setModalContent(<CreateEquiClass onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID} rowVals={rowVals} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = (newEquiClassVals) => {
        setModalOpen(false);
        var newState = { ...state };
        if (newEquiClassVals) {
            newState.description = newEquiClassVals.description;
            newState.equiClass = newEquiClassVals.equiClass;
        }

        navigate(0, { replace: true, state: { ...newState } });
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };


    return (
        <>
            <div className="omis-header-box">
                <OmisCol xs={1}>
                    <OmisIconButton id={"backButton"}
                        iconcontent={<ArrowBackIcon fontSize="inherit" />}
                        text="Back" onClick={() => { navigate(-1); }} />
                </OmisCol>
            </div>
            <Card>
                <OmisContainer fluid>
                    <OmisRow>
                        <OmisCol xs={6}>
                            <OmisCol xs={6}>
                                <strong>{t("Description")}:</strong>
                                <OmisLabel label={details?.description} />
                            </OmisCol>
                            <OmisCol xs={6}>
                                <strong>{t("EquiClass")}:</strong>
                                <OmisLabel label={details?.equiClass} />
                            </OmisCol>
                        </OmisCol>
                        <OmisCol xs={{ span: 2, offset: 4 }}>
                            <OmisButtonSecondary id={"editButton"} onClick={handleEditClick.bind(this, state?.id, state)} text={t("Action_Edit")} />
                        </OmisCol>
                    </OmisRow>
                </OmisContainer>

                <OmisRow>
                    <OmisTabs tabs={tabs} onChange={tabChanged} tabvalue={selectedTabValue} />
                </OmisRow>
            </Card>

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"md"}
                title={modalTitle}
                content={modalContent}
                contentHeight={300}
            />
        </>
    );
}