import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {OmisGrid} from "../../shared/OmisGrids";
import { OmisIconButton, OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import {OmisDialog} from "../../shared/OmisDisplays";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {OmisTextArea, OmisTextBox} from "../../shared/OmisInputs";
import DialogActions from "@mui/material/DialogActions";
import httpclientService from "../../../services/httpclient.service";
import { Link as RouterLink } from "react-router-dom";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisCol, OmisRow } from '../../shared/OmisLayouts';

var state = { selectedtabvalue: TabConstants.System }
export default function SysParameter(props) {
    const { t } = useTranslation();

    const [changeCounter, setChangeCounter] = useState(0);

    const [editModalOpen, setEditModalOpen] = useState(false);

    const [selectedRowID, setSelectedRowID] = useState(0);

    const handleModalClose = () => {
        setEditModalOpen(false);
        setSelectedRowID(0);
    };

    function handleEditClick(item) {
        setSelectedRowID(item);
        setEditModalOpen(true);
    }

    return (
        <div className="omis-header-box">
            <OmisCol xs={1}>
                <OmisIconButton id={"backButton"}
                    iconcontent={<ArrowBackIcon fontSize="inherit" />}
                text="Back" component={RouterLink} to={"/settings"} state={state ?? { selectedtabvalue: TabConstants.System }} />
            </OmisCol>


            <OmisGrid actiondropdown={{ edit: handleEditClick }}
                      change={changeCounter}
                      headerText={t("SysParameter")}
                      apiUrl={"/api/settings/sysparameter/index"}
            />
            <OmisDialog onClose={handleModalClose} open={editModalOpen}
                        title={t("Action_Edit")}
                        content={<Popup_Content />}
            />
        </div>
    );

    function Popup_Content(props) {
        const [initialValues, setInitialValues] = useState({
            id: 0,
            paramname: '',
            paramvalue: ''
        });

        const editItem = Boolean(selectedRowID > 0);
        useEffect(() => {
            if (editItem) {
                httpclientService.get(`/api/settings/sysparameter/get?id=${selectedRowID}`).then((response) => {
                    if (response.teamid === null) {
                        response.teamid = '';
                    }
                    setInitialValues(response);
                })
            }
        }, [])

        function handleSave(values) {
            httpclientService.post("/api/settings/sysparameter/save", JSON.stringify(values)).then((response) => {
                switch (response.statusCode) {
                    case 200:
                        handleModalClose();
                        setChangeCounter((prevState) => prevState + 1);
                        break;
                    default:
                        break;
                }
            })
        }

        return(
            <>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        paramname: Yup.string()
                            .required(t("GenericRequiredText"))
                            .max(255, t('Error_TextTooLong', { 1: 255 })),
                        paramvalue: Yup.string()
                            .required(t("GenericRequiredText"))
                            .max(1000, t('Error_TextTooLong', { 1: 1000 })),
                    })}
                    onSubmit={(values) => { handleSave(values) }}
                >
                    <Form>
                        <OmisRow>
                            <OmisCol xs="12">
                                <OmisTextBox disabled={true} labeltext={"Name"} name="paramname" />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol xs="12">
                                <OmisTextArea labeltext={t("Value")} name="paramvalue" />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <DialogActions>
                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleModalClose}></OmisButtonSecondary>
                                <OmisButtonPrimarySubmit id={"submitButton"} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                            </DialogActions>
                        </OmisRow>
                    </Form>
                </Formik>
            </>
        );
    }
}