import axios from "axios";
import HttpClientService from "./httpclient.service";
import {NavBarRoles, TRole} from "../constants/RoleConstants";
import AppRoutes from "../AppRoutes";
import { NavBarMenus } from "../constants/PageNameConstants";

const API_URL = `${process.env.PUBLIC_URL}/api/profile/`;

const login = (username, password, customerNr , lang) => {
    const headers = {
        'Content-Type': 'application/json'
    };

    return axios
        .post(API_URL + "login", {
            username,
            password,
            customerNr,
            lang
        }, {headers})
        .then((response) => {
            if (response.data.username) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response;
        }).catch((err) => {
            return err.response;
        });
};

const logout = () => {
    localStorage.removeItem("user");
    return HttpClientService.post("/api/profile/logout");
};

const resetpassword = (username) => {
    const headers = {
        'Content-Type': 'application/json'
    };

    return axios.post(API_URL + "resetpassword", {
        username: username
    }, { headers })
        .then((response) => {
            return response;
        }).catch((err) => {
            return err.response;
        });
};

const changepassword = (username,oldPassword, password) => {
    const headers = {
        'Content-Type': 'application/json'
    };

    return axios
        .post(API_URL + "changepassword", {
            username,
            oldPassword,
            password
        }, { headers })
        .then((response) => {           
            return response;
        }).catch((err) => {
            return err.response;
        });
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const updateCurrentUser = () => {
    var currentUser = getCurrentUser();
    HttpClientService.post(`/api/profile/logintoanotheruser?userID=${currentUser.id}`).then((response) => {
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(response));
    }).catch((err) => {
        return err.response;
    });
};

const getSessionUser = () => {
    //User check if there is logged-in user.
    var _urlAddress = window.location.href ?? "";
    var isDemo = _urlAddress.includes("demo.omis");

    if (!(_urlAddress.includes("robots.txt"))) {
        if (!isDemo && !(_urlAddress.includes("login"))) {
            axios.get(`${process.env.PUBLIC_URL}/api/profile/getsessionuser`).then((response) => {
                if (getCurrentUser() !== null && (response.data.message.includes("User not found"))) {
                    logout().then(() => {
                        window.location = `${process.env.PUBLIC_URL}/${!AuthService.isAuthenticated() && isDemo ? "demologin" : "login"}`;
                    });
                    return false;
                }
                return true;
            }).catch((err) => {
                try {
                    if (getCurrentUser() !== null && (err.response.data.message.includes("User not found"))) {
                        logout().then(() => {
                            window.location = `${process.env.PUBLIC_URL}/${!AuthService.isAuthenticated() && isDemo ? "demologin" : "login"}`;
                        });
                    }
                } catch (e) {
                }
                return false;
            });
        }
    }
};

const getUrlCheck = () => {
    var baseUri = document.getElementsByTagName('base')[0]?.baseURI ?? "";
    var url = window.location.href ?? "";

    var isDemo = baseUri.includes("demo.omis") || url.includes("demo.omis");
    //console.log("base:" + baseUri);
    //console.log("url:" + url);
    //console.log("path:" + url.replace(baseUri, baseUri.endsWith("/") ? "/" : ""));
    var searchPath = url.replace(baseUri, baseUri.endsWith("/") ? "/" : "");
    if (searchPath.includes("?")) {
        searchPath = searchPath.substring(0, searchPath.indexOf("?"));
    }else if (searchPath.includes("#")) {
        searchPath = searchPath.substring(0, searchPath.indexOf("#"));
    }

    var isExist = AppRoutes.filter((route) => route.path && `${route.path}${searchPath.endsWith("/") && !route.path.endsWith("/") ? "/":"" }` === searchPath).map((item) => item.path);
    if (isExist.length === 0) {
        if (!searchPath.includes("robots.txt")) {
            var startPage = getUserStartPage();
            startPage = startPage && startPage.startsWith("/") ? startPage.substring(1, startPage.length) : startPage;
            window.location.href = `${baseUri}${!baseUri.endsWith("/") ? "/" : ""}${!AuthService.isAuthenticated() ? isDemo ? "demologin" : "login" : startPage !== '' ? startPage : "reports"} `;
        }
    }
}

const getUserStartPage = () => {
    var user = getCurrentUser();
    var navbarMenus = NavBarMenus();
    var navbarRoles = NavBarRoles();
    var startPage = '';
    if (user) {
        // check if startPage contains in navbarMenus
        var roleResult = navbarRoles.filter(x => user.roles.indexOf(x.role) > -1);
        if (user?.isFirstLogin) {
            return "/firstloginchangepassword";
        }

        if (user.roles?.length === 0 || roleResult?.length === 0) {
            if (!AuthService.isFSMMaster()) {
            result = "/noroleassigned";
            }
        } else {
            if (user?.startPage) {
                if (!user?.startPage.startsWith('/')) {
                    startPage = user?.startPage.split('/')[0];
                } else {
                    startPage = user?.startPage.split('/')[1];
                }
            }
            var result = navbarMenus.filter((menu) => menu.includes(startPage)).length > 0 && user?.startPage ? user?.startPage : user.roles.some(x => x === TRole.FaultReportRead_40) ? '/reports' : `/${roleResult[0].pageurl}`;
        }
        return result;
    }
}

const getAccessToken = () => {
    if (isAuthenticated()) {
        return getCurrentUser().token;
    }
    return null;
}

const withRole = (roles) => (Component) => (props) => {
    if (isAuthenticated()) {
        if (getCurrentUser().roles.some(r => roles.indexOf(r) >= 0)) {
            return <Component {...props} />;
        }
    }
    return null;
}

const isAuthenticated = () => {
    return getCurrentUser() !== null;
}

const hasRole = (role) => {
    if (isAuthenticated()) {
        if (getCurrentUser().roles.some(r => r.toLowerCase() === role.toLowerCase())) {
            return true;
        }
    }
    return false;
}

const isFSMMaster = () => {
    if (isAuthenticated()) {
        if (getCurrentUser().roles.some(r => r === TRole.FSMWorkflowUser)) {
            return true;
        }
    }
    return false;
}

const userName = () => {
    if (isAuthenticated()) {
        return getCurrentUser().username;
    }
}

const customerNr = () => {
    if (isAuthenticated()) {
        return getCurrentUser().customerNr;
    }

    return "";
}

const AuthService = {
    login,
    logout,
    resetpassword,
    changepassword,
    getCurrentUser,
    updateCurrentUser,
    getAccessToken,
    getSessionUser,
    getUrlCheck,
    getUserStartPage,
    isAuthenticated,
    hasRole,
    withRole,
    isFSMMaster,
    userName,
    customerNr
}
export default AuthService;