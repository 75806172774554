import Icon from '@mdi/react';
import { mdiInboxFull, mdiHammerWrench, mdiHome, mdiCheckboxMarkedOutline, mdiAccountGroup } from '@mdi/js';
import './Components.css';
import {Chip} from "@mui/material";
import {OmisAvatar} from "../profile/OmisAvatar";
import React, { useState} from "react";
import { Link as RouterLink } from 'react-router-dom';
import authService from "../../services/auth.service";
import urlEncryptService from "../../services/urlencrypt.service";
import {TRole} from "../../constants/RoleConstants";
import {UserDetailsModal} from "../settings/User/User";
import {OmisDialog} from "./OmisDisplays";
import {OmisButtonPrimary, OmisButtonSecondary} from "./OmisButtons";
import {t} from "i18next";
import { ObjectDetailsModal } from "../objects/ObjectDetailsModal";
import { ReportsDetailsModal } from "../reports/ReportsDetailsModal";
import { EquipmentDetailsModal } from "../equipment/EquipmentDetailsModal";
import { InspectionDetailsModal } from "../inspections/InspectionDetailsModal";
import { GroupDetails } from '../users/GroupDetails';
import { OmisCol, OmisRow } from './OmisLayouts';


export function OmisReportChip(props) {
    const [chipModalOpen, setChipModalOpen] = useState(false);
    const pathName = "/reports/details/";

    const handleChipClickOpen = () => {
        setChipModalOpen(true);
    };

    const handleChipClose = () => {
        setChipModalOpen(false);
    };

    var encrptedProps = urlEncryptService.encrypt(props);

    if (props.fromReportIndex) {
        return (
            props.disableChipClick ?
                <Chip avatar={<Icon path={mdiInboxFull} />}                    
                    className={"reportChip"} label={props.text} sx={{ width: "auto;", marginLeft: "2%;", marginRight: "2%;" }} />
                :
                <Chip avatar={<Icon path={mdiInboxFull} />} clickable={true}
                    component={RouterLink} to={{ pathname: pathName, search: `${encrptedProps}` }} state={props}
                    className={"reportChip"} label={props.text} sx={{ width: "auto;", marginLeft: "2%;", marginRight: "2%;" }} />
        );
    }

    return (
        <>
            {
                props.disableChipClick ?
                    <Chip avatar={<Icon className="reportChipsvg" path={mdiInboxFull} />} className={"reportChip"} label={props.text} />
                    :
                    <Chip clickable={props.disableChipClick ? false : true} onClick={handleChipClickOpen} avatar={<Icon className="reportChipsvg" path={mdiInboxFull} />} className={"reportChip"} label={props.text} />
            }            
            <OmisDialog onClose={handleChipClose} open={chipModalOpen}
                        title={t("Chosen_ReportDetails")}
                        content={<ReportsDetailsModal hideStatusDropdown={ true} {...props}/>}
                        maxWidth={"lg"}
                        actions=
                        {
                            <>
                                <OmisButtonPrimary id={"detailsButton"} component={RouterLink} to={pathName} state={props} text={t("Details")}></OmisButtonPrimary>
                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Close")} onClick={handleChipClose}></OmisButtonSecondary>
                            </>
                        }
            />
        </>
    );
}

export function OmisClickableEmailChip(props) {
    const [chipModalOpen, setChipModalOpen] = useState(false);
    const pathName = "/reports/details/";

    const handleChipClickOpen = () => {
        setChipModalOpen(true);
    };

    const handleChipClose = () => {
        setChipModalOpen(false);
    };

    var encrptedProps = urlEncryptService.encrypt(props);

    if (props.fromReportIndex) {
        return (
            props.disableChipClick ?
                <Chip avatar={<Icon path={mdiInboxFull} />} className={"reportChip"} label={props.text} sx={{ width: "auto;", marginLeft: "2%;", marginRight: "2%;" }} />
                :
                <Chip avatar={<Icon path={mdiInboxFull} />} clickable={ true} component={RouterLink} to={{ pathname: pathName, search: `${encrptedProps}` }} state={props} className={"reportChip"} label={props.text} sx={{ width: "auto;", marginLeft: "2%;", marginRight: "2%;" }} />
        );
    }

    return (
        <>
            {
                props.disableChipClick ?
                    <Chip avatar={<Icon path={mdiInboxFull} />} className={"reportChip"} label={props.text} sx={{ width: "auto;", marginLeft: "2%;", marginRight: "2%;" }} />
                    :
                    <Chip clickable={props.disableChipClick ? false : true} onClick={handleChipClickOpen} avatar={<Icon className="reportChipsvg" path={mdiInboxFull} />} className={"reportChip"} label={props.text} />
            }
            <OmisDialog onClose={handleChipClose} open={chipModalOpen}
                title={t("Chosen_ReportDetails")}
                content={<ReportsDetailsModal hideStatusDropdown={true} {...props} />}
                maxWidth={"lg"}
                actions=
                {
                    <>
                        <OmisButtonPrimary id={"detailsButton"} component={RouterLink} to={"/reports/details/"} state={props} text={t("Details")}></OmisButtonPrimary>
                        <OmisButtonSecondary id={"cancelButton"} text={t("Action_Close")} onClick={handleChipClose}></OmisButtonSecondary>
                    </>
                }
            />
        </>
    );
}

export function OmisEquipmentChip(props) {
    //TODO: refactor, props should just contain id and get displaytext from cache or api call
    const [chipModalOpen, setChipModalOpen] = useState(false);
    const pathName = "/equipment/details/";

    const handleChipClickOpen = () => {
        setChipModalOpen(true);
    };

    const handleChipClose = () => {
        setChipModalOpen(false);
    };

    var encrptedProps = urlEncryptService.encrypt(props);

    if (props.fromEquipmentIndex) {
        return (
            props.disableChipClick ?
                <Chip avatar={<Icon path={mdiHammerWrench} viewBox="0 0 25 25" />} className={"equipmentChip"} label={<p>{props.text}</p>} sx={{ width: "auto;", marginLeft: "2%;", marginRight: "2%;" }} />
                :
                <Chip clickable={true} component={RouterLink} to={{ pathname: pathName, search: `${encrptedProps}` }} avatar={<Icon path={mdiHammerWrench} viewBox="0 0 25 25" />} state={props} className={"equipmentChip"} label={<p>{props.text}</p>} sx={{ width: "auto;", marginLeft: "2%;", marginRight: "2%;" }} />
        );
    }

    return (
        <>
            {
                props.disableChipClick ?
                    <Chip avatar={<Icon path={mdiHammerWrench} />} className={"equipmentChip"} label={props.text} />
                    :
                    <Chip clickable={true} onClick={handleChipClickOpen} avatar={<Icon path={mdiHammerWrench} />} className={"equipmentChip"} label={props.text} />
            }
            <OmisDialog onClose={handleChipClose} open={chipModalOpen}
                        title={`${t("Header_Details_Equi")} #${props.equipid}`}
                        content={<EquipmentDetailsModal {...props}/>}
                        maxWidth={"md"}
                        actions=
                            {
                                <>
                                    <OmisButtonPrimary id={"detailsButton"} component={RouterLink} to={pathName} state={props} text={t("Details")}></OmisButtonPrimary>
                                    <OmisButtonSecondary id={"cancelButton"}  text={t("Action_Close")} onClick={handleChipClose}></OmisButtonSecondary>
                                </>
                            }
            />
        </>
    );
}

export function OmisObjectChip(props) {
    //TODO: refactor, props should just contain id and get displaytext from cache or api call
    const [chipModalOpen, setChipModalOpen] = useState(false);
    const pathName = "/object/details/";

    const handleChipClickOpen = () => {
        setChipModalOpen(true);
    };

    const handleChipClose = () => {
        setChipModalOpen(false);
    };

    var encrptedProps = urlEncryptService.encrypt(props);

    if (props.fromObjectIndex) {
        return (
            props.disableChipClick ?
            <Chip className={"objectChip"} label={props.text} />
            :
            <Chip clickable={true} component={RouterLink} to={{ pathname: props.detailslink ?? pathName, search: `${encrptedProps}` }} state={props} className={"objectChip"} label={props.text} />
        );
    }

    return (
        <>
            {
                props.disableChipClick ?
                    <Chip className={"objectChip"} avatar={<Icon className="objectChipsvg" path={mdiHome} ></Icon>} label={props.text} />
                    :
                    <Chip clickable={true} onClick={handleChipClickOpen} className={"objectChip"} avatar={<Icon className="objectChipsvg" path={mdiHome} ></Icon>} label={props.text} />
            }
            <OmisDialog onClose={handleChipClose} open={chipModalOpen}
                        title={`${t("Object_Details_Title")} #${props.objectid}`}
                        content={<ObjectDetailsModal {...props}/>}
                        maxWidth={"lg"}
                        actions=
                        {
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimary id={"detailsButton"} component={RouterLink} to={props.detailslink ?? pathName} state={props} text={t("Details")}></OmisButtonPrimary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Close")} onClick={handleChipClose}></OmisButtonSecondary>
                                </OmisCol>
                            </OmisRow>
                        }
            />
        </>
    );
}

export function OmisUserChip(props) {
    //TODO: refactor, props should just contain id and get displaytext from cache or api call
    const [omisUserChipModalOpen, setOmisUserChipModalOpen] = React.useState(false);

    let clickable = true;
    if (props.userID === 0 || props.disableChipClick) {
        clickable = false;
    }

    const handleOmisUserChipClickOpen = () => {
        if (clickable) {
            setOmisUserChipModalOpen(true);
        }
    };

    const handleOmisUserChipClose = () => {
        setOmisUserChipModalOpen(false);
    };

    if (authService.hasRole(TRole.Settings_User) || authService.hasRole(TRole.FSMSettings)) {
        return (
            <>
                <Chip clickable={clickable} onClick={handleOmisUserChipClickOpen} className={"userChip"} avatar={<OmisAvatar name={props.userFullName}/>} label={props.userFullName} />
                <OmisDialog onClose={handleOmisUserChipClose} open={omisUserChipModalOpen}
                           title={t("User")}
                           maxWidth={"md"}
                           content={<UserDetailsModal userName={props.userName ?? props.userFullName} {...props}/>}
                           actions=
                               {
                               <>{/*
                                    <OmisButtonPrimary component={RouterLink} to={"/settings/user/details/"} state={props} text={t("Details")}></OmisButtonPrimary>
                                    <OmisButtonSecondary text={t("Action_Close")} onClick={handleOmisUserChipClose}></OmisButtonSecondary>*/ }
                                </>
                            }
                />
            </>
        );
    } else {
        return (
            <Chip className={"userChip"} avatar={<OmisAvatar name={props.userFullName}/>} label={props.userFullName} />
        );
    }
}

export function OmisProjectChip(props) {
    const [omisProjectChipModalOpen, setOmisProjectChipModalOpen] = React.useState(false);

    const handleOmisProjectChipClickOpen = () => {
        setOmisProjectChipModalOpen(true);
    };

    const handleOmisProjectChipClose = () => {
        setOmisProjectChipModalOpen(false);
    };

    if (authService.hasRole(TRole.ProjectEdit_40) || authService.hasRole(TRole.FSMSettings)) {
        return (
            <>
                <Chip clickable={props.disableChipClick ? false : true} onClick={props.disableChipClick ? null : handleOmisProjectChipClickOpen} className={"projectChip"} label={props.projectName} />
                <OmisDialog onClose={handleOmisProjectChipClose} open={omisProjectChipModalOpen}
                    title={t("Project") + " " + t("Details")}
                    //content={<ProjectDetailsModal {...props} />}
                    actions=
                    {
                        <>
                            <OmisButtonPrimary id={"detailsButton"} component={RouterLink} to={"/settings/project/details/"} state={props} text={t("Details")}></OmisButtonPrimary>
                            <OmisButtonSecondary id={"cancelButton"} text={t("Action_Close")} onClick={handleOmisProjectChipClose}></OmisButtonSecondary>
                        </>
                    }
                />
            </>
        );
    } else {
        return (
            <Chip className={"userChip"} avatar={<OmisAvatar name={props.projectName} />} label={props.userFullName} />
        );
    }
}

export function OmisInspectionChip(props) {
    const [chipModalOpen, setChipModalOpen] = useState(false);
    const pathName = "/inspections/details/";

    const handleChipClickOpen = () => {
        setChipModalOpen(true);
    };

    const handleChipClose = () => {
        setChipModalOpen(false);
    };

    var encrptedProps = urlEncryptService.encrypt(props);

    if (props.fromInspectionIndex) {
        return (
            props.disableChipClick ?
            <Chip className={"inspectionChip"} avatar={<Icon path={mdiCheckboxMarkedOutline} ></Icon>} label={props.text} />
            :
            <Chip clickable={true} component={RouterLink} to={{ pathname: pathName, search: `${encrptedProps}` }} state={props} className={"inspectionChip"} avatar={<Icon path={mdiCheckboxMarkedOutline} ></Icon>} label={props.text} />
        );
    }

    return (
        <>
            {
                props.disableChipClick ?
                    <Chip className={"inspectionChip"} avatar={<Icon path={mdiCheckboxMarkedOutline} ></Icon>} label={props.text} />
                    :
                    <Chip clickable={true} onClick={handleChipClickOpen} className={"inspectionChip"} avatar={<Icon path={mdiCheckboxMarkedOutline} ></Icon>} label={props.text} />
            }
            <OmisDialog onClose={handleChipClose} open={chipModalOpen}
                title={t("Inspection_Details_Title")}
                content={<InspectionDetailsModal {...props} />}
                actions=
                {
                    <>
                        <OmisButtonPrimary id={"detailsButton"} component={RouterLink} to={pathName} state={props} text={t("Details")}></OmisButtonPrimary>
                        <OmisButtonSecondary id={"cancelButton"} text={t("Action_Close")} onClick={handleChipClose}></OmisButtonSecondary>
                    </>
                }
            />
        </>
    );
}

export function OmisGroupChip(props) {
    const [chipModalOpen, setChipModalOpen] = useState(false);
    const pathName = "/users/groupdetails";

    const handleChipClickOpen = () => {
        setChipModalOpen(true);
    };

    const handleChipClose = () => {
        setChipModalOpen(false);
    };

    var encrptedProps = urlEncryptService.encrypt(props);

    if (props.fromUserIndex) {
        return (
            props.disableChipClick ?
                <Chip className={"groupChip"} avatar={<Icon path={mdiAccountGroup} ></Icon>} label={props.text} />
                :
                <Chip clickable={true} component={RouterLink} to={{ pathname: pathName, hash: `${encrptedProps}` }} state={props} className={"groupChip"} avatar={<Icon path={mdiAccountGroup} ></Icon>} label={props.text} />
        );
    }

    return (
        <>
            {
                props.disableChipClick ?
                    <Chip className={"groupChip"} avatar={<Icon path={mdiAccountGroup} ></Icon>} label={props.text} />
                    :
                    <Chip clickable={true} onClick={handleChipClickOpen} className={"groupChip"} avatar={<Icon path={mdiAccountGroup} ></Icon>} label={props.text} />
            }
            <OmisDialog onClose={handleChipClose} open={chipModalOpen}
                title={t("Details")}
                content={<GroupDetails state={props} {...props} />}
                actions=
                {
                    <>
                        <OmisButtonPrimary id={"detailsButton"} component={RouterLink} to={pathName} state={props} text={t("Details")}></OmisButtonPrimary>
                        <OmisButtonSecondary id={"cancelButton"} text={t("Action_Close")} onClick={handleChipClose}></OmisButtonSecondary>
                    </>
                }
            />
        </>
    );
}

export function OmisDetailsChip(props) {
    const [chipModalOpen, setChipModalOpen] = useState(false);
    const pathName = props.detailsroute;

    const handleChipClickOpen = () => {
        setChipModalOpen(true);
    };

    const handleChipClose = () => {
        setChipModalOpen(false);
    };

    var encrptedProps = urlEncryptService.encrypt(props);

    return (
        props.disableChipClick ?
            <Chip className={"detailsChip"} label={props.text} state={props} />
            :
            <Chip clickable={props.disableChipClick ? false : true} component={RouterLink} to={{ pathname: pathName, hash: `${encrptedProps}` }} className={"detailsChip"} label={props.text} state={props} />
    );
}


export function OmisTestChip(props) {

    return (
        <>
            <Chip clickable={props.disableChipClick ? false : true} onClick={props.onClick} className={"groupChip"} label={props.text} />
        </>
    );
}