import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisTextBox } from "../../shared/OmisInputs";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateSeverity(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const [visiblites, setVisiblites] = useState([]);
    const [initialValues, setInitialValues] = useState({
        id: props.rowVals?.id ?? null,
        description: props.rowVals?.severityDesc ?? "",
        visibilityID: props.rowVals?.visibilityID ?? null
    });

    useEffect(() => {
        httpclientService.get("/api/settings/settingsreports/getvisiblities").then((response) => {
            if (response && response.length>0) {
                setVisiblites(response);
            }
        })
    },[]);

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsreports/saveseverity", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(-1);
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(-1);
        }
    }

    const [schema, setSchema] = useState({
        description: Yup.string()
            .required(t("GenericRequiredText"))
            .max(300, t('Error_TextTooLong', {1:300})),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("Severity")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisTextBox labeltext={t("Description")} name="description" required />
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisDropdown size={"small"} id={"visibilityID"} name={"visibilityID"} items={visiblites} labeltext={t("Visibility")} />
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}