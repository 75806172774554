const IsToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
}

const IsSameDay = (firstDate, secondDate) => {
    //console.log(secondDate);
    return firstDate.getFullYear() === secondDate.getFullYear() &&
        firstDate.getMonth() === secondDate.getMonth() &&
        firstDate.getDate() === secondDate.getDate();
}

const DateHelperService = {
    IsToday,
    IsSameDay
}

export default DateHelperService;