import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {OmisDropdownNoForm} from "../shared/OmisDropdowns";
import httpclientService from "../../services/httpclient.service";

export default function LanguageDropdown(props) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [currentLanguage, setCurrentLanguage] = useState("de-AT");
    const [languages, setLanguages] = useState([
            {
                key: "de-AT",
                displayText: t("Language_de_AT")
            }
    ]);

    function changeUserLanguage(lang) {

        if (props.languageSelectionCallback) {
            props.languageSelectionCallback(lang);
        } else {
            i18n.changeLanguage(lang).then(() => {
                localStorage.setItem("i18nLng", lang);
                setCurrentLanguage(lang);
            }).then(() => {
                httpclientService.post("/api/profile/changelanguage", lang).then((response) => {
                    var user = JSON.parse(localStorage.getItem("user"));
                    user.language = lang;
                    localStorage.setItem("user", JSON.stringify(user));
                    navigate(0);
                    window.location.reload();
                });
            });
        }
    }

    useEffect(() => {
        httpclientService.get("api/profile/getcustomerlanguages").then((response) => {
            response.forEach(l => l.displayText = t(l.displayText));
            setLanguages(response);
            setCurrentLanguage(localStorage.getItem("i18nLng"));
        })
    }, []);

    useEffect(() => {
        if (props.userLanguage && languages.length>0) {
            setCurrentLanguage(props.userLanguage);
        }
    }, [props.userLanguage]);

    return (
        <OmisDropdownNoForm id={"languageDropdown"} items={languages} onChange={e => changeUserLanguage(e.target.value)} value={[currentLanguage]} labeltext={!props.hideLabelText ? t("Sprachauswahl"):null}/>
    );
}