import React, {useEffect, useState} from "react";
import httpclientService from "../../services/httpclient.service";
import {OmisDropdownNoForm} from "../shared/OmisDropdowns";
import { OmisButtonNewItem, OmisButtonPrimary, OmisButtonPrimarySubmit, OmisButtonSecondary, OmisButtonUploadFiles } from "../shared/OmisButtons";
import { IconButton } from "@mui/material";
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';
import Icon from "@mdi/react";
import {t} from "i18next";
import DialogActions from "@mui/material/DialogActions";
import {OmisGrid} from "../shared/OmisGrids";
import {DMSDocumentReferer} from "../../constants/DMSDocumentReferer";
import {OmisDialog, OmisErrorMessage} from "../shared/OmisDisplays";
import { mdiPencil,mdiInboxArrowDown,mdiTrashCan} from "@mdi/js";
import {OmisLinearProgress} from "../shared/OmisProgressDisplays";
import { OmisTextBoxNoForm } from "../shared/OmisInputs";
import AuthService from "../../services/auth.service";
import { TRole } from "../../constants/RoleConstants";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../shared/OmisLayouts";

export default function Documents(props) {
    var {id, referer} = {...props};

    if (!id) {
        id = 1; //TODO: get CustomerID
    }

    if (!referer) {
        referer = DMSDocumentReferer.Customer;
    }

    const [changeCounter, setChangeCounter] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState("");

    function fileUploadCallback(errorMessage) {
        setChangeCounter((prevState) => prevState + 1);
        if (!errorMessage || errorMessage?.length === 0) {
            handleModalClose();
        }
    }

    const handleModalOpen = (content, title, e, rowVals) => {

        if (content === null && title === t("Action_Edit")) {
            content = <UpdateDocument updateChanges={updateDocument} closeAction={handleModalClose} rowVals={rowVals} {...props} />;
        }

        setModalContent(content);
        setModalTitle(title);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setModalContent(null);
        setModalTitle(null);
    };

    const deleteDocument = (e, rowVals) => {
        if (rowVals.fullLocation !== "" && rowVals.fullLocation !== undefined) {
            httpclientService.post(`api/documents/deletedocuments`, { fileName: "", fullLocation: rowVals.fullLocation }).then((response) => {
                setChangeCounter((prevState) => prevState + 1);
            });
        }
    };

    const updateDocument = (documentName, rowVals) => {
        if ((documentName !== "" && documentName !== undefined) && (rowVals?.fullLocation !== "" && rowVals?.fullLocation !== undefined)) {
            httpclientService.get(`api/documents/renamedocument?fullLocation=${rowVals?.fullLocation}&newName=${documentName}`).then((response) => {
                setChangeCounter((prevState) => prevState + 1);
                handleModalClose();
            });
        }
    };

    const downloadDocument = (fullLocation, rowVals) => {
        if ((rowVals?.text !== "" && rowVals?.text !== undefined) && (rowVals?.fullLocation !== "" && rowVals?.fullLocation !== undefined)) {
            const link = document.createElement("a");
            link.href = `api/documents/displaydocuments?fileName=${rowVals?.text}&fullLocation=${rowVals?.fullLocation}&download=${true}`;
            link.click();
            link.remove();
        }
    };

    var dropDownMenuItems = [
        {
            action: downloadDocument,
            iconpath: mdiInboxArrowDown,
            icontext: t("Action_Download"),
            disableItem: false
        }
    ]

    if (AuthService.hasRole(TRole.DMSRenameDocument)) {
        dropDownMenuItems = [
            {
                action: handleModalOpen.bind(this, null, t("Action_Edit")),
                iconpath: mdiPencil,
                icontext: t("Action_Edit"),
                disableItem: false
            },  ...dropDownMenuItems ];
    }

    if (AuthService.hasRole(TRole.DMSDeleteDocument)) {
        dropDownMenuItems.push({
            action: deleteDocument,
            iconpath: mdiTrashCan,
            icontext: t("Action_Delete"),
            disableItem: false
        }); 
    }
    var showNewButton = true;

    switch (referer) {
        case DMSDocumentReferer.Customer:
            showNewButton = AuthService.hasRole(TRole.GenericDocuments);
            break;
        default:
            break;
    }

	if (props.documentTab) {
		return (
		
			<>
				
				<OmisGrid
                    treeView
                    headerText={t("Documents")}
					apiUrl={`/api/documents/getdocuments?id=${id}&referer=${referer}`}
					change={changeCounter}
					actiondropdown={{ dropDownMenuItems: dropDownMenuItems }}
					withUploadDate={true}
                    documentTypesDropDownHasItems={[]}
                    newButton={showNewButton ? <OmisButtonNewItem id={"newDocumentButton"} onClick={handleModalOpen.bind(this, <DocumentUpload handleModalClose={handleModalClose} fileUploadCallback={fileUploadCallback} id={id} referer={referer} />, `${t("Documents")}`)} text={t("AddDocuments")} /> : null}
                    noExportButton
                    hideQuickSearch
				/>
				<OmisDialog onClose={handleModalClose} open={modalOpen}
							fullWidth={true}
							maxWidth={'md'}
							title={modalTitle}
							content={modalContent}
				/>
			</>
		);		
	}

    return (
		
        <>			
            <OmisGrid
                treeView
                headerText={t("Documents")}
                apiUrl={`/api/documents/getdocuments?id=${id}&referer=${referer}`}
                change={changeCounter}
                actiondropdown={{ dropDownMenuItems: dropDownMenuItems }}
                withUploadDate={true}
                documentTypesDropDownHasItems={[]}
                newButton={showNewButton ? <><OmisButtonNewItem id="newDocumentButton" onClick={handleModalOpen.bind(this, <DocumentUpload handleModalClose={handleModalClose} fileUploadCallback={fileUploadCallback} id={id} referer={referer} />, `${t("Documents")}`)} text={t("AddDocuments")} /></> : null}
                noButtons={props.noButtons}
                noExportButton
                hideQuickSearch
            />
            <OmisDialog onClose={handleModalClose} open={modalOpen}
                        fullWidth={true}
                        maxWidth={'lg'}
                        title={modalTitle}
                        content={modalContent}
            />
        </>
    );
}

function UpdateDocument(props) {
    const [documentName, setDocumentName] = useState(null);

    const nameTyped = (event) => {
        setDocumentName(event.target.value);
    }

    const onSubmitChange = () => {
        if (props.updateChanges) {
            props.updateChanges(documentName, props.rowVals);
        }        
    }

    return (
        <>
            <OmisContainer fluid>
                <OmisRow>
                    <OmisCol xs={6}><OmisTextBoxNoForm label={t("Name")} defaultValue={props.rowVals?.text} onChange={nameTyped} /></OmisCol>
                </OmisRow>
                <br/>
                <OmisRow>
                    <OmisCol xs={6}>
                        <OmisButtonSecondary id={"cancelButton"} text={t("Cancel")} onClick={props.closeAction}></OmisButtonSecondary>
                    </OmisCol>
                    <OmisCol xs={6}>
                        <OmisButtonPrimarySubmit id={"submitButton"} text={t("Action_Save")} onClick={onSubmitChange} />
                    </OmisCol>
                </OmisRow>
            </OmisContainer>
        </>
    );
}

export function DocumentUpload(props) {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(undefined);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState("");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [selectedDocumentTypes, setSelectedDocumentTypes] = useState([]);
    const [documentTypeList, setDocumentTypeList] = useState([]);
    const [uploadButtonEnabled, setUploadButtonEnabled] = useState(false);

    useEffect(() => {
        if (selectedFiles.length > 0 && documentTypeList.length === 0) {
            httpclientService.get(`api/documents/getavailabledocumenttypes?referer=${props.referer}`).then((response) => {
                response.forEach(l => l.displayText);
                setDocumentTypeList(response);
            });
        }
        setSelectedDocumentTypes([]);
    }, [selectedFiles]);

    const selectFile = (event) => {
        event.preventDefault();
        var allFiles;
        if(event.target?.files)
        {
            allFiles = selectedFiles.concat(...event.target.files);
        }
        if(event.dataTransfer?.files)
        {
            allFiles = selectedFiles.concat(...event.dataTransfer.files);       
        }
        allFiles = allFiles.filter((value, index, self) => self.findIndex(v => v.name === value.name) === index);
        setSelectedFiles(allFiles);
    };

    const selectFileV2 = (files) => {
        var allFiles;
        if (files) {
            allFiles = selectedFiles.concat(...files);
        }
        allFiles = allFiles.filter((value, index, self) => self.findIndex(v => v.name === value.name) === index);
        setSelectedFiles(allFiles);
    };

    useEffect(() => {
        if (selectedFiles.length > 0) {
            if (selectedDocumentTypes.length === selectedFiles.length) {
                setUploadButtonEnabled(true);
            } else {
                setUploadButtonEnabled(false);
            }
        }

        return () => {
            setUploadButtonEnabled(false);
        };
    }, [selectedDocumentTypes]);


    const upload = () => {
        let succesfullyUploadedFiles = [];
        let errorMessage = [];
        setMessage(null);
        for (var i = 0; i < selectedFiles.length; i++) {
            let currentFile = selectedFiles[i];

            setProgress(0);
            setCurrentFile(currentFile);

            httpclientService.uploadDocument([currentFile], props.id ?? 0, props.referer, selectedDocumentTypes.filter(s => s.name === currentFile.name)[0].docType, (event) => {
                setProgress(Math.round((100 * event.loaded) / event.total));
            },props.guid)
                .then((response) => {
                    //setMessage(response.data.message);
                    if (response.status === 200 || response.data.statusCode === 200) {
                        var filedetails = {
                            name: currentFile.name,
                            content:"",
                            filecontenttype: currentFile.type,
                            doctype: selectedDocumentTypes.filter(s => s.name === currentFile.name)[0].docType,
                            doctypename: documentTypeList.filter(f => f.key === selectedDocumentTypes.filter(s => s.name === currentFile.name)[0].docType)[0]?.displayText
                        };

                        succesfullyUploadedFiles.push(filedetails);
                        //setMessage("");
                    } else {
                        if (props.uploadError) {
                            errorMessage.push(`${response.data.message ?? `${currentFile.name}: Could not upload the file!`}`);
                            if (props.uploadError) {
                                props.uploadError(errorMessage);
                            } else if (errorMessage) {
                                setMessage(errorMessage);
                            }
                        }                       
                    }
                    if (props.multiFilesUploadCallback && succesfullyUploadedFiles.length === selectedFiles.length) {
                        props.multiFilesUploadCallback(succesfullyUploadedFiles);
                    }
                    setSelectedFiles([]);
                    setCurrentFile(undefined);
                    setUploadedFiles(succesfullyUploadedFiles);
                    if (props.fileUploadCallback) {
                        props.fileUploadCallback(errorMessage);
                    }
                })
                .catch((err) => {
                    setProgress(0);
                    errorMessage.push(`${err?.response?.data?.message ?? `${currentFile.name}: Could not upload the file!`}`);
                    if (props.uploadError) {
                        props.uploadError(errorMessage);
                    } else if (errorMessage) {
                        setMessage(errorMessage);
                    }
                    setCurrentFile(undefined);
                });
        }
    };

    function deleteFileButtonClicked(file) {
        var filteredSelectedFiles = selectedFiles.filter(s => s.name !== file);
        setSelectedFiles(filteredSelectedFiles);
        var filteredSelectedDocumentTypes = selectedDocumentTypes.filter(s => s.name !== file);
        setSelectedDocumentTypes(filteredSelectedDocumentTypes);
    }

    function handleDocumentTypeChange(file, type) {
        var existingDocType = selectedDocumentTypes.filter(s => s.name === file)[0];
        if (!existingDocType) {
            setSelectedDocumentTypes((prevState) => [...prevState, {
                name: file,
                docType: type
            }]);
        } else {
            var updatedDocumentTypes = selectedDocumentTypes.map(docType => {
                if (docType.name === file) {
                    return {...docType, docType: type};
                }
                return docType;
            })

            setSelectedDocumentTypes(updatedDocumentTypes);
        }
    }

    return (
        <>
            <OmisContainer fluid style={{ display: 'flex', justifyContent: 'center', marginBottom:'1em'}}>
                <OmisRow>
                    <OmisCol xs={12}>
                        <span style={{ display: 'flex', justifyContent: 'center' }}>{t("DMS_DropFilesHere")} {t("Or")?.toLowerCase()}<br></br></span>
                        {/* <FileDropZone selectfilecallback= {selectFile}/> */}
                        <OmisButtonUploadFiles onChange={selectFileV2} />
                    </OmisCol>
                </OmisRow>
               
            </OmisContainer>
			{
				selectedFiles ?
                    selectedFiles.map((f, index) =>

                        <OmisContainer key={ index} fluid style={{ display: 'flex', justifyContent: 'center'}}>
                            <OmisRow>
                                <OmisCol xs={5}>
                                    <p>{f.name}</p>
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisDropdownNoForm items={documentTypeList}
                                        onChange={e => handleDocumentTypeChange(f.name, e.target.value)}
                                        value={selectedDocumentTypes?.filter(s => s.name === f.name)[0]?.docType ?? ""}
                                        labeltext={t("DMS_DocumentType")}
                                        id={"documentTypeDropdown"}
                                    />
                                </OmisCol>
                                <OmisCol xs={2}>
                                    <p>{bytesToSize(f.size)}</p>
                                </OmisCol>
                                <OmisCol xs={1}>
                                    <IconButton variant={"outlined"}
                                        id={"deleteSelectedFilesButton"}
                                        color={"error"}
                                        onClick={() => deleteFileButtonClicked(f.name)}
                                    >
                                        <Icon path={mdiTrashCan} size={1} />
                                    </IconButton>
                                </OmisCol>
                                <OmisCol xs={12}>
                                    {currentFile && (
                                        <OmisLinearProgress value={progress} />
                                    )}
                                </OmisCol>
                            </OmisRow>
               
                        </OmisContainer>
					)
					: null
            }

            <OmisContainer fluid style={{ justifyContent: 'center' }}>

                {
                    message ?
                        typeof (message) === "string" ?
                            <OmisRow>
                                <OmisStack direction="horizontal">
                                    <OmisCol xs={1}>
                                        <ClearIcon color={"error"} />
                                    </OmisCol>
                                    <OmisCol xs={11}>
                                        <OmisErrorMessage text={message} />
                                    </OmisCol>
                                </OmisStack>
                            </OmisRow>
                            :
                            message?.map((message, index) =>
                                <OmisRow key={index}>
                                    <OmisStack direction="horizontal">
                                        <OmisCol xs={1}>
                                            <ClearIcon color={"error"} />
                                        </OmisCol>
                                        <OmisCol xs={11}>
                                            <OmisErrorMessage text={message} />
                                        </OmisCol>
                                    </OmisStack>
                                </OmisRow>
                            )
                        :
                        null
                }

                {
                    uploadedFiles && uploadedFiles.length > 0 ?
                        <OmisRow>
                            <span>{`${t("Import_UploadedFiles")} :`}</span>
                            {
                                uploadedFiles.map((file, index) =>
                                    <OmisStack key={index.toString()} direction="horizontal">
                                        <OmisCol xs={1}>
                                            <CheckIcon color={"success"} />
                                        </OmisCol>
                                        <OmisCol xs={11}>
                                            <strong>{file.name}({file.doctypename})</strong>
                                        </OmisCol>
                                    </OmisStack>
                                )
                            }
                        </OmisRow>
                        :
                        null
                }

                <OmisRow>
                    <DialogActions>
                        <OmisButtonPrimary
                            id={"uploadFilesButton"}
                            disabled={!uploadButtonEnabled}
                            onClick={upload}
                            text={t("Upload")}
                        />
                        {
                            props.id ?
                                <OmisButtonSecondary id={"cancelUploadButton"} text={t("Action_Cancel")} onClick={props.handleModalCancel ? props.handleModalCancel : props.handleModalClose}></OmisButtonSecondary>
                                : null
                        }
                    </DialogActions>
                </OmisRow>

                {/*{*/}
                {/*    message && (<div className="alert alert-light" role="alert">*/}
                {/*        {message}*/}
                {/*    </div>)*/}
                {/*}*/}
            </OmisContainer>
        </>
    );
}

export function DocumentUploadForNewReport(props) {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(undefined);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState("");
    const [selectedDocumentTypes, setSelectedDocumentTypes] = useState([]);
    const [documentTypeList, setDocumentTypeList] = useState([]);
    const [uploadButtonEnabled, setUploadButtonEnabled] = useState(false);

    useEffect(() => {
        if (selectedFiles.length > 0 && documentTypeList.length === 0) {
            if (props.hideDocumentType) {
                setUploadButtonEnabled(true);
            } else {
                httpclientService.get(`api/documents/getavailabledocumenttypes?referer=${props.referer}`).then((response) => {
                    response.forEach(l => l.displayText);
                    setDocumentTypeList(response);
                });
            }
        }
    }, [selectedFiles]);

    const selectFile = (event) => {
        event.preventDefault();
        var allFiles;
        if (event.target?.files) {
            allFiles = selectedFiles.concat(...event.target.files);
        }
        if (event.dataTransfer?.files) {
            allFiles = selectedFiles.concat(...event.dataTransfer.files);
        }
        allFiles = allFiles.filter((value, index, self) => self.findIndex(v => v.name === value.name) === index);
        setSelectedFiles(allFiles);
    };

    const selectFileV2 = (files) => {
        var allFiles;
        if (files) {
            allFiles = selectedFiles.concat(...files);
        }
        allFiles = allFiles.filter((value, index, self) => self.findIndex(v => v.name === value.name) === index);
        setSelectedFiles(allFiles);
    };

    useEffect(() => {
        if (selectedFiles.length > 0) {
            if (selectedDocumentTypes.length === selectedFiles.length) {
                setUploadButtonEnabled(true);
            } else {
                setUploadButtonEnabled(false);
            }
        }

        return () => {
            setUploadButtonEnabled(false);
        };
    }, [selectedDocumentTypes]);


    const upload = () => {
        let succesfullyUploadedFiles = [];
        for (var i = 0; i < selectedFiles.length; i++) {
            let currentFile = selectedFiles[i], docType = props.hideDocumentType ? 0 : selectedDocumentTypes[i].docType;

            const reader = new FileReader();
            var url = reader.readAsDataURL(currentFile);

            reader.onloadend = function (e) {
                succesfullyUploadedFiles.push({ name: currentFile.name, filecontenttype: currentFile.type, doctype: docType, content: reader.result });
                if (props.multiFilesUploadCallback && i === selectedFiles.length) {
                    props.multiFilesUploadCallback(succesfullyUploadedFiles);
                }
                setSelectedFiles([]);
                setSelectedDocumentTypes([]);
            }.bind(this);
        }
    };

    function deleteFileButtonClicked(file) {
        var filteredSelectedFiles = selectedFiles.filter(s => s.name !== file);
        setSelectedFiles(filteredSelectedFiles);
        var filteredSelectedDocumentTypes = selectedDocumentTypes.filter(s => s.name !== file);
        setSelectedDocumentTypes(filteredSelectedDocumentTypes);
    }

    function handleDocumentTypeChange(file, type) {
        var existingDocType = selectedDocumentTypes.filter(s => s.name === file)[0];
        if (!existingDocType) {
            setSelectedDocumentTypes((prevState) => [...prevState, {
                name: file,
                docType: type
            }]);
        } else {
            var updatedDocumentTypes = selectedDocumentTypes.map(docType => {
                if (docType.name === file) {
                    return { ...docType, docType: type };
                }
                return docType;
            })

            setSelectedDocumentTypes(updatedDocumentTypes);
        }
    }

    return (
        <>
            <OmisContainer>
                <OmisRow>
                    <OmisCol xs={12} style={{ paddingLeft: '1.8em', paddingRight: '1.8em' }} >
                        {/* <FileDropZone selectfilecallback= {selectFile}/> */}
                        <OmisButtonUploadFiles disabled={props.disabled || (props.singlefileupload && selectedFiles.length > 0)} onChange={selectFileV2} labeltext={<strong>{t("DMS_DropFilesHere")} {t("Or")?.toLowerCase()}</strong>} />
                    </OmisCol>

                </OmisRow>

                {
                    selectedFiles ?
                        selectedFiles.map((f, index) =>
                            <OmisRow key={`${index}_row`} style={{ display: 'flex', justifyContent: 'center', marginLeft: '0.5em' }}>
                                <OmisStack direction="horizontal" gap={3}>
                                    <OmisCol key={f.name} xs={3}>
                                        <p>{f.name}</p>
                                    </OmisCol>
                                    {
                                        props.hideDocumentType ?
                                            null
                                            :
                                            <OmisCol xs={5} style={{ marginTop: '1em' }}>
                                                <OmisDropdownNoForm items={documentTypeList}
                                                    onChange={e => handleDocumentTypeChange(f.name, e.target.value)}
                                                    value={selectedDocumentTypes?.filter(s => s.name === f.name)[0]?.docType ?? ""}
                                                    labeltext={t("DMS_DocumentType")}
                                                    id={"documentTypeDropdown"}
                                                />
                                            </OmisCol>
                                    }
                                    <OmisCol xs={2}>
                                        <p>{bytesToSize(f.size)}</p>
                                    </OmisCol>
                                    <OmisCol xs={1}>
                                        <IconButton variant={"outlined"}
                                            id={"deleteSelectedFilesButton"} 
                                            color={"error"}
                                            onClick={() => deleteFileButtonClicked(f.name)}
                                        >
                                            <Icon path={mdiTrashCan} size={1} />
                                        </IconButton>
                                    </OmisCol>
                                    <OmisCol xs={12}>
                                        {currentFile && (
                                            <OmisLinearProgress value={progress} />
                                        )}
                                    </OmisCol>
                                </OmisStack>
                            </OmisRow>
                        )
                        : null
                }
                <OmisRow>
                    <OmisCol style={{ justifyContent: 'center', marginLeft: '0.5em' }} xs={12}>
                        <DialogActions>
                            <OmisButtonPrimary
                                id={"uploadButton"} 
                                disabled={!uploadButtonEnabled}
                                onClick={upload}
                                text={t("Upload")}
                                fullWidth={true}
                            />
                            {
                                props.id ?
                                    <OmisButtonSecondary id={"cancelUploadButton"} text={t("Action_Cancel")} onClick={props.handleModalClose}></OmisButtonSecondary>
                                    : null
                            }
                        </DialogActions>
                    </OmisCol>
                </OmisRow>
                
            </OmisContainer>
        </>
    );
}

function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};


