import React, { useState, useEffect } from 'react';
import { OmisButtonNewItem, OmisIconButton, OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisGrid } from "../../shared/OmisGrids";
import { mdiTrashCan, mdiPencil } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { OmisDeleteQuestionDialog, OmisDialog } from '../../shared/OmisDisplays';
import { OmisCheckBox, OmisTextBox } from '../../shared/OmisInputs';
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { OmisDropdown } from '../../shared/OmisDropdowns';
import httpClientService from '../../../services/httpclient.service';
import { Link as RouterLink } from "react-router-dom";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { TabConstants } from "../../../constants/SettingConstants";

export default function FaultKind(props) {
    var url = "api/settings/faultkind/getfaultKinds";
    const { t } = useTranslation();

    const [changeCounter, setChangeCounter] = useState(0);
    const [newModalOpen, setNewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedRowID, setSelectedRowID] = useState(0);

    const [faultEquiClassDefs, setFaultEquiClassDefs] = useState([]);
    const [faultCategories, setFaultCategories] = useState([]);
    const [tradeLevels, setTradeLevels] = useState([]);
    const [reactTimeModels, setReactTimeModels] = useState([]);

    if (props.faultEquiClassDefID) {
        url = `${url}?faultEquiClassDefID=${props.faultEquiClassDefID}`;
    }

    const handleModalOpen = () => {
        setNewModalOpen(true);
    }

    const handleModalClose = () => {
        setNewModalOpen(false);
        setEditModalOpen(false);
        setDeleteModalOpen(false);
        setSelectedRowID(0);
    }

    function handleEditClick(item) {
        setSelectedRowID(item);
        setEditModalOpen(true);
    }

    function handleDeleteClick(item) {
        setSelectedRowID(item);
        setDeleteModalOpen(true);
    }

    function handleDeleteItem() {
        httpClientService.post(`api/settings/faultkind/delete?id=${selectedRowID}`).then(response => {
            setChangeCounter(prevState => prevState + 1);
            handleModalClose();
        })
    }

    useEffect(() => {
        httpClientService.get("api/settings/faultkind/getfaultequiclassdeflist").then(response => {
            setFaultEquiClassDefs(response);
        })

        httpClientService.get("api/settings/faultkind/getfaultcategorylist").then(response => {
            setFaultCategories(response);
        })

        httpClientService.get("api/settings/faultkind/gettradelevellist").then(response => {
            setTradeLevels(response)
        })

        httpClientService.get("api/settings/faultkind/getreacttimemodellist").then(response => {
            setReactTimeModels(response)
        })
    }, [])

    const dropDownMenuItems = [
        {
            action: handleEditClick,
            iconpath: mdiPencil,
            icontext: t("Action_Edit")
        },
        {
            action: handleDeleteClick,
            iconpath: mdiTrashCan,
            icontext: t("Action_Delete")
        }
    ]

    return (
        <OmisContainer>
            {
                props.hideBack ?
                    null
                    :
                    <div class=" omis-header-box">
                        <OmisCol xs={1}>
                            <OmisIconButton id={"backButton"}
                                iconcontent={<ArrowBackIcon fontSize="inherit" />}
                                text="Back" component={RouterLink} to={"/settings"} state={{ selectedtabvalue: TabConstants.Reports }} />
                        </OmisCol>
                    </div>
            }

            <OmisGrid actiondropdown={{ dropDownMenuItems: dropDownMenuItems }}
                change={changeCounter}
                headerText={t("FaultKinds")}
                newButton={<OmisButtonNewItem id={"newItemButton"} onClick={handleModalOpen} text={t("Action_New")} />}
                noHeaderText={props.faultEquiClassDefID}
                noExportButton={props.faultEquiClassDefID}
                apiUrl={url}
            />

            <OmisDialog onClose={handleModalClose} open={newModalOpen}
                title={t("FaultKind_Create")}
                content={<FaultKindPopup_Content {...props} />}
            />
            <OmisDialog onClose={handleModalClose} open={editModalOpen}
                title={"Fehlerart bearbeiten"}
                content={<FaultKindPopup_Content {...props} />}
            />
            <OmisDeleteQuestionDialog open={deleteModalOpen}
                handleModalClose={handleModalClose}
                deleteFunction={handleDeleteItem}
            />
        </OmisContainer>
    );



    function FaultKindPopup_Content(props) {
        const [initialValues, setInitialValues] = useState({
            id: 0,
            faultEquiClassDefID: props.faultEquiClassDefID ?? null,
            faultKindDesc: '',
            sortIndex: 0,
            faultCategoryID: null,
            tradeLevelID: null,
            reactTimeModelID: null,
            pictureRequired: false,
            returnRequired: false,
            affectsDownTime: false
        })

        const editItem = Boolean(selectedRowID > 0)
        useEffect(() => {
            if (editItem) {
                httpClientService.get(`api/settings/faultkind/get?id=${selectedRowID}`).then(response => {
                    setInitialValues(response);
                })
            }
        },[])

        function handleSave(values) {
            httpClientService.post("api/settings/faultkind/savefaultkind", JSON.stringify(values)).then(response => {
                switch (response.statusCode) {
                    case 200:
                        handleModalClose();
                        setChangeCounter(prevState => prevState + 1);
                        break;

                    default:
                        break;
                }
            })
        }

        return (
            <Formik initialValues={initialValues}
                enableReinitialize={true}
                validateOnMount
                validationSchema={Yup.object({
                    faultEquiClassDefID: Yup.number()
                        .required(t("GenericRequiredText"))
                        .min(1, t("GenericRequiredText")),
                    faultKindDesc: Yup.string()
                        .required(t("GenericRequiredText"))
                        .min(1, t("GenericRequiredText"))
                        .max(128, t("Error_TextTooLong", {1:128})),
                    sortIndex: Yup.string()
                        .required(t("GenericRequiredText"))
                        .min(1, t("GenericRequiredText"))
                        .max(2048, t("Error_TextTooLong", { 1: 2048 })),

                    faultCategoryID: Yup.number()
                        .required(t("GenericRequiredText"))
                        .min(1, t("GenericRequiredText")),

                    tradeLevelID: Yup.number()
                        .required(t("GenericRequiredText"))
                        .min(1, t("GenericRequiredText")),

                    reactTimeModelID: Yup.number()
                        .required(t("GenericRequiredText"))
                        .min(1, t("GenericRequiredText")),
                })}
                onSubmit={values => handleSave(values)}
            >{formik => (
                <Form>

                    <OmisContainer fluid>
                        <OmisRow>
                            <OmisCol xs={12}>
                                <OmisDropdown labeltext={t("FaultEquiClassDefs")} name="faultEquiClassDefID" items={faultEquiClassDefs} disabled={props.faultEquiClassDefID ? true : false} required />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol >
                                <OmisTextBox labeltext={t("Description")} name="faultKindDesc" required />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol xs={6}>
                                <OmisTextBox labeltext={t("FaultKind_SortIndex")} name="sortIndex" required />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol >
                                <OmisDropdown labeltext={t("FaultCategory")} name="faultCategoryID" items={faultCategories} required />
                            </OmisCol>
                        </OmisRow>

                        <OmisRow>
                            <OmisCol>
                                <OmisDropdown labeltext={t("Object_TradeLevel")} name="tradeLevelID" items={tradeLevels} required />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol>
                                <OmisDropdown labeltext={t("ReactTimeModel_Settings")} name="reactTimeModelID" items={reactTimeModels} required />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol>
                                <OmisCheckBox labeltext={t("PictureRequired")} name="pictureRequired" />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol>
                                <OmisCheckBox labeltext={t("ReturnRequired")} name="returnRequired" />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol>
                                <OmisCheckBox labeltext={t("FaultKind_AffectsDownTime")} name="affectsDownTime" />
                            </OmisCol>
                        </OmisRow>

                        <OmisRow>
                            <OmisCol xs={6}>
                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleModalClose}></OmisButtonSecondary>
                            </OmisCol>
                            <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                            </OmisCol>
                        </OmisRow>
                    </OmisContainer>
                </Form>
            )}
            </Formik>
        );
    }
}