import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { Card } from "@mui/material";
import { OmisCheckBox } from "../../shared/OmisInputs";
import { SettingsCompanySites } from "../CompanySite/SettingsCompanySites";
import { OmisSelectedCatalogueCard } from "../../shared/OmisDisplays";
import { SettingsEquiClasses } from "./SettingsEquiClasses";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";


const SelectionStep = {
    EquiClass: 1,
    CompanySite: 2,
    Done: 3
}

export function CreateEquiContractor(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const [selectedEquiClassID, setSelectedEquiClassID] = useState(null);
    const [selectedEquiClassText, setSelectedEquiClassText] = useState('');
    const [selectedCompanySiteID, setSelectedCompanySiteID] = useState(null);
    const [selectedCompanySiteText, setSelectedCompanySiteText] = useState('');

    const [selectionStep, setSelectionStep] = useState(SelectionStep.EquiClass);

    const [initialValues, setInitialValues] = useState({
        id: props.id ? props.id : null,
        equiClassID: props.rowVals?.equiClassID ?? selectedEquiClassID,
        companySiteID: props.rowVals?.companySiteID ?? selectedCompanySiteID,
        guarantee: props.rowVals?.guarantee ?? false
    });

    useEffect(() => {
        if (props.rowVals) {
            setSelectedEquiClassID(props.rowVals.equiClassID);
            setSelectedEquiClassText(props.rowVals.equiClass);
            setSelectedCompanySiteID(props.rowVals.companySiteID);
            setSelectedCompanySiteText(props.rowVals.companySiteName);
            setSelectionStep(SelectionStep.Done);
        }
    },[]);

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsequipments/saveequicontractor", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate("/settings/settingsequipments/equipmentcontractors");
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate("/settings/settingsequipments/equipmentcontractors");
        }
    }

    function handleSelect(step, selection) {
        switch (step) {
            case SelectionStep.EquiClass:
                setSelectionStep(SelectionStep.CompanySite);
                updateSelection(selection);
                break;
            case SelectionStep.CompanySite:
                setSelectionStep(SelectionStep.Done);
                updateSelection(selection);
                break;
            default:
                break;
        }
    }

    function handleDeselect(step) {
        switch (step) {
            case SelectionStep.EquiClass:
                setSelectionStep(SelectionStep.EquiClass);
                clearSelection(step);
                break;
            case SelectionStep.CompanySite:
                setSelectionStep(SelectionStep.CompanySite);
                clearSelection(step);
                break;
            default:
                break;
        }
    }

    function handleSingleEntry(selectionGrid, row) {
        switch (selectionGrid) {
            case SelectionStep.EquiClass:
                setSelectionStep(SelectionStep.CompanySite);
                updateSelection(row);
                break;
            case SelectionStep.CompanySite:
                setSelectionStep(SelectionStep.CompanySite);
                updateSelection(row);
                break;
            default:
                break;
        }
    }

    function clearSelection(step) {
        var clearEquiClass = step <= SelectionStep.EquiClass;
        var clearCompanySite = step <= SelectionStep.CompanySite;

        if (clearEquiClass) {
            setSelectedEquiClassID(null);
            setSelectedEquiClassText('');
            formRef.current.setFieldValue("equiClassID", null);
        }

        if (clearCompanySite) {
            setSelectedCompanySiteID(null);
            setSelectedCompanySiteText('');
            formRef.current.setFieldValue("companySiteID", null);
        }
    }

    function updateSelection(row) {
        switch (selectionStep) {
            case SelectionStep.EquiClass:
                setSelectedEquiClassID(row?.id ?? null);
                setSelectedEquiClassText(row?.equiClass ?? '');
                formRef.current.setFieldValue("equiClassID", row?.id ?? null);
                break;
            case SelectionStep.CompanySite:
                setSelectedCompanySiteID(row?.id ?? null);
                setSelectedCompanySiteText(row?.companySiteName ?? '');
                formRef.current.setFieldValue("companySiteID", row?.id ?? null);
                break;
            default:
                break;
        }
    }

    function CatalogueGrid(props) {
        const { step } = { ...props };

        switch (step) {
            case SelectionStep.EquiClass:
                return (
                    <OmisCol xs={12}>
                        <SettingsEquiClasses
                            rowclickCallback={handleSelect.bind(this, SelectionStep.EquiClass)}
                            noExportButton
                            singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.EquiClass)}
                            hideBack
                            disableChipClick
                        />
                    </OmisCol>
                );
            case SelectionStep.CompanySite:
                return (
                    <OmisCol xs={12}>
                        <SettingsCompanySites
                            rowclickCallback={handleSelect.bind(this, SelectionStep.CompanySite)}
                            singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.CompanySite)}
                            hideBack
                            disableChipClick
                        />
                    </OmisCol>
                );
            default:
                break;
        }
    }

    const [schema, setSchema] = useState({
        equiClassID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        companySiteID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("Settings_EquiContractors")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={4}>
                                    <Card variant={"outlined"}>
                                        <OmisSelectedCatalogueCard
                                            text={`${t("EquiClass")} (${selectedEquiClassText})`}
                                            id={selectedEquiClassText}
                                            handleDeselect={handleDeselect.bind(this, SelectionStep.EquiClass)}
                                            disabled={props.rowVals}
                                            required
                                        />
                                    </Card>
                                </OmisCol>

                                <OmisCol xs={4}>
                                    <Card variant={"outlined"}>
                                        <OmisSelectedCatalogueCard
                                            text={`${t("CompanySite")} (${selectedCompanySiteText})`}
                                            id={selectedCompanySiteText}
                                            handleDeselect={handleDeselect.bind(this, SelectionStep.CompanySite)}
                                            disabled={props.rowVals}
                                            required
                                        />
                                    </Card>
                                </OmisCol>

                                <OmisCol xs={4}>
                                    <Card variant={"outlined"}>
                                        <br />
                                        <OmisCheckBox labeltext={t("MaintenanceContractType_Guarantee")} name={"guarantee"} />
                                    </Card>
                                </OmisCol>
                            </OmisRow>
                            <br />
                            <OmisRow>
                                <CatalogueGrid step={selectionStep} {...props} />
                            </OmisRow>
                            <br/>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}