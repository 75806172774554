import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary,  } from "../../shared/OmisButtons";
import { OmisCheckBox, OmisDateTimePicker, OmisTextBox } from "../../shared/OmisInputs";
import AuthService from "../../../services/auth.service";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateEquiType(props) {

    var { state } = useLocation();
    const navigate = useNavigate();
    const formRef = useRef();

    const [equiClasses, setEquiClasses] = useState([]);
    const [initialValues, setInitialValues] = useState({
        id: props.id ? props.id : null,
        description: props.rowVals?.description ?? "",
        equiClassID: props.rowVals?.equiClassID ?? state?.equiclassid ?? null,
        avgLifeTime: props.rowVals?.avgLifeTime ?? 0,
        sparePartAvailability: props.rowVals?.sparePartAvailability ?? null,
        installationCosts: props.rowVals?.installationCosts ?? 0,
        disposalCosts: props.rowVals?.disposalCosts ?? 0,
        newPrice: props.rowVals?.newPrice ?? 0,
        price: props.rowVals?.price ?? 0,
        //annualPriceAdjustment: props.rowVals?.annualPriceAdjustment ?? 0,
        //lastPriceAdjustment: props.rowVals?.lastPriceAdjustment ?? null,
        //salesRevenue: props.rowVals?.salesRevenue ?? 0,
        writeOffDuration: props.rowVals?.writeOffDuration ?? 0,
        customerEditable: props.rowVals?.customerEditable ?? false,
    });

    useEffect(() => {
        httpclientService.get(`/api/settings/settingsequipments/getequiclassesfordropdown`).then((response) => {
            if (response && response.length > 0) {
                var equiClassID = props.rowVals?.equiClassID ?? state?.equiclassid ?? -1;
                setEquiClasses(response.map(x => {
                    return {
                        ...x,
                        disabled: (x.id !== equiClassID)
                    };
                }));
            }
        });
    }, []);

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsequipments/saveequitype", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(-1);
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(-1);
        }
    }

    const [schema, setSchema] = useState({
        description: Yup.string()
            .required(t("GenericRequiredText"))
            .max(60, t('Error_TextTooLong', { 1: 60 })),
        equiClassID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        avgLifeTime: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        installationCosts: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        disposalCosts: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        newPrice: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        price: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        //annualPriceAdjustment: Yup.number()
        //    .required(t("GenericRequiredText"))
        //    .min(1),
        //salesRevenue: Yup.number()
        //    .required(t("GenericRequiredText"))
        //    .min(1),
        writeOffDuration: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("EquiType")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={t("Description")} name="description" required />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisDropdown labeltext={t("EquiClass")} name="equiClassID" items={equiClasses} required />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={t("EquiType_AvgLifeTime")} name="avgLifeTime" required />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisDateTimePicker label={t("EquiType_SparePartAvailability")} name="sparePartAvailability" />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={t("InstallationCosts")} name="installationCosts" type="number" required />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={t("EquiType_DisposalCosts")} name="disposalCosts" type="number" required />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={t("EquiType_NewPrice")} name="newPrice" type="number" required />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={t("EquiType_Price")} name="price" type="number" required />
                                </OmisCol>
                                {/*<OmisCol xs={4}>*/}
                                {/*    <OmisTextBox labeltext={t("EquiType_AnnualPriceAdjustment")} name="annualPriceAdjustment" type="number" required />*/}
                                {/*</OmisCol>*/}
                                {/*<OmisCol xs={4}>*/}
                                {/*    <OmisDateTimePicker label={t("EquiType_LastPriceAdjustment")} name="lastPriceAdjustment" />*/}
                                {/*</OmisCol>*/}
                                {/*<OmisCol xs={4}>*/}
                                {/*    <OmisTextBox labeltext={t("EquiType_SalesRevenue")} name="salesRevenue" type="number" required />*/}
                                {/*</OmisCol>*/}
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={t("EquiType_WriteOffDuration")} name="writeOffDuration" type="number" required />
                                </OmisCol>
                                {
                                    AuthService.isFSMMaster() ?
                                        <OmisRow>
                                            <OmisCol xs={4}>
                                                <OmisCheckBox labeltext={t("CustomerEditable")} name="customerEditable" />
                                            </OmisCol>
                                        </OmisRow>
                                        :
                                        null
                                }
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}