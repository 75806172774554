export const TCustomer = {
    CUSTOMER_OMV_AT: "100000",
    CUSTOMER_HPV: "10",
    CUSTOMER_SUEDRAMOL: "30",
    CUSTOMER_TEST: "110000",
    CUSTOMER_DEMO_POLSKA: "Polska",
    CUSTOMER_DEMO_SF: "120000",
    CUSTOMER_INIT: "INIT",
    CUSTOMER_MIGROL: "MIGROL",
    CUSTOMER_WORKSHOP: "Workshop",
    CUSTOMER_RHEINLAND: "100",
    CUSTOMER_BAVARIA: "Bavaria",
    CUSTOMER_PICKEL: "Pickel",
    CUSTOMER_DEMO_TS: "Tankstelle",
    CUSTOMER_DEMO_IMMO: "Immobilie",
    CUSTOMER_DEMO_IND: "Industrie",
    CUSTOMER_DEMO_LOGISTICS: "Logistik",
    CUSTOMER_DEMO_FIL: "Filialist",
    CUSTOMER_BELL: "Bell",
    CUSTOMER_PETROTEC: "Petrotec",
    CUSTOMER_MOVERI: "MOVERI",
    CUSTOMER_BAYWA: "BAYWA",
    CUSTOMER_MIGROLINO: "MIGROLINO",
    CUSTOMER_SUPPORT: "Support",
    CUSTOMER_COOP: "Coop",
    CUSTOMER_FOERSTER: "Foerster",
    CUSTOMER_TESSOL: "Tessol",
    CUSTOMER_Q1: "Q1",
    CUSTOMER_VIGO: "ViGo",
    CUSTOMER_CALPAM: "Calpam"
}