import React from "react";
import { t } from "i18next";
import { Link as RouterLink } from "react-router-dom";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisIconButton } from "../../shared/OmisButtons";
import { OmisGrid } from "../../shared/OmisGrids";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { OmisCol } from "../../shared/OmisLayouts";

var state = { selectedtabvalue: TabConstants.System }

export function SettingsRoles(props) {
    var url = "/api/settings/system/getroles";
    return (
        <div className="omis-header-box">
            <OmisCol xs={1}>
                <OmisIconButton id={"backButton"}
                    iconcontent={<ArrowBackIcon fontSize="inherit" />}
                    text="Back" component={RouterLink} to={"/settings"} state={state} />
            </OmisCol>

            <OmisGrid
                headerText={t("Settings_Roles")}
                apiUrl={url}
                noExportButton
                hasdetailview
                detailsroute="/settings/system/roledetails"
                {...props} />
        </div>
    );
}