// imports the React Javascript Library
import React, { useState } from "react";
//Card
import { Card, CardActionArea, CardContent } from "@mui/material";
import "./OmisImageUploader.css" 
import { OmisButtonUploadImages } from "./OmisButtons";
import { useEffect } from "react";
import { OmisCol, OmisContainer, OmisRow } from "./OmisLayouts";

function OmisImageUploader (props){

    const [image, setImage] = useState({
        mainState: "initial", // initial, uploaded
        imageUploaded: 0,
        selectedFile: null
    });

    const [src, setSrc] = useState(null);

    useEffect(() => {
        if (image.selectedFile) {
            const reader = new FileReader();
            var url = reader.readAsDataURL(image.selectedFile);

            reader.onloadend = function (e) {
                setSrc([reader.result]);
            }.bind(this);
        }
    },[image]);

    const handleUploadClick = event => {
        var file = event.target.files[0];      

        setImage({
            mainState: "uploaded",
            selectedFile: file,
            imageUploaded: 1
        });

        if (props.handleUploadClick) {
            return props.handleUploadClick(file);
        }
    };

    function renderInitialState() {

        return (
            <React.Fragment>
                <CardContent>
                    <OmisContainer fluid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <OmisRow>
                            <OmisCol style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <OmisButtonUploadImages isimage={1} id="contained-button-file" onChange={handleUploadClick} />
                            </OmisCol>
                        </OmisRow>
                    </OmisContainer>
                </CardContent>
            </React.Fragment>
        );
    }

    function renderUploadedState() {
        return (
            <React.Fragment>
                <CardActionArea onClick={imageResetHandler}>
                    <img
                        width="50%"
                        height="100px;"
                        className={"media"}
                        src={src}
                    />
                </CardActionArea>
            </React.Fragment>
        );
    }

    const imageResetHandler = () => {
        setImage({
            mainState: "initial",
            selectedFile: null,
            imageUploaded: 0
        });
    };

    return (
        <React.Fragment>
            <div className={"root"}>
                <Card className={"cardName"}>
                    {(image.mainState === "initial" && renderInitialState()) ||
                        (image.mainState === "uploaded" &&
                            renderUploadedState())}
                </Card>
            </div>
        </React.Fragment>
    );
}

export default OmisImageUploader;
