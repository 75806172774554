import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonNewItem, OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";
import { OmisDropdown, OmisDropdownMultiselectV2 } from "../../shared/OmisDropdowns";
import { OmisDialog, OmisLabel } from "../../shared/OmisDisplays";
import { CreateSkill } from "./CreateSkill";

export function AddCompanySiteSkills(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);

    const [companySites, setCompanySites] = useState([]);
    const [initialValues, setInitialValues] = useState({
        companySiteID: props.companySiteID ?? props.rowVals?.companySiteID ?? null,
        skillIDs: props.skillIDs ?? props.rowVals?.skillIDs ?? null,
    });
    const [skills, setSkills] = useState([]);

    useEffect(() => {

        httpclientService.get("api/inspections/getcompanysitesfordropdown").then((response) => {
            if (response && response.length > 0) {
                setCompanySites(response);
            }
        });

        httpclientService.get(`/api/settings/companysite/getskillsfordropdown?removeFromListByCompanySiteID=${initialValues.companySiteID}`).then((response) => {
            if (response) {
                setSkills(response);
            }
        });
    },[]);

    function handleSave(values) {
        httpclientService.post("/api/settings/companysite/savecompanysiteskills", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate("/settings/companysite/skills");
                }
            }
        });
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate("/settings/companysite/skills");
        }
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    function handleSkillSelectionChange(event) {
        const {
            target: { value },
        } = event;

        // On autofill we get a stringified value.
        var _newArr = typeof value === 'string' ? value.split(',') : value;
        formRef.current.setFieldValue("skillIDs", _newArr.sort((a, b) => a - b));
    }

    function handleNewSkillClick(rowID, rowVals) {
        if (rowID && typeof (rowID) === 'number') {
            setModalTitle(t("Action_Edit"));
        } else {
            setModalTitle("");
        }
        setModalContent(<CreateSkill onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID && typeof (rowID) === 'number' ? rowID : null} rowVals={rowVals} companySiteIDs={props.companySiteID ? [props.companySiteID] : []} />);
        handleModalOpen();
    }

    const [schema, setSchema] = useState({
        companySiteID: Yup.string()
            .required(t("GenericRequiredText"))
            .min(1, t('GenericRequiredText')),
        skillIDs: Yup.array()
            .required(t("GenericRequiredText"))
            .min(1, t('GenericRequiredText')),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("Skills")}</h1>
                                :
                                null
                        }

                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={12} style={{display:'flex',justifyContent:'right'} }>
                                    <OmisCol xs={3}>
                                        <OmisButtonNewItem id={"newCompanysiteButton"} onClick={handleNewSkillClick} text={t("New_Skill")} />
                                    </OmisCol>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisDropdown id={"companySiteID"} labeltext={t("CompanySite")} name={"companySiteID"} size={"small"} items={companySites} disabled={props.companySiteID ? true:false} />
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisLabel id={"Skillslabel"} label={t("Skills")} required/>
                                    <OmisDropdownMultiselectV2 id="skills" name={"skills"} className="skillsDropDown" items={skills} onChange={handleSkillSelectionChange} value={formik.values.skillIDs} />
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"md"}
                title={modalTitle}
                content={modalContent}
                contentHeight={300}
            />
        </>
    );
}