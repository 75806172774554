import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisAutosizeTextArea, OmisCheckBox, OmisDateTimePicker } from "../shared/OmisInputs";
import { OmisDropdown } from "../shared/OmisDropdowns";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../shared/OmisButtons";
import { OmisLabel } from "../shared/OmisDisplays";
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";

export function DischargeTestDate(props) {

    const navigate = useNavigate();
    const formRef = useRef();
    const [companySites, setCompanySites] = useState([]);
    const [equiList, setEquiList] = useState([]);
    const [initialValues, setInitialValues] = useState({
        ids: props.ids ?? null,
        comment: "",
        dateCompletion: null,
        datePlannedCustom: null,
        companySiteID: null,
        equiID: null,
        createNew: true,
        createNewReport:false
    });

    useEffect(() => {
        httpclientService.get("api/inspections/getcompanysitesfordropdown").then((response) => {
            if (response && response.length > 0) {
                setCompanySites(response);
            }
        });

        httpclientService.get("/api/equipments/getequilistfordropdown").then((response) => {
            if (response) {
                setEquiList(response);
            }
        });

    }, []);

    function handleSave(values) {

        httpclientService.post("/api/inspections/bulkdischarge", values).then((response) => {
            if (response) {
                if (values.createNewReport) {
                    navigate("/reports/create/", { replace: true, state: { ...response } });
                } else {
                    if (props.handleModalClose) {
                        props.handleModalClose();
                    } else {
                        navigate("/inspections");
                    }
                }
            }
        });

    }

    function handleCancel() {
        if (props.handleModalCancel) {
            props.handleModalCancel();
        } else {
            navigate("/inspections");
        }
    }

    const [schema, setSchema] = useState({
        dateCompletion: Yup.date()
            .required(t("GenericRequiredText"))
            .min(1, t('GenericRequiredText')),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, e) => {
                    handleSave(values);
                }}
            >
                {formik => (
                    <Form>
                        <strong>{`${t("Import_YouHaveChosen")} ${props.ids}`}</strong>
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={3}>
                                    <OmisLabel label={t("DateCompletion")} required />
                                    <OmisDateTimePicker name="dateCompletion" required />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <OmisLabel label={t("DatePlannedCustom")} />
                                    <OmisDateTimePicker name="datePlannedCustom" />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <OmisDropdown id={"companySiteID"} labeltext={t("CompanySite")} name={"companySiteID"} size={"small"} items={companySites} />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <br />
                                    <OmisCheckBox labeltext={t("TestDate_CreateNew")} name="createNew" />
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisAutosizeTextArea labeltext={t("Comment")} name="comment" />
                                </OmisCol>
                                {
                                    equiList.length > 0 ?
                                        <OmisCol xs={6}>
                                            <OmisLabel label={t("Equipment")} />
                                            <OmisDropdown size={"small"} id={"equiID"} name={"equiID"} items={equiList} />
                                        </OmisCol>
                                        :
                                        null
                                }
                            </OmisRow>
                            <br/>
                            <OmisRow>
                                <OmisCol xs={props.ids?.length === 1 ? 4 : 6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                {
                                    props.ids?.length === 1 ?
                                        <OmisCol xs={4}>
                                            <OmisButtonPrimarySubmit id={"dischargaAndCreateButton"} onClick={() => { formRef.current.setFieldValue("createNewReport", true); }} disabled={!formik.isValid} text={t("Action_DischargeAndCreateReport")} />
                                        </OmisCol>
                                        :
                                        null
                                }
                                <OmisCol xs={props.ids?.length === 1 ? 4 : 6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} onClick={() => { formRef.current.setFieldValue("createNewReport", false); }} disabled={!formik.isValid} text={t("Action_Save")} />
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}