import React, { useEffect, useRef, useState } from "react";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { t } from "i18next";
import { Form, Formik } from "formik";
import { OmisCol, OmisRow } from "../../shared/OmisLayouts";
import { OmisButtonPrimarySubmit } from "../../shared/OmisButtons";
import { OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisDropdownMultiselectV2 } from "../../shared/OmisDropdowns";

var api = "/api/profile/";

export default function AddUserModule(props) {

    const navigate = useNavigate();

    const formRef = useRef();
    const [initialValues, setInitialValues] = useState({
        userID: props.userID ?? null,
        modules: [],
    });

    const [modules, setModules] = useState([]);
    const [selectedModules, setSelectedModules] = useState([]);

    useEffect(() => {
        httpclientService.get(`${api}getmodulesfordropdown?userIDForRemove=${props.userID}`).then((response) => {
            if (response && response.length>0) {
                setModules(response);
            }
        });
    }, []);

    const validationSchema = Yup.object({
        userID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1, t('GenericRequiredText')),
        modules: Yup.array()
            .min(1, t('GenericRequiredText')),
    });

    function handleAddUserModule(values) {
        httpclientService.post(`${api}addusermodule`, values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(-1);
                }
            }
        });
    }

    function handleModuleChange(event) {
        const {
            target: { value },
        } = event;

        // On autofill we get a stringified value.
        var _newArr = typeof value === 'string' ? value.split(',') : value;//gives selected values in array    
        setSelectedModules(_newArr.sort((a, b) => a - b));
        formRef.current.setFieldValue("modules", _newArr.sort((a, b) => a - b));
    }

    function handleCancel() {
        if (props.onCancel) {
            props.onCancel();
        } else {
            navigate(-1);
        }
    }

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    handleAddUserModule(values)
                }}
            >
                {formik => (
                    <Form>
                        <OmisRow>
                            <OmisCol xs={6}>
                                <OmisDropdownMultiselectV2 id="modules" name={"modules"} className="modulesDropDown" items={modules} onChange={handleModuleChange} value={selectedModules} labeltext={t("Modules")} required />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol xs={6}>
                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel} />
                            </OmisCol> 
                            <OmisCol xs={6}>
                                <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                            </OmisCol>                            
                        </OmisRow>
                    </Form>)}
            </Formik>           
        </>
    );
};