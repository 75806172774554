import { Grid } from "@mui/material";
import { DetailsCardWithSkeleton } from "../shared/Shared";
import { InspectionDetailsInfo } from "./InspectionDetailsInfo";
import React from "react";

export function TabContent_Details(props) {
    const state = props.state;

    return (
        <DetailsCardWithSkeleton
            url={`/api/inspections/details?id=${state?.inspectionid}`}
            skeletonheight={"200px"}
            content={
                <InspectionDetailsInfo {...props} />}{...props} />
    );
}