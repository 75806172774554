import { OmisGrid } from "../shared/OmisGrids";
import React, { useEffect, useRef, useState } from "react";
import httpclientService from "../../services/httpclient.service";
import * as Yup from "yup";
import { t } from "i18next";
import { Form, Formik } from "formik";
import { Card, IconButton } from "@mui/material";
import Icon from "@mdi/react";
import { mdiTrashCan } from "@mdi/js";
import { OmisButtonPrimary, OmisButtonPrimarySubmit, OmisButtonSecondary, OmisProgress } from "../shared/OmisButtons";
import { useLocation, useNavigate } from "react-router-dom";
import { OmisAutosizeTextArea, OmisCheckBox, OmisDatePicker, OmisSliderNoForm, OmisTextBox } from "../shared/OmisInputs";
import { OmisDropdown } from "../shared/OmisDropdowns";
import { OmisLabel, OmisSelectedCatalogueCard } from "../shared/OmisDisplays";
import { DocumentUploadForNewReport } from "../documents/Documents";
import { DMSDocumentReferer } from "../../constants/DMSDocumentReferer";
import DynamicStepBuilder from "./DynamicStepBuilder";
import AuthService from "../../services/auth.service";
import { TRole } from "../../constants/RoleConstants";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../shared/OmisLayouts";

export function InspectionsCreate(props) {

    const formRef = useRef();
    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState(1);
    const [selectionStep, setSelectionStep] = useState(1);

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [checkedShowEquipment, setCheckedShowEquipment] = useState(false);

    const [selectedObjectID, setSelectedObjectID] = useState(null);
    const [selectedObjectText, setSelectedObjectText] = useState('');
    const [selectedEquiID, setSelectedEquiID] = useState(null);
    const [selectedEquipmentText, setSelectedEquipmentText] = useState('');
    const [selectedEquiIDBackUp, setSelectedEquiIDBackUp] = useState(null);

    const [types, setTypes] = useState([]);
    const [companySites, setCompanySites] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [testDateOriginList, setTestDateOriginList] = useState([]);
    const [equiClassList, setEquiClassList] = useState([]);


    const SelectionStep = {
        Object: 1,
        Equipment: 2,
        Done: 3
    }

    const location = useLocation();

    const [initialValues, setInitialValues] = useState({
        tdid: props.tdid ?? null,
        totdID: props.totdID ?? null,
        objectID: selectedObjectID,
        equiID: selectedEquiID,
        companySiteID: null,
        description: "",
        checklistTester: "",
        comment: "",
        idExtern:"",
        periodicity: 0,
        periodicityEnum: null,
        allowedTimeBefore: 0,
        allowedTimeAfter: 0,
        testDateOriginID: null,
        equiClassID: null,
        customerEditable: true,
        setToLastDayOfMonth: false,
        datePlanned: null,
        datePlannedCustom: null,
        dateCompletion: null,
        lastDateCompletion: null,
        deficiencyCleared: null,
        deficiency: false,
        allowedYear: false,
        attachedFiles: [],
        checklistCompatible: false,
        checkListItems: [],
    });


    useEffect(() => {
        httpclientService.get("api/shared/getperiodselectionlist").then((response) => {
            setPeriods(response);
        });

        httpclientService.get("api/inspections/gettypes").then((response) => {
            if (response && response.length > 0) {
                setTypes(response);
            }
        });

        httpclientService.get("api/inspections/getcompanysitesfordropdown").then((response) => {
            if (response && response.length > 0) {
                setCompanySites(response);
            }
        });

        httpclientService.get("/api/inspections/gettestdateoriginlist").then((response) => {
            if (response && response.length > 0) {
                setTestDateOriginList(response);
            }
        });

        httpclientService.get("/api/equipment/equiclass/getequiclassesfordropdown").then((response) => {
            if (response && response.length > 0) {
                setEquiClassList(response);
            }
        });

        if (props.tdid && props.tdid > 0) {
            httpclientService.get(`/api/inspections/getdetailsforedit?id=${props.tdid}`).then((response) => {
                if (response) {
                    setInitialValues(response);
                    //formRef.current.setValues(response);
                    setSelectedObjectID(response.objectID ?? null);
                    setSelectedObjectText(response.objectNrInternal ?? "");
                    setSelectedEquiID(response.equiID ?? null);
                    setSelectedEquipmentText(response.equiDescription ?? "");
                    setSelectedEquiIDBackUp(response.equiID ?? null);
                    setSelectionStep(SelectionStep.Done);
                    //formRef.current.validateForm();
                }
            });
        }

    }, []);

    function handleUploadedFiles(file) {
        var newUploadedFiles = [...uploadedFiles, ...file];
        setUploadedFiles(newUploadedFiles);
        formRef.current.setFieldValue("attachedFiles", newUploadedFiles);
    }

    function deleteFileButtonClicked(file) {
        var filteredSelectedFiles = uploadedFiles.filter(s => s.name !== file);
        setUploadedFiles(filteredSelectedFiles);
        formRef.current.setFieldValue("attachedFiles", filteredSelectedFiles);
    }

    useEffect(() => {
        if (checkedShowEquipment) {
            if (!selectedEquiID) {
                setSelectedEquiID(selectedEquiIDBackUp);
                formRef.current.setFieldValue("selectedEquiID", selectedEquiIDBackUp);
            }
        } else {
            if (selectedEquiID) {
                setSelectedEquiIDBackUp(selectedEquiID);
            }
            setSelectedEquiID(null);
            formRef.current.setFieldValue("selectedEquiID", null);
        }
    }, [checkedShowEquipment]);

    function totdChange(e) {
        var selectedTOTD = types.filter(x => x.key === e.target.value)[0];
        formRef.current.setFieldValue("periodicity", selectedTOTD?.periodicity ?? 0);
        formRef.current.setFieldValue("periodicityEnum", selectedTOTD?.periodicityType ?? null);
        formRef.current.setFieldValue("setToLastDayOfMonth", selectedTOTD?.setToLastDayOfMonth ?? null);
        formRef.current.setFieldValue("allowedTimeBefore", selectedTOTD?.allowedTimeBefore ?? null);
        formRef.current.setFieldValue("allowedTimeAfter", selectedTOTD?.allowedTimeAfter ?? null);
        formRef.current.setFieldValue("allowedYear", selectedTOTD?.allowedYear ?? null);
        formRef.current.setFieldValue("checklistCompatible", selectedTOTD?.checklistCompatible ?? null);
        if (testDateOriginList.length > 0) {
            formRef.current.setFieldValue("testDateOriginID", selectedTOTD?.testDateOriginID ?? null);
        }

        if (equiClassList.length > 0) {
            formRef.current.setFieldValue("equiClassID", selectedTOTD?.equiClassID ?? null);
        }
    }

    function handleCreateInspection(values) {
        //handleModalOpen();
        if (!values.checklistCompatible) {
            values.checkListItems = null;
        }

        httpclientService.post("/api/inspections/createinspection", values).then((response) => {
            console.log(response.statusCode);
            //handleModalClose();
            if (response.statusCode === 200) {
                if (props.handleModalClose) {
                    props.handleModalClose();
                }else {
                    navigate("/inspections");
                }
            }
        });
    }

    function handleDeselect(step) {

        if (step <= SelectionStep.Object) {
            setSelectedObjectID(null);
            setSelectedObjectText('');
            formRef.current.setFieldValue("objectID",null);
        }

        if (step <= SelectionStep.Equipment) {
            setCheckedShowEquipment(false);
            setSelectedEquiID(null);
            setSelectedEquiIDBackUp(null);
            setSelectedEquipmentText('');
            formRef.current.setFieldValue("equiID", null);
        }

        setSelectionStep(step);
    }

    function CatalogueGrid(props) {
        const { step } = { ...props };

        switch (step) {
            case SelectionStep.Object:
                return (
                    <ObjectGrid
                        handleObjectRowSelectionCallback={handleObjectRowSelectionCallback}
                        selectedObjectID={selectedObjectID}
                        step={selectionStep}
                    />
                );
            case SelectionStep.Equipment:
                return (
                    checkedShowEquipment ?
                        <EquipmentGrid handleEquiRowSelectionCallback={handleEquiRowSelectionCallback}
                            selectedObjectID={selectedObjectID}
                            selectedEquiID={selectedEquiIDBackUp ?? selectedEquiID}
                            noHeader
                        />
                        :
                        null
                );
            default:
                break;
        }
    }

    function handleCancel() {
        if (props.handleModalCancel) {
            props.handleModalCancel();
        } else if (`${location.pathname}`.includes("/inspections")) {
            navigate("/inspections");
        } else {
            navigate(-1);
        }
    }

    const validationSchema = Yup.object({
        objectID: Yup.number()
            .required(t("GenericRequiredText"))
            .typeError(t("GenericRequiredText"))
            .min(1),
        totdID: Yup.number()
            .required(t("GenericRequiredText"))
            .typeError(t("GenericRequiredText"))
            .min(1),
        periodicity: Yup.number()
            .required(t("GenericRequiredText"))
            .typeError(t("GenericRequiredText"))
            .min(1),
        periodicityEnum: Yup.number()
            .required(t("GenericRequiredText"))
            .typeError(t("GenericRequiredText"))
            .min(1),
        allowedTimeBefore: Yup.number()
            .typeError(t("GenericRequiredText"))
            .min(0, t("GenericErrorText")),
        allowedTimeAfter: Yup.number()
            .typeError(t("GenericRequiredText"))
            .min(0, t("GenericErrorText")),
        datePlanned: Yup.date()
            .typeError(t("GenericRequiredText"))
            .required(t("GenericRequiredText")),
        checklistTester: Yup.string()
            .max(500, t("Error_TextTooLong", { 1: 500 })),
        comment: Yup.string()
            .max(1000, t("Error_TextTooLong", { 1: 1000 })),
    });

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values) => { handleCreateInspection(values) }}>
                {formik => (
                    <Form>
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={6} >
                                    {
                                        props.id || props.tdid ?
                                            null
                                            :
                                            <h1>
                                                {t("TestDate_CreateNew")}
                                            </h1>
                                    }
                                </OmisCol>
                                <OmisCol style={{ display: 'flex', justifyContent: 'right' }} xs={6}>
                                    {
                                        currentStep === 1 ?
                                            <>
                                                <OmisStack direction="horizontal" gap={1}>
                                                    <div className="p-2"><OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary></div>
                                                    {
                                                        formik.values.checklistCompatible ?
                                                            <div className="p-2">
                                                                <OmisButtonPrimary id={"nextStepButton"} disabled={!formik.isValid} text={t("NextStep")} onClick={() => setCurrentStep(2)} />
                                                            </div>
                                                            :
                                                            <div className="p-2">
                                                                <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")} />
                                                            </div>
                                                    }
                                                </OmisStack>

                                            </>
                                            :
                                            <>
                                                <OmisStack direction="horizontal" gap={1}>
                                                    <div className="p-2"><OmisButtonSecondary id={"previousStepButton"} disabled={!formik.isValid} text={t("PreviousStep")} onClick={() => setCurrentStep(1)}></OmisButtonSecondary></div>
                                                    <div className="p-2"><OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit></div>
                                                </OmisStack>
                                            </>
                                    }
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol>
                                    <OmisProgress variant="determinate" value={formik.isValid ? 100 : 0} />(1) {t("BaseDetails")}
                                </OmisCol>
                                {
                                    formik.values.checklistCompatible ?
                                        <OmisCol>
                                            <OmisProgress variant="determinate" value={currentStep === 2 && formik.isValid ? 100 : 0} />(2) {t("Checklists")}
                                        </OmisCol>
                                        :
                                        null
                                }
                            </OmisRow>
                        </OmisContainer>
                        {
                            currentStep === 1 ?
                                <>
                                    {/*
                                    <h3>
                                        {t("Details")}
                                    </h3>
                                    */ }
                                    <Card variant={"outlined"}>
                                        <OmisRow>
                                            <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'left' }}>
                                                <Card variant={"outlined"}>
                                                    <OmisRow>
                                                        <OmisAutosizeTextArea labeltext={t("Description")} name={"description"} placeholder={t("Description")} />
                                                    </OmisRow>
                                                    <br />
                                                    <OmisRow>
                                                        {
                                                            testDateOriginList.length > 0 ?
                                                                <OmisCol xs={6}>
                                                                    <OmisDropdown labeltext={t("TestDateOrigin")} name={"testDateOriginID"} size={"small"} items={testDateOriginList} disabled={initialValues.testdateorigin} />
                                                                </OmisCol>
                                                                :
                                                                null
                                                        }
                                                        <OmisCol xs={6}>
                                                            <OmisDropdown labeltext={t("TypeOfTestDate")} name={"totdID"} size={"small"} items={types} onChange={totdChange} required />
                                                        </OmisCol>
                                                        <OmisCol xs={6}>
                                                            <OmisDropdown labeltext={t("EquiClass")} name={"equiClassID"} size={"small"} items={equiClassList} disabled />
                                                        </OmisCol>
                                                        <OmisCol xs={6}>
                                                            <OmisDropdown labeltext={t("CompanySite")} name={"companySiteID"} size={"small"} items={companySites} />
                                                        </OmisCol>
                                                        <OmisCol xs={6}>
                                                            <OmisTextBox labeltext={t("CheckList_Tester")} name={"checklistTester"} placeholder={t("CheckList_Tester")} />
                                                        </OmisCol>
                                                        <OmisCol xs={6}>
                                                            <OmisTextBox labeltext={t("Comment")} name="comment" />
                                                        </OmisCol>
                                                    </OmisRow>
                                                </Card>
                                            </OmisCol>
                                            <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'right' }}>
                                                <Card variant={"outlined"}>

                                                    <OmisRow>
                                                        <OmisCol xs={6}>
                                                            <OmisTextBox labeltext={t("Periodicity")} name={"periodicity"} placeholder={t("Periodicity")} type="number" disabled required />
                                                        </OmisCol>
                                                        <OmisCol xs={6}>
                                                            <OmisDropdown labeltext={t("SensorData_Unit")} name={"periodicityEnum"} size={"small"} items={periods} disabled required />
                                                        </OmisCol>
                                                    </OmisRow>
                                                    <OmisRow>
                                                        <OmisCol xs={6}>
                                                            <OmisTextBox labeltext={t("TestDate_AllowedTimeBefore")} name="allowedTimeBefore" type="number" disabled />
                                                        </OmisCol>
                                                        <OmisCol xs={6}>
                                                            <OmisTextBox labeltext={t("TestDate_AllowedTimeAfter")} name="allowedTimeAfter" type="number" disabled />
                                                        </OmisCol>
                                                    </OmisRow>
                                                    <OmisRow>
                                                        <OmisCol xs={6}>
                                                            <OmisLabel label={t("DatePlanned")} required />
                                                            <OmisDatePicker id={"datePlanned"} name={"datePlanned"} />
                                                        </OmisCol>
                                                        {
                                                            AuthService.hasRole(TRole.TestdatesPlannedDateCustom) ?
                                                                <OmisCol xs={6}>
                                                                    <OmisLabel label={t("DatePlannedCustom")} />
                                                                    <OmisDatePicker id={"datePlannedCustom"} name={"datePlannedCustom"} />
                                                                </OmisCol>
                                                                :
                                                                null
                                                        }
                                                        <OmisCol xs={6}>
                                                            <OmisLabel label={t("DateCompletion")} />
                                                            <OmisDatePicker id={"dateCompletion"} name={"dateCompletion"} />
                                                        </OmisCol>
                                                        <OmisCol xs={6}>
                                                            <OmisLabel label={t("LastDateCompletion")} />
                                                            <OmisDatePicker id={"lastDateCompletion"} name={"lastDateCompletion"} />
                                                        </OmisCol>
                                                        <OmisCol xs={6}>
                                                            <OmisLabel label={t("DeficiencyCleared")} />
                                                            <OmisDatePicker id={"deficiencyCleared"} name={"deficiencyCleared"} />
                                                        </OmisCol>
                                                        <OmisCol xs={6}>
                                                            <OmisTextBox labeltext={t("IDExtern")} name="idExtern" />
                                                        </OmisCol>
                                                        {
                                                            AuthService.isFSMMaster() ?
                                                                <OmisCol xs={6}><br />
                                                                    <OmisCheckBox labeltext={t("CustomerEditable")} name="customerEditable" />
                                                                </OmisCol>
                                                                :
                                                                null
                                                        }
                                                    </OmisRow>
                                                </Card>
                                            </OmisCol>
                                        </OmisRow>
                                    </Card>
                                    <br />
                                    <Card variant={"outlined"}>
                                        <OmisContainer fluid style={{ display: 'flex', justifyContent: 'center' }} className="faultReportCreateDetailsBox">
                                            <OmisRow style={{ display: 'flex', justifyContent: 'center' }}>
                                                <OmisCol xs={8}>
                                                    <DocumentUploadForNewReport multiFilesUploadCallback={handleUploadedFiles} referer={DMSDocumentReferer.TestDate} />
                                                </OmisCol>
                                                <OmisCol style={{ justifyContent: 'center', marginLeft: '3.5em' }} xs={8}>
                                                    {
                                                        uploadedFiles && uploadedFiles.length > 0 ?
                                                            <>
                                                                <span>{`${t("Import_UploadedFiles")} :`}</span>
                                                                {
                                                                    uploadedFiles.map((file, index) =>
                                                                        <OmisCol key={index.toString()} item xs={12}>
                                                                            <strong>{file.name}</strong>
                                                                            <IconButton id={"deleteUploadedFileButton"} variant={"outlined"}
                                                                                color={"error"}
                                                                                onClick={() => deleteFileButtonClicked(file.name)}
                                                                            >
                                                                                <Icon path={mdiTrashCan} size={1} />
                                                                            </IconButton>
                                                                        </OmisCol>
                                                                    )
                                                                }
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </OmisCol>
                                            </OmisRow>
                                        </OmisContainer>
                                    </Card>
                                    <br />

                                    <Card variant={"outlined"}>
                                        <OmisContainer>
                                            <OmisRow>
                                                <OmisCol xs={4}>
                                                    <OmisSelectedCatalogueCard
                                                        text={`${t("Object")} (${selectedObjectText})`}
                                                        id={selectedObjectID}
                                                        selectionstep={SelectionStep.Object}
                                                        handleDeselect={handleDeselect.bind(this, SelectionStep.Object)}
                                                        required
                                                    />
                                                </OmisCol>
                                                <OmisCol xs={4}>
                                                    {
                                                        selectedEquiID ?
                                                            <OmisSelectedCatalogueCard
                                                                text={`${t("Equipment")} (${selectedEquipmentText})`}
                                                                id={selectedEquiID}
                                                                selectionstep={SelectionStep.Equipment}
                                                                handleDeselect={handleDeselect.bind(this, SelectionStep.Equipment)}
                                                            />
                                                            :
                                                            null
                                                    }
                                                    {
                                                        !selectedEquiID ?
                                                            <OmisCol xs={8}>
                                                                <Card>
                                                                    <OmisSliderNoForm
                                                                        checked={checkedShowEquipment}
                                                                        onChange={(event) => setCheckedShowEquipment(event.target.checked)}
                                                                        labeltext={`${t("Equipment")} (${selectedEquipmentText})`} />
                                                                </Card>
                                                            </OmisCol>
                                                            :
                                                            null
                                                    }
                                                </OmisCol>
                                            </OmisRow>
                                            <OmisRow>
                                                <OmisContainer fluid style={{ marginTop: '2em !important' }}>
                                                    <CatalogueGrid step={selectionStep} />
                                                </OmisContainer>
                                            </OmisRow>
                                        </OmisContainer>
                                    </Card>
                                </>
                                :
                                currentStep === 2 ?
                                    <>
                                        <DynamicStepBuilder steps={formik.values.checkListItems} checklistCompatible={formik.values.checklistCompatible} handleCheckListItemsChange={handleCheckListItemsChange} handleIsCheckListSliderChange={handleCheckListSliderChange} />
                                    </>
                                    :
                                    null
                        }
                    </Form>
                )}
            </Formik>
        </>
    );

    function handleObjectRowSelectionCallback(row) {
        setSelectedObjectID(row?.id ?? null);
        setSelectedObjectText(row?.objectnrinternal ?? '');
        setSelectionStep(SelectionStep.Equipment);
        formRef.current.setFieldValue("objectID", row?.id ?? null);
    }

    function handleCheckListSliderChange(isSliderUnchecked) {
        formRef.current.setFieldValue("checklistCompatible", isSliderUnchecked);        
    }

    function handleCheckListItemsChange(checkListItems) {
        formRef.current.setFieldValue("checkListItems", checkListItems);
    }

    function handleEquiRowSelectionCallback(row) {
        setSelectedEquiID(row?.id ?? null);
        setSelectedEquipmentText(row?.equidescription ?? '');
        setSelectionStep(SelectionStep.Done);
        formRef.current.setFieldValue("equiID", row?.id ?? null ?? null);
    }
}

function ObjectGrid(props) {
    return (
        <OmisGrid
            apiUrl={"/api/objects/getobjectlist"}
            noHeader
            hideSavedFilters
            rowclickCallback={ props.handleObjectRowSelectionCallback }
        />

    );
}

function EquipmentGrid(props) {
    return (
        <OmisGrid
            apiUrl={`/api/equipments/getequipmentlist?objectid=${props.selectedObjectID}`}
            noHeader
            hideSavedFilters
            rowclickCallback={ props.handleEquiRowSelectionCallback }
        />);
}