import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { t } from "i18next";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisIconButton } from "../../shared/OmisButtons";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { SettingsObjectContactPersons } from "./SettingsObjectContactPerson";
import { SettingsObjectAttributes } from "./SettingsObjectAttribute";
import { OmisCol, OmisTabs } from "../../shared/OmisLayouts";
import { Object_TabContent_Details } from "../../objects/Object_TabContent_Details";
import { SettingsObjectUsers } from "./SettingsObjectUsers";
import { SettingsObjectOpeningTimes } from "./SettingsObjectOpeningTimes";
import { SettingsObjectContractors } from "./SettingsObjectCompanySites";

export function SettingsObjectsDetails(props) {
    var { state } = useLocation();

    const [selectedTabValue, setSelectedTabValue] = useState(0);

    useEffect(() => {
        if (state && state.selectedObjectAttributeTabValue) {
            if (/[0-9]/.test(state.selectedObjectAttributeTabValue)) {
                setSelectedTabValue(parseInt(state.selectedObjectAttributeTabValue));
            } else {
                setSelectedTabValue(0);
            }
        }
    }, []);

    const tabs = [
        {
            title: t("User"),
            content: <SettingsObjectUsers objectid={props.objectid ?? state?.objectid} />
        },
        {
            iconposition: "start",
            title: t("Object_ContactPersonList"),
            content: <SettingsObjectContactPersons objectid={props.objectid ?? state?.objectid} />,
        },
        {
            title: t("Settings_ObjectContractor"),
            content: <SettingsObjectContractors objectid={props.objectid ?? state?.objectid} />,
        },
        {
            title: t("OpeningTime"),
            content: <SettingsObjectOpeningTimes objectid={props.objectid ?? state?.objectid} />,
        },
        {
            title: t("ObjectAttributes_Title"),
            content: <SettingsObjectAttributes objectid={props.objectid ?? state?.objectid} isobjectdetails/>,
        },
    ]

    function tabChanged(e) {
        setSelectedTabValue(e);
    }

    return (
        <>
            <div className="omis-header-box">
                <OmisCol xs={1}>
                    <OmisIconButton id={"backButton"}
                        iconcontent={<ArrowBackIcon fontSize="inherit" />}
                        text="Back" component={RouterLink} to={"/settings"} state={{ selectedtabvalue: TabConstants.Objects }}/>
                </OmisCol>
                <OmisCol xs={12}>
                    <Object_TabContent_Details hidecontactlist hideopeningtimes state={state} />
                </OmisCol>
                <OmisCol xs={12}>
                    <OmisTabs tabs={tabs} onChange={tabChanged} tabvalue={selectedTabValue} />
                </OmisCol>
            </div>
        </>
    );
}