import React, { useState } from "react";
import { t } from "i18next";
import httpClientService from "../../../services/httpclient.service";
import { Link as RouterLink } from "react-router-dom";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisButtonNewItem, OmisDropDownMenu, OmisIconButton } from "../../shared/OmisButtons";
import { mdiDotsHorizontal , mdiPencil, mdiTrashCan } from "@mdi/js";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { OmisBox, OmisDialog } from "../../shared/OmisDisplays";
import { DetailsCardWithSkeleton } from "../../shared/Shared";
import { CreateSkill } from "./CreateSkill";
import { OmisContainer, OmisStack } from "../../shared/OmisLayouts";
import { OmisGrid } from "../../shared/OmisGrids";
import { AddCompanySiteSkills } from "./AddCompanySiteSkills";

export function SettingsCompanySiteSkills(props) {
    var url = "/api/settings/companysite/getskills";
    var apiUrl = "/api/settings/companysite/getskillsforgrid";

    if (props.companySiteID) {
        apiUrl = `${apiUrl}?companySiteID=${props.companySiteID}`
    }
    
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    function handleEditClick(rowID,rowVals) {
        if (rowID && typeof (rowID) === 'number' ) {
            setModalTitle(t("Action_Edit"));
        } else {
            setModalTitle("");
        }
        setModalContent(<CreateSkill onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID && typeof (rowID) === 'number' ? rowID : null} rowVals={rowVals} companySiteIDs={props.companySiteID ? [props.companySiteID]:[]} />);
        handleModalOpen();
    }

    function handleAddSkillClick(rowID, rowVals) {
        if (rowID && typeof (rowID) === 'number') {
            setModalTitle(t("Action_Edit"));
        } else {
            setModalTitle("");
        }

        setModalContent(<AddCompanySiteSkills onClose={handleModalClose} handleEditCancel={handleModalCancel} rowVals={rowVals} companySiteID={props.companySiteID} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    const handleDelete = (rowID) => {
        httpClientService.post(`/api/settings/companysite/deletecompanysiteskill?id=${rowID.target?.id ?? rowID}&companySiteID=${props.companySiteID}`).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    }

    const childItemsForDotsMenu = [
        //{
        //    menuName: t("Details"),
        //    toLink: "/settings/companysite/skills/details",
        //    state: { ...props }
        //},
        {
            subMenuIcon: mdiPencil,
            menuName: t("Action_Edit"),
            onClick: handleEditClick
        },
        {
            subMenuIcon: mdiTrashCan,
            menuName: t("Action_Delete"),
            onClick: handleDelete,
            iconclassName: "omis-delete-menuitem"
        }
    ]

    function SkillsRow(values) {
        return (
            <div className="omis-header-box">
                <OmisContainer fluid>
                    <OmisStack direction="horizontal" gap={3}>
                        <div className="p-1">
                            <OmisIconButton id={"backButton"}
                                iconcontent={<ArrowBackIcon fontSize="inherit" />}
                                text="Back" component={RouterLink} to={"/settings"} state={{ selectedtabvalue: TabConstants.CompanySites }} />
                        </div>
                        <div className="p-2">
                            <h1>{t("Skills")}</h1>
                        </div>
                        <div className="p-2 ms-auto">
                            <OmisButtonNewItem id={"newButton"} onClick={handleEditClick} text={t("Action_New")} />
                        </div>
                    </OmisStack>
                </OmisContainer>
                <br />
                <br />
                {
                    values && values.data.length > 0 ?
                        values.data.map((skill) => {
                            return (
                                <OmisContainer fluid key={skill.id.toString()}>
                                    <OmisBox >
                                        <OmisStack direction="horizontal" gap={3}>
                                            <div className="p-11">
                                                <strong>{skill.description}</strong>
                                            </div>
                                            <div className="p-1 ms-auto">
                                                <OmisDropDownMenu id={"dotsDropdown"} rowid={skill.id} rowVals={ skill} menuicon={mdiDotsHorizontal} childitems={childItemsForDotsMenu} />
                                            </div>
                                        </OmisStack>
                                    </OmisBox>
                                </OmisContainer>
                            );
                        })
                        :
                        <strong>{t("Grid_Empty")}</strong>
                }
            </div>
        );
    }

    return (
        <>
            {
                props.gridView ?
                    <OmisGrid actiondropdown={{ edit: handleEditClick, delete: handleDelete }} headerText={t("Skills")}
                        newButton={props.companySiteID ? <OmisButtonNewItem id={"newCompanysiteButton"} onClick={handleAddSkillClick} text={t("Action_Add")} /> :<OmisButtonNewItem id={"newCompanysiteButton"} onClick={handleEditClick} text={t("Action_New")} />}
                        apiUrl={apiUrl}
                        change={changeCounter}
                        noExportButton
                        {...props}
                    />
                    :
                    <DetailsCardWithSkeleton
                        url={url}
                        skeletonheight={"200px"}
                        content={<SkillsRow {...props} />}
                        reload={changeCounter}
                        {...props}
                    />
            }

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"md"}
                title={modalTitle}
                content={modalContent}
                contentHeight={300}
            />
        </>
    );
}