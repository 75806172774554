import React from "react";
import { t } from "i18next";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../shared/OmisButtons";
import { OmisCheckBoxNoForm } from "../shared/OmisInputs";
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";

export function InspectionArchivePopup(props) {

    var values = {
        ids: props.ids,
        deleteAttachedDocuments: false
    }

    function handleOnSubmit() {
        if (props.onSubmit) {
            props.onSubmit(values);
        }
    }

    function handleCancel() {
        if (props.handleModalCancel) {
            props.handleModalCancel();
        }
    }

    function handleCheckChanged(e) {
        values.deleteAttachedDocuments = e.target.checked;
    }

    return (
        <OmisContainer fluid>

            <OmisRow>
                <strong>{t("BulkArchive_Text")}</strong>
            </OmisRow>
            <br/>
            <OmisRow>
                <OmisCol xs={6}>
                    <strong>{`${t("Settings_TestDates")}: ${values.ids}`}</strong>
                </OmisCol>
                <OmisCol xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <OmisCheckBoxNoForm labeltext={t("DeleteAttachedDocuments")} value={values.deleteAttachedDocuments} onClick={handleCheckChanged } />
                </OmisCol>
            </OmisRow>
            <br />
            <OmisRow>
                <OmisCol xs={6}>
                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                </OmisCol>
                <OmisCol xs={6}>
                    <OmisButtonPrimarySubmit id={"submitButton"} text={t("Action_Save")} onClick={handleOnSubmit} />
                </OmisCol>
            </OmisRow>
        </OmisContainer>
    );
}