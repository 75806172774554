import { Box, CircularProgress, LinearProgress, Skeleton, Typography } from "@mui/material";
import React from "react";

export function OmisCircularProgress(props) {

    var size = 20;
    if (props.size) {
        size = props.size;
    }

    return (
        <CircularProgress color="primary" size={size} />
    );
}

export function OmisLinearProgress(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export function OmisSkeleton(props) {
    return (
        <Skeleton variant={"rounded"} height={props.height ?? "200px"} />
    );
}