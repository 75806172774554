import React from "react";
import { t } from "i18next";
import {OmisErrorMessage} from "../shared/OmisDisplays";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../shared/OmisLayouts";


export default function NoRoleAssigned() {   

    return (
        <div>
            <OmisContainer fluid style={{ display: 'flex !important', justifyContent: 'center !important' }}>
                <OmisStack gap={1}>
                    <OmisRow>
                        <OmisCol xs={8}>
                            <OmisErrorMessage text={t("Error_NoRoleAssigned")} />
                        </OmisCol>
                        <OmisCol xs={8}>
                            <a id={"logoutButton"} className="font-orange" href="logout">{t("Action_Logout")}</a>
                        </OmisCol>
                    </OmisRow>
                </OmisStack>
            </OmisContainer>
        </div>
    );
}