import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisAutosizeTextArea, OmisCheckBox, OmisDatePicker } from "../../shared/OmisInputs";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import AuthService from "../../../services/auth.service";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateObjectContractor(props) {

    const navigate = useNavigate();
    const formRef = useRef();
    const [initialValues, setInitialValues] = useState({
        objectID: props.objectid ?? props.rowVals?.objectID ?? null,
        companySiteID: props.companySiteID ?? props.rowVals?.companySiteID ?? "",
        comment: props.rowVals?.comment ?? "",
        beginDate: props.rowVals?.beginDate ?? null,
        endDate: props.rowVals?.endDate ?? null,
        customerEditable: props.rowVals?.customereditable ?? true
    });
    const [companySites, setCompanySites] = useState([]);
    const [objectsList, setObjectsList] = useState([]);

    useEffect(() => {

        httpclientService.get(`/api/objects/getobjectlistfordropdown`).then((response) => {
            if (response) {
                setObjectsList(response.map((item) => ({
                    key: item.key,
                    displayText: item.label
                })));
            }
        });

        httpclientService.get(`/api/reports/getsuppliers`).then((response) => {
            if (response) {
                setCompanySites(response.map((item) => ({
                    key: item.id,
                    displayText: item.companyName
                })));
            }
        });
    },[]);

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsobjects/saveobjectcontractor", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate("/settings/objects/objectcontractors");
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate("/settings/objects/objectcontractors");
        }
    }

    const [schema, setSchema] = useState({
        objectID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        companySiteID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            !props.rowVals ?
                                <h1>{`${t("Action_New") } ${t("Settings_ObjectContractor") }`}</h1>
                                :
                                <h1>{t("Action_Edit")}</h1>
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={8}>
                                    <OmisRow>
                                        <OmisCol xs={6}>
                                            <br />
                                            <OmisDropdown size={"small"} id={"objectID"} name={"objectID"} items={objectsList} labeltext={t("Object")} required disabled={props.objectid} />
                                        </OmisCol>
                                        <OmisCol xs={6}>
                                            <br />
                                            <OmisDropdown size={"small"} id={"companySiteID"} name={"companySiteID"} items={companySites} labeltext={t("CompanySite")} required disabled={props.companySiteID} />
                                        </OmisCol>
                                    </OmisRow>
                                    <OmisRow>
                                        <OmisCol xs={6}>
                                            <OmisDatePicker id={"beginDate"} name={"beginDate"} />
                                        </OmisCol>
                                        <OmisCol xs={6}>
                                            <OmisDatePicker id={"endDate"} name={"endDate"} />
                                        </OmisCol>   
                                    </OmisRow>
                                </OmisCol>                                
                                <OmisCol xs={4}>
                                    <br/>
                                    <OmisAutosizeTextArea labeltext={t("Comment") } id={"comment"} name={"comment"} />
                                </OmisCol>                             
                            </OmisRow>
                            <OmisRow>
                                {
                                    AuthService.isFSMMaster() ?
                                        <OmisCol xs={8}>
                                            <OmisRow>
                                                <OmisCol xs={6}>
                                                    <OmisCheckBox labeltext={t("CustomerEditable")} name="customerEditable" />
                                                </OmisCol>
                                            </OmisRow>
                                        </OmisCol>
                                        :
                                        null
                                }
                            </OmisRow>
                            <br />
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}