export const CustomerConstants = {
    CUSTOMER_OMV_AT: "100000",
    CUSTOMER_OMV_RS: "200000",
    CUSTOMER_OMV_RO: "300000",
    CUSTOMER_OMV_BG: "400000",
    CUSTOMER_OMV_DE: "500500",
    CUSTOMER_OMV_SK: "600600",
    CUSTOMER_HPV: "10",
    CUSTOMER_SCHNECK: "20",
    CUSTOMER_SUEDRAMOL: "30",
    CUSTOMER_FORSTINGER: "130000",
    CUSTOMER_TEST: "110000",
    CUSTOMER_DEMO_POLSKA: "Polska",
    CUSTOMER_DEMO_SF: "120000",
    CUSTOMER_INIT: "INIT",
    CUSTOMER_ESLETZBICHLER: "ESLE",
    CUSTOMER_INRAG: "INRAG",
    CUSTOMER_MIGROL: "MIGROL",
    CUSTOMER_WORKSHOP: "Workshop",
    CUSTOMER_RHEINLAND: "100",
    CUSTOMER_GUENTHERTANK: "Guenther",
    CUSTOMER_BAVARIA: "Bavaria",
    CUSTOMER_MOON: "MOON",
    CUSTOMER_PICKEL: "Pickel",
    CUSTOMER_IRIS: "IRIS",
    CUSTOMER_DEMO_TS: "Tankstelle",
    CUSTOMER_DEMO_IMMO: "Immobilie",
    CUSTOMER_DEMO_IND: "Industrie",
    CUSTOMER_DEMO_LOGISTICS: "Logistik",
    CUSTOMER_DEMO_FIL: "Filialist",
    CUSTOMER_BELL: "Bell",
    CUSTOMER_PETROTEC: "petrotec",
    CUSTOMER_MOVERI: "MOVERI",
    CUSTOMER_BAYWA: "BAYWA",
    CUSTOMER_MIGROLINO: "MIGROLINO",
    CUSTOMER_HEISLITZ: "Heislitz",
    CUSTOMER_GOLDENGATE: "GoldenGate",
    CUSTOMER_SUPPORT: "Support",
    CUSTOMER_COOP: "Coop",
    CUSTOMER_FOERSTER: "Foerster",
    CUSTOMER_TESSOL: "Tessol",
    CUSTOMER_Q1: "Q1",
    CUSTOMER_VIGO: "ViGo",
    CUSTOMER_CALPAM: "Calpam",
}