import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";

const Logout = () => {

    const navigate = useNavigate();

    AuthService.logout().then(
        () => {
            navigate("/");
            window.location.reload();
        }
    );
};
export default Logout;