import React from "react";
import Reports from "./Reports";

export function TabContent_AdditionalReports(props) {
    const { state } = { ...props };
    return (
        <>
        <br></br>
            <Reports
                noButtons
                equipid={state?.equipid ?? 0}
            />
        </>
    );
}