import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { mdiCheckboxMarkedOutline, mdiBallotOutline, mdiFileDocumentPlus, mdiFolderOutline, mdiHammerWrench, mdiInboxFull, mdiInformationOutline, mdiPencil} from "@mdi/js";
import { t } from "i18next";
import { Equipment_TabContent_Details } from "./Equipment_TabContent_Details";
import { TabContent_SubEquipments } from "./TabContent_SubEquipments";
import { TabContent_Documents } from "./TabContent_Documents";
import { TabContent_Reports } from "./TabContent_Reports";
import { TabContent_Inspections } from "./TabContent_Inspections";
import { OmisEquipmentChip } from "../shared/OmisChips";
import { AddDocuments } from "../reports/AddDocuments";
import { OmisIconButton} from "../shared/OmisButtons";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { OmisCol, OmisContainer, OmisRow, OmisStack, OmisTabsWithIcon } from "../shared/OmisLayouts";
import { OmisDialog } from "../shared/OmisDisplays";
import { SpaceComponent, TabChanged } from "../shared/Shared";
import AuthService from "../../services/auth.service";
import { TRole } from "../../constants/RoleConstants";
import { DMSDocumentReferer } from "../../constants/DMSDocumentReferer";
import { EquipmentCreate } from "./create/EquipmentCreate";
import { TabContent_EquiAttributes } from "./TabContent_EquiAttributes";
import urlEncryptService from "../../services/urlencrypt.service";

export function EquipmentDetails(props) {
    const navigate = useNavigate();
    var { state } = useLocation();
    var location = useLocation();
    const [modalWidth, setModalWidth] = useState("md");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModaContent] = useState(null);
    const [topButtons, setTopButtons] = useState(null);

    var location = useLocation();
    if (!state) {

        var encryptedString = "";
        if (location?.search) {
            encryptedString = location?.search.replace("#", "").replace("?", "").replaceAll("%22", "\"");
        } else if (location?.hash) {
            encryptedString = location?.hash.replace("#", "").replace("?", "").replaceAll("%22", "\"");
        }

        if (encryptedString && encryptedString !== "") {
            state = urlEncryptService.decrypt(encryptedString);
        }
    }

    var addSubEquipmentTitle = "Add new sub-equipment",
        addDocumentTitle = t("AddDocuments"),
        addObjectTitle = "Add object",
        addReportTitle = "Add report", editTitle = t("Action_Edit") ;

    const tabs = [{
        iconpath: mdiInformationOutline,
        iconposition: "start",
        title: t("Details"),
        content:
            <Equipment_TabContent_Details state={state} />,
        //showExportCVS: true
    }, {
        iconpath: mdiHammerWrench,
        iconposition: "start",
        title: t("SubEquipments"),
        content:
            <TabContent_SubEquipments state={state} />,
        removeFromAddMenu: addSubEquipmentTitle
    }, {
        iconpath: mdiFolderOutline,
        iconposition: "start",
        title: t("Documents"),
        content:
            <TabContent_Documents state={state} />,
        hideTab: !AuthService.hasRole(TRole.DMSEquipment),
        removeFromAddMenu: addDocumentTitle

    }, {
        iconpath: mdiInboxFull,
        iconposition: "start",
        title: t("FaultReports"),
        content:
            <TabContent_Reports state={state} />,
        hideTab: !AuthService.hasRole(TRole.FaultReportRead)
    }, {
        iconpath: mdiCheckboxMarkedOutline,
        iconposition: "start",
        title: t("Settings_TestDates"),
        content:
            <TabContent_Inspections state={state} />,
        hideTab: !AuthService.hasRole(TRole.TestdatesRead)
    }, { 
        iconpath: mdiBallotOutline,
        iconposition: "start",
        title: t("Equipment_AttributesList"),
        content: <TabContent_EquiAttributes state={state} />,
    },];

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        navigate(0);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    function PrepareModalTitle(props) {
        return (
            <OmisContainer fluid>
                <OmisRow>
                    <OmisCol xs={12}>
                        <span>{props.title}</span>
                        <SpaceComponent /><SpaceComponent />
                        {
                            props.equipid ?
                                <OmisEquipmentChip text={props.equipid} equipid={props.equipid} />
                                :
                                null
                        }
                    </OmisCol>
                </OmisRow>
            </OmisContainer>
        );
    }

    const handleModalOpenForDropdownMenuActions = (title, content, e) => {
        var custommodalwidth = "md";

        if (content === null || content === undefined) {
            switch (title) {
                case addDocumentTitle:
                    content = <AddDocuments referer={DMSDocumentReferer.Equipment} closeAction={handleModalClose} {...state} />;
                    break;
                case editTitle:
                    custommodalwidth = "lg";
                    content = <EquipmentCreate handleModalCancel={handleModalCancel} handleModalClose={handleModalClose} equiid={state.equipid}/>;
                    break;
                default:
                    break;
            }
        }

        setModalWidth(custommodalwidth);
        setModalTitle(
            <PrepareModalTitle modalwidth={custommodalwidth} equipid={state.equipid} title={title} />);
        setModaContent(content);
        handleModalOpen();
    };

    var childItemsForDotsMenu = [{
        subMenuIcon: mdiPencil,
        onClick: handleModalOpenForDropdownMenuActions.bind(this, editTitle, null),
        menuName: editTitle,
    },
        //{
        //    subMenuIcon: mdiFileDocumentMultiple,
        //    onClick: handleModalOpenForDropdownMenuActions.bind(this, "Duplicate", "test"),
        //    menuName: "Duplicate",
        //},         
        //{
        //    subMenuIcon: mdiPlusBox,
        //    onClick: handleModalOpenForDropdownMenuActions.bind(this, "Add to project", "test"),
        //    menuName: "Add to project",
        //},
        //{
        //    subMenuIcon: mdiEmail,
        //    onClick: handleModalOpenForDropdownMenuActions.bind(this, "Send as email", "test"),
        //    menuName: "Send as email",
        //},
        //{
        //    subMenuIcon: mdiBell,
        //    onClick: handleModalOpenForDropdownMenuActions.bind(this, "Cancel Report", "test"),
        //    menuName: "Cancel Report",
        //},
        //{
        //    subMenuIcon: mdiFlagVariant,
        //    onClick: handleModalOpenForDropdownMenuActions.bind(this, "Archive active", "test"),
        //    menuName: "Archive active",
        //}
    ]

    if (!AuthService.hasRole(TRole.EquipmentEdit)) {
        childItemsForDotsMenu = [];
    }

    var childItemsForAddMenu = [  //{
        //    subMenuIcon: mdiHomePlus,
        //    onClick: handleModalOpenForDropdownMenuActions.bind(this, addObjectTitle, "test"),
        //    menuName: addObjectTitle,
        //},
        //{
        //    subMenuIcon: mdiInboxFull,
        //    onClick: handleModalOpenForDropdownMenuActions.bind(this, addReportTitle, "test"),
        //    menuName: addReportTitle,
        //}
    ]

    if (AuthService.hasRole(TRole.EquipmentEdit)) {
       // childItemsForAddMenu = [{
       //     subMenuIcon: mdiHammerWrench,
       //     onClick: handleModalOpenForDropdownMenuActions.bind(this, addSubEquipmentTitle, "test"),
       //     menuName: addSubEquipmentTitle,
       // }, ...childItemsForAddMenu];
    }

    if (AuthService.hasRole(TRole.DMSEquipment)) {
        childItemsForAddMenu = [...childItemsForAddMenu,{
            subMenuIcon: mdiFileDocumentPlus,
            onClick: handleModalOpenForDropdownMenuActions.bind(this, addDocumentTitle,
                <AddDocuments referer={DMSDocumentReferer.Equipment} closeAction={handleModalClose} {...state} />),
            menuName: addDocumentTitle,
        }];
    }

    return (
        <div className="omis-header-box">
            <OmisContainer fluid>
                <OmisStack direction="horizontal" gap={5}>
                    <div className="me-auto">
                        <OmisRow className="gy-5">
                            <OmisCol xs={1}>
                                <OmisIconButton id={"backButton"} iconcontent={
                                    <ArrowBackIcon fontSize="inherit" />} onClick={() => { navigate(location.key !== "default" ? -1 : "/equipment"); }}
                                />
                            </OmisCol>
                            <OmisCol xs={11}>
                                <div className="omis-header-title-details" style={{ paddingLeft: '1.5em' }}>{t("Header_Details_Equi")} #{state?.equipid}</div>
                            </OmisCol>
                        </OmisRow>
                    </div>
                    <div className="ms-auto">
                        <OmisRow>
                            {topButtons}
                        </OmisRow>

                    </div>
                </OmisStack>
            </OmisContainer>
            <OmisTabsWithIcon tabs={tabs} onChange={TabChanged.bind(this, tabs, state, childItemsForDotsMenu, childItemsForAddMenu, setTopButtons)} />
            <OmisDialog onClose={handleModalCancel } open={ modalOpen }
                        maxWidth={ modalWidth }
                        title={ modalTitle }
                        content={ modalContent }
            />
        </div>);
}