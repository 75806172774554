import { OmisAddNote } from "../shared/OmisAddNote";
import React from "react";
import { useLocation } from "react-router-dom";
import { OmisCol } from "../shared/OmisLayouts";

export function AddNote(props) {
    const { state } = useLocation();
    if (state === {}) {
        state = { ...props };
    }

    return (
        <OmisCol xs={12}><OmisAddNote cancelclick={props.onCancel} onClose={props.onClose} id={`${props.reportid}_AddNote`} state={state} {...props} /></OmisCol>);
}