import React, { useState, useCallback ,useEffect} from 'react';
import Diagram, { createSchema, useSchema } from 'beautiful-react-diagrams';
import 'beautiful-react-diagrams/styles.css';
import { styled } from '@mui/material/styles';


export function OmisGraphTree(props) {

    const [schemaValues, setSchemaValues] = useState({ nodes: [], links: [] }); 

    var StyledDiagram = styled(Diagram)({
        "& .bi-diagram-node-default": {
            width: "25%;",
            height: "10%;",
            backgroundColor: "white;"
        }
    }); 

    useEffect(() => {
        if (props.nodes && props.links) {
            var tmpSchemaVal = { ...schemaValues };
            tmpSchemaVal.nodes = props.nodes;
            if (tmpSchemaVal.nodes.length>0)
            {
                props.links.map((linkItem) => {
                    //filtering if the linked nodes are existing in nodes list
                    var tmp = props.nodes.filter(n => n.id === linkItem.input).length > 0 && props.nodes.filter(n2 => n2.id === linkItem.output).length > 0;
                    if (tmp) {
                        tmpSchemaVal.links.push(linkItem);
                    }
                });                
            }
            setSchemaValues(tmpSchemaVal);
        } 
    }, [props.nodes, props.links]);

    var initialSchema = createSchema(schemaValues);

    /*
     createSchema({
        nodes: [
            { id: 'node-1', content: 'Node 1', coordinates: [250, 60], },
            { id: 'node-2', content: 'Node 2', coordinates: [100, 200], },
            { id: 'node-3', content: 'Node 3', coordinates: [250, 220], },
            { id: 'node-4', content: 'Node 4', coordinates: [400, 200], },
        ],
        links: [
            { input: 'node-1', output: 'node-2' },
            { input: 'node-1', output: 'node-3' },
            { input: 'node-1', output: 'node-4' },
        ]
    });
    */

    const UncontrolledDiagram = () => {
        // create diagrams schema
        const [schema, { onChange }] = useSchema(initialSchema);

        return (
            <div style={{ height: `${props.nodes.length * 7}rem` }}>
                <StyledDiagram schema={schema} onChange={onChange} />
            </div>
        );
    };

    return (
            <UncontrolledDiagram />
    );
}
