import React from "react";
import { Card } from "@mui/material";
import { t } from "i18next";
import { OmisIconButton } from "../shared/OmisButtons";
import { OmisAutosizeTextArea, OmisTextBoxNoForm, OmisCheckBoxNoForm } from "../shared/OmisInputs";
import { Clear as ClearIcon } from "@mui/icons-material";
import { OmisCol, OmisRow } from "../shared/OmisLayouts";


export default function InspectionsCheckList(props) {  

    const handleValueOnChange = (e) => { /* onchange added because it was giving uncontrolled input error */

        if (props.stepValueChanged) {
            props.stepValueChanged(e);
        }
    }

    const handleDeleteStep = (key) => {
        if (props.handleDeleteStepCallback)
        {
            props.handleDeleteStepCallback(key);
        }
    }

    return (
        <Card key={`${props.stepObject?.itemID}_rowCard` }>

            <OmisRow>
                <OmisRow style={{ display: 'flex', justifyContent: 'right' }}>
                    <OmisCol style={{ display: 'flex', justifyContent: 'right' }} xs={1}>
                        <OmisIconButton id={"deleteStepButton"} iconcontent={<ClearIcon fontSize="inherit" color={"error"} />} onClick={() => { handleDeleteStep(props.stepObject?.itemID); }} />
                    </OmisCol>
                </OmisRow>
                <Card variant={"outlined"}>
                    <OmisRow>
                        <OmisCol xs={6}>
                            <Card variant={"outlined"}>
                                <OmisRow>
                                    <OmisCol xs={12}>
                                        <OmisTextBoxNoForm id={props.stepObject?.itemID.toString()} key={props.stepObject?.itemID} label={t("Name")} name={"name"} placeholder={t("Name")} value={props.stepObject?.name ?? ""} onChange={handleValueOnChange} required />
                                    </OmisCol>
                                </OmisRow>
                                <OmisRow>
                                    <OmisCol xs={6}>
                                        <OmisTextBoxNoForm id={props.stepObject?.itemID.toString()} key={props.stepObject?.itemID} label={t("CheckListItem_OkText")} name={"okText"} value={props.stepObject?.okText ?? ""} onChange={handleValueOnChange} placeholder={t("CheckListItem_OkText")} required />
                                    </OmisCol>
                                    <OmisCol xs={6}>
                                        <OmisTextBoxNoForm id={props.stepObject?.itemID.toString()} key={props.stepObject?.itemID} label={t("CheckListItem_NotOkText")} name={"notOkText"} value={props.stepObject?.notOkText ?? ""} onChange={handleValueOnChange} placeholder={t("CheckListItem_NotOkText")} required />
                                    </OmisCol>
                                </OmisRow>
                            </Card>
                        </OmisCol>
                        <OmisCol xs={6}>
                            <Card variant={"outlined"}>
                                <OmisRow>
                                    <OmisCol xs={12}>
                                    <br/>
                                        <OmisAutosizeTextArea id={props.stepObject?.itemID.toString()} key={props.stepObject?.itemID} label={t("Description")} name={"description"} value={props.stepObject?.description ?? ""} onChange={handleValueOnChange} placeholder={t("Description")} />
                                    </OmisCol>
                                </OmisRow>
                            </Card>
                        </OmisCol>
                    </OmisRow>
                </Card>

                <Card variant={"outlined"}>
                    <OmisRow>
                        <OmisCol xs={12}>
                            <h6 style={{ fontWeight: 'bold' }} >{t("Value")}</h6>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <OmisCheckBoxNoForm
                                id={props.stepObject?.itemID.toString()} key={props.stepObject?.itemID}
                                labeltext={t("CheckListItem_ValueVisible")}
                                onChange={handleValueOnChange}
                                checked={props.stepObject?.valueVisible ?? false}
                                name={"valueVisible"}
                            />
                        </OmisCol>
                        <OmisCol xs={3}>
                            <OmisCheckBoxNoForm
                                disabled={!props.stepObject?.valueVisible }
                                id={props.stepObject?.itemID.toString()} key={props.stepObject?.itemID}
                                labeltext={t("CheckListItem_ValueRequired")}
                                onChange={handleValueOnChange}
                                checked={props.stepObject?.valueRequired ?? false}
                                name={"valueRequired"}
                            />
                        </OmisCol>
                        <OmisCol xs={3}>
                            <OmisCheckBoxNoForm
                                id={props.stepObject?.itemID.toString()} key={props.stepObject?.itemID}
                                labeltext={t("CheckListItem_OkNotOkRequired")}
                                onChange={handleValueOnChange}
                                checked={props.stepObject?.okNotOkRequired ?? false}
                                name={"okNotOkRequired"}
                            />
                        </OmisCol>
                        <OmisCol xs={3}>
                            <OmisCheckBoxNoForm
                                id={props.stepObject?.itemID.toString()} key={props.stepObject?.itemID}
                                labeltext={t("CheckListItem_ShowCreateReportButton")}
                                onChange={handleValueOnChange}
                                checked={props.stepObject?.showCreateReportButton ?? false}
                                name={"showCreateReportButton"}
                            />
                        </OmisCol>
                    </OmisRow>
                </Card>

                <Card variant={"outlined"}>
                    <OmisRow>
                        <OmisCol xs={12}>
                            <h6 style={{ fontWeight: 'bold' }} >Comment</h6>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <OmisCheckBoxNoForm
                                id={props.stepObject?.itemID.toString()} key={props.stepObject?.itemID}
                                labeltext={t("CheckListItem_NoteVisible")}
                                onChange={handleValueOnChange}
                                checked={props.stepObject?.noteVisible ?? false}
                                name={"noteVisible"}
                            />
                        </OmisCol>
                        <OmisCol xs={3}>
                            <OmisCheckBoxNoForm
                                disabled={!props.stepObject?.noteVisible}
                                id={props.stepObject?.itemID.toString()} key={props.stepObject?.itemID}
                                labeltext={t("CheckListItem_NoteRequired")}
                                onChange={handleValueOnChange}
                                checked={props.stepObject?.noteRequired ?? false}
                                name={"noteRequired"}
                            />
                        </OmisCol>
                        <OmisCol xs={3}>
                            <OmisCheckBoxNoForm
                                id={props.stepObject?.itemID.toString()} key={props.stepObject?.itemID}
                                labeltext={t("CheckListItem_OkVisible")}
                                onChange={handleValueOnChange}
                                checked={props.stepObject?.okVisible ?? false}
                                name={"okVisible"}
                            />
                        </OmisCol>
                        <OmisCol xs={3}>
                            <OmisCheckBoxNoForm
                                id={props.stepObject?.itemID.toString()} key={props.stepObject?.itemID}
                                labeltext={t("CheckListItem_NotOkVisible")}
                                onChange={handleValueOnChange}
                                checked={props.stepObject?.notOkVisible ?? false}
                                name={"notOkVisible"}
                            />
                        </OmisCol>
                    </OmisRow>
                </Card>
            </OmisRow>
        </Card>
    );
}