import React, { useState, useEffect } from 'react';
import { Card, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Checkbox, Paper } from "@mui/material";
import { OmisLabel } from './OmisDisplays';
import { OmisSearchBar } from './OmisInputs';
import { OmisCol, OmisContainer } from './OmisLayouts';

export function OmisCheckboxList(props) {
    const [checked, setChecked] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
    const [multiSelect, setMultiSelect] = useState(true);
    const [listItems, setListItems] = useState([]);
    const [maxHeight, setMaxHeight] = useState(200);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [listAttr, setListAttr] = useState([]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        var newChecked = [...checked];
        if (multiSelect) {
            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }
        } else {
            newChecked = [value];
        }

        setChecked(newChecked);
    };

    const handleSelectAll = (event) => {
        if (event.currentTarget.checked) {
            setChecked([...listItems.keys()]);
        } else {
            setChecked([]);
        }
        setCheckedAll(event.target.checked);
    };

    useEffect(() => {
        if (props.onChange) {
            props.onChange(checked);
        }
    }, [checked]);

    useEffect(() => {
        if (props.checked && props.checked.length>0) {
            setChecked(props.checked)
        }
    }, [props.checked]);

    useEffect(() => {
        if (props.itemlist) {
            setListItems(props.itemlist);
            setSearchResults(props.itemlist);
        }
    }, [props.itemlist]);

    useEffect(() => {
        if (props.multiSelect != undefined) {
            setMultiSelect(props.multiSelect);
        }
    }, [props.multiSelect]);

    useEffect(() => {
        if (props.listAttr) {
            setListAttr(props.listAttr);
        }
    }, [props.listAttr]);

    useEffect(() => {
        if (props.maxheight) {
            setMaxHeight(props.maxheight);
        }
    }, [props.maxheight]);

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    const handleSearchBarValChange = newSearchTerm => {
        setSearchTerm(newSearchTerm);
    };

    const filterArrayBySearchQuery = (array, searchQuery, attributes) => {
        return array.filter((item) => {
            // Concatenate all attributes into a single string to perform search
            var searchString = "";
            if (attributes && attributes.length > 0) {
                searchString = attributes
                    .map((attribute) => item[attribute])
                    .join(" ")
                    .toLowerCase();
            } else {
                searchString = item
                    .toLowerCase();
            }
            return searchString.includes(searchQuery.toLowerCase());
        });
    };

    useEffect(() => {
        var results = [];
        if (listItems && listItems.length > 0 && listItems[0].content && listItems[0].content.props) {
            results = listItems.filter(x =>
                x.content.props.item.email.toLowerCase().includes(searchTerm.toLowerCase())
                || x.content.props.item.companyName.toLowerCase().includes(searchTerm.toLowerCase())
                || x.content.props.item.skills.filter(sf => sf.toLowerCase().includes(searchTerm.toLowerCase())).length > 0
            );
        } else {
            results = filterArrayBySearchQuery(listItems, searchTerm, listAttr /* ["content","name","email"]*/)
        }

        if (searchTerm === "" && props.itemlist) {
            setSearchResults(props.itemlist);
        }
        else {
            setSearchResults(results);
        }
    }, [searchTerm]);

    function handleSearchResults(result) {
        setSearchResults(result);
    }

    return (
        <OmisContainer fluid>
            {props.label ? <OmisCol xs={12}><OmisLabel label={props.label} props={props}></OmisLabel> </OmisCol> : ""}

            {
                props.showSearchBox ? 
                    <OmisCol xs={12}>
                        <OmisSearchBar getSearchTerm={handleSearchBarValChange } />
                    </OmisCol>
                    :
                    null
            }
            <OmisCol xs={12}>
                <Paper style={{ height: maxHeight, maxHeight: maxHeight, overflow: 'auto' }} elevation={0} >
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {
                            props.showAllButton ?
                                <>
                                    {
                                        props.withCardView ?
                                            <Card key={"selectAllCard"}>
                                                <ListItem
                                                    key={"selectAll"}
                                                    disablePadding
                                                >
                                                    <ListItemButton role={undefined} onClick={() => handleSelectAll} dense>
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                edge="start"
                                                                checked={checkedAll}
                                                                onChange={handleSelectAll}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText id={'checkbox-list-label-all'} primary={'All'} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Card>
                                            :
                                            <ListItem
                                                key={"selectAll"}
                                                disablePadding
                                            >
                                                <ListItemButton role={undefined} onClick={() => handleSelectAll} dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={checkedAll}
                                                            onChange={handleSelectAll}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={'checkbox-list-label-all'} primary={'All'} />
                                                </ListItemButton>
                                            </ListItem>
                                    }
                                </>
                                :
                                null
                        }

                        {searchResults.map((value, index) => {
                            const labelId = `checkbox-list-label-${value.key ? value.key : index}`;
                            const keyVal = value.key ? value.key : index;
                            const contentVal = value.content ? value.content : value;
                            return (
                                props.withCardView ?
                                    <Card key={`${keyVal}_RowCard`}>
                                        <ListItem
                                            key={keyVal}
                                            disablePadding
                                        >
                                            <ListItemButton role={undefined} onClick={handleToggle(keyVal)} dense>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={checked.indexOf(keyVal) !== -1}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                {contentVal}
                                            </ListItemButton>
                                        </ListItem>
                                    </Card>
                                    :
                                    <ListItem
                                        key={keyVal}
                                        disablePadding
                                    >
                                        <ListItemButton role={undefined} onClick={handleToggle(keyVal)} dense>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(keyVal) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            {contentVal}
                                        </ListItemButton>
                                    </ListItem>
                            );
                        })}
                    </List>
                </Paper >
            </OmisCol>
        </OmisContainer>
    );
} 