import { t } from "i18next";

export const PageNames = () => {
    return [
        { key: 0, content: t("Dashboard"), path: '/dashboard' },
        { key: 1, content: t("FaultReports"), path: '/reports' },
        { key: 2, content: t("Settings_TestDates"), path: '/inspections' },
        { key: 3, content: t("Documents"), path: '/documents' },
        { key: 4, content: t("Objects"), path: '/objects' },
        { key: 5, content: t("Equipment"), path: '/equipment' },
        { key: 6, content: t("Projects"), path: '/projects' },
        { key: 7, content: "SystemData", path: '/systemdata' }
    ];
}

export const NavBarMenus = () => {
    return [
        "dashboard",
        "reports",
        "inspections",
        "documents",
        "objects",
        "equipment",
        "users",
        "projects",
        "systemdata",
        "settings",
        "notifications",
        "support",
        "omisdocs",
        "profile",
        "logout"
    ];
}