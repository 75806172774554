import React, { useState } from "react";
import { DocumentUpload } from "../documents/Documents";
import { DMSDocumentReferer } from "../../constants/DMSDocumentReferer";
import { OmisContainer, OmisRow } from "../shared/OmisLayouts";

export function AddDocuments(props) {
    const [documentID, setDocumentID] = useState(props.inspectionid);

    return (
        <OmisContainer fluid>
            <OmisRow>
                <DocumentUpload handleModalClose={props.closeAction} handleModalCancel={props.onCancel} fileUploadCallback={props.closeAction} id={documentID} referer={DMSDocumentReferer.TestDate} />
            </OmisRow>
        </OmisContainer>);
}