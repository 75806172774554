import React, { useEffect, useState, useRef } from "react";
import { t } from "i18next";
import httpclientService from "../../services/httpclient.service";
import { mdiInformationOutline } from "@mdi/js";
import "./Components.css"
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { OmisCol, OmisContainer, OmisRow, OmisTabsWithIcon } from './OmisLayouts';
import OmisTextBoxEmailSearchV2, { OmisAutosizeTextArea, OmisCheckBox, OmisTextBox } from './OmisInputs';
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "./OmisButtons";

var internalNoteTitle = "Internal note";
var externalNoteTitle = "External note";
var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
export function OmisAddNote(props) {
    const { state } = { ...props };
    const [iconPosition, setIconPosition] = useState("end");
    const [tabValue, setTabValue] = useState(0);
    const [isAnonymousUser, setIsAnonymousUser] = useState(true);
    const [prefillNoteResponse, setPrefillNoteResponse] = useState(null);
    const [editValues, setEditValues] = useState(null);

    internalNoteTitle = t("InternalNote");
    externalNoteTitle = t("ExternalNote");

    const formRef = useRef();

    const [initialValues, setInitialValues] = useState({
        noteID: 0,
        targetID: 0,
        caption: '',
        note: '',
        internalNote: true,
        mailTO: '',
        mailCC: '',
        mailNote: false,
        mailSearch:'',
        user: ''
    });

    const editItem = Boolean(props.selectedRowID > 0);
    useEffect(() => {
        if (editItem) {
            httpclientService.get(`${props.apiUrl}?id=${props.selectedRowID}`).then((response) => {
                if (response) {
                    setInitialValues(response);
                    setTabValue(Number(!response.internalNote));
                    formRef.current?.setValues(response);
                    setEditValues(response);
                }
            })
        } else {
            const reportID = props.reportid ? props.reportid : state?.reportid;
            initialValues.targetID = reportID;
            formRef.current?.setFieldValue("targetID", reportID);
            setInitialValues(initialValues);

            httpclientService.get(`/api/reports/prefillnote?reportId=${reportID}`).then((response) => {
                if (response && response.mailNotePreSelected) {
                    initialValues.mailNote = response.mailNotePreSelected;
                    initialValues.mailTO = response.mailTo;
                    initialValues.internalNote = !response.mailNotePreSelected;
                    formRef.current?.setFieldValue("mailNote", response.mailNotePreSelected);
                    formRef.current?.setFieldValue("mailTO", response.mailTo);
                    formRef.current?.setFieldValue("internalNote", !response.mailNotePreSelected);
                    setInitialValues(initialValues);

                    setTabValue(initialValues.internalNote ? 0 : 1);
                }
                setPrefillNoteResponse(response);
            });
        }

        httpclientService.get('/api/shared/getusertypebyworkflow').then((response) => {
            setIsAnonymousUser(response);
        });
    }, [])

    useEffect(() => {
        if (!editItem) {
            if (isAnonymousUser) {
                initialValues.internalNote = false;
            } else {
                initialValues.internalNote = true;
            }
            formRef.current?.setFieldValue("internalNote", initialValues.internalNote);
            setInitialValues(initialValues);
        }
    }, [isAnonymousUser]);

    function handleSave(values) {
        httpclientService.post('/api/reports/savenote', values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } 
            }
        })
    }

    function tabChanged(e) {
        var newInitial = { ...initialValues };
        if (formRef.current?.values?.note || formRef.current?.values?.caption) {
            newInitial = { ...formRef.current.values };
        }

        newInitial.internalNote = e === 0 && viewtabs.filter((f) => f.title === internalNoteTitle && !f.hideTab).length > 0;

        if (newInitial.internalNote) {
            newInitial.mailNote = false;
            newInitial.mailTO = "";
            formRef.current?.setFieldValue("mailNote", false);
            formRef.current?.setFieldValue("mailTO", "");
        } else if (editItem && editValues && editValues?.mailNote) {
            newInitial.mailNote = editValues.mailNote;
            newInitial.mailTO = editValues.mailTO;
            formRef.current?.setFieldValue("mailNote", editValues.mailNote);
            formRef.current?.setFieldValue("mailTO", editValues.mailTO);
        } else if (prefillNoteResponse && prefillNoteResponse?.mailNotePreSelected) {
            newInitial.mailNote = prefillNoteResponse?.mailNotePreSelected;
            newInitial.mailTO = prefillNoteResponse?.mailTo;
            formRef.current?.setFieldValue("mailNote", prefillNoteResponse?.mailNotePreSelected);
            formRef.current?.setFieldValue("mailTO", prefillNoteResponse?.mailTo);
        }
        formRef.current?.setValues(newInitial);
        setInitialValues(newInitial);
    }

    const viewtabs = [
        {
            title: internalNoteTitle,
            iconpath: mdiInformationOutline,
            iconposition: iconPosition,
            content: <NotePageContent formRef={formRef} handleSave={handleSave} initialValues={initialValues} showSendEmail={false} handleCancel={props.cancelclick} {...props} />,
            hideTab: isAnonymousUser
        },
        {
            title: externalNoteTitle,
            iconpath: mdiInformationOutline,
            iconposition: iconPosition,
            content: <NotePageContent formRef={formRef} handleSave={handleSave} initialValues={initialValues} editItem={editItem} showSendEmail={true} handleCancel={props.cancelclick} {...props} />
        }
    ]

    return (
        <OmisContainer className='addNoteDiv'>
            <OmisTabsWithIcon tabvalue={tabValue} onChange={ tabChanged}  tabs={viewtabs} />
        </OmisContainer>
    );
}

function NotePageContent(props) {

    const [emailAddressesVisible, setEmailAddressesVisible] = useState(false);
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [foundEmailsList, setFoundEmailsList] = useState([]);
    const [formInitialValues, setFormInitialValues] = useState(props.formRef?.current?.values ?? props.initialValues);

    useEffect(() => {
        setEmailAddressesVisible(props.initialValues.mailNote);
    }, [props?.initialValues?.mailNote]);

    function handleMailNoteChange(e) {
        setEmailAddressesVisible((prevState) => !prevState);
    }

    function handleSelectedEmails(selections) {
        setSelectedEmails(selections);
    }

    function handleAddIconClick(propertName,result) {
        //var emails = "";
        //
        //selectedEmails.map((item) => {
        //    if (foundEmailsList.length > 0) {
        //        var tmp = foundEmailsList.filter(f => f.id === item)[0];
        //
        //        if (tmp) {
        //            emails += `${tmp.displayText},`;
        //        }
        //    }
        //});

        props.formRef.current?.setFieldValue(propertName, result);
    }

    return (
        <Formik
            innerRef={props.formRef}
            initialValues={formInitialValues}
                enableReinitialize={true}
            validationSchema={Yup.object({
                caption: Yup.string()
                    .required(t("GenericRequiredText"))
                    .max(100, t('Error_TextTooLong', { 1: 100 })),
                note: Yup.string()
                    .required(t("GenericRequiredText"))
                    .max(2048, t('Error_TextTooLong', { 1: 2048 })),

                mailTO: Yup.string()
                    .notRequired()
                    .nullable()
                    .test("mailTO_validation", "Email Validation Error", function (value) { // Use function
                        var emails = [],errorMail=[];
                        if (value && value.includes(",")) {
                            emails = value.split(',');
                            if (value.endsWith(',')) {
                                emails.pop(emails[emails.length - 1]);
                            }

                            for (var i = 0; i < emails.length; i++) {
                                var currentEmail = emails[i];
                                if (!mailFormat.test(currentEmail)) {
                                    errorMail.push(currentEmail);
                                }
                            }
                        } else if (value && value!=='') {
                            if (!mailFormat.test(value)) {
                                errorMail.push(value);
                            }
                        }

                        if (errorMail.length === 0) {
                            return true;
                        } else {
                            return this.createError({ message: `${t("OnlineImport_InvalidFormat")}: ${errorMail}`, path: 'mailTO' });
                        }
                    }),

                mailCC: Yup.string()
                    .notRequired()
                    .nullable()
                    .test("mailCC_validation", "Email Validation Error", function (value) { // Use function
                        var emails = [], errorMail = [];
                        if (value && value.includes(",")) {
                            emails = value.split(',');
                            if (value.endsWith(',')) {
                                emails.pop(emails[emails.length - 1]);
                            }

                            for (var i = 0; i < emails.length; i++) {
                                var currentEmail = emails[i];
                                if (!mailFormat.test(currentEmail)) {
                                    errorMail.push(currentEmail);
                                }
                            }
                        } else if (value && value !== '') {
                            if (!mailFormat.test(value)) {
                                errorMail.push(value);
                            }
                        }

                        if (errorMail.length === 0) {
                            return true;
                        } else {
                            return this.createError({ message: `${t("OnlineImport_InvalidFormat")}: ${errorMail}`, path: 'mailCC' });
                        }
                    }),
                })}
                onSubmit={(values) => props.handleSave(values) }
        >
            {formik => (
                <Form>
                    <OmisContainer fluid>
                        <OmisRow>
                            <OmisCol xs={12}>
                                <OmisTextBox name="caption" labeltext={t("Title")} required />
                            </OmisCol>
                            <OmisCol xs={12}>
                                <OmisAutosizeTextArea name="note" labeltext={t("Note")} required />
                            </OmisCol>
                        </OmisRow>
                        <br />
                        {
                            props.showSendEmail ?
                                <OmisRow>
                                    <OmisCol xs={6}>
                                        <OmisCheckBox name="mailNote"
                                            onClick={handleMailNoteChange}
                                            labeltext={t("SendEmail")}
                                            disabled={props.editItem}
                                        />
                                    </OmisCol>
                                    {
                                        emailAddressesVisible ?
                                            <>
                                                <OmisCol xs={12}>
                                                    <OmisTextBoxEmailSearchV2 name="mailTO" labeltext={t("Email_TO")} onChange={handleAddIconClick.bind(this, "mailTO")} disabled={props.editItem} />
                                                </OmisCol>
                                                <OmisCol xs={12}>
                                                    <OmisTextBoxEmailSearchV2 name="mailCC" labeltext={t("Email_CC_Recipients")} onChange={handleAddIconClick.bind(this, "mailCC")} disabled={props.editItem} />
                                                </OmisCol>
                                                {
                                                    /*
                                                <OmisCol xs={12}>
                                                    <OmisTextBoxEmail name="mailTO" labeltext={t("Email_TO")} addIconClick={handleAddIconClick.bind(this, "mailTO")} disabled={props.editItem} />
                                                </OmisCol>
                                                <OmisCol xs={12}>
                                                    <OmisTextBoxEmail name="mailCC" labeltext={t("Email_CC_Recipients")} addIconClick={handleAddIconClick.bind(this,"mailCC")} disabled={props.editItem} />
                                                </OmisCol>
                                                <OmisCol xs={12}>
                                                    <OmisTextBoxEmailSearch name="mailSearch" labeltext={t("Email_Search")} setFoundEmailsList={setFoundEmailsList} callbackFunc={handleSelectedEmails} disabled={props.editItem} />
                                                </OmisCol>
                                                    */
                                                }
                                            </>
                                            :
                                            null
                                    }
                                </OmisRow>
                                : null
                        }
                        <br />

                        <OmisRow>
                            <OmisCol xs={6}>
                                <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")} onClick={props.postclick} />
                            </OmisCol>
                            <OmisCol xs={6}>
                                <OmisButtonSecondary id={"cancelButton"} onClick={props.handleCancel} text={t("Action_Cancel")} />
                            </OmisCol>

                        </OmisRow>
                    </OmisContainer>
                </Form>
            )}
        </Formik>
        );
}
