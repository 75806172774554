import React, { useState } from "react";
import { t } from "i18next";
import { Link as RouterLink } from "react-router-dom";
import httpclientService from "../../../services/httpclient.service";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisIconButton } from "../../shared/OmisButtons";
import { OmisGrid } from "../../shared/OmisGrids";
import { ArrowBack as ArrowBackIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../../shared/OmisLayouts";

export function SettingsMemoryCaches(props) {
    var url = "/api/settings/settingsfsmintern/getmemorycaches";
    const [changeCounter, setChangeCounter] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);

    const handleDelete = (rowID) => {
        httpclientService.post(`/api/settings/settingsfsmintern/deletememorycache`, JSON.stringify([rowID])).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    }

    const handleDeleteSelections = () => {
        httpclientService.post(`/api/settings/settingsfsmintern/deletememorycache`, JSON.stringify(selectedRows)).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    }

    const handleDeleteAll = (rowID) => {
        httpclientService.post(`/api/settings/settingsfsmintern/deleteallmemorycaches`).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    }

    return (
        <div className="omis-header-box">
            <OmisContainer fluid>
                <OmisStack direction="horizontal" gap={5}>
                    <div className="me-auto">
                        <OmisRow className="gy-5">
                            <OmisCol xs={1}>
                                <OmisIconButton id={"backButton"}
                                    iconcontent={<ArrowBackIcon fontSize="inherit" />}
                                    text="Back" component={RouterLink} to={"/settings"} state={{ selectedtabvalue: TabConstants.FSMIntern }} />
                            </OmisCol>
                        </OmisRow>
                    </div>
                    <div className="ms-auto">
                        <OmisRow>
                            <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'left' }}>
                                <OmisIconButton id={"deleteSelectionButton"}
                                    sx={{ color:'#fd681e' }}
                                    iconcontent={<DeleteIcon fontSize="inherit" />}
                                    text={t("Action_DeleteSelections")} onClick={handleDeleteSelections} />
                            </OmisCol>
                            <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'right' }}>
                                <OmisIconButton id={"deleteAllButton"}
                                    sx={{ color: '#cf0014' }}
                                    iconcontent={<DeleteIcon fontSize="inherit" />}
                                    text={t("Action_DeleteAll")} onClick={handleDeleteAll} />
                            </OmisCol>
                        </OmisRow>
                    </div>
                </OmisStack>
            </OmisContainer>

            <OmisGrid headerText={"Memory Caches"}
                actiondropdown={{delete: handleDelete }}
                apiUrl={url}
                change={changeCounter}
                showCheckBox={true}
                rowSelectionCallback={setSelectedRows}
                noExportButton
                {...props} />
        </div>
    );
}