import React, { useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisDateRangePickerDayAndMonth, OmisTextBox } from "../../shared/OmisInputs";
import dayjs from 'dayjs';
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateSeason(props) {

    const navigate = useNavigate();
    const formRef = useRef();
    const [initialValues, setInitialValues] = useState({
        id: props.rowVals  && props.rowVals.id ? props.rowVals.id : null,
        description: props.rowVals && props.rowVals.description ? props.rowVals.description : "",
        from: props.rowVals?.seasonfromdate ?? null,
        to: props.rowVals?.seasontodate ?? null,
    });

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsobjects/saveseason", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate("/settings/objects/seasons");
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate("/settings/objects/seasons");
        }
    }

    function handleDateChange(val) {
        formRef.current.setFieldValue("from", val.fromDate);
        formRef.current.setFieldValue("to", val.toDate);
    }

    const [schema, setSchema] = useState({
        description: Yup.string()
            .required(t("GenericRequiredText"))
            .max(30, t('Error_TextTooLong', { 1: 30 })),
        from: Yup.date()
            .required(t("GenericRequiredText")),
        to: Yup.date()
            .required(t("GenericRequiredText"))
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("New_Season")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisRow>
                                    <OmisCol xs={12}>
                                        <OmisTextBox labeltext={t("Description")} name="description" required />
                                    </OmisCol>
                                </OmisRow>
                            </OmisRow>
                            <OmisRow>
                                <OmisDateRangePickerDayAndMonth value={[dayjs(initialValues.from), dayjs(initialValues.to)]} onChange={handleDateChange} required />
                            </OmisRow>
                            <OmisRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}