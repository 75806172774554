import React, { useRef, useState } from "react";
import { t } from "i18next";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisCheckBox, OmisPasswordTextBoxWithEye, OmisTextBox } from "../../shared/OmisInputs";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateWebServiceSettings(props) {

    const navigate = useNavigate();
    const formRef = useRef();
    const [initialValues, setInitialValues] = useState({
        id: props.rowVals?.id ?? null,
        serviceUrl: props.rowVals?.serviceUrl ?? null,
        username: props.rowVals?.username ?? null,
        password: props.rowVals?.password ?? null,
        serviceName: props.rowVals?.serviceName ?? null,
        contentType: props.rowVals?.contentType ?? null,
        archiv: props.rowVals?.archiv ?? false,
    });

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsfsmintern/savewebservicesettings", JSON.stringify(values)).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate("/settings/settingsfsmintern/webservicesettings");
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate("/settings/settingsfsmintern/webservicesettings");
        }
    }

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("New_WebServiceSettings")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>

                            <OmisRow>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={"ServiceUrl"} name="serviceUrl" />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={"Username"} name="username" />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisPasswordTextBoxWithEye labeltext={"Password"} name="password" />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={"ServiceName"} name="serviceName" />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={"ContentType"} name="contentType" />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <br/>
                                    <OmisCheckBox labeltext={"Archiv"} name="archiv" sx={{height:'2.96em'} } />
                                </OmisCol>
                            </OmisRow>

                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}