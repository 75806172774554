import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimary, OmisButtonPrimarySubmit, OmisButtonSecondary, OmisProgress } from "../../shared/OmisButtons";
import { Card } from "@mui/material";
import { OmisCheckBox, OmisTextBox } from "../../shared/OmisInputs";
import { OmisSelectedCatalogueCard } from "../../shared/OmisDisplays";
import AuthService from "../../../services/auth.service";
import { SettingsCompanySiteContractors } from "./SettingsCompanySiteContractors";
import { SettingsAddresses } from "../Miscellaneous/SettingsAddresses";
import { TRole } from "../../../constants/RoleConstants";
import { OmisDropdownMultiselectV2 } from "../../shared/OmisDropdowns";
import { OmisCol, OmisContainer, OmisRow, OmisStack, OmisSticky } from "../../shared/OmisLayouts";
import { DispatchParameterFields } from "./CreateDispatchParameter";

const SelectionStep = {
    Company: 1,
    Address: 2,
    Done: 3
}

export function CreateCompanySite(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const [selectedCompanyID, setSelectedCompanyID] = useState(null);
    const [selectedCompanyText, setSelectedCompanyText] = useState('');
    const [selectedAddressID, setSelectedAddressID] = useState(null);
    const [selectedAddressText, setSelectedAddressText] = useState('');
    const [selectionStep, setSelectionStep] = useState(SelectionStep.Company);
    const [skills, setSkills] = useState([]);
    const [priorities, setPriority] = useState([]);
    const [companySiteEmail, setCompanySiteEmail] = useState("");

    const [initialValues, setInitialValues] = useState({
        id: props.id ? props.id : null,
        companyID: props.rowVals?.companyID ?? selectedCompanyID,
        addressID: props.rowVals?.addressID ?? selectedAddressID,
        companySiteName: props.rowVals?.companySiteName ?? '',
        skillIDs: props.rowVals?.skillIDs ?? [],
        customerEditable: props.rowVals?.customerEditable === true ? true : false,

        headQuarter: props.rowVals?.headQuarter ?? false,
        reactionTime1: props.rowVals?.reactionTime1 ?? '',
        reactionTime2: props.rowVals?.reactionTime2 ?? '',
        smsReactionTime2Contacts: props.rowVals?.smsReactionTime2Contacts ?? '',
        smsAuthentication: props.rowVals?.smsAuthentication ?? '',
        clientNr: props.rowVals?.clientNr ?? '',
        lockFlag: props.rowVals?.lockFlag ?? false,        

        dispatchParameterID: props.rowVals?.dispatchParameterID ?? null,

        workDayNTFrom: props.rowVals?.workDayNTFrom ?? null,
        workDayNTTo: props.rowVals?.workDayNTTo ?? null,
        workDayNTEMail: props.rowVals?.workDayNTEMail ?? "",
        workDayOTEMail: props.rowVals?.workDayOTEMail ?? "",
        workDayNTLimitedPriorityEmail: props.rowVals?.workDayNTLimitedPriorityEmail ?? null,
        workDayOTLimitedPriorityEmail: props.rowVals?.workDayOTLimitedPriorityEmail ?? null,

        workDayFrNTFrom: props.rowVals?.workDayFrNTFrom ?? null,
        workDayFrNTTo: props.rowVals?.workDayFrNTTo ?? null,
        workDayFrNTEMail: props.rowVals?.workDayFrNTEMail ?? "",
        workDayFrOTEMail: props.rowVals?.workDayFrOTEMail ?? "",
        workDayFrNTLimitedPriorityEmail: props.rowVals?.workDayFrNTLimitedPriorityEmail ?? null,
        workDayFrOTLimitedPriorityEmail: props.rowVals?.workDayFrOTLimitedPriorityEmail ?? null,

        workDaySaNTFrom: props.rowVals?.workDaySaNTFrom ?? null,
        workDaySaNTTo: props.rowVals?.workDaySaNTTo ?? null,
        workDaySaNTEMail: props.rowVals?.workDaySaNTEMail ?? "",
        workDaySaOTEMail: props.rowVals?.workDaySaOTEMail ?? "",
        workDaySaNTLimitedPriorityEmail: props.rowVals?.workDaySaNTLimitedPriorityEmail ?? null,
        workDaySaOTLimitedPriorityEmail: props.rowVals?.workDaySaOTLimitedPriorityEmail ?? null,

        publicHolidayNTFrom: props.rowVals?.publicHolidayNTFrom ?? null,
        publicHolidayNTTo: props.rowVals?.publicHolidayNTTo ?? null,
        publicHolidayNTEMail: props.rowVals?.publicHolidayNTEMail ?? "",
        publicHolidayOTEMail: props.rowVals?.publicHolidayOTEMail ?? "",
        publicHolidayNTLimitedPriorityEmail: props.rowVals?.publicHolidayNTLimitedPriorityEmail ?? null,
        publicHolidayOTLimitedPriorityEmail: props.rowVals?.publicHolidayOTLimitedPriorityEmail ?? null,
        currentStep: 1
    });

    useEffect(() => {
        if (props.id && props.id > 0) {
            setSelectedCompanyID(props.rowVals?.companyID ?? null);
            setSelectedCompanyText(props.rowVals?.companyName ?? null);
            setSelectedAddressID(props.rowVals?.addressID ?? null);
            setSelectedAddressText(props.rowVals?.addressText ?? null);

            handleSingleEntry(SelectionStep.Company, props.rowVals);
            handleSingleEntry(SelectionStep.Address, props.rowVals);
            setSelectionStep(SelectionStep.Done);

            httpclientService.get(`/api/settings/companysite/getcompanysiteemail?companySiteID=${props.companySiteID}`).then(response => {
                setCompanySiteEmail(response);
            });

            const keys = Object.keys(initialValues);
            keys.forEach(key => {
                if (initialValues[key]) {
                    formRef.current.setFieldTouched(`${key}`, true, true);
                }
            });
        }

        if (skills.length === 0) {
            httpclientService.get(`/api/settings/companysite/getskillsfordropdown`).then((response) => {
                if (response) {
                    setSkills(response);
                }
            });
        }

        httpclientService.get("api/settings/faultkind/getfaultcategorylist").then(response => {
            setPriority(response);
        });
    },[]);

    function handleSave(values) {

        const keys = Object.keys(values);
        keys.forEach(key => {
            if (values[key] === '') {
                values[key] = null;
            }
        });

        httpclientService.post("/api/settings/companysite/savecompanysite", values).then((response) => {
            if (response) {
                if (response.status > 200) {
                    ;
                } else {
                    if (props.onClose) {
                        props.onClose(values);
                    } else {
                        navigate(-1);
                    }
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(-1);
        }
    }

    function handleCompanySelect(selection) {
        setSelectionStep(SelectionStep.Address);
        updateSelection(selection);
    }

    function handleCompanyDeselect() {
        setSelectionStep(SelectionStep.Company);
        clearSelection(SelectionStep.Company);
    }

    function handleAddressSelect(selection) {
        setSelectionStep(SelectionStep.Done);
        updateSelection(selection);
    }

    function handleAddressDeselect() {
        setSelectionStep(SelectionStep.Address);
        clearSelection(SelectionStep.Address);
    }

    function handleSingleEntry(selectionGrid, row) {
        switch (selectionGrid) {
            case SelectionStep.Company:
                setSelectionStep(SelectionStep.Address);
                updateSelection(row, SelectionStep.Company);
                break;
            case SelectionStep.Address:
                setSelectionStep(SelectionStep.Done);
                updateSelection(row, SelectionStep.Address);
                break;
            default:
                break;
        }
    }

    function clearSelection(selectedStep) {
        var clearCompany = selectedStep < SelectionStep.Address;
        var clearAddress = selectedStep < SelectionStep.Done;

        if (clearCompany) {
            setSelectedCompanyID(null);
            setSelectedCompanyText('');
            formRef.current.setFieldValue("companyID", null);
        }

        if (clearAddress) {
            setSelectedAddressID(null);
            setSelectedAddressText('');
            formRef.current.setFieldValue("addressID", null);
        }
    }

    function updateSelection(row,step) {
        switch (step ?? selectionStep) {
            case SelectionStep.Company:
                setSelectedCompanyID(row?.companyID ?? null);
                setSelectedCompanyText(row?.companyName ?? '');
                formRef.current.setFieldValue("companyID", row?.companyID ?? null);
                break;
            case SelectionStep.Address:
                setSelectedAddressID(row?.addressid ?? row?.addressID ??row?.id ?? null);
                setSelectedAddressText(row?.name ?? row?.lastName1 ?? row?.addressText ?? '');
                formRef.current.setFieldValue("addressID", row?.addressid ?? row?.addressID ?? row?.id ?? null);
                break;
            default:
                break;
        }
    }

    function CatalogueGrid(props) {
        const { step } = { ...props };

        switch (step) {
            case SelectionStep.Company:
                return (
                    <SettingsCompanySiteContractors
                        noHeaderText noExportButton hideBack
                        singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.Company)}
                        disableChipClick
                        removeArchived
                        rowclickCallback={handleCompanySelect}
                        searchByIDInList={props.id ? null : props.contractorID}
                        isInListCallback={handleSingleEntry.bind(this, SelectionStep.Company)}
                    />
                );
            case SelectionStep.Address:
                var addressurl = "/api/settings/settingsobjects/getpossibleaddresses";

                return (
                    <SettingsAddresses
                        noHeaderText noExportButton hideBack
                        singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.Address)}
                        removeArchived
                        rowclickCallback={handleAddressSelect} 
                        apiUrl={addressurl}
                        selectNewAddress={handleSingleEntry.bind(this, SelectionStep.Address)}
                        disableChipClick
                        {...props }
                    />);
            default:
                return null;
        }
    }

    function handleSelectedSkillsChange(event) {
        const {
            target: { value },
        } = event;

        // On autofill we get a stringified value.
        var _newArr = typeof value === 'string' ? value.split(',') : value;
        formRef.current.setFieldValue("skillIDs", _newArr.sort((a, b) => a - b));
    }

    function setCurrentStep(step) {
        const reValidate = formRef.current.values.currentStep !== step;
        formRef.current.setFieldValue("currentStep", step);

        if (reValidate) {
            var newInitialValues = { ...formRef.current.values };
            newInitialValues.currentStep = step;
            setInitialValues(newInitialValues);
        }
    }

    function emailCheckBoxClicked(event) {

        const checkType = event?.target?.name;
        const checkValue = event?.target?.checked;

        switch (checkType) {
            case "chkworkDayNTEMail":
            case "chkworkDayOTEMail":
            case "chkworkDayFrNTEMail":
            case "chkworkDayFrOTEMail":
            case "chkworkDaySaNTEMail":
            case "chkworkDaySaOTEMail":
            case "chkpublicHolidayNTEMail":
            case "chkpublicHolidayOTEMail":
                if (checkValue) {
                    const fieldStr = checkType.replace("chk", "");
                    formRef.current.setFieldValue(fieldStr, companySiteEmail);
                }
                break;


            case "fridayUseMoThValues":
                setFieldValuesFromMoTh("workDayFr", checkValue);
                break;
            case "saturdayUseMoThValues":
                setFieldValuesFromMoTh("workDaySa", checkValue);
                break;
            case "sundayUseMoThValues":
                setFieldValuesFromMoTh("publicHoliday", checkValue);
                break;
            default:
                break;
        }
    }

    function setFieldValuesFromMoTh(dayCode, checked) {
        if (checked) {
            formRef.current.setFieldValue(`${dayCode}NTFrom`, formRef.current.values.workDayNTFrom);
            formRef.current.setFieldValue(`${dayCode}NTTo`, formRef.current.values.workDayNTTo);
            formRef.current.setFieldValue(`${dayCode}NTEMail`, formRef.current.values.workDayNTEMail);
            formRef.current.setFieldValue(`${dayCode}OTEMail`, formRef.current.values.workDayOTEMail);
            formRef.current.setFieldValue(`${dayCode}NTLimitedPriorityEmail`, formRef.current.values.workDayNTLimitedPriorityEmail);
            formRef.current.setFieldValue(`${dayCode}OTLimitedPriorityEmail`, formRef.current.values.workDayOTLimitedPriorityEmail);
            formRef.current.setFieldValue(`chk${dayCode}NTEMail`, formRef.current.values.chkworkDayNTEMail);
            formRef.current.setFieldValue(`chk${dayCode}OTEMail`, formRef.current.values.chkworkDayOTEMail);

            const obj = Object();
            obj[`${dayCode}NTFrom`] = new Date(formRef.current.values.workDayNTFrom);
            obj[`${dayCode}NTTo`] = new Date(formRef.current.values.workDayNTTo);
            var tmpInit = {
                ...formRef.current.values,
                ...obj
            };

            setInitialValues(tmpInit);

        }
    }

    const [schema, setSchema] = useState({
        companyID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        addressID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        companySiteName: Yup.string()
            .required(t("GenericRequiredText"))
            .max(60, t('Error_TextTooLong', { 1: 60 })),
        clientNr: Yup.string()
            .nullable()
            .max(20, t('Error_TextTooLong', { 1: 20 })),
        reactionTime1: Yup.number(t("InvalidFormat_OnlyNumbers"))
            .nullable()
            .typeError(t("InvalidFormat_OnlyNumbers"))
            .min(0),
        reactionTime2: Yup.number(t("InvalidFormat_OnlyNumbers"))
            .nullable()
            .typeError(t("InvalidFormat_OnlyNumbers"))
            .min(0),
        smsReactionTime2Contacts: Yup.string()
            .nullable()
            .max(500, t('Error_TextTooLong', { 1: 500 })),

        workDayNTFrom: Yup.lazy(() => {
            if (formRef.current.values.currentStep > 1) {
                return Yup.date()
                    .typeError(t("GenericRequiredText"))
                    .required(t("GenericRequiredText"));
            } else {
                return Yup.date().notRequired().nullable();
            }
        }),
        workDayNTTo: Yup.lazy(() => {
            if (formRef.current.values.currentStep > 1) {
                return Yup.date()
                    .min(
                        Yup.ref('workDayNTFrom'),
                        ({ min }) => t("OnlineImport_InvalidTime"),
                    )
                    .typeError(t("GenericRequiredText"))
                    .required(t("GenericRequiredText"));
            } else {
                return Yup.date().notRequired().nullable();
            }
        }),
        workDayFrNTFrom: Yup.lazy(() => {
            if (formRef.current.values.currentStep > 1) {
                return Yup.date()
                    .typeError(t("GenericRequiredText"))
                    .required(t("GenericRequiredText"));
            } else {
                return Yup.date().notRequired().nullable();
            }
        }),
        workDayFrNTTo: Yup.lazy(() => {
            if (formRef.current.values.currentStep > 1) {
                return Yup.date()
                    .min(
                        Yup.ref('workDayFrNTFrom'),
                        ({ min }) => t("OnlineImport_InvalidTime"),
                    )
                    .typeError(t("GenericRequiredText"))
                    .required(t("GenericRequiredText"));
            } else {
                return Yup.date().notRequired().nullable();
            }
        }),
        workDaySaNTFrom: Yup.lazy(() => {
            if (formRef.current.values.currentStep > 1) {
                return Yup.date()
                    .typeError(t("GenericRequiredText"))
                    .required(t("GenericRequiredText"));
            } else {
                return Yup.date().notRequired().nullable();
            }
        }),
        workDaySaNTTo: Yup.lazy(() => {
            if (formRef.current.values.currentStep > 1) {
                return Yup.date()
                    .min(
                        Yup.ref('workDaySaNTFrom'),
                        ({ min }) => t("OnlineImport_InvalidTime"),
                    )
                    .typeError(t("GenericRequiredText"))
                    .required(t("GenericRequiredText"));
            } else {
                return Yup.date().notRequired().nullable();
            }
        }),
        publicHolidayNTFrom: Yup.lazy(() => {
            if (formRef.current.values.currentStep > 1) {
                return Yup.date()
                    .typeError(t("GenericRequiredText"))
                    .required(t("GenericRequiredText"));
            } else {
                return Yup.date().notRequired().nullable();
            }
        }),
        publicHolidayNTTo: Yup.lazy(() => {
            if (formRef.current.values.currentStep > 1) {
                return Yup.date()
                    .min(
                        Yup.ref('publicHolidayNTFrom'),
                        ({ min }) => t("OnlineImport_InvalidTime"),
                    )
                    .typeError(t("GenericRequiredText"))
                    .required(t("GenericRequiredText"));
            } else {
                return Yup.date().notRequired().nullable();
            }
        }),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        <OmisSticky>
                            <OmisContainer fluid>
                                <OmisRow>
                                    <OmisCol xs={6} >
                                        {
                                            initialValues.id === null ?
                                                <h1>{t("New_CompanySite")}</h1>
                                                :
                                                null
                                        }
                                    </OmisCol>
                                    <OmisCol style={{ display: 'flex', justifyContent: 'right' }} xs={6}>
                                        {formik.values.currentStep === 1 ? <>
                                            <OmisStack direction="horizontal" gap={1}>
                                                <div className="p-2"><OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary></div>
                                                <div className="p-2"><OmisButtonPrimary id={"nextStepButton"} disabled={!formik.isValid} text={t("NextStep")} onClick={() => setCurrentStep(2)}></OmisButtonPrimary></div>
                                            </OmisStack>

                                        </> : <>
                                            <OmisStack direction="horizontal" gap={1}>
                                                <div className="p-2"><OmisButtonSecondary id={"previousStepButton"} text={t("PreviousStep")} onClick={() => setCurrentStep(1)}></OmisButtonSecondary></div>
                                                    <div className="p-2"><OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit></div>
                                            </OmisStack>
                                        </>}
                                    </OmisCol>
                                </OmisRow>
                                <OmisRow>
                                    <OmisCol> <OmisProgress variant="determinate" value={formik.isValid || formik.values.currentStep === 2 ? 100 : 0} />(1) {t("Details")}  </OmisCol>
                                    <OmisCol> <OmisProgress variant="determinate" value={formik.values.currentStep === 2 && formik.isValid ? 100 : 0} />(2) {t("Settings_DispatchParameter")} </OmisCol>
                                </OmisRow>
                            </OmisContainer>
                        </OmisSticky>

                        {
                            formik.values.currentStep === 1 ?
                                <>
                                    <OmisContainer fluid>
                                        <OmisRow>
                                            <Card variant={"outlined"}>
                                                <OmisRow>
                                                    <OmisCol xs={4}>
                                                        <OmisSelectedCatalogueCard
                                                            text={`${t("Company")} (${selectedCompanyText})`}
                                                            id={selectedCompanyText}
                                                            handleDeselect={handleCompanyDeselect}
                                                            disabled={props.rowVals}
                                                            required
                                                        />
                                                    </OmisCol>
                                                    <OmisCol xs={4}>
                                                        <OmisSelectedCatalogueCard
                                                            text={`${t("Address")} (${selectedAddressText})`}
                                                            id={selectedAddressText}
                                                            handleDeselect={handleAddressDeselect}
                                                            disabled={props.rowVals}
                                                            required
                                                        />
                                                    </OmisCol>
                                                    <OmisCol xs={4}>
                                                        <br />
                                                        <OmisTextBox labeltext={t("Name")} name={"companySiteName"} placeholder={t("Name")} required />
                                                    </OmisCol>
                                                </OmisRow>
                                                <br />
                                                <OmisRow>
                                                    <CatalogueGrid step={selectionStep} {...props} />
                                                </OmisRow>
                                                <br />
                                                <OmisRow>

                                                    <OmisCol xs={4}>
                                                        <OmisDropdownMultiselectV2 id="skillIDs" name={"skillIDs"} items={skills} onChange={handleSelectedSkillsChange} value={formik.values.skillIDs} labeltext={t("Skills")} />
                                                    </OmisCol>
                                                    {
                                                        AuthService.isFSMMaster() ?
                                                            <OmisCol xs={4}>
                                                                <OmisCheckBox labeltext={t("CustomerEditable")} name="customerEditable" />
                                                            </OmisCol>
                                                            :
                                                            null
                                                    }

                                                    <OmisCol xs={4}>
                                                        <OmisCheckBox labeltext={t("CompanySite_Headquarter")} name="headQuarter" />
                                                    </OmisCol>
                                                    <OmisCol xs={4}>
                                                        <OmisCheckBox labeltext={t("LockFlag")} name="lockFlag" />
                                                    </OmisCol>
                                                </OmisRow>
                                            </Card>
                                        </OmisRow>
                                        <br />

                                        {
                                            AuthService.hasRole(TRole.SMSRegistration) ?
                                                <OmisRow>
                                                    <Card variant={"outlined"}>
                                                        <span>{t("SMSRegistration_InfoText")}</span>
                                                        <OmisRow>
                                                            <OmisCol xs={4}>
                                                                <OmisTextBox labeltext={t("Customer_Nr")} name={"clientNr"} placeholder={t("Customer_Nr")} />
                                                            </OmisCol>
                                                            <OmisCol xs={4}>
                                                                <OmisTextBox labeltext={t("CompanySite_SMSReactionTime1")} name={"reactionTime1"} placeholder={t("CompanySite_SMSReactionTime1")} type='number' />
                                                            </OmisCol>
                                                            <OmisCol xs={4}>
                                                                <OmisTextBox labeltext={t("CompanySite_SMSReactionTime2")} name={"reactionTime2"} placeholder={t("CompanySite_SMSReactionTime2")} type='number' />
                                                            </OmisCol>
                                                            <OmisCol xs={4}>
                                                                <OmisTextBox labeltext={t("CompanySite_SMSAuthentication")} name={"smsAuthentication"} placeholder={t("CompanySite_SMSAuthentication")} />
                                                            </OmisCol>
                                                            <OmisCol xs={4}>
                                                                <OmisTextBox labeltext={t("CompanySite_SMSReactionTime2Contacts")} name={"smsReactionTime2Contacts"} placeholder={t("CompanySite_SMSReactionTime2Contacts")} />
                                                            </OmisCol>
                                                        </OmisRow>
                                                    </Card>
                                                </OmisRow>
                                                :
                                                null
                                        }
                                    </OmisContainer>
                                </>
                                :
                                <>
                                    <OmisContainer fluid>
                                        <DispatchParameterFields emailCheckBoxClicked={emailCheckBoxClicked} priorities={priorities} values={formik.values} companySiteEmail={companySiteEmail} />                                        
                                    </OmisContainer>
                                </>
                        }

                    </Form>
                )}
            </Formik>
        </>
    );
}