import React, {useState} from 'react';
import { Link as RouterLink } from "react-router-dom";
import { t } from "i18next";
import { TabConstants } from '../../../constants/SettingConstants';
import { EditFilterDefs } from './EditFilterDefs';
import { OmisGrid } from '../../shared/OmisGrids';
import { OmisDialog } from '../../shared/OmisDisplays';
import { OmisIconButton } from '../../shared/OmisButtons';
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { OmisCol } from '../../shared/OmisLayouts';

var state = { selectedtabvalue: TabConstants.System }

export function System_FilterDefs(props) {
    var url = "/api/settings/system/getfilterdefs";
    const [modalHeight, setModalHeight] = useState(300);
    const [modalWidth, setModalWidth] = useState("sm");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    function handleEditClick(rowID, e) {
        setModalTitle(t("Action_Edit"));
        setModalContent(<EditFilterDefs onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID} rowVals={e} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    return (
        <div className="omis-header-box">
            <OmisCol xs={1}>
                <OmisIconButton id={"backButton"}
                    iconcontent={<ArrowBackIcon fontSize="inherit" />}
                    text="Back" component={RouterLink} to={"/settings"} state={state} />
            </OmisCol>

            <OmisGrid actiondropdown={{ edit: handleEditClick }} headerText={t("SettingsFilterDefs")}
                apiUrl={url}
                change={changeCounter}
                noExportButton
                {...props} />

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={modalWidth}
                title={modalTitle}
                content={modalContent}
                contentHeight={modalHeight}
            />
        </div>
    );
}