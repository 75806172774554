import React, { useState} from 'react';
import httpclientService from "../../../services/httpclient.service";
import { t } from "i18next";
import { DetailsCardWithSkeleton } from '../../shared/Shared';
import { UserContactsList, UserDetails } from '../../profile/Profile';
import { OmisSkeleton } from '../../shared/OmisProgressDisplays';
import { OmisCol, OmisRow } from "../../shared/OmisLayouts";

var api = "/api/profile/";

export function User(props) {

    return (
      <div>User index</div>
    );
}

export function UserDetailsModal(props) {

    const [userInfo, setUserInfo] = useState(false);
    const [loading, setLoading] = useState(true);
    httpclientService.get(`/api/profile/getuserbyusername?username=${props.userName}`)
        .then((response) => {
            if (response) {
                setUserInfo(response);
            }
            setLoading(false);
        });

    return (
        <div>
            {
                userInfo ?
                    <OmisRow>
                        <OmisCol xs={12}>
                            <DetailsCardWithSkeleton
                                url={`${api}getgroupsforuser?customerNr=${userInfo.customerNr}&username=${userInfo.username}`}
                                skeletonheight={"200px"}
                                content={<UserDetails hideLanguageDropDown={ true} currentUser={userInfo} {...props} />}
                                {...props}
                            />
                        </OmisCol>

                        <OmisCol xs={12}>
                            <DetailsCardWithSkeleton
                                url={`${api}getusercontacts?customerNr=${userInfo.customerNr}&username=${userInfo.username}`}
                                skeletonheight={"200px"}
                                content={<UserContactsList userdata={userInfo} {...props} />}
                                {...props}
                            />
                        </OmisCol>
                        {/*<OmisCol xs={12}>*/}
                        {/*    <DetailsCardWithSkeleton*/}
                        {/*        url={`${api}getuseraddress?customerNr=${userInfo.customerNr}&username=${userInfo.username}`}*/}
                        {/*        skeletonheight={"100px"}*/}
                        {/*        content={<UserAddressDetailsInfo hideMap={true} {...props} />}*/}
                        {/*        {...props}*/}
                        {/*    />*/}
                        {/*</OmisCol>*/}
                    </OmisRow>
                    :
                        loading ?
                            <OmisSkeleton height={"420px"} /> 
                        :
                        <strong>{t("NoResultsFound")}</strong>
            }
        </div>
    );
}