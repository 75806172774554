import React, { useState } from 'react';
import { OmisAutoComplete } from "./OmisAutoCompletes";
import { Autocomplete, Checkbox, FormControl, IconButton, InputLabel, ListItemText, MenuItem, RadioGroup, FormControlLabel, Radio, TextField, OutlinedInput } from "@mui/material";
import { Search as SearchIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Select from '@mui/material/Select';
import {OmisLabel} from "./OmisDisplays";
import { OmisAvatar } from '../profile/OmisAvatar';
import { useField } from "formik";
import {t} from "i18next";
import { useEffect } from 'react';
import { OmisSearchBar } from './OmisInputs';
import { OmisCol, OmisRow, OmisStack } from './OmisLayouts';

export function OmisDropdown(props) {
    const [field, meta] = useField(props);
    const [filteredValues, setFilteredValues] = useState([]);
    const [chooseActionKey, setChooseActionKey] = useState('0');

    useEffect(() => {
        if (props.items?.length > 0) {
            setFilteredValues(props.items);

            const exists = props.items.some(v => (v.key === 0));
            if (exists) {
                setChooseActionKey('-1')
            }
        }
    }, [props.items]);
    return (
        <>
            {
                props.labeltext ?
                    <OmisLabel id={props.id + "-label"} label={props.labeltext} required={props.required && !props.disabled} sx={{ opacity: props.disabled ? "0.5" : "" }}></OmisLabel>
                    :
                    null
            }
            <TextField
                sx={{ width: "100%" }}
                className="omis-select"
                variant="standard"
                id={field.name}
                name={field.name}
                select
                value={field.value ?? chooseActionKey}
                onChange={(e) => {
                    field.onChange(e);
                    if (props.onChange) {
                        props.onChange(e);
                    }
                }}
                onBlur={field.onBlur}
                helperText={meta.touched && meta.error ? meta.error : ""}
                error={meta.touched && Boolean(meta.error)}
                size={"small"}
                disabled={props.disabled}
            >
                <OmisSearchBar getSearchResultItems={setFilteredValues} listItems={props.items} listAttr={["displayText"]} />
                {
                    <MenuItem key={chooseActionKey} value= { chooseActionKey } >{t("Choose_Action")}</MenuItem>
            }
                {
                    Array.isArray(filteredValues) ?
                        [
                            ...filteredValues.filter(f => field.value === f.key).sort((a, b) => a - b),
                            ...filteredValues.filter(f => field.value !== f.key).sort((a, b) => a - b),
                        ].map((option) => (
                            <MenuItem key={option.key} value={option.key} disabled={option.disabled}>
                                {option.displayText}
                            </MenuItem>
                        ))
                        : null
                }
            </TextField>
        </>
    );
}

export function OmisDropdownNoForm(props) {
    const dropdownID = props.id;
    return (
        <FormControl size={"small"} className={"omis-select"} sx={{ height: '2.973em' }} fullWidth>
            {props.labeltext && (<OmisLabel id={props.id + "-label"} label={props.labeltext} tooltip={props.tooltip} required={props.required && !props.disabled} sx={{ opacity: props.disabled ? "0.5" : "" }}></OmisLabel>)}
            <Select size={"small"}
                //disableUnderline={"true"}
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, maxHeight: '2.2em', textAlign: 'center !important'  }}
                name={props.name}
                labelId={props.id + "-label"} id={props.id} value={props?.value ?? ''} label={props.labeltext} onChange={(e) => {
                    if (props.onChange) {
                        e.target.id = dropdownID;
                        props.onChange(e);
                    }
                }}
                renderValue={(selected) => {
                    var selectedItem = Array.isArray(selected) ? selected[0] : selected;
                    return props.items?.filter(x => x.key === selectedItem)[0]?.displayText;
                }}>
                <MenuItem key='0' value=''>{t("Choose_Action")}</MenuItem>
                {
                    props.items?.map((item,index) => {
                        return (
                            <MenuItem key={index} value={item.key}>
                                <OmisStack direction="horizontal" gap={3}>
                                    <OmisCol xs={props.deletecallback ? 8 : 12} style={{ display: 'inline-table' }}>
                                        {item.displayText}
                                    </OmisCol>
                                    {
                                        props.deletecallback ?
                                            <OmisCol xs={3}>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    size="small"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        props.deletecallback(item.key);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </OmisCol>
                                            : null
                                        }
                                </OmisStack>
                            </MenuItem> 
                        );                           
                    })
                }
            </Select>
        </FormControl>
    );
}

export function OmisDropdownMultiselect(props) {

    const [field, state, { setValue, setTouched }] = useField(props);

    const [listValues, setListValues] = useState([]);
    const [filteredValues, setFilteredValues] = useState(props.items);

    useEffect(() => {
        if (props.items && props.items.length > 0) {
            setListValues(props.items);
        }
    }, [props.items]);

    useEffect(() => {
        setFilteredValues(listValues);
    }, [listValues]);

    // value is an array now
    const onChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <FormControl className={"omis-select"} fullWidth>
            <OmisLabel id={props.id + "-label"} label={props.labeltext} required={props.required && !props.disabled} sx={{ opacity: props.disabled ? "0.5" : "" }}></OmisLabel>
            <Select label={props.labeltext} 
			//disableUnderline={"true"}
					sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
			multiple size={"small"} labelId={props.id + "-label"} id={props.id} value={state?.value} onChange={onChange} onBlur={setTouched}
                renderValue={(selected) => {
                    //return props.items.filter((f) => selected.indexOf(f.key) > -1).map((item) => item.displayText).join(", ");
                    return `${selected.length} ${props.labeltext ? props.labeltext : ''} ${t("CheckListItem_Checked")}`;
                }}>
                <OmisSearchBar getSearchResultItems={setFilteredValues} listItems={listValues} listAttr={["displayText"]} />
                {
                    Array.isArray(filteredValues) ?
                        [
                            ...filteredValues.filter(f => state?.value.indexOf(f.key) > -1).sort((a, b) => a - b),
                            ...filteredValues.filter(f => state?.value.indexOf(f.key) === -1).sort((a, b) => a - b),
                        ].map((item) => (
                            <MenuItem key={item.key} value={item.key}>
                                <Checkbox checked={state?.value.map(x => x.toString()).indexOf(item.key.toString()) > -1} />
                            <ListItemText primary={item.displayText} />
                        </MenuItem>
                    ))
                        : null
                }
            </Select>
        </FormControl>
    );
}

export function OmisDropdownAvatar(props)
{
    var displayText = "";
    if (props.value) {
        var name = props.items.filter(f => f.key === props.value[0])[0].displayText;
        displayText = <><OmisAvatar name={name}/> {name}</>;
    } else {
        displayText = <><SearchIcon /> {props?.labeltext ?? ""}</>
    }    

    return (     
        <FormControl className={"omis-select"} fullWidth size='small'>
            <InputLabel id={props.id + "-label"} {...props}> {displayText}</InputLabel>
            <Select label={props?.labeltext ?? ""}
			//disableUnderline={"true"}
                value=""
					sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
			size={"small"} labelId={props.id + "-label"} id={props.id} onChange={props.onChange}>
            {
                props.items?.map((item) => {
                    return <MenuItem key={item.key} value={item.key}>
                        <OmisAvatar name={item.displayText}/>
                        <ListItemText primary={item.displayText} />
                    </MenuItem>
                })
            }
            </Select>
        </FormControl>
    );
}

export function OmisDropdownRadio(props)
{
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedValueText, setSelectedValueText] = useState('');
    var foundItems = [];
    useEffect(() => {
        if (props.items?.length > 0 && props.value) {
            foundItems = props.items.filter((item) => item.key === props.value);
            if (foundItems.length > 0) {
                var tmpVal = foundItems[0].key;
                var tmpValText = foundItems[0].displayText;
                setSelectedValue(tmpVal);
                setSelectedValueText(tmpValText);
            }
        }
    }, [props.items,props.value])

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        var selectedVal = parseInt(event.target.value);
        foundItems = props.items.filter((item) => item.key === selectedVal);
        if (foundItems.length > 0) {
            var tmpVal = foundItems[0].key;
            var tmpValText = foundItems[0].displayText;
            setSelectedValue(tmpVal);
            setSelectedValueText(tmpValText);
        }

        if (props.onChange) {
            props.onChange(event);
        }
    };

    return (     
        <FormControl className={"omis-select"} fullWidth size='small'>
            {/*<InputLabel id={props.id + "-label"} {...props}><SearchIcon />{props.labeltext}</InputLabel>*/} {/*TODO : search icon css should be fixed.*/}
            <Select label={props.labeltext} 
			//disableUnderline={"true"}
					sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
			size={"small"} labelId={props.id + "-label"} id={props.id} value={props.items?.length > 0 ? selectedValue:''} onChange={handleChange} /*TODO : value gives 'You have provided an out-of-range value ' error.*/
                renderValue={(selected) => `${selectedValueText !== '' ? `${selectedValueText} ${t("CheckListItem_Checked")}` : selectedValueText}`}>
            {   
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group" onChange={handleChange} >
            {
                            props.items?.map((item) => {
                                return <FormControlLabel key={item.key} value={item.key} checked={item.key === selectedValue} control={<Radio />} label={item.displayText} />
            })}
            </RadioGroup>
        }
            </Select>
        </FormControl>
    );
}

export function OmisDropdownMultiselectV2(props) {

    const [itemName, setItemName] = useState([]);
    const [disableitemlist, setDisableItemList] = useState([]);
    const [listValues, setListValues] = useState(props.items);
    const [filteredValues, setFilteredValues] = useState(props.items);
    const [selectionCount, setSelectionCount] = useState(0);
    const [lastChangeEvent, setLastChangeEvent] = useState(null);

    useEffect(() => {
        if (props.items && props.items.length > 0) {
            setListValues(props.items);
        }
    }, [props.items]);

    useEffect(() => {
        setFilteredValues(listValues);
    }, [listValues]);


    useEffect(() => {
        if (props.value && itemName.length !== props.value.length) {
            setItemName(props.value);
        }        
    }, [props.value]);

    useEffect(() => {
        if (props.disableitemlist && disableitemlist.length !== props.disableitemlist.length) {
            setDisableItemList(props.disableitemlist);
        }
    }, [props.disableitemlist]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        // On autofill we get a stringified value.
        var _newArr = typeof value === 'string' ? value.split(',') : value;//gives selected values in array
        setSelectionCount(props.items.filter(o1 => _newArr.indexOf(o1.key) > -1).length); //total count of selected items in the drop-down list        
        setItemName(_newArr.sort((a, b) => a - b));
        setLastChangeEvent(event);
    };

    return (
        <div>
            <FormControl size={"small"} sx={{ height: '2.973em' }} className={"omis-select"} fullWidth ref={props.inputRef} onKeyDown={props.onKeyDown}>
                <OmisLabel id={props.id + "-label"} label={props.labeltext} required={props.required && !props.disabled} sx={{ opacity: props.disabled ? "0.5" : "" }}></OmisLabel>
                <Select
                    id={props.id + "-label"}
                    labelId={props.id + "multiple-checkbox-label"}
                    name={props.name}
                    multiple
                    value={itemName}
                    onChange={(event) => {
                        if (props.items.filter(o1 => event.target.value.indexOf(o1.key) > -1).length == 0) {
                            event.target.value = [];
                        }
                        handleChange(event);
                    }}
                    input={<OutlinedInput label={props.labeltext} />} /* commented because of rendering few times */
                    renderValue={(selected) => `${selected.length > 0 ? selected.length : itemName.length > 0 && props.items.length > 0 ? props.items.filter((f) => itemName.indexOf(f.key) > -1).map((item) => item.displayText) : selected.length} ${props.labeltext ? props.labeltext : ''} ${t("CheckListItem_Checked")}`}
                    size={"small"}
                    onClose={() => {
                        if (props.onChange && lastChangeEvent?.target?.value) {
                            props.onChange(lastChangeEvent);
                        }
                    }}
                    MenuProps={{ PaperProps: { sx: { maxHeight: "50%;" } } }}
                    //disableUnderline={"true"}
					sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, maxHeight: '2.2em', textAlign: 'center !important' }}
                >
                    <OmisSearchBar getSearchResultItems={setFilteredValues} listItems={listValues} listAttr={["displayText"]} />
                    {Array.isArray(filteredValues) ?
                        [
                            ...filteredValues.filter(f => itemName.indexOf(f.key) > -1).sort((a, b) => a - b),
                            ...filteredValues.filter(f => itemName.indexOf(f.key) === -1).sort((a, b) => a - b),
                        ].map((name) => (
                            <MenuItem key={name.key} value={name.key} className={"omisDropDownBox"} disabled={disableitemlist.indexOf(name.key) > -1 && !(props.value?.indexOf(name.key) > -1)}>
                                <Checkbox checked={itemName.indexOf(name.key) > -1} />
                                <ListItemText primary={name.displayText} />
                            </MenuItem>
                        ))
                        : null
                    }
                </Select>
            </FormControl>
        </div>
    );
}

export function OmisDropdownMultiselectBySearch(props) {
    return (
        <div>
            <Autocomplete 
                multiple
				defaultValue={[]}
                limitTags={1}
                id="searchableSelect"
                options={props.items ?? []}
                disableCloseOnSelect
                getOptionLabel={(option) => option.displayText}
                renderOption={(props, option, { selected }) => (
                    <li {...props} >
                        <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.displayText}
                    </li>
                )}
                componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                renderInput={(params) => (
                    <TextField {...params} label={props.labeltext} />
                )}
				renderTags={(values) =>`${values?.length} ${props.labeltext ? props.labeltext : ''} selected` }
				variant="standard"
				fullWidth={true}
				size='small'
            />
        </div>
    );
}


export function ObjectDropdown(props) {
    return (
        <OmisAutoComplete apiurl={"api/objects/getobjectlistfordropdown"} labelText={t("Objekt")} id={"ObjectID"} />
    );
}

export function EquipmentDropdown(props) {
    return (
        <OmisAutoComplete apiurl={"api/equipments/getequilistfordropdown"} labelText={t("Equipment")} id={"EquipID"}/>
    );
}