import React, { useEffect, useState } from "react";
import httpclientService from "../../services/httpclient.service";
import { Card, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { t } from "i18next";
import { OmisAutosizeTextAreaNoForm, OmisTextBoxNoForm } from "../shared/OmisInputs";
import { OmisLabel } from "../shared/OmisDisplays";
import { OmisButtonPrimary, OmisButtonSecondary } from "../shared/OmisButtons";
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";

export function DetailsCheckListsInfo(props) {
    const {state} = {...props};
    const [_list, setCheckList] = useState([]);

    useEffect(() => {
        if (!props.checkList || props.checkList?.length === 0) {
            httpclientService.get(`api/inspections/getchecklists?id=${state?.inspectionid}`).then((response) => {
                setCheckList(response);
                if (props.handleCheckListValueChange) {
                    props.handleCheckListValueChange(response);
                }
            });
        } else {
            if (_list.length === 0 && props.checkList?.length > 0) {
                setCheckList(props.checkList);
            }
        }
    }, []);

    useEffect(() => {
        <RenderChecklistInfos list={ _list } handleRadioChange={ handleRadioChange } { ...props } />
        if (props.selectionsUpdated && _list.length > 0 && _list.filter((row) => (row.status !== null)).length === _list.length) {
            props.selectionsUpdated(true);
        }
    }, [_list]);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const myNextList = [..._list];
        var selection = event.target.value;
        var index = parseInt(event.target.name);
        myNextList[index] = {
            ...myNextList[index],
            status: selection
        }
        setCheckList(myNextList);
    }

    return (
        <RenderChecklistInfos list={ _list } handleRadioChange={ handleRadioChange } { ...props } />);
}

function RenderChecklistInfos(props) {


    const [_checklist, setCheckList] = useState([]);

    useEffect(() => {
        if (props.list && props.list?.length>0) {
            setCheckList(props.list);
        }
    }, [props.list]);

    function handleCheckListValueChange(itemColumn,e) {
        var tmp = _checklist[e.target.name];
        if (itemColumn === "status") {
            var isTrueSet = /^true$/i.test(e.target.value);
            tmp[itemColumn] = (e.target.value === null || e.target.value === 'null') ? null : isTrueSet;
        } else {
            tmp[itemColumn] = e.target.value;
        }

        if (props.handleCheckListValueChange) {
            props.handleCheckListValueChange(_checklist);
        }
    }

    return (
        _checklist.map((item, stepKey) => (
            <OmisContainer fluid key={stepKey} name={stepKey}>
                <OmisRow>
                    <p className={"bold"}>{item.title}</p>
                    <Card variant={"outlined"}>
                        <p className={"bold"}>{t("Description")}</p>
                        <p>{item.description}</p>
                        <OmisRow>
                            {
                                item.okNotOkRequired || item.okVisible || item.notOkVisible ?
                                    <OmisCol xs={4}>
                                        <Card variant={"outlined"}>
                                            <br />
                                            <RadioGroup
                                                id={stepKey.toString()}
                                                name={stepKey.toString()}
                                                value={item.status}
                                                required={item.okNotOkRequired}
                                                onChange={handleCheckListValueChange.bind(this, "status")}
                                            >
                                                {
                                                    item.okNotOkRequired && props.isEdit ?
                                                        <OmisLabel label={item.status === null ? t("GenericRequiredText") : ""} required={item.status === null ? item.okNotOkRequired : false} />
                                                        :
                                                        null

                                                }
                                                <br />
                                                {
                                                    item.okVisible ?
                                                        <FormControlLabel value={true} control={
                                                            <Radio />} label={item.okText} disabled={!props.isEdit} />
                                                        :
                                                        null
                                                }
                                                <br/>
                                                {
                                                    item.notOkVisible ?
                                                        <FormControlLabel value={false} control={
                                                            <Radio />} label={item.notOkText} disabled={!props.isEdit} />
                                                        :
                                                        null
                                                }
                                                <br />
                                                {
                                                    item.status !== null && props.isEdit ?
                                                        <OmisButtonSecondary id={"clearButton"} text={t("Selection_Clear")} onClick={(e) => {
                                                            var tmp = { ...e };
                                                            tmp.target.value = null;
                                                            tmp.target.name = stepKey.toString();
                                                            return handleCheckListValueChange("status", tmp);
                                                        }} />
                                                        :
                                                        null
                                                }
                                            </RadioGroup>
                                        </Card> {(item.documentName && item.documentName?.trim() !== '') ?
                                            <Card>
                                                <p className={"bold"}>{t("Documents")}</p>
                                                <a className={"bold"} href={item.documentPath}>{item.documentName}</a>
                                            </Card> : null}

                                    </OmisCol>
                                    :
                                    null


                            }
                            <OmisCol xs={8}>
                                <Card variant={"outlined"}>

                                        {
                                            item.valueVisible ?
                                                props.isEdit ?
                                                    <OmisCol xs={12}>
                                                        <OmisTextBoxNoForm id={stepKey} name={stepKey} label={t("Value")} onChange={handleCheckListValueChange.bind(this, "value")} required={item.valueRequired} defaultValue={item.value} />
                                                    </OmisCol>
                                                    :
                                                    item.value ?
                                                    <OmisCol xs={12}>
                                                            <p>{t("Value")}</p>
                                                            {item.value}
                                                        </OmisCol>
                                                        :
                                                        null
                                                : null
                                        }
                                        {
                                            item.noteVisible ?
                                                props.isEdit ?
                                                <OmisCol xs={12}>
                                                        <OmisAutosizeTextAreaNoForm label={t("Comment")} id={stepKey} name={stepKey} onChange={handleCheckListValueChange.bind(this, "note")} required={item.noteRequired} value={item.note ?? ""} />
                                                    </OmisCol>
                                                    :
                                                <OmisCol xs={12}>
                                                        <p>{t("Comment")}</p>
                                                        <p>{item.note}</p>
                                                    </OmisCol>
                                                : null
                                        }
                                </Card>
                            </OmisCol>
                            {
                                item.status !== null && !item.status && item.showCreateReportButton ?
                                    <OmisCol xs={4}>
                                        <OmisButtonPrimary id={"generateReportButton"} text={t("Generate_Report")} onClick={props.handleGenerateReport.bind(this, item)} />
                                    </OmisCol>
                                    :
                                    null
                            }
                        </OmisRow>
                    </Card>
                </OmisRow>
            </OmisContainer>
        ))
    );
}