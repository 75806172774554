/* eslint-disable no-unused-vars */
import DialogActions from "@mui/material/DialogActions";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import httpClientService from '../../../services/httpclient.service';
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisLabel } from '../../shared/OmisDisplays';
import { OmisDropdown } from '../../shared/OmisDropdowns';
import { OmisGrid } from "../../shared/OmisGrids";
import { OmisTextBox } from '../../shared/OmisInputs';
import { OmisCol, OmisRow } from '../../shared/OmisLayouts';

export default function FaultEquiClassDefPopup_Content(props) {
    const { t } = useTranslation();
    const formRef = useRef();
    const [selectedRowIDs, setSelectedRowIDs] = useState([]);

    const [faultEquiClassGroups, setFaultEquiClassGroups] = useState([]);
    const [initialValues, setInitialValues] = useState({
        id: 0,
        archive: false,
        equiClassIDs: [],
        faultEquiClassDefDesc: '',
        faultEquiClassGroupID: '',
        sortIndex: 0
    });

    const editItem = Boolean(props.selectedRowID > 0)
    useEffect(() => {
        if (editItem) {
            httpClientService.get(`api/settings/faultequiclassdef/get?id=${props.selectedRowID}`).then(response => {
                formRef.current.setValues(response);
                setSelectedRowIDs(response.equiClassIDs);
            })
        }

        httpClientService.get("api/settings/faultequiclassdef/getfaultequiclassgrouplist").then(response => {
            setFaultEquiClassGroups(response);
        });
    }, [])

    function handleSave(values) {
        httpClientService.post("api/settings/faultequiclassdef/savefaultequiclassdef", JSON.stringify(values)).then(response => {
            switch (response.statusCode) {
                case 200:
                    if (props.handleModalClose) {
                        props.handleModalClose(values);
                    }
                    break;
                default:
                    break;
            }
        })
    }

    function handleEquiClassesSelection(selectedRows) {
        formRef.current.setFieldValue("equiClassIDs", selectedRows);
        setSelectedRowIDs(selectedRows);
    }

    const schema = {
        faultEquiClassDefDesc: Yup.string()
            .required(t("GenericRequiredText"))
            .max(2048, t('Error_TextTooLong', { 1: 2048 })),

        equiClassIDs: Yup.array()
            .required(t("GenericRequiredText"))
            .min(1, t('GenericRequiredText')),
        faultEquiClassGroupID: Yup.string()
            .required(t("GenericRequiredText"))
            .min(1, t("GenericRequiredText")),

        sortIndex: Yup.string()
            .required(t("GenericRequiredText"))
            .min(1, t("GenericRequiredText"))
    };
    const validationSchema = Yup.object(schema);
    return (
        <Formik initialValues={initialValues}
            innerRef={formRef}
            validateOnMount
            validationSchema={validationSchema}
            onSubmit={values => handleSave(values)}
        >
            {formik => (
                <Form>
                    <OmisRow>
                        <OmisCol xs={6}>
                            <OmisTextBox labeltext={t("Description")} name="faultEquiClassDefDesc" required />
                        </OmisCol>
                    </OmisRow>
                    <OmisRow>
                        <OmisCol xs={6}>
                            <OmisTextBox labeltext={t("FaultKind_SortIndex")} name="sortIndex" required />
                        </OmisCol>

                        <OmisCol xs={6}>
                            <OmisDropdown labeltext={t("FaultEquiClassGroup")} name="faultEquiClassGroupID" items={faultEquiClassGroups} required />
                        </OmisCol>
                    </OmisRow>

                    <OmisRow>
                        <OmisCol xs={12}>
                            <OmisLabel label={`${t("EquiClasses")} (${selectedRowIDs})`} required />
                        </OmisCol>
                        <OmisCol xs={12}>
                            <OmisGrid noButtons noHeaderText
                                apiUrl={`/api/equipment/equiclass/getequiclasses`}
                                showCheckBox={true}
                                checkedRows={selectedRowIDs}
                                rowSelectionCallback={handleEquiClassesSelection}
                            />
                        </OmisCol>
                    </OmisRow>
                    <OmisRow>
                        <DialogActions>
                            <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={props.handleModalCancel}></OmisButtonSecondary>
                            <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                        </DialogActions>
                    </OmisRow>
                </Form>
            )}
        </Formik>
    );
}