import React from "react";
import { DetailsCardWithSkeleton } from "../shared/Shared";
import { EquipmentDetailsInfo } from "./Equipment_TabContent_Details";

export function EquipmentDetailsModal(props) {
    return (
        <div>
            <DetailsCardWithSkeleton
                url={ `/api/equipments/details?equipid=${ props?.equipid }` }
                skeletonheight={ "350px" }
                content={
                    <EquipmentDetailsInfo { ...props } /> }{ ...props }/> {/*Equipment Details for { props? <b>{props.equidescription}</b> : ''} in popup!*/ }
        </div>);
}