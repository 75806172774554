import React, {
    //useEffect,
    useRef, useState
} from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisCheckBox, OmisTextBox } from "../../shared/OmisInputs";
import AuthService from "../../../services/auth.service";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateTradeLevel(props) {

    const navigate = useNavigate();
    const formRef = useRef();
    //const [selectedTradeLevelGroupText, setSelectedTradeLevelGroupText] = useState('');
    const [initialValues, setInitialValues] = useState({
        id: props.rowVals?.id ?? null,
        tradeLevelGroupID: props.rowVals?.tradelevelgroupid ?? 0,
        description: props.rowVals?.description ?? "",
        customerEditable: props.rowVals?.customereditable ?? true
    });

    //useEffect(() => {
    //    setSelectedTradeLevelGroupText(props.rowVals?.tradelevelgroupdesc ?? '');
    //}, []);

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsobjects/savetradelevel", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate("/settings/objects/objecttradelevels");
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate("/settings/objects/objecttradelevels");
        }
    }

    const [schema, setSchema] = useState({
        description: Yup.string()
            .required(t("GenericRequiredText"))
            .max(30, t('Error_TextTooLong', { 1: 30 })),
        //tradeLevelGroupID: Yup.number()
        //    .required(t("GenericRequiredText"))
        //    .min(1),
    });

    const validationSchema = Yup.object(schema);

    //function handleTradeLevelGroupSelect(selection) {
    //    formRef.current.setFieldValue("tradeLevelGroupID", selection?.id);
    //    setSelectedTradeLevelGroupText(selection?.description ?? '');
    //}

    //function handleTradeLevelGroupDeselect() {
    //    formRef.current.setFieldValue("tradeLevelGroupID", 0);
    //    setSelectedTradeLevelGroupText('');
    //}

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("New_TradeLevel")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisTextBox labeltext={t("Description")} name="description" required />
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <br />
                                    <OmisCheckBox labeltext={t("CustomerEditable")} name="customerEditable" disabled={!AuthService.isFSMMaster()} />
                                </OmisCol>
                            </OmisRow>
                            {/*<OmisRow>*/}
                            {/*    <OmisLabel label={t("TradeLevelGroup")} />*/}
                            {/*    <Card variant={"outlined"}>*/}
                            {/*        <OmisSelectedCatalogueCard*/}
                            {/*            text={`${t("TradeLevelGroup")} (${selectedTradeLevelGroupText})`}*/}
                            {/*            id={selectedTradeLevelGroupText}*/}
                            {/*            handleDeselect={handleTradeLevelGroupDeselect}*/}
                            {/*        />*/}
                            {/*    </Card>*/}
                            {/*    {*/}
                            {/*        selectedTradeLevelGroupText === '' ?*/}
                            {/*            <OmisGrid rowclickCallback={handleTradeLevelGroupSelect} noButtons noToolbar noHeader*/}
                            {/*                apiUrl={"/api/settings/settingsobjects/gettradelevelgroups"}*/}
                            {/*            />*/}
                            {/*            :*/}
                            {/*            null*/}
                            {/*    }*/}
                            {/*</OmisRow>*/}
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}