import React from "react";
import { DetailsCardWithSkeleton } from "../shared/Shared";
import { DetailsPastInspectionsInfo } from "./DetailsPastInspectionsInfo";
import { OmisContainer } from "../shared/OmisLayouts";

export function TabContent_PastInspections(props) {
    const state = props.state;

    return (
        <OmisContainer fluid>
            <DetailsCardWithSkeleton
                url={`/api/inspections/details?id=${state?.inspectionid}`}
                skeletonheight={"200px"}
                content={
                    <DetailsPastInspectionsInfo {...props} />}{...props} />
        </OmisContainer>
    );
}