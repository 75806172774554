import React, { useEffect, useRef, useState } from "react";
import httpclientService from "../../services/httpclient.service";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import {
    Card, Chip, IconButton, Paper, List
    //Switch, FormControlLabel
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { OmisCol, OmisContainer, OmisRow, OmisTabs, OmisTabsWithIcon } from "../shared/OmisLayouts";
import {
    mdiApplicationOutline, mdiTable, mdiFamilyTree, mdiTextBoxOutline//, mdiInformationOutline
    , mdiLogin//, mdiTrayArrowDown
    , mdiTrashCan
} from '@mdi/js';
import Icon from "@mdi/react";
import {
    OmisButtonPrimary, OmisButtonPrimarySubmit, OmisButtonSecondary,// OmisDropDownWithDynamicComponents,
    OmisSecButtonWithStartIcon
} from "../shared/OmisButtons";
import { OmisGraphTree } from "../shared/OmisGraphTree";
import { OmisDialog, OmisErrorMessage } from "../shared/OmisDisplays";
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import { DocumentUploadForNewReport } from "../documents/Documents";
import { OmisCheckboxList } from "../shared/OmisLists";
import { DetailsCardWithSkeleton, checkIsNumeric } from "../shared/Shared";
import { OmisSlider } from "../shared/OmisInputs";
import { Parser } from "html-to-react";

export default function SystemData(props) {
    return (
        <>  
            <HeaderPanel />
        </>
    );
}

export function HeaderPanel(props) {

    const [datas, setDatas] = useState(null);
    const [iconPosition, setIconPosition] = useState("start");
    const [isLoading, setIsLoading] = useState(true);
    const [loadingWidth, setLoadingWidth] = useState(2);
    const [tabVal, setTabVal] = useState(0);
    const [selectedTabValue, setSelectedTabValue] = useState(0);
    const [modalTitleForData, setModalTitleForData] = useState("");
    const [modalOpenForData, setModalOpenForData] = useState(false);
    const [modalContentForData, setModelContentForData] = useState(null);

    useEffect(() => {
        httpclientService.get("api/systemdata/getsystemdata").then((response) => {
            setDatas(response);
        })
    }, []);

    useEffect(() => {
        if (!isLoading) {
            setLoadingWidth(4);
        } else {
            setLoadingWidth(2);
        }
    }, [isLoading]);

    const handleTabValueChange = (selectedTab) => {
        setTabVal(selectedTab);
    }

    const handleObjectClick = (selectedTabIndex) => {
        setTabVal(0);
        if (checkIsNumeric(selectedTabIndex)) {
            setSelectedTabValue(selectedTabIndex);
        } else {
            setSelectedTabValue(0);
        }
    }

    const viewtabs = [
        {
            title: t("TableView"),
            iconpath: mdiTable,
            iconposition: iconPosition,
            content: <TabContent_TableView SystemData={datas} isLoadingDone={setIsLoading} selectedtabvalue={selectedTabValue} {...props} />
        },
        {
            title: t("DiagramView"),
            iconpath: mdiFamilyTree,
            iconposition: iconPosition,
            content: <TabContent_DiagramView SystemData={datas} selectedtabvalue={selectedTabValue} handleObjectClick={handleObjectClick} {...props} />
        }
    ]

    function handleModalOpenForData(modalContent, title, event) {
        setModalTitleForData(title);
        setModelContentForData(modalContent);
        setModalOpenForData(true);
    }

    function handleModalCloseForData() {
        setModelContentForData(null);
        setModalOpenForData(false);
    }


    const childItemsForDowloadMenu = [ 
        {
            content: <OmisSecButtonWithStartIcon iconpath={mdiApplicationOutline} onClick={null} text={t("EmptyTableTemplate")} />,
        },
        {
            content: <OmisSecButtonWithStartIcon iconpath={mdiTextBoxOutline} onClick={handleModalOpenForData.bind(this, <ExportCurrentData closeAction={handleModalCloseForData} />, t("Action_Download"))} text={t("CurrentSystemData")} />,
        },
    ]

    return (
        <div className="omis-header-box" style={{ height: '15em !important' }} sx={{ height: '15em !important' }}>
            <OmisContainer fluid>
                <OmisRow>
                    <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <h1>{t("SystemData")}</h1>
                    </OmisCol>

                    <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'right', alignItems:'center' }} >

                        <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}  >
                            <LoadingButton startIcon={<CheckIcon />} size="medium" loading={isLoading} loadingPosition="start" variant="contained" >
                                {isLoading ? <span>Loading</span> : <span>{t("OnlineImport_LoadDataSuccess")}</span>}
                            </LoadingButton>
                        </OmisCol>
                        {/*<OmisCol xs={3} style={{ display: 'flex', justifyContent: 'center', height:'2.5em', marginRight:'0.5em'}} >*/}
                        {/*	<OmisDropDownWithDynamicComponents menutitle={t("Action_Download")} menuicon={mdiTrayArrowDown} childitems={childItemsForDowloadMenu} showdownarrow={1} />*/}
                        {/*</OmisCol>*/}
                        <OmisCol xs={3} style={{ display: 'flex', justifyContent: 'right', height: '2.5em' }}>
                                           <OmisSecButtonWithStartIcon  id={"importButton"} iconpath={mdiLogin} onClick={handleModalOpenForData.bind(this, <ImportData closeAction={handleModalCloseForData} />, t("ExcelImport"))} text={t("ExcelImport")} />
                        </OmisCol>
                    </OmisCol>
                </OmisRow>
				<OmisRow>
					<OmisTabsWithIcon tabvalue={tabVal} onChange={handleTabValueChange} tabs={viewtabs} />
				</OmisRow>

				<OmisDialog onClose={handleModalCloseForData} open={modalOpenForData}
					fullWidth={true}
					maxWidth={'lg'}
					title={modalTitleForData}
					content={modalContentForData}
				/>
			</OmisContainer>
		</div>
	);
}

function TabContent_TableView(props) {
    const { t } = useTranslation();

    const [emptyStr, setEmptyStr] = useState('');
    const [searchText, setSearchText] = useState('');
    const [selectedTabValue, setSelectedTabValue] = useState(0);

    useEffect(() => {
        if (props.selectedtabvalue) {
            if (/[0-9]/.test(props.selectedtabvalue)) {
                setSelectedTabValue(parseInt(props.selectedtabvalue));
            } else {
                setSelectedTabValue(0);
            }
        }
    }, [props.selectedtabvalue]);
   
    const tableviewtabs = [
        {
            title: `${t("Objects")}${props.SystemData && props.SystemData.objects ? ` (${props.SystemData.objects.length})` : emptyStr}`,
            content: <TabContent_Objects searchText={searchText} isLoadingDone={props.isLoadingDone} {...props} />,
            disabled: props.SystemData && props.SystemData.objects ? false : true
        },
        {
            title: `${t("User")}${props.SystemData && props.SystemData.users ? ` (${props.SystemData.users.length})` : emptyStr}`,
            content: <TabContent_User searchText={searchText} {...props} />,
            disabled: props.SystemData && props.SystemData.users ? false : true
        },
        {
            title: `${t("Contractors")}${props.SystemData && props.SystemData.suppliers ? ` (${props.SystemData.suppliers.length})` : emptyStr}`,
            content: <TabContent_Suppliers searchText={searchText} {...props} />,
            disabled: props.SystemData && props.SystemData.suppliers ? false : true
        },
        {
            title: `${t("Settings_TestDates")}${props.SystemData && props.SystemData.inspections ? ` (${props.SystemData.inspections.length})` : emptyStr}`,
            content: <TabContent_Inspections searchText={searchText} {...props} />,
            disabled: props.SystemData && props.SystemData.inspections ? false : true
        },
        {
            title: `${t("Equipments")}${props.SystemData && props.SystemData.equipments ? ` (${props.SystemData.equipments.length})` : emptyStr}`,
            content: <TabContent_Equipment searchText={searchText} {...props} />,
            disabled: props.SystemData && props.SystemData.equipments ? false : true
        },
        {
            title: `${t("FaultReports")}${props.SystemData && props.SystemData.faults ? ` (${props.SystemData.faults.length})` : emptyStr}`,
            content: <TabContent_Faults searchText={searchText} {...props} />,
            disabled: props.SystemData && props.SystemData.faults ? false : true
        }
    ]
    return (
        <>
            <OmisTabs tabs={tableviewtabs} tabvalue={selectedTabValue } />
        </>
    );
}

function TabContent_DiagramView(props) {

    const [nodeValues, setNodeValues] = useState([]);
    const [nodeLinks, setNodeLinks] = useState([]);
    var tmpNodes = [], tmpLinks = [];

    function tabNavigatorObjectClicked(index,event) {
        if (props.handleObjectClick) {
            props.handleObjectClick(index);
        }
    }

    function addNodes(arr, props) {
        arr.map((item, index) => {
            tmpNodes.push({
                id: `node-${item.infoTitle}`,
                content:
                    <OmisContainer fluid>
                        <OmisRow>
                            <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }} >
                                <span>{`${item.infoTitle} ${item.count}`}</span> 
                            </OmisCol>
                            <OmisCol xs={4} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }} > 
                                {/*<span>{t("DataIsFrom")} <OmisDate>{item.updateDate}</OmisDate></span>                                */}
                            </OmisCol>
                        </OmisRow>
                        <br />
                        <OmisRow>
                            <OmisCol xs={12}>
                                {
                                    item.navigationList ?
                                        Object.entries(item.navigationList).map((value, key) =>
                                            <div id={`div-${item.infoTitle + key}`} key={`div-${item.infoTitle + key}`}>
                                                <Chip id={`btn_${item.infoTitle + key}`} label={`${value[1]}`} variant="outlined" onClick={tabNavigatorObjectClicked.bind(this, value[0])} sx={{ backgroundColor:"white;"} } />
                                            </div>
                                        )
                                        : null
                                }
                            </OmisCol>
                        </OmisRow>
                    </OmisContainer>,
                coordinates: [item.coordinates.x, item.coordinates.y],
            });

            if (item.parentTitle && item.parentTitle.length > 0) {
                item.parentTitle.map((parent) => {
                    return tmpLinks.push({ input: `node-${parent}`, output: `node-${item.infoTitle}` }); 
                });                
            }

            return true;
        });
    }

    useEffect(() => {
        if (props.SystemData) {

            setNodeValues([]);
            setNodeLinks([]);

            if (props.SystemData.objects && props.SystemData.objects.length > 0) {
                addNodes(props.SystemData.objects, props);
            }
            if (props.SystemData.users && props.SystemData.users.length > 0) {
                addNodes(props.SystemData.users, props);
            }
            if (props.SystemData.suppliers && props.SystemData.suppliers.length > 0) {
                addNodes(props.SystemData.suppliers, props);
            }
            if (props.SystemData.inspections && props.SystemData.inspections.length > 0) {
                addNodes(props.SystemData.inspections, props);
            }
            if (props.SystemData.equipments && props.SystemData.equipments.length > 0) {
                addNodes(props.SystemData.equipments, props);
            }
            if (props.SystemData.faults && props.SystemData.faults.length > 0) {
                addNodes(props.SystemData.faults, props);
            }
            setNodeValues(tmpNodes);
            setNodeLinks(tmpLinks);
        }
    }, [props.SystemData]);

    return (
        <>
            <OmisGraphTree nodes={nodeValues} links={ nodeLinks} {...props} />
        </>
    );
}

function TabContent_Objects(props) {

    return (
        <>
            <PrepareTabContent contentid={"Objects"} isLoadingDone={props.isLoadingDone} tabcontent={props.SystemData ? props.SystemData.objects : null} />            
        </>
    );
}

function TabContent_User(props) {

    return (
        <>
            <PrepareTabContent contentid={"Users"} tabcontent={props.SystemData ? props.SystemData.users : null} />
        </>
    );
}

function TabContent_Suppliers(props) {

    return (
        <>
            <PrepareTabContent contentid={"Suppliers"} tabcontent={props.SystemData ? props.SystemData.suppliers : null} /> 
        </>
    );
}

function TabContent_Inspections(props) {

    return (
        <>
            <PrepareTabContent contentid={"Inspections"} tabcontent={props.SystemData ? props.SystemData.inspections : null} />
        </>
    );
}

function TabContent_Equipment(props) {

    return (
        <>
            <PrepareTabContent contentid={"Equipments"} tabcontent={props.SystemData ? props.SystemData.equipments : null} />
        </>
    );
}

function TabContent_Faults(props) {

    return (
        <>
            <PrepareTabContent contentid={"Faults"} tabcontent={props.SystemData ? props.SystemData.faults : null} />
        </>
    );
}

function PrepareTabContent(props) {

    const [showSkeleton, setShowSkeleton] = useState(true); 

    function handleHideSkeleton() {
        if (showSkeleton) {
            setShowSkeleton(false);
        }
    }

    useEffect(() => {
        if (props.isLoadingDone) {
            props.isLoadingDone(showSkeleton);
        }
    }, [showSkeleton]);

    return (
        <>
            <OmisContainer fluid>
                <OmisRow>
                    <OmisCol xs={12} style={{ display: 'flex', justifyContent: 'center'}}>
                        <span>{t("LoadSystemDataVisualization")}</span>
                    </OmisCol>
                    <br />
                    <OmisCol xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <RenderSkeleton showSkeleton={showSkeleton} {...props} />
                        {
                            props.tabcontent && props.tabcontent.length > 0 ?
                                <OmisCol xs={5}>
                                    {
                                        props.tabcontent.map((item, index) =>
                                            <div key={index.toString() + "itemCard"}>
                                                <Card>
                                                    <OmisContainer fluid>
                                                        <OmisRow>
                                                            <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                                                <span>{`${item.infoTitle} ${item.count}`}</span>
                                                            </OmisCol>
                                                            <OmisCol xs={4} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                                                {/*                                    <span>{t("DataIsFrom")}</span>*/}
                                                                {/*<OmisDate>{item.updateDate}</OmisDate>*/}
                                                            </OmisCol>
                                                        </OmisRow>
                                                    </OmisContainer>
                                                </Card>
                                                <br/>
                                            </div>
                                        )
                                    }
                                    {handleHideSkeleton()}
                                </OmisCol>
                                : null
                        }
                    </OmisCol>
                    {/*<div className="d-flex justify-content-center">*/}
                    {/*    <OmisCol xs={2}>*/}
                    {/*        <OmisButtonSecondary text={t("LoadAllPages")} />*/}
                    {/*    </OmisCol>*/}
                    {/*    <OmisCol xs={{ span: 2, offset: 1 }}>*/}
                    {/*        <OmisButtonPrimary text={t("LoadCurrentPage")} />*/}
                    {/*    </OmisCol>*/}
                    {/*</div>*/}
                </OmisRow>
            </OmisContainer>
        </>
    );
}

function RenderSkeleton(props) {
    return (
        props.showSkeleton ?
            <OmisCol xs={5}>
                <DetailsCardWithSkeleton />
            </OmisCol>
            : null
    );
}

function ImportData(props) {
    const { t } = useTranslation();
    const formRef = useRef();
    const htmlParser = new Parser();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState(null);
    const [logMessage, setLogMessage] = useState(null);


    const [initialValues, setInitialValues] = useState({
        dryRun: true,
        visualisation: false,
        liveValidation: false,
        addData: true,
        importedfile: [],
    });

    //const [selectedItems, setSelectedItems] = useState([]);
    //const [dataValidation, setDataValidation] = useState(false);
    //const ListItems = ["Equipment", "EquiClass", "EquiTypes", "Faults", "Inspections", "Suppliers", "Objects", "ObjectAttributes_Title", "Users", "UserAllocation"]

    //const handleSelectedListItems = (selectedItems) => {
    //    setSelectedItems(selectedItems);
    //}

    function fileUploadCallback(file) {
        if (file[0].name !== "OnlineMasterData.xlsx") {
            setFileUploadErrorMessage(t("Import_MasterDataFileName_Error"));
        } else {
            setFileUploadErrorMessage(null);
            var newUploadedFiles = [...uploadedFiles, ...file];
            formRef.current.setFieldValue("importedfile", newUploadedFiles);
            setUploadedFiles(newUploadedFiles);
        }
    }

    function deleteFileButtonClicked(file) {
        var filteredSelectedFiles = uploadedFiles.filter(s => s.name !== file);
        formRef.current.setFieldValue("importedfile", filteredSelectedFiles);
        setUploadedFiles(filteredSelectedFiles);
    }

    const importFile = (values) => {
        httpclientService.post("api/systemdata/uploadfile", values).then((response) => {
            setLogMessage(response);
        });
    }

    const [schema, setSchema] = useState({
        importedfile: Yup.array()
            .min(1, t('GenericRequiredText')),
    });

    const validationSchema = Yup.object(schema);
    return (
        <Formik
            innerRef={formRef}
            validateOnMount
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                importFile(values)
            }}
            enableReinitialize={false}
        >
            {formik => (
                <Form>
                    <OmisContainer fluid>
                        {/*        <OmisRow>*/}
                        {/*            <Card>*/}
                        {/*                <h6>{t("SourceFile")}</h6>*/}
                        {/*                <DocumentUpload referer={DMSDocumentReferer.TestDate} />*/}
                        {/*            </Card>*/}
                        {/*        </OmisRow>*/}
                        {/*        <br />*/}
                        {/*        <OmisRow>*/}
                        {/*            <h6>{t("Select_Tables")}</h6>*/}

                        {/*<OmisCheckboxList itemlist={ListItems} onChange={handleSelectedListItems} {...props} />*/}
                        {/*        </OmisRow>*/}
                        {/*        <br />*/}
                        {/*        <OmisRow style={{ display: 'flex', alignItems: 'center' }} >*/}
                        {/*<OmisCol xs={3}>*/}
                        {/*	<FormControlLabel*/}
                        {/*		value={dataValidation}*/}
                        {/*		control={<Switch checked={dataValidation} onChange={() =>  setDataValidation(!dataValidation)} />}*/}
                        {/*                    label={<><span>{t("Import_ValidationActive")}</span><Icon path={mdiInformationOutline} size={1}></Icon></> }*/}
                        {/*		labelPlacement="start"*/}
                        {/*	/>*/}
                        {/*	</OmisCol>*/}
                        {/*        </OmisRow>*/}


                        <OmisRow>
                            {
                                logMessage ?
                                    <OmisRow>
                                        <OmisCol style={{ justifyContent: 'center', marginLeft: '2.6em', border: '1px solid', borderColor: '#cbd5e1', borderRadius: '1rem' }} xs={11}>
                                            <strong>{t("Result")}</strong>
                                            <Paper sx={{ maxHeight: '10em !important', overflow: 'auto', borderRadius: 1 }} elevation={10} >
                                                <List>
                                                    {
                                                        htmlParser.parse(logMessage)
                                                    }
                                                </List>
                                            </Paper>
                                        </OmisCol>
                                    </OmisRow>
                                    :
                                    null
                            }
                        </OmisRow>
                        <br />
                        <br />
                        <OmisRow>
                            <OmisCol xs={12}>
                                <DocumentUploadForNewReport multiFilesUploadCallback={fileUploadCallback} hideDocumentType disabled={uploadedFiles.length > 0} singlefileupload />
                            </OmisCol>
                        </OmisRow>
                        <br />
                        <OmisRow>
                            <OmisCol style={{ justifyContent: 'center', marginLeft: '2em' }} xs={12}>
                                {
                                    uploadedFiles && uploadedFiles.length > 0 ?
                                        <>
                                            <span>{`${t("Import_UploadedFiles")} :`}</span>
                                            {
                                                uploadedFiles.map((file, index) =>
                                                    <OmisCol key={index.toString()} xs={12}>
                                                        <strong>{file.name}</strong>
                                                        <IconButton id={"deleteUploadedFileButton"} variant={"outlined"}
                                                            color={"error"}
                                                            onClick={() => deleteFileButtonClicked(file.name)}
                                                        >
                                                            <Icon path={mdiTrashCan} size={1} />
                                                        </IconButton>
                                                    </OmisCol>
                                                )
                                            }
                                        </>
                                        :
                                        null
                                }
                            </OmisCol>
                            {
                                fileUploadErrorMessage ?
                                    <OmisCol style={{ justifyContent: 'center', marginLeft: '2em' }} xs={12}>
                                        <OmisErrorMessage text={fileUploadErrorMessage} />
                                    </OmisCol>
                                    :
                                    null
                            }
                        </OmisRow>
                        <OmisRow>
                            <OmisCol>
                                <OmisSlider name="dryRun" labeltext={formik.values.dryRun ? t("Import_DryRun") : "Live"} />
                            </OmisCol>
                            <OmisCol>
                                <OmisSlider disabled={true} /*there is no visualisation method yet*/ name="visualisation" labeltext={formik.values.visualisation ? t("Import_VisualizationActive") : t("Import_VisualizationInActive")} />
                            </OmisCol>
                            <OmisCol>
                                <OmisSlider disabled={true} /*there is no validation method yet*/ name="liveValidation" labeltext={formik.values.liveValidation ? t("Import_ValidationActive") : t("Import_ValidationInActive")} />
                            </OmisCol>
                            <OmisCol>
                                <OmisSlider disabled={true} /*there is no validation method yet*/ name="addData" labeltext={t("Import_AddData")} />
                            </OmisCol>
                        </OmisRow>
                        <br />
                        <OmisRow>
                            <OmisRow style={{ justifyContent: 'center', marginLeft: '0.1em' }}>
                                <OmisCol>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={props.closeAction} />
                                </OmisCol>
                                <OmisCol>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("ExcelImport")} />
                                </OmisCol>
                            </OmisRow>
                        </OmisRow>
                    </OmisContainer>

                </Form>)
            }
        </Formik >
    );
}

function ExportCurrentData(props) {
    const { t } = useTranslation();

    const [selectedItems, setSelectedItems] = useState([]);
    const ListItems = ["Equipment", "EquiClass", "EquiTypes", "Faults", "Inspections", "Suppliers", "Objects", "ObjectAttributes_Title", "Users", "UserAllocation"]

    const handleSelectedListItems = (selectedItems) => {
        setSelectedItems(selectedItems);
    }

    return (
        <OmisContainer fluid>
			<OmisRow>
                <h6>{t("Select_Tables")}</h6>

				<OmisCheckboxList itemlist={ListItems} onChange={handleSelectedListItems} {...props} />
            </OmisRow>
            <br />
            <OmisRow>
                <OmisCol xs={6}>
                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={props.closeAction} />
                </OmisCol>
                <OmisCol xs={6}>
                    <OmisButtonPrimary id={"downloadButton"} text={t("Action_Download")} />
                </OmisCol>
            </OmisRow>
		</OmisContainer>
    );
}

