import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonNewItem, OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { Card } from "@mui/material";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";
import { OmisDialog, OmisLabel, OmisSelectedCatalogueCard } from "../../shared/OmisDisplays";
import { OmisGrid } from "../../shared/OmisGrids";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { OmisCheckBox } from "../../shared/OmisInputs";
import AuthService from "../../../services/auth.service";
import { CreateAddress } from "../Miscellaneous/CreateAddress";
import { TabContent_ListView } from "../../objects/Objects";

const SelectionStep = {
    Object: 1,
    Address: 2,
    Done: 3
}

export function CreateObjectContactPerson(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    const [selectedObjectID, setSelectedObjectID] = useState(null);
    const [selectedObjectText, setSelectedObjectText] = useState('');
    const [selectedAddressID, setSelectedAddressID] = useState(null);
    const [selectedAddressText, setSelectedAddressText] = useState('');
    const [selectionStep, setSelectionStep] = useState(SelectionStep.Object);

    const [contactTypes, setContactTypes] = useState([]);

    const [initialValues, setInitialValues] = useState({
        id: props.rowVals?.id ?? null,
        objectID: props.rowVals?.objectid ?? props.rowVals?.objectID ?? 0,
        addressID: props.rowVals?.addressid ?? props.rowVals?.addressID ?? 0,
        contactTypeID: props.rowVals?.contacttypeid ?? props.rowVals?.contactTypeID ?? "0",
        contactPersonVisibleAll: props.rowVals?.contactpersonvisibleall ?? props.rowVals?.contactPersonVisibleAll ?? false,
        contactPersonVisibleSingleObject: props.rowVals?.contactpersonvisiblesingleobject ?? props.rowVals?.contactPersonVisibleSingleObject ?? true,
        customerEditable: props.rowVals?.customereditable ?? props.rowVals?.customerEditable ?? true
    });

    useEffect(() => {

        if (props.rowVals) {
            handleSingleEntry(SelectionStep.Object, props.rowVals);
            handleSingleEntry(SelectionStep.Address, props.rowVals);
        }

    },[]);

    useEffect(() => {

        if (selectedObjectID > 0 && selectedAddressID > 0) {
            httpclientService.get(`/api/settings/settingsobjects/getcontacttypes?objectID=${selectedObjectID}&addressID=${selectedAddressID}`).then((response) => {
                if (response && response.length > 0) {
                    setContactTypes(response);
                }
            });
        }

    }, [selectedObjectID, selectedAddressID]);

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsobjects/saveobjectcontactperson", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate("/settings/objects/objectcontactpersons", { replace: true, state: { selectedtabvalue: TabConstants.Objects } });
                }
            }
        });
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else if (props.onCancel) {
            props.onCancel();
        } else {
            navigate("/settings/objects/objectcontactpersons", { replace: true, state: { selectedtabvalue: TabConstants.Objects } });
        }
    }

    function handleNewAddressClick() {
        setModalContent(<CreateAddress onClose={handleModalClose} handleEditCancel={handleModalCancel} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = (response) => {
        handleAddressSelect(response)
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    const [schema, setSchema] = useState({
        objectID: Yup.number(t("GenericRequiredText"))
            .required(t("GenericRequiredText"))
            .min(1, t("GenericRequiredText")),
        addressID: Yup.number(t("GenericRequiredText"))
            .required(t("GenericRequiredText"))
            .min(1, t("GenericRequiredText")),
        contactTypeID: Yup.number(t("GenericRequiredText"))
            .required(t("GenericRequiredText"))
            .min(1, t("GenericRequiredText")),
    });

    const validationSchema = Yup.object(schema);

    function handleObjectSelect(selection) {
        setSelectionStep(SelectionStep.Address);
        updateSelection(selection);
    }

    function handleObjectDeselect() {        
        setSelectionStep(SelectionStep.Object);
        clearSelection(SelectionStep.Object);
    }

    function handleAddressSelect(selection) {
        setSelectionStep(SelectionStep.Done);
        updateSelection(selection);
    }

    function handleAddressDeselect() {
        setSelectionStep(SelectionStep.Address);
        clearSelection(SelectionStep.Address);
    }

    function handleSingleEntry(selectionGrid, row) {
        switch (selectionGrid) {
            case SelectionStep.Object:
                setSelectedObjectID(row?.objectid ?? row?.id ?? null);
                setSelectedObjectText(row?.objectnrinternal ?? '');
                formRef.current.setFieldValue("objectID", row?.objectid ?? row?.id ?? null);
                setSelectionStep(SelectionStep.Address);
                break;
            case SelectionStep.Address:
                setSelectedAddressID(row?.addressid ?? row?.id ?? null);
                setSelectedAddressText(row?.name ?? '');
                formRef.current.setFieldValue("addressID", row?.addressid ?? row?.id ?? null);
                setSelectionStep(SelectionStep.Done);
                break;
            default:
                break;
        }
    }

    function clearSelection(selectedStep) {
        var clearObject = selectedStep < SelectionStep.Address;
        var clearAddress = selectedStep < SelectionStep.Done;

        if (clearObject) {
            setSelectedObjectID(null);
            setSelectedObjectText('');
            formRef.current.setFieldValue("objectID", null);
        }

        if (clearAddress) {
            setSelectedAddressID(null);
            setSelectedAddressText('');
            formRef.current.setFieldValue("addressID", null);
        }
    }

    function updateSelection(row) {
        switch (selectionStep) {
            case SelectionStep.Object:
                setSelectedObjectID(row?.id ?? null);
                setSelectedObjectText(row?.objectnrinternal ?? '');
                formRef.current.setFieldValue("objectID", row?.id ?? null);
                break;
            case SelectionStep.Address:
                setSelectedAddressID(row?.addressid ?? row?.id ?? null);
                setSelectedAddressText(row?.name ?? row?.lastName1 ?? row?.addressText ?? '');
                formRef.current.setFieldValue("addressID", row?.addressid ?? row?.id ?? null);
                break;
            default:
                break;
        }
    }

    function CatalogueGrid(props) {
        const { step } = { ...props };

        switch (step) {
            case SelectionStep.Object:
                var objecturl = "/api/objects/getobjectlist";

                if (props.objectid) {
                    objecturl = `${objecturl}?objectid=${props.objectid}`;
                }

                if (props.removeobjectbyuserid) {
                    objecturl = `${objecturl}?removeobjectbyuserid=${props.removeobjectbyuserid}`;
                }

                return (
                    <TabContent_ListView
                        url={objecturl}
                        rowclickCallback={handleObjectSelect} noExportButton
                        singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.Object)}
                        disableChipClick
                    />);
            case SelectionStep.Address:
                var addressurl = "/api/settings/settingsobjects/getpossibleaddresses";

                if (selectedObjectID) {
                    addressurl = `${addressurl}?objectid=${selectedObjectID}`;
                }

                if (props.rowVals?.addressid) {
                    if (selectedObjectID) {
                        addressurl = `${addressurl}&currentAddressID=${props.rowVals?.addressid}`;
                    } else {
                        addressurl = `${addressurl}?currentAddressID=${props.rowVals?.addressid}`;
                    }
                }

                if (props.getbyAddressID) {
                    addressurl = `${addressurl}${addressurl.includes("?") ? '&':'?'}getbyAddressID=${props.getbyAddressID}`;
                }

                return (
                    <OmisGrid
                        headerText={t("Address")}
                        rowclickCallback={handleAddressSelect} noExportButton
                        newButton={<OmisButtonNewItem id={"newItemButton"} onClick={handleNewAddressClick} text={t("Action_New")} />}
                        apiUrl={addressurl}
                        singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.Address)}
                        change={changeCounter}
                        disableChipClick
                    />);
            default:
                break;
        }
    }

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("New_Object_ContactPerson")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={4}>
                                    <Card variant={"outlined"}>
                                        <OmisSelectedCatalogueCard
                                            text={`${t("Object_Details_Title")} (${selectedObjectText})`}
                                            id={selectedObjectText}
                                            handleDeselect={handleObjectDeselect}
                                            disabled={props.rowVals}
                                            required
                                        />
                                    </Card>
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <Card variant={"outlined"}>
                                        <OmisSelectedCatalogueCard
                                            text={`${t("Address")} (${selectedAddressText})`}
                                            id={selectedAddressText}
                                            handleDeselect={handleAddressDeselect}
                                            disabled={props.rowVals}
                                            required
                                        />
                                    </Card>
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <Card variant={"outlined"}>
                                        <br />
                                        <OmisLabel label={<strong>{t("ContactType")}</strong>} required />
                                        <OmisDropdown size={"small"} id={"contacttypes"} name={"contactTypeID"} items={contactTypes} />
                                    </Card>
                                </OmisCol>
                                <OmisContainer fluid>
                                    <CatalogueGrid step={selectionStep} {...props} />
                                </OmisContainer>
                            </OmisRow>
                            <br />
                            <OmisRow>

                                <OmisCol xs={6}>
                                    <OmisCheckBox labeltext={t("ContactPersonUIVisible")} name="contactPersonVisibleAll" />
                                </OmisCol>

                                <OmisCol xs={6}>
                                    <OmisCheckBox labeltext={t("ContactPersonUIVisibleSingleObject")} name="contactPersonVisibleSingleObject" />
                                </OmisCol>
                                {
                                    AuthService.isFSMMaster() ?
                                        <OmisCol xs={6}>
                                            <OmisCheckBox labeltext={t("CustomerEditable")} name="customerEditable" />
                                        </OmisCol>
                                        :
                                        null
                                }
                            </OmisRow>
                            <br />
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"xl"}
                title={modalTitle}
                content={modalContent}
                contentHeight={700}
            />
        </>
    );
}