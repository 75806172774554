export const FaultReportStatusConstants = {
    STATUS_START_FAULTREPORT: "Start_FaultReport",
    STATUS_DISPATCHING: "Dispatching_FaultReport",
    STATUS_ANALYSING: "Analysing_MaintenanceResponsible",
    STATUS_TENDERING_PROCEDURE: "TenderingProcedure_MaintenanceResponsible",
    STATUS_TENDERING_PROCEDURE_INVEST: "TenderingProcedure_MaintenanceResponsible_Invest",
    STATUS_TENDERING_PROCEDURE_INVEST_RETAIL: "TenderingProcedure_MaintenanceResponsible_InvestRetail",
    STATUS_BIDS_SOLICITED: "BidsSolicited_MaintenanceResponsible",
    STATUS_BIDS_SOLICITED_INVEST: "BidsSolicited_MaintenanceResponsible_Invest",
    STATUS_BIDS_SOLICITED_INVEST_RETAIL: "BidsSolicited_MaintenanceResponsible_InvestRetail",
    STATUS_AWAITING_ACCEPTANCE: "AwaitingAcceptance_FaultReport",
    STATUS_ORDER_PLACED: "OrderPlaced_FaultReport",
    STATUS_ORDER_PAUSED: "OrderPaused_FaultReport",
    STATUS_WAITING_FOR_RELEASE: "WaitingForRelease_FaultReport",
    STATUS_ORDER_EXECUTING: "OrderExecuting_FaultReport",
    STATUS_PLAN_FAULTREPORT: "Plan_FaultReport",
    STATUS_PLANNED_FAULTREPORT: "Planned_FaultReport",
    STATUS_COSTAPPROVAL_RVL: "Cost_Approval_RVL",
    STATUS_COSTAPPROVAL_VLP: "Cost_Approval_VLP",
    STATUS_WAITINGFORCOSTAPPROVAL_FAULTREPORT: "WaitingForCostApproval_FaultReport",
    STATUS_TECHNICALLY_FINISHED: "TechnicallyFinished_FaultReport",
    STATUS_FINISHED: "Finished_FaultReport",
    STATUS_CANCELLED: "Cancelled_FaultReport",
    STATUS_TECHNICALLY_PREVIEWED: "TechnicallyPreviewed_FaultReport",
    STATUS_RETURN_REQUIRED: "ReturnRequired_FaultReport",
    STATUS_SECURE_OBJECT: "SecureObject_FaultReport",
    STATUS_URGENCY: "Urgenz_99",
    STATUS_CHECK_GUARANTEE: "CheckGuarantee_FaultReport",
    STATUS_CHECK_CONTRACTOR: "CheckContractor_FaultReport",
    STATUS_TENDER_SPEC_CHECK: "TenderSpecCheck_FaultReport",
    STATUS_CHECKSEVERITIES: "CheckSeverities_FaultReport",
    OPEN_CASES_CONTRACTOR: "Open_Cases_Contractor",
    STATUS_ARCHIVED: "Archived_FaultReport",
    STATUS_INSURANCE: "Insurance_FaultReport",
    STATUS_ANNUNCIATOR: "Annunciator_FaultReport",
    STATUS_ANALYSING_AUTHORITY: "Analysing_Authority_FaultReport",
    STATUS_CREATE_INVOICE: "CreateInvoice_FaultReport",
    STATUS_ANALYSING_INVOICE: "AnalysingInvoice_FaultReport",
    STATUS_ANALYSING_INVEST: "AnalysingInvest_FaultReport",
    STATUS_ANALYSING_INVEST_RETAIL: "AnalysingInvestRetail_FaultReport",
    STATUS_WAITING_FOR_PAYMENT: "WaitingForPayment_FaultReport",
    STATUS_CONFIRMED: "Confirmed_FaultReport",
    STATUS_KIMOCE_ATT: "Start_FaultReport",
    STATUS_KIMOCE_CRS: "OrderExecuting_FaultReport",
    STATUS_KIMOCE_PLA: "PlannedAction_FaultReport",
    STATUS_KIMOCE_SUS: "OrderPaused_FaultReport",
    STATUS_KIMOCE_GES: "TechnicallyFinished_FaultReport",
    STATUS_KIMOCE_NOK: "Declined_FaultReport",
    STATUS_KIMOCE_TRA: "TechnicianOnTravel_FaultReport",
    STATUS_KIMOCE_ITV: "TechnicianOnSite_FaultReport",
    STATUS_KIMOCE_PDA: "SentToTechnician_FaultReport",
    STATUS_KIMOCE_WAE: "WaitingForSpareParts_FaultReport",
    STATUS_KIMOCE_FZP: "ReadyForPlanning_FaultReport",
    STATUS_KIMOCE_AIB: "OtherInterventions_FaultReport",
    STATUS_KIMOCE_EWB: "SparePartsNecessary_FaultReport",
    STATUS_KIMOCE_FAULTREPORT: "Kimoce_FaultReport",
    STATUS_START_INVESTREPORT: "Start_InvestReport",
    STATUS_ANALYSING_INVESTREPORT: "Analysing_InvestReport",
    STATUS_VERIFICATION_INVESTREPORT: "Verification_InvestReport",
    STATUS_COSTESTIMATION_INVESTREPORT: "CostEstimation_InvestReport",
    STATUS_CREATEREQUEST_INVESTREPORT: "CreateRequest_InvestReport",
    STATUS_ORDERPLACED_INVESTREPORT: "OrderPlaced_InvestReport",
    STATUS_ORDEREXECUTING_INVESTREPORT: "OrderExecuting_InvestReport",
    STATUS_FINISHED_INVESTREPORT: "Finished_InvestReport",
    STATUS_CANCELLED_INVESTREPORT: "Cancelled_InvestReport",
    STATUS_ARCHIVED_INVESTREPORT: "Archived_InvestReport",
    STATUS_CHECKRESPONSIBLE_FAULTREPORT: "CheckResponsible_FaultReport",
    STATUS_INTERNAL_CHECK_FAULTREPORT: "InternalCheck_FaultReport",
    STATUS_NO_ORDER_FAULTREPORT: "NoOrder_Faultreport",
    STATUS_RELEVANT_HUTH_FAULTREPORT: "Relevant_Huth_FaultReport",
    STATUS_FIRSTLEVEL_HUTH_FAULTREPORT: "FirstLevel_Huth_FaultReport",
    STATUS_PAUSE_HUTH_FAULTREPORT: "OrderPaused_Huth_FaultReport",
    STATUS_ORDER_PLACED_HUTH_FAULTREPORT: "OrderPlaced_Huth_FaultReport",
    STATUS_RELEVANT_WEBINTERFACE_FAULTREPORT: "Relevant_WebInterface_FaultReport",
    STATUS_PAUSE_WEBINTERFACE_FAULTREPORT: "OrderPaused_WebInterface_FaultReport",
    STATUS_ORDER_PLACED_WEBINTERFACE_FAULTREPORT: "OrderPlaced_WebInterface_FaultReport",
    STATUS_WAITING_FOR_APPROVAL_FAULTREPORT: "WaitingForApproval_FaultReport",
    STATUS_INVOICECREATION_CHECK: "InvoiceCreationCheck_FaultReport",
    STATUS_WAITING_FOR_ORDER_FAULTREPORT: "WaitingForOrder_FaultReport",
    STATUS_CHECKPARENTID_FAULTREPORT: "CheckParentID_FaultReport",
    STATUS_CHECK_COSTS_FAULTREPORT: "Check_Costs_FaultReport",
    STATUS_CHECKPARENTID_DECLINE_FAULTREPORT: "CheckParentID_Decline_FaultReport",
    STATUS_RECLASSIFIED: "Reclassified",
}