import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonNewItem, OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { DynamicOpeningTimeBuilder } from "../../objects/create/DynamicOpeningTimeBuilder";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateAutoDispatchSetting(props) {
    
    const navigate = useNavigate();
    const formRef = useRef();
    const [faultReportKinds, setFaultReportKinds] = useState([]);
    const [faultEquiClassGroups, setFaultEquiClassGroups] = useState([]);
    const [faultEquiClassDefs, setFaultEquiClassDefs] = useState([]);

    const [dispatchTimes, setDispatchTimes] = useState([{ id: new Date().getTime(), dispatchTimeID: null, selectedDays: null, fromTime: null, toTime: null }]);
    const [daysList, setDaysList] = useState([]);
    const [removeFromDaysList, setRemoveFromDaysList] = useState([]);

    const [initialValues, setInitialValues] = useState({
        id: props.id ? props.id : null,
        faultReportKindID: null,
        faultEquiClassGroupID: null,
        faultEquiClassDefID: null,
        dispatchTimes: [],
    });

    useEffect(() => {

        httpclientService.get("/api/settings/settingsreports/getfaultreportkindsfordropdown").then((response) => {
            if (response && response.length > 0) {
                setFaultReportKinds(response);
            }
        });

        httpclientService.get("/api/settings/settingsreports/getfaultequiclassgroupsfordropdown").then((response) => {
            if (response && response.length > 0) {
                setFaultEquiClassGroups(response);
            }
        });

        httpclientService.get("/api/settings/settingsequipments/getfaultequiclassdefsfordropdown").then((response) => {
            if (response && response.length > 0) {
                setFaultEquiClassDefs(response);
            }
        });

        httpclientService.get("/api/shared/getweekdays?includePublicHoliday=true").then((response) => {
            if (response && response.length > 0) {
                setDaysList(response);
            }
        });

        var dispatchTimesList = props.rowVals?.dispatchTimes ?? null;

        if (dispatchTimesList && dispatchTimesList.length>0) {
            var _dispatchTimes = dispatchTimesList.map((item, index) => {
                var tmp = {
                    id: new Date().getTime() + index,
                    openingTimeID: item.openingTimeID,
                    selectedDays: item.selectedDays,
                    fromTime: new Date(item.fromTime),
                    toTime: new Date(item.toTime),
                    rowString: null
                };
                return tmp;
            }
            ) ?? [];
            setDispatchTimes(_dispatchTimes);
            formRef.current.setFieldValue("dispatchTimes", _dispatchTimes);
        }
    }, []);

    useEffect(() => {
        if (faultReportKinds.length > 0) {
            formRef.current.setFieldValue("faultReportKindID", props.rowVals?.faultReportKindID ?? null);
        }
    }, [faultReportKinds]);

    useEffect(() => {
        if (faultEquiClassGroups.length > 0) {
            formRef.current.setFieldValue("faultEquiClassGroupID", props.rowVals?.faultEquiClassGroupID ?? null);
        }
    }, [faultEquiClassGroups]);

    useEffect(() => {
        if (faultEquiClassDefs.length > 0) {
            formRef.current.setFieldValue("faultEquiClassDefID", props.rowVals?.faultEquiClassDefID ?? null);
        }
    }, [faultEquiClassDefs]);

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsreports/saveautodispatchsetting", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(-1);
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(-1);
        }
    }

    /* Dispatch times logic */
    const handleAddDispatchTime = (index) => {

        if (dispatchTimes.length > 0) {
            setDispatchTimes([
                ...dispatchTimes,
                { id: new Date().getTime(), dispatchTimeID: null, selectedDays: null, fromTime: null, toTime: null }
            ]);
        } else {
            setDispatchTimes([
                { id: new Date().getTime(), dispatchTimeID: null, selectedDays: null, fromTime: null, toTime: null }
            ]);
        }
    }

    const handleRemoveDispatchTime = (removeID) => {
        var _newList = [...dispatchTimes.filter((item) => item.id !== removeID)];
        if (_newList.length < 1) {
            _newList = [{ id: new Date().getTime(), dispatchTimeID: null, selectedDays: null, fromTime: null, toTime: null }];
        }
        setDispatchTimes(_newList);
    }

    const handleDispatchTimeChange = (id, value, changeType) => {
        setDispatchTimes(
            dispatchTimes.map((item, i) => {
                if (item.id === id) {
                    switch (changeType) {
                        case 'selectedDays':
                            item.selectedDays = value;
                            break;
                        case 'fromTime':
                            item.fromTime = value;
                            break;
                        case 'toTime':
                            item.toTime = value;
                            break;
                        default:
                            break;
                    }
                }
                return item;
            })
        );
    }

    useEffect(() => {
        formRef.current.setFieldValue("dispatchTimes", dispatchTimes);
        var selectedDays = [];
        if (dispatchTimes.length > 0) {
            dispatchTimes.map((item) => {
                if (item.selectedDays?.length > 0) {
                    selectedDays = [
                        ...selectedDays,
                        ...item.selectedDays?.filter((f) => selectedDays.indexOf(f) === -1)
                    ];
                }
            });
            setRemoveFromDaysList(selectedDays);
        }
    }, [dispatchTimes]);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("AutoDispatchSettings")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={4}>
                                    <OmisDropdown labeltext={t("FaultReportKind")} name="faultReportKindID" items={faultReportKinds} />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisDropdown labeltext={t("FaultEquiClassGroup")} name="faultEquiClassGroupID" items={faultEquiClassGroups} />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisDropdown labeltext={t("FaultReport_FaultEquiClassDef")} name="faultEquiClassDefID" items={faultEquiClassDefs} disabled={props.rowVals?.faultEquiClassDefID ? true:false} />
                                </OmisCol>

                                <OmisCol xs={8}>
                                    <OmisCol xs={12}>
                                        <DynamicOpeningTimeBuilder removeFromDaysList={removeFromDaysList} daysList={daysList} openingTimes={dispatchTimes} handleRemoveOpeningTime={handleRemoveDispatchTime} handleOpeningTimeChange={handleDispatchTimeChange} disableTypingDate />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <OmisButtonNewItem id={"addTimeButton"} text={t("Action_Add")} onClick={handleAddDispatchTime} disabled={removeFromDaysList.length === daysList.length} />
                                    </OmisCol>
                                </OmisCol>
                            </OmisRow>

                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}