import React, { useState, useEffect } from 'react';
import { OmisButtonNewItem, OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { mdiTrashCan, mdiPencil} from "@mdi/js";
import { OmisGrid } from "../../shared/OmisGrids";
import { useTranslation } from "react-i18next";
import { OmisCheckBox, OmisTextBox } from '../../shared/OmisInputs';
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { OmisDeleteQuestionDialog, OmisDialog } from "../../shared/OmisDisplays";
import DialogActions from "@mui/material/DialogActions";
import httpclientService from '../../../services/httpclient.service';
import { OmisIconButton } from "../../shared/OmisButtons";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisCol, OmisRow } from '../../shared/OmisLayouts';

export default function FaultReportKind() {
    
    const { t } = useTranslation();

    const [changeCounter, setChangeCounter] = useState(0);  
    const [newModalOpen, setNewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [selectedRowID, setSelectedRowID] = useState(0);

    const handleModalOpen = () => {
        setNewModalOpen(true)
    }

    const handleModalClose = () => {
        setNewModalOpen(false);
        setEditModalOpen(false);
        setDeleteModalOpen(false);
        setSelectedRowID(0);
    };

    function handleEditClick(item) {
        setSelectedRowID(item);
        setEditModalOpen(true);
    }

    function handleDeleteClick(item) {
        setSelectedRowID(item);
        setDeleteModalOpen(true);
    }

    const dropDownMenuItems = [
        {
            action: handleEditClick,
            iconpath: mdiPencil,
            icontext: t("Action_Edit")
        },
        {
            action:  handleDeleteClick,
            iconpath: mdiTrashCan,
            icontext: t("Action_Delete"),
        }
    ]

    return (
        <div class=" omis-header-box">
            <OmisCol xs={1}>
                <OmisIconButton id={"backButton"}
                    iconcontent={<ArrowBackIcon fontSize="inherit" />}
                    text="Back" component={RouterLink} to={"/settings"} state={{ selectedtabvalue: TabConstants.Reports }} />
            </OmisCol>

            <OmisGrid actiondropdown={{ dropDownMenuItems: dropDownMenuItems }}
                change={changeCounter}
                headerText={t("FaultReportKind")}
                newButton={<OmisButtonNewItem id={"newItemButton"} onClick={handleModalOpen} text={t("Action_New")} />}
                apiUrl={"/api/settings/faultreportkind/getallfaultreportkinds"}
            />
            <OmisDialog onClose={handleModalClose} open={newModalOpen}
                title={"Neue Meldungsart"}                 
                content={< FaultReportKindPopup_Content/>}
            />
            <OmisDialog onClose={handleModalClose} open={editModalOpen}
                title={"Meldungsart bearbeiten"}
                content={<FaultReportKindPopup_Content />}
            />
            <OmisDeleteQuestionDialog open={deleteModalOpen}
                handleModalClose={handleModalClose}
                deleteFunction={handleDeleteItem}
            />
        </div>
    );

    function handleDeleteItem() {
        httpclientService.post(`/api/settings/faultreportkind/delete?id=${selectedRowID}`).then((response) => {
            setChangeCounter((prevState) => prevState + 1);
            handleModalClose();
        })
    }

    function FaultReportKindPopup_Content(props) {

        const [initialValues, setInitialValues] = useState({
            id: 0,
            kinddescription: '',
            archive: false,
            mainContractor: false,
            imminentDanger: false,
            selfRepair: false,
            dispatch: false
        });

        const editItem = Boolean(selectedRowID > 0);
        useEffect(() => {
            if (editItem) {
                httpclientService.get(`/api/settings/faultreportkind/get?id=${selectedRowID}`).then((response) => {
                    setInitialValues(response);
                })
            }
        }, [])

        function handleSave(values) {
            httpclientService.post("/api/settings/faultreportkind/savefaultreportkind", JSON.stringify(values)).then((response) => {
                switch (response.statusCode) {
                    case 200:
                        handleModalClose();
                        setChangeCounter((prevState) => prevState + 1);
                        break;
                    default:
                        break;
                }
            })
        }

        return(    
            <>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        kinddescription: Yup.string()
                            .required(t("GenericRequiredText"))
                            .max(2048, t('Error_TextTooLong', { 1: 2048 })),
                    })}
                    onSubmit={(values) => { handleSave(values) }}
                >
                    <Form>
                        <OmisRow>
                            <OmisCol xs="12">
                                <OmisTextBox labeltext={t("Description")} name="kinddescription" placeholder={t("FaultReportKind") + " " + t("Description")}  />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol xs={6}>
                                <OmisCheckBox labeltext={t("FaultReportKind_MainContractor")} name="mainContractor" />
                            </OmisCol>

                            <OmisCol xs={6}>
                                <OmisCheckBox labeltext={t("ImminentDanger")} name="imminentDanger" />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol xs={6}>
                                <OmisCheckBox labeltext={t("FaultReport_SelfRepair")} name="selfRepair" />
                            </OmisCol>

                            <OmisCol xs={6}>
                                <OmisCheckBox labeltext={t("FaultReportKind_Dispatch")} name="dispatch"/>
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <DialogActions>
                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleModalClose}></OmisButtonSecondary>
                                <OmisButtonPrimarySubmit id={"submitButton"} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                            </DialogActions>
                        </OmisRow>
                    </Form>
                </Formik>
            </>
        );
    }
}