import React, { useState, useEffect } from 'react';
import { OmisButtonNewItem, OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisGrid } from "../../shared/OmisGrids";
import { mdiTrashCan, mdiPencil } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { OmisDeleteQuestionDialog, OmisDialog } from '../../shared/OmisDisplays';
import { OmisCheckBox, OmisTextBox } from '../../shared/OmisInputs';
import * as Yup from "yup";
import { Form, Formik } from "formik";
import DialogActions from "@mui/material/DialogActions";
import httpClientService from '../../../services/httpclient.service';
import { OmisIconButton } from "../../shared/OmisButtons";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisCol, OmisRow } from '../../shared/OmisLayouts';

export default function ReactTimeModel() {
    const { t } = useTranslation();

    const [changeCounter, setChangeCounter] = useState(0);
    const [newModalOpen, setNewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [selectedRowID, setSelectedRowID] = useState(0);


    const handleModalOpen = () => {
        setNewModalOpen(true);
    }

    const handleModalClose = () => {
        setNewModalOpen(false);
        setEditModalOpen(false);
        setDeleteModalOpen(false);
        setSelectedRowID(0);
    };

    function handleEditClick(item) {
        setSelectedRowID(item);
        setEditModalOpen(true);
    }

    function handleDeleteClick(item) {
        setSelectedRowID(item);
        setDeleteModalOpen(true);
    }

    function handleDeleteItem() {
        httpClientService.post(`/api/settings/reacttimemodel/delete?id=${selectedRowID}`).then(response => {
            setChangeCounter(prevState => prevState + 1);
            handleModalClose();
        })
    }

    const dropDownMenuItems = [
        {
            action: handleEditClick,
            iconpath: mdiPencil,
            icontext: t("Action_Edit")
        },
        {
            action: handleDeleteClick,
            iconpath: mdiTrashCan,
            icontext: t("Action_Delete")
        }
    ]

    return (
        <div className=" omis-header-box">
            <OmisCol xs={1}>
                <OmisIconButton id={"backButton"}
                    iconcontent={<ArrowBackIcon fontSize="inherit" />}
                    text="Back" component={RouterLink} to={"/settings"} state={{ selectedtabvalue: TabConstants.Reports }} />
            </OmisCol>
            <OmisGrid actiondropdown={{ dropDownMenuItems: dropDownMenuItems }}
                change={changeCounter}
                headerText={t("ReactTimeModel_Settings")}
                newButton={<OmisButtonNewItem id={"newItemButton"} onClick={handleModalOpen} text={t("Action_New")} />}
                apiUrl={"api/settings/reacttimemodel/getallreacttimemodels" }
            />
            <OmisDialog onClose={handleModalClose} open={newModalOpen}
                title={"Neues Reaktionszeit Berechnungsmodell"}
                content={< ReactTimeModelPopup_Content />}
                maxWidth={"lg"}
            />
            <OmisDialog onClose={handleModalClose} open={editModalOpen}
                title={"Reaktionszeit Berechnungsmodell bearbeiten"}
                content={<ReactTimeModelPopup_Content />}
                maxWidth={"lg" }
            />
            <OmisDeleteQuestionDialog open={deleteModalOpen}
                handleModalClose={handleModalClose}
                deleteFunction={handleDeleteItem}
            />
        </div>
    )

    function ReactTimeModelPopup_Content(props) {

        const [initialValues, setInitialValues] = useState({
            id: 0,
            namereacttimemodel: '',
            consideropeningtime: false,
            considersaturday: false,
            considersunday: false,
            considerpublicholiday: false,
            customereditable: true
        });

        const editItem = Boolean(selectedRowID > 0);
        useEffect(() => {
            if (editItem) {
                httpClientService.get(`api/settings/reacttimemodel/get?id=${selectedRowID}`).then(response => {
                    setInitialValues(response)
                })
            }
        }, [])

        function handleSave(values) {
            httpClientService.post("/api/settings/reacttimemodel/savereacttimemodel", JSON.stringify(values)).then(response => {
                switch (response.statusCode) {
                    case 200:
                        handleModalClose();
                        setChangeCounter(prevState => prevState + 1)
                        break;
                    default:
                        break;
                }
            })
        }

        return (
            <>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        namereacttimemodel: Yup.string()
                            .required(t("GenericRequiredText"))
                            .max(100, t('Error_TextTooLong', { 1: 100 })),
                    })}
                    onSubmit={values => handleSave(values) }
                >
                    <Form>
                        <OmisRow>
                            <OmisCol >
                                <OmisTextBox labeltext={t("Name")} name="namereacttimemodel" />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol xs={12}>
                                <strong>{t("ReactTimeModel_ConsiderForCalculation")}:</strong>
                            </OmisCol>
                        </OmisRow>
                        <br/>
                        <OmisRow>
                            <OmisCol xs={3}>
                                <OmisCheckBox labeltext={t("ReactTimeModel_ConsiderOpeningTime") } name="consideropeningtime"/>
                            </OmisCol>
                            <OmisCol xs={3}>
                                <OmisCheckBox labeltext={t("ReactTimeModel_ConsiderSaturday") } name="considersaturday"/>
                            </OmisCol>
                            <OmisCol xs={3}>
                                <OmisCheckBox labeltext={t("ReactTimeModel_ConsiderSunday")} name="considersunday" />
                            </OmisCol>
                            <OmisCol xs={3}>
                                <OmisCheckBox labeltext={t("ReactTimeModel_ConsiderPublicHoliday")} name="considerpublicholiday" />
                            </OmisCol>
                            <OmisCol xs={3}>
                                <OmisCheckBox labeltext={t("CustomerEditable")} name="customereditable" />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <DialogActions>
                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleModalClose}></OmisButtonSecondary>
                                <OmisButtonPrimarySubmit id={"submitButton"} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                            </DialogActions>
                        </OmisRow>
                    </Form>
                </Formik>
            </>  
        );
    }
}