import React, { useEffect,useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import httpclientService from "../../services/httpclient.service";
import { OmisDateTimePicker, OmisTextArea } from "../shared/OmisInputs";
//import { OmisDropdown } from "../shared/OmisDropdowns";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../shared/OmisButtons";
import AuthService from "../../services/auth.service";
import { TRole } from "../../constants/RoleConstants";
import { OmisLabel } from "../shared/OmisDisplays";
import { FaultReportMessage } from "../../constants/FaultReportMessageConstants";
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";
import { OmisDropdown } from "../shared/OmisDropdowns";
import { CustomerConstants } from "../../constants/CustomerConstants";
import { FaultReportStatusConstants } from "../../constants/FaultReportStatusConstants";

export function ReportStatusChangeForm(props) {

    //const [contractors, setContractor] = useState([]);
    const currentUser = AuthService.getCurrentUser();
    const [initialValues, setInitialValues] = useState({
        reportid: 0,
        contractor: 0,
        comment: '',
        message: '',
        internalnote: 0,
        clearancedate: props.selectedMessage === FaultReportMessage.FINISH_TECHNICALLY || props.selectedMessage === FaultReportMessage.FINISH || (currentUser?.customerNr === CustomerConstants.CUSTOMER_SUPPORT && props.selectedMessage === FaultReportMessage.CONFIRM) &&
            (props.currentStatus !== FaultReportStatusConstants.STATUS_ORDER_EXECUTING) ? new Date() : null
    });

    const statusDropdown = [
        {
            key: 1,
            displayText: t("InternalNote"),
        },
        {
            key: 0,
            displayText: t("ExternalNote"),
        }
    ];

    const [btnsDisabled, setBtnsDisabled] = useState(false);

    useEffect(() => {
        initialValues.reportid = props.reportid;
        initialValues.message = props.selectedMessage;
        setInitialValues(initialValues);

        //httpclientService.get(`/api/reports/getsuppliers?reportid=${props.reportid}`)
        //    .then((data) => {
        //        if (data && data.length > 0) {
        //            setContractor(data.map((item) => 
        //                ({ key: item.id, displayText: item.companyName })
        //            ));
        //        }
        //    });
    }, []);

    function handleSave(values) {
        setBtnsDisabled(true);
        const newValues = { ...values };
        newValues.internalnote = Boolean(newValues.internalnote);
        httpclientService.post(`/api/reports/savereportstatuschange`, newValues).then((response) => {
            if (response) {
                props.handleModalClose();
            }
        })
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={Yup.object({
                    //contractor: Yup.number()
                    //    .required(t("GenericRequiredText")),
                    comment: Yup.string()
                        .required(t("GenericRequiredText"))
                        .max(2048, t('Error_TextTooLong', { 1: 2048 }))
                })}
                onSubmit={(values) => { handleSave(values) }}
            >
                <Form>
                    <OmisContainer fluid>
                        <OmisRow>
                            {
                                props.selectedMessage === FaultReportMessage.FINISH_TECHNICALLY // FaultReportMessage.FINISH_TECHNICALLY = 27,
                                    || props.selectedMessage === FaultReportMessage.FINISH  //FaultReportMessage.FINISH = 31,
                                    || (currentUser?.customerNr === CustomerConstants.CUSTOMER_SUPPORT && props.selectedMessage === FaultReportMessage.CONFIRM) && (
                                        props.currentStatus !== FaultReportStatusConstants.STATUS_ORDER_EXECUTING) //FaultReportMessage.CONFIRM = 43,
                                    ?
                                    <OmisCol xs={7}>
                                        <OmisLabel label={t("FaultReport_ClearanceDate")} />
                                        <OmisDateTimePicker name="clearancedate" disabled={!AuthService.hasRole(TRole.FaultReportChangeFinishdate)} />
                                    </OmisCol>
                                    :
                                    null
                            }
                            <OmisCol xs={6}>
                                <OmisDropdown labeltext={t("NoteType")} name="internalnote" items={statusDropdown} required />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol xs={12}>
                                <OmisTextArea labeltext={t("Description")} name="comment" placeholder={t("SettingsTextModule") + " " + t("Description")} required />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol xs={6}>
                                <OmisButtonSecondary id={"cancelButton"} disabled={btnsDisabled} text={t("Action_Cancel")} onClick={props.handleModalCancel}></OmisButtonSecondary>
                            </OmisCol>
                            <OmisCol xs={ 6}>
                                <OmisButtonPrimarySubmit id={"submitButton"} disabled={btnsDisabled} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                            </OmisCol>
                        </OmisRow>
                    </OmisContainer>
                </Form>
            </Formik>
        </>
    );
}