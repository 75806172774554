import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { t } from "i18next";
import httpclientService from "../../services/httpclient.service";
import urlEncryptService from "../../services/urlencrypt.service";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Formik } from "formik";
import { Chip, Link, Tooltip } from "@mui/material";
import { mdiPencil, mdiArchive } from '@mdi/js';
import { ArrowBack as ArrowBackIcon, Person as PersonIcon, Groups as GroupIcon } from "@mui/icons-material";
import { OmisPriButtonWithStartIcon, OmisButtonPrimarySubmit, OmisButtonSecondary, OmisCopyButton, OmisIconButton, OmisSecButtonWithStartIcon } from '../shared/OmisButtons';
import { OmisDialog } from '../shared/OmisDisplays';
import { OmisAutosizeTextAreaV2, OmisTextBoxNoForm } from "../shared/OmisInputs";
import { OmisUserChip } from "../shared/OmisChips";
import { OmisCheckboxList } from "../shared/OmisLists";
import { AllUserList } from "./UsersList";
import { PageNames } from "../../constants/PageNameConstants";
import { Roles } from "./Roles";
import AuthService from "../../services/auth.service";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../shared/OmisLayouts";

export function GroupDetails(props) {
    const navigate = useNavigate();
    var location = useLocation();
    var { state } = useLocation();
    if (!state && props && props.state) {
        state = props.state;
    }

    var location = useLocation();
    if (!state) {

        var encryptedString = "";
        if (location?.search) {
            encryptedString = location?.search.replace("#", "").replace("?", "").replaceAll("%22", "\"");
        } else if (location?.hash) {
            encryptedString = location?.hash.replace("#", "").replace("?", "").replaceAll("%22", "\"");
        }

        if (encryptedString && encryptedString != "") {
            state = urlEncryptService.decrypt(encryptedString);
        }
    }

    const formRef = useRef();
    const [listLimit, setListLimit] = useState(5);
    const [chipModalOpen, setChipModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editCounter, setEditCounter] = useState(0);

    const selectedTab = { selectedTab: state && state.selectedTab != undefined ? state.selectedTab : 1 };

    const [initialValues, setInitialValues] = useState({
        id: 0,
        description: '',
        groupRoles: [],
        groupModules: [],
        groupUsers: [{
            name: '',
            userID : 0,
            role: '',
            phone: '',
            email: '',
        }],
        userIDs:[],
        startPage: "",
        startPageDesc: "",
    });

    const [editedValues, setEditedValues] = useState({
        id: 0,
        description: '',
        groupRoles: [],
        groupModules: [],
        groupUsers: [{
            name: '',
            userID: 0,
            role: '',
            phone: '',
            email: '',
        }],
        userIDs: [],
        startPage: "",
        startPageDesc: ""
    });

    const [pages, setPages] = useState(PageNames());

    const [selectedPage, setSelectedPage] = useState([]);    

    function loadValues() {
        if (!state) {
            return;
        }
        httpclientService.get(`api/profile/getgroupdetails?groupID=${state.id}`).then((response) => {
            if (response) {
                var startPage = pages.filter((item) => (item.path == (response.startPage ?? '') )|| (item.content == (response.startPage ?? '')))[0];
                setInitialValues(
                    {
                        id: response.id ? response.id : 0,
                        startPage: startPage?.path,
                        startPageDesc: startPage?.content,
                        description: response.description ? response.description : '',
                        groupRoles: response.groupRoles,
                        groupModules: response.groupModules,
                        groupUsers: response.groupUsers,
                        userIDs: response.groupUsers.map((user) => user.userID),
                    }
                );
                setEditedValues(
                    {
                        id: response.id ? response.id : 0,
                        startPage: startPage?.path,
                        startPageDesc: startPage?.content,
                        description: response.description ? response.description : '',
                        groupRoles: response.groupRoles,
                        groupModules: response.groupModules,
                        groupUsers: response.groupUsers,
                        userIDs: response.groupUsers.map((user) => user.userID),
                    }
                );
            }
        });
    }

    useEffect(() => {
        loadValues();
    }, []);

    useEffect(() => {
        if (initialValues && isEdit) {
            setSelectedPage(pages.filter((item) => (item.content == initialValues.startPage) || (item.path == initialValues.startPage)).map((x) => x.key));
        } else if (editCounter !== 0 ) {
            loadValues();
        }
    }, [isEdit]);

    const handleChipClickOpen = () => {
        setChipModalOpen(true);
    };

    const handleChipClose = () => {
        setChipModalOpen(false);
    };

    const handleEditClicked= () => {
        setIsEdit(true);
    };

    const handleEditCancel = () => {
        setIsEdit(false);
        setSelectedPage(initialValues.startPageDesc);
    };    

    const validationSchema = Yup.object({
        //startPage: Yup.string()
        //    .required(t("GenericRequiredText"))
        //    .max(100, t('Error_TextTooLong')),
        description: Yup.string()
            .required(t("GenericRequiredText"))
            .max(40, t('Error_TextTooLong', { 1: 40 }))
    });

    function handleGroupEdit(values) {
        values.userIDs = editedValues.userIDs;
        values.startPage = editedValues.startPage;
        httpclientService.post("/api/profile/savegroupdetails", values).then((response) => {
            if (response) {
                setInitialValues(values);
                setIsEdit(false);
                setEditCounter((prevState) => prevState + 1);
            }
        }).then(() => {
            AuthService.updateCurrentUser();
        });
    }

    const handleSelectedListItems = (selectedItems) => {
        var newValues = editedValues;
        var selectedPage = selectedItems.length > 0 ? pages.filter((item) => item.key === selectedItems[0])[0] : null;
        newValues.startPage = selectedPage?.path ?? initialValues.startPage;
        newValues.startPageDesc = selectedPage?.content ?? initialValues.startPageDesc;
        setEditedValues(newValues);        
    }

    const handleSelectedUsersList = (selectedIDs) => {
        var newValues = editedValues;
        newValues.groupUsers = initialValues.groupUsers.filter((user) => selectedIDs.indexOf(user.userID)>-1);
        newValues.userIDs = selectedIDs;
        setEditedValues(newValues);

        formRef.current.setFieldValue("groupUsers", newValues.groupUsers);
        formRef.current.setFieldValue("userIDs", newValues.selectedIDs);
    }

    const handleSelectedRoles = (selectedItems) => {
        var newValues = editedValues;
        newValues.groupRoles = selectedItems;
        setEditedValues(newValues);

        formRef.current.setFieldValue("groupRoles", selectedItems);
    }


    const handleArchiv = (selectedItems) => {
        httpclientService.post(`api/profile/deletegroup?groupID=${state.id}`).then((response) => {
            if (response) {
                navigate("/users", { replace: true, state:  selectedTab  });
            }
        });
    }

    return (
        
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                validate={(values) => {
                    const errors = {};
                    if (!values.description) {
                        errors.description = 'Required';
                    }
                    return false;
                }}
                onSubmit={(values) => { handleGroupEdit(values) }}
            >
                {formik => (
                    <Form>
                        <div className="omis-header-box">
                            <OmisContainer fluid>
                                <OmisRow>
                                    <OmisCol xs={1}>
                                        <OmisIconButton id={"backButton"} iconcontent={<ArrowBackIcon fontSize="inherit" />} text="Back" onClick={() => { navigate(location.key !== "default" ? -1 : "/users"); }} state={selectedTab} />
                                    </OmisCol>
                                    <OmisCol xs={5} >
                                        <h5><strong>{initialValues ? initialValues.description : null}</strong></h5>
                                    </OmisCol>
                                    <OmisCol style={{ display: 'flex', justifyContent: 'right' }} xs={6}>
                                        <OmisStack direction="horizontal" gap={3}>
                                            {
                                                isEdit ?
                                                    <div><OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} disabled={!isEdit} onClick={handleEditCancel}></OmisButtonSecondary></div>
                                                    :
                                                    <div><OmisSecButtonWithStartIcon id={"archivButton"} disabled={isEdit} onClick={handleArchiv} iconpath={mdiArchive} text={t("Archiv")} size={"small"} /></div>
                                            }
                                            {
                                                isEdit ?
                                                    <div><OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit></div>
                                                    :
                                                    <div><OmisPriButtonWithStartIcon id={"editButton"} key={`addButton`} iconpath={mdiPencil} text={t("Action_Edit")} size={"small"} disabled={isEdit} onClick={handleEditClicked} /></div>
                                            }
                                        </OmisStack>
                                    </OmisCol>
                                </OmisRow>
                            </OmisContainer>
                        </div>
                        <strong className="detailsCardTitle">Group details</strong>
                        <OmisContainer fluid className="usersGroupCreateCard" style={{ display: 'flex', justifyContent: 'start' }}>
                            <OmisRow>
                                <OmisCol xs={12}>
                                    
                                        <OmisStack direction="horizontal" gap="3">
                                        <OmisCol xs={6} >
                                            <strong className="detailsCardTitle">{t("Group_StartPage")}</strong>
                                            {
                                                isEdit ?
                                                <OmisCol xs={12}><OmisCheckboxList itemlist={pages} checked={selectedPage} onChange={handleSelectedListItems} listAttr={["key", "content"]} maxheight={100} multiSelect={false} {...state} showSearchBox /></OmisCol>
                                                    :
                                                <OmisCol xs={12}><OmisTextBoxNoForm disabled={!isEdit} name="startPageDesc" value={initialValues.startPageDesc ?? ""} /></OmisCol>

                                            }
                                            
                                        </OmisCol>
                                        <OmisCol xs={6} >
                                            {
                                                !isEdit ?
                                                    <OmisCol xs={12}><strong>{t("Description")}</strong></OmisCol>
                                                    :
                                                    null
                                            }
                                            <OmisCol xs={12}><OmisAutosizeTextAreaV2 disabled={!isEdit} name="description" labeltext={isEdit ? <strong>{t("Description")}</strong> : null} required={isEdit} /></OmisCol>
                                            <OmisCol xs={12}>
                                                <strong className="detailsCardTitle">{t("Settings_Roles")}</strong>
                                                <Roles selectedRoles={editedValues.groupRoles} isEdit={isEdit} handleSelectedRoles={handleSelectedRoles} />
                                            </OmisCol>
                                        </OmisCol>
                                    </OmisStack>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>

            {
                initialValues && initialValues.modules && initialValues.modules.length > 0 ?
                    <>
                        <strong className="detailsCardTitle">{t("Modules")}</strong>
                        <OmisCol xs={12}>
                            {
                                initialValues.modules.map((item,index) =>
                                    <Chip key={`${index}_Modules`} clickable={false} variant="outlined" label={item.description} />
                                )
                            }
                        </OmisCol>
                    </>
                    :
                    null
            }
            {
                initialValues && initialValues.permissions && initialValues.permissions.length > 0 ?
                    <>
                        <strong className="detailsCardTitle">{t("Permissions")}</strong>
                        <OmisCol xs={12}>
                            {
                                initialValues.permissions.map((item, index) =>
                                    <OmisCol xs={12} key={`${index}_PermissionGrid`}>
                                        <span>{item.title}</span>
                                        {
                                            item.subitems && item.subitems.length > 0 ?
                                                item.subitems.map((subitem) => <Chip clickable={false} variant="outlined" label={subitem} />)
                                                :
                                                null
                                        }
                                    </OmisCol>
                                )
                            }
                        </OmisCol>
                    </>
                    :
                    null
            }
            {
                isEdit ?
                    
                    <OmisContainer fluid className="usersGroupCreateCard" style={{ display: 'flex', justifyContent: 'center', marginTop: '2em !important', marginBottom: '2em !important' }}>
                        <OmisRow>
                            <OmisCol xs={12 }>
                                <AllUserList noActionButtons noButtons initialValues={initialValues} handleSelectedUsersList={handleSelectedUsersList} />
                            </OmisCol>
                        </OmisRow>
                    </OmisContainer>
                    
                    
                    : initialValues.groupUsers && initialValues.groupUsers.length > 0 ?
                        <>
                            <strong className="detailsCardTitle">{t("Users")}</strong>
                            <OmisContainer fluid className="usersGroupCreateCard" style={{ display: 'flex', justifyContent: 'start' }}>
                                <OmisRow>
                                    <OmisCol xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
                                        {
                                            initialValues.groupUsers && initialValues.groupUsers.length > listLimit ?
                                                <Link
                                                    component="button"
                                                    underline="none"
                                                    variant="body2"
                                                    onClick={handleChipClickOpen}
                                                >
                                                    View all users {`(${initialValues.groupUsers.length})`}
                                                </Link>
                                                :
                                                null
                                        }
                                    </OmisCol>
                                    <OmisCol xs={12}>
                                        <RenderUsersList shortList={true} listLimit={listLimit} data={initialValues.groupUsers} />
                                    </OmisCol>
                                </OmisRow>
                            </OmisContainer>

                            <OmisDialog onClose={handleChipClose} open={chipModalOpen}
                                title={`${t("Contacts")}`}
                                content={<RenderUsersList shortList={false} listLimit={listLimit} data={initialValues.groupUsers} />}
                                maxWidth={"md"}
                            />
                        </>
                        :
                        null
            }
        </>
    );
}

export function RenderUsersList(props) {

    var limit = props.shortList ? props.listLimit : (props.data ? props.data.length : 0);

    return (
        props.data && props.data.length > 0 ?
            <>
                <OmisContainer fluid>
                    <OmisRow>
                        <OmisCol xs={props.showaddress ? 3 : 4}>
                            <p>{t("Name")}</p>
                        </OmisCol>
                        <OmisCol xs={props.showaddress ? 2 : 4}>
                            <p>{t("Phone")}</p>
                        </OmisCol>
                        <OmisCol xs={props.showaddress ? 2 : 4}>
                            <p>{t("Email")}</p>
                        </OmisCol>
                        {
                            props.showaddress ?
                                <OmisCol xs={props.hasbutton ? 4 : 5}>
                                    <p>{t("Address")}</p>
                                </OmisCol>
                                :
                                null
                        }
                    </OmisRow>
                    <OmisRow>
                        {
                            props.data.map((d, index) =>
                                index < limit ?
                                    <OmisCol xs={12} key={d.userID ?? d.id}>
                                        <OmisStack direction="horizontal">
                                        <OmisCol xs={props.showaddress ? 3 : 4} >
                                            <OmisUserChip userFullName={d.name} userID={d.userID ?? d.id} />
                                            {
                                                d.roleassignmenttype === 'group' ?
                                                    <Tooltip title={t("Role_TypeGroup")}><GroupIcon /></Tooltip>
                                                    :
                                                    <Tooltip title={t("Role_TypeUser")}><PersonIcon /></Tooltip>
                                            }
                                        </OmisCol>
                                        <OmisCol xs={props.showaddress ? 2 : 4}>
                                            {
                                                d.phone ?
                                                    <>

                                                            <span className="userProfilCardInfoText bold">{d.phone}</span>
                                                        <OmisCopyButton text={d.phone} />
                                                    </>
                                                    :
                                                    null
                                            }
                                        </OmisCol>
                                        <OmisCol xs={props.showaddress ? 2 : 4} >
                                            {
                                                d.email ?
                                                    <>
                                                            <span className="userProfilCardInfoText bold">{d.email}</span>
                                                        <OmisCopyButton text={d.email} />
                                                    </>
                                                    :
                                                    null
                                            }
                                        </OmisCol>
                                        {
                                            props.showaddress ?
                                                <OmisCol xs={props.hasbutton ? 4 : 5} >
                                                    {
                                                        d.phone ?
                                                            <>
                                                                <span className={"bold"}>{d.address}</span>
                                                                <OmisCopyButton text={d.address} />
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </OmisCol>
                                                :
                                                null
                                        }
                                        {
                                            props.hasbutton ?
                                                <OmisCol xs={1} >
                                                    <OmisIconButton id={"extraButton"} iconcontent={props.hasbutton} onClick={() => { if (props.onClick) { props.onClick(d.id); } }} />
                                                </OmisCol>
                                                :
                                                null
                                            }
                                        </OmisStack>
                                    </OmisCol>
                                    :
                                    null
                            )
                     }
                    </OmisRow>
                </OmisContainer>
            </>
            : null
    );
}