import React, { useEffect, useRef, useState } from "react";
import httpclientService from "../../../services/httpclient.service";
import * as Yup from "yup";
import { t } from "i18next";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary, OmisProgress } from "../../shared/OmisButtons";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Card, IconButton } from "@mui/material";
import Icon from "@mdi/react";
import { mdiTrashCan } from "@mdi/js";
import { OmisAutosizeTextAreaV2, OmisCheckBox, OmisDatePicker, OmisDateRangePicker, OmisTextBox, OmisTextBoxNoForm, OmisYearPicker } from "../../shared/OmisInputs";
import { DocumentUploadForNewReport } from "../../documents/Documents";
import { DMSDocumentReferer } from "../../../constants/DMSDocumentReferer";
import { OmisGrid } from "../../shared/OmisGrids";
import { OmisDialog, OmisLabel, OmisSelectedCatalogueCard } from "../../shared/OmisDisplays";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function EquipmentCreate(props) {

    const SelectionStep = {
        Object: 1,
        EquiClass: 2,
        EquiType: 3,
        Done: 4
    }

    const formRef = useRef();

    const [modalOpen, setModalOpen] = useState(false);
    const [selectionStep, setSelectionStep] = useState(1);
    const [catalogueCardClicked, setCatalogueCardClicked] = useState(false);

    const [selectedObjectID, setSelectedObjectID] = useState(null);
    const [selectedEquiClassID, setSelectedEquiClassID] = useState(null);
    const [selectedEquiTypeID, setSelectedEquiTypeID] = useState(null);

    const [selectedObjectNrInternal, setSelectedObjectNrInternal] = useState('');
    const [selectedEquiClassText, setSelectedEquiClassText] = useState('');
    const [selectedEquiTypeText, setSelectedEquiTypeText] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [ownerList, setOwnerList] = useState([]);
    const [equiList, setEquiList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);

    const [initialValues, setInitialValues] = useState({
        id: props.equiid ? props.equiid : null,
        objectid: selectedObjectID,
        equiclassid: selectedEquiClassID,
        equitypeid: selectedEquiTypeID,
        equidescription: '',
        idexternal: '',
        idinternally: '',
        dateofconstruction: null,
        serialnumber: '',
        inventorynumber: '',
        status: '0',
        amount: 0,
        vendor: '',
        producer: '',
        owner: '0',
        parentequiid: '0',
        attachedFiles: [],
        comment: '',
        warrantyfrom: null,
        warrantyto: null,
        warrantysupplier: null,
        warrantydiscount: 0,
        supplierid: null,
        scrappingdate: null,
        tSpecActive: false,
        attributes:[]
    });

    const [schema, setSchema] = useState({
        objectid: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        equiclassid: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        equitypeid: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        status: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),

        equidescription: Yup.string()
            .notRequired()
            .max(300, t("Error_TextTooLong", { 1: 300 })),
        idexternal: Yup.string()
            .notRequired()
            .max(40, t("Error_TextTooLong", { 1: 40 })),
        idinternally: Yup.string()
            .notRequired()
            .max(20, t("Error_TextTooLong", { 1: 20 })),
        serialnumber: Yup.string()
            .notRequired()
            .max(50, t("Error_TextTooLong", { 1: 50 })),
        inventorynumber: Yup.string()
            .notRequired()
            .max(10, t("Error_TextTooLong", { 1: 10 })),
        vendor: Yup.string()
            .notRequired()
            .max(255, t("Error_TextTooLong", { 1: 255 })),
        producer: Yup.string()
            .notRequired()
            .max(40, t("Error_TextTooLong", { 1: 40 })),
        comment: Yup.string()
            .notRequired()
            .max(2000, t("Error_TextTooLong", { 1: 2000 })),
    });

    const navigate = useNavigate();

    function handleCreateEquipment(values) {
        httpclientService.post("/api/equipments/createequipment", values).then((response) => {
            handleModalClose();
            if (response.statusCode === 200) {
                if (props.handleModalClose) {
                    props.handleModalClose();
                } else {
                    navigate("/equipment");
                }
            }
        });
    }

    useEffect(() => {
        if (selectionStep < SelectionStep.Done) {
            clearSelection(null);
        } else {
            formRef.current.validateForm();
        }
    }, [selectionStep]);

    useEffect(() => {
        httpclientService.get("/api/equipments/getstatuslist").then((response) => {
            if (response) {
                setStatusList(response);
            }
        });

        httpclientService.get("/api/equipments/getequipmentownerlist").then((response) => {
            if (response) {
                setOwnerList(response);
            }
        });

        httpclientService.get("/api/equipments/getequilistfordropdown").then((response) => {
            if (response) {
                setEquiList(response);
            }
        });

        httpclientService.get("/api/reports/getsuppliers").then((response) => {
            if (response && response.length>0) {
                setSupplierList(response.map((item) => ({
                    key: item.id,
                    displayText: item.companyName
                })));
            }
        });

        if (props.equiid) {

            httpclientService.get(`/api/equipments/getequidetailsforedit?equipid=${props.equiid}`).then((response) => {
                if (response) {
                    setInitialValues(response);
                    setSelectedObjectID(response.objectid);
                    setSelectedObjectNrInternal(response.objectnrinternal);
                    setSelectedEquiClassID(response.equiclassid);
                    setSelectedEquiClassText(response.equiclasstext);
                    setSelectedEquiTypeID(response.equitypeid);
                    setSelectedEquiTypeText(response.equitypetext);
                    setSelectionStep(SelectionStep.Done);
                }
            });
        }

    }, []);

    useEffect(() => {
        if (selectedEquiClassID && selectedEquiClassID > 0 && selectionStep === SelectionStep.Done) {
            httpclientService.get(`/api/equipments/getequiattributeslist?equiClassID=${selectedEquiClassID}`).then((response) => {
                if (response) {
                    var tmpInitialValue = { ...formRef.current.values };
                    var tmpMidArr = [
                        ...response.filter((item) => tmpInitialValue.attributes.map((a) => a.id).indexOf(item.id) == -1),
                        ...tmpInitialValue.attributes.filter((item) => response.map((a) => a.id).indexOf(item.id) > -1)
                    ];
                    tmpInitialValue.attributes = tmpMidArr;
                    setInitialValues(tmpInitialValue);
                    formRef.current.setFieldValue("attributes", tmpMidArr);
                }
            });
        } else if (initialValues.attributes.length > 0 && selectionStep < SelectionStep.Done) {
            var tmpInitialValue = { ...formRef.current.values };
            tmpInitialValue.attributes = [];
            setInitialValues(tmpInitialValue);
            formRef.current.setFieldValue("attributes", []);
        }
    }, [selectedEquiClassID, selectionStep]);

    function handleUploadedFiles(file) {
        var newUploadedFiles = [...uploadedFiles, ...file];
        setUploadedFiles(newUploadedFiles);
        formRef.current.setFieldValue("attachedFiles", newUploadedFiles);
    }

    function deleteFileButtonClicked(file) {
        var filteredSelectedFiles = uploadedFiles.filter(s => s.name !== file);
        setUploadedFiles(filteredSelectedFiles);
        formRef.current.setFieldValue("attachedFiles", filteredSelectedFiles);
    }

    function handleAttributeValueChange(attrID, event) {
        var tmpList = [...initialValues.attributes ];
        var existingAttribute = tmpList.filter((f) => f.id && f.id === attrID);
        if (existingAttribute.length > 0) {
            existingAttribute[0].attributeValue = event?.target?.value ?? "";
        }
        formRef.current.setFieldValue("attributes", tmpList);
    }

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const validationSchema = Yup.object(schema);

    return (<>
        <Formik
            innerRef={formRef}
            validateOnMount
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                handleCreateEquipment(values)
            }}
            enableReinitialize={true}
        >
            {formik => (
                <Form>
                    {
                        props.equiid == null ?
                            <h1>
                                {t("New_Equipment")}
                            </h1>
                            :
                            null
                    }
                    <FirstStep uploadedFiles={uploadedFiles} setUploadedFiles={handleUploadedFiles} deleteFileButtonClicked={deleteFileButtonClicked} equiclassid={props.equiclassid} />
                    <br/>
                    {
                        initialValues.attributes.length > 0 ?
                            <OmisContainer fluid>
                                <Card variant={"outlined"}>
                                    <OmisRow>
                                        <AttributesList items={initialValues.attributes} handleAttributeValueChange={handleAttributeValueChange} />
                                    </OmisRow>
                                </Card>
                            </OmisContainer>
                            :
                            null
                    }
                    <br />

                    <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    {
                                        props.handleModalCancel ?
                                            <OmisButtonSecondary  id={"cancelButton"} text={t("Action_Cancel")} onClick={props.handleModalCancel}></OmisButtonSecondary>
                                            :
                                            <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} component={RouterLink} to={"/equipment"}></OmisButtonSecondary>
                                    }
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                    </OmisContainer>
                </Form>)}
        </Formik>

        {/* formik rerenders on every change / blur, so separate not needed components from form*/}
        <OmisDialog open={modalOpen}
            maxWidth={"md"}
            title={""}
            content={<OmisProgress />}
        />
    </>);

    //region helper functions

    function handleObjectSelect(selection) {
        setSelectionStep(SelectionStep.EquiClass);
        updateSelection(selection);
        setCatalogueCardClicked(false);
    }

    function handleEquiClassSelect(selection) {
        setSelectionStep(SelectionStep.EquiType);
        updateSelection(selection);
        setCatalogueCardClicked(false);
    }

    function handleEquiTypeSelect(selection) {
        setSelectionStep(SelectionStep.Done);
        updateSelection(selection);
        setCatalogueCardClicked(false);
    }

    function handleSingleEntry(selectionGrid, row) {
        switch (selectionGrid) {
            case SelectionStep.Object:
                setSelectionStep(SelectionStep.EquiClass);
                break;
            case SelectionStep.EquiClass:
                setSelectionStep(SelectionStep.EquiType);
                break;
            case SelectionStep.EquiType:
                setSelectionStep(SelectionStep.Done);
                break;
            default:
                break;
        }

        updateSelection(row);
    }

    function clearSelection(row) {
        var clearObject = selectionStep < SelectionStep.EquiClass;
        var clearEquiClass = selectionStep < SelectionStep.EquiType;
        var clearEquiType = selectionStep < SelectionStep.Done;

        if (clearObject) {
            setSelectedObjectID(null);
            setSelectedObjectNrInternal('');
            formRef.current.setFieldValue("objectid", null);
        }

        if (clearEquiClass) {
            setSelectedEquiClassID(null);
            setSelectedEquiClassText('');
            formRef.current.setFieldValue("equiclassid", null);
        }

        if (clearEquiType) {
            setSelectedEquiTypeID(null);
            setSelectedEquiTypeText('');
            formRef.current.setFieldValue("equitypeid", null);
        }
    }

    function updateSelection(row) {
        switch (selectionStep) {
            case SelectionStep.Object:
                setSelectedObjectID(row?.id ?? null);
                setSelectedObjectNrInternal(row?.objectnrinternal ?? '');
                formRef.current.setFieldValue("objectid", row?.id ?? null);
                break;
            case SelectionStep.EquiClass:
                setSelectedEquiClassID(row?.id ?? null);
                setSelectedEquiClassText(row?.equiclassdesc ?? '');
                formRef.current.setFieldValue("equiclassid", row?.id ?? null);

                break;
            case SelectionStep.EquiType:
                setSelectedEquiTypeID(row?.id ?? null);
                setSelectedEquiTypeText(row?.equitypedesc ?? '');
                formRef.current.setFieldValue("equitypeid", row?.id ?? null);
                break;
            default:
                break;
        }
    }
    //endregion

    function FaultCatalogueGrid(props) {
        const {step} = {...props};

        switch (step) {
            case SelectionStep.Object:
                return (
                    <OmisGrid rowclickCallback={handleObjectSelect} noButtons noHeader
                        apiUrl={"/api/objects/getobjectlist"}
                        singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.Object)}
                        catalogueCardClicked={catalogueCardClicked }
                    />);
            case SelectionStep.EquiClass:
                return (
                    <OmisGrid rowclickCallback={handleEquiClassSelect} noButtons noHeader
                        apiUrl={`/api/equipment/equiclass/getequiclasses`}
                        singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.EquiClass)}
                        searchByIDInList={props?.equiclassid}
                        isInListCallback={handleSingleEntry.bind(this, SelectionStep.EquiClass)}
                        catalogueCardClicked={catalogueCardClicked} />);
            case SelectionStep.EquiType:
                return (
                    <OmisGrid rowclickCallback={handleEquiTypeSelect} noButtons noHeader
                        apiUrl={`/api/equipment/equitype/getequitypes?equiclassid=${selectedEquiClassID}`}
                        singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.EquiType)}
                        catalogueCardClicked={catalogueCardClicked} />);
            default:
                break;
        }
    }

    function rangeOnChange(val) {
        formRef.current.setFieldValue("warrantyfrom", val.fromDate);
        formRef.current.setFieldValue("warrantyto", val.toDate);
    }

    function FirstStep(props) {

        function fileUploadCallback(event) {
            props.setUploadedFiles(event);
        }

        return (
            <>
                <h3>
                    { t("Details")}
                </h3>
                <OmisContainer fluid>
                    <Card variant={"outlined"}>
                        <OmisRow>
                            <OmisCol xs={6}>
                                <Card variant={"outlined"}>
                                    <OmisRow>
                                        <OmisCol xs={12}>
                                            <OmisTextBox labeltext={t("IDExtern")} name={"idexternal"} placeholder={t("IDExtern")} />
                                        </OmisCol>
                                        <OmisCol xs={12}>
                                            <OmisTextBox labeltext={t("EquiDescription")} name={"equidescription"} placeholder={t("EquiDescription")} />
                                        </OmisCol>
                                        <OmisCol xs={6}>
                                            <OmisTextBox labeltext={t("Equipment_SNR")} name={"serialnumber"} placeholder={t("Equipment_SNR")} />
                                        </OmisCol>
                                        {
                                            statusList.length > 0 ?
                                                <OmisCol xs={6}>
                                                    <OmisLabel label={t("Status")} required />
                                                    <OmisDropdown size={"small"} id={"status"} name={"status"} items={statusList} />
                                                </OmisCol>
                                                :
                                                null
                                        }
                                        <OmisCol xs={12}>
                                            <OmisTextBox labeltext={t("Equipment_Producer")} name={"producer"} placeholder={t("Equipment_Producer")} />
                                        </OmisCol>
                                        {
                                            ownerList.length > 0 ?
                                                <OmisCol xs={12}>
                                                    <OmisLabel label={t("Equipment_Owner")} />
                                                    <OmisDropdown size={"small"} id={"owner"} name={"owner"} items={ownerList} />
                                                </OmisCol>
                                                :
                                                null
                                        }
                                        <OmisCol xs={12}>
                                            <OmisLabel label={t("ScrappingDate")} />
                                            <OmisDatePicker id={"scrappingdate"} name={"scrappingdate"} className={"OmisComponent"} />
                                        </OmisCol>
                                    </OmisRow>
                                </Card>
                            </OmisCol>
                            <OmisCol xs={6}>
                                <Card variant={"outlined"}>
                                    <OmisRow>
                                        <OmisCol xs={12}>
                                            <OmisTextBox labeltext={t("Equipment_IDInternally")} name={"idinternally"} placeholder={t("Equipment_IDInternally")} />
                                        </OmisCol>
                                        {
                                            equiList.length > 0 ?
                                                <OmisCol xs={12}>
                                                    <OmisLabel label={t("Equipment_Parent")} />
                                                    <OmisDropdown size={"small"} id={"equis"} name={"parentequiid"} items={equiList} />
                                                </OmisCol>
                                                :
                                                null
                                        }

                                        <OmisCol xs={6}>
                                            <OmisTextBox labeltext={t("Equipment_InvNr")} name={"inventorynumber"} placeholder={t("Equipment_InvNr")} />
                                        </OmisCol>
                                        <OmisCol xs={6}>
                                            <OmisTextBox labeltext={t("TenderSpec_Amount")} name={"amount"} placeholder={t("TenderSpec_Amount")} />
                                        </OmisCol>
                                        <OmisCol xs={12}>
                                            <OmisTextBox labeltext={t("Equipment_Vendor")} name={"vendor"} placeholder={t("Equipment_Vendor")} />
                                        </OmisCol>

                                        <OmisCol xs={12}>
                                            <OmisLabel label={t("Equipment_YearOfConstruction")} />
                                            <OmisYearPicker name={"dateofconstruction"} className={"OmisComponent"} />
                                        </OmisCol>
                                    </OmisRow>
                                </Card>
                            </OmisCol>
                            <OmisCol xs={6}>
                                <DocumentUploadForNewReport multiFilesUploadCallback={fileUploadCallback} referer={DMSDocumentReferer.Equipment} />
                                <OmisCol style={{ justifyContent: 'center', marginLeft: '2em' }} xs={12}>
                                    {
                                        props.uploadedFiles && props.uploadedFiles.length > 0 ?
                                            <>
                                                <span>{`${t("Import_UploadedFiles")} :`}</span>
                                                {
                                                    props.uploadedFiles.map((file, index) =>
                                                        <OmisCol key={index.toString()} xs={12}>
                                                            <strong>{file.name}</strong>
                                                            <IconButton variant={"outlined"}
                                                                color={"error"}
                                                                onClick={() => props.deleteFileButtonClicked(file.name)}
                                                                id={"deleteUploadedFilesButton"}
                                                            >
                                                                <Icon path={mdiTrashCan} size={1} />
                                                            </IconButton>
                                                        </OmisCol>
                                                    )
                                                }
                                            </>
                                            :
                                            null
                                    }
                                </OmisCol>
                            </OmisCol>
                            <OmisCol xs={6}>
                                <Card variant={"outlined"} sx={{ paddingTop: '0em !important' }}>
                                    <OmisAutosizeTextAreaV2 labeltext={t("Comment")} name={"comment"} placeholder={t("Comment")} />
                                </Card>
                            </OmisCol>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <Card variant={"outlined"}>
                                        <OmisRow>
                                            <OmisCol xs={6}>
                                                <OmisLabel label={t("Equipment_GuaranteeFrom")} />
                                            </OmisCol>
                                            <OmisCol xs={6}>
                                                <OmisLabel label={t("Equipment_GuaranteeTo")} sx={{ marginLeft:'1.5em' }} />
                                            </OmisCol>
                                        </OmisRow>
                                        <OmisDateRangePicker fromDate={initialValues.warrantyfrom} toDate={initialValues.warrantyto} onChange={rangeOnChange} />
                                        <OmisTextBox labeltext={t("TenderSpec_GuaranteeDiscount")} name={"warrantydiscount"} placeholder={t("TenderSpec_GuaranteeDiscount")} />

                                        <OmisCol xs={12}>
                                            <OmisCheckBox labeltext={t("TenderSpec_Active")} id={"tSpecActive"} name={"tSpecActive"} className={"OmisComponent"} />
                                        </OmisCol>
                                    </Card>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <Card variant={"outlined"}>
                                        {
                                            supplierList.length > 0 ?
                                                <OmisCol xs={12}>
                                                    <OmisLabel label={t("MaintenanceContractType_Guarantee")} />
                                                    <OmisDropdown size={"small"} id={"warrantysupplierid"} name={"warrantysupplier"} items={supplierList} />
                                                </OmisCol>
                                                :
                                                null
                                        }
                                        {
                                            supplierList.length > 0 ?
                                                <OmisCol xs={12}>
                                                    <OmisLabel label={t("CompanySiteWizard_Step1_Header")} />
                                                    <OmisDropdown size={"small"} id={"supplierid"} name={"supplierid"} items={supplierList} />
                                                </OmisCol>
                                                :
                                                null
                                        }
                                    </Card>
                                </OmisCol>
                            </OmisRow>
                        </OmisRow>
                    </Card>
                    <br />

                    <Card variant={"outlined"}>
                        <OmisRow>
                            <OmisCol xs={4}>
                                <OmisSelectedCatalogueCard
                                    text={`${t("Object_Details_Title")} (${selectedObjectNrInternal})`}
                                    id={selectedObjectID}
                                    selectionstep={SelectionStep.Object}
                                    handleDeselect={() => {
                                        if (selectionStep >= SelectionStep.Object) {
                                            setSelectionStep(SelectionStep.Object);
                                            setCatalogueCardClicked(true);
                                        }
                                    }}
                                    required
                                />
                            </OmisCol>
                            <OmisCol xs={4}>
                                <OmisSelectedCatalogueCard
                                    text={`${t("EquiClass")} (${selectedEquiClassText})`}
                                    id={selectedEquiClassID}
                                    selectionstep={SelectionStep.EquiClass}
                                    handleDeselect={() => {
                                        if (selectionStep >= SelectionStep.EquiClass) {
                                            setSelectionStep(SelectionStep.EquiClass);
                                            setCatalogueCardClicked(true);
                                        }
                                    }}
                                    required
                                />
                            </OmisCol>
                            <OmisCol xs={4}>
                                <OmisSelectedCatalogueCard
                                    text={`${t("EquiType")} (${selectedEquiTypeText})`}
                                    id={selectedEquiTypeID}
                                    selectionstep={SelectionStep.EquiType}
                                    handleDeselect={() => {
                                        if (selectionStep >= SelectionStep.EquiType) {
                                            setSelectionStep(SelectionStep.Object);
                                            setCatalogueCardClicked(true);
                                        }
                                    }}
                                    required
                                />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <FaultCatalogueGrid step={selectionStep} equiclassid={props.equiclassid} />
                        </OmisRow>
                    </Card>
                </OmisContainer>
            </>
        )
    }
}

export function AttributesList(props) {

    function handleOnChange(attrID, event) {
        if (props.handleAttributeValueChange) {
            props.handleAttributeValueChange(attrID, event);
        }
    }

    return (
        props.items && props.items.length > 0 ?
            <Card>
                <strong>{t("Equipment_AttributesList")}</strong>
                <OmisRow>
                    {
                        props.items.map((item, index) => (
                            <OmisCol xs={{ span: 5, offset: index % 2 === 0 ? 0 : 1 }} key={index}>
                                <OmisTextBoxNoForm label={item.displayText} defaultValue={item.attributeValue} onBlur={handleOnChange.bind(this, item.key)} />
                            </OmisCol>
                        ))
                    }
                </OmisRow>
            </Card>
            :
            null
    );
}