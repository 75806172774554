import {Avatar} from "@mui/material";
import React from "react";

export function OmisAvatar(props) {

    function stringToColor(string) {
        let hash = 0;
        let i;

        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }

        return color;
    }

    function stringAvatar(name) {
        if(!name)
        {
            name = "-";
        }
        var firstLetter = "";
        var secondLetter = "";
        
        if (name.indexOf(' ') === -1) {
            firstLetter = name[0];
            secondLetter = name[1];
        } else {
            firstLetter = name.split(' ')[0][0];
            secondLetter = name.split(' ')[1][0];
        }
        
        return {
            sx: {
                bgcolor: stringToColor(name)
            },
            children: `${firstLetter}${secondLetter}`,
        };
    }
    
    return (
        props.name?<Avatar className="omis-avatar-small" {...stringAvatar(props.name)} sx={{mr:0}}/>:<Avatar src={props.picture} sx={{mr:1}} />
    );
}