import React, {useEffect, useState} from 'react';
import {Col, Navbar, Nav, Row, NavbarBrand, Container} from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import SplitButton from 'react-bootstrap/SplitButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './NavMenu.scss';
import AuthService from "../services/auth.service";
import { TRole } from "../constants/RoleConstants";
import { SupportAgent as SupportAgentIcon, Quiz as QuizIcon }  from '@mui/icons-material';
import Icon from "@mdi/react";
import { mdiAccount, mdiBell, mdiCheckboxMarkedOutline, mdiChevronDoubleLeft, mdiChevronDoubleRight, mdiCog, mdiFileDocumentOutline, mdiFolderCogOutline, mdiGauge, mdiHammerWrench, mdiHelpCircleOutline, mdiHome, mdiInboxFull, mdiLogin, mdiLogout, mdiTable, mdiAccountMultiple, mdiMessageArrowRightOutline } from "@mdi/js";
import {useTranslation} from "react-i18next";
import {OmisLogo} from "../components/shared/OmisIcons"
import { SpaceComponent } from './shared/Shared';
import { NavBarMenus } from '../constants/PageNameConstants';
import NavbarCollapse from 'react-bootstrap/NavbarCollapse'
import { CustomerConstants } from '../constants/CustomerConstants';
import { OmisContainer } from './shared/OmisLayouts';

const RestrictedNavItem = AuthService.withRole([TRole.FSMSettings, TRole.Visibility])(Nav.Item);
var navbarMenus = NavBarMenus();

export default function NavMenu(props) {

    const currentUser = AuthService.getCurrentUser();
    const [activeLink, setActiveLink] = useState("");
    const navigate = useNavigate();

    const { t } = useTranslation();
    const location = useLocation();
    const languageTag = currentUser?.language?.includes("de") ? "/de" : "/en";

    var customer = "";

    switch (currentUser?.customerNr) {

        case CustomerConstants.CUSTOMER_BAVARIA:
            customer = "bp";
            break;
        case CustomerConstants.CUSTOMER_BAYWA:
            customer = "baywa";
            break;
        case CustomerConstants.CUSTOMER_BELL:
            customer = "bell";
            break;
        case CustomerConstants.CUSTOMER_CALPAM:
            customer = "calpam";
            break;
        case CustomerConstants.CUSTOMER_COOP:
            customer = "coop";
            break;
        case CustomerConstants.CUSTOMER_ESLETZBICHLER:
            customer = "esle";
            break;
        case CustomerConstants.CUSTOMER_FOERSTER:
            customer = "foerster";
            break;
        case CustomerConstants.CUSTOMER_GUENTHERTANK:
            customer = "gunthertank";
            break;
        case CustomerConstants.CUSTOMER_HEISLITZ:
            customer = "heislitz";
            break;
        case CustomerConstants.CUSTOMER_HPV:
            customer = "hpv";
            break;
        case CustomerConstants.CUSTOMER_INIT:
            customer = "init";
            break;
        case CustomerConstants.CUSTOMER_MIGROL:
            customer = "migrol";
            break;
        case CustomerConstants.CUSTOMER_MIGROLINO:
            customer = "migrolino";
            break;
        case CustomerConstants.CUSTOMER_MOVERI:
            customer = "moveri";
            break;
        case CustomerConstants.CUSTOMER_OMV_AT:
            customer = "omv";
            break;
        case CustomerConstants.CUSTOMER_PETROTEC:
            customer = "petrotec";
            break;
        case CustomerConstants.CUSTOMER_PICKEL:
            customer = "Pickel";
            break;
        case CustomerConstants.CUSTOMER_Q1:
            customer = "q1";
            break;
        case CustomerConstants.CUSTOMER_RHEINLAND:
            customer = "rk";
            break;
        case CustomerConstants.CUSTOMER_SUEDRAMOL:
            customer = "suedramol";
            break;
        case CustomerConstants.CUSTOMER_TESSOL:
            customer = "tessol";
            break;
        case CustomerConstants.CUSTOMER_VIGO:
            customer = "vigo";
            break;
        default:
            customer = "";
            break;
    }

    useEffect(() => {
        var path = location?.pathname;
        if (path) {
            var navigatedMenu = null;
            try {
                if (!path.startsWith('/')) {
                    navigatedMenu = path.split('/')[0];
                } else {
                    navigatedMenu = path.split('/')[1];
                }

                if (currentUser?.isFirstLogin) {
                    var username = currentUser.username;
                    try {
                        if (!path?.includes("firstloginchangepassword")) {
                            navigate("/firstloginchangepassword", { replace: true, state: { username: username } });
                        }
                    } catch (err) {

                    }
                }

                if (navigatedMenu !== '') {
                    var currentMenu = navbarMenus.filter((menu) => menu.includes(navigatedMenu));
                    if (currentMenu.length > 0 && currentMenu[0] !== activeLink) {
                        setActiveLink(currentMenu[0]);
                    } else {
                        if (currentUser && navigatedMenu==='login') {
                            //TODO: Comment line can be remove after startPage route fixed in DB for Omis Redesign.
                            try {
                                navigate(AuthService.getUserStartPage());
                            } catch (err) {

                            }
                            //navigate("/reports");
                        }
                    }
                } else {
                    if (currentUser) {
                        //TODO: Comment line can be remove after startPage route fixed in DB for Omis Redesign.
                        try {
                            navigate(AuthService.getUserStartPage());
                        } catch (err) {

                        }
                        //navigate("/reports");
                    }
                }
            } catch (err) {
                try {
                    navigate("/reports");
                } catch (err) {

                }
            }
        }
    }, [location]);

    return (
        <NavbarCollapse className={props.collapsed ? "omis-navbar navbar-collapse closed" : "omis-navbar navbar-collapse"}>
			<OmisContainer>
				<Navbar.Brand className='navbar-brand-padding'>
                    <OmisLogo></OmisLogo>
                    <span><SpaceComponent /><OmisNavBrand {...props} displayText={"omis"} /></span>
				</Navbar.Brand>
			</OmisContainer>
            <ul>
                {AuthService.isAuthenticated() && !currentUser?.isFirstLogin && (
                    <>
                        {
                            (AuthService.hasRole(TRole.DashboardAvailable) || AuthService.isFSMMaster()) &&
                            (<OmisNavItem disabled={true}/*disabled for demo presentation*/ link={"dashboard"} {...props} displayText={"Dashboard"} icon={<Icon path={mdiGauge} size={1}></Icon>} />)
                        }
                        {
                            (AuthService.hasRole(TRole.FaultReportRead) || AuthService.isFSMMaster()) &&
                            (<OmisNavItem link={"reports"} {...props} displayText={"FaultReports"} icon={<Icon path={mdiInboxFull} size={1}></Icon>}/>)
                        }
                        {
                            (AuthService.hasRole(TRole.TestdatesRead) || AuthService.isFSMMaster()) &&
                            (<OmisNavItem link={"inspections"} {...props} displayText={"Settings_TestDates"} icon={<Icon path={mdiCheckboxMarkedOutline} size={1}></Icon>}/>)
                        }
                        {
                            (AuthService.hasRole(TRole.GenericDocuments) || AuthService.isFSMMaster()) &&
                            (<OmisNavItem link={"documents"} {...props} displayText={"Documents"} icon={<Icon path={mdiFileDocumentOutline} size={1}></Icon>}/>)
                        }
                        {
                            (AuthService.hasRole(TRole.ObjectRead) || AuthService.isFSMMaster()) &&
                            (<OmisNavItem link={"objects"} {...props} displayText={"Objects"} icon={<Icon path={mdiHome} size={1}></Icon>}/>)
                        }
                        {
                            (AuthService.hasRole(TRole.EquipmentRead) || AuthService.isFSMMaster()) &&
                            (<OmisNavItem link={"equipment"} {...props} displayText={"Equipments"} icon={<Icon path={mdiHammerWrench} size={1}></Icon>}/>)
                        }
                        {
                            (AuthService.isFSMMaster()) &&
                            (<OmisNavItem link={"users"} {...props} displayText={"Users"} icon={<Icon path={mdiAccountMultiple} size={1}></Icon>} />)
                        }
                        {
                            (AuthService.hasRole(TRole.ProjectRead) || AuthService.isFSMMaster()) &&
                            (<OmisNavItem link={"projects"} {...props} displayText={"Projects"} icon={<Icon path={mdiFolderCogOutline} size={1}></Icon>}/>)
                        }
                        {
                            (AuthService.hasRole(TRole.Import) || AuthService.isFSMMaster()) &&
                            (<OmisNavItem link={"systemdata"} {...props} displayText={"SystemData"} icon={<Icon path={mdiTable} size={1}></Icon>}/>)
                        }
						<OmisContainer className='omis-navbar-bottom'>
							{
								(AuthService.hasRole(TRole.CustomerSettings) || AuthService.isFSMMaster()) &&
								(<OmisNavItem link={"settings"} {...props} displayText={"Area_Settings"} icon={<Icon path={mdiCog} size={1}></Icon>}/>)
                            }
                            {
                                (AuthService.hasRole(TRole.CustomerSettings) || AuthService.isFSMMaster()) ?
                                    (<OmisHelpDropdownNavItem customer={customer} languageTag={languageTag} link={"help"}  {...props}></OmisHelpDropdownNavItem>)
                                    :
                                    (<OmisNavItem link={"help"} helpLink={`https://doc.omis.at${customer && customer !== "" ? "/" + customer : customer}${languageTag}`} {...props} displayText={"Area_Help"} icon={<Icon path={mdiHelpCircleOutline} size={1}></Icon>} />)
                            }
                            {
                                (AuthService.hasRole(TRole.DashboardAvailable) || AuthService.isFSMMaster()) &&
								(<OmisNavItem link={"notifications"} {...props} displayText={"Notification"} icon={<Icon path={mdiBell} size={1}></Icon>}/>)
							}
							{
                                (<OmisProfileDropdownNavItem link={"profile"}  {...props}></OmisProfileDropdownNavItem>)
							}
						</OmisContainer>
                    </>
                )}
                {/*
                {
                    !AuthService.isAuthenticated() && (<OmisNavItem link={"login"} {...props} displayText={"Login"} icon={<Icon path={mdiLogin} size={1}></Icon>}/>)
                }
                */ }
            </ul>
        </NavbarCollapse>
    );

    function OmisNavItem(props) {
        var displayText = t(props.displayText);

        return (
            <Nav.Item>
                <Nav.Link disabled={props.disabled} as={Link} to={props.helpLink ?? "/" + props.link} onClick={() => setActiveLink(props.link)} className={activeLink.indexOf(props.link) >= 0 ? "active" : ""}>{props.icon} {!props.collapsed && displayText} </Nav.Link>
            </Nav.Item>
        );
    }

	function OmisProfileDropdownNavItem(props) {
        return (
			<DropdownButton
                key="up-centered"
                id="omis-navbar-dropdown-account"
                drop="up-centered"
                title={<><Icon path={mdiAccount} size={1}></Icon> <span>{!props.collapsed && currentUser?.fullName }</span></>}               
                className={`omis-navbar-dropdown ${activeLink.indexOf(props.link) >= 0 ? "active" : ""}` }>
                <Dropdown.Item as={Link} to={"/" + "profile"} onClick={() => setActiveLink("profile")}><Icon path={mdiAccount} size={1}></Icon> {!props.collapsed && t("Account_Account")} </Dropdown.Item>
                <Dropdown.Item style={{ color: 'red' }} as={Link} to={"/" + "logout"} onClick={() => setActiveLink("logout")}><Icon path={mdiLogout} size={1}></Icon> {!props.collapsed && t("Action_Logout")} </Dropdown.Item>
			</DropdownButton>
        );
    }

    function OmisHelpDropdownNavItem(props) {
        return (
            <DropdownButton
                key="down-centered"
                id="omis-navbar-dropdown-help"
                drop="down-centered"
                title={<><Icon path={mdiHelpCircleOutline} size={1}></Icon> <span>{!props.collapsed && t("Area_Help")}</span></>}
                className={`omis-navbar-dropdown-help ${activeLink.indexOf(props.link) >= 0 ? "active" : ""}`}>
                <Dropdown.Item as={Link} to={"https://support.omis.at"} onClick={() => setActiveLink("support")}><Icon path={mdiMessageArrowRightOutline} size={1}/> {!props.collapsed && t("SupportOmisText")} </Dropdown.Item>
                <Dropdown.Item as={Link} to={`https://doc.omis.at${props.customer && props.customer !== "" ? "/" + props.customer : props.customer}${props.languageTag}`} onClick={() => setActiveLink("omisdocs")}><QuizIcon size={1} /> {!props.collapsed && "omis docs"} </Dropdown.Item>
            </DropdownButton>
        );
    }

	function OmisNavBrand(props) {
        var displayText = t(props.displayText);
        if (props.collapsed) {
            displayText = "";
        }
        return (
                displayText
        );
    }


}

