import React from "react";
import { DetailsCardWithSkeleton } from "../shared/Shared";
import { DetailsCheckListsInfo } from "./DetailsCheckListsInfo";

export function TabContent_CheckLists(props) {
    const state = props.state;

    return (
        <DetailsCardWithSkeleton
            url={`/api/inspections/details?id=${state?.inspectionid}`}
            skeletonheight={"200px"}
            content={
                <DetailsCheckListsInfo {...props} />}{...props} />
    );
}