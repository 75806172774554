import Home from "./components/Home";
import Login, { FirstLoginChangePassword } from "./components/profile/Login";
import Logout from "./components/profile/Logout";
import Profile, { UserDetails } from "./components/profile/Profile";
import Dashboard from "./components/dashboard/Dashboard";
import Documents from "./components/documents/Documents";
import Equipment from "./components/equipment/Equipment";
import Inspections from "./components/inspections/Inspections";
import InspectionControl from "./components/inspections/InspectionControl";
import Notifications from "./components/notifications/Notifications";
import { NotificationDetails } from "./components/notifications/NotificationDetails";
import { NotificationEdit } from "./components/notifications/NotificationEdit";
import Objects from "./components/objects/Objects";
import Reports from "./components/reports/Reports";
import Projects, { ProjectDetails } from "./components/projects/Projects";
import { ProjectEdit } from "./components/projects/ProjectEdit";
import Settings from "./components/settings/Settings";
import SystemData from "./components/systemdata/SystemData";
import {User} from "./components/settings/User/User";
import TextTemplates from "./components/settings/TextTemplates/TextTemplates";
import TradeLevels from "./components/settings/TradeLevels/TradeLevels";
import { ObjectDetails } from "./components/objects/ObjectDetails";
import { ReportsCreate } from "./components/reports/Create/ReportsCreate";
import {FaultReportDetailsLink, ReportsDetails} from "./components/reports/ReportsDetails";
import { EquipmentDetails } from "./components/equipment/EquipmentDetails";
import { DetailsCheckListsInfo } from "./components/inspections/DetailsCheckListsInfo";
import { InspectionDetails } from "./components/inspections/InspectionDetails";
import { InspectionsCreate } from "./components/inspections/InspectionsCreate";
import Users from "./components/users/Users";
import { GroupDetails } from "./components/users/GroupDetails";
import { AddNote as ReportAddNote } from "./components/reports/AddNote";
import AddNewUser from "./components/users/AddNewUser";
import AddNewGroup from "./components/users/AddNewGroup";
import ResetPassword from "./components/profile/ResetPassword";
import {TRole} from "./constants/RoleConstants";
import { ReportsReclassify } from "./components/reports/ReportsReclassify";
import FaultEquiClassGroup from "./components/settings/Reports/FaultEquiClassGroup"
import FaultReportKind from "./components/settings/Reports/FaultReportKind";
import FaultEquiClassDef from "./components/settings/Reports/FaultEquiClassDef";
import FaultKind from "./components/settings/Reports/FaultKind"
import ReactTimeModel from "./components/settings/Reports/ReactTimeModel"
import SysParameter from "./components/settings/System/SysParameter";
import SysParameterCust from "./components/settings/System/SysParameterCust";
import SettingsCust from "./components/settings/System/SettingsCust";
import Maintenance from "./components/settings/System/Maintenance";
import ConnectionTest from "./components/settings/System/ConnectionTest";
import { SettingsOperationLabels } from "./components/settings/Objects/SettingsOperationLabel";
import { CreateOperationLabel } from "./components/settings/Objects/CreateOperationLabel";
import { SettingsObjectAttributes } from "./components/settings/Objects/SettingsObjectAttribute";
import { CreateObjectAttribute } from "./components/settings/Objects/CreateObjectAttribute";
import { CreateObjectAttributeDef } from "./components/settings/Objects/CreateObjectAttributeDef";
import { SettingsTradeLevels } from "./components/settings/Objects/SettingsTradeLevel";
import { CreateTradeLevel } from "./components/settings/Objects/CreateTradeLevel";
import { CreateObjectStatus } from "./components/settings/Objects/CreateObjectStatus";
import { SettingsObjectStatuses } from "./components/settings/Objects/SettingsObjectStatus";
import { CreateObjectContactPerson } from "./components/settings/Objects/CreateObjectContactPerson";
import { SettingsObjectContactPersons } from "./components/settings/Objects/SettingsObjectContactPerson";
import { SettingsObjects } from "./components/settings/Objects/SettingsObjects";
import { ObjectCreate } from "./components/objects/create/ObjectCreate";
import { SettingsObjectsDetails } from "./components/settings/Objects/SettingsObjectsDetails";
import { SettingsObjectUsers } from "./components/settings/Objects/SettingsObjectUsers";
import { CreateObjectUser } from "./components/settings/Objects/CreateObjectUser";
import { CreateObjectContractor } from "./components/settings/Objects/CreateObjectContractor";
import { SettingsObjectContractors } from "./components/settings/Objects/SettingsObjectCompanySites";
import { EquipmentCreate } from "./components/equipment/create/EquipmentCreate";
import { SettingsRoles } from "./components/settings/System/SettingsRoles";
import { SettingsRoles_Details } from "./components/settings/System/SettingsRoles_Details";
import { SettingsPasswordPolicy } from "./components/settings/System/SettingsPasswordPolicy";
import { SettingsRegions } from "./components/settings/Objects/SettingsRegion";
import { CreateRegion } from "./components/settings/Objects/CreateRegion";
import { CreateSeason } from "./components/settings/Objects/CreateSeason";
import { SettingsSeasons } from "./components/settings/Objects/SettingsSeasons";
import { SettingsSalesArea } from "./components/settings/Objects/SettingsSalesArea";
import { CreateSalesArea } from "./components/settings/Objects/CreateSalesArea";
import { CreatePublicHoliday } from "./components/settings/Objects/CreatePublicHoliday";
import { SettingsPublicHolidays } from "./components/settings/Objects/SettingsPublicHoliday";
import { SettingsOperationKinds } from "./components/settings/Objects/SettingsOperationKind";
import { CreateOperationKind } from "./components/settings/Objects/CreateOperationKind";
import { System_FilterDefs } from "./components/settings/System/System_FilterDefs";
import { SettingsAddresses } from "./components/settings/Miscellaneous/SettingsAddresses";
import { CreateAddress } from "./components/settings/Miscellaneous/CreateAddress";
import { SettingsEntityMappings } from "./components/settings/Miscellaneous/SettingsEntityMappings";
import { SettingsMissingNewEquiAttributes } from "./components/settings/FSMIntern/SettingsMissingNewEquiAttributes";
import { SettingsSeverities } from "./components/settings/Reports/SettingsSeverities";
import { CreateSeverity } from "./components/settings/Reports/CreateSeverity";
import { SettingsCostTypes } from "./components/settings/Reports/SettingsCostTypes";
import { CreateCostType } from "./components/settings/Reports/CreateCostType";
import { SettingsCostCenters } from "./components/settings/Reports/SettingsCostCenters";
import { CreateCostCenter } from "./components/settings/Reports/CreateCostCenter";
import { SettingsMessagingParameters } from "./components/settings/Reports/SettingsMessagingParameters";
import { CreateMessagingParameter } from "./components/settings/Reports/CreateMessagingParameter";
import { SettingsFaultAttributeDefs } from "./components/settings/Reports/SettingsFaultAttributeDefs";
import { CreateFaultAttributeDef } from "./components/settings/Reports/CreateFaultAttributeDef";
import { SettingsVisibleStatesConfig } from "./components/settings/Reports/SettingsVisibleStatesConfig";
import { EditVisibleStatesConfig } from "./components/settings/Reports/EditVisibleStatesConfig";
import { SettingsRatings } from "./components/settings/CompanySite/SettingsRatings";
import { CreateRating } from "./components/settings/CompanySite/CreateRating";
import { UserDetailsInfo } from "./components/users/UserDetailsInfo";
import { SettingsUsageLogs } from "./components/settings/FSMIntern/SettingsUsageLogs";
import { SettingsDmsRights } from "./components/settings/FSMIntern/SettingsDmsRights";
import { SettingsMappings } from "./components/settings/FSMIntern/SettingsMappings";
import { CreateMapping } from "./components/settings/FSMIntern/CreateMapping";
import { CreateWebServiceSettings } from "./components/settings/FSMIntern/CreateWebServiceSettings";
import { SettingsWebServiceSettings } from "./components/settings/FSMIntern/SettingsWebServiceSettings";
import { SettingsEquiClasses } from "./components/settings/Equipments/SettingsEquiClasses";
import { CreateEquiClass } from "./components/settings/Equipments/CreateEquiClass";
import { SettingsEquiOwners } from "./components/settings/Equipments/SettingsEquiOwners";
import { CreateEquiOwner } from "./components/settings/Equipments/CreateEquiOwner";
import { SettingsEquipmentStatuses } from "./components/settings/Equipments/SettingsEquipmentStatuses";
import { CreateEquipmentStatus } from "./components/settings/Equipments/CreateEquipmentStatus";
import { SettingsEquipmentContractors } from "./components/settings/Equipments/SettingsEquipmentContractors";
import { CreateEquiContractor } from "./components/settings/Equipments/CreateEquiContractor";
import { SettingsCompanySiteContractors } from "./components/settings/CompanySite/SettingsCompanySiteContractors";
import { SettingsCompanySites } from "./components/settings/CompanySite/SettingsCompanySites";
import { SettingsCompanySiteSkills } from "./components/settings/CompanySite/SettingsCompanySiteSkills";
import { CreateCompanySite } from "./components/settings/CompanySite/CreateCompanySite";
import { CreateContractor } from "./components/settings/CompanySite/CreateContractor";
import { CreateSkill } from "./components/settings/CompanySite/CreateSkill";
import { SettingsEquiClassDetails } from "./components/settings/Equipments/SettingsEquiClassDetails";
import { CreateEquiType } from "./components/settings/Equipments/CreateEquiType";
import { SettingsEquiTypes } from "./components/settings/Equipments/SettingsEquiTypes";
import { SettingsEquiAttributeDefs } from "./components/settings/Equipments/SettingsEquiAttributeDefs";
import { CreateEquiAttributeDef } from "./components/settings/Equipments/CreateEquiAttributeDef";
import { SettingsEquiTestDates } from "./components/settings/Equipments/SettingsEquiTestDates";
import { CreateEquiTestDate } from "./components/settings/Equipments/CreateEquiTestDate";
import { SettingsFaultEquiClassDefs } from "./components/settings/Equipments/SettingsFaultEquiClassDefs";
import { FaultEquiClassDefDetails } from "./components/settings/Reports/FaultEquiClassDefDetails";
import { FaultEquiClassRecipients } from "./components/settings/Reports/FaultEquiClassRecipients";
import { CreateFaultEquiClassRecipient } from "./components/settings/Reports/CreateFaultEquiClassRecipient";
import { CreateAutoDispatchSetting } from "./components/settings/Reports/CreateAutoDispatchSetting";
import { SettingsAutoDispatchSettings } from "./components/settings/Reports/SettingsAutoDispatchSettings";
import { SettingsMemoryCaches } from "./components/settings/FSMIntern/SettingsMemoryCaches";
import NoRoleAssigned from "./components/profile/NoRoleAssigned";
import { SettingsContractorDetails } from "./components/settings/CompanySite/SettingsContractorDetails";
import { CompanySiteDetails } from "./components/settings/CompanySite/CompanySiteDetails";

const AppRoutes = [
    {
        index: true,
        allowAnonymous: true,
        element: <Home />
    },
    {
        path: '/login',
        allowAnonymous: true,
        element: <Login />
    },
    {
        path: '/FaultManagement/FaultManagement/FaultReportDetails',
        element: <FaultReportDetailsLink />
    },
    {
        path: '/firstloginchangepassword',
        allowAnonymous: true,
        element: <FirstLoginChangePassword />
    },
    {
        path: '/noroleassigned',
        allowAnonymous: true,
        element: <NoRoleAssigned />
    },
    {
        path: '/resetpassword',
        allowAnonymous: true,
        element: <ResetPassword />
    },
    {
        path: '/logout',
        element: <Logout />
    },
    {
        path: '/profile',
        element: <Profile />
    },
    {
        path: '/dashboard',
        element: <Dashboard />,
        roles: [TRole.DashboardAvailable]
    },
    {
        path: '/documents',
        element: <Documents />,
        roles: [TRole.GenericDocuments]
    },
    {
        path: '/equipment',
        element: <Equipment />,
        roles: [TRole.EquipmentRead]
    },
    {
        path: '/equipment/details',
        element: <EquipmentDetails />,
        roles: [TRole.EquipmentRead]
    },
    {
        path: '/equipment/create',
        element: <EquipmentCreate />,
        roles: [TRole.EquipmentEdit]
    },
    {
        path: '/inspections',
        element: <Inspections />,
        roles: [TRole.TestdatesRead]
    },
    {
        path: '/inspections/create',
        element: <InspectionsCreate />,
        roles: [TRole.TestdatesWrite]
    },
    {
        path: '/inspections/details',
        element: <InspectionDetails />,
        roles: [TRole.TestdatesRead]
    },
    {
        path: '/inspections/detailschecklists',
        element: <DetailsCheckListsInfo />,
        roles: [TRole.TestdatesRead]
    },
    {
        path: '/inspections/inspectioncontrol',
        element: <InspectionControl />,
        roles: [TRole.TestdatesRead]
    },
    {
        path: '/notifications',
        element: <Notifications />,
        roles: [TRole.FaultReportRead] //there is no notification role yet
    },
    {
        path: '/notifications/details',
        element: <NotificationDetails />,
        roles: [TRole.FaultReportRead] //there is no notification role yet
    },
    {
        path: '/notifications/edit',
        element: <NotificationEdit />,
        roles: [TRole.FaultReportRead] //there is no notification role yet
    },
    {
        path: '/notifications/new',
        element: <NotificationEdit />,
        roles: [TRole.FaultReportRead] //there is no notification role yet
    },
    {
        path: '/objects',
        element: <Objects />,
        roles: [TRole.ObjectRead]
    },
    {
        path: '/object/details',
        element: <ObjectDetails />,
        roles: [TRole.ObjectRead]
    },
    {
        path: '/objects/create',
        element: <ObjectCreate />,
        roles: [TRole.ObjectRead]
    },
    {
        path: '/projects',
        element: <Projects />,
        roles: [TRole.ProjectRead]
    },
    {
        path: '/projects/edit',
        element: <ProjectEdit />,
        roles: [TRole.ProjectEdit]
    },
    {
        path: '/projects/details',
        element: <ProjectDetails />,
        roles: [TRole.ProjectRead]
    },
    {
        path: '/reports',
        element: <Reports />,
        roles: [TRole.FaultReportRead]
    },
    {
        path: '/reports/details',
        element: <ReportsDetails />,
        roles: [TRole.FaultReportRead]
    },
    {
        path: '/reports/create',
        element: <ReportsCreate />,
        roles: [TRole.FaultReportWrite]
    },
    {
        path: '/reports/addnote',
        element: <ReportAddNote />,
        roles: [TRole.FaultReportNote]
    },
    {
        path: '/reports/reportsreclassify',
        element: <ReportsReclassify />,
        roles: [TRole.FaultReportRead]
    },
    {
        path: '/settings',
        element: <Settings />,
        roles: [TRole.CustomerSettings, TRole.FSMSettings]
    },
    {
        path: '/settings/texttemplates',
        element: <TextTemplates />,
        roles: [TRole.TextTemplates]
    },
    {
        path: '/settings/sysparameter',
        element: <SysParameter />,
        roles: [TRole.FSMSettings]
    },
    {
        path: '/settings/sysparametercust',
        element: <SysParameterCust />,
        roles: [TRole.FSMSettings]
    },
    {
        path: '/settings/settingscust',
        element: <SettingsCust />,
        roles: [TRole.FSMSettings]
    },
    {
        path: '/settings/maintenance',
        element: <Maintenance />,
        roles: [TRole.FSMSettings]
    },
    {
        path: '/settings/connectiontest',
        element: <ConnectionTest />,
        roles: [TRole.FSMSettings]
    },
    {
        path: '/settings/tradelevels',
        element: <TradeLevels />
    },
    {
        path: '/settings/user',
        element: <User />,
        roles: [TRole.Settings_User]
    },
    {
        path: '/settings/user/details',
        element: <UserDetails />,
        roles: [TRole.Settings_User]
    },
    {
        path: '/settings/settingsequipments/equiclasses',
        element: <SettingsEquiClasses />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/settingsequipments/equiclassdetails',
        element: <SettingsEquiClassDetails />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/settingsequipments/createequiclass',
        element: <CreateEquiClass />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/settingsequipments/equitypes',
        element: <SettingsEquiTypes />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/system/roles',
        element: <SettingsRoles />,
        roles: [TRole.CustomerSettings]
    },
    {
        path: '/settings/settingsequipments/createequitype',
        element: <CreateEquiType />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/settingsequipments/equiattributedefs',
        element: <SettingsEquiAttributeDefs />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/settingsequipments/createequiattributedef',
        element: <CreateEquiAttributeDef />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/settingsequipments/equitestdates',
        element: <SettingsEquiTestDates />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/system/roledetails',
        element: <SettingsRoles_Details />,
        roles: [TRole.CustomerSettings]
    },
    {
        path: '/settings/settingsequipments/createequitestdate',
        element: <CreateEquiTestDate />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/settingsequipments/faultequiclassdefs',
        element: <SettingsFaultEquiClassDefs />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/settingsreports/faultequiclassdefdetails',
        element: <FaultEquiClassDefDetails />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/faultequiclassrecipient/faultequiclassrecipients',
        element: <FaultEquiClassRecipients />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/faultequiclassrecipient/createfaultequiclassrecipient/',
        element: <CreateFaultEquiClassRecipient />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/settingsreports/faultkind',
        element: <FaultKind />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/settingsreports/autodispatchsettings',
        element: <SettingsAutoDispatchSettings />,
        roles: [TRole.Settings_FaultReports]
    },
    {
        path: '/settings/settingsreports/createautodispatchsetting/',
        element: <CreateAutoDispatchSetting />,
        roles: [TRole.Settings_FaultReports]
    },
    {
        path: '/settings/settingsequipments/equiowners',
        element: <SettingsEquiOwners />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/system/passwordpolicy',
        element: <SettingsPasswordPolicy />,
        roles: [TRole.CustomerSettings]
    },
    {
        path: '/settings/system/filterdefs',
        element: <System_FilterDefs />,
        roles: [TRole.CustomerSettings, TRole.FSMSettings]
    },
    {
        path: '/settings/settingsequipments/createequipmentstatus',
        element: <CreateEquipmentStatus />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/settingsequipments/equipmentstatuses',
        element: <SettingsEquipmentStatuses />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/settingsequipments/createequiowner',
        element: <CreateEquiOwner />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/settingsequipments/createequicontractor',
        element: <CreateEquiContractor />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/settingsequipments/equipmentcontractors',
        element: <SettingsEquipmentContractors />,
        roles: [TRole.Settings_Equipments]
    },
    {
        path: '/settings/companysite/contractors',
        element: <SettingsCompanySiteContractors />,
        roles: [TRole.Settings_Suppliers]
    },
    {
        path: '/settings/companysite/contractordetails',
        element: <SettingsContractorDetails />,
        roles: [TRole.Settings_Suppliers]
    },
    {
        path: '/settings/companysite/createcontractor',
        element: <CreateContractor />,
        roles: [TRole.Settings_Suppliers]
    },
    {
        path: '/settings/companysite/companysites',
        element: <SettingsCompanySites />,
        roles: [TRole.Settings_Suppliers]
    },
    {
        path: '/settings/companysite/companysitedetails',
        element: <CompanySiteDetails />,
        roles: [TRole.Settings_Suppliers]
    },
    {
        path: '/settings/companysite/create',
        element: <CreateCompanySite />,
        roles: [TRole.Settings_Suppliers]
    },
    {
        path: '/settings/companysite/skills',
        element: <SettingsCompanySiteSkills />,
        roles: [TRole.Settings_Suppliers]
    },
    {
        path: '/settings/companysite/createskill',
        element: <CreateSkill />,
        roles: [TRole.Settings_Suppliers]
    },
    {
        path: '/settings/companysite/ratings',
        element: <SettingsRatings />,
        roles: [TRole.Settings_Suppliers]
    },
    {
        path: '/settings/companysite/createrating',
        element: <CreateRating />,
        roles: [TRole.Settings_Suppliers]
    },
    {
        path: '/settings/objects/objects',
        element: <SettingsObjects />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/objectsdetails',
        element: <SettingsObjectsDetails />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/createobjectuser/',
        element: <CreateObjectUser />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/objectusers',
        element: <SettingsObjectUsers />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/createobjectcontractor/',
        element: <CreateObjectContractor />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/objectcontractors',
        element: <SettingsObjectContractors />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/operationlabels',
        element: <SettingsOperationLabels />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/createoperationlabel/',
        element: <CreateOperationLabel />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/objectattributes',
        element: <SettingsObjectAttributes />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/createobjectattribute/',
        element: <CreateObjectAttribute />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/createobjectattributedef/',
        element: <CreateObjectAttributeDef />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/objecttradelevels',
        element: <SettingsTradeLevels />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/createobjectstatus/',
        element: <CreateObjectStatus />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/objectstatus',
        element: <SettingsObjectStatuses />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/createtradelevel/',
        element: <CreateTradeLevel />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/createobjectcontactperson/',
        element: <CreateObjectContactPerson />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/objectcontactpersons',
        element: <SettingsObjectContactPersons />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/regions',
        element: <SettingsRegions />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/createregion/',
        element: <CreateRegion />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/seasons',
        element: <SettingsSeasons/>,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/createseason/',
        element: <CreateSeason />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/objects/',
        element: <SettingsObjects />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/salesarea',
        element: <SettingsSalesArea />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/createsalesarea/',
        element: <CreateSalesArea />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/publicholidays',
        element: <SettingsPublicHolidays />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/createpublicholiday/',
        element: <CreatePublicHoliday />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/operationkinds',
        element: <SettingsOperationKinds />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/objects/createoperationkind/',
        element: <CreateOperationKind />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/miscellaneous/addresses',
        element: <SettingsAddresses />,
        roles: [TRole.Settings_Misc]
    },
    {
        path: '/settings/miscellaneous/createaddress',
        element: <CreateAddress />,
        roles: [TRole.Settings_Misc]
    },
    {
        path: '/settings/miscellaneous/entitymappings',
        element: <SettingsEntityMappings />,
        roles: [TRole.Settings_Misc]
    },
    {
        path: '/settings/settingsfsmintern/missingnewequiattrs',
        element: <SettingsMissingNewEquiAttributes />,
        roles: [TRole.Settings_Object]
    },
    {
        path: '/settings/settingsfsmintern/usagelogs',
        element: <SettingsUsageLogs />,
        roles: [TRole.FSMSettings]
    },
    {
        path: '/settings/settingsfsmintern/dmsrights',
        element: <SettingsDmsRights />,
        roles: [TRole.FSMSettings]
    },
    {
        path: '/settings/settingsfsmintern/mappings',
        element: <SettingsMappings />,
        roles: [TRole.FSMSettings]
    },
    {
        path: '/settings/settingsfsmintern/createmapping',
        element: <CreateMapping />,
        roles: [TRole.FSMSettings]
    },
    {
        path: '/settings/settingsfsmintern/webservicesettings',
        element: <SettingsWebServiceSettings />,
        roles: [TRole.FSMSettings]
    },
    {
        path: '/settings/settingsfsmintern/createwebservicesettings',
        element: <CreateWebServiceSettings />,
        roles: [TRole.FSMSettings]
    },
    {
        path: '/settings/settingsfsmintern/memorycaches',
        element: <SettingsMemoryCaches />,
        roles: [TRole.FSMSettings]
    },
    {
        path: '/users',
        element: <Users />,
        roles: [TRole.Settings_User, TRole.FSMSettings]
    },
    {
        path: '/user/userdetails',
        element: <UserDetailsInfo />,
        roles: [TRole.Settings_User, TRole.FSMSettings]
    },
    {
        path: '/users/groupdetails',
        element: <GroupDetails />,
        roles: [TRole.Settings_User, TRole.FSMSettings]
    },
    {
        path: '/users/addnewuser',
        element: <AddNewUser />,
        roles: [TRole.Settings_User, TRole.FSMSettings]
    },
    {
        path: '/users/addnewgroup',
        element: <AddNewGroup />,
        roles: [TRole.Settings_User, TRole.FSMSettings]
    },
    {
        path: '/systemdata',
        element: <SystemData />,
        roles: [TRole.Import]
    },
    {
        path: '/settings/reports/faultreportkind',
        element: <FaultReportKind />,
        roles: [TRole.Settings_FaultReports, TRole.FSMSettings]
    },
    {
        path: '/settings/reports/faultequiclassgroups',
        element: <FaultEquiClassGroup />,
        roles: [TRole.Settings_FaultReports, TRole.FSMSettings]
    },
    {
        path: '/settings/reports/faultequiclassdef',
        element: <FaultEquiClassDef/>,
        roles: [TRole.Settings_FaultReports, TRole.FSMSettings]
    },
    {
        path: '/settings/reports/faultkinds',
        element: <FaultKind/>,
        roles: [TRole.Settings_FaultReports, TRole.FSMSettings]
    },
    {
        path: '/settings/reports/reacttimemodel',
        element: <ReactTimeModel/>,
        roles: [TRole.Settings_FaultReports, TRole.FSMSettings]
    },
    {
        path: '/settings/reports/severities',
        element: <SettingsSeverities />,
        roles: [TRole.Settings_FaultReports, TRole.FSMSettings]
    },
    {
        path: '/settings/reports/createseverity',
        element: <CreateSeverity />,
        roles: [TRole.Settings_FaultReports, TRole.FSMSettings]
    },
    {
        path: '/settings/reports/costtypes',
        element: <SettingsCostTypes />,
        roles: [TRole.Settings_FaultReports, TRole.FSMSettings]
    },
    {
        path: '/settings/reports/createcosttype',
        element: <CreateCostType />,
        roles: [TRole.Settings_FaultReports, TRole.FSMSettings]
    },
    {
        path: '/settings/reports/costcenters',
        element: <SettingsCostCenters />,
        roles: [TRole.Settings_FaultReports, TRole.FSMSettings]
    },
    {
        path: '/settings/reports/createcostcenter',
        element: <CreateCostCenter />,
        roles: [TRole.Settings_FaultReports, TRole.FSMSettings]
    },
    {
        path: '/settings/reports/messagingparameters',
        element: <SettingsMessagingParameters />,
        roles: [TRole.Settings_FaultReports, TRole.FSMSettings]
    },
    {
        path: '/settings/reports/createmessagingparameter',
        element: <CreateMessagingParameter />,
        roles: [TRole.Settings_FaultReports, TRole.FSMSettings]
    },
    {
        path: '/settings/reports/faultattributedefs',
        element: <SettingsFaultAttributeDefs />,
        roles: [TRole.Settings_FaultReports, TRole.FSMSettings]
    },
    {
        path: '/settings/reports/createfaultattributedef',
        element: <CreateFaultAttributeDef />,
        roles: [TRole.Settings_FaultReports, TRole.FSMSettings]
    },
    {
        path: '/settings/reports/visiblestatesconfig',
        element: <SettingsVisibleStatesConfig />,
        roles: [TRole.Settings_FaultReports, TRole.FSMSettings]
    },
    {
        path: '/settings/reports/editvisiblestatesconfig',
        element: <EditVisibleStatesConfig />,
        roles: [TRole.Settings_FaultReports, TRole.FSMSettings]
    }

];

export default AppRoutes;
