import { OmisGrid } from "../shared/OmisGrids";
import { t } from "i18next";
import React from "react";

export function TabContent_Tenders(props) {
    const {state} = {...props};
    var url = "/api/reports/getsupplieroffers"
    url = `${ url }?reportid=${ state?.reportid }`;

    return (<>

            <OmisGrid
                headerText={ t("TenderList") }
                apiUrl={ url }
                statusDropDownHasItems={ [] }
                priceDropDownHasItems={ [] }
                withOfferTime={ true }{ ...props }/>

        </>);
}