import React, { useState } from "react";
import { DocumentUpload } from "../documents/Documents";
import { DMSDocumentReferer } from "../../constants/DMSDocumentReferer";
import { OmisCol } from "../shared/OmisLayouts";

export function AddDocuments(props) {
    const {state} = {...props};
    const [documentID, setDocumentID] = useState(props.reportid ? props.reportid : props.equipid);
    return (
        <OmisCol xs={12 }>
            <DocumentUpload handleModalClose={props.closeAction} handleModalCancel={props.onCancel} fileUploadCallback={props.fileUploadCallback ?? props.closeAction} id={documentID} referer={props.referer ? props.referer : DMSDocumentReferer.FaultReport} />
        </OmisCol>
);
}