var CryptoJS = require("crypto-js");

const SUPERSECRETPASSWORD = "sfdlktjhpjo4whp4358hjp48hj4�08hm4w08hj�49686u798�05ubm78536um798m3b5um798b35um7984b5u798043um57689485u67098m4b35u6798035bu83mjlnfhghn,bhnmcxhjnb9u4556bjhz94jbh98e4jhbz984uz98ejh98e4jzb98ej46z9bej69z4jz9bm984zjbmw9�48uzb984ewuzb90e8u4z908eu4z98bu498zubw948zub9w48uzb90w84uz9b80w4u5z9buw498zub9w48zub9w48uz98zj498nzub8w4u5bz�9wu4539zbuw49�uzbw98u5zb90w8u4z908wugb43569�80zubw40587ubg�08w45u6�bw0894u5b90�w4u7�098buw489�07ub3480hu";
const SUPERSECRETSALT = "974856zb8947356bz7u974803bum704958u7b9385u7b938um7nb03985umb93jmfgvbkbjnlmcmvgblvgkbjhm45e806mjmuzb945jmu89e4j5mun9w845jmu�b0vw4�907vk,0�3496wbk,7v09�24j, kz30vk9053jzu 986j5u zb983ju89�bum,3456�908ujv3�9845umbjz98�3456jubzmv�98345uj6mb9�83jm456�9bvz83ub4v98uvj29,�84jzubv9�834wjm�z83vjbm4z98vb3uvmj9�83645ukz9�80432u7z9�80mu3m4w�90m8zvu32m4�908zu�90348gvuz,v�9834u,z69�,8v3uw4z�0983uv4�06,zu8v34�,0568zu3�0v48,u567vz�098346uzv�90,8";

var key = CryptoJS.enc.Utf8.parse(SUPERSECRETPASSWORD.substring(0,16));
var iv = CryptoJS.enc.Utf8.parse(SUPERSECRETSALT.substring(0, 16));

var config =
{
    keySize: 256,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
}

export const encrypt = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), key, config).toString();
};

export const decrypt = (str) => {
    var bytes = CryptoJS.AES.decrypt(str, key, config);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};

const UrlEncryptService = {
    encrypt,
    decrypt
}

export default UrlEncryptService;