import React, { useEffect, useRef, useState} from "react";
import AuthService from "../../services/auth.service";
import httpclientService from "../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { OmisPasswordTextBoxWithEye} from "../shared/OmisInputs";
import {OmisButtonPrimarySubmit} from "../shared/OmisButtons";
import { useTranslation } from "react-i18next";
import { OmisCol, OmisRow } from "../shared/OmisLayouts";

export default function ChangePasswordRequest(props) {
    const { t } = useTranslation();
    const [passwordPolicy, setPasswordPolicy] = useState(null);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [poorPassword, setPoorPassword] = useState(false);
    const [weakPassword, setWeakPassword] = useState(false);
    const [strongPassword, setStrongPassword] = useState(false);
    const [passwordError, setPasswordErr] = useState("");
    var user = AuthService.getCurrentUser();
    const navigate = useNavigate();

    useEffect(() => {
        httpclientService.get("/api/profile/getpasswordpolicy").then((response) => {
            setPasswordPolicy(response);
        });
    }, []);

    const [initialValues, setInitialValues] = useState({
        username: user ? user.username : (props.username ? props.username : ''),
        oldPassword:'',
        password: '',
        password2: '',
    });

    const [schema, setSchema] = useState({
        oldPassword: Yup.string()
            .required(t("Account_Password_Empty"))
            .min(6, t('Account_Password_InvalidLength', { 0: passwordPolicy ? passwordPolicy.length : 0 })),
        password: Yup.string()
            .required(t("Account_Password_Empty"))
            .min(6, t('Account_Password_InvalidLength', { 0: passwordPolicy ? passwordPolicy.length : 0 })),
        password2: Yup.string()
            .required(t("Account_Password_Empty"))
            .min(6, t('Account_Password_InvalidLength', { 0: passwordPolicy ? passwordPolicy.length : 0 })),
    });

    const handlePasswordChange = (username, oldPassword, password) => {        
        if (username === '') {
            setPasswordErr(t("Account_InvalidCredentials_User"));
        } else {
            setPasswordErr(null);
            setSubmitButtonDisabled(true);

            AuthService.changepassword(username, oldPassword, password).then(
                (result) => {
                    if (result.status === 200) {
                        if (props.fromFirstLoginChangePassword) {
                            var currUser = AuthService.getCurrentUser();
                            currUser.isFirstLogin = false
                            localStorage.setItem("user", JSON.stringify(currUser));
                            navigate(AuthService.getUserStartPage());
                        } else {
                            if (user) {
                                AuthService.logout();
                            }
                            navigate("/login");
                        }
                    } else {
                        setPoorPassword(false);
                        setWeakPassword(false);
                        setStrongPassword(false);
                        setPasswordErr(result.data.message);
                        setSubmitButtonDisabled(false);
                    }
                }
            );
        }
    };

    const passwordStrength = (newPass, newPass2) => {
        const passwordValue = newPass;

        const passwordValue2 = newPass2;

        setSubmitButtonDisabled(true);
        setPoorPassword(false);
        setWeakPassword(false);
        setStrongPassword(false);

        if (passwordValue !== passwordValue2) {
            setPasswordErr(t("Account_Password_DoNotMatch"));

        } else if (passwordValue !== "" || passwordValue2 !== "") {
            const passwordLength = passwordValue.length;
            const lowerRegExp = /[a-z]|[ä-ü]/;
            const upperRegExp = /[A-Z]|[Ä-Ü]/;
            const weakRegExp = /(?=.*?[0-9])/;
            const strongRegExp = /(?=.*?[#?!@$%^&*-+._,:])/;
            const whitespaceRegExp = /^$|\s+/;
            const hasLowerCase = lowerRegExp.test(passwordValue);
            const hasUpperCase = upperRegExp.test(passwordValue);
            const weakPassword = weakRegExp.test(passwordValue);
            const strongPassword = strongRegExp.test(passwordValue);
            const whiteSpace = whitespaceRegExp.test(passwordValue);

            if (passwordValue === '') {
                setPasswordErr(t("Account_Password_Empty"));
            } else {
                // to check whitespace
                if (whiteSpace) {
                    setPasswordErr(t("PasswordWhiteSpace"));
                }
                // to check poor password
                if (passwordLength <= 3 || (hasLowerCase || hasUpperCase || weakPassword || strongPassword)) {
                    setPoorPassword(true);
                    setPasswordErr(t("PasswordIsPoor"));
                }
                // to check weak password
                if (passwordLength >= 4 && ((hasLowerCase || hasUpperCase) || (weakPassword || strongPassword))) {
                    setWeakPassword(true);
                    setPasswordErr(t("PasswordIsWeak"));
                } else {
                    setWeakPassword(false);
                }
                // to check strong Password
                if (passwordLength >= passwordPolicy.length && (hasLowerCase && weakPassword) && hasUpperCase && strongPassword) {
                    setStrongPassword(true);
                    setSubmitButtonDisabled(false);
                    setPasswordErr(t("PasswordIsStrong"));
                } else {
                    setStrongPassword(false);
                }
            }
        }
    }

    const validationSchema = Yup.object(schema);
        
    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validate={(values) => {
                    passwordStrength(values.password, values.password2);
                }}
                onSubmit={(values) => { handlePasswordChange(values.username, values.oldPassword, values.password) }}
            >
                <Form>
                    <OmisCol className="justify-content-center d-flex align-items-center align-self-center align-content-center flex-wrap">
                        <OmisCol xs={12} md={{ span: 10 }} xxl={8} className="justify-content-center d-flex align-items-center align-self-center align-content-center flex-wrap">
                            <ChangePassword passwordPolicy={passwordPolicy} poorPassword={poorPassword} weakPassword={weakPassword} strongPassword={strongPassword} passwordError={passwordError} submitButtonDisabled={submitButtonDisabled} {...props} />
                        </OmisCol>
                    </OmisCol>
                </Form>
            </Formik>
        </div>
    );
}

export function ChangePassword(props) {
    const { t } = useTranslation();
    return (
        <>
            <OmisCol xs={12} md={{ span: 10 }} xxl={8}>
                <OmisPasswordTextBoxWithEye labeltext={t("Account_OldPassword")} name="oldPassword" placeholder={t("Account_OldPassword")} required />
            </OmisCol>
            <OmisCol xs={12} md={{ span: 10 }} xxl={8}>
                <OmisPasswordTextBoxWithEye labeltext={t("Account_NewPassword1")} name="password" placeholder={t("Account_NewPassword1")} required />
            </OmisCol>
            <OmisCol xs={12} md={{ span: 10 }} xxl={8}>
                <OmisPasswordTextBoxWithEye labeltext={t("Account_NewPassword2")} name="password2" placeholder={t("Account_NewPassword2")} required />
            </OmisCol>
            <OmisCol xs={12} md={{ span: 10 }} xxl={8}>
                <StrengthMeter poorPassword={props.poorPassword} weakPassword={props.weakPassword} strongPassword={props.strongPassword} passwordError={props.passwordError} />
            </OmisCol>
            {
                props.passwordPolicy ?
                    <OmisCol xs={12} md={{ span: 10 }} xxl={8}>
                        <p>{`${t("Important")}:
                        ${props.passwordPolicy.upperCase ? `${t('Account_Password_NoUpperCase', { 0: props.passwordPolicy.upperCase })},` : ''}
                        ${props.passwordPolicy.lowerCase ? `${t('Account_Password_NoLowerCase', { 0: props.passwordPolicy.lowerCase })},` : ''}
                        ${props.passwordPolicy.digits ? `${t('Account_Password_NoNumber', { 0: props.passwordPolicy.digits })},` : ''}
                        ${props.passwordPolicy.special ? `${t('Account_Password_Special', { 0: props.passwordPolicy.special })},` : ''}
                        ${props.passwordPolicy.length ? `${t('Account_Password_InvalidLength', { 0: props.passwordPolicy.length })},` : ''}
                        *`}</p>
                    </OmisCol>
                    :
                    null
            }
            {
                props.hidesubmit ?
                    null
                    :
                    <OmisCol xs={12} md={{ span: 10 }} xxl={8} className="loginSubmit">
                        <OmisButtonPrimarySubmit id={"submitButton"} text={t("Action_Save")} disabled={props.submitButtonDisabled} />
                    </OmisCol>


            }
            {
                props.backButtonAction ?
                    <OmisCol xs={12} md={{ span: 10 }} xxl={8}>
                        <a id="backButton" className="font-orange" onClick={props.backButtonAction}>{t("Action_Back")}</a>
                    </OmisCol>
                    :
                    null
            }
        </>
    );
}

export function StrengthMeter(props) {

    var className = props.strongPassword ? 'text-success' : props.weakPassword ? 'text-warning' : 'text-danger';

    return (
        <>
            <ul className="list-group list-group-horizontal">

                {props.poorPassword === true ? <li className="list-group-item bg-danger col-4" style={{ padding: "1px 0px" }}></li> : ''}
                {props.weakPassword === true ? <li className="list-group-item bg-warning col-4" style={{ padding: "1px 0px" }}></li> : ''}
                {props.strongPassword === true ? <li className="list-group-item bg-success col-4" style={{ padding: "1px 0px" }}></li> : ''}

            </ul>
            <div className={className} ><b>{props.passwordError}</b></div>        
        </>      
    )
}