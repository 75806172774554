import React, { useRef, useState } from "react";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { t } from "i18next";
import { Form, Formik } from "formik";
import { OmisCol, OmisRow } from "../../shared/OmisLayouts";
import { OmisButtonPrimarySubmit } from "../../shared/OmisButtons";
import { OmisButtonSecondary } from "../../shared/OmisButtons";
import { Roles } from "./../Roles";

var api = "/api/profile/";

export default function AddUserRoles(props) {

    const navigate = useNavigate();

    const formRef = useRef();
    const [initialValues, setInitialValues] = useState({
        userID: props.userID ?? null,
        roles: [],
    });

    const validationSchema = Yup.object({
        userID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1, t('GenericRequiredText')),
        roles: Yup.array()
            .min(1, t('GenericRequiredText')),
    });

    const handleSelectedRoles = (selectedItems) => {
        formRef.current.setFieldValue("roles", selectedItems.length > 0 && selectedItems[0].id ? selectedItems.map((item) => item.id) : selectedItems);
    }
    function handleAddUserGroup(values) {
        httpclientService.post(`${api}adduserroles`, values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(-1);
                }
            }
        });
    }

    function handleCancel() {
        if (props.onCancel) {
            props.onCancel();
        } else {
            navigate(-1);
        }
    }

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    handleAddUserGroup(values)
                }}
            >
                {formik => (
                    <Form>
                        <OmisRow>
                            <OmisCol xs={6}>
                                <Roles isEdit selectedRoles={initialValues.roles} handleSelectedRoles={handleSelectedRoles} userIDForRemove={props.userIDForRemove} />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol xs={6}>
                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel} />
                            </OmisCol> 
                            <OmisCol xs={6}>
                                <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                            </OmisCol>                            
                        </OmisRow>
                    </Form>)}
            </Formik>           
        </>
    );
};