import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisTextBox } from "../../shared/OmisInputs";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateFaultEquiClassRecipient(props) {
    const navigate = useNavigate();
    const formRef = useRef();
    const [faultEquiClassDefs, setFaultEquiClassDefs] = useState([]);
    const [initialValues, setInitialValues] = useState({
        id: props.id ?? null,
        recipients: props.rowVals?.recipients ?? "",
        faultEquiClassDefID: null,
        faultEquiClassGroupID: props.rowVals?.faultEquiClassGroupID ?? null,
    });

    useEffect(() => {
        httpclientService.get("/api/settings/settingsequipments/getfaultequiclassdefsfordropdown").then((response) => {
            if (response && response.length>0) {
                setFaultEquiClassDefs(response);
            }
        });
    }, []);

    useEffect(() => {
        if (faultEquiClassDefs.length > 0) {
            formRef.current.setFieldValue("faultEquiClassDefID", props.rowVals?.faultEquiClassDefID ?? null);
        }
    }, [faultEquiClassDefs]);

    function handleSave(values) {
        httpclientService.post("/api/settings/faultequiclassrecipient/savefaultequiclassrecipient", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(-1);
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(-1);
        }
    }

    const [schema, setSchema] = useState({
        recipients: Yup.string().email(t("OnlineImport_InvalidFormat"))
            .required(t("GenericRequiredText"))
            .max(255, t('Error_TextTooLong', { 1: 255 })),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        <OmisContainer fluid>
                            {
                                initialValues.id === null ?
                                    <h1>{t("FaultEquiClassRecipient")}</h1>
                                    :
                                    null
                            }
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisTextBox labeltext={t("Email_TO")} name="recipients" required />
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisDropdown labeltext={t("FaultReport_FaultEquiClassDef")} name="faultEquiClassDefID" items={faultEquiClassDefs} disabled={props.rowVals?.faultEquiClassDefID ? true : false} />
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}