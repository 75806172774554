import React, { useCallback, useState } from "react";
import { OmisGrid } from "../shared/OmisGrids";
import httpclientService from "../../services/httpclient.service";
import { t } from "i18next";
import { Link as RouterLink } from "react-router-dom";
import {
    mdiArchive, mdiFileDocument,/* mdiFolder, mdiHome, mdiInboxArrowUp, mdiInboxFull,*/
    mdiInboxArrowDown, mdiFolderMove, mdiPencil, mdiLock,
    mdiPlusBox
} from "@mdi/js";
import { OmisButtonNewItem } from '../shared/OmisButtons';
import AuthService from "../../services/auth.service";
import { TRole } from "../../constants/RoleConstants";
import { AddDocuments } from "../reports/AddDocuments";
import { DMSDocumentReferer } from "../../constants/DMSDocumentReferer";
import { PrepareModalTitle } from "../reports/ReportsDetails";
import { OmisDialog, OmisBulkActionQuestionDialog } from "../shared/OmisDisplays";
import { EquipmentCreate } from "./create/EquipmentCreate";
import { EquipmentBulkEdit } from "./EquipmentBulkEdit";
import { EquipmentBulkMove } from "./EquipmentBulkMove";

var addSubEquipmentTitle = "Add new sub-equipment", editTitle = "",
    addDocumentTitle = "Add document";

export default function Equipment(props) {
    var url = "/api/equipments/getequipmentlist";

    const [modalHeight, setModalHeight] = useState(300);
    const [modalWidth, setModalWidth] = useState("md");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);
    const [freeTextFilter, setFreeTextFilter] = useState("");
    const [selectedRowIDs, setSelectedRowIDs] = useState([]);
    const [dataGridRows, setDataGridRows] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [gridApiRef, setGridApiRef] = useState(null);

    const setRowValues = useCallback((values) => {
        setDataGridRows(values);
    }, []);

    addDocumentTitle = t("AddDocuments");
    editTitle = t("Action_Edit");

    if (props.objectid) {
        url = `${url}?objectid=${props.objectid}`;
    }

    if (props.parentid) {
        url = `${url}?parentid=${props.parentid}`;
    }

    if (props.projectid) {
        url = `${url}?projectid=${props.projectid}`;
    }

    if (freeTextFilter && freeTextFilter !== "") {
        url = `${url}?freeTextFilterVal=${freeTextFilter}`;
    }

    function applyAttributeFilter(value) {
        setFreeTextFilter(value);
        setChangeCounter((prevState) => prevState + 1);
    }

    if (props.equiclassdescription) {
        url = `${url}?equiclassdescription=${props.equiclassdescription}`;
    }
    
    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalCancel= () => {
        setModalOpen(false);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalOpenForDropdownMenuActions = (title, content, e) => {
        var custommodalwidth = "md";

        if (content === null || content === undefined) {
            switch (title) {
                case addDocumentTitle:
                    content = <AddDocuments referer={DMSDocumentReferer.Equipment} equipid={e} onCancel={handleModalCancel} closeAction={handleModalClose} {...props} />;
                    break;
                case editTitle:
                    custommodalwidth = "xl";
                    content = <EquipmentCreate handleModalCancel={handleModalCancel} handleModalClose={handleModalClose} equiid={e} {...props} />;
                    break;
                case "addequipment":
                    custommodalwidth = "xl";
                    content = <EquipmentCreate handleModalCancel={handleModalCancel} handleModalClose={handleModalClose} {...props} />;
                    break;
                default:
                    break;
            }
        }

        if (title === "addequipment") {
            setModalTitle("");

        } else {
            setModalTitle(<PrepareModalTitle modalwidth={custommodalwidth} equipid={e} title={title} />);
        }

        setModalWidth(custommodalwidth);
        setModalContent(content);
        handleModalOpen();
    };

    var subMenuItems = [];

    if (AuthService.hasRole(TRole.DMSEquipment)) {
        subMenuItems = [{
            action: handleModalOpenForDropdownMenuActions.bind(this, addDocumentTitle, null),
            iconpath: mdiFileDocument,
            icontext: t("Documents"),
            disableItem: false
        },...subMenuItems]
    }

    //if (AuthService.hasRole(TRole.FaultReportWrite)) {
    //    subMenuItems = [...subMenuItems,{
    //        action: null,
    //        iconpath: mdiInboxFull,
    //        icontext: "Add report",
    //        disableItem: false
    //    }]
    //}

    var dropDownMenuItems = [

        {
            action: null,
            iconpath: mdiPlusBox,
            icontext: t("Action_Add"),
            expand: false,
            subMenuItems: subMenuItems,
            disableItem: false
        },
        
        //{
        //    action: null,
        //    iconpath: mdiInboxArrowUp,
        //    icontext: "Export CSV",
        //    disableItem: false
        //}        
    ]


    //if (AuthService.hasRole(TRole.ProjectEdit)) {
    //    dropDownMenuItems = [...dropDownMenuItems, {
    //        action: null,
    //        iconpath: mdiFolder,
    //        icontext: "Add to project",
    //        disableItem: false
    //    }];
    //}

    if (AuthService.hasRole(TRole.EquipmentEdit)) {
        dropDownMenuItems = [...dropDownMenuItems, {
            action: handleArchivClick,
            iconpath: mdiArchive,
            icontext: t("Action_Archive"),
            disableItem: false
        }];
    }

    const getSelectedRowsToExport = ()=> {
        if (gridApiRef) {
            const csvOptions = {
                delimiter: ';',
                utf8WithBom: true,
                includeHeaders: true,
            };
            gridApiRef?.current.exportDataAsCsv(csvOptions);
        }
    };

    function handleBulkActions(menuItem) {
        var customModalWidth = "md";
        var customModalTitle = menuItem;
        var customModalHeight = 300;
        var customModalContent = null;
        let selectedRowDatas = null;
        if (dataGridRows) {
            selectedRowDatas = dataGridRows.filter(f => selectedRowIDs.indexOf(f.id) > -1);
        }
        switch (menuItem) {
            case t("Action_Move"):
                customModalContent = <EquipmentBulkMove {...props} ids={selectedRowIDs} handleModalCancel={handleModalCancel} handleModalClose={handleModalClose} selectedRowDatas={selectedRowDatas} />;
                customModalWidth = "lg";
                customModalHeight = 400;
                break;
            case t("Action_Edit"):
                let selectedEquiClassID = Array.from(new Set(selectedRows.map((item: any) => item.equiclassdid)));
                customModalContent = <EquipmentBulkEdit {...props} ids={selectedRowIDs} selectedEquiClassID={selectedEquiClassID.length === 1 ? selectedEquiClassID[0] : null} handleModalCancel={handleModalCancel} handleModalClose={handleModalClose} selectedRowDatas={selectedRowDatas} />;
                customModalWidth = "lg";
                customModalHeight = 800;
                break;
            case t("Action_Archive"):
                customModalTitle = t("Warning_Title");
                customModalContent =
                    <OmisBulkActionQuestionDialog
                        actionCallbackFunction={() => {
                            handleArchivClick(selectedRowIDs).then(() => {
                                handleModalClose();
                            });
                        }}
                        ids={selectedRowIDs}
                        handleModalCancel={handleModalCancel}
                    />;
                break;
            case t("Equipment_Lock"):
                customModalTitle = t("Warning_Title");
                customModalContent =
                    <OmisBulkActionQuestionDialog
                        actionCallbackFunction={() => {
                            handleLockClick(selectedRowIDs).then(() => {
                                handleModalClose();
                            });
                        }}
                        ids={selectedRowIDs}
                        handleModalCancel={handleModalCancel}
                    />;
                break;
            default:
                break;
        }
        setModalTitle(customModalTitle);
        setModalWidth(customModalWidth);
        setModalHeight(customModalHeight);
        setModalContent(customModalContent);
        handleModalOpen();
    }

    var bulkActions = [
        {
            onClick: ()=> getSelectedRowsToExport(),
            subMenuIcon: mdiInboxArrowDown,
            menuName: t("ExcelExport"),
        },
    ];

    if (AuthService.hasRole(TRole.EquipmentEdit) || AuthService.hasRole(TRole.Settings_Equipments) || AuthService.hasRole(TRole.FSMSettings))
    {
        bulkActions = [...bulkActions,
            {
                onClick: handleBulkActions.bind(this, t("Action_Move")),
                subMenuIcon: mdiFolderMove,
                menuName: t("Action_Move"),
            }
        ];
    }

    if (AuthService.hasRole(TRole.EquipmentEdit) || AuthService.hasRole(TRole.Settings_Equipments) || AuthService.hasRole(TRole.FSMSettings)) {
        bulkActions = [...bulkActions,
        {
            onClick: handleBulkActions.bind(this, t("Action_Edit")),
            subMenuIcon: mdiPencil,
            menuName: t("Action_Edit"),
        }
        ];
    }

    if (AuthService.hasRole(TRole.EquipmentEdit) || AuthService.hasRole(TRole.FSMSettings)) {
        bulkActions = [...bulkActions,
        {
            onClick: handleBulkActions.bind(this, t("Action_Archive")),
            subMenuIcon: mdiArchive,
            menuName: t("Action_Archive"),
        }
        ];
    }

    if (AuthService.hasRole(TRole.Equipment_Review) || AuthService.hasRole(TRole.FSMSettings)) {
        bulkActions = [...bulkActions,
        {
            onClick: handleBulkActions.bind(this, t("Equipment_Lock")),
            subMenuIcon: mdiLock,
            menuName: t("Equipment_Lock"),
        }
        ];
    }

    function handleArchivClick(equipids) {
        var values = Array.isArray(equipids) ? equipids : [equipids];
        return httpclientService.post(`/api/equipments/archivequipment`, { ids: values })
            .then((response) => {
                if (response) {
                    setChangeCounter((prevState) => prevState + 1);
                }
            });
    }

    function handleLockClick(equipids) {
        if (equipids) {
            var values = Array.isArray(equipids) ? equipids : [equipids];
            return httpclientService.post(`/api/equipments/lockequipments`, { ids: values })
                .then((response) => {
                    if (response) {
                        setChangeCounter((prevState) => prevState + 1);
                    }
                });
        }
    }

    function handleBulkEdit(values) {
        return httpclientService.post(`/api/equipments/equipmentsbulkedit`, { ids: values })
            .then((response) => {
                if (response) {
                    setChangeCounter((prevState) => prevState + 1);
                }
            });
    }
    
    return (
        <>
            <OmisGrid actiondropdown={AuthService.hasRole(TRole.EquipmentEdit) ? { edit: handleModalOpenForDropdownMenuActions.bind(this, editTitle, null), dropDownMenuItems: dropDownMenuItems } : { dropDownMenuItems: dropDownMenuItems }} equipmentgrid {...props} headerText={t("Equipments")}
                equiClassesDropDownHasItems={[]}
                equiTypesDropDownHasItems={[]}
                manufacturersDropDownHasItems={[]}
                freeTextFilter={{ filterValue: freeTextFilter, setFilterCallback: applyAttributeFilter }}
                change={changeCounter}
                newButton={AuthService.hasRole(TRole.EquipmentEdit) ? props.equiclassid ? < OmisButtonNewItem id={"newEquiButton"} onClick={handleModalOpenForDropdownMenuActions.bind(this, "addequipment", null)} text={t("Action_New")} /> : < OmisButtonNewItem id={"newEquiButton"} component={RouterLink} to={"/equipment/create/"} text={t("Action_New")} /> : null}
                apiUrl={url}
                bulkactions={bulkActions}
                showCheckBox={props.showCheckBox ?? true}
                rowSelectionCallback={(e,objectIDs,selectedRows) => {
                    if (props.rowSelectionCallback) {
                        props.rowSelectionCallback(e);
                    }
                    setSelectedRowIDs(e);
                    setSelectedRows(selectedRows);
                }}
                selectedRows={selectedRowIDs}
                gridApiRef={setGridApiRef}
                gridRowsCallback={setRowValues }
                {...props} />

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={modalWidth}
                title={modalTitle}
                content={modalContent}
                contentHeight={modalHeight}
            />
        </>
    );
}