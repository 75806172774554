import React, { useEffect, useState } from "react";
import AuthService from "../../services/auth.service";
import httpclientService from "../../services/httpclient.service";
import { t } from "i18next";
import { Link as RouterLink } from 'react-router-dom';
import LanguageDropdown from "./LanguageDropdown";
import { OmisAvatar } from "./OmisAvatar";
import { OmisCopyButton } from "../shared/OmisButtons";
import { DetailsCardWithSkeleton, SmallMap } from "../shared/Shared";
import { OmisCollapseCard, OmisDialog } from "../shared/OmisDisplays";
import { OmisButtonPrimary, OmisButtonSecondary } from "../shared/OmisButtons";
import { OmisObjectChip } from "../shared/OmisChips";
import { OmisCol, OmisContainer, OmisRow, OmisStack, OmisTabsWithIcon } from "../shared/OmisLayouts";
import { Card, Chip, ImageListItem, Link as LinkItem } from "@mui/material";
import { Check as CheckIcon } from "@mui/icons-material";
import { mdiInformationOutline, mdiCog, mdiAccountGroup } from '@mdi/js';
import Icon from '@mdi/react';
import ChangePasswordRequest from "./ChangePassword";

var api = "/api/profile/";

export default function Profile(props) {
    const currentUser = AuthService.getCurrentUser();
    const [iconPosition, setIconPosition] = useState("start");

    const viewtabs = [
        {
            title: "Details",
            iconpath: mdiInformationOutline,
            iconposition: iconPosition,
            content: <TabContent_ProfileDetails currentUser={currentUser } {...props} />
        },
        {
            title: `${t("Account_Account")} ${t("Area_Settings")}`,
            iconpath: mdiCog,
            iconposition: iconPosition,
            content: <TabContent_AccountSettings {...props} />
        }
    ]

    return (
        <div className="omis-header-box">
            <OmisContainer fluid>
                <OmisRow>
                    <OmisCol xs={3}>
                        <div className="omis-header-title-details">Profile</div>
                    </OmisCol>
                </OmisRow>
                <OmisRow>
                    <OmisCol xs={12}>
                        <OmisTabsWithIcon tabs={viewtabs} />
                    </OmisCol>
                </OmisRow>
            </OmisContainer>
            
        </div>
    );
};

function TabContent_ProfileDetails(props) {
    var currentUser = { ...props.currentUser }
    return (
        <OmisCol>
            <OmisRow>
                <OmisCol xs={12}>
                    <DetailsCardWithSkeleton
                        url={`${api}getgroupsforuser?customerNr=${currentUser.customerNr}&username=${currentUser.username}`}
                        skeletonheight={"100px"}
                        content={<UserDetails currentUser={currentUser} {...props} />}
                        {...props}
                    />
                </OmisCol>

                <OmisCol xs={12}>
                    <DetailsCardWithSkeleton
                        url={`${api}getusercontacts?customerNr=${currentUser.customerNr}&username=${currentUser.username}`}
                        skeletonheight={"400px"}
                        content={<UserContactsList userdata={currentUser} {...props} />}
                        {...props}
                    />
                </OmisCol>
                <OmisCol xs={12}>
                    <DetailsCardWithSkeleton
                        url={`${api}getuseraddress?customerNr=${currentUser.customerNr}&username=${currentUser.username}`}
                        skeletonheight={"100px"}
                        content={<UserAddressDetailsInfo {...props} />}
                        {...props}
                    />
                </OmisCol>

                <OmisCol xs={12}>
                    <Objects {...props} />
                </OmisCol>
            </OmisRow>
        </OmisCol>
    );
}

export function UserDetails(props) {
    const currentUser = { ...props.currentUser }
    const [imgSrc, setImgSrc] = useState("https://images.unsplash.com/photo-1471357674240-e1a485acb3e1");
    const [imgFormatText, setImgFormatText] = useState("?w=160&h=160&fit=crop&auto=format");

    const [chipModalOpen, setChipModalOpen] = useState(false);

    const handleChipClickOpen = () => {
        setChipModalOpen(true);
    };

    const handleChipClose = () => {
        setChipModalOpen(false);
    };

    return (
        <>
            <br></br>
            <p className="userProfilCardText">
                User details
        </p>
        <Card variant={"outlined"} sx={{height:'450px !important'} }>
          <OmisRow>
            <OmisCol xs={props.hideLanguageDropDown ? 12 : 10}>
              <OmisRow>
                <OmisCol xs={3} >
                  <ImageListItem>
                    <img src={`${imgSrc}${imgFormatText}`}
                      srcSet={`${imgSrc}${imgFormatText}&dpr=2 2x`}
                      loading="lazy"
                    />
                  </ImageListItem>
                </OmisCol>

                <OmisCol xs={8}>
                  <OmisRow>
                    <OmisCol xs={12}><p><strong>{t("UserName")}</strong></p></OmisCol>
                    <OmisCol xs={12} className={"userProfilCardContentTextWB"}><OmisRow><OmisCol xs={1}><OmisAvatar name={currentUser.fullName} /></OmisCol><OmisCol className={`align-content-center`}><span> {currentUser.username}</span></OmisCol> </OmisRow></OmisCol>
                    <OmisCol xs={6}><strong>{t("Name")}</strong></OmisCol>
                    <OmisCol xs={6}>{/*<strong>{t("Role_Title")}</strong>*/}</OmisCol>
                    <OmisCol xs={6} className={"userProfilCardContentTextWB"}>{currentUser.fullName}</OmisCol>
                    <OmisCol xs={6}></OmisCol>
                    <OmisCol xs={6}>
                      <p>
                        <strong>Id</strong>
                      </p>
                    </OmisCol>
                    <OmisCol xs={6}><strong>{t("Group")}</strong></OmisCol>
                    <OmisCol xs={6} className={"userProfilCardContentTextWB"}>{currentUser.id}</OmisCol>
                    <OmisCol xs={6} className={"userProfilCardContentTextWB"}>{props.data && props.data.length > 0 ?
                      props.data.map((item, index) => <Chip key={`${index}_Chip`} avatar={<Icon path={mdiAccountGroup} ></Icon>} clickable={true} onClick={handleChipClickOpen} label={item.description} />)
                      : null}</OmisCol> {/* <Chip clickable={true} onClick={handleChipClickOpen} className={"objectChip"} label={"Add item to Commisioning"} /> */}
                  </OmisRow>
                </OmisCol>
              </OmisRow>

            </OmisCol>
            {
              props.hideLanguageDropDown ?
                null
                :
                <OmisCol xs={2}>
                  <OmisRow>
                    <OmisCol xs={12}><p><strong> {t("Language")}</strong></p></OmisCol>
                    <OmisCol xs={12}>
                      <LanguageDropdown hideLabelText />
                    </OmisCol>
                  </OmisRow>
                </OmisCol>
            }
          </OmisRow>
        </Card>
            <br />
            <OmisDialog onClose={handleChipClose} open={chipModalOpen}
                title={`${t("Group")}`}
                content={"Test"}
                maxWidth={"md"}
                actions=
                {
                    <>
                        <OmisButtonPrimary id={"nameButton"} component={RouterLink} to={"#"} state={props} text={t("Name")}></OmisButtonPrimary>
                        <OmisButtonSecondary id={"cancelButton"} text={t("Action_Close")} onClick={handleChipClose}></OmisButtonSecondary>
                    </>
                }
            />
        </>
        );
}

export function UserContactsList(props) {
    var d = { ...props.data }

    return (
        <>
            <p className="userProfilCardText">
                {t("Contacts")}
            </p>

            <Card variant={"outlined"}>
                <div className="userProfilCardContentText">
                    <OmisRow>
                        <OmisCol xs={4}><p>{t("Phone")}</p></OmisCol>
                        <OmisCol xs={4}><p>{t("Email")}</p></OmisCol>
                        <OmisCol xs={4}><p>{t("Fax")}</p></OmisCol>
                    </OmisRow>
                            
                    <OmisRow>
                        <OmisCol xs={4}  className="userProfilCardContentText">
                            <p className={"userProfilCardContentTextWB bold"}>{d.phone}
                                {d.phone ? <OmisCopyButton text={d.phone} /> : null}</p>
                        </OmisCol>
                        <OmisCol xs={4} className="userProfilCardContentText">
                            <p className={"userProfilCardContentTextWB bold"}>{d.email}
                                {d.email ? <OmisCopyButton text={d.email} /> : null}</p>
                        </OmisCol>
                        <OmisCol xs={4} className="userProfilCardContentText">
                            <p className={"userProfilCardContentTextWB bold"}>{d.fax}
                                {d.fax ? <OmisCopyButton text={d.fax} /> : null}</p>
                        </OmisCol>
                    </OmisRow>
                </div>
            </Card> 
        </>
    );
}

export function UserAddressDetailsInfo(props) {
    return (
        <>
            <p className="userProfilCardText">
                {t("Address")}
            </p>
            <Card variant={"outlined"}>
                <OmisRow>
                    <OmisCol xs={props.hideMap ? 12 : 9} className="userProfilCardContentText">
                        <OmisRow>
                            <OmisCol xs={4}>
                                <p>{t("Address_State")}</p>
                                <p className={"userProfilCardContentTextWB bold"}>{props.data.state}</p>
                            </OmisCol>
                            <OmisCol xs={4}>
                                <p>{t("City")}</p>
                                <p className={"userProfilCardContentTextWB bold"}>{props.data.city}</p>
                            </OmisCol>
                            <OmisCol xs={4}>
                                <p>{t("ZipCode")}</p>
                                <p className={"userProfilCardContentTextWB bold"}>{props.data.zipCode}</p>
                            </OmisCol>
                            <OmisCol xs={4}>
                                <p>{t("Street")}</p>
                                <p className={"userProfilCardContentTextWB bold"}>{props.data.streetstring}</p>
                            </OmisCol>
                            <OmisCol xs={4}>
                                <p>{t("Address_SalesRegion")}</p>
                                <p className={"userProfilCardContentTextWB bold"}>{props.data.salesRegion ? props.data.salesRegion : ""}</p>
                            </OmisCol>
                        </OmisRow>
                    </OmisCol>
                    {
                        props.hideMap ?
                            null
                            :
                            <OmisCol xs={3} className="userProfilCardContentText">
                                {
                                    <SmallMap latitude={props.data.latitude} longitude={props.data.longitude} />
                                }
                            </OmisCol>
                    }
                </OmisRow>
            </Card>
        </>
    );
}

function Permissions(props) {
    const currentUser = { ...props.currentUser }

    const [chipModalOpen, setChipModalOpen] = useState(false);

    const handleChipClickOpen = () => {
        setChipModalOpen(true);
    };

    const handleChipClose = () => {
        setChipModalOpen(false);
    };

    return (
        <>
			<p className="userProfilCardText">
				{t("Permissions")}
            </p>

            <Card variant={"outlined"}>
                <OmisCol className="userProfilCardContentText">
                    <OmisRow>
                        <OmisCol xs={12}><p>{t("Basic")}</p></OmisCol>

                        <OmisCol xs={4}>
                            <Chip clickable={true} onClick={handleChipClickOpen} className={"objectChip"} label={"Add item to Commisioning"} />
                        </OmisCol>
                        <OmisCol xs={4}>
                            <Chip clickable={true} onClick={handleChipClickOpen} className={"objectChip"} label={"Remove item from Commisioning"} />
                        </OmisCol>
                        <OmisCol xs={4}>
                            <Chip clickable={true} onClick={handleChipClickOpen} className={"objectChip"} label={"View customer settings"} />
                        </OmisCol>

                        <OmisCol xs={12}><p>{t("Documents")}</p></OmisCol>

                        <OmisCol xs={4}>
                            <Chip clickable={true} onClick={handleChipClickOpen} className={"objectChip"} label={"Delete document"} />
                        </OmisCol>
                        <OmisCol xs={4}>
                            <Chip clickable={true} onClick={handleChipClickOpen} className={"objectChip"} label={"DMSEquipment"} />
                        </OmisCol>
                    </OmisRow>
                </OmisCol>
            </Card>

            <OmisDialog onClose={handleChipClose} open={chipModalOpen}
                title={`${t("Title")}`}
                content={"Test"}
                maxWidth={"md"}
                actions=
                {
                    <>
                        <OmisButtonPrimary id={"nameButton"} component={RouterLink} to={"#"} state={props} text={t("Name")}></OmisButtonPrimary>
                        <OmisButtonSecondary id={"cancelButton"} text={t("Action_Close")} onClick={handleChipClose}></OmisButtonSecondary>
                    </>
                }
            />
        </>
    );
}

function Objects(props) {
    const currentUser = { ...props.currentUser }

    const [listLimit, setListLimit] = useState(3);
    const [objectsList, setObjectsList] = useState([]);
    const [chipModalOpen, setChipModalOpen] = useState(false);

    const handleChipClickOpen = () => {
        setChipModalOpen(true);
    };

    const handleChipClose = () => {
        setChipModalOpen(false);
    };

    useEffect(() => {
        httpclientService.get(`${api}getuserobjectslist?customerNr=${currentUser.customerNr}&username=${currentUser.username /*"JürgenSchröder"*/}`).then((response) => {
            setObjectsList(response);
        });
    }, []);


    return (
        objectsList && objectsList.length > 0 ?
            <>
                <OmisCol>
                    <OmisRow>
                        <OmisCol xs={2}>
                            <p className="userProfilCardText">
                                {t("Objects")}
                            </p>
                        </OmisCol>
                        <OmisCol xs={2}>
                            {
                                objectsList.length > listLimit ?
                                    <LinkItem
                                        component="button"
                                        underline="none"
                                        variant="body2"
                                        onClick={handleChipClickOpen}
                                    >
                                        View all objects {`(${objectsList.length})`}
                                    </LinkItem>
                                    :
                                    null
                            }
                        </OmisCol>
                    </OmisRow>
                    <OmisRow>
                        <Card variant={"outlined"}>
                            <OmisCol xs={12 } >
                                <ObjectList shortList={true} listLimit={listLimit} objectsList={objectsList} {...props} />
                            </OmisCol>
                        </Card>
                    </OmisRow>
                    
                </OmisCol>

                
                <OmisDialog onClose={handleChipClose} open={chipModalOpen}
                    title={`${t("Title")}`}
                    content={<ObjectList shortList={false} listLimit={listLimit} objectsList={objectsList } {...props} />}
                    maxWidth={"md"}
                    actions=
                    {
                        <>
                            <OmisButtonPrimary id={"nameButton"} component={RouterLink} to={"#"} state={props} text={t("Name")}></OmisButtonPrimary>
                            <OmisButtonSecondary id={"cancelButton"} text={t("Action_Close")} onClick={handleChipClose}></OmisButtonSecondary>
                        </>
                    }
                /> 
            </>
            :
            null            
    );
}

function ObjectList(props) {

    var limit = props.shortList ? props.listLimit : (props.objectsList ? props.objectsList.length : 0);

    return (
        <>
            <OmisRow>
                <OmisCol xs={12}>
                    <OmisCol xs={3}><p>{t("Object")}</p></OmisCol>
                    <OmisCol xs={3}><p>{t("Settings_ObjectContactPersons")}</p></OmisCol>
                </OmisCol>
                {
                    props.objectsList.map((item, index) =>
                        index < limit ?
                            <OmisCol key={`${index}_ChipMainGrid`} xs={12}>
                                <OmisCol xs={3}><OmisObjectChip text={item.objectNrInternal} objectnrinternal={item.objectNrInternal} objectid={item.id} /></OmisCol>
                                <OmisCol xs={3}><CheckIcon className="text-success" /></OmisCol>
                            </OmisCol> : null
                    )
                }
            </OmisRow>
            
        </>
    );
}

function TabContent_AccountSettings(props) {
    return (
        <OmisStack gap={3 }>
            <br></br>
            <OmisCol xs={12}>
                <OmisCollapseCard buttonRowContent={<OmisRow><OmisCol xs={12}><h6> <strong>{t("UserName")}</strong> </h6></OmisCol><OmisCol xs={12}><span>Request username change</span></OmisCol></OmisRow>} variant={"outlined"}>
                </OmisCollapseCard>
            </OmisCol>

            <OmisCol xs={12}>
                <OmisCollapseCard buttonRowContent={<OmisRow><OmisCol xs={12}><h6> <strong>{t("Password")}</strong> </h6> </OmisCol><OmisCol xs={12}><span>Request password change</span></OmisCol></OmisRow>} variant={"outlined"}>
                    <ChangePasswordRequest />
                </OmisCollapseCard>
            </OmisCol>

            <OmisCol xs={12}>
                <OmisCollapseCard buttonRowContent={<OmisRow><OmisCol xs={12}><h6> <strong>{t("Notification")}</strong> </h6> </OmisCol><OmisCol xs={12}><span>Setup automated reminders</span></OmisCol></OmisRow>} variant={"outlined"}>

                </OmisCollapseCard>
            </OmisCol>

            <OmisCol xs={12}>
                <OmisCollapseCard buttonRowContent={<OmisRow><OmisCol xs={12}><h6> <strong>{t("Dashboard")}</strong> </h6></OmisCol><OmisCol xs={12}> <span>Description</span></OmisCol></OmisRow>} variant={"outlined"}>

                </OmisCollapseCard>
            </OmisCol>
        </OmisStack>
    );
}