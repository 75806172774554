import axios from "axios";
import AuthService from "./auth.service";
import JsonStreamDecoder from "./jsonstreamdecoder.service";
import UrlEncryptService from "./urlencrypt.service";

const post = (url, obj) => {
    const token = AuthService.getAccessToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    url = `${process.env.PUBLIC_URL}/${url}`;

    url = url.replace("//", "/");

    AuthService.getSessionUser();

    if (url.includes("?")) {
        var tmpUrl = url.split("?");
        url = `${tmpUrl[0]}?${UrlEncryptService.encrypt(tmpUrl[1])}`;
    }

    return axios.post(url, obj, { headers })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response;
        });
}

const uploadDocument = (files, id, referer, documentType, onUploadProgress,guid) => {
    const token = AuthService.getAccessToken();
    const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    };

    let formData = new FormData();

    for(var i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
    }
    let url = `${process.env.PUBLIC_URL}/api/documents/uploaddocument?id=${id}&referer=${referer}&documentType=${documentType}&guid=${guid}`;

    url = url.replace("//", "/");

    if (url.includes("?")) {
        var tmpUrl = url.split("?");
        url = `${tmpUrl[0]}?${UrlEncryptService.encrypt(tmpUrl[1])}`;
    }

    return axios.post(url, formData, {
        headers,
        onUploadProgress
    });
};

const get = (url) => {
    const token = AuthService.getAccessToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    url = `${process.env.PUBLIC_URL}/${url}`;

    url = url.replace("//", "/");

    if (url.includes("?")) {
        var tmpUrl = url.split("?");
        url = `${tmpUrl[0]}?${UrlEncryptService.encrypt(tmpUrl[1])}`;
    }

    return axios.get(url, { headers }).then((response) => {
        return response.data;
    })
        .catch(function (error) {
            //console.log(error);
            switch (error.response.status) {
                case 401: //UnAuthorized
                case 403: //Forbidden
                    window.location = '/'
                    break;
                default:
                    break;
            }
        });
}

function getasync(url) {
    const decoder = new JsonStreamDecoder();
    fetch(url).then(response => response.body)
        .then(rb => {
            const reader = rb.getReader();
            return new ReadableStream({
                start(controller) {
                    function push() {
                        reader.read().then(({done, value}) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            controller.enqueue(value);
                            try {
                                var decodedItem = decoder.decodeChunk(value);
                                //console.log(decodedItem);
                                return decodedItem;
                            } catch { }
                            push();
                        })
                    }
                    push();
                }
            });
        }).then(stream => {
            return new Response(stream, { headers: { "Content-Type": "application/json" } }).text(); }).then(result => {
            console.log(`DONE!`);
        });
}

const HttpClientService = {
    post,
    get,
    getasync,
    uploadDocument
}

export default HttpClientService;