import React, { useEffect, useState } from "react";
import httpclientService from "../../services/httpclient.service";
import { OmisTimeFilterBox } from "../shared/OmisInputs";
import { FilterTab } from "../reports/Reports";
import { Card } from "@mui/material";
import { OmisTimeGap, OmisTimeGapWithWords } from "../shared/OmisDisplays";
import { OmisUserChip } from "../shared/OmisChips";
import { t } from "i18next";
import { SpaceComponent } from "../shared/Shared";
import { OmisCol, OmisRow } from "../shared/OmisLayouts";

export function TabContent_ActivityHistory(props) {
    const {state} = {...props};
    const [activities, setActivities] = useState([]);
    const [checked, setChecked] = useState([]);
    const [filteredVal, setFilteredVal] = useState([]);
    const [userItems, setUserItems] = useState([]);
    const [selectedUserItems, setSelectedUserItems] = useState([]);
    const [changedStatusItems, setChangedStatusItems] = useState([]);
    const [selectedChangedStatusItems, setSelectedChangedStatusItems] = useState([]);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startStr, setStartStr] = useState("start");
    const [endStr, setEndStr] = useState("end");


    useEffect(() => {
        if (props.data && props.data.length > 0) {

            setActivities(props.data);

            httpclientService.get(`/api/inspections/getinspectionhistoryusers?id=${ state.inspectionid }`)
                .then((data) => {
                    if (data && data.length > 0) {
                        setUserItems(data.map((item, index) => item));
                    }
                });

            httpclientService.get(`/api/inspections/getinspectionhistorychangedstatus?id=${ state.inspectionid }`)
                .then((data) => {
                    if (data && data.length > 0) {
                        setChangedStatusItems(data.map((item, index) => item));
                    }
                });
        }
    }, []);

    useEffect(() => {
        setFilteredVal(activities);
    }, [activities]);

    const checkBoxChange = (selectedBox, event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, selectedBox];
        } else {
            updatedList.splice(checked.indexOf(selectedBox), 1);
        }
        setChecked(updatedList);
    }

    const handleDateChange = (dateType, startDate, endDate, callbackFunction, e) => {
        if (dateType === startStr) {
            if (endDate && endDate < e) {
                alert("Start Date can not be later than End Date");
            } else callbackFunction(e);
        } else if (dateType === endStr) {
            if (startDate && startDate > e) {
                alert("End Date can not be earlier than Start Date");
            } else callbackFunction(e);
        }
    };

    const handleTimeBoxButtonClicks = (clickType, columnField, filterID, selectedStartDate, selectedEndDate, setStartDateFunctionCallback, setEndDateFunctionCallback) => {
        if (clickType === 'applyClick') {
            var results = activities.filter(x => (selectedStartDate ? new Date(x.date) >= selectedStartDate : true) && (selectedEndDate ? new Date(x.date) <= selectedEndDate : true));
            setFilteredVal(results);
        } else if (clickType === 'resetClick') {
            setStartDateFunctionCallback("");
            setEndDateFunctionCallback("");
            setFilteredVal(activities);
        }
    };

    const handleDropDownChangeForUser = (setDropdownCallback, event) => {
        const {
            target: {value},
        } = event;

        // On autofill we get a stringified value.
        var _newArr = typeof value === 'string' ? value.split(',') : value;//gives selected values in array
        setDropdownCallback(_newArr.sort((a, b) => a - b));
        if (_newArr.length > 0) {
            var results = activities.filter(x => _newArr.indexOf(x.userID) !== -1);
            setFilteredVal(results);
        } else {
            setFilteredVal(activities);
        }
    };

    const handleDropDownChangeForChangedStatus = (setDropdownCallback, event) => {
        const {
            target: {value},
        } = event;

        // On autofill we get a stringified value.
        var _newArr = typeof value === 'string' ? value.split(',') : value;//gives selected values in array
        setDropdownCallback(_newArr.sort((a, b) => a - b));
        if (_newArr.length > 0) {
            var results = activities.filter(x => _newArr.indexOf(x.newStatus) !== -1 || _newArr.indexOf(x.oldStatus) !== -1);
            setFilteredVal(results);
        } else {
            setFilteredVal(activities);
        }
    };

    const childItemsDateTimePicker = [{
        content:
            <OmisTimeFilterBox id={ "inspectionActivity" } selectedStartDate={ startDate } setStartDate={ setStartDate } onChange={ handleDateChange }
                               selectedEndDate={ endDate } setEndDate={ setEndDate }
                               applyClicked={ handleTimeBoxButtonClicks } resetClicked={ handleTimeBoxButtonClicks }
                               columnField={ "inspectionActivity" } filterID={ "inspectionActivity" }{ ...props } />
    }];

    const datesAreOnSameDay = (first, second) => first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();

    return (<>
        <br />
        {
            activities.length>0 ?
                <FilterTab
                    getSearchResultItems={setFilteredVal} listItems={activities} listAttr={["username", "fullName", "newStatus", "oldStatus"]}
                    changedStatusItems={changedStatusItems} handleDropDownChangeForChangedStatus={handleDropDownChangeForChangedStatus} setSelectedChangedStatusItems={setSelectedChangedStatusItems} selectedChangedStatusItems={selectedChangedStatusItems}
                    userItems={userItems} handleDropDownChange={handleDropDownChangeForUser} setSelectedUserItems={setSelectedUserItems} selectedUserItems={selectedUserItems}
                    childItemsDateTimePicker={childItemsDateTimePicker} timetitle={"All time"} />
                :
                null
        }
        <br/>

        {
            filteredVal.length > 0 ? filteredVal.map((item, index) =>
                <div key={`${index}Div`}>
                    {index !== 0 && !datesAreOnSameDay(new Date(item.date), new Date(filteredVal[index - 1].date)) ?
                        <OmisRow>
                            <strong key={`${index}Strong`} className="detailsCardTitle"><OmisTimeGapWithWords key={`${index}Date`} date={item.date} />
                            </strong>
                        </OmisRow> : index === 0 ?
                            <OmisRow>
                                <strong key={`${index}Strong`} className="detailsCardTitle"><OmisTimeGapWithWords key={`${index}Date`} date={item.date} />
                                </strong>
                            </OmisRow> : null}
                    <OmisRow>
                        <Card>
                            <OmisRow>
                                <OmisCol xs={10} style={{ display: 'flex', justifyContent: 'left' }}>
                                    <OmisUserChip userFullName={item.fullName} userID={item.userID} /><SpaceComponent />{t("From")}<SpaceComponent />
                                    <strong>{item.oldStatus}</strong><SpaceComponent />{t("To")}<SpaceComponent />
                                    <strong>{item.newStatus}</strong>
                                </OmisCol>
                                <OmisCol xs={2} style={{ display: 'flex', justifyContent: 'right' }}>
                                    <OmisTimeGap date={item.date} />
                                </OmisCol>
                            </OmisRow>
                        </Card>
                    </OmisRow>
                </div>
            )
                :
                <strong className="detailsCardTitle">{t("NoResultsFound")}</strong>
        }
    </>);
}