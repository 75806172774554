import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import httpclientService from "../../services/httpclient.service";
import urlEncryptService from "../../services/urlencrypt.service";
import { Card } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { mdiDotsHorizontal, mdiSend, mdiAccountSwitch, mdiSwapHorizontal, mdiArchive } from '@mdi/js';
import { OmisButtonSecondary, OmisDropDownMenu, OmisIconButton, OmisProgress } from "../shared/OmisButtons";
import { OmisDialog, OmisLabel } from "../shared/OmisDisplays";
import { OmisCol, OmisContainer, OmisRow, OmisStack, OmisTabs } from "../shared/OmisLayouts";
import { TabContent_UserContactPersons } from "./UserDetails/TabContent_UserContactPersons";
import { TabContent_UserGroups } from "./UserDetails/TabContent_UserGroups";
import { TabContent_UserRoles } from "./UserDetails/TabContent_UserRoles";
import { TabContent_UserModules } from "./UserDetails/TabContent_UserModules";
import { TabContent_UserContractor } from "./UserDetails/TabContent_UserContractor";
import { TabContent_UserTeams } from "./UserDetails/TabContent_UserTeams";
import EditUser from "./EditUser";
import AuthService from "../../services/auth.service";
import { TRole } from "../../constants/RoleConstants";
import SetUserReplacement from "./SetUserReplacement";
import { DetailsCardWithSkeleton, SmallMap } from "../shared/Shared";
import { UserObjects, UserObjectsList } from "./UserDetails/UserObjectsList";

export function UserDetailsInfo(props) {
    const { i18n } = useTranslation();

    var { state } = useLocation();
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(null);

    const [modalWidth, setModalWidth] = useState("sm");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState("");

    var location = useLocation();
    if (!state) {

        var encryptedString = "";
        if (location?.search) {
            encryptedString = location?.search.replace("#", "").replace("?", "").replaceAll("%22", "\"");
        } else if (location?.hash) {
            encryptedString = location?.hash.replace("#", "").replace("?", "").replaceAll("%22", "\"");
        }

        if (encryptedString && encryptedString != "") {
            state = urlEncryptService.decrypt(encryptedString);
        }
    }

    useEffect(() => {
        httpclientService.get(`/api/profile/getuserinfo?userID=${state?.id}`).then((response) => {
            if (response) {
                setUserInfo(response);
            }
        });
    }, []);

    const tabs = [
        //{
        //    title: t("ObjectWizard_Step4_Header"),
        //    content: <TabContent_UserContactPersons userid={state?.id} />
        //},
        {
            title: t("Group_Title"),
            content: <TabContent_UserGroups userid={state?.id} />
        },
        {
            title: t("Settings_Roles"),
            content: <TabContent_UserRoles userid={state?.id} />
        },
        {
            title: t("Modules"),
            content: <TabContent_UserModules userid={state?.id} />
        },
        {
            title: t("Contractors"),
            content: <TabContent_UserContractor userid={state?.id} />
        },
        {
            title: t("Teams"),
            content: <TabContent_UserTeams userid={state?.id} />
        },
    ];

    const handleModalOpen = (content, title, e) => {
        if (title === t("Action_Edit")) {
            setModalWidth("xl");
        } else {
            setModalWidth("md");
        }
        setModalContent(content);
        setModalTitle(title);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setModalContent(null);
        setModalTitle(null);
        navigate(0);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
        setModalContent(null);
        setModalTitle(null);
    };

    function handleUserEdit() {
        handleModalOpen(<EditUser userID={state?.id} handleModalClose={handleModalClose} handleModalCancel={handleModalCancel} />, t("Action_Edit"), state?.id);
    }

    const sendLoginData = () => {
        handleModalOpen(<OmisProgress />, null, null);
        httpclientService.post(`api/profile/sendlogindata?userID=${state?.id}`).then((response) => {
            handleModalCancel();
            if (response) {
                navigate(0);
            }
        });
    };

    const setReplacement = () => {
        handleModalOpen(<SetUserReplacement userID={state?.id} handleModalClose={handleModalClose} handleModalCancel={handleModalCancel} />, "Set replacement", state?.id);
    };

    const archiveClicked = () => {
        httpclientService.post(`api/profile/setuserarchive?userID=${state?.id}`).then((response) => {
            if (response) {
                navigate(0);
            }
        });
    };

    const loginToAnotherUser = (rowID) => {
        httpclientService.post(`api/profile/logintoanotheruser?userID=${rowID}`).then((response) => {
            if (response) {
                localStorage.removeItem("user");
                localStorage.setItem("user", JSON.stringify(response));
                var lang = response.language;
                if (lang && localStorage.getItem("i18nLng") !== lang) {
                    i18n.changeLanguage(lang).then(() => {
                        localStorage.setItem("i18nLng", lang);
                    }).then(() => {
                        try {
                            httpclientService.post("/api/profile/changelanguage?isUserSwitch=1", lang).then((response) => {
                                AuthService.getUrlCheck();
                                window.location.reload();
                            });
                        } catch (err) {
                            AuthService.getUrlCheck();
                            window.location.reload();
                        }
                    });
                } else {
                    AuthService.getUrlCheck();
                    window.location.reload();
                }
            }
        }).catch((err) => {
            console.error(err.response.data.errormessage);
            window.location.reload();
        });
    };
    var dropDownMenuItems = [
        {
            onClick: sendLoginData,
            subMenuIcon: mdiSend,
            menuName: t("Settings_SendUserLogins"),
        },
        {
            onClick: setReplacement,
            subMenuIcon: mdiAccountSwitch,
            menuName: t("Replacement_Set"),
        },
        {
            onClick: archiveClicked,
            subMenuIcon: mdiArchive,
            menuName: t("Action_Archive"),
        }
    ];

    if (AuthService.hasRole(TRole.FSMWorkflowUser)) {
        dropDownMenuItems.splice(3, 0, {
            onClick: loginToAnotherUser,
            subMenuIcon: mdiSwapHorizontal,
            menuName: t("Account_Login_Title"),
        });
    }

    return (
        <>
            <div className="omis-header-box">
                <OmisContainer fluid>
                    <OmisRow style={{ display: 'flex', alignItems: 'center' }}>
                        <OmisCol xs={1}>
                            <OmisIconButton id={"backButton"}
                                iconcontent={<ArrowBackIcon fontSize="inherit" />}
                                text="Back" onClick={() => { navigate(-1); }} />
                        </OmisCol>
                        <OmisCol xs={3}>
                            <div className="omis-header-title-details">{t("User")} #{state?.id}</div>
                        </OmisCol>
                    </OmisRow>
                </OmisContainer>
            </div>
            <Card>
                <OmisContainer fluid>
                    <OmisStack>
                        <OmisRow>
                            <OmisCol xs={9}>
                                <OmisRow>
                                    <OmisCol xs={12}>
                                        <strong>{t("UserName")}</strong>
                                        <OmisLabel label={userInfo?.username} />
                                    </OmisCol>
                                    <OmisCol xs={12}>
                                        <strong>{t("Language")}</strong>
                                        <OmisLabel label={userInfo?.language ? t(`Language_${userInfo?.language?.replace("-", "_")}`) : ""} />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <strong>{t("FirstName")}</strong>
                                        <OmisLabel label={userInfo?.firstName} />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <strong>{t("Address_LastName1")}</strong>
                                        <OmisLabel label={userInfo?.lastName} />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <strong>{t("Address_Industry")}</strong>
                                        <OmisLabel label={userInfo?.addressIndustry} />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <strong>{t("Street")}</strong>
                                        <OmisLabel label={userInfo?.addressStreet} />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <strong>{t("StreetNr")}</strong>
                                        <OmisLabel label={userInfo?.addressStreetNr} />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <strong>{t("ZipCode")}</strong>
                                        <OmisLabel label={userInfo?.addressZipCode} />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <strong>{t("City")}</strong>
                                        <OmisLabel label={userInfo?.addressCity} />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <strong>{t("Address_FederalState")}</strong>
                                        <OmisLabel label={userInfo?.addressFederalState} />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <strong>{t("Address_State")}</strong>
                                        <OmisLabel label={userInfo?.addressState} />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <strong>{t("Phone")}</strong>
                                        <OmisLabel label={userInfo?.phone} />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <strong>{t("Address_Mobile")}</strong>
                                        <OmisLabel label={userInfo?.mobile} />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <strong>{t("Email")}</strong>
                                        <OmisLabel label={userInfo?.email} />
                                    </OmisCol>
                                    {/*<OmisCol xs={3}>*/}
                                    {/*    <strong>{t("Fax")}</strong>*/}
                                    {/*    <OmisLabel label={userInfo?.fax} />*/}
                                    {/*</OmisCol>*/}
                                </OmisRow>
                            </OmisCol>
                            <OmisCol xs={3}>
                                <OmisRow>
                                    <OmisCol xs={10}>
                                        <OmisButtonSecondary id={"editButton"} onClick={handleUserEdit} text={t("Action_Edit")} />
                                    </OmisCol>
                                    <OmisCol xs={1}>
                                        <OmisDropDownMenu id={"dropdownButton"} menuicon={mdiDotsHorizontal} childitems={dropDownMenuItems} />
                                    </OmisCol>
                                </OmisRow>
                                <br/>
                                <OmisRow>
                                    <OmisCol xs={12}>
                                        <SmallMap latitude={userInfo?.latitude} longitude={userInfo?.longitude} />
                                    </OmisCol>
                                </OmisRow>
                            </OmisCol>
                        </OmisRow>
                        <br/>
                        <OmisRow>
                            <Card>

                                <DetailsCardWithSkeleton
                                    url={`/api/profile/getuserobjectcontactpersons?userID=${state?.id}`}
                                    skeletonheight={"100px"}
                                    content={
                                        <UserObjects listLimit={5} {...props} />
                                    }
                                    {...props} />
                            </Card>
                        </OmisRow>
                    </OmisStack>
                </OmisContainer>

                <OmisRow>
                    <OmisTabs tabs={tabs} />
                </OmisRow>
            </Card>

            <OmisDialog onClose={handleModalCancel} open={modalOpen} maxWidth={modalWidth} title={modalTitle} content={modalContent} />
        </>
    );
}