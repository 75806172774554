import React, { useState } from "react";
import { t } from "i18next";
import { Link as RouterLink } from "react-router-dom";
import httpclientService from "../../../services/httpclient.service";
import { OmisButtonNewItem } from "../../shared/OmisButtons";
import { OmisDialog } from "../../shared/OmisDisplays";
import { OmisGrid } from "../../shared/OmisGrids";
import { CreateEquiType } from "./CreateEquiType";

export function SettingsEquiTypes(props) {

    var url = "/api/settings/settingsequipments/getequitypes";
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    if (props.equiclassid) {
        url = `${url}?equiclassid=${props.equiclassid}`;
    }

    function handleEditClick(rowID, e) {
        if (e) {
            setModalTitle(t("Action_Edit"));
        } else {
            setModalTitle(null);
            if (typeof rowID !== 'number') {
                rowID = null
            }
        }
        var rowVals = { ...e };
        if (props.equiclassid && props.equiclassid>0 ) {
            rowVals.equiClassID = props.equiclassid;
        }
        setModalContent(<CreateEquiType onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID} rowVals={rowVals} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    const handleDelete = (rowID) => {
        httpclientService.post(`/api/settings/settingsequipments/deleteequitype?id=${rowID}`).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    }

    return (
        <div>

            <OmisGrid actiondropdown={{ edit: handleEditClick, delete: handleDelete }} headerText={t("EquiTypes")}
                newButton={<OmisButtonNewItem id={"newItemButton"} onClick={handleEditClick} text={t("Action_New")}  state={props} />}
                apiUrl={url}
                change={changeCounter}
                noExportButton noHeaderText
                {...props} />

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"lg"}
                title={modalTitle}
                content={modalContent}
                contentHeight={550}
            />
        </div>
    );
}