import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {OmisGrid} from "../../shared/OmisGrids";
import {OmisButtonNewItem, OmisIconButton, OmisButtonPrimarySubmit, OmisButtonSecondary} from "../../shared/OmisButtons";
import {OmisDeleteQuestionDialog, OmisDialog} from "../../shared/OmisDisplays";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {OmisTextArea} from "../../shared/OmisInputs";
import DialogActions from "@mui/material/DialogActions";
import httpclientService from "../../../services/httpclient.service";
import { Link as RouterLink } from "react-router-dom";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisCol, OmisRow } from "../../shared/OmisLayouts";

var state = { selectedtabvalue: TabConstants.Templates }
export default function TradeLevels(props) {
    const { t } = useTranslation();

    const [changeCounter, setChangeCounter] = useState(0);

    const [newModalOpen, setNewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [selectedRowID, setSelectedRowID] = useState(0);

    const handleModalOpen = () => {
        setNewModalOpen(true);
    };

    const handleModalClose = () => {
        setNewModalOpen(false);
        setEditModalOpen(false);
        setDeleteModalOpen(false);
        setSelectedRowID(0);
    };

    function handleEditClick(item) {
        setSelectedRowID(item);
        setEditModalOpen(true);
    }

    function handleDeleteClick(item) {
        setSelectedRowID(item);
        setDeleteModalOpen(true);
    }
    
    return (
        <div className="omis-header-box">

            <OmisCol xs={1}>
                <OmisIconButton id={"backButton"}
                    iconcontent={<ArrowBackIcon fontSize="inherit" />}
                    text="Back" component={RouterLink} to={"/settings"} state={state} />
            </OmisCol>

            <OmisGrid actiondropdown={{ edit: handleEditClick, delete: handleDeleteClick }}
                      change={changeCounter}
                      newButton={<OmisButtonNewItem id={"newItemButton"} onClick={handleModalOpen} text={t("Action_New")} />}
                      headerText={t("TradeLevel")}
                      apiUrl={"/api/settings/tradelevels/gettradelevelslist"}
            />
            <OmisDialog onClose={handleModalClose} open={newModalOpen}
                        title={t("Action_Add")}
                        content={<TradeLevelPopup_Content />}
            />
            <OmisDialog onClose={handleModalClose} open={editModalOpen}
                        title={t("Action_Edit")}
                        content={<TradeLevelPopup_Content />}
            />
            <OmisDeleteQuestionDialog open={deleteModalOpen}
                                      handleModalClose={handleModalClose}
                                      deleteFunction={handleDeleteItem}
            />
        </div>
    );

    function handleDeleteItem() {
        httpclientService.post(`/api/settings/tradelevels/delete?id=${selectedRowID}`).then((response) => {
            setChangeCounter((prevState) => prevState + 1);
            handleModalClose();
        })
    }

    function TradeLevelPopup_Content(props) {
        const [initialValues, setInitialValues] = useState({
            id: 0,
            TradeLevelDescription: '',
        });

        const editItem = Boolean(selectedRowID > 0);
        useEffect(() => {
            if (editItem) {
                httpclientService.post(`/api/settings/tradelevels/get?id=${selectedRowID}`).then((response) => {
                    setInitialValues(response);
                })
            }
        }, [])

        function handleSave(values) {

            if (values.TradeLevelDescription === '') {
                values.TradeLevelDescription = null;
            }

            httpclientService.post("/api/settings/tradelevels/savetradelevel", JSON.stringify(values)).then((response) => {
                switch (response.statusCode) {
                    case 200:
                        handleModalClose();
                        setChangeCounter((prevState) => prevState + 1);
                        break;
                    default:
                        break;
                }
            })
        }

        return(
            <>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        TradeLevelDescription: Yup.string()
                            .required(t("GenericRequiredText"))
                            .max(100, t('Error_TextTooLong', { 1: 100 }))
                    })}
                    onSubmit={(values) => { handleSave(values) }}
                >
                    <Form>
                        <OmisRow>
                            <OmisCol xs="12">
                                <OmisTextArea labeltext={t("Description")} name="TradeLevelDescription" placeholder={t("TradeLevel") + " " + t("Description")} required />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <DialogActions>
                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")}></OmisButtonSecondary>
                                <OmisButtonPrimarySubmit id={"submitButton"} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                            </DialogActions>
                        </OmisRow>
                    </Form>
                </Formik>
            </>
        );
    }
}