import Documents from "../documents/Documents";
import { DMSDocumentReferer } from "../../constants/DMSDocumentReferer";
import React from "react";

export function TabContent_Documents(props) {
    const {state} = {...props};

    return (
        <>
        <br></br>
            <Documents id={state?.reportid} referer={DMSDocumentReferer.FaultReport} documentTab={true} {...props} />

        </>
            );
}