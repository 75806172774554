import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { OmisButtonSecondary, OmisIconButton } from "../../shared/OmisButtons";
import { Card } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { OmisCol, OmisContainer, OmisRow, OmisTabs } from "../../shared/OmisLayouts";
import { OmisDialog, OmisLabel } from "../../shared/OmisDisplays";
import HttpClientService from "../../../services/httpclient.service";
import { SettingsCompanySites } from "./SettingsCompanySites";
import { AllUserList } from "../../users/UsersList";
import { CreateContractor } from "./CreateContractor";

export function SettingsContractorDetails(props) { 

    var { state } = useLocation();
    const navigate = useNavigate();

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [selectedTabValue, setSelectedTabValue] = useState(0);
    const [details, setDetails] = useState(null);

    const tabs = [
        {
            title: t("User"),
            content: <AllUserList contractorID={state?.id} selectedtabvalue={selectedTabValue} showCheckBox={false} noExportButton openInPopup />
        },
        {
            title: t("CompanySites"),
            content: <SettingsCompanySites contractorID={state?.id} selectedtabvalue={selectedTabValue} hideBack />
        },
    ];

    useEffect(() => {
        if (state && state.selectedtabvalue) {
            if (/[0-9]/.test(state.selectedtabvalue)) {
                setSelectedTabValue(parseInt(state.selectedtabvalue));
            } else {
                setSelectedTabValue(0);
            }
        }

        if (state?.id) {
            HttpClientService.get(`/api/settings/companysite/getcontractordetails?contractorID=${state?.id}`).then((response) => {
                if (response) {
                    setDetails(response);
                }
            });
        }

    }, []);

    function tabChanged(e) {
        setSelectedTabValue(e);
    }

    function handleEditClick(rowID, e) {
        setModalTitle(t("Action_Edit"));
        setModalContent(<CreateContractor onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID} rowVals={e} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = (newEquiClassVals) => {
        setModalOpen(false);
        var newState = { ...state };
        if (newEquiClassVals) {
            newState.description = newEquiClassVals.description;
            newState.equiClass = newEquiClassVals.equiClass;
        }

        navigate(0, { replace: true, state: { ...newState } });
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };


    return (
        <>
            <div className="omis-header-box">
                <OmisCol xs={1}>
                    <OmisIconButton id={"backButton"}
                        iconcontent={<ArrowBackIcon fontSize="inherit" />}
                        text="Back" onClick={() => { navigate(-1); }} />
                </OmisCol>
            </div>
            <Card>
                <OmisContainer fluid>
                    <OmisRow>
                        <OmisCol xs={6}>
                            <OmisRow>
                                <OmisCol xs={5}>
                                    <strong>{t("Description")}:</strong>
                                </OmisCol>
                                <OmisCol xs={7}>
                                    <OmisLabel label={details?.description} />
                                </OmisCol>

                                <OmisCol xs={5}>
                                    <strong>{t("Contractor_SupplierNumber")}:</strong>
                                </OmisCol>
                                <OmisCol xs={7}>
                                    <OmisLabel label={details?.supplierNumber} />
                                </OmisCol>

                                <OmisCol xs={5}>
                                    <strong>{t("Contractor_AdditionalDescription")}:</strong>
                                </OmisCol>
                                <OmisCol xs={7}>
                                    <OmisLabel label={details?.additionalDescription} />
                                </OmisCol>
                            </OmisRow>
                        </OmisCol>
                        <OmisCol xs={{ span: 2, offset: 4 }}>
                            <OmisButtonSecondary id={"editButton"} onClick={handleEditClick.bind(this, state?.id, state)} text={t("Action_Edit")} />
                        </OmisCol>
                    </OmisRow>
                </OmisContainer>

                <OmisRow>
                    <OmisTabs tabs={tabs} onChange={tabChanged} tabvalue={selectedTabValue} />
                </OmisRow>
            </Card>

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"md"}
                title={modalTitle}
                content={modalContent}
                contentHeight={300}
            />
        </>
    );
}