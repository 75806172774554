import React, { useEffect, useRef, useState } from "react";
import httpclientService from "../../../services/httpclient.service";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Chip } from "@mui/material";
import { OmisLabel } from "../../shared/OmisDisplays";
import { OmisAutosizeTextArea, OmisDatePicker } from "../../shared/OmisInputs";
import { OmisButtonPrimarySubmit, OmisButtonSecondary, OmisSecButtonWithStartIcon } from "../../shared/OmisButtons";
import { mdiPlusBox } from "@mdi/js";
import { OmisUserChip } from "../../shared/OmisChips";
import { CostsList } from "./CostsList";
import { OmisDropdownMultiselectV2 } from "../../shared/OmisDropdowns";
import { generateUniqueRandom } from "../../shared/Shared";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function AddSupplierRequest(props) {

    const formRef = useRef();
    const navigate = useNavigate();

    const [suppliers, setSuppliers] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [costTypes, setCostTypes] = useState([]);

    const [initialValues, setInitialValues] = useState({
        id: props.rowVals?.id ?? null,
        faultReportID: props.rowVals?.faultReportID ?? props.faultReportID ?? null,
        requestDescription: props.rowVals?.requestDescription ?? "",
        offerDeadline: null,
        costList: [],
        companySiteIDs: []
    });

    const [costList, setCostList] = useState([]);

    const DefaultCost = [{
        key: generateUniqueRandom(costList.map(x => x.key)),
        costType: costTypes.length === 1 ? costTypes[0].key : null,
        costValue: 0,
        currencyType: currencies.length === 1 ? currencies[0].key : ""
    }];

    useEffect(() => {
        if (props && props.reportid) {
            httpclientService.get(`/api/reports/getcompanysitesfordropdown?reportid=${ props.reportid }`)
                .then((data) => {
                    if (data && data.length > 0) {
                        setSuppliers(data);
                    }
                });
        }

        httpclientService.get(`/api/reports/getcurrencies`)
            .then((data) => {
                if (data && data.length > 0) {
                    setCurrencies(data);
                }
            });

        httpclientService.get(`/api/reports/getcosttypes`)
            .then((data) => {
                if (data && data.length > 0) {
                    setCostTypes(data);
                }
            });

    }, []);

    useEffect(() => {
        if (costList.length === 0 && currencies.length > 0) {
            setCostList(DefaultCost);
        }
    }, [currencies, costTypes]);

    const handleSelectedListItems = (e) => {
        var values = e.target.value; 
        formRef.current.setFieldValue("companySiteIDs", values);
        var newInitValues = { ...formRef.current.values };
        newInitValues.companySiteIDs = values;
        setInitialValues(newInitValues);
    }

    const handleAddCost = () => {
        setCostList([...costList, ...DefaultCost]);
    }

    const handleRemoveLastCost = (key) => {
        if (key && costList.length > 0) {
            var newCosts = costList.filter((f) => f.key !== key);
            setCostList(newCosts);
        }
    }

    const costValueChanged = (event) => {
        var newCostList = [...costList];
        var currentCost = newCostList.filter(f => f.key === parseInt(event.target.id ))[0];
        var columnName = event.target.name;
        var newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        currentCost[columnName] = newValue;
        setCostList(newCostList);
    };

    useEffect(() => {
        formRef.current.setFieldValue("costList", costList);
        formRef.current.validateForm();
    }, [costList]);

    function handleSave(values) {
        httpclientService.post("/api/reports/savesupplierrequest", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(-1);
                }
            }
        })
    }

    const [schema, setSchema] = useState({
        requestDescription: Yup.string()
            .required(t("GenericRequiredText"))
            .max(2048, t('Error_TextTooLong', { 1: 2048 })),
        faultReportID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1, t('GenericRequiredText')),
        companySiteIDs:Yup.array()
            .required(t("GenericRequiredText"))
            .min(1, t('GenericRequiredText')),
    });

    const validationSchema = Yup.object(schema);

    return (
        <Formik
            innerRef={formRef}
            validateOnMount
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => { handleSave(values) }}
        >
            {formik => (
                <Form>
                    <OmisContainer fluid>
                        <OmisRow>
                            <OmisCol xs={6}>
                                <OmisCol xs={12}>
                                    <OmisLabel label={t("CompanySites")} required />
                                    <OmisDropdownMultiselectV2 id="suppliersDrpDown" className="omisSearchDropDown" items={suppliers} onChange={handleSelectedListItems} value={formik.values.supplierIDs} labeltext={t("CompanySites")} />
                                </OmisCol>

                                <OmisCol xs={12}>
                                    <br />
                                    <OmisAutosizeTextArea labeltext={"Text"} name="requestDescription" required />
                                </OmisCol>
                            </OmisCol>
                            <OmisCol xs={6}>
                                <OmisCol xs={12}>
                                    <OmisLabel label={t("OfferDeadline")} />
                                    <OmisDatePicker name="offerDeadline" /*minDate={new Date()} */ />
                                </OmisCol>
                                <CostsList currencies={currencies} costTypes={costTypes} costlist={costList} handleRemoveLastCost={handleRemoveLastCost} costValueChangedCallback={costValueChanged} />
                                {
                                    costTypes.length > 0 ?
                                        <OmisCol xs={12}>
                                            <OmisSecButtonWithStartIcon id={"addButton"} text={t("Action_Add")} iconpath={mdiPlusBox} onClick={handleAddCost} />
                                        </OmisCol>
                                        :
                                        null
                                }
                            </OmisCol>
                        </OmisRow>
                        

                        <br />
                        <OmisRow>
                            <OmisCol xs={6}>
                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={props.handleCancel}></OmisButtonSecondary>
                            </OmisCol>
                            <OmisCol xs={6}>
                                <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")} />
                            </OmisCol>
                        </OmisRow>
                    </OmisContainer>
                </Form>
            )}
        </Formik>
    );
}

export function PrepareSupplierListItem(props) {

    return (
        <OmisRow key={ `${ props.item.companyName }_GridRow` } container direction="row" justifyContent="flex-start" alignItems="center" spacing={ 1 }>
            <OmisRow>
                <OmisCol xs={ 3 }>
                    <span style={ {overflowWrap: 'anywhere'} }>{ props.item.email }</span>
                </OmisCol> { props.item.isDefaultSupp ?
                <Chip label={ "Default supplier" } variant="outlined"/> : null }
                <OmisCol xs={ 4 }>
                    <OmisUserChip userFullName={ props.item.companyName } userID={ props.item.companyName }/>
                </OmisCol>
            </OmisRow>
            <OmisCol xs={ 12 }>
                { props.item.skills && props.item.skills.length > 0 ? props.item.skills.map((skill) =>
                    <Chip key={ `${ skill }_Chip` } label={ skill } variant="outlined"/>) : null }
            </OmisCol>
        </OmisRow>);
}