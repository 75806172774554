import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../services/httpclient.service";
import { useFormikContext, Formik, Form } from 'formik';
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";
import { OmisButtonPrimarySubmit, OmisButtonSecondary, OmisProgress } from "../shared/OmisButtons";
import { OmisDialog, OmisLabel } from "../shared/OmisDisplays";
import { OmisDropdown } from "../shared/OmisDropdowns";

export function EquipmentBulkMove(props) {    

    const formRef = useRef();
    const [objectsList, setObjectsList] = useState([]);
    const [equiClassList, setEquiClassList] = useState([]);
    const [equiList, setEquiList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    const [selectedObjectID, setSelectedObjectID] = useState(null);
    const [selectedEquiClassID, setSelectedEquiClassID] = useState(null);

    const [initialValues, setInitialValues] = useState({
        equipmentIDs: props.ids,
        objectID: null,
        equiClassID: null,
        parentEquipmentID: null,
    });

    useEffect(() => {

        httpclientService.get(`/api/objects/getobjectlistfordropdown`).then((response) => {
            if (response) {
                setObjectsList(response.map((item) => ({
                    key: item.key,
                    displayText: item.label
                })));
            }
        });

        httpclientService.get("/api/equipment/equiclass/getequiclassesfordropdown").then((response) => {
            if (response && response.length > 0) {
                setEquiClassList(response);
            }
        });

    }, []);

    const AutoSubmitToken = () => {
        // Grab values and submitForm from context
        const { values } = useFormikContext();
        var fieldChanged = false;
        useEffect(() => {

            if (values.objectID !== selectedObjectID) {
                setSelectedObjectID(values.objectID);
                fieldChanged = true;
            }

            if (values.equiClassID !== selectedEquiClassID) {
                setSelectedEquiClassID(values.equiClassID);
                fieldChanged = true;
            }

            if (fieldChanged) {
                if (values.equiClassID && values.objectID) {
                    httpclientService.get(`/api/equipments/getpossibleparentequilist?objectID=${values.objectID}&equiClassID=${values.equiClassID}`).then((response) => {
                        if (response) {
                            setEquiList(response);
                        }
                    });
                }
            }

        }, [values]);
        return null;
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    function handleSave(values) {
        handleModalOpen();
        httpclientService.post("/api/equipments/moveequipments", values).then((response) => {
            handleModalClose();
            if (response.statusCode === 200) {
                if (props.handleModalClose) {
                    props.handleModalClose();
                }
            } else {
                formRef.current.setFieldError(response.data.errorField, response.data.message);
            }
        });
    }

    const [schema, setSchema] = useState({
        objectID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1, t("GenericRequiredText")),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={2}>
                                    <OmisLabel label={t("MoveToObject")} />
                                </OmisCol>
                                <OmisCol xs={5}>
                                    <OmisDropdown size={"small"} id={"objectID"} name={"objectID"} items={objectsList} labeltext={t("Object")} required />
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={2}>
                                    <OmisLabel label={t("MoveToEqui")} />
                                </OmisCol>
                                <OmisCol xs={5}>
                                    <OmisDropdown labeltext={t("EquiClass")} name={"equiClassID"} size={"small"} items={equiClassList} disabled={!formik.values.objectID || formik.values.objectID === '0'} />
                                </OmisCol>
                                <OmisCol xs={5}>
                                    <OmisDropdown labeltext={t("Equipments")} size={"small"} id={"parentEquipmentID"} name={"parentEquipmentID"} items={equiList} disabled={!formik.values.objectID || formik.values.objectID === '0' || !formik.values.equiClassID || formik.values.equiClassID === '0'} />
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={props.handleModalCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                        <AutoSubmitToken />
                    </Form>
                )}
            </Formik>
            <OmisDialog open={modalOpen}
                maxWidth={"md"}
                title={""}
                content={<OmisProgress />}
            />
        </>
    );
}