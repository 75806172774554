import React, { useState } from "react";
import { t } from "i18next";
import { Link as RouterLink } from "react-router-dom";
import httpclientService from "../../../services/httpclient.service";
import { OmisButtonNewItem } from "../../shared/OmisButtons";
import { OmisDialog } from "../../shared/OmisDisplays";
import { OmisGrid } from "../../shared/OmisGrids";
import { CreateObjectAttributeDef } from "./CreateObjectAttributeDef";

export function SettingsObjectAttributeDefsTab(props) {
    var url = "/api/settings/settingsobjects/getobjectattributedefs";

    if (props.objectid) {
        url = `${url}?objectid=${props.objectid}`;
    }

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);
    const [rowsCount, setRowsCount] = useState(0);

    function handleEditClick(rowID, e) {
        if (e) {
            setModalTitle(t("Action_Edit"));
        } else {
            setModalTitle(null);
            if (typeof rowID !== 'number') {
                rowID = null
            }
        }
        setModalContent(<CreateObjectAttributeDef onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID} rowVals={e} objectid={props.objectid} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    const handleDelete = (rowID) => {
        httpclientService.post(`/api/settings/settingsobjects/deleteobjectattributedef?id=${rowID}`).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    }

    return (
        <>
            <OmisGrid actiondropdown={{ edit: handleEditClick, delete: handleDelete }} headerText={t("ObjectAttributeDef")}
                newButton={rowsCount < 40 ? <OmisButtonNewItem id={"newItemButton"} onClick={handleEditClick.bind(this, null, null)} text={t("Action_New")} /> : null}
                apiUrl={url}
                change={changeCounter}
                noExportButton
                getrowcount={setRowsCount}
                {...props} />

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"md"}
                title={modalTitle}
                content={modalContent}
                contentHeight={400}
            />
        </>
    );
}