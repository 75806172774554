import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { Card } from "@mui/material";
import { OmisCheckBox, OmisTextBox } from "../../shared/OmisInputs";
import AuthService from "../../../services/auth.service";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateAddress(props) {

    const navigate = useNavigate();
    const formRef = useRef();
    const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    const latitudeRegex = /^-?((90(\.0{1,14})?)|([1-8]?\d(\.\d{1,14})?))$/;
    const longitudeRegex = /^-?((180(\.0{1,14})?)|((1[0-7]\d{0,1}|[0-9]?\d{1})(\.\d{1,14})?))$/;

    const [formOfAddress, setFormOfAddress] = useState([]);
    const [initialValues, setInitialValues] = useState({
        id: props.id ?? null,
        firstName: props.rowVals?.firstName ?? '',
        lastName1: props.rowVals?.lastName1 ?? '',
        street: props.rowVals?.street ?? '',
        streetNr: props.rowVals?.streetNr ?? '',
        zipCode: props.rowVals?.zipCode ?? '',
        city: props.rowVals?.city ?? '',
        federalState: props.rowVals?.federalState ?? '',
        state: props.rowVals?.state ?? '',
        phone: props.rowVals?.phone ?? '',
        mobile: props.rowVals?.mobile ?? '',
        email: props.rowVals?.email ?? '',
        fax: props.rowVals?.fax ?? '',
        longitude: props.rowVals?.longitude ?? '',
        latitude: props.rowVals?.latitude ?? '',
        formOfAddressID: props.rowVals?.formOfAddressID ?? null,
        customerEditable: props.rowVals?.customerEditable ?? true,
    });

    useEffect(() => {
        httpclientService.get("/api/settings/miscellaneous/getformofaddress").then((response) => {
            if (response && response.length > 0) {
                setFormOfAddress(response);
            }
        });

        if (props.id) {
            const keys = Object.keys(initialValues);
            keys.forEach(key => {
                if (initialValues[key]) {
                    formRef.current.setFieldTouched(`${key}`, true, true);
                }
            });
        }

    },[]);

    function handleSave(values) {

        const keys = Object.keys(values);
        keys.forEach(key => {
            if (values[key] === '') {
                values[key] = null;
            }
        });

        httpclientService.post("/api/settings/miscellaneous/saveaddress", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose(response);
                } else {
                    navigate("/settings/miscellaneous/addresses");
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate("/settings/miscellaneous/addresses");
        }
    }

    const [schema, setSchema] = useState({
        email: Yup.string().email(t("OnlineImport_InvalidFormat"))
            .required(t("GenericRequiredText"))
            .max(255, t('Error_TextTooLong', { 1: 255 })),
        phone: Yup.string()
            .nullable()
            .max(20, t('Error_TextTooLong', { 1: 20 }))
            .matches(phoneRegExp, t("PhoneNumberFormatInvalid")),
        mobile: Yup.string()
            .nullable()
            .max(20, t('Error_TextTooLong', { 1: 20 }))
            .matches(phoneRegExp, t("PhoneNumberFormatInvalid")),
        longitude: Yup.string()
            .nullable()
            .matches(longitudeRegex, t("OnlineImport_InvalidFormat")),
        latitude: Yup.string()
            .nullable()
            .matches(latitudeRegex, t("OnlineImport_InvalidFormat")),
        lastName1: Yup.string()
            .required(t("GenericRequiredText"))
            .max(60, t('Error_TextTooLong', { 1: 60 })),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("AddressWizard_Step1_Header")}</h1>
                                :
                                null
                        }

                        <OmisContainer fluid>

                            <OmisRow>
                                <Card variant={"outlined"}>
                                    <OmisRow>
                                        <OmisCol xs={4}>
                                            <OmisTextBox labeltext={t("FirstName")} name={"firstName"} placeholder={t("FirstName")} />
                                        </OmisCol>
                                        <OmisCol xs={4}>
                                            <OmisTextBox labeltext={t("Address_LastName1")} name={"lastName1"} placeholder={t("Address_LastName1")} required />
                                        </OmisCol>
                                        <OmisCol xs={4}>
                                            <OmisTextBox labeltext={t("Street")} name={"street"} placeholder={t("Street")} />
                                        </OmisCol>
                                        <OmisCol xs={4}>
                                            <OmisTextBox labeltext={t("StreetNr")} name={"streetNr"} placeholder={t("StreetNr")} />
                                        </OmisCol>
                                        <OmisCol xs={4}>
                                            <OmisTextBox labeltext={t("ZipCode")} name={"zipCode"} placeholder={t("ZipCode")} />
                                        </OmisCol>
                                        <OmisCol xs={4}>
                                            <OmisTextBox labeltext={t("City")} name={"city"} placeholder={t("City")} />
                                        </OmisCol>
                                        <OmisCol xs={4}>
                                            <OmisTextBox labeltext={t("Address_FederalState")} name={"federalState"} placeholder={t("Address_FederalState")} />
                                        </OmisCol>
                                        <OmisCol xs={4}>
                                            <OmisTextBox labeltext={t("Address_State")} name={"state"} placeholder={t("Address_State")} />
                                        </OmisCol>

                                        <OmisCol xs={4}>
                                            <OmisTextBox labeltext={t("Phone")} name={"phone"} placeholder={t("Phone")} />
                                        </OmisCol>
                                        <OmisCol xs={4}>
                                            <OmisTextBox labeltext={t("Address_Mobile")} name={"mobile"} placeholder={t("Address_Mobile")} />
                                        </OmisCol>
                                        <OmisCol xs={4}>
                                            <OmisTextBox labeltext={t("Email")} name={"email"} placeholder={t("Email")} required />
                                        </OmisCol>
                                        {/*<OmisCol xs={4}>*/}
                                        {/*    <OmisTextBox labeltext={t("Fax")} name={"fax"} placeholder={t("Fax")} />*/}
                                        {/*</OmisCol>*/}
                                        <OmisCol xs={4}>
                                            <OmisTextBox labeltext={t("Address_Longitude")} name={"longitude"} placeholder={t("Address_Longitude")} type="number" />
                                        </OmisCol>
                                        <OmisCol xs={4}>
                                            <OmisTextBox labeltext={t("Address_Latitude")} name={"latitude"} placeholder={t("Address_Latitude")} type="number"/>
                                        </OmisCol>
                                        <OmisCol xs={4}>
                                            <span>{t("FormOfAddress")}</span>
                                            <OmisDropdown size={"small"} id={"formOfAddresses"} name={"formOfAddressID"} items={formOfAddress} />
                                        </OmisCol>
                                    </OmisRow>
                                    {
                                        AuthService.isFSMMaster() ?
                                            <OmisRow>
                                                <OmisCol xs={4}>
                                                    <OmisCheckBox labeltext={t("CustomerEditable")} name="customerEditable" />
                                                </OmisCol>
                                            </OmisRow>
                                            :
                                            null
                                    }
                                    <OmisRow>
                                        <OmisCol xs={6}>
                                            <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                        </OmisCol>
                                        <OmisCol xs={6}>
                                            <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                        </OmisCol>
                                    </OmisRow>
                                </Card>
                            </OmisRow>                            
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}