import React, { useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { Card, IconButton } from "@mui/material";
import Icon from "@mdi/react";
import { mdiTrashCan } from "@mdi/js";
import { OmisCol, OmisRow } from "../../shared/OmisLayouts";
import { OmisAutosizeTextArea, OmisDateTimePicker, OmisTextBox } from "../../shared/OmisInputs";
import { OmisLabel } from "../../shared/OmisDisplays";
import { DocumentUploadForNewReport } from "../../documents/Documents";
import { DMSDocumentReferer } from "../../../constants/DMSDocumentReferer";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { TenderMessage } from "../../../constants/TenderMessageConstants";

export function TenderActions(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const noteTypes = [
        {
            key: true,
            displayText: t("NoteTypeEnum_Internal")
        },
        {
            key: false,
            displayText: t("NoteTypeEnum_External")
        }
    ];

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [initialValues, setInitialValues] = useState({
        tenderID: props.rowVals?.id ?? null,
        faultReportID: props.rowVals?.faultReportID ?? null,
        caption: props.rowVals?.caption ?? "",
        internalNote: props.rowVals?.internalNote ?? false,
        note: props.rowVals?.note ?? "",
        offerDeadline: props.rowVals?.offerDeadline ?? null,
        dateToCompleteActionCustom: props.rowVals?.dateToCompleteActionCustom ?? null,
        attachedFiles: [],
        selectedMessage: props.selectedMessage
    });

    function handleSave(values) {
        httpclientService.post("/api/reports/savetenderaction", values).then((response) => {
            if (response) {
                if (props.handleModalClose) {
                    props.handleModalClose();
                } else {
                    navigate(0);
                }
            }
        });
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(0);
        }
    }

    function handleUploadedFiles(file) {
        var newUploadedFiles = [...uploadedFiles, ...file];
        setUploadedFiles(newUploadedFiles);
        formRef.current.setFieldValue("attachedFiles", newUploadedFiles);
    }

    function deleteFileButtonClicked(file) {
        var filteredSelectedFiles = uploadedFiles.filter(s => s.name !== file);
        setUploadedFiles(filteredSelectedFiles);
        formRef.current.setFieldValue("attachedFiles", filteredSelectedFiles);
    }

    const [schema, setSchema] = useState({
        caption: Yup.string()
            .required(t("GenericRequiredText"))
            .max(64, t('Error_TextTooLong', { 1: 64 })),
        note: Yup.string()
            .required(t("GenericRequiredText"))
            .max(2048, t('Error_TextTooLong', { 1: 2048 })),
        internalNote: Yup.string()
            .required(t("GenericRequiredText"))
            .test('is boolean',
                t("GenericRequiredText"),
                (value) => {
                    if (value === '0') {
                        return false;
                    } else {
                        return value;
                    }
                })
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        <OmisCol>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisCol xs={6}>
                                        <OmisTextBox labeltext={t("Title")} name="caption" required />
                                    </OmisCol>
                                    <OmisCol xs={6}>
                                        <OmisLabel label={t("NoteType")} required />
                                        <OmisDropdown size={"small"} id={"InternalNote"} name={"internalNote"} items={noteTypes} />
                                    </OmisCol>
                                    <OmisCol xs={12}>
                                        <OmisAutosizeTextArea labeltext={t("Text")} name="note" required />
                                    </OmisCol>
                                    {
                                        props.selectedMessage === TenderMessage.START_BARGAINING ?
                                            <OmisCol xs={6}>
                                                <OmisCol xs={12}>
                                                    <OmisLabel label={t("OfferDeadline")} />
                                                    <OmisDateTimePicker name="offerDeadline" />
                                                </OmisCol>
                                            </OmisCol>
                                            :
                                            null
                                    }
                                    {
                                        props.selectedMessage === TenderMessage.SELECT_OFFER ?
                                            <OmisCol xs={6}>
                                                <OmisCol xs={12}>
                                                    <OmisLabel label={t("FaultReport_DateToCompleteActionCustom")} />
                                                    <OmisDateTimePicker name="dateToCompleteActionCustom" />
                                                </OmisCol>
                                            </OmisCol>
                                            :
                                            null
                                    }
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <Card variant={"outlined"}>
                                        <DocumentUploadForNewReport multiFilesUploadCallback={handleUploadedFiles} referer={DMSDocumentReferer.Tender} />
                                        <OmisRow>
                                            <OmisCol xs={12}>
                                                {
                                                    uploadedFiles && uploadedFiles.length > 0 ?
                                                        <>
                                                            <span>{`${t("Import_UploadedFiles")} :`}</span>
                                                            {
                                                                uploadedFiles.map((file, index) =>
                                                                    <OmisCol key={index.toString()} xs={12}>
                                                                        <strong>{file.name}</strong>
                                                                        <IconButton variant={"outlined"}
                                                                            id={"deleteUploadedFileButton"}
                                                                            color={"error"}
                                                                            onClick={() => deleteFileButtonClicked(file.name)}
                                                                        >
                                                                            <Icon path={mdiTrashCan} size={1} />
                                                                        </IconButton>
                                                                    </OmisCol>
                                                                )
                                                            }
                                                        </>
                                                        :
                                                        null
                                                }
                                            </OmisCol>
                                        </OmisRow>
                                    </Card>
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={12}>
                                    <OmisCol xs={6}>
                                        <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                    </OmisCol>
                                    <OmisCol xs={6}>
                                        <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                    </OmisCol>
                                </OmisCol>
                            </OmisRow>
                        </OmisCol>
                        
                    </Form>
                )}
            </Formik>
        </>
    );
}