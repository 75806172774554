import React, { useState } from "react";
import { t } from "i18next";
import { Link as RouterLink } from "react-router-dom";
import httpClientService from "../../../services/httpclient.service";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisButtonNewItem, OmisIconButton } from "../../shared/OmisButtons";
import { OmisGrid } from "../../shared/OmisGrids";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { OmisDialog } from "../../shared/OmisDisplays";
import { OmisCol, OmisContainer } from "../../shared/OmisLayouts";
import { CreateDispatchParameterVariety } from "./CreateDispatchParameterVariety";

export function SettingsDispatchParameterVariety(props) {
    var url = "/api/settings/companysite/getdispatchparametervariety";

    if (props.companySiteID) {
        url = `${url}?companySiteID=${props.companySiteID}`;
    }

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    function handleEditClick(rowID, e) {
        if (e) {
            setModalTitle(t("Action_Edit"));
        } else {
            setModalTitle(null);
            if (typeof rowID !== 'number') {
                rowID = null
            }
        }
        setModalContent(<CreateDispatchParameterVariety onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID} companySiteID={props.companySiteID} rowVals={e} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    const handleDelete = (rowID) => {
        httpClientService.post(`/api/settings/companysite/deletedispatchparametervariety?id=${rowID}`).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    }

    return (
        <OmisContainer fluid>
            {
                props.hideBack ?
                    null
                    :
                    <OmisCol xs={1}>
                        <OmisIconButton id={"backButton"}
                            iconcontent={<ArrowBackIcon fontSize="inherit" />}
                            text="Back" component={RouterLink} to={"/settings"} state={{ selectedtabvalue: TabConstants.CompanySites }} />
                    </OmisCol>

            }

            <OmisGrid actiondropdown={{ edit: handleEditClick, delete: handleDelete }} headerText={t("Settings_DispatchParameterVariety")}
                newButton={<OmisButtonNewItem id={"newDispatchParameterVarietyButton"} onClick={handleEditClick} text={t("Action_New")} />}
                apiUrl={url}
                change={changeCounter}
                noExportButton
                {...props} />

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"md"}
                title={modalTitle}
                content={modalContent}
                contentHeight={300}
            />
        </OmisContainer>
    );
}