import React from "react";
import { DetailsCardWithSkeleton } from "../shared/Shared";
import { AddressDetailsInfo, ContactsList, ObjectDetailsInfo } from "./Object_TabContent_Details";
import { OmisCol, OmisRow } from "../shared/OmisLayouts";

export function ObjectDetailsModal(props) {
    return (
        <div>
            <OmisRow>
                <OmisCol xs={ 12 }>
                    <DetailsCardWithSkeleton
                        url={ `/api/objects/contactlist?objectid=${ props.objectid }` }
                        skeletonheight={ "400px" }
                        content={
                            <ContactsList { ...props } /> }{ ...props }/>
                </OmisCol>
                <OmisCol xs={ 12 }>
                    <DetailsCardWithSkeleton
                        url={ `/api/objects/addressdetails?objectid=${ props.objectid }` }
                        skeletonheight={ "100px" }
                        content={
                            <AddressDetailsInfo { ...props } /> }{ ...props }/>
                </OmisCol>
                <OmisCol xs={ 12 }>
                    <DetailsCardWithSkeleton
                        url={ `/api/objects/details?objectid=${ props.objectid }` }
                        skeletonheight={ "200px" }
                        content={
                            <ObjectDetailsInfo { ...props } /> }{ ...props }/>
                </OmisCol>
            </OmisRow> {/*Object Details for { props? <b>{props.objectnrinternal}</b> : ''} in popup!*/ }
        </div>);
}