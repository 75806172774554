import React, { useEffect, useState } from "react";
import { t } from "i18next";
import httpclientService from "../../services/httpclient.service";
import Reports from "../reports/Reports";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../shared/OmisButtons";
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";

export function AddLinkedReport(props) {
    const { state } = { ...props };
    const [model, setModel] = useState({
        reportIDs: props?.reportids?.length > 0 ? props?.reportids : state?.reportids?.length>0 ? state?.reportids : state?.reportid ? [state?.reportid] : [],
        selectedLinkedReports : []
    });

    const selectableRowCount = props.selecteableRowCount ?? 20;

    useEffect(() => {
        if (props.selectedRows && props.selectedRows.length>0 && model.selectedLinkedReports.length === 0) {
            var tmp = { ...model };
            tmp.selectedLinkedReports = props.selectedRows;
            setModel(tmp);
        }
    },[])

    function SelectedRowIDs(e) {
        var tmp = { ...model };
        tmp.selectedLinkedReports = e;
        setModel(tmp);
    }

    function handleSave() {
        if (model.selectedLinkedReports.length > 0) {
            if (props.saveUrl) {
                httpclientService.post(`${props.saveUrl}`, model).then((response) => {
                    if (response) {
                        if (props.onClose) {
                            props.onClose();
                        }
                    }
                });
            } else if (props.selectionCallback) {
                props.selectionCallback(model.selectedLinkedReports);
            }
        }
    }

    return (
        <>
            <OmisContainer fluid>
                <OmisRow>
                    <strong>{`${t("LinkFaultReports")}:`}</strong><span>{`${model.reportIDs}`} </span>
                </OmisRow>
                <Reports noButtons showCheckBox removereportfromlist={state?.reportid} rowSelectionCallback={SelectedRowIDs}
                    checkedRows={model.selectedLinkedReports.length > 0 ? model.selectedLinkedReports : props.selectedRows}
                    isRowSelectable={{ isSelectable: model.selectedLinkedReports.length < 20 && selectableRowCount > model.selectedLinkedReports.length, selections: model.selectedLinkedReports }}
                    sx={{
                        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                            display: 'none'
                        },
                        marginBottom: '1em !important'
                    }}
                    hideActionDropdown
                />
                <br />
                <br />
                <br />
                <OmisRow>
                    <OmisCol xs={6}>
                        <OmisButtonPrimarySubmit id={"submitButton"} text={t("LinkSelectedFaultReports")} onClick={handleSave} />
                    </OmisCol>
                    <OmisCol xs={6}>
                        <OmisButtonSecondary id={"cancelButton"} onClick={props.handleCancel} text={t("Action_Cancel")} />
                    </OmisCol>
                </OmisRow>
            </OmisContainer>
        </>
    );
}