import React, { useEffect, useState } from "react";
import { mdiDotsHorizontal, mdiEmail, mdiPencil, mdiTrashCanOutline } from "@mdi/js";
import { t } from "i18next";
import httpclientService from "../../services/httpclient.service";
import { OmisTimeFilterBox } from "../shared/OmisInputs";
import { OmisDate, OmisTimeGapWithWords } from "../shared/OmisDisplays";
import { Card, Checkbox, Grid } from "@mui/material";
import { OmisUserChip } from "../shared/OmisChips";
import { OmisDropDownMenu } from "../shared/OmisButtons";
import { FilterTab } from "./Reports";
import { OmisCol, OmisRow } from "../shared/OmisLayouts";

export function TabContent_Invoices(props) {
    const {state} = {...props};
    const [checked, setChecked] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [filteredVal, setFilteredVal] = useState([]);
    const [userItems, setUserItems] = useState([]);
    const [selectedUserItems, setSelectedUserItems] = useState([]);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startStr, setStartStr] = useState("start");
    const [endStr, setEndStr] = useState("end");

    function btnClick(menuName, e) {
        console.log(menuName);
    }

    const childItemsForDotsMenu = [{
        subMenuIcon: mdiPencil,
        onClick: btnClick.bind(this, "Edit Note"),
        menuName: "Edit Note",
    }, {
        subMenuIcon: mdiEmail,
        onClick: btnClick.bind(this, "Send as email"),
        menuName: "Send as email",
    }, {
        subMenuIcon: mdiTrashCanOutline,
        onClick: btnClick.bind(this, t("Action_Delete")),
        menuName: t("Action_Delete"),
    },]

    useEffect(() => {
        if (state && state.reportid) {
            httpclientService.get(`/api/reports/getinvoices?reportid=${ state.reportid }`)
                .then((data) => {
                    if (data && data.length > 0) {
                        setInvoices(data.map((item, index) => item));
                    }
                });

            httpclientService.get(`/api/reports/getinvoicesusers?reportid=${ state.reportid }`)
                .then((data) => {
                    if (data && data.length > 0) {
                        setUserItems(data.map((item, index) => item));
                    }
                });
        }
    }, []);

    useEffect(() => {
        setFilteredVal(invoices);
    }, [invoices]);

    const checkBoxChange = (selectedBox, event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, selectedBox];
        } else {
            updatedList.splice(checked.indexOf(selectedBox), 1);
        }
        setChecked(updatedList);
    }

    const handleDateChange = (dateType, startDate, endDate, callbackFunction, e) => {
        if (dateType === startStr) {
            if (endDate && endDate < e) {
                alert("Start Date can not be later than End Date");
            } else callbackFunction(e);
        } else if (dateType === endStr) {
            if (startDate && startDate > e) {
                alert("End Date can not be earlier than Start Date");
            } else callbackFunction(e);
        }
    };

    const handleTimeBoxButtonClicks = (clickType, columnField, filterID, selectedStartDate, selectedEndDate, setStartDateFunctionCallback, setEndDateFunctionCallback) => {
        if (clickType === 'applyClick') {
            var results = invoices.filter(x => (selectedStartDate ? new Date(x.invoiceDate) >= selectedStartDate : true) && (selectedEndDate ? new Date(x.invoiceDate) <= selectedEndDate : true));
            setFilteredVal(results);
        } else if (clickType === 'resetClick') {
            setStartDateFunctionCallback("");
            setEndDateFunctionCallback("");
            setFilteredVal(invoices);
        }
    };

    const handleDropDownChange = (setDropdownCallback, event) => {
        const {
            target: {value},
        } = event;

        // On autofill we get a stringified value.
        var _newArr = typeof value === 'string' ? value.split(',') : value;//gives selected values in array
        setDropdownCallback(_newArr.sort((a, b) => a - b));
        if (_newArr.length > 0) {
            var results = invoices.filter(x => _newArr.indexOf(x.userID) !== -1);
            setFilteredVal(results);
        } else {
            setFilteredVal(invoices);
        }
    };

    const childItemsDateTimePicker = [{
        content:
            <OmisTimeFilterBox id={ "invoicetime" } selectedStartDate={ startDate } setStartDate={ setStartDate } onChange={ handleDateChange }
                               selectedEndDate={ endDate } setEndDate={ setEndDate }
                               applyClicked={ handleTimeBoxButtonClicks } resetClicked={ handleTimeBoxButtonClicks }
                               columnField={ "invoicetime" } filterID={ "invoice" }{ ...props } />
    }];

    return (<>
            <br/> { invoices && invoices.length > 0 ? <>
                <Card>
                    <FilterTab
                        getSearchResultItems={ setFilteredVal } listItems={ invoices } listAttr={ ["id", "userName", "userID", "additionalText"] }
                        userItems={ userItems } handleDropDownChange={ handleDropDownChange } setSelectedUserItems={ setSelectedUserItems } selectedUserItems={ selectedUserItems }
                    childItemsDateTimePicker={childItemsDateTimePicker} timetitle={"Created anytime"} />
                    </Card>
                <br/>

                { filteredVal.length > 0 ? filteredVal.map((item, index) => {
                        return (
                            <div key={`${item.id}_RowCard`}>
                                <strong className="detailsCardTitle"><OmisTimeGapWithWords date={item.invoiceDate} /></strong>
                                <Card key={`${item.id}_RowCard`}>
                                    <OmisCol xs={12}>
                                        <OmisRow>
                                            <OmisCol xs={8}>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(item.id) !== -1}
                                                    tabIndex={-1}
                                                    onChange={checkBoxChange.bind(this, item.id)}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${item.id}` }}
                                                />
                                                <strong>{`#${item.id}`}</strong>
                                            </OmisCol>
                                            <OmisCol xs={4}>
                                                <OmisRow className="ms-auto justify-content-end">
                                                    <OmisCol xs={3} >
                                                        <OmisDate date={item.invoiceDate} />
                                                    </OmisCol>
                                                    <OmisCol xs={4}>
                                                        <OmisUserChip userFullName={item.userName} userID={item.userID} />
                                                    </OmisCol>
                                                    <OmisCol xs={1}>
                                                        <OmisDropDownMenu menuicon={mdiDotsHorizontal} childitems={childItemsForDotsMenu} />
                                                    </OmisCol>
                                                </OmisRow>
                                                
                                            </OmisCol>
                                        </OmisRow>
                                        
                                    </OmisCol>
                                    <OmisCol xs={12}>
                                        <p>{`${item.additionalText}`}</p>
                                    </OmisCol>
                                    <OmisCol xs={12}>
                                        <Card>
                                            <OmisCol xs={12}>
                                                <OmisRow>
                                                    <OmisCol xs={4}>
                                                        <span>{t("Invoice_SumNetto")}</span>
                                                    </OmisCol>
                                                    <OmisCol xs={4}>
                                                        <span>{t("Invoice_SumBrutto")}</span>
                                                    </OmisCol>
                                                    <OmisCol xs={4}>
                                                        <span>{t("Invoice_VAT")}</span>
                                                    </OmisCol>
                                                </OmisRow>
                                                
                                                <OmisRow>
                                                    <OmisCol xs={4}>
                                                        <strong>{`${item.sumNetto} €`}</strong>
                                                    </OmisCol>
                                                    <OmisCol xs={4}>
                                                        <strong>{`${item.sumBrutto} €`}</strong>
                                                    </OmisCol>
                                                    <OmisCol xs={4}>
                                                        <strong>{`${item.vat} €`}</strong>
                                                    </OmisCol>
                                                </OmisRow>
                                                
                                            </OmisCol>
                                        </Card>
                                    </OmisCol>
                                </Card>
                            </div>);
                    }) :
                    <strong>{ t("NoResultsFound") }</strong> }
            </>

            :
            <strong>{ t("NoResultsFound") }</strong> }
        </>);
}