import React, { useEffect, useRef, useState } from "react";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { t } from "i18next";
import { Form, Formik } from "formik";
import { OmisCol, OmisRow } from "../../shared/OmisLayouts";
import { OmisButtonPrimarySubmit } from "../../shared/OmisButtons";
import { OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisDropdownMultiselectV2 } from "../../shared/OmisDropdowns";

var api = "/api/profile/";

export default function AddUserTeam(props) {

    const navigate = useNavigate();

    const formRef = useRef();
    const [initialValues, setInitialValues] = useState({
        userID: props.userID ?? null,
        teams: [],
    });

    const [teams, setTeams] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);

    useEffect(() => {
        httpclientService.get(`${api}getteamsfordropdown?userIDForRemove=${props.userID}`).then((response) => {
            if (response && response.length>0) {
                setTeams(response);
            }
        });
    }, []);

    const validationSchema = Yup.object({
        userID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1, t('GenericRequiredText')),
        teams: Yup.array()
            .min(1, t('GenericRequiredText')),
    });

    function handleAddUserTeam(values) {
        httpclientService.post(`${api}adduserteam`, values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(-1);
                }
            }
        });
    }

    function handleTeamChange(event) {
        const {
            target: { value },
        } = event;

        // On autofill we get a stringified value.
        var _newArr = typeof value === 'string' ? value.split(',') : value;//gives selected values in array    
        setSelectedTeams(_newArr.sort((a, b) => a - b));
        formRef.current.setFieldValue("teams", _newArr.sort((a, b) => a - b));
    }

    function handleCancel() {
        if (props.onCancel) {
            props.onCancel();
        } else {
            navigate(-1);
        }
    }

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    handleAddUserTeam(values)
                }}
            >
                {formik => (
                    <Form>
                        <OmisRow>
                            <OmisCol xs={6}>
                                <OmisDropdownMultiselectV2 id="teams" name={"teams"} className="teamsDropDown" items={teams} onChange={handleTeamChange} value={selectedTeams} labeltext={t("Teams")} required />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol xs={6}>
                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel} />
                            </OmisCol> 
                            <OmisCol xs={6}>
                                <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                            </OmisCol>                            
                        </OmisRow>
                    </Form>)}
            </Formik>           
        </>
    );
};