import React from "react";
import authService from "../../services/auth.service";

export function CustomerCheck(props) {

    if (Array.isArray(props.denied)) {
        if (props.denied.indexOf(authService.customerNr()) > -1) {
            return <></>
        } else {
            return <>{props.children}</>
        }
    }

    if (Array.isArray(props.allowed)) {
        if (props.allowed.indexOf(authService.customerNr()) > -1) {
            return <>{props.children}</>
        }
    }

    return <></>
}