import React, { useEffect, useRef, useState } from "react";
import httpclientService from "../../../services/httpclient.service";
import urlEncryptService from '../../../services/urlencrypt.service';
import * as Yup from "yup";
import { t } from "i18next";
import { Form, Formik } from "formik";
import { OmisButtonPrimary, OmisButtonPrimarySubmit, OmisButtonSecondary, OmisProgress, OmisSecButtonWithStartIcon } from "../../shared/OmisButtons";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Card, CardActionArea, CardContent, IconButton } from "@mui/material";
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';
import Icon from "@mdi/react";
import { mdiTrashCan, mdiPlus } from "@mdi/js";
import { OmisCheckBox, OmisTextArea, OmisTextBox } from "../../shared/OmisInputs";
import { DocumentUpload } from "../../documents/Documents";
import { DMSDocumentReferer } from "../../../constants/DMSDocumentReferer";
import { OmisGrid } from "../../shared/OmisGrids";
import { CheckCircle, HighlightOff } from "@mui/icons-material";
import authService from "../../../services/auth.service";
import { OmisDropdown, OmisDropdownMultiselect } from "../../shared/OmisDropdowns";
import { TRole } from "../../../constants/RoleConstants";
import AuthService from "../../../services/auth.service";
import { OmisDialog, OmisErrorMessage, OmisLabel } from "../../shared/OmisDisplays";
import { AddLinkedReport } from "../../inspections/AddLinkedReport";
import { OmisCol, OmisContainer, OmisRow, OmisStack, OmisSticky } from "../../shared/OmisLayouts";

export function ReportsCreate(props) {
    var { state } = useLocation();

    const SelectionStep = {
        Object: 1,
        FaultReportKind: 2,
        FaultEquiClassGroup: 3,
        FaultEquiClassDef: 4,
        Equipment: 5,
        FaultKind: 6,
        Done: 7
    }

    const formRef = useRef();
    var location = useLocation();
    if (!state) {

        var encryptedString = "";
        if (location?.search) {
            encryptedString = location?.search.replace("#", "").replace("?", "").replaceAll("%22", "\"");
        } else if (location?.hash) {
            encryptedString = location?.hash.replace("#", "").replace("?", "").replaceAll("%22", "\"");
        }

        if (encryptedString && encryptedString !== "") {
            state = urlEncryptService.decrypt(encryptedString);
        }
    }

    const [errorMessage, setErrorMessage] = useState(null);
    const [guid, setGuid] = useState(null);
    const [modalWidth, setModalWidth] = useState("md");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [selectionStep, setSelectionStep] = useState(1);
    const [catalogueCardClicked, setCatalogueCardClicked] = useState(false);

    const [selectedObjectID, setSelectedObjectID] = useState(null);
    const [selectedFaultReportKindID, setSelectedFaultReportKindID] = useState(null);
    const [selectedFaultEquiClassGroupID, setSelectedFaultEquiClassGroupID] = useState(null);
    const [selectedFaultEquiClassDefID, setSelectedFaultEquiClassDefID] = useState(null);
    const [selectedEquipmentID, setSelectedEquipmentID] = useState(null);
    const [selectedFaultKindID, setSelectedFaultKindID] = useState(null);

    const [selectedObjectText, setSelectedObjectText] = useState('');
    const [selectedFaultReportKindText, setSelectedFaultReportKindText] = useState('');
    const [selectedFaultEquiClassGroupText, setSelectedFaultEquiClassGroupText] = useState('');
    const [selectedFaultEquiClassDefText, setSelectedFaultEquiClassDefText] = useState('');
    const [selectedEquipmentText, setSelectedEquipmentText] = useState('');
    const [selectedFaultKindText, setSelectedFaultKindText] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const [equipmentHidden, setEquipmentHidden] = useState(false);

    const [imminentDanger, setImminentDanger] = useState(false);
    const [selfRepair, setSelfRepair] = useState(false);
    const [severities, setSeverities] = useState([]);
    const [selectedCompanySite, setSelectedCompanySite] = useState({ value: 0, name: '' });
    const [companySiteSelectable, setCompanySiteSelectable] = useState(AuthService.hasRole(TRole.CompanySite_Selectable));
    const [companySites, setCompanySites] = useState([]);

    const [maintenanceDetails, setMaintenanceDetails] = useState('');
    const [responseTime, setResponseTime] = useState('');
    const [additionalInfoResponse, setAdditionalInfoResponse] = useState({ imminentDangerAvailable: false, selfRepairAvailable: false, severities: [] });

    const [currentUser, setCurrentUser] = useState(authService.getCurrentUser());
    const [isCompanySiteValid, setIsCompanySiteValid] = useState(true);

    const [schema, setSchema] = useState({
        announciator: Yup.string()
            .required(t("GenericRequiredText"))
            .max(100, t('Error_TextTooLong', { 1: 100 })),
        faultdescription: Yup.string()
            .required(t("FaultDescription_Required"))
            .max(4000, t('Error_TextTooLong', { 1: 4000 })),
        objectid: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        faultkindid: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        equipmentid: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        companySiteID: Yup.number()
            .test("", (value) => {
                if (value > 0) {
                    setIsCompanySiteValid(false);
                }else if (companySiteSelectable) {
                    setIsCompanySiteValid(false);
                } else {
                    setIsCompanySiteValid(false);
                }
                return true;
            }),
        //optionalObject: Yup.lazy(value => {
        //    if (formRef.current.values.companySiteSelectable) {
        //        return Yup.object().shape({
        //            companySiteID: Yup.number()
        //                .required(t("GenericRequiredText"))
        //                .min(1),
        //        });
        //    }
        //    return Yup.mixed().notRequired();
        //}),
    });

    const navigate = useNavigate();

    function handleCreateReport(values) {

        handleModalOpen(null, <OmisProgress />);
        if (values.severityID === '') {
            values.severityID = 0;
        }
        if (values.companySiteID === '') {
            values.companySiteID = 0;
        }
        
        httpclientService.post("/api/reports/createreport", values).then((response) => {
            console.log(response.statusCode);
            handleModalClose();
            if (response.statusCode === 200) {
                navigate("/reports");
            }
        });
    }

    useEffect(() => {
        clearSelection(null);
    }, [selectionStep]);

    useEffect(() => {
        console.log(errorMessage);
    }, [errorMessage]);

    const [initialValues, setInitialValues] = useState({
        announciator: currentUser ? currentUser.fullName:'',
        assignee: '',
        faultdescription: '',
        objectid: selectedObjectID,
        faultreportkindid: selectedFaultReportKindID,
        faultequiclassgroupid: selectedFaultEquiClassGroupID,
        faultequiclassdefid: selectedFaultEquiClassDefID,
        equipmentid: selectedEquipmentID,
        faultkindid: selectedFaultKindID,
        damageKnown: false,
        imminentDanger: imminentDanger,
        selfRepair: selfRepair,
        severityID: '0',
        severityIDs: [],
        companySiteID: '',
        attachedFiles: [],
        linkedReports: [],
        companySiteSelectable: null,
        testDatesChecklistID: state?.testDatesChecklistID ?? null,
        guid: null
    });

    function handleUploadedFiles(file) {
        var newUploadedFiles = [...uploadedFiles, ...file];
        setUploadedFiles(newUploadedFiles);
        formRef.current.setFieldValue("attachedFiles", newUploadedFiles);
    }

	function hasAdditionalIntems(props) {
		if(additionalInfoResponse.imminentDangerAvailable) return true;
		if(additionalInfoResponse.selfRepairAvailable) return true;
		if(props.severities && props.severities.length > 0) return true;
		return false;
	}

    const handleModalOpen = (title, content) => {
        if (title === `${t("Action_Add")} ${t("LinkedFaultReports")}`) {
            setModalWidth("lg");
            setModalTitle(null);
        } else {
            setModalTitle(title);
        }

        setModalContent(content);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setModalTitle(null);
        setModalContent(null);
        setModalWidth("md");
    };

    useEffect(() => {
        httpclientService.get(`/api/reports/getguid`).then((response) => {
            if (response) {
                setGuid(response);
                formRef.current.setFieldValue("guid", response);
            }
        });
    }, []);

    useEffect(() => {
        if (currentStep === 2 ) {
            if (selectedFaultReportKindID) {
                httpclientService.get(`/api/reports/getadditionalinformation?faultReportKindID=${selectedFaultReportKindID}`).then((response) => {
                    if (response) {
                        setAdditionalInfoResponse(response);
                        if (response.severities) {
                            setSeverities(response.severities.map((item) => ({
                                key: item.value,
                                displayText: item.name
                            })));
                        }
                    }
                });
            }


            if (selectedObjectID && selectedFaultEquiClassDefID && selectedFaultReportKindID) {

                var url = `/api/reports/getgenerateddetails?objectID=${selectedObjectID}&faultKindID=${selectedFaultKindID}&faultEquiClassDefID=${selectedFaultEquiClassDefID}&faultReportKindID=${selectedFaultReportKindID}&imminentDanger=${imminentDanger}`;
                if (selectedEquipmentID) {
                    url = url + `&equipmentID=${selectedEquipmentID}`;
                }

                httpclientService.get(url).then((response) => {
                    if (response) {
                        if (response.companySite) {
                            setSelectedCompanySite(response.companySite);
                            formRef.current.setFieldValue("companySiteID", response.companySite.value ?? 0);
                        }
                        if (response.maintenanceDetails) {
                            setMaintenanceDetails(response.maintenanceDetails);
                        }
                        if (response.responseTime) {
                            setResponseTime(response.responseTime);
                        }
                    }
                });
            }

            if (companySiteSelectable) {
                handleModalOpen(null, <OmisProgress />);
                httpclientService.get(`/api/reports/getsuppliers`).then((response) => {
                    if (response) {
                        setCompanySites(response.map((item) => ({
                            key: item.id,
                            displayText: item.companyName
                        })));
                    }
                    handleModalClose();
                });
            }
        }
    }, [currentStep]);

    useEffect(() => {
        if (currentStep > 1 && companySites.length > 0) {
            if (companySiteSelectable && formRef.current.values.companySiteID < 1) {
                setIsCompanySiteValid(false);
            } else {
                setIsCompanySiteValid(false);
            }
        }
    }, [companySites, currentStep])

    function deleteFileButtonClicked(file) {
        var filteredSelectedFiles = uploadedFiles.filter(s => s.name !== file);
        setUploadedFiles(filteredSelectedFiles);
        formRef.current.setFieldValue("attachedFiles", filteredSelectedFiles);

        httpclientService.get(`/api/documents/deletetempdocument?guid=${guid}&filename=${file}`);

    }

    function handleLinkReport() {
        handleModalOpen(`${t("Action_Add")} ${t("LinkedFaultReports")}`, <AddLinkedReport selectionCallback={handleSaveLinkedReports} handleCancel={handleModalClose} onClose={handleModalClose} selectedRows={formRef.current.values.linkedReports} selecteableRowCount={20 - formRef.current.values.linkedReports.length} />);
    }
    function handleSaveLinkedReports(ids) {
        formRef.current.setFieldValue("linkedReports", ids);
        handleModalClose();
    }

    const validationSchema = Yup.object(schema);

    return (<>
        <Formik
            innerRef={formRef}
            validateOnMount
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                handleCreateReport(values)
            }}
            enableReinitialize={false}
        >
            {formik => (
                <Form>
                    <OmisSticky>
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={6} >
                                    <h1>
                                        {t("Action_New_Report")}
                                    </h1>
                                </OmisCol>
                                <OmisCol style={{ display: 'flex', justifyContent: 'right' }} xs={6}>
                                    {currentStep === 1 ? <>
                                        <OmisStack direction="horizontal" gap={1}>
                                            <div className="p-2"><OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} component={RouterLink} to={"/reports"}></OmisButtonSecondary></div>
                                            <div className="p-2"><OmisButtonPrimary id={"nextStepButton"} disabled={!formik.isValid} text={t("NextStep")} onClick={() => setCurrentStep(2)}></OmisButtonPrimary></div>
                                        </OmisStack>

                                    </> : <>
                                        <OmisStack direction="horizontal" gap={1}>
                                                <div className="p-2"><OmisButtonSecondary id={"previousStepButton"} disabled={!formik.isValid} text={t("PreviousStep")} onClick={() => setCurrentStep(1)}></OmisButtonSecondary></div>
                                                <div className="p-2"><OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_CreateFaultReport")}></OmisButtonPrimarySubmit></div>
                                        </OmisStack>
                                    </>}
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol> <OmisProgress variant="determinate" value={formik.isValid ? 100 : 0} />(1) {t("ReportDetails")}  </OmisCol>
                                <OmisCol> <OmisProgress variant="determinate" value={currentStep === 2 && formik.isValid ? 100 : 0} />(2) {t("AdditionalDetails")} </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </OmisSticky>
                    <br/>
                    {
                        currentStep === 1 ?

                            <Card variant={"outlined"}>
                                <OmisStack gap={3}>
                                    <OmisCol xs={12 }>
                                        <FirstStep uploadedFiles={uploadedFiles} setUploadedFiles={handleUploadedFiles} deleteFileButtonClicked={deleteFileButtonClicked} />
                                    </OmisCol>
                                    <OmisCol xs={12}>
                                        <FaultCatalogueGrid step={selectionStep} />
                                    </OmisCol>
                                </OmisStack>

                                <br />
                                <br />
                            </Card>
                            :
                            <Card variant={"outlined"}>
                                <OmisCol xs={12}>
                                    <SecondStep companySites={companySites} selectedCompanySite={selectedCompanySite} severities={severities} addlinkedReportClick={handleLinkReport} selectedLinkedReports={formik.values.linkedReports} />
                                </OmisCol>
                            </Card>
                    }
                    
                    
                </Form>)}
        </Formik>

        {/* formik rerenders on every change / blur, so separate not needed components from form*/}
        <OmisDialog open={modalOpen}
            maxWidth={modalWidth}
            title={modalTitle}
            content={modalContent}
        />
    </>);

    //region helper functions

    function handleObjectSelect(selection) {
        setSelectionStep(SelectionStep.FaultReportKind);
        updateSelection(selection);
        setCatalogueCardClicked(false);
    }

    function handleFaultReportKindSelect(selection) {
        setSelectionStep(SelectionStep.FaultEquiClassGroup);
        updateSelection(selection);
        setCatalogueCardClicked(false);
    }

    function handleFaultEquiClassGroupSelect(selection) {
        setSelectionStep(SelectionStep.FaultEquiClassDef);
        updateSelection(selection);
        setCatalogueCardClicked(false);
    }

    function handleFaultEquiClassDefSelect(selection) {
        setSelectionStep(SelectionStep.Equipment);
        updateSelection(selection);
        setCatalogueCardClicked(false);
    }

    function handleEquipmentSelect(selection) {
        setSelectionStep(SelectionStep.FaultKind);
        updateSelection(selection);
        setCatalogueCardClicked(false);
    }

    function handleFaultKindSelect(selection) {
        setSelectionStep(SelectionStep.Done);
        updateSelection(selection);
        setCatalogueCardClicked(false);
    }

    function handleEquipmentEmpty() {
        setSelectionStep(SelectionStep.FaultKind);
        setEquipmentHidden(true);
        schema.equipmentid = null;
    }

    function handleSingleEntry(selectionGrid, row) {
        switch (selectionGrid) {
            case SelectionStep.Object:
                setSelectedObjectID(row?.id ?? null);
                setSelectedObjectText(row?.objectnrinternal ?? '');
                formRef.current.setFieldValue("objectid", row?.id ?? null);
                setSelectionStep(SelectionStep.FaultReportKind);
                break;
            case SelectionStep.FaultReportKind:
                setSelectedFaultReportKindID(row?.id ?? null);
                setSelectedFaultReportKindText(row?.kinddescription ?? '');
                formRef.current.setFieldValue("faultreportkindid", row?.id ?? null);
                setSelectionStep(SelectionStep.FaultEquiClassGroup);
                break;
            case SelectionStep.FaultEquiClassGroup:
                setSelectedFaultEquiClassGroupID(row?.id ?? null);
                setSelectedFaultEquiClassGroupText(row?.faultequiclassgroupdesc ?? '');
                formRef.current.setFieldValue("faultequiclassgroupid", row?.id ?? null);
                setSelectionStep(SelectionStep.FaultEquiClassDef);
                break;
            case SelectionStep.FaultEquiClassDef:
                setSelectedFaultEquiClassDefID(row?.id ?? null);
                setSelectedFaultEquiClassDefText(row?.faultequiclassdefdesc ?? '');
                formRef.current.setFieldValue("faultequiclassdefid", row?.id ?? null);
                setSelectionStep(SelectionStep.Equipment);
                break;
            case SelectionStep.Equipment:
                setSelectedEquipmentID(row?.id ?? null);
                setSelectedEquipmentText(row?.equidescription ?? '');
                formRef.current.setFieldValue("equipmentid", row?.id ?? null);
                setSelectionStep(SelectionStep.FaultKind);
                break;
            case SelectionStep.FaultKind:
                setSelectedFaultKindID(row?.id ?? null);
                setSelectedFaultKindText(row?.faultkinddesc ?? row.faultKindDesc ?? '');
                formRef.current.setFieldValue("faultkindid", row?.id ?? null);
                setSelectionStep(SelectionStep.Done);
                break;
            default:
                break;
        }
    }

    function clearSelection(row) {
        var clearObject = selectionStep < 2;
        var clearFaultReportKind = selectionStep < 3;
        var clearFaultEquiClassGroup = selectionStep < 4;
        var clearFaultEquiClassDef = selectionStep < 5;
        var clearEquipment = selectionStep < 6;
        var clearFaultKind = selectionStep < 7;

        if (clearObject) {
            setSelectedObjectID(null);
            setSelectedObjectText('');
            formRef.current.setFieldValue("objectid", null);
            setEquipmentHidden(false);
        }

        if (clearFaultReportKind) {
            setSelectedFaultReportKindID(null);
            setSelectedFaultReportKindText('');
            formRef.current.setFieldValue("faultreportkindid", null);
            setEquipmentHidden(false);
        }

        if (clearFaultEquiClassGroup) {
            setSelectedFaultEquiClassGroupID(null);
            setSelectedFaultEquiClassGroupText('');
            formRef.current.setFieldValue("faultequiclassgroupid", null);
            setEquipmentHidden(false);
        }

        if (clearFaultEquiClassDef) {
            setSelectedFaultEquiClassDefID(null);
            setSelectedFaultEquiClassDefText('');
            formRef.current.setFieldValue("faultequiclassdefid", null);
            setEquipmentHidden(false);
        }

        if (clearEquipment) {
            setSelectedEquipmentID(null);
            setSelectedEquipmentText('');
            formRef.current.setFieldValue("equipmentid", null);
        }

        if (clearFaultKind) {
            setSelectedFaultKindID(null);
            setSelectedFaultKindText('');
            formRef.current.setFieldValue("faultkindid", null);
        }
    }

    function updateSelection(row) {
        switch (selectionStep) {
            case SelectionStep.Object:
                setSelectedObjectID(row?.id ?? null);
                setSelectedObjectText(row?.objectnrinternal ?? '');
                formRef.current.setFieldValue("objectid", row?.id ?? null);
                break;
            case SelectionStep.FaultReportKind:
                setSelectedFaultReportKindID(row?.id ?? null);
                setSelectedFaultReportKindText(row?.kinddescription ?? '');
                formRef.current.setFieldValue("faultreportkindid", row?.id ?? null);
                break;
            case SelectionStep.FaultEquiClassGroup:
                setSelectedFaultEquiClassGroupID(row?.id ?? null);
                setSelectedFaultEquiClassGroupText(row?.faultequiclassgroupdesc ?? '');
                formRef.current.setFieldValue("faultequiclassgroupid", row?.id ?? null);
                break;
            case SelectionStep.FaultEquiClassDef:
                setSelectedFaultEquiClassDefID(row?.id ?? null);
                setSelectedFaultEquiClassDefText(row?.faultequiclassdefdesc ?? '');
                formRef.current.setFieldValue("faultequiclassdefid", row?.id ?? null);
                break;
            case SelectionStep.Equipment:
                setSelectedEquipmentID(row?.id ?? null);
                setSelectedEquipmentText(row?.equidescription ?? '');
                formRef.current.setFieldValue("equipmentid", row?.id ?? null);
                break;
            case SelectionStep.FaultKind:
                setSelectedFaultKindID(row?.id ?? null);
                setSelectedFaultKindText(row?.faultkinddesc ?? row.faultKindDesc ?? '');
                formRef.current.setFieldValue("faultkindid", row?.id ?? null);
                break;
            default:
                break;
        }
    }

    //endregion

    function FaultCatalogueGrid(props) {
        const {step} = {...props};

        switch (step) {
            case SelectionStep.Object:
                return (
                    <OmisGrid rowclickCallback={handleObjectSelect} noButtons noToolbar noHeader
                        apiUrl={"/api/objects/getobjectlist"}
                        singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.Object)}
                        catalogueCardClicked={catalogueCardClicked}
                        searchByIDInList={state?.objectid}
                        isInListCallback={handleSingleEntry.bind(this, SelectionStep.Object)}
                    />);
            case SelectionStep.FaultReportKind:
                return (
                    <OmisGrid rowclickCallback={handleFaultReportKindSelect} noButtons noToolbar noHeader
                        apiUrl={`/api/settings/faultreportkind/getfaultreportkinds?objectid=${selectedObjectID}`}
                        singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.FaultReportKind)}
                        catalogueCardClicked={catalogueCardClicked}
                    />);
            case SelectionStep.FaultEquiClassGroup:
                return (
                    <OmisGrid rowclickCallback={handleFaultEquiClassGroupSelect} noButtons noToolbar noHeader
                        apiUrl={`/api/settings/faultequiclassgroup/getfaultequiclassgroups?faultreportkindid=${selectedFaultReportKindID}`}
                        singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.FaultEquiClassGroup)}
                        catalogueCardClicked={catalogueCardClicked}
                        searchByIDInList={state?.faultequiclassgroupid}
                        isInListCallback={handleSingleEntry.bind(this, SelectionStep.FaultEquiClassGroup)}
                    />);
            case SelectionStep.FaultEquiClassDef:
                return (
                    <OmisGrid rowclickCallback={handleFaultEquiClassDefSelect} noButtons noToolbar noHeader
                        apiUrl={`/api/settings/faultequiclassdef/getfaultequiclassdefs?faultequiclassgroupid=${selectedFaultEquiClassGroupID}`}
                        singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.FaultEquiClassDef)}
                        catalogueCardClicked={catalogueCardClicked}
                        searchByIDInList={state?.faultequiclassdefid}
                        isInListCallback={handleSingleEntry.bind(this, SelectionStep.FaultEquiClassDef)}
                    />);
            case SelectionStep.Equipment:
                return (
                    <OmisGrid rowclickCallback={handleEquipmentSelect} noButtons noToolbar noHeader
                        apiUrl={ `/api/equipments/getequipmentcreatereport?objectid=${ selectedObjectID }&faultequiclassdefid=${ selectedFaultEquiClassDefID }` }
                        noEntriesCallback={handleEquipmentEmpty}
                        singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.Equipment)}
                        catalogueCardClicked={catalogueCardClicked}
                        searchByIDInList={state?.equiID}
                        isInListCallback={handleSingleEntry.bind(this, SelectionStep.Equipment)}
                    />);
            case SelectionStep.FaultKind:
                return (
                    <OmisGrid rowclickCallback={handleFaultKindSelect} noButtons noToolbar noHeader
                        apiUrl={`/api/settings/faultkind/getfaultkinds?faultequiclassdefid=${selectedFaultEquiClassDefID}`}
                        singleEntriesCallback={handleSingleEntry.bind(this, SelectionStep.FaultKind)}
                        catalogueCardClicked={catalogueCardClicked} />);
            default:
                break;
        }
    }

    function FirstStep(props) {

        function fileUploadCallback(event) {
            props.setUploadedFiles(event);
        }

        return (
            <>
                <OmisStack direction="horizontal" gap={3}>
                    <OmisContainer fluid className="faultReportCreateDetailsBox">
                        <OmisRow>
                            <OmisCol xs={12}>
                                <OmisTextBox labeltext={t("Annunciator")} name={"announciator"} placeholder={t("Annunciator")} required />
                            </OmisCol>
                            <OmisCol xs={12}>
                                <OmisTextArea labeltext={t("FaultDescription")} name={"faultdescription"} placeholder={t("FaultDescription")} required />
                            </OmisCol>
                        </OmisRow>
                    </OmisContainer>
                    <OmisContainer fluid style={{ display: 'flex', justifyContent: 'center' }} className="faultReportCreateDetailsBox">
                        <OmisRow>
                            <OmisCol xs={12}>
                                <DocumentUpload uploadError={setErrorMessage} guid={guid} multiFilesUploadCallback={fileUploadCallback} referer={DMSDocumentReferer.FaultReport} />
                            </OmisCol>
                            {/*<OmisCol xs={12}>*/}
                            {/*    <DocumentUploadForNewReport multiFilesUploadCallback={fileUploadCallback} referer={DMSDocumentReferer.FaultReport} />*/}
                            {/*</OmisCol>*/}
                            <OmisRow>
                                <OmisCol style={{ justifyContent: 'center', marginLeft: '2em' }} xs={12}>
                                    {
                                        errorMessage ?
                                            typeof (errorMessage) === "string" ?
                                                <OmisStack direction="horizontal">
                                                    <OmisCol xs={1}>
                                                        <ClearIcon color={"error"} />
                                                    </OmisCol>
                                                    <OmisCol xs={11}>
                                                        <OmisErrorMessage text={errorMessage} />
                                                    </OmisCol>
                                                </OmisStack>
                                                :
                                                errorMessage?.map((message, index) =>
                                                    <OmisStack key={index} direction="horizontal">
                                                        <OmisCol xs={1}>
                                                            <ClearIcon color={"error"} />
                                                        </OmisCol>
                                                        <OmisCol xs={11}>
                                                            <OmisErrorMessage text={message} />
                                                        </OmisCol>
                                                    </OmisStack>
                                                )
                                                :
                                                null
                                    }
                                    {
                                        props.uploadedFiles && props.uploadedFiles.length > 0 ?
                                            <>
                                                <span>{`${t("Import_UploadedFiles")} :`}</span>
                                                {
                                                    props.uploadedFiles.map((file, index) =>
                                                        <OmisStack key={index.toString()} direction="horizontal">
                                                            <OmisCol xs={1}>
                                                                <CheckIcon color={"success"} />
                                                            </OmisCol>
                                                            <OmisCol xs={11}>
                                                                <strong>{file.name}({file.doctypename})</strong>
                                                                <IconButton variant={"outlined"}
                                                                    id={"deleteAttachmentButton"}
                                                                    color={"error"}
                                                                    onClick={() => props.deleteFileButtonClicked(file.name)}
                                                                >
                                                                    <Icon path={mdiTrashCan} size={1} />
                                                                </IconButton>
                                                            </OmisCol>
                                                    </OmisStack>
                                                    )
                                                }
                                            </>
                                            :
                                            null
                                    }
                                </OmisCol>
                            </OmisRow>
                        </OmisRow>
                    </OmisContainer>
                </OmisStack>
                <OmisContainer fluid>
                    <OmisRow>
                        <OmisCol xs={12}>
                            <h5>
                                {t("FaultDetails")}
                            </h5>
                        </OmisCol>
                        <OmisCol xs={12} className="faultReportCreateDetailsBox">
                            <OmisStack direction="horizontal">
                                <OmisCol xs={4}>
                                    <SelectedFaultCatalogueCard
                                        text={`${t("Object_Details_Title")} (${selectedObjectText})`}
                                        id={selectedObjectID}
                                        selectionstep={SelectionStep.Object}
                                        required
                                        active={selectionStep === SelectionStep.Object}
                                    />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <SelectedFaultCatalogueCard
                                        text={`${t("FaultReportKind")} (${selectedFaultReportKindText})`}
                                        id={selectedFaultReportKindID}
                                        selectionstep={SelectionStep.FaultReportKind}
                                        required
                                        active={selectionStep === SelectionStep.FaultReportKind}
                                    />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <SelectedFaultCatalogueCard
                                        text={`${t("FaultEquiClassGroup")} (${selectedFaultEquiClassGroupText})`}
                                        id={selectedFaultEquiClassGroupID}
                                        selectionstep={SelectionStep.FaultEquiClassGroup}
                                        required
                                        active={selectionStep === SelectionStep.FaultEquiClassGroup}
                                        />
                                </OmisCol>
                            </OmisStack>
                            <OmisStack direction="horizontal" gap={1}>
                                <OmisCol xs={4}>
                                    <SelectedFaultCatalogueCard
                                        text={`${t("FaultReport_FaultEquiClassDef")} (${selectedFaultEquiClassDefText})`}
                                        id={selectedFaultEquiClassDefID}
                                        selectionstep={SelectionStep.FaultEquiClassDef}
                                        required
                                        active={selectionStep === SelectionStep.FaultEquiClassDef}
                                    /> </OmisCol>{equipmentHidden ? null :
                                    <OmisCol xs={4}>
                                        <SelectedFaultCatalogueCard
                                            text={`${t("Equipment")} (${selectedEquipmentText})`}
                                            id={selectedEquipmentID}
                                            selectionstep={SelectionStep.Equipment}
                                            required
                                            active={selectionStep === SelectionStep.Equipment}
                                        />
                                    </OmisCol>}
                                <OmisCol xs={4}>
                                    <SelectedFaultCatalogueCard
                                        text={`${t("FaultKind")} (${selectedFaultKindText})`}
                                        id={selectedFaultKindID}
                                        selectionstep={SelectionStep.FaultKind}
                                        required
                                        active={selectionStep === SelectionStep.FaultKind}
                                    />
                                </OmisCol>
                            </OmisStack>
                        </OmisCol>
                    </OmisRow>
                </OmisContainer>
            </>
        )
    }

    function SecondStep(props) {

        const [multiSelectSeverity, setMultiSelectSeverity] = useState(false);
        return (
            <>
                {
                    props.addlinkedReportClick ?
                        <OmisContainer fluid>
                            <OmisRow style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                <OmisCol xs={3} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <OmisSecButtonWithStartIcon id={"linkReportButton"} iconpath={ mdiPlus} text={`${t("LinkedFaultReports")} (${props.selectedLinkedReports?.length})`} onClick={props.addlinkedReportClick} />
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                        :
                        null
                }
                <OmisStack direction="horizontal" gap={3} className="faultReportCreateDetailsBox">
                    <Card className="createReportStepTwoCardBox">
                        <OmisContainer fluid style={{ display: 'flex', justifyContent: 'center' }} >
                            {hasAdditionalIntems(props) && (
                                <OmisRow>
                                    <OmisCol xs={12}>
                                        <strong>{t("AdditionalInformation")}</strong>
                                    </OmisCol>

                                    <OmisCol xs={12} className="faultReportCreateDetailsBox">

                                        <OmisCol>

                                            {
                                                additionalInfoResponse.imminentDangerAvailable ?
                                                    <OmisCol xs={12}><OmisCheckBox labeltext={t("ImminentDanger")} name={"imminentDanger"} className={"OmisComponent"} /> </OmisCol>
                                                    :
                                                    null

                                            }
                                            {
                                                additionalInfoResponse.selfRepairAvailable ?
                                                    <OmisCol xs={12}><OmisCheckBox labeltext={t("FaultReport_SelfRepair")} name={"selfRepair"} /> </OmisCol>
                                                    :
                                                    null

                                            }
                                            {
                                                props.severities && props.severities.length > 0 ?
                                                    multiSelectSeverity ?
                                                        <OmisCol xs={12}>
                                                            <OmisDropdownMultiselect size={"small"} id={"severities"} name={"severityIDs"} labeltext={t("Severity")}
                                                                items={props.severities} />
                                                        </OmisCol> :
                                                        <OmisCol xs={12}>
                                                            <span>{t("Severity")}</span>
                                                            <OmisDropdown size={"small"} id={"severities"} name={"severityID"} items={props.severities} />
                                                        </OmisCol>

                                                    :
                                                    null
                                            }
                                        </OmisCol>
                                    </OmisCol>
                                </OmisRow>
                            )}
                        </OmisContainer>
                    </Card>
                    <Card className="createReportStepTwoCardBox">
                        <OmisContainer fluid style={{ display: 'flex', justifyContent: 'center' }} >
                            <OmisRow>
                                <OmisCol xs={12}>
                                    <strong>{t("GeneratedDetails")}</strong>
                                </OmisCol>
                                <OmisCol xs={12} className="faultReportCreateDetailsBox">
                                    <OmisCol xs={12}>
                                        <OmisLabel label={t("Contractor")} required={props.companySites?.length > 0 && props.companySiteSelectable} />
                                        {
                                            props.companySites && props.companySites.length > 0 ?
                                                <OmisCol xs={12}>
                                                    <OmisDropdown size={"small"} id={"companySites"} name={"companySiteID"} items={props.companySites} />
                                                </OmisCol>
                                                :
                                                <OmisCol xs={12}>
                                                    <strong>{selectedCompanySite && selectedCompanySite.name ? selectedCompanySite.name : null}</strong>
                                                </OmisCol>
                                        }
                                        {t("MaintenanceContractType")}
                                        <OmisCol xs={12}>
                                            <strong>{maintenanceDetails}</strong>
                                        </OmisCol>
                                        {t("FaultCategory_TimeToCompleteAction")}
                                        <OmisCol xs={12}>
                                            <strong>{responseTime}</strong>
                                        </OmisCol>
                                    </OmisCol>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Card>
                </OmisStack>

            </>
        );
    }

    function SelectedFaultCatalogueCard(props) {
        return (
            <OmisContainer fluid style={{ display: 'flex', justifyContent: 'center' }}>
                <OmisRow>
                    <OmisCol xs={12}>
                        <Card onClick={() => {
                            if (selectionStep >= props.selectionstep) {
                                setSelectionStep(props.selectionstep);
                                setCatalogueCardClicked(true);
                            }
                        }}>
                            <CardActionArea>
                                {
                                    props.id ?
                                    <CardContent className="createReportCatalogueCard">
                                        <OmisRow className="justify-content-md-center">
                                            <OmisStack direction="horizontal" gap={3}>
                                                <OmisCol xs={1}>
                                                    {
                                                        props.id ?
                                                            <CheckCircle color={"success"} />
                                                            :
                                                            <HighlightOff color={"error"} />
                                                    }
                                            </OmisCol>
                                            <OmisCol xs={11}>
                                                    <OmisLabel label={props.text} required={props.required} />
                                            </OmisCol>
                                            </OmisStack>
                                        </OmisRow>    
                                        </CardContent>
                                        :
                                        <CardContent className={props.active ? "createReportCatalogueCardActive" : "createReportCatalogueCardDisabled"}>
                                            <OmisRow className="justify-content-md-center">
                                                <OmisStack direction="horizontal" gap={2}>
                                                    <OmisCol xs={1}>
                                                        {
                                                            props.id ?
                                                                <CheckCircle color={"success"} />
                                                                :
                                                                <HighlightOff color={"error"} />
                                                            }
                                                    </OmisCol>
                                                    <OmisCol xs={11}>
                                                        <OmisLabel label={props.text} required={props.required} />
                                                    </OmisCol>
                                                </OmisStack>
                                            </OmisRow>    
                                        </CardContent>
                                }
                            </CardActionArea>
                        </Card>
                    </OmisCol>
                </OmisRow>
            </OmisContainer> );
    }
}