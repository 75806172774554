import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: false,
        debug: true,
        lng: localStorage.getItem("i18nLng") || navigator.language,
        backend: {
            loadPath: (lng) => {
                return (lng[0] !== localStorage.getItem("i18nLng")) ? 'api/profile/getresources?language=' + localStorage.getItem("i18nLng") || navigator.language : '';
            },
        },
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;