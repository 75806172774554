import React, { useState, useEffect } from 'react';
import { CardActions } from "@mui/material";
import { GridPrintExportMenuItem, GridCsvExportMenuItem, GridFilterPanel, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import { mdiClockTimeThreeOutline, mdiFilterOutline, mdiMenuDown } from "@mdi/js";
import { t } from "i18next";
import { OmisCollapseCard, OmisBadge } from "../shared/OmisDisplays";
import { OmisEditTextBoxNoForm, OmisTimeFilterBox } from './OmisInputs';
import { OmisDropDownMenu, OmisDropDownWithDynamicComponents } from "./OmisButtons";
import { OmisDropdownMultiselectV2 } from "./OmisDropdowns";
import "./Components.css"
import { OmisGridColumnViewsToolbar } from './OmisGridColumnToolbar';
import moment from 'moment';
import httpClientService from '../../services/httpclient.service';
import { OmisCol, OmisRow } from './OmisLayouts';

export function QuickSearchToolbar(props) {

    const [filterNumbers, setFilterNumbers] = useState(0);

    const [currentCurrObjDropdown, setObjDropdown] = useState([]);
    const [objDropdownItems, setObjDropdownItems] = useState([]);

    const [currentStatusDropdown, setStatusDropdown] = useState([]);
    const [statusDropdownItems, setStatusDropdownItems] = useState([]);

    const [currentAnnunciatorDropdown, setAnnunciatorDropdown] = useState([]);
    const [annunciatorDropdownItems, setAnnunciatorDropdownItems] = useState([]);

    const [currentEquiClassesDropdown, setEquiClassesDropdown] = useState([]);
    const [equiClassesDropdownItems, setEquiClassesDropdownItems] = useState([]);

    const [currentEquiTypesDropdown, setEquiTypesDropdown] = useState([]);
    const [equiTypesDropdownItems, setEquiTypesDropdownItems] = useState([]);

    const [currentEquisDropdown, setEquisDropdown] = useState([]);
    const [equisDropdownItems, setEquisDropdownItems] = useState([]);

    const [currentManufacturersDropdown, setManufacturersDropdown] = useState([]);
    const [manufacturersDropdownItems, setManufacturersDropdownItems] = useState([]);

    const [documentTypesDropdownItems, setDocumentTypesDropdownItems] = useState([]);
    const [currentDocumentTypesDropdown, setDocumentTypesDropdown] = useState([]);

    const [currentPriceDropdown, setPriceDropdown] = useState([]);
    const [priceDropdownItems, setPriceDropdownItems] = useState([]);

    const [announcementStartDate, setAnnouncementStartDate] = useState("");
    const [announcementEndDate, setAnnouncementEndDate] = useState("");

    const [currentUserObjDropdown, setUserObjDropdown] = useState([]);
    const [userObjDropdownItems, setUserObjDropdownItems] = useState([]);

    const [currentGroupDropdown, setGroupDropdown] = useState([]);
    const [groupDropdownItems, setGroupDropdownItems] = useState([]);

    const [plannedStartDate, setPlannedStartDate] = useState("");
    const [plannedEndDate, setPlannedEndDate] = useState("");

    const [executionStartDate, setExecutionStartDate] = useState("");
    const [executionEndDate, setExecutionEndDate] = useState("");

    const [uploadStartDate, setUploadStartDate] = useState("");
    const [uploadEndDate, setUploadEndDate] = useState("");

    const [offerDeadlineStartDate, setOfferDeadlineStartDate] = useState("");
    const [offerDeadlineEndDate, setOfferDeadlineEndDate] = useState("");

    const [searchFilterModel, setFilterModel] = useState({
        items: [],
        linkOperator: "and"
    });

    if (props.columnDefs?.length > 0) {
        var temp = 1;
    }

    const colDefEquiClass = props.columnDefs.filter((cols) => cols.field.toLowerCase().includes("equiclass"));
    const colDefEquiType = props.columnDefs.filter((cols) => cols.field.toLowerCase().includes("equitype"));
    const [strFilterOperatorVal, setStrFilterOperatorVal] = useState("isAnyOf");
    const [startTimeFilterOperatorVal, setStartTimeFilterOperatorVal] = useState("onOrAfter"); 
    const [endTimeFilterOperatorVal, setEndTimeFilterOperatorVal] = useState("onOrBefore"); 

    const [startStr, setStartStr] = useState("start");
    const [endStr, setEndStr] = useState("end");

    const [definedFiltersList, setDefinedFiltersList] = useState([]);

    useEffect(() => {
        if (definedFiltersList.length === 0) {
            httpClientService.get("/api/shared/getdefinedfilters").then((response) => {
                if (response && response.length > 0) {
                    setDefinedFiltersList(response);
                }
            });
        }
    }, []);

    function setFilterDropdown(columnField, currentDropdown, setFunctionCallback) {
        if (props.filterModel.items.filter((data) => data.columnField === columnField).length === 1) {

            var tmpObj = props.filterModel.items.filter((data) => data.columnField === columnField)[0];
            var isNewFilterValNotSameWithSelections = tmpObj.value && tmpObj.value.length !== currentDropdown.length;

            if (tmpObj.operatorValue && tmpObj.operatorValue === strFilterOperatorVal && isNewFilterValNotSameWithSelections) {
                setFunctionCallback(tmpObj.value);
            }
            else if (isNewFilterValNotSameWithSelections && currentDropdown.length > 0) {
                setFunctionCallback([]);
            }
        } else if (currentDropdown.length > 0) {
            setFunctionCallback([]);
        }
    }

    function setFilterDate(columnField, operatorValue, selectedDate, setFunctionCallback) {

        if (props.filterModel.items.filter((data) => data.columnField === columnField && data.operatorValue === operatorValue).length === 1) {
            var tmpObj = props.filterModel.items.filter((data) => data.columnField === columnField && data.operatorValue === operatorValue)[0];
            var isNewFilterValNotSameWithSelections = selectedDate ? tmpObj.value !== ConvertDateStringToDatePickerFormat(selectedDate) : true;

            if (tmpObj.value && isNewFilterValNotSameWithSelections) {
                setFunctionCallback(tmpObj.value);
            }
            else if (isNewFilterValNotSameWithSelections && selectedDate) {
                setFunctionCallback("");
            }
        } else if (selectedDate) {
            setFunctionCallback("");
        }
    }

    function ConvertDateStringToDatePickerFormat(date) {
        var startVal = 0, maxLength = 16;
        //Date format should be "yyyy-MM-ddTHH:mm"
        //toISOString() returns with seconds ('yyyy-MM-ddTHH:mm:ss.fffZ') that's why substring used here
        
        if (date) {
            return `${moment(date).format()}`.substring(startVal, maxLength);
        }

        return `${new Date(date).toISOString().substring(startVal, maxLength)}`;
        
    }

    //#region set the selections again by filter changes
    useEffect(() => {
        if (props.filterModel) {
            setFilterModel(props.filterModel);
            setFilterNumbers(props.filterModel.items.length);

            setFilterDropdown('objectnrinternal', currentCurrObjDropdown, setObjDropdown);
            setFilterDropdown('currentstatus', currentStatusDropdown, setStatusDropdown);
            setFilterDropdown('annunciator', currentAnnunciatorDropdown, setAnnunciatorDropdown);
            setFilterDropdown((colDefEquiClass?.length > 0 ? colDefEquiClass[0].field : 'equiClass_description'), currentEquiClassesDropdown, setEquiClassesDropdown);
            setFilterDropdown((colDefEquiType.length > 0 ? colDefEquiType[0].field : 'equitype_description'), currentEquiTypesDropdown, setEquiTypesDropdown);
            setFilterDropdown('equidescription', currentEquisDropdown, setEquisDropdown);
            setFilterDropdown('parent', currentDocumentTypesDropdown, setDocumentTypesDropdown);
            setFilterDropdown('producer', currentManufacturersDropdown, setManufacturersDropdown);
            setFilterDropdown('currentprice', currentPriceDropdown, setPriceDropdown);
            setFilterDropdown('objectids', currentUserObjDropdown, setUserObjDropdown);
            setFilterDropdown('groupids', currentGroupDropdown, setGroupDropdown);
            setFilterDate('announcementdate', startTimeFilterOperatorVal, announcementStartDate, setAnnouncementStartDate);
            setFilterDate('announcementdate', endTimeFilterOperatorVal, announcementEndDate, setAnnouncementEndDate);
            setFilterDate('uploadDate', startTimeFilterOperatorVal, uploadStartDate, setUploadStartDate);
            setFilterDate('uploadDate', endTimeFilterOperatorVal, uploadEndDate, setUploadEndDate);
            setFilterDate('dateplannedvalue', startTimeFilterOperatorVal, plannedStartDate, setPlannedStartDate);
            setFilterDate('dateplannedvalue', endTimeFilterOperatorVal, plannedEndDate, setPlannedEndDate);
            setFilterDate('datecompletion', startTimeFilterOperatorVal, executionStartDate, setExecutionStartDate);
            setFilterDate('datecompletion', endTimeFilterOperatorVal, executionEndDate, setExecutionEndDate);
            setFilterDate('offerdeadline', startTimeFilterOperatorVal, offerDeadlineStartDate, setOfferDeadlineStartDate);
            setFilterDate('offerdeadline', endTimeFilterOperatorVal, offerDeadlineEndDate, setOfferDeadlineEndDate);
        }
    }, [props.filterModel]);
    //#endregion

    //#region filling dropdown items
    
    useEffect(() => {
        if (props.filterFields && props.filterFields?.objectsDropDownHasItems && props.rowData?.length > 0 && (objDropdownItems?.length === 0 || props.filterFields?.objectsDropDownHasItems?.length !== objDropdownItems?.length )) {
            setObjDropdownItems(props.filterFields?.objectsDropDownHasItems);
        }
    }, [props.objectsDropDownHasItems, props.rowData]);

    useEffect(() => {
        if (props.filterFields && props.filterFields?.statusDropDownHasItems && props.rowData?.length > 0 && (statusDropdownItems?.length === 0 || props.filterFields?.statusDropDownHasItems?.length !== statusDropdownItems?.length )) {
            setStatusDropdownItems(props.filterFields?.statusDropDownHasItems);
        }
    }, [props.statusDropDownHasItems, props.rowData]);

    useEffect(() => {
        if (props.filterFields && props.filterFields?.annunciatorDropDownHasItems && props.rowData?.length > 0 && (annunciatorDropdownItems?.length === 0 || props.filterFields?.annunciatorDropDownHasItems?.length  !== annunciatorDropdownItems.length)) {
            setAnnunciatorDropdownItems(props.filterFields?.annunciatorDropDownHasItems);

            //set filter if navigated from dashboard chart bar click
            if (props.innerDatas.setFilterVal && props.innerDatas.setFilterVal.annunciator && props.filterFields?.annunciatorDropDownHasItems?.length > 0) {
                setManuelFilterValues(props.innerDatas.setFilterVal.annunciator, 'annunciator', 'annunciatorSearchID', setAnnunciatorDropdown);
            }
        }
    }, [props.annunciatorDropDownHasItems, props.rowData]);

    useEffect(() => {
        if (props.filterFields && props.filterFields?.equiClassesDropDownHasItems && props.rowData?.length > 0 && (equiClassesDropdownItems?.length === 0 || props.filterFields?.equiClassesDropDownHasItems?.length !== equiClassesDropdownItems.length)) {
            setEquiClassesDropdownItems(props.filterFields?.equiClassesDropDownHasItems);
        }
    }, [props.equiClassesDropDownHasItems, props.rowData]);

    useEffect(() => {
        if (props.filterFields && props.filterFields?.equiTypesDropDownHasItems && props.rowData?.length > 0 && (equiTypesDropdownItems?.length === 0 || props.filterFields?.equiTypesDropDownHasItem?.length !== equiTypesDropdownItems.length)) {
            setEquiTypesDropdownItems(props.filterFields?.equiTypesDropDownHasItems);
        }
    }, [props.equiTypesDropDownHasItems, props.rowData]);

    useEffect(() => {
        if (props.filterFields && props.filterFields?.equisDropDownHasItems && props.rowData?.length > 0 && (equisDropdownItems?.length === 0 || props.filterFields?.equisDropDownHasItems?.length !== equisDropdownItems?.length )) {
            setEquisDropdownItems(props.filterFields?.equisDropDownHasItems);
        }
    }, [props.equisDropDownHasItems, props.rowData]);


    useEffect(() => {
        if (props.filterFields && props.filterFields?.manufacturersDropDownHasItems && props.rowData?.length > 0 && (manufacturersDropdownItems?.length === 0 || props.filterFields?.manufacturersDropDownHasItems?.length !== manufacturersDropdownItems.length)) {
            setManufacturersDropdownItems(props.filterFields?.manufacturersDropDownHasItems);
        }
    }, [props.manufacturersDropDownHasItems, props.rowData]);

    useEffect(() => {
        if (props.filterFields && props.filterFields?.documentTypesDropDownHasItems && props.rowData?.length > 0 && (documentTypesDropdownItems?.length === 0 || props.filterFields?.documentTypesDropDownHasItems?.length !== documentTypesDropdownItems.length)) {
            setDocumentTypesDropdownItems(props.filterFields?.documentTypesDropDownHasItems);
        }
    }, [props.documentTypesDropDownHasItems, props.rowData]);

    useEffect(() => {
        if (props.filterFields && props.filterFields?.priceDropDownHasItems && props.rowData?.length > 0 && (priceDropdownItems?.length === 0 || props.filterFields?.priceDropDownHasItems?.length !== priceDropdownItems.length)) {
            setPriceDropdownItems(props.filterFields?.priceDropDownHasItems);
        }
    }, [props.priceDropDownHasItems, props.rowData]);

    useEffect(() => {
        if (props.filterFields && props.filterFields?.groupsDropDownHasItems && props.rowData?.length > 0 && (groupDropdownItems?.length === 0 || props.filterFields?.groupsDropDownHasItems?.length !== groupDropdownItems.length)) {
            setGroupDropdownItems(props.filterFields?.groupsDropDownHasItems);
        }
    }, [props.groupsDropDownHasItems, props.rowData]);

    useEffect(() => {
        if (props.filterFields && props.filterFields?.userObjectsDropDownHasItems && props.rowData?.length > 0 && (userObjDropdownItems?.length === 0 || props.filterFields?.userObjectsDropDownHasItems?.length !== userObjDropdownItems.length)) {
            setUserObjDropdownItems(props.filterFields?.userObjectsDropDownHasItems);
        }
    }, [props.userObjectsDropDownHasItems, props.rowData]);
    //#endregion

    //#region Add Manuel Filter from navigation 
    function setManuelFilterValues(filterVal,columnField,filterID,dropDownCallBackFunc) {
        var e = {
            target: { value: filterVal }
        };
        handleDropDownChange(columnField, filterID, strFilterOperatorVal, dropDownCallBackFunc, e);
        dropDownCallBackFunc([filterVal]);
    }
    //#endregion

    //#region DropDown filters Onchange Functions

    const handleDropDownChange = (columnField, filterID, operatorValue,setDropdownCallback, event) => {
        const {
            target: { value },
        } = event;

        // On autofill we get a stringified value.
        var _tmpArray = typeof value === 'string' ? value.split(',') : value;//gives selected values in array
        var _newArr = typeof value === 'string' ? value.split(',') : value;

        if (columnField === "groupids" || columnField === "objectids") {
            _tmpArray.map((item) => {
                if (item !== "") {
                    var tmp = props.rowData.map((row) => row[columnField]).filter((f) => f != item && f.split(",").indexOf(item) > -1);
                    if (tmp && tmp != undefined && tmp.length > 0) {
                        tmp.map((missingFilter) => {
                            if (_newArr.indexOf(missingFilter) === -1) {
                                _newArr.push(missingFilter)
                            }
                        })
                    }
                }
            }
            )
        }

        setDropdownCallback(_newArr.sort((a, b) => a - b));

        var colDefinition = props.columnDefs.filter((cols) => cols.field.toLowerCase().includes(columnField));

        if (searchFilterModel.items?.length > 0) {
            searchFilterModel.items = searchFilterModel.items.filter((data) => data.columnField !== (colDefinition.length > 0 ? colDefinition[0].field : columnField));
        } else {
            searchFilterModel.items = [];
        }

        if (_newArr.length > 0)
            searchFilterModel.items.push({ columnField: (colDefinition.length > 0 ? colDefinition[0].field : columnField), operatorValue: operatorValue, id: filterID, value: _newArr });

        setFilterModel(searchFilterModel);

        if (props.handlefilterChng) {
            props.handlefilterChng(searchFilterModel.items);
        }
    };

    //#endregion

    //#region date time filters Onchange Functions

    const handleDateChange = (dateType, startDate, endDate, callbackFunction, e) => {
        if (dateType === startStr) {
            if (endDate && endDate < e) {
                alert("Start Date can not be later than End Date");
            } else
                callbackFunction(e);
        } else if (dateType === endStr) {
            if (startDate && startDate > e) {
                alert("End Date can not be earlier than Start Date");
            } else
                callbackFunction(e);
        }
    };

    //#endregion

    //#region date time filters apply/reset Functions

    const handleTimeBoxButtonClicks = (clickType, columnField, filterID, selectedStartDate, selectedEndDate, setStartDateFunctionCallback, setEndDateFunctionCallback) => {
        if (clickType === 'applyClick')
        {
            if (searchFilterModel.items?.length > 0) {
                searchFilterModel.items = searchFilterModel.items.filter((data) => data.columnField !== columnField);
            } else {
                searchFilterModel.items = [];
            }

            //TODO : toISOString returns UTC time zone that�s why announcementStartDate & EndDate is changing. Need to find a way to convert Date to ISOString(yyyy-MM-ddTHH:mm) in locale time zone.
            if (selectedStartDate !== '') {
                searchFilterModel.items.push({ columnField: columnField, operatorValue: startTimeFilterOperatorVal, id: `${filterID}StartDate`, value: ConvertDateStringToDatePickerFormat(selectedStartDate) });
            }

            if (selectedEndDate !== '') {
                searchFilterModel.items.push({ columnField: columnField, operatorValue: endTimeFilterOperatorVal, id: `${filterID}EndDate`, value: ConvertDateStringToDatePickerFormat(selectedEndDate) });
            }

            setFilterModel(searchFilterModel);

            if (props.handlefilterChng) {
                props.handlefilterChng(searchFilterModel.items);
            }

        } else if (clickType === 'resetClick') {
            setStartDateFunctionCallback("");
            setEndDateFunctionCallback("");
            searchFilterModel.items = searchFilterModel.items.filter((data) => data.columnField !== columnField);
            setFilterModel(searchFilterModel);
            if (props.handlefilterChng) {
                props.handlefilterChng(searchFilterModel.items);
            }
        }
    };

    //#endregion

    const childItemsForAnnouncementDateTimePicker = [{
        content:
            <>
                <OmisTimeFilterBox id={"announcement"} selectedStartDate={announcementStartDate} setStartDate={setAnnouncementStartDate} onChange={handleDateChange}
                    selectedEndDate={announcementEndDate} setEndDate={setAnnouncementEndDate} 
                    applyClicked={handleTimeBoxButtonClicks} resetClicked={handleTimeBoxButtonClicks}
                    columnField={"announcementdate"} filterID={"announcement"} 
                    {...props} />
            </>
    }];

    const childItemsForExecutionDateTimePicker = [{
        content:
            <>
                <OmisTimeFilterBox id={"execution"}
                    selectedStartDate={executionStartDate} setStartDate={setExecutionStartDate} onChange={handleDateChange}
                    selectedEndDate={executionEndDate} setEndDate={setExecutionEndDate}
                    applyClicked={handleTimeBoxButtonClicks} resetClicked={handleTimeBoxButtonClicks}
                    columnField={"datecompletion"} filterID={"execution"}
                    {...props} />
            </>
    }];

    const childItemsForUploadTimePicker = [{
        content:
            <>
                <OmisTimeFilterBox id={"upload"}
                    selectedStartDate={uploadStartDate} setStartDate={setUploadStartDate} onChange={handleDateChange}
                    selectedEndDate={uploadEndDate} setEndDate={setUploadEndDate}
                    applyClicked={handleTimeBoxButtonClicks} resetClicked={handleTimeBoxButtonClicks}
                    columnField={"uploadDate"} filterID={"upload"}
                    {...props} />
            </>
    }];

    const childItemsForPlannedDateTimePicker = [{
        content:
            <>
                <OmisTimeFilterBox id={"planned"}
                    selectedStartDate={plannedStartDate} setStartDate={setPlannedStartDate} onChange={handleDateChange}
                    selectedEndDate={plannedEndDate} setEndDate={setPlannedEndDate}
                    applyClicked={handleTimeBoxButtonClicks} resetClicked={handleTimeBoxButtonClicks}
                    columnField={"dateplannedvalue"} filterID={"planned"} 
                    {...props} />
            </>
    }];

    const childItemsForOfferDeadlineDateTimePicker = [{
        content:
            <>
                <OmisTimeFilterBox id={"offerdeadline"}
                    selectedStartDate={offerDeadlineStartDate} setStartDate={setOfferDeadlineStartDate} onChange={handleDateChange}
                    selectedEndDate={offerDeadlineEndDate} setEndDate={setOfferDeadlineEndDate}
                    applyClicked={handleTimeBoxButtonClicks} resetClicked={handleTimeBoxButtonClicks}
                    columnField={"offerdeadline"} filterID={"offerdeadline"}
                    {...props} />
            </>
    }];

    const dynamicFilterComponents = [
        {
            component:
                <OmisCol xs={2} key="FaultManagement_ObjectIDs">
                    <div className={"omisFilterCard"}>
                        <OmisDropdownMultiselectV2 id="objDrpDown" className="" items={objDropdownItems} onChange={handleDropDownChange.bind(this, 'objectnrinternal', 'objectnrinternalSearchID', strFilterOperatorVal, setObjDropdown)} value={currentCurrObjDropdown} labeltext={t("Object")} props={props} />
                    </div >
                </OmisCol>,
            visibleFor: ["FaultManagement_ObjectIDs", "Inspections_ObjectIDs"],
            visible: props.innerDatas.objectsDropDownHasItems && props.visibleFilters?.filter((f) => (props.innerDatas.reportsgrid ? f.description == "FaultManagement_ObjectIDs" : f.description == "Inspections_ObjectIDs"))[0]?.defaultVisible 
        },
        {
            component:
                <OmisCol xs={2} key="Status">
                    <div className={"omisFilterCard"}>
                        <OmisDropdownMultiselectV2 id="statusDrpDown" className="" items={statusDropdownItems} onChange={handleDropDownChange.bind(this, 'currentstatus', 'currentstatusSearchID', strFilterOperatorVal, setStatusDropdown)} value={currentStatusDropdown} labeltext={t("FaultReport_CurrentStatus")} props={props} />
                    </div>
                </OmisCol>,
            visibleFor: ["Inspections_States", "FaultManagement_States", "CheckList_OverView_States"],
            visible: props.innerDatas.statusDropDownHasItems
        },
        {
            component:
                <OmisCol xs={2} key="FaultManagement_AnnunciatorIDs">
                    <div className={"omisFilterCard"}>
                        <OmisDropdownMultiselectV2 id="annunciatorDrpDown" className="" items={annunciatorDropdownItems} onChange={handleDropDownChange.bind(this, 'annunciator', 'annunciatorSearchID', strFilterOperatorVal, setAnnunciatorDropdown)} value={currentAnnunciatorDropdown} labeltext={t("Annunciator")} props={props} />
                    </div>
                </OmisCol>,
            visibleFor: ["FaultManagement_AnnunciatorIDs"],
            visible: props.innerDatas.annunciatorDropDownHasItems && props.visibleFilters?.filter((f) => f.description == "FaultManagement_AnnunciatorIDs")[0]?.defaultVisible 
        },
        {
            component:
                <OmisCol xs={2} key="Equipments_EquiClass">
                    <div className={"omisFilterCard"}>
                        <OmisDropdownMultiselectV2 id="allEquipmentClassesDrpDown" className="" items={equiClassesDropdownItems} onChange={handleDropDownChange.bind(this, 'equiclass', 'equiClass_descriptionSearchID', strFilterOperatorVal, setEquiClassesDropdown)} value={currentEquiClassesDropdown} labeltext={t("EquiClasses")} props={props} />
                    </div>
                </OmisCol>,
            visibleFor: ["FaultManagement_EquiClassIDs", "FaultManagement_Equipment_EquiClassIDs", "Equipments_EquiClass"],
            visible: props.innerDatas.equiClassesDropDownHasItems && props.visibleFilters?.filter((f) => (props.innerDatas.reportsgrid ? (f.description == "FaultManagement_EquiClassIDs" || f.description == "FaultManagement_Equipment_EquiClassIDs") : f.description == "Equipments_EquiClass"))[0]?.defaultVisible 
        },
        {
            component:
                <OmisCol xs={2} key="Equipments_EquiType">
                    <div className={"omisFilterCard"}>
                        <OmisDropdownMultiselectV2 id="allEquipmentTypesDrpDown" className="" items={equiTypesDropdownItems} onChange={handleDropDownChange.bind(this, 'equitype', 'equitype_descriptionSearchID', strFilterOperatorVal, setEquiTypesDropdown)} value={currentEquiTypesDropdown} labeltext={t("EquiTypes")} props={props} />
                    </div>
                </OmisCol>,
            visibleFor: ["FaultManagement_Equipment_EquiTypeIDs", "Equipments_EquiType"],
            visible: props.innerDatas.equiTypesDropDownHasItems && props.visibleFilters?.filter((f) => (props.innerDatas.reportsgrid ? f.description == "FaultManagement_Equipment_EquiTypeIDs" : f.description == "Equipments_EquiType"))[0]?.defaultVisible
        },
        {
            component:
                <OmisCol xs={2} key="Equipments_Producer">
                    <div className={"omisFilterCard"}>
                        <OmisDropdownMultiselectV2 id="manufacturersDrpDown" className="" items={manufacturersDropdownItems} onChange={handleDropDownChange.bind(this, 'producer', 'producerSearchID', strFilterOperatorVal, setManufacturersDropdown)} value={currentManufacturersDropdown} labeltext={t("Equipment_Producer")} props={props} />
                    </div>
                </OmisCol>,
            visibleFor: ["Equipments_Producer"],
            visible: props.innerDatas.manufacturersDropDownHasItems && props.visibleFilters?.filter((f) => f.description == "Equipments_Producer")[0]?.defaultVisible
        },
        {
            component:
                <OmisCol xs={2} key="Tender_Price">
                    <div className={"omisFilterCard"}>
                        <OmisDropdownMultiselectV2 id="priceDrpDown" className="" items={priceDropdownItems} onChange={handleDropDownChange.bind(this, 'currentprice', 'currentpriceSearchID', strFilterOperatorVal, setPriceDropdown)} value={currentPriceDropdown} labeltext={t("Price")} props={props} />
                    </div>
                </OmisCol>,
            visibleFor: ["Tender_Price"],
            visible: props.innerDatas.priceDropDownHasItems && props.visibleFilters?.filter((f) => f.description == "Tender_Price")[0]?.defaultVisible 
        },
        {
            component:
                <OmisCol xs={2} key="Users_GroupIDs">
                    <div className={"omisFilterCard"}>
                        <OmisDropdownMultiselectV2 id="groupsDrpDown" className="" items={groupDropdownItems} onChange={handleDropDownChange.bind(this, 'groupids', 'groupsSearchID', strFilterOperatorVal, setObjDropdown)} value={currentGroupDropdown} labeltext={t("Group")} props={props} />
                    </div >
                </OmisCol>,
            visibleFor: ["Users_GroupIDs"],
            visible: props.innerDatas.groupsDropDownHasItems && props.visibleFilters?.filter((f) => f.description == "Users_GroupIDs")[0]?.defaultVisible
        },
        {
            component:
                <OmisCol xs={2} key="Users_ObjectIDs">
                    <div className={"omisFilterCard"}>
                        <OmisDropdownMultiselectV2 id="userObjDrpDown" className="" items={userObjDropdownItems} onChange={handleDropDownChange.bind(this, 'objectids', 'objectsSearchID', strFilterOperatorVal, setObjDropdown)} value={currentUserObjDropdown} labeltext={t("Object")} props={props} />
                    </div >
                </OmisCol>,
            visibleFor: ["Users_ObjectIDs"],
            visible: props.innerDatas.userObjectsDropDownHasItems && props.visibleFilters?.filter((f) => f.description == "Users_ObjectIDs")[0]?.defaultVisible
        },
        {
            component:
                <OmisCol xs={2} key="AnnouncementDate" >
                    <div className={""}>
                        <OmisDropDownWithDynamicComponents horizontal={"center"} menuicon={mdiClockTimeThreeOutline} menutitle={t("FollowupConfiguration_1")} childitems={childItemsForAnnouncementDateTimePicker} showdownarrow={1} />
                    </div>
                </OmisCol>,
            visibleFor: ["FaultManagement_FromDate"],
            visible: (props.innerDatas.withDueTime && props.columnDefs?.filter((data) => data.field === 'announcementdate').length === 1) && props.visibleFilters?.filter((f) => f.description == "FaultManagement_FromDate")[0]?.defaultVisible
        },
        {
            component:
                <OmisCol xs={2} key="Inspections_FromPlannedDate">
                    <div className={""}>
                        <OmisDropDownWithDynamicComponents horizontal={"center"} menuicon={mdiClockTimeThreeOutline} menutitle={t("DatePlanned")} childitems={childItemsForPlannedDateTimePicker} showdownarrow={1} />
                    </div>
                </OmisCol>,
            visibleFor: ["Inspections_FromPlannedDate"],
            visible: props.innerDatas.withPlannedDate && props.visibleFilters?.filter((f) => f.description == "Inspections_FromPlannedDate")[0]?.defaultVisible
        },
        {
            component:
                <OmisCol xs={2} key="Inspections_FromCompletionDate">
                    <div className={""}>
                        <OmisDropDownWithDynamicComponents horizontal={"center"} menuicon={mdiClockTimeThreeOutline} menutitle={t("DateCompletion")} childitems={childItemsForExecutionDateTimePicker} showdownarrow={1} />
                    </div>
                </OmisCol>,
            visibleFor: ["Inspections_FromCompletionDate"],
            visible: props.innerDatas.withExecutionDate && props.visibleFilters?.filter((f) => f.description == "Inspections_FromCompletionDate")[0]?.defaultVisible
        },
        {
            component:
                <OmisCol xs={2} key="Documents_DocumentType">
                    <div className={"omisFilterCard"}>
                        <OmisDropdownMultiselectV2 id="documentTypesDrpDown" className="" items={documentTypesDropdownItems} onChange={handleDropDownChange.bind(this, 'parent', 'documentTypeSearchID', strFilterOperatorVal, setDocumentTypesDropdown)} value={currentDocumentTypesDropdown} labeltext={t("DMS_DocumentType")} props={props} />
                    </div>
                </OmisCol>,
            visibleFor: ["Documents_DocumentType"],
            visible: props.innerDatas.documentTypesDropDownHasItems && props.visibleFilters?.filter((f) => f.description == "Documents_DocumentType")[0]?.defaultVisible
        },
        {
            component:
                <OmisCol xs={2} key="Documents_UploadDate">
                    <div className={""}>
                        <OmisDropDownWithDynamicComponents horizontal={"center"} menuicon={mdiClockTimeThreeOutline} menutitle={t("UploadDate")} childitems={childItemsForUploadTimePicker} showdownarrow={1} />
                    </div>
                </OmisCol>,
            visibleFor: ["Documents_UploadDate"],
            visible: props.innerDatas.withUploadDate && props.visibleFilters?.filter((f) => f.description == "Documents_UploadDate")[0]?.defaultVisible
        },
        {
            component:
                <OmisCol xs={2} key="Tender_OfferTime">
                    <div className={""}>
                        <OmisDropDownWithDynamicComponents horizontal={"center"} menuicon={mdiClockTimeThreeOutline} menutitle={t("OfferDeadline")} childitems={childItemsForOfferDeadlineDateTimePicker} showdownarrow={1} />
                    </div>
                </OmisCol>,
            visibleFor: ["Tender_OfferTime"],
            visible: props.innerDatas.withOfferTime && props.visibleFilters?.filter((f) => f.description == "Tender_OfferTime")[0]?.defaultVisible
        },
    ];

    const collapseCardFirstRow =
        <>
            <OmisRow>
                {
                    props.innerDatas.hideQuickSearch ?
                        null
                        :
                        <OmisCol xs={2}>
                            <div>
                                <GridToolbarQuickFilter sx={{ height: 40, borderRadius: '12px !important' }} quickFilterParser={(srchVal) =>
                                    srchVal
                                        .split(',')
                                        .map((value) => value.trim())
                                        .filter((value) =>
                                            value !== "")

                                } />
                            </div>
                        </OmisCol>
                }
                {
                    dynamicFilterComponents
                        .filter((f) => f.visible)
                        .slice(0, 4)
                        .map((item) => {
                            return item.component;
                        })
                }
                {
                    props.innerDatas.freeTextFilter ?
                        <OmisCol xs={2}>
                            <div className={"omisFilterCard"}>
                                <OmisEditTextBoxNoForm sx={{ maxHeight: '2.5em' }} value={props.innerDatas?.freeTextFilter?.filterValue ?? ""} onSave={props.innerDatas?.freeTextFilter?.setFilterCallback} />
                            </div>
                        </OmisCol>
                        :
                        null
                }
            </OmisRow>
        </>;

    const filtersListForIcon = [{
        content:
                <GridFilterPanel columnsSort="asc"/>
    }];

    //const childItemsForExport = [{
    //    content:
    //        <>
    //            <GridCsvExportMenuItem options={props.csvOptions} />
    //            <GridPrintExportMenuItem options={props.printOptions} />
    //        </>
    //}];

    useEffect(() => {
        if (dynamicFilterComponents?.length > 0 && definedFiltersList?.length > 0) {
            dynamicFilterComponents.forEach((item) => {
                if (item.visibleFor && item.visibleFor.length > 0) {
                    item.visibleFor.forEach((vf) => {
                        if (!(definedFiltersList.some((f) => f.description === vf))) {
                            httpClientService.post(`/api/shared/savefilterdef?description=${vf}`).then((response) => {
                                definedFiltersList.push({ description: vf });
                            });                            
                        }
                    })
                }
            })
        }
    }, [definedFiltersList, dynamicFilterComponents]);

    return (
        <>
            <OmisCollapseCard buttonRowContent={collapseCardFirstRow} variant={"outlined"} hasAdditionalButton={<OmisCol xs={2}><OmisCol xs={6}><OmisDropDownWithDynamicComponents isfilter={1} showdownarrow={0} filterscontainer={1} menuicon={mdiFilterOutline} menutitle={<OmisBadge text={`${filterNumbers}`} badgeSide="rightTop" textColor="#0F8B6C" backgroundColor="#E8F9F5" />} childitems={filtersListForIcon} /></OmisCol></OmisCol>} >
                {
                    dynamicFilterComponents.filter((f) => f.visible).length > 4 ?
                    <CardActions>
                            <OmisRow style={{ display: 'flex', alignItems: 'center' }}>
                                <OmisCol xs={11} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                    <OmisRow>
                                        {
                                            dynamicFilterComponents
                                                .filter((f) => f.visible)
                                                .slice(4)
                                                .map((item) => {
                                                    return item.component;
                                                })
                                        }
                                    </OmisRow>
                                </OmisCol>
                        </OmisRow>
                        </CardActions>
                        :
                        null
                }
            </OmisCollapseCard>
            {/* <OmisGridColumnViewsToolbar apiUrl={props.innerDatas.apiUrl} columnOrderChanged={props.columnOrderChanged} pinnedColumnsChanged={props.pinnedColumnsChanged} filterModel={props.filterModel} setView={props.setView} columnDefs={props.columnDefs} columnVisibilityModel={props.columnVisibilityModel} /> */}
            <br />
            {
                props.innerDatas?.bulkactions && props.innerDatas?.selectedRows?.length > 0
                    ?
                    <>
                        <OmisRow>
                            <OmisCol style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                <OmisDropDownMenu menutitle={t("Common_CollectiveActions")} menuicon={mdiMenuDown} iconposition={"right"} childitems={props.innerDatas.bulkactions} />
                            </OmisCol>
                        </OmisRow>
                        <br />
                    </>
                    :
                    null
            }
        </>
    );
}