import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {OmisGrid} from "../../shared/OmisGrids";
import { OmisButtonNewItem, OmisIconButton, OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import {OmisDeleteQuestionDialog, OmisDialog} from "../../shared/OmisDisplays";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {OmisDateTimePicker, OmisTextBox} from "../../shared/OmisInputs";
import DialogActions from "@mui/material/DialogActions";
import httpclientService from "../../../services/httpclient.service";
import { Link as RouterLink } from "react-router-dom";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisCol, OmisRow } from '../../shared/OmisLayouts';

var state = { selectedtabvalue: TabConstants.System }
export default function Maintenance(props) {
    const { t } = useTranslation();

    const [changeCounter, setChangeCounter] = useState(0);

    const [newModalOpen, setNewModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [selectedRowID, setSelectedRowID] = useState(0);

    const handleModalOpen = () => {
        setNewModalOpen(true);
    };

    const handleModalClose = () => {
        setNewModalOpen(false);
        setEditModalOpen(false);
        setDeleteModalOpen(false);
        setSelectedRowID(0);
    };

    function handleEditClick(item) {
        setSelectedRowID(item);
        setEditModalOpen(true);
    }

    function handleDeleteClick(item) {
        setSelectedRowID(item);
        setDeleteModalOpen(true);
    }

    return (
        <div className="omis-header-box">
            <OmisCol xs={1}>
                <OmisIconButton id={"backButton"} 
                    iconcontent={<ArrowBackIcon fontSize="inherit" />}
                    text="Back" component={RouterLink} to={"/settings"} state={state ?? { selectedtabvalue: TabConstants.System }} />
            </OmisCol>

            <OmisGrid actiondropdown={{ edit: handleEditClick, delete: handleDeleteClick }}
                      change={changeCounter}
                      newButton={<OmisButtonNewItem id={"newItemButton"} onClick={handleModalOpen} text={t("Action_New")} />}
                      headerText={"Wartungsfenster"}
                      apiUrl={"/api/settings/maintenance/index"}
            />
            <OmisDialog onClose={handleModalClose} open={newModalOpen}
                        title={"Neues Wartungsfenster"}
                        content={<Popup_Content />}
            />
            <OmisDialog onClose={handleModalClose} open={editModalOpen}
                        title={"Wartungsfenster bearbeiten"}
                        content={<Popup_Content />}
            />
            <OmisDeleteQuestionDialog open={deleteModalOpen}
                                      handleModalClose={handleModalClose}
                                      deleteFunction={handleDeleteItem}
            />
        </div>
    );

    function handleDeleteItem() {
        httpclientService.post(`/api/settings/maintenance/delete?id=${selectedRowID}`).then((response) => {
            setChangeCounter((prevState) => prevState + 1);
            handleModalClose();
        })
    }

    function Popup_Content(props) {
        const [initialValues, setInitialValues] = useState({
            id: 0,
            starttime: new Date(),
            endtime: new Date(),
            showinfohoursbeforestart: 12
        });

        const editItem = Boolean(selectedRowID > 0);
        useEffect(() => {
            if (editItem) {
                httpclientService.get(`/api/settings/maintenance/get?id=${selectedRowID}`).then((response) => {
                    setInitialValues(response);
                })
            }
        }, [])

        function handleSave(values) {
            httpclientService.post("/api/settings/maintenance/save", JSON.stringify(values)).then((response) => {
                switch (response.statusCode) {
                    case 200:
                        handleModalClose();
                        setChangeCounter((prevState) => prevState + 1);
                        break;
                    default:
                        break;
                }
            })
        }

        return(
            <>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        showinfohoursbeforestart: Yup.number()
                            .required(t("GenericRequiredText"))
                            .min(1, "Zahl muss zwischen 1 und 24 liegen")
                            .max(24, "Zahl muss zwischen 1 und 24 liegen"),
                        starttime: Yup.date()
                            .required(t("GenericRequiredText")),
                        endtime: Yup.date()
                            .required(t("GenericRequiredText"))
                            .when('starttime', (starttime) => {
                                if (starttime) {
                                    return Yup.date()
                                        .min(starttime, 'Enddatum muss nach Startdatum liegen')
                                }
                            })
                    })}
                    onSubmit={(values) => { handleSave(values) }}
                >
                    <Form>
                        <OmisRow>
                            <OmisCol xs="6">
                                <OmisTextBox labeltext={"Stunden davor anzeigen"} name="showinfohoursbeforestart" />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol xs="6">
                                <OmisDateTimePicker disablePast={true} label={"Beginndatum"} name="starttime" />
                            </OmisCol>
                            <OmisCol xs="6">
                                <OmisDateTimePicker disablePast={true} labeltext={"Enddatum"} name="endtime" />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <DialogActions>
                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleModalClose}></OmisButtonSecondary>
                                <OmisButtonPrimarySubmit id={"submitButton"} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                            </DialogActions>
                        </OmisRow>
                    </Form>
                </Formik>
            </>
        );
    }
}