import React, { useState } from "react";
import { t } from "i18next";
import httpClientService from "../../../services/httpclient.service";
import { Link as RouterLink } from "react-router-dom";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisButtonNewItem, OmisIconButton } from "../../shared/OmisButtons";
import { OmisGrid } from "../../shared/OmisGrids";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { CreateContractor } from "./CreateContractor";
import { OmisDialog } from "../../shared/OmisDisplays";
import { OmisCol, OmisContainer } from "../../shared/OmisLayouts";


export function SettingsCompanySiteContractors(props) {
    var url = "/api/settings/companysite/getcontractors";

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    if (props.removeArchived) {
        url = `${url}?removeArchived=true`
    }

    function handleEditClick(rowID, e) {
        if (e) {
            setModalTitle(t("Action_Edit"));
        } else {
            setModalTitle(null);
            if (typeof rowID !== 'number') {
                rowID = null
            }
        }
        setModalContent(<CreateContractor onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID} rowVals={e} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    const handleDelete = (rowID) => {
        httpClientService.post(`/api/settings/companysite/deletecontractor?id=${rowID}`).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    }

    return (
        <OmisContainer fluid>
            {
                props.hideBack ?
                    null
                    :
                    <div className="omis-header-box">
                        <OmisCol xs={1}>
                            <OmisIconButton id={"backButton"}
                                iconcontent={<ArrowBackIcon fontSize="inherit" />}
                                text="Back" component={RouterLink} to={"/settings"} state={{ selectedtabvalue: TabConstants.CompanySites }} />
                        </OmisCol>
                    </div>
            }

            <OmisGrid actiondropdown={{ edit: handleEditClick, delete: handleDelete }} headerText={t("Contractors")}
                newButton={<><OmisButtonNewItem id={"newContractorButton"} onClick={handleEditClick} text={t("Action_New")} /></>}
                apiUrl={url}
                noHeader={props.noHeader}
                noButtons={props.noButtons}
                change={changeCounter}
                noExportButton
                hasdetailview
                detailsroute={"/settings/companysite/contractordetails"}
                {...props} />


            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={"md"}
                title={modalTitle}
                content={modalContent}
                contentHeight={400}
            />
        </OmisContainer>
    );
}