import React, { useEffect, useState } from "react";
import { mdiDotsHorizontal, mdiPencil, mdiTrashCanOutline, mdiEmailArrowRight } from "@mdi/js";
import { t } from "i18next";
import httpclientService from "../../services/httpclient.service";
import { OmisTimeFilterBox } from "../shared/OmisInputs";
import { Card, Checkbox, Skeleton } from "@mui/material";
import { OmisDialog, OmisTimeGap, OmisTimeGapWithWords } from "../shared/OmisDisplays";
import { OmisDropDownMenu, OmisIconButton } from "../shared/OmisButtons";
import { OmisUserChip } from "../shared/OmisChips";
import { FilterTab } from "./Reports";
import { AddNote } from "./AddNote";
import AuthService from "../../services/auth.service";
import { TRole } from "../../constants/RoleConstants";
import DatehelperService from "../../services/datehelper.service";
import { SpaceComponent } from "../shared/Shared";
import HistoryIcon from '@mui/icons-material/History';
import Icon from '@mdi/react';
import { NoteHistory } from "./NoteHistory";
import { OmisCol, OmisRow } from "../shared/OmisLayouts";

var editNoteTitle = "";
var sendEmailTitle = "";
var deleteNoteTitle = "";

export function TabContent_Notes(props) {
    const {state} = {...props};
    const [isLoading, setIsLoading] = useState(true);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [noteList, setNoteList] = useState([]);
    const [checked, setChecked] = useState([]);
    const [filteredVal, setFilteredVal] = useState([]);
    const [userItems, setUserItems] = useState([]);
    const [selectedUserItems, setSelectedUserItems] = useState([]);
    const [changeCounter, setChangeCounter] = useState(0);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startStr, setStartStr] = useState("start");
    const [endStr, setEndStr] = useState("end");

    editNoteTitle = t("Action_Edit");
    sendEmailTitle = t("SendEmail");
    deleteNoteTitle = t("Action_Delete");

    const [childItemsForDotsMenu, setChildItemsForDotsMenu] = useState([
        //{
        //subMenuIcon: mdiEmail,
        //onClick: menuItemClicked.bind(this, sendEmailTitle),
        //menuName: sendEmailTitle,
        //}
    ]);

    function menuItemClicked(menuName, e) {
        let rowID = typeof (e) === "number" || typeof (e) === "string" ? e : e?.target?.id;
        if (menuName === editNoteTitle) {
            setModalContent(<AddNote apiUrl={'/api/reports/getnotedetails'} onCancel={handleModalCancel} onClose={handleModalClose} selectedRowID={rowID} />);
        }

        if (menuName === sendEmailTitle) {
            handleSendNoteToEmail(rowID);
        } else {
            setModalTitle(menuName);
            setModalOpen(true);
        }

    }

    function handleModalCancel() {
        setModalOpen(false);
    }

    function handleModalClose() {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    }

    const editNoteMenuItem = {
        subMenuIcon: mdiPencil,
        onClick: menuItemClicked.bind(this, editNoteTitle),
        menuName: editNoteTitle,
    };

    const deleteNoteMenuItem = {
        subMenuIcon: mdiTrashCanOutline,
        onClick: (e) => {
            let rowID = typeof (e) === "number" || typeof (e) === "string" ? e : e?.target?.id;
            handleDeleteNote(rowID).then((resp) => {
                if (resp) {
                    loadList();
                }
            });
        },
        menuName: deleteNoteTitle,
    };

    useEffect(() => {
        loadList();
    }, [changeCounter, props.changeCounter]);

    useEffect(() => {
        setFilteredVal(noteList);
    }, [noteList]);

    function loadList() {
        httpclientService.get(`/api/reports/notes?reportid=${state?.reportid}`)
            .then((data) => {
                setNoteList(data);
                data.map((item) => {
                    var tmp = [...childItemsForDotsMenu];
                    if (AuthService.hasRole(TRole.NoteEdit) && tmp.map((item) => item.menuName).indexOf(editNoteTitle) === -1) {
                        tmp = [editNoteMenuItem, ...tmp];
                    }
                    if (AuthService.hasRole(TRole.NoteDelete) && tmp.map((item) => item.menuName).indexOf(deleteNoteTitle) === -1) {
                        tmp = [...tmp, deleteNoteMenuItem];
                    }
                    setChildItemsForDotsMenu(tmp);
                })
                setIsLoading(false);
            });

        httpclientService.get(`/api/reports/notesusers?reportid=${state?.reportid}`)
            .then((data) => {
                if (data && data.length > 0) {
                    setUserItems(data.map((item, index) => item));
                }
            });

        return () => {
            setNoteList(null);
            setIsLoading(true);
        };
    }

    const checkBoxChange = (selectedBox, event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, selectedBox];
        } else {
            updatedList.splice(checked.indexOf(selectedBox), 1);
        }
        setChecked(updatedList);
    }

    const handleDateChange = (dateType, startDate, endDate, callbackFunction, e) => {
        if (dateType === startStr) {
            if (endDate && endDate < e) {
                alert("Start Date can not be later than End Date");
            } else callbackFunction(e);
        } else if (dateType === endStr) {
            if (startDate && startDate > e) {
                alert("End Date can not be earlier than Start Date");
            } else callbackFunction(e);
        }
    };

    const handleTimeBoxButtonClicks = (clickType, columnField, filterID, selectedStartDate, selectedEndDate, setStartDateFunctionCallback, setEndDateFunctionCallback) => {
        if (clickType === 'applyClick') {
            var results = noteList.filter(x => (selectedStartDate ? new Date(x.created) >= selectedStartDate : true) && (selectedEndDate ? new Date(x.created) <= selectedEndDate : true));
            setFilteredVal(results);
        } else if (clickType === 'resetClick') {
            setStartDateFunctionCallback("");
            setEndDateFunctionCallback("");
            setFilteredVal(noteList);
        }
    };

    const handleDropDownChange = (setDropdownCallback, event) => {
        const {
            target: {value},
        } = event;

        // On autofill we get a stringified value.
        var _newArr = typeof value === 'string' ? value.split(',') : value;//gives selected values in array
        setDropdownCallback(_newArr.sort((a, b) => a - b));
        if (_newArr.length > 0) {
            var results = noteList.filter(x => _newArr.indexOf(x.userID) !== -1);
            setFilteredVal(results);
        } else {
            setFilteredVal(noteList);
        }
    };

    const childItemsDateTimePicker = [{
        content:
            <OmisTimeFilterBox id={ "invoicetime" } selectedStartDate={ startDate } setStartDate={ setStartDate } onChange={ handleDateChange }
                               selectedEndDate={ endDate } setEndDate={ setEndDate }
                               applyClicked={ handleTimeBoxButtonClicks } resetClicked={ handleTimeBoxButtonClicks }
                               columnField={ "invoicetime" } filterID={ "invoice" }{ ...props } />
    }];

    return (<>
            <br/>

            { isLoading ?
                <Skeleton variant={ "rounded" } height={ 200 }/> : noteList.length > 0 ? <>

						<Card>
							<FilterTab
								getSearchResultItems={ setFilteredVal } listItems={ noteList } listAttr={ ["userName", "caption", "note"] }
								userItems={ userItems } handleDropDownChange={ handleDropDownChange } setSelectedUserItems={ setSelectedUserItems } selectedUserItems={ selectedUserItems }
                        childItemsDateTimePicker={childItemsDateTimePicker} timetitle={t("Note_Created") }/>
							<br/>
						</Card>
                            { filteredVal && filteredVal.length > 0 ? filteredVal.map((item, index) =>
                                <OmisCol xs={12} key={ `${ index }Div` }>
                                    {
                                        index !== 0 && !DatehelperService.IsSameDay(new Date(item.created), new Date(filteredVal[index - 1].created)) ?
                                            <OmisCol xs={ 12 } key={ `${ index }Grid` }>
                                                <strong key={`${index}Strong`} className="detailsCardTitle"><OmisTimeGapWithWords key={`${index}Date`} date={item.created }/>
                                                </strong>
                                            </OmisCol> : index === 0 ?
                                                <OmisCol xs={ 12 } key={ `${ index }Grid` }>
                                                    <strong key={`${index}Strong`} className="detailsCardTitle"><OmisTimeGapWithWords key={`${index}Date`} date={item.created }/>
                                                    </strong>
                                                </OmisCol> : null }
                                    <OmisCol key={ item.noteID } xs={ 12 }>
                                        <NoteCard index={index} checked={checked} checkBoxChange={checkBoxChange}
                                            childItemsForDotsMenu={
                                                childItemsForDotsMenu
                                            } {...item} />
                                    </OmisCol>
                                </OmisCol>
                ) :
                                <strong>{ t("NoResultsFound") }</strong> }
                    </> :
                <strong className="detailsCardTitle">{t("NoResultsFound")}</strong>}

        <OmisDialog onClose={handleModalCancel} open={modalOpen}
            title={modalTitle}
            content={modalContent}
        />
        </>);
}

function NoteCard(props) {

    const [modalContent, setModalContent] = useState();
    const [modalOpen, setModalOpen] = useState(false);

    function showHistory(history) {
        setModalContent(<NoteHistory itemsList={history} />);
        setModalOpen(true);
    }

    function handleModalCancel() {
        setModalOpen(false);
    }

    return (
        <>
            <div key={`${props.index}_RowCard`}>
                <Card key={`${props.index}_RowCard`} variant={"outlined"} sx={{ paddingRight: '0 !important', paddingLeft: '48px !important' }}>
                    <OmisCol xs={12}>
                        <OmisRow>
                            <OmisCol xs={9} style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    edge="start"
                                    checked={props.checked.indexOf(props.index) !== -1}
                                    tabIndex={-1}
                                    onChange={props.checkBoxChange.bind(this, props.index)}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${props.index}` }}
                                />
                                <strong>{`${props.caption}`}</strong>
                            </OmisCol>
                            <OmisCol xs={3} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                <OmisRow>
                                    <OmisCol xs={8} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                        <OmisTimeGap date={props.created} />
                                    </OmisCol>
                                    <OmisCol xs={2} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                        {
                                            props.noteHistory && props.noteHistory.length > 0 ?
                                                <OmisIconButton id={"historyButton"} iconcontent={<HistoryIcon />} onClick={showHistory.bind(this, props.noteHistory)} />
                                                :
                                                null
                                        }
                                    </OmisCol>
                                    <OmisCol xs={2} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                        <OmisDropDownMenu rowid={props.noteID} menuicon={mdiDotsHorizontal} childitems={props.childItemsForDotsMenu} />
                                    </OmisCol>
                                </OmisRow>
                                
                            </OmisCol>
                        </OmisRow>
                        
                    </OmisCol>
                    <OmisCol xs={12}>
                        <p>{props.note}</p>
                    </OmisCol>
                    <OmisCol xs={12}>
                        <OmisUserChip userFullName={props.user} userName={props.userName} userID={props.userID} />
                        <SpaceComponent />
                        <strong>{t(props.internalNote ? "InternalNote" : "ExternalNote")}</strong>
                        <SpaceComponent />
                        {props.mailNote && props.mailTO ? <><span>{t("Email_Sent_To")}: </span><a href={`mailto:${props.mailTO}`}>{props.mailTO}</a></> : null}
                        <SpaceComponent />
                        {props.mailNote && props.mailCC ? <> <span>{t("Email_CC")}: </span><a href={`mailto:${props.mailCC}`}>{props.mailCC}</a></> : null}
                        <SpaceComponent />
                        {props.mailNote && props.mailTO ? <Icon path={mdiEmailArrowRight} size={1} /> : null}
                    </OmisCol>
                </Card>
                <br></br>
            </div>
            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={'md'}
                title={t("Tender_HistoryList")}
                content={modalContent}
            />
        </>
    );
}

const handleSendNoteToEmail = (rowID) => {
    return httpclientService.post(`/api/reports/sendnotetoemail?noteid=${rowID}`).then((response) => {
        return response;
    });
}

const handleDeleteNote = (rowID) => {
    return httpclientService.post(`/api/reports/deletenote?id=${rowID}`).then((response) => {
        return response;
    });
}