import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";
import { OmisDropdown } from "../../shared/OmisDropdowns";

export function CreateDispatchParameterVariety(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const [faultEquiClassDefs, setFaultEquiClassDefs] = useState([]);
    const [faultCategories, setFaultCategories] = useState([]);
    const [companySites, setCompanySites] = useState([]);

    const [initialValues, setInitialValues] = useState({
        id:  props.id ?? null,
        faultEquiClassDefID: props.rowVals?.faultEquiClassDefID ?? null,
        faultCategoryID: props.rowVals?.faultCategoryID ?? null,
        companySiteID: props.companySiteID ??  props.rowVals?.companySiteID ?? null,
    });

    useEffect(() => {

        httpclientService.get("/api/settings/settingsequipments/getfaultequiclassdefsfordropdown").then((response) => {
            if (response && response.length > 0) {
                setFaultEquiClassDefs(response);
            }
        });

        httpclientService.get("api/settings/faultkind/getfaultcategorylist").then(response => {
            setFaultCategories(response);
        });

        httpclientService.get("api/inspections/getcompanysitesfordropdown").then((response) => {
            if (response && response.length > 0) {
                setCompanySites(response);
            }
        });
    },[]);

    function handleSave(values) {
        httpclientService.post("/api/settings/companysite/savedispatchparametervariety", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(0);
                }
            }
        });
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(-1);
        }
    }

    const [schema, setSchema] = useState({
        faultEquiClassDefID: Yup.number(t('GenericRequiredText'))
            .typeError(t("GenericRequiredText"))
            .required(t("GenericRequiredText"))
            .min(1, t('GenericRequiredText')),
        faultCategoryID: Yup.number(t('GenericRequiredText'))
            .typeError(t("GenericRequiredText"))
            .required(t("GenericRequiredText"))
            .min(1, t('GenericRequiredText')),
        companySiteID: Yup.number(t('GenericRequiredText'))
            .typeError(t("GenericRequiredText"))
            .required(t("GenericRequiredText"))
            .min(1, t('GenericRequiredText')),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("Settings_DispatchParameterVariety")}</h1>
                                :
                                null
                        }

                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={4}>
                                    <OmisDropdown labeltext={t("FaultReport_FaultEquiClassDef")} name="faultEquiClassDefID" items={faultEquiClassDefs} />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisDropdown labeltext={t("FaultCategory")} name="faultCategoryID" items={faultCategories} />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisDropdown labeltext={t("CompanySite")} name="companySiteID" items={companySites} disabled={props.companySiteID ? true:false} />
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}