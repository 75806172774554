import React from "react";
import { DetailsCardWithSkeleton } from "../shared/Shared";
import { ReportDetailsInfo } from "./ReportsDetails";
import { OmisCol } from "../shared/OmisLayouts";

export function ReportsDetailsModal(props) {
    return (
        <div>
            <OmisCol xs={12}>
                <DetailsCardWithSkeleton
                    url={`/api/reports/details?reportid=${props.reportid}`}
                    skeletonheight={"720px"}
                    content={
                        <ReportDetailsInfo {...props} />
                    }
                {...props} />
            </OmisCol>
        </div>);
}