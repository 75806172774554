import { t } from "i18next";
import { Card } from "@mui/material";
import { OmisDate } from "../shared/OmisDisplays";
import React from "react";
import { DetailsCardWithSkeleton } from "../shared/Shared";
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';
import { OmisObjectChip } from "../shared/OmisChips";
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";

export function Equipment_TabContent_Details(props) {
    const {state} = {...props};

    return (
        <OmisRow>
            <OmisCol xs={ 12 }>
                <DetailsCardWithSkeleton
                    url={ `/api/equipments/details?equipid=${ state?.equipid }` }
                    skeletonheight={ "200px" }
                    content={
                        <EquipmentDetailsInfo { ...props } /> }{ ...props }/>
            </OmisCol>
            <OmisCol xs={6}>
                <br />
                <DetailsCardWithSkeleton
                    url={ `/api/equipments/faultcount?equipid=${ state?.equipid }` }
                    skeletonheight={ "400px" }
                    content={
                        <FaultCountInfo { ...props } /> }{ ...props }/>
            </OmisCol>
            <OmisCol xs={6}>
                <br />
                <DetailsCardWithSkeleton
                    url={ `/api/equipments/tendercosts?equipid=${ state?.equipid }` }
                    skeletonheight={ "400px" }
                    content={
                        <TenderCostsInfo { ...props } /> }{ ...props }/>
            </OmisCol>
        </OmisRow>);
}

export function EquipmentDetailsInfo(props) {

    const data = {...props.data};

    return (
        <>
            <strong className="detailsCardTitle">{t("Details")}</strong>
            <Card variant={"outlined"}>
                <OmisContainer fluid>
                    <OmisRow>
                        <OmisCol xs={3}>
                            <p>{t("Description")}</p>
                            <p className={"bold"}>{data.EquiDescription}</p>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <p>{t("IDExtern")}</p>
                            <p className={"bold"}>{data.IDExtern}</p>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <p>{t("Equipment_Owner")}</p>
                            <p className={"bold"}>{data.EquiOwner_Description}</p>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <p>{t("SNR")}</p>
                            <p className={"bold"}>{data.SNR}</p>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <p>{t("Object")}</p>
                            <span className={"bold"}>
                                <OmisObjectChip text={data.ObjectNrInternal} objectnrinternal={data.ObjectNrInternal} objectid={data.ObjectID} /></span>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <p>{t("EquiClass")}</p>
                            <p className={"bold"}>{data.EquiClass_Description}</p>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <p>{t("EquiType")}</p>
                            <p className={"bold"}>{data.EquiType_Description}</p>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <p>{t("Equipment_Producer")}</p>
                            <p className={"bold"}>{data.Producer}</p>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <p>{t("Status")}</p>
                            <p className={"bold"}>{data.Status_Description}</p>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <p>{t("CompanySite")}</p>
                            <p className={"bold"}>{data.EquiContractor}</p>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <p>{t("Comment")}</p>
                            <p className={"bold"}>{data.Comment}</p>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <p>{t("Equipment_YearOfConstruction")}</p>
                            <p className={"bold"}>{data.YearOfConstruction}</p>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <p>{t("ScrappingDate")}</p>
                            <span className={"bold"}>{data.ScrappingDate ? <OmisDate date={data.ScrappingDate} /> : null}</span>
                        </OmisCol>
                    </OmisRow>
                </OmisContainer>
            </Card>
        <br />
        <strong>{ t("Garantie Details") }</strong>
            <Card variant={"outlined"}>
                <OmisContainer fluid>
                    <OmisRow>
                        <OmisCol xs={3}>
                            <p>{t("Equipment_GuaranteeFrom")}</p>
                            <p className={"bold"}>
                                {
                                    data.GuaranteeFrom ?
                                        <OmisDate date={data.GuaranteeFrom} />
                                        :
                                        null
                                }
                            </p>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <p>{t("Equipment_GuaranteeTo")}</p>
                            <span className={"bold"}>
                                {
                                    data.GuaranteeFrom ?
                                        <OmisDate date={data.GuaranteeTo} />
                                        :
                                        null
                                }
                            </span>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <p>{t("CompanySite_Guarantee")}</p>
                            <p className={"bold"}>{data.EquiContractor_Guarantee}</p>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <p>{t("TenderSpec_Active")}</p>
                            <span className={"bold"}>{data.TSpecActive ? <CheckIcon color={"success"} /> : <ClearIcon color={"error"} />}</span>
                        </OmisCol>
                    </OmisRow>
                </OmisContainer>
            </Card>
        </>
        );
}

function TenderCostsInfo(props) {
    const data = {...props.data};
    return (
        <>
            <strong>{t("Costs")}</strong>
            <Card variant={"outlined"} sx={{ height: '8.5em !important' }}>
                <OmisContainer>
                    <OmisRow>
                        <OmisCol xs={6}>
                            <p>{t("Equipment_TenderingCosts_Months", { 0: 12 })}</p> { /* TODO: wie funktioniert string.format? */}
                            <p className={"bold"}>{data.tendercosts12months}</p>
                        </OmisCol>
                        <OmisCol xs={6}>
                            <p>{t("Equipment_TenderingCosts_Total")}</p>
                            <p className={"bold"}>{data.tendercoststotal}</p>
                        </OmisCol>
                    </OmisRow>
                </OmisContainer>
            </Card>
        </>
    );
}

function FaultCountInfo(props) {
    const data = {...props.data};
    return (
        <>
            <strong>{t("FaultReport_Count")}</strong>
            <Card variant={"outlined"} sx={{height:'8.5em !important'} }>
                <OmisContainer>
                    <OmisRow>
                        <OmisCol xs={6}>
                            <p>{t("Widget_LastYearReports")}</p>
                            <p className={"bold"}>{data.faultcount12months}</p>
                        </OmisCol>
                        <OmisCol xs={6}>
                            <p>{t("Equipment_FaultCount_Total")}</p>
                            <p className={"bold"}>{data.faultcounttotal}</p>
                        </OmisCol>
                    </OmisRow>
                </OmisContainer>
            </Card>
        </>
    );
}