import React, {useEffect} from 'react';
import Login from "./profile/Login";
import AuthService from "../services/auth.service";
import Reports from './reports/Reports';

export default function Home(props) {
    return (
        <>
            {
                !AuthService.isAuthenticated() ? <Login /> : null
            }
        </>
    )
}
