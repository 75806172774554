import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisDatePicker, OmisTextBox } from "../../shared/OmisInputs";
import { OmisLabel } from "../../shared/OmisDisplays";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreatePublicHoliday(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const [states, setStates] = useState([]);
    const [initialValues, setInitialValues] = useState({
        id: props.id ? props.id : null,
        name: props.rowVals ? props.rowVals.name : null,
        state: "",
        date: props.rowVals ? props.rowVals.date : "",
    });

    useEffect(() => {
        httpclientService.get("/api/settings/settingsobjects/getstates").then((response) => {
            if (response) {
                setStates(response);
            }
        });
    }, []);

    useEffect(() => {
        if (states.length > 0 && props.rowVals?.federalstateid) {
            var tmp = { ...initialValues };
            tmp.state = props.rowVals.federalstateid;
            setInitialValues(tmp);
        }
    }, [states]);

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsobjects/savepublicholiday", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate("/settings/objects/publicholidays");
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate("/settings/objects/publicholidays");
        }
    }

    const [schema, setSchema] = useState({
        name: Yup.string()
            .required(t("GenericRequiredText"))
            .max(100, t('Error_TextTooLong', { 1: 100 })),
        date: Yup.date()
            .required(t("GenericRequiredText"))
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("New_PublicHoliday")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisTextBox labeltext={t("Name")} name="name" required />
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisDropdown labeltext={t("Address_FederalState")} name="state" items={states} />
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisLabel label={t("Date")} required />
                                    <OmisDatePicker name="date" />
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}