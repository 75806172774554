import React, { useState } from "react";
import { t } from "i18next";
import { OmisCol, OmisRow, OmisStack } from "../../shared/OmisLayouts";
import { OmisObjectChip } from "../../shared/OmisChips";
import { Check as CheckIcon } from '@mui/icons-material';
import { Card, Link } from "@mui/material";
import { OmisDialog } from "../../shared/OmisDisplays";

export function UserObjects(props) {

    const [listLimit, setListLimit] = useState(3);

    const [chipModalOpen, setChipModalOpen] = useState(false);

    const handleChipClickOpen = () => {
        setChipModalOpen(true);
    };

    const handleChipClose = () => {
        setChipModalOpen(false);
    };

    return (
        props.data?.length > 0 ?
            <>
                <br />
                <OmisStack direction="horizontal" gap={5}>
                    <OmisRow className="gy-5">
                        <OmisCol xs={12}>
                            <strong>{t("Objects")}</strong>
                        </OmisCol>
                    </OmisRow>

                    <div className="ms-auto">
                        {props.data && props.data.length > listLimit ?
                            <Link
                                component="button"
                                underline="none"
                                variant="body2"
                                onClick={handleChipClickOpen}
                            > {t("ViewAllObjects")} {"->"} {`(${props.data.length})`}
                            </Link> : null}
                    </div>
                </OmisStack>
                <OmisRow>
                    <OmisCol xs={12}>
                        <Card variant={"outlined"}>
                            <UserObjectsList shortList={true} listLimit={props.listLimit} {...props} />
                        </Card>
                    </OmisCol>
                </OmisRow>

                <OmisDialog onClose={handleChipClose} open={chipModalOpen}
                    title={t("Objects")}
                    content={
                        <UserObjectsList shortList={false} {...props} />}
                    maxWidth={"md"}
                />
            </>
            :
            null
    );
}

export function UserObjectsList(props) {
    var limit = props.shortList ? props.listLimit : (props.data ? props.data.length : 0);

    return (
        props.data && props.data.length > 0 ?
            <>
                <OmisRow>
                    <OmisCol xs={3}>
                        <strong>{t("Object")}</strong>
                    </OmisCol>
                    <OmisCol xs={3}>
                        <strong>{t("Settings_ContactPersons")}</strong>
                    </OmisCol>
                </OmisRow>
                {
                    props.data.map((item, index) => index < limit ?

                        <OmisRow key={item.objectID}>
                            <OmisCol xs={3}>
                                <OmisObjectChip text={item.objectID} objectnrinternal={item.objectnrinternal} objectid={item.objectID} />
                            </OmisCol>
                            <OmisCol xs={3}>
                                <p className={"userProfilCardContentText3 bold"}><CheckIcon color={"success"} /></p>
                            </OmisCol>
                        </OmisRow>
                        :
                        null
                    )
                }

            </>
            :
            null
    );
}