import React, { useRef, useState } from "react";
import httpclientService from "../../services/httpclient.service";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { t } from "i18next";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit } from "../shared/OmisButtons";
import { OmisButtonSecondary } from "../shared/OmisButtons";
import { OmisDateTimePicker } from "../shared/OmisInputs";
import { OmisLabel } from "../shared/OmisDisplays";
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";

var api = "/api/profile/";

export default function EditHolidayReplacement(props) {

    const navigate = useNavigate();
    const selectedTab = { selectedTab: 2 };

    const formRef = useRef();
    const [initialValues, setInitialValues] = useState({
        replacementID: props.id,
        fromDate: props.rowVals?.fromdate ?? null,
        toDate: props.rowVals?.todate ?? null,
    });

    function saveReplacement(values) {
        httpclientService.post(`${api}edituserreplacement`, JSON.stringify(values)).then((response) => {
            if (response) {
                if (props.handleModalClose) {
                    props.handleModalClose();
                } else {
                    navigate("/users", { replace: true, state: selectedTab });
                }
            }
        });
    }

    const validationSchema = Yup.object({
        replacementID: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        fromDate: Yup.date()
            .required(t("GenericRequiredText")),
        toDate: Yup.date()
            .required(t("GenericRequiredText")),
    });

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                    saveReplacement(values)
                }}
            >
                {formik => (
                    <Form>


                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisLabel label={`${t("From")} ${t("User")}: ${props.rowVals?.fromuser}`} />
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisLabel label={`${t("To")} ${t("User")}: ${props.rowVals?.replacementuser}`} />
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <br />
                                    <OmisDateTimePicker label={t("BeginDate")} name="fromDate" /> 
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <br />
                                    <OmisDateTimePicker label={t("EndDate")} name="toDate" />
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    {
                                        props.handleModalCancel ?
                                            <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={props.handleModalCancel}></OmisButtonSecondary>
                                            :
                                            <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} component={RouterLink} to={"/users"}></OmisButtonSecondary>
                                    }
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>

                        
                    </Form>)}
            </Formik>
        </>
    );
};