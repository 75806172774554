import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import httpclientService from "../../../services/httpclient.service";
import { OmisButtonSecondary, OmisIconButton } from "../../shared/OmisButtons";
import { OmisGrid } from "../../shared/OmisGrids";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function SettingsMissingNewEquiAttributes(props) {
    var url = "/api/settings/settingsfsmintern/getmissingnewequiattributes";
    const navigate = useNavigate();

    const [changeCounter, setChangeCounter] = useState(0);
    const [selectedRowIDs, setSelectedRowIDs] = useState([]);

    const handleSaveSelections = () => {
        httpclientService.post(`/api/settings/settingsfsmintern/saveselections`, selectedRowIDs).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    }

    const handleSaveAll = () => {
        httpclientService.post(`/api/settings/settingsfsmintern/saveallmissing`).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    }

    return (
        <OmisContainer fluid>

            <OmisRow>
                <OmisCol xs={1}>
                    <OmisIconButton id={"backButton"} iconcontent={
                        <ArrowBackIcon fontSize="inherit" />} text="Back" onClick={() => { navigate(-1); }} />
                </OmisCol>

                <OmisCol xs={2}>
                    <OmisButtonSecondary id={"saveAllButton"} text="Save All" onClick={handleSaveAll} />
                </OmisCol>

                <OmisCol xs={2}>
                    <OmisButtonSecondary id={"saveSelectionsButton"} text="Save Selections" onClick={handleSaveSelections} />
                </OmisCol>
            </OmisRow>

            <OmisGrid
                apiUrl={url}
                change={changeCounter}
                showCheckBox={true}
                rowSelectionCallback={setSelectedRowIDs}
                checkedRows={selectedRowIDs}
                noExportButton
                noHeader
                {...props} />

        </OmisContainer>
    );
}