import React from "react";
import { t } from "i18next";
import { Link as RouterLink } from "react-router-dom";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisIconButton } from "../../shared/OmisButtons";
import { OmisGrid } from "../../shared/OmisGrids";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { OmisCol, OmisContainer } from "../../shared/OmisLayouts";

export function SettingsDmsRights(props) {
    var url = "/api/settings/settingsfsmintern/getdmsrights";

    return (
        <OmisContainer fluid>

            <div className=" omis-header-box">
                <OmisCol xs={1}>
                    <OmisIconButton id={"backButton"}
                        iconcontent={<ArrowBackIcon fontSize="inherit" />}
                        text="Back" component={RouterLink} to={"/settings"} state={{ selectedtabvalue: TabConstants.FSMIntern }} />
                </OmisCol>

                <OmisGrid headerText={t("DMSSettings")}
                    apiUrl={url}
                    noExportButton
                    {...props} />
            </div>
        </OmisContainer>
    );
}