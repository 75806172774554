import React, { useState } from "react";
import { t } from "i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
    mdiArchive, //mdiBell, mdiFileDocumentMultiple,
    mdiFlagVariant,
    mdiFileSign ,
    //mdiHistory, mdiInboxArrowDown,
    mdiInboxFull,
    //mdiPencil
} from "@mdi/js";
import { OmisButtonNewItem } from "../shared/OmisButtons";
import { OmisGrid } from "../shared/OmisGrids";
import HttpClientService from "../../services/httpclient.service";
import { OmisDialog } from "../shared/OmisDisplays";
import { InspectionsCreate } from "./InspectionsCreate";
import { DischargeTestDate } from "./DischargeTestDate";
import { InspectionArchivePopup } from "./InspectionArchivePopup";

export default function Inspections(props) {
    var url = "/api/inspections/getinspectionslist";
    const navigate = useNavigate();

    const [modalHeight, setModalHeight] = useState(500);
    const [modalWidth, setModalWidth] = useState("md");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    if (props.objectid) {
        url = `${url}?objectid=${props.objectid}`;
    }

    if (props.equipid) {
        url = `${url}?equipid=${props.equipid}`;
    }


    function handleStartInspection(rowid) {
        navigate("/inspections/inspectioncontrol/", { replace: true, state: { inspectionid: rowid } });
    }

    function handleGenerateReport(rowid, rowVals) {
        navigate("/reports/create/", { replace: true, state: { objectid: rowVals?.objectid, objectnrinternal: rowVals?.objectnrinternal } });
    }

    function handleArchivTestDate(value) {

        var values = Array.isArray(value) ? value : [value];
        handleModalOpenForDropdownMenuActions(t("Action_Archive"), <InspectionArchivePopup ids={values} handleModalCancel={handleModalCancel} onSubmit={handleArchivConfirmed} />);
        
    }

    function handleArchivConfirmed(values) {
        HttpClientService.post(`/api/inspections/archivtestdate`, JSON.stringify(values)).then((response) => {
            if (response) {
                handleModalClose();
            }
        });
    }

    function handleDischargeTestDate(value) {
        var values = Array.isArray(value) ? value : [value];
        handleModalOpenForDropdownMenuActions(t("Inspection_Discharged"), <DischargeTestDate ids={values} handleModalCancel={handleModalClose} handleModalClose={handleModalClose} />);
    }

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    const handleModalOpenForDropdownMenuActions = (title, content, e) => {
        var modalwidth = "md";
        if (title === t("Action_Edit")) {
            modalwidth = "xl";
            content = <InspectionsCreate handleModalCancel={handleModalCancel} handleModalClose={handleModalClose} tdid={e} />;
            setModalHeight(800);
        } else if (title === t("Inspection_Discharged")) {
            modalwidth = "lg";
        } else {
            setModalHeight(500);
        }

        setModalWidth(modalwidth);
        setModalTitle(title);
        setModalContent(content);
        handleModalOpen();
    };

    const subMenuItems = [
        //{
        //    action: handleAddDocumentClick,
        //    iconpath: mdiInboxArrowDown,
        //    icontext: "Download result",
        //    disableItem: false
        //},
        {
            action: handleGenerateReport,
            iconpath: mdiInboxFull,
            icontext: t("Generate_Report"),
        },
    ]

    const dropDownMenuItems = [
        {
            action: handleStartInspection,
            iconpath: mdiFlagVariant,
            icontext: t("FillIn"),
            disableMultiSelect: true,
            checklistaction:true
        },
        //{
        //    action: null,
        //    iconpath: mdiHistory,
        //    icontext: "Last inspection...",
        //    expand: false,
        //    subMenuItems: subMenuItems,
        //    disableMultiSelect: true,
        //    disableItem: false
        //},
        //{
        //    action: () => { console.log("dowload checklist") },
        //    iconpath: mdiInboxArrowDown,
        //    icontext: "Download checklist",
        //    expand: false,
        //    subMenuItems: null,
        //    disableItem: false
        //},
        //{
        //    action: null,
        //    iconpath: mdiPencil,
        //    icontext: "Edit inspection", //TODO
        //    disableItem: false
        //},
        //{
        //    action: null,
        //    iconpath: mdiFileDocumentMultiple,
        //    icontext: "Duplicate",//TODO
        //    disableItem: false
        //},
        //{
        //    action: null,
        //    iconpath: mdiInboxFull,
        //    icontext: "Add to project", //TODO
        //    disableItem: false
        //},
        //{
        //    action: () => { console.log("SetReminder") },
        //    iconpath: mdiBell,
        //    icontext: "Set Reminder",
        //    expand: false,
        //    subMenuItems: null,
        //    disableItem: false
        //},
        {
            action: handleDischargeTestDate,
            iconpath: mdiFileSign,
            icontext: t("Inspection_Discharged"),
            multiactionpossible: true
        },
        {
            action: handleArchivTestDate,
            iconpath: mdiArchive,
            icontext: t("Action_Archive"),
            multiactionpossible: true
        },
    ]

    return (
        <>
            <OmisGrid
                headerText={t("Settings_TestDates")}
                apiUrl={url}
                showCheckBox={true}
                inspectionsgrid
                actiondropdown={{ dropDownMenuItems: dropDownMenuItems, edit: handleModalOpenForDropdownMenuActions.bind(this, t("Action_Edit"), null) }}
                withPlannedDate={true}
                withExecutionDate={true}
                objectsDropDownHasItems={[]}
                newButton={<OmisButtonNewItem id={"newInspectionButton"} component={RouterLink} to={"/inspections/create/"} text={t("Action_New")} />}
                change={changeCounter}
                {...props}
            />

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={modalWidth}
                title={modalTitle}
                contentHeight={modalHeight}
                content={modalContent}
            />
        </>
    );
}