import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { useLocation } from "react-router-dom";
import { mdiAccountGroup, mdiAccount, mdiAccountSwitch } from '@mdi/js';
import { OmisDialog } from '../shared/OmisDisplays';
import { OmisTabsWithIcon } from '../shared/OmisLayouts';
import { Tab_UsersList } from "./UsersList";
import { Tab_Groups } from "./Groups";
import { HolidayReplacementList } from "./HolidayReplacementList";

export default function Users(props) {

    return (
        <>
            <HeaderPanel />
        </>
    );
}

export function HeaderPanel(props) {
    var { state } = useLocation();

    const [iconPosition, setIconPosition] = useState("start");
    const [tabVal, setTabVal] = useState(0);
    const [modalTitleForData, setModalTitleForData] = useState("");
    const [modalOpenForData, setModalOpenForData] = useState(false);
    const [modalContentForData, setModelContentForData] = useState(null);

    useEffect(() => {
        if (state && state.selectedTab) {
            setTabVal(state.selectedTab);
        }
    }, []);

    const handleTabValueChange = (selectedTab) => {
        setTabVal(selectedTab);
    }

    var viewtabs = [
        {
            title: t("All_Users"),
            iconpath: mdiAccount,
            iconposition: iconPosition,
            content: <Tab_UsersList />
        },
        {
            title: t("Settings_UserGroup"),
            iconpath: mdiAccountGroup,
            iconposition: iconPosition,
            content:<Tab_Groups {...props} /> 
        },
        {
            title: t("HolidayReplacement_Title"),
            iconpath: mdiAccountSwitch,
            iconposition: iconPosition,
            content: <HolidayReplacementList />
        }
    ]

    function handleModalOpenForData(modalContent, title, event) {
        setModalTitleForData(title);
        setModelContentForData(modalContent);
        setModalOpenForData(true);
    }

    function handleModalCloseForData() {
        setModelContentForData(null);
        setModalOpenForData(false);
    }

    return (
        <div className="omis-header-box">
            <OmisTabsWithIcon tabvalue={tabVal} onChange={handleTabValueChange} tabs={viewtabs} />

            <OmisDialog onClose={handleModalCloseForData} open={modalOpenForData}
                fullWidth={true}
                maxWidth={'lg'}
                title={modalTitleForData}
                content={modalContentForData}
            />
		</div>);
}
