import React, { useEffect, useState } from "react";
import { t } from "i18next";
import httpclientService from "../../../services/httpclient.service";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { TabConstants } from "../../../constants/SettingConstants";
import { ArrowBack as ArrowBackIcon, Clear as ClearIcon, Person as PersonIcon, Groups as GroupIcon } from "@mui/icons-material";
import { RenderUsersList } from "../../users/GroupDetails";
import { OmisGroupChip } from "../../shared/OmisChips";
import { OmisDialog, OmisLabel } from "../../shared/OmisDisplays";
import { SettingsRoles_Assign } from "./SettingsRoles_Assign";
import { OmisCol, OmisRow } from "../../shared/OmisLayouts";
import { OmisButtonNewItem, OmisIconButton } from "../../shared/OmisButtons";

var state = { selectedtabvalue: TabConstants.System }
var url = "/api/settings/system/";

export function SettingsRoles_Details(props) {
    var { state } = useLocation();
    const [groupsList, setGroupsList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [modalWidth, setModalWidth] = useState("lg");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    useEffect(() => {
        LoadList();
    }, [changeCounter]);

    function LoadList() {
        httpclientService.get(`${url}getroleassignedgroups?roleid=${state?.id}`).then((response) => {
            if (response) {
                setGroupsList(response);
            }
        });

        httpclientService.get(`${url}getroleassignedusers?roleid=${state?.id}`).then((response) => {
            if (response) {
                setUsersList(response);
            }
        });
    }

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    function handleAddClick(type) {
        var title="",isUser=false;
        switch (type) {
            case "group":
                title = t("AssignToGroup");
                break;
            case "user":
                isUser = true;
                title = t("AssignToUser");
                break;
        }
        
        setModalContent(<SettingsRoles_Assign user={isUser} roleid={state?.id ?? 0} handleModalClose={handleModalClose} handleModalCancel={handleModalCancel} />);
        setModalTitle(title);
        handleModalOpen();
    }

    function handleRemoveFromRole(assignmenttype,id) {
        httpclientService.post(`${url}deleteassignedrole?assignmenttype=${assignmenttype}&roleid=${state?.id}&id=${id}`).then((response) => {
            if (response) {
                setChangeCounter((prevState) => prevState + 1);
            }
        });
    }

    return (
        <div className="omis-header-box">
            <OmisRow>
                <OmisCol xs={1}>
                    <OmisIconButton id={"backButton"} className="darkColor" iconcontent={<ArrowBackIcon fontSize="inherit" />} text="Back" component={RouterLink} to={"/settings/system/roles"} />
                </OmisCol>
                <OmisCol xs={3}>
                    <h3>{state?.name}</h3>
                </OmisCol>
            </OmisRow>

            <hr />
            <OmisRow>
                <OmisCol xs={2}>
                    <strong>{t("Settings_UserGroup")}</strong>
                </OmisCol>
                <OmisCol xs={2}><OmisButtonNewItem id={"assignGroupRoleButton"} text={t("AssignToGroup")} onClick={handleAddClick.bind(this, "group")} sx={{ width: 170 }} />
                </OmisCol>
            </OmisRow>
            {
                groupsList.length > 0 ?
                    <OmisRow>
                        <>
                            <OmisRow>
                                <OmisCol xs={4}>
                                    <p>{t("Description")}</p>
                                </OmisCol>
                            </OmisRow>
                            {
                                groupsList.map((d, index) =>
                                (
                                    <OmisRow>
                                        <OmisCol xs={4}>
                                            <OmisGroupChip fromUserIndex key={d.id} text={d.description} id={d.id} selectedTab={0} />
                                        </OmisCol>
                                        <OmisCol xs={1} container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                            <OmisIconButton id={"removeGroupRoleButton"} iconcontent={<ClearIcon />} onClick={handleRemoveFromRole.bind(this,"group", d.id)} />
                                        </OmisCol>
                                    </OmisRow>    
                                )
                                )
                            }
                        </>
                    </OmisRow>
                    :
                    <OmisLabel label={t("Grid_Empty")} />
            }
            <br />
            <hr />
            <br />
            <OmisRow>
                <OmisCol xs={2}>
                    <strong>{t("User")}</strong>
                </OmisCol>
                <OmisCol xs={2}>
                    <OmisButtonNewItem id={"assignUserRoleButton"} text={t("AssignToUser")} onClick={handleAddClick.bind(this, "user")} sx={{ width: 170 }} />
                </OmisCol>
            </OmisRow>
            
            {
                usersList.length > 0 ?
                    <OmisCol xs={12}>
                        {
                            <RenderUsersList shortList={false} data={usersList} showaddress hasbutton={<ClearIcon />} onClick={handleRemoveFromRole.bind(this,"user")} />
                        }
                    </OmisCol>
                    :
                    <OmisLabel label={t("Grid_Empty") } />
            }

            <br />
            <hr />
            <OmisRow>
                <OmisCol xs={7}>
                    <PersonIcon /> = {t("Role_TypeUser")}
                </OmisCol>
                <OmisCol xs={7}>
                    <GroupIcon /> = {t("Role_TypeGroup")}
                </OmisCol>
            </OmisRow>

            <OmisDialog onClose={handleModalCancel} open={modalOpen} maxWidth={modalWidth} title={modalTitle} content={modalContent} />
        </div>
    );
}