import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisTextBox } from "../../shared/OmisInputs";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateSkill(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const [initialValues, setInitialValues] = useState({
        id:  props.id ?? null,
        description: props.rowVals?.description ?? '',
        companySiteIDs: props.companySiteIDs ?? null,
    });

    useEffect(() => {
        if (props.id && props.id>0) {
            httpclientService.get(`/api/settings/companysite/getskill?id=${props.id}`).then((response) => {
                if (response) {
                    setInitialValues({
                        id: response.id,
                        description: response.description ?? '',
                        companySiteIDs: props.companySiteIDs ?? null,
                    });
                }
            });
        }
    },[]);

    function handleSave(values) {
        httpclientService.post("/api/settings/companysite/saveskill", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate("/settings/companysite/skills");
                }
            }
        });
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate("/settings/companysite/skills");
        }
    }

    const [schema, setSchema] = useState({
        description: Yup.string()
            .required(t("GenericRequiredText"))
            .max(128, t('Error_TextTooLong', { 1: 128 })),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("New_Skill")}</h1>
                                :
                                null
                        }

                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={6 }>
                                    <OmisTextBox labeltext={t("Description")} name={"description"} placeholder={t("Description")} required />
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}