import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardActions, CardActionArea, CardContent, Dialog, DialogTitle, IconButton, InputLabel, Tooltip } from "@mui/material";
import Badge from '@mui/material/Badge';
import Moment from "react-moment";
import { Close as CloseIcon, CheckCircle, HighlightOff } from "@mui/icons-material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {OmisButtonDiscard, OmisButtonSecondary} from "./OmisButtons";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next"; 
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronRight } from "@mdi/js";
import DateHelperService from "../../services/datehelper.service";
import { OmisCol, OmisRow, OmisStack } from "./OmisLayouts";


export function OmisLabel(props) {
    return (
        props.tooltip ?
            <Tooltip title={props.tooltip}>
                <InputLabel htmlFor={props.id || props.name} className="omis-label-normal omisInputLabels" {...props}>{props.label}</InputLabel>
            </Tooltip>
            :
            <InputLabel htmlFor={props.id || props.name} className="omis-label-normal omisInputLabels" {...props } >{props.label}</InputLabel>
    );
}

export function OmisErrorMessage(props) {
    if (props.meta) {
        return (
            <span>
            {props.meta.touched && props.meta.error ? (<div className="text-danger"><b>{props.meta.error}</b></div>) : null}
        </span>
        );
    }

    return (
        <span>
            <div className="text-danger"><b>{props.text}</b></div>
        </span>
    );
};

export function OmisDate(props) {
    //https://www.npmjs.com/package/react-moment
    return (
        <Moment {...props} format={"DD MMM yyyy"} date={props.date} /> //TODO: get format from locale
    );
}

export function OmisDateTime(props) {
    //https://www.npmjs.com/package/react-moment
    return (
        <Moment {...props} format={"DD MMM yyyy - HH:mm"} date={props.date} /> //TODO: get format from locale
    );
}

export function OmisDateTimeForGrid(props) {
    //https://www.npmjs.com/package/react-moment
    return (
        <Moment format={"DD.MM.YYYY HH:mm"} date={props.date} /> //TODO: get format from locale
    );
}

export function OmisTime(props) {
    //https://www.npmjs.com/package/react-moment
    return (
        <Moment {...props} format={"HH:mm"} date={props.date} /> //TODO: get format from locale
    );
}

export function OmisTimeGap(props) {
    return DateHelperService.IsToday(new Date(props.date)) ?
        (
            //https://www.npmjs.com/package/react-moment
            <Moment {...props} fromNow>{props.date}</Moment> //TODO: get time gap from now
        )
        :
        (
            //https://www.npmjs.com/package/react-moment
            <OmisDateTime {...props} />
        )  
}

export function OmisTimeGapWithWords(props) {
        //TODO: add t() translations
        const calendarStrings = {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            lastWeek: '[last] dddd',
            nextWeek: 'dddd',
            sameElse: 'L'
        };

        return (
            <Moment calendar={calendarStrings}>
                {props.date}
            </Moment>
        );
    
}

export function OmisBox(props) {
    return (
        <Box {...props} className={"omis-box " + (props.className ? props.className : "")} onClick={props.onClick}>
            {props.children}
        </Box>
    );
}

export function OmisDialog(props) {
    function BootstrapDialogTitle(props) {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children ?? null}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    }

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") //Andreas wanted pop up not to close when mouse pointer clicked out of popup
            return;
        props.onClose();
    }
    return (
        <Dialog onClose={handleClose} fullWidth={true} maxWidth={props.maxWidth} aria-labelledby="customized-dialog-title" open={props.open}>
            <BootstrapDialogTitle className={"omis-dialog-title"} id="customized-dialog-title" onClose={props.onClose}>
                {props.title}
            </BootstrapDialogTitle>
            <DialogContent sx={{ height: props.contentHeight ? props.contentHeight : "auto;" }}>
                {props.content}
            </DialogContent>
            {
                props.actions ?
                    <>
                        <DialogActions>
                            {props.actions}
                        </DialogActions>
                    </> :
                    null
            }
        </Dialog>
    );
}

export function OmisDeleteQuestionDialog(props) {
    const { t } = useTranslation();

    return (
        <OmisDialog onClose={props.handleModalClose} open={props.open}
                    title={t("Warning_Title")}
                    content={
                        <>
                            <OmisRow>
                                <OmisCol xs={12}>
                                    {t("Common_Delete_Question")}
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <DialogActions>
                                    <OmisButtonDiscard onClick={props.deleteFunction} text={t("Action_Delete")}></OmisButtonDiscard>
                                    <OmisButtonSecondary text={t("Action_Cancel")} onClick={props.handleModalClose}></OmisButtonSecondary>
                                </DialogActions>
                            </OmisRow>
                        </>
                    }
        />
    );
}

export function OmisBulkActionQuestionDialog(props) {
    const { t } = useTranslation();
    return (
        <>
            <OmisRow>
                <OmisCol xs={12}>
                    {t("Common_MultiRow_Actions_Question", { 0: props.ids })}
                </OmisCol>
            </OmisRow>
            <OmisRow>
                <DialogActions>
                    <OmisButtonDiscard id="actionCallbackButton" onClick={props.actionCallbackFunction} text={t("Action_Finish")}></OmisButtonDiscard>
                    <OmisButtonSecondary id="cancelButton" text={t("Action_Cancel")} onClick={props.handleModalCancel}></OmisButtonSecondary>
                </DialogActions>
            </OmisRow>
        </>
    );
}

export function OmisBadge(props) {

    const divStyle = {
        marginLeft: 0
    };

    var StyledBadge = styled(Badge)({
        "& .MuiBadge-badge": {
            right: 125,
            top: 9,
            padding: '0 4px',
            color: "white",
            backgroundColor: props.backgroundColor ? props.backgroundColor:"gray"
        }
    });

    if (props.badgeSide === "rightTop") {
        StyledBadge = styled(Badge)({
            "& .MuiBadge-badge": {
                top: -15,
                color: props.textColor ? props.textColor : "white",
                backgroundColor: props.backgroundColor ? props.backgroundColor : "gray"
            }
        });
        divStyle.marginLeft = 2;
        divStyle.marginTop = 10;
    }

    return (
        <div id={"badgeDiv"} style={divStyle} >
            <StyledBadge badgeContent={props.text} >
                {props.content}
            </StyledBadge>
        </div>
    );
}

export function OmisBadgeForGridTitle(props) {

    const divStyle = {
        marginLeft: 0
    };

    var StyledBadge = styled(Badge)({
        "& .MuiBadge-badge": {
            right: 15,
            top: -5,
            padding: '0 4px',
            color: "white",
            backgroundColor: props.backgroundColor ? props.backgroundColor : "gray"
        }
    });

    if (props.badgeSide === "rightTop") {
        StyledBadge = styled(Badge)({
            "& .MuiBadge-badge": {
                top: -15,
                color: props.textColor ? props.textColor : "white",
                backgroundColor: props.backgroundColor ? props.backgroundColor : "gray"
            }
        });
        divStyle.marginLeft = 7;
    }

    return (
        <OmisCol xs={12} style={{ display: 'flex', justifyContent: 'left', alignItems: "center", marginLeft: '1.5em' }}>
            <StyledBadge badgeContent={props.text} />
            {props.content}
        </OmisCol>
    );
}

export function OmisCollapseCard(props) {
    const [expand, setExpand] = useState();

    useEffect(() => {
        if (props.expand !== undefined) {
            setExpand(props.expand);
        }
    }, [props.expand]);

    return (
        <>
            <Card variant={"outlined"} sx={{ height: "100%;", width: "100% !important;"}}>
                <CardActions>
                    <OmisRow style={{ display: 'flex', alignItems: 'center' }}>
                        <OmisCol xs={11} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>{props.buttonRowContent}</OmisCol>
                        <OmisCol xs={1} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }} >
                            {
                                props.hasAdditionalButton ?
                                    <OmisRow>
                                        <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                            {props.children ?
                                                <Button onClick={() => setExpand(!expand)}>
                                                    <Icon path={expand ? mdiChevronDown : mdiChevronRight} size={1}></Icon>
                                                </Button>
                                                : null}
                                        </OmisCol>
                                        <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {props.hasAdditionalButton }
                                        </OmisCol>
                                    </OmisRow>
                                    :
                                    props.children ?
                                        <Button className="userProfilCardCollapse" onClick={() => setExpand(!expand)}>
                                            <Icon path={expand ? mdiChevronDown : mdiChevronRight} size={1}></Icon>
                                        </Button>
                                        : null 
                             
                            }
                        </OmisCol>                                                
                    </OmisRow>
                </CardActions>
                {
                    expand ?
                        props.children
                        :
                        null
                }
                {/*<OmisCollapse in={expand}>*/}
                    
                {/*</OmisCollapse>*/}
            </Card>
        </>
    );
}

export function OmisSelectedCatalogueCard(props) {
    return (
        <OmisCol xs={12}>
            <Card onClick={() => {
                if (!props.disabled) {
                    props.handleDeselect();
                }
            }}>
                <CardActionArea>
                    <CardContent>
                        <OmisRow className="justify-content-md-center">
                            <OmisStack direction="horizontal" gap={3}>
                                <OmisCol xs={1}>
                                    {
                                        props.id ?
                                            <CheckCircle color={"success"} />
                                            :
                                            <HighlightOff color={"error"} />
                                    }
                                </OmisCol>
                                <OmisCol xs={11}>
                                    <OmisLabel label={props.text} required={props.required} />
                                </OmisCol>
                            </OmisStack>
                        </OmisRow>
                    </CardContent>
                </CardActionArea>
            </Card>
        </OmisCol>);
}

export function ConvertDateStringToDatePickerFormat(date) {
    var startVal = 0, maxLength = 16;
    //Date format should be "yyyy-MM-ddTHH:mm"
    //toISOString() returns with seconds ('yyyy-MM-ddTHH:mm:ss.fffZ') that's why substring used here
    return `${new Date(date).toISOString().substring(startVal, maxLength)}`
}