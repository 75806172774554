import React, { useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisCheckBox, OmisTextBox } from "../../shared/OmisInputs";
import AuthService from "../../../services/auth.service";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateEquiClass(props) {

    var { state } = useLocation();
    const navigate = useNavigate();
    const formRef = useRef();
    const [initialValues, setInitialValues] = useState({
        id: props.id ? props.id : null,
        description: props.rowVals?.description ?? "",
        equiClass: props.rowVals?.equiClass ?? "",
        faultEquiClassDefID: props.rowVals?.faultEquiClassDefID ?? state?.faultEquiClassDefID ?? null,
        customerEditable: props.rowVals?.customerEditable === false ? false : true,
    });

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsequipments/saveequiclass", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose(values);
                } else {
                    navigate(-1);
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(-1);
        }
    }

    const [schema, setSchema] = useState({
        description: Yup.string()
            .required(t("GenericRequiredText"))
            .max(100, t('Error_TextTooLong', { 1: 100 })),
        equiClass: Yup.string()
            .required(t("GenericRequiredText"))
            .max(20, t('Error_TextTooLong', { 1: 20 })),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("New_EquiClass")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={t("EquiClass")} name="equiClass" required />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisTextBox labeltext={t("Description")} name="description" required />
                                </OmisCol>
                                {
                                    AuthService.isFSMMaster() ?
                                        <OmisCol xs={4}>
                                        <br/>
                                            <OmisCheckBox labeltext={t("CustomerEditable")} name="customerEditable" />
                                        </OmisCol>
                                        :
                                        null
                                }
                            </OmisRow>
                            <br/>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}