import React, { useEffect, useState } from "react";
import { Card, CardActionArea, CardContent, CardHeader } from "@mui/material";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import {useTranslation} from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import {OmisCol, OmisRow, OmisTabs} from "../shared/OmisLayouts";
import AuthService from "../../services/auth.service";

export default function Settings(props) {
    const { t } = useTranslation();
    var { state } = useLocation();

    const [searchText, setSearchText] = useState('');
    const [selectedTabValue, setSelectedTabValue] = useState(0);

    useEffect(() => {
        if (state && state.selectedtabvalue) {
            if (/[0-9]/.test(state.selectedtabvalue)) {
                setSelectedTabValue(parseInt(state.selectedtabvalue));
            } else {
                setSelectedTabValue(0);
            }
        }
    }, []);

    function tabChanged(e) {
        setSelectedTabValue(e);
    }

    const settingstabs = [
        {
            title:  t("System") ,
            content: <TabContent_System searchText={searchText}/>
        },
        {
            title: t("Objects"),
            content: <TabContent_Objects searchText={searchText}/>
        },
        {
            title: t("Equipment"),
            content: <TabContent_Equipment searchText={searchText}/>
        },
        { 
            title: t("Contractors"),
            content: <TabContent_CompanySites searchText={searchText} />
        },
        {
            title: t("FaultReports"),
            content: <TabContent_Reports searchText={searchText}/>
        },
        {
            title: t("Interface_Console"),
            content: <TabContent_InterfaceConsole searchText={searchText}/>
        },
        {
            title: "Automation",
            content: <TabContent_Automation searchText={searchText}/>
        },
        {
            title: "Templates",
            content: <TabContent_Templates searchText={searchText}/>
        },
        {
            title: t("Settings_Miscellaneous"),
            content: <TabContent_Miscellaneous searchText={searchText} />
        },
        {
            title: t("Settings_FSMInternal"),
            content: <TabContent_FSMInternal searchText={searchText} />
        }
    ]

    return (
        <>
			<div className="omis-header-box">
				{/*<OmisTextBoxNoForm placeholder={t("Action_Search")} value={searchText} onChange={(event) => setSearchText(event.target.value)}/>*/}
				<OmisTabs tabs={settingstabs} onChange={tabChanged} tabvalue={selectedTabValue} />
			</div>
        </>
    );
}

function TabContent_System(props) {
    const {t} = useTranslation();

    var cardList = [
        {
            title: t("SysParameter"),
            url: "/settings/sysparameter",
            description: `${t("SettingsSysParameterText")}`
        },
        {
            title: t("SysParameter_Cust"),
            url: "/settings/sysparametercust",
            description: `${t("SettingsSysParameterCustText")}`
        },
        {
            title: t("Settings_Cust"),
            url: "/settings/settingscust",
            description: `${t("SettingsSettingsCustText")}`
        },
        {
            title: "Wartungsfenster",
            url: "/settings/maintenance",
            description: `Wartungsfenster eintragen`
        },
        {
            title: "Verbindungstest",
            url: "/settings/connectiontest",
            description: `Email und VoiceCall Verbindung testen`
        },
        {
            title: t("Settings_Roles"),
            url: "/settings/system/roles",
            description: `${t("SettingsRolesText")}`
        },
        {
            title: t("PasswordPolicy"),
            url: "/settings/system/passwordpolicy",
            description: `${t("SettingsPasswordPolicyText")}`
        }
    ];

    if (AuthService.isFSMMaster()) {
        cardList = [
            ...cardList,
            {
                title: t("SettingsFilterDefs"),
                url: "/settings/system/filterdefs",
                description: t("SettingsFilterDefText")
            }
        ]
    }
    

    return (
        <>
            {
                cardList.map((c) => <SettingsCard key={c.title} searchText={props.searchText} title={c.title} url={c.url} description={c.description} />)
            }
        </>
    );
}

function TabContent_Objects(props) {
    const {t} = useTranslation();


    const cardList = [
        {
            title: t("Objects"),
            url: "/settings/objects/objects",
            description: `${t("SettingsObjectText")}`
        },
        {
            title: t("Regions"),
            url: "/settings/objects/regions",
            description: `${t("SettingsRegionText")}`
        },
        {
            title: t("Seasons"),
            url: "/settings/objects/seasons",
            description: `${t("SettingsSeasonText")}`
        },
        {
            title: t("Object_SalesArea"),
            url: "/settings/objects/salesarea",
            description: `${t("SettingsSalesAreaText")}`
        },
        {
            title: t("Settings_PublicHoliday"),
            url: "/settings/objects/publicholidays",
            description: `${t("SettingsPublicHolidayText")}`
        },
        {
            title: t("OperationKind"),
            url: "/settings/objects/operationkinds",
            description: `${t("SettingsOperationKindText")}`
        },
        //new
        {
            title: t("OperationLabel"),
            url: "/settings/objects/operationlabels",
            description: `${t("SettingsOperationLabelText")}`
        },
        {
            title: t("ObjectAttributes_Title"),
            url: "/settings/objects/objectattributes",
            description: `${t("SettingsObjectAttributeDevText")}`
        },
        {
            title: t("Object_TradeLevel"),
            url: "/settings/objects/objecttradelevels",
            description: `${t("SettingsTradeLevelText")}`
        },
        {
            title: t("ObjectWizard_Step4_Header"),
            url: "/settings/objects/objectcontactpersons",
            description: `${t("SettingsContactPersonText")}`
        },
        {
            title: t("Status"),
            url: "/settings/objects/objectstatus",
            description: `${t("SettingsStatusText")}`
        },
    ];

    return (
        <>
            {
                cardList.map((c) => <SettingsCard key={c.title} searchText={props.searchText} title={c.title} url={c.url} description={c.description} />)
            }
        </>
    );
}

function TabContent_Equipment(props) {
    const { i18n, t } = useTranslation();

    const cardList = [
        {
            title: t("EquiClasses"),
            url: "/settings/settingsequipments/equiclasses",
            description: t("SettingsEquiclassText")
        },
        {
            title: t("Equipment_Owner"),
            url: "/settings/settingsequipments/equiowners",
            description: t("SettingsEquiOwnerEdit")
        },
        {
            title: t("Status"),
            url: "/settings/settingsequipments/equipmentstatuses",
            description: t("SettingsStatusText")
        },
        {
            title: t("Settings_EquiContractors"),
            url: "/settings/settingsequipments/equipmentcontractors",
            description: t("SettingsEquiContractorsText")
        },
    ];

    return (
        <>
            {
                cardList.map((c) => <SettingsCard key={c.title} searchText={props.searchText} title={c.title} url={c.url} description={c.description} />)
            }
        </>
    );
}

function TabContent_CompanySites(props) {
    const { t } = useTranslation();

    const cardList = [
        {
            title: t("Contractors"),
            url: "/settings/companysite/contractors",
            description: t("SettingsContractorText")
        },
        {
            title: t("CompanySites"),
            url: "/settings/companysite/companysites",
            description: t("SettingsCompanysiteText")
        },
        {
            title: t("Skills"),
            url: "/settings/companysite/skills",
            description: t("SettingsSkillsText")
        },
        {
            title: t("Rating_Rating"),
            url: "/settings/companysite/ratings",
            description: t("SettingsRatingText")
        },
    ];

    return (
        <>
            {
                cardList.map((c) => <SettingsCard key={c.title} searchText={props.searchText} title={c.title} url={c.url} description={c.description} />)
            }
        </>
    );
}

function TabContent_Reports(props) {
    const { t } = useTranslation();

    const cardList = [
        {
            title: t("FaultReportKind"),
            url: "/settings/reports/faultreportkind",
            description: `${t("SettingsFaultReportKindText")}`
        },
        {
            title: t("FaultEquiClassGroups"),
            url: "/settings/reports/faultequiclassgroups",
            description: `${t("SettingsFaultEquiClassGroupText")}`
        },
        {
            title: t("FaultEquiClassDefs"),
            url: "/settings/reports/faultequiclassdef",
            description: `${t("SettingsFaultEquiClassDefText")}`
        },
        {
            title: t("FaultKinds"),
            url: "/settings/reports/faultkinds",
            description: `${t("SettingsFaultKindText")}`
        },
        {
            title: t("ReactTimeModel_Settings"),
            url: "/settings/reports/reacttimemodel",
            description: `${t("SettingsReactTimeModel")}`
        },
	{
            title: t("Severities"),
            url: "/settings/reports/severities",
            description: `${t("SettingsSeverityText")}`
        },
        {
            title: t("CostTypes"),
            url: "/settings/reports/costtypes",
            description: `${t("SettingsCostTypeText")}`
        },
        {
            title: t("CostCenter"),
            url: "/settings/reports/costcenters",
            description: `${t("CostCenter")}`
        },
        {
            title: t("MessagingParameter_Settings"),
            url: "/settings/reports/messagingparameters",
            description: `${t("SettingsMessagingParameterText")}`
        },
        {
            title: t("FaultAttributeDef"),
            url: "/settings/reports/faultattributedefs",
            description: `${t("FaultAttributeDef")}`
        },
        {
            title: t("VisibleStatesConfig"),
            url: "/settings/reports/visiblestatesconfig",
            description: `${t("SettingsVisibleStatesConfigText")}`
        },
    ];

    return (
        <>
            {
                cardList.map((c) => <SettingsCard key={c.title} searchText={props.searchText} title={c.title} url={c.url} description={c.description} />)
            }
        </>
    );
}

function TabContent_InterfaceConsole() {
    const {t} = useTranslation();

    return (
        <>
            {t("Interface_Console")}
        </>
    );
}

function TabContent_Automation() {
    const {t} = useTranslation();

    return (
        <>
            Automation
        </>
    );
}

function TabContent_Templates(props) {
    const {t} = useTranslation();

    const cardList = [
        {
            title: t("SettingsTextModules"),
            url: "/settings/texttemplates",
            description: `${t("SettingsTextModulesCreate")}, ${t("SettingsTextModulesEdit")}`
        },
        {
            title: "Inspection templates",
            url: "",
            description: "Beschreibungstext"
        },
        {
            title: t("TradeLevel"),
            url: "/settings/tradelevels",
            description: `${t("SettingsTradeLevelText")}`
        }
    ];

    return (
        <>
            {
                cardList.map((c) => <SettingsCard key={c.title} searchText={props.searchText} title={c.title} url={c.url} description={c.description}/>)
            }
        </>
    );
}

function TabContent_Miscellaneous(props) {
    const { t } = useTranslation();

    const cardList = [
        {
            title: t("Settings_Addresses"),
            url: "/settings/miscellaneous/addresses",
            description: t("SettingsAddressText")
        },
        {
            title: "EntityMapping",
            url: "/settings/miscellaneous/entitymappings",
            description: t("SettingsEntitymappingText")
        }
    ];

    return (
        <>
            {
                cardList.map((c) => <SettingsCard key={c.title} searchText={props.searchText} title={c.title} url={c.url} description={c.description} />)
            }
        </>
    );
}

function TabContent_FSMInternal(props) {
    const { t } = useTranslation();

    const cardList = [
        {
            title: t("UsageLog"),
            url: "/settings/settingsfsmintern/usagelogs",
            description: t("SettingsUsageLogText")
        },
        {
            title: t("SettingsDocumentRightsText"),
            url: "/settings/settingsfsmintern/dmsrights",
            description: t("SettingsDocumentRightsText")
        },
        {
            title: t("Settings_Mapping"),
            url: "/settings/settingsfsmintern/mappings",
        },
        {
            title: "Webservice Settings",
            url: "/settings/settingsfsmintern/webservicesettings",
        },
        {
            title: "Missing New Equi Attributes",
            url: "/settings/settingsfsmintern/missingnewequiattrs",
        },
        {
            title: "Memory Caches",
            url: "/settings/settingsfsmintern/memorycaches",
        },
    ];

    return (
        <>
            {
                cardList.map((c) => <SettingsCard key={c.title} searchText={props.searchText} title={c.title} url={c.url} description={c.description} />)
            }
        </>
    );
}

function SettingsCard(props) {
    return (
        <>
            {
                //TODO: how to hide tab if no visible cards in tab
                props.title?.toLowerCase().indexOf(props.searchText?.toLowerCase()) >= 0 || props.description?.toLowerCase().indexOf(props.searchText?.toLowerCase()) >= 0 ?
                    <Card className={"omis-box"}>
                        <CardActionArea component={Link} to={props.url}>
                            <CardHeader title={
                                <OmisRow>
                                    <OmisCol xs={11}>
                                        <h3>{props.title}</h3>
                                    </OmisCol>
                                    <OmisCol xs={1}>
                                        <Icon path={mdiChevronRight} size={1}></Icon>
                                    </OmisCol>
                                </OmisRow>
                            } />
                            <CardContent>
                                <p>{props.description}</p>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    : null
            }
        </>

    );
}