import React, { useEffect, useState } from "react";
import {Card, Chip, CircularProgress, MenuItem} from "@mui/material";
import Icon from "@mdi/react";
import { OmisCollapseCard, OmisDateTime, OmisDialog } from "../shared/OmisDisplays";
import { t } from "i18next";
import httpclientService from "../../services/httpclient.service";
import urlEncryptService from "../../services/urlencrypt.service";
import { OmisButtonPrimary, OmisDropDownWithDynamicComponents, OmisIconButton, OmisPriButtonWithStartIcon, OmisSecButtonWithStartIcon } from "../shared/OmisButtons";
import { mdiAccount, mdiArchive, mdiClipboardList, mdiCreditCardChip, mdiFileDocument, mdiFileDocumentMultiple, mdiFolderOutline, mdiInboxArrowUpOutline, mdiInboxFull, mdiInformationOutline, mdiLinkVariant, mdiPencil, mdiPlusBoxOutline, mdiTimerOutline, mdiAlertCircleOutline, mdiHammerWrench } from "@mdi/js";
import { DetailsCardWithSkeleton, SpaceComponent, TabChanged } from "../shared/Shared";
import { Object_TabContent_Details } from "../objects/Object_TabContent_Details";
import {Link as RouterLink, useLocation, useSearchParams} from "react-router-dom";
import { useNavigate } from 'react-router';
import { OmisEquipmentChip, OmisObjectChip, OmisReportChip, OmisUserChip } from "../shared/OmisChips";
import { TabContent_SubReports } from "./TabContent_SubReports";
import { TabContent_LinkedReports } from "./TabContent_LinkedReports";
import { TabContent_Documents } from "./TabContent_Documents";
import { TabContent_Notes } from "./TabContent_Notes";
import { TabContent_Tenders } from "./TabContent_Tenders";
import { TabContent_Invoices } from "./TabContent_Invoices";
import { TabContent_ActivityHistory } from "./TabContent_ActivityHistory";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { OmisCol, OmisColCenteredContent, OmisContainer, OmisRow, OmisStack, OmisTabsWithIcon } from "../shared/OmisLayouts";
import OmisStepper from "../shared/OmisStepper";
import { AddDocuments } from "./AddDocuments";
import { AddNote } from "./AddNote";
//import { AddInvoice } from "./AddInvoice";
import { Equipment_TabContent_Details } from "../equipment/Equipment_TabContent_Details";
import { ReportStatusChangeForm } from "./ReportStatusChangeForm";
import { ChangeResponsiblePerson, ReportEdit } from "./ReportEdit";
import { handleReportArchive } from "./Reports";
import { AddLinkedReport } from "../inspections/AddLinkedReport";
//import Projects from "../projects/Projects";
import { TRole } from "../../constants/RoleConstants";
import AuthService from "../../services/auth.service";
import { FaultReportMessage } from "../../constants/FaultReportMessageConstants";
import {ReportsDetailsModal} from "./ReportsDetailsModal";
import { AddSupplierRequest } from "./Tender/AddSupplierRequest";
import { TabContent_AdditionalReports } from "./TabContent_AdditionalReports";
import { CustomerCheck } from "../shared/CustomerCheck";
import { TCustomer } from "../../constants/TCustomer";

export function FaultReportDetailsLink() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [details, setDetails] = useState(null);

    useEffect(() => {
        httpclientService.get(`/api/reports/getreportdetailsfromlink?guid=${searchParams.get("guid")}`)
            .then((response) => {
                if (response) {
                    setDetails(response);
                } else {
                    window.location = "/";
                }
            });
    }, []);

    if (details != null) {
        return (
            <OmisDialog open={true}
                        title={t("Chosen_ReportDetails")}
                        content={<ReportsDetailsModal hideStatusDropdown={ true} {...details}/>}
                        maxWidth={"lg"}
                        actions=
                            {
                                <>
                                    <OmisButtonPrimary id={ "navigateDetailsButton"} component={RouterLink} to={"/reports/details/"} state={details} text={t("Details")}></OmisButtonPrimary>
                                </>
                            }
            />
        );
    } else {
        return (<CircularProgress />);
    }
}

export function ReportsDetails() {
    var { state } = useLocation();
    var location = useLocation();
    const navigate = useNavigate();

    if (!state) {
        var encryptedString = "";
        if (location?.search) {
            encryptedString = location?.search.replace("#", "").replace("?", "").replaceAll("%22", "\"") ;
        } else if (location?.hash) {
            encryptedString = location?.hash.replace("#", "").replace("?", "").replaceAll("%22", "\"");
        }

        if (encryptedString && encryptedString != "") {
            state = urlEncryptService.decrypt(encryptedString);
        }
    }

    const [tabs, setTabs] = useState([{
        iconpath: mdiInformationOutline,
        iconposition: "start",
        title: t("Details"),
        content: <TabContent_Details state={state} />,
        //showExportCVS: true
    }]);
    const [modalWidth, setModalWidth] = useState("md");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModaContent] = useState(null);
    const [topButtons, setTopButtons] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);
    const [selectedProject, setSelectedProject] = useState('');

    var //addSubReportTitle = "Add new sub-report",
        addLinkedReportsTitle = `${t("LinkedFaultReports")}`,
        addDocumentTitle = `${t("Documents")}`,
        addNoteTitle = `${t("Note")}`,
        //addInvoiceTitle = `${t("Action_Add")} ${t("Invoice")}`,
        addSupplierRequestsTitle = t("New_TenderingInvitation");

    var subReportTitle = t("FaultReport_SubReports"),
        linkedReportsTitle = t("LinkedFaultReports"),
        documentTitle = t("Documents"),
        notesTitle = t("Notes_for_Report"),
        supplierRequestsTitle = t("TenderList"),
        invoiceTitle = t("Invoices"),
        activityHistoryTitle = `${t("Activity")} ${t("FaultReport_History")}`,
        additionalReportsTitle = t("AdditionalReports") ;


    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        if (changeCounter>0) {
            navigate(0); //refresh page
        }
    }, [changeCounter]);

    const handleModalOpenForAddButton = (title, content, e) => {
        var newModalWidth = "md";
        if (title === addSupplierRequestsTitle) {
            newModalWidth = "xl";
        } else if (title === addNoteTitle || title === `${t("Action_Add")} ${t("Note")}`) {
            newModalWidth = "sm";
            title = addNoteTitle;
            content = <AddNote onCancel={handleModalCancel} onClose={handleModalClose} {...state} />;
        }

        if (title === addLinkedReportsTitle) {
            setModalTitle(null);
            newModalWidth = "lg";

        } else {
            setModalTitle(<PrepareModalTitle modalwidth={newModalWidth} reportid={state?.reportid} title={title} />);
        }

        setModalWidth(newModalWidth);  
        setModaContent(content);
        handleModalOpen();
    };

    const handleModalOpenForDropdownMenuActions = (title, content, e) => {
        var newModalWidth = "md";

        switch (title) {
            case addSupplierRequestsTitle:
                newModalWidth = "lg";
                break;
            case addNoteTitle:
                newModalWidth = "sm";
                content = <AddNote apiUrl={'/api/reports/getnotedetails'} onCancel={handleModalCancel} onClose={handleModalClose} {...state} />;
                break;
            case addLinkedReportsTitle:
                newModalWidth = "lg";
                break;
            default:
                break;
        }

        setModalWidth(newModalWidth);
        if (title === addLinkedReportsTitle) {
            setModalTitle(null);

        } else {
            setModalTitle(
                <PrepareModalTitle modalwidth={newModalWidth} reportid={state?.reportid} title={title} />);
        }
        setModaContent(content);
        handleModalOpen();
    };

    function ProjectCheckChange(event) {
        if (event.target.checked) {
            setSelectedProject(event.target.id);
        } else {
            setSelectedProject('');
        }
    }

    function AddToProjectSave(selectedProject) {
        const values = { reportid: parseInt(state?.reportid), projectid: parseInt(selectedProject) }
        httpclientService.post(`/api/reports/addtoproject?reportid=${parseInt(state?.reportid)}&projectid=${parseInt(selectedProject) }`, values)
            .then((response) => {
                if (response) {
                    handleModalClose();
                }
            });
    }

    const childItemsForDotsMenu = [
        {
            subMenuIcon: mdiPencil,
            onClick: handleModalOpenForDropdownMenuActions.bind(this, t("Action_Edit"), <ReportEdit handleModalCancel={handleModalCancel} handleModalClose={handleModalClose} selectedRowID={state?.reportid} {...state} />),
            menuName: t("Action_Edit"),
            hideMenuItem: !AuthService.hasRole(TRole.FaultReportEdit)            
        },
        // {
        //     subMenuIcon: mdiFileDocumentMultiple,
        //     onClick: handleModalOpenForDropdownMenuActions.bind(this, "Duplicate", "test"),
        //     menuName: "Duplicate",
        // },
        //{
        //    subMenuIcon: mdiPlusBox,
        //    onClick: handleModalOpenForDropdownMenuActions.bind(this, t("Action_AddToProject"), <Projects isSelectList reportid={state?.reportid} selectedProject={selectedProject} handleModalCancel={handleModalCancel} handleAddToProjectSave={AddToProjectSave} checkChange={ProjectCheckChange} />),
        //    menuName: t("Action_AddToProject"),
        //    hideMenuItem: !AuthService.hasRole(TRole.ProjectEdit)     
        //},
        {
            subMenuIcon: mdiAccount,
            onClick: handleModalOpenForDropdownMenuActions.bind(this, "Verantwortlichen ändern", <ChangeResponsiblePerson handleModalCancel={handleModalCancel} handleModalClose={handleModalClose} faultReportID={state?.reportid} {...state} />),
            menuName: "Verantwortlichen ändern",
            hideMenuItem: !AuthService.hasRole(TRole.ChangeAssignedTechnician) 
        },
        // {
        //     subMenuIcon: mdiEmail,
        //     onClick: handleModalOpenForDropdownMenuActions.bind(this, "Send as email", "test"),
        //     menuName: "Send as email",
        // },
        // {
        //     subMenuIcon: mdiBell,
        //     onClick: handleModalOpenForDropdownMenuActions.bind(this, "Cancel Report", "test"),
        //     menuName: "Cancel Report",
        // },
         {
             subMenuIcon: mdiArchive,
             onClick: () => {
                 handleReportArchive(state?.reportid).then((response) => {
                     if (response) {
                         navigate("/reports");
                     }
                 });
             },
             menuName: t("Action_Archive"),
         }
    ]


    const [childItemsForAddMenu, setChildItemsForAddMenu] = useState(
        [
            //     {
            //     subMenuIcon: mdiInboxFull,
            //     onClick: handleModalOpenForDropdownMenuActions.bind(this, addSubReportTitle, "test"),
            //     menuName: addSubReportTitle,
            // },
            {
                subMenuIcon: mdiLinkVariant,
                onClick: handleModalOpenForDropdownMenuActions.bind(this, addLinkedReportsTitle,
                    <AddLinkedReport saveUrl={"/api/reports/savelinkreports"} handleCancel={handleModalCancel} onClose={handleModalClose} state={state} />),
                menuName: addLinkedReportsTitle
            }, {
                subMenuIcon: mdiFileDocument,
                onClick: handleModalOpenForDropdownMenuActions.bind(this, documentTitle,
                    <AddDocuments closeAction={handleModalClose} onCancel={handleModalCancel} fileUploadCallback={(err) => {
                        err?.length === 0 ? handleModalClose() : console.log(err);
                    }} {...state} />),
                menuName: addDocumentTitle,
                hideMenuItem: !AuthService.hasRole(TRole.DMSFaultReport)
            }, {
                subMenuIcon: mdiFileDocumentMultiple,
                onClick: handleModalOpenForDropdownMenuActions.bind(this, addNoteTitle, null),
                menuName: addNoteTitle,
                hideMenuItem: !AuthService.hasRole(TRole.FaultReportNote)
            },
            //     {
            //     subMenuIcon: mdiCreditCardChip,
            //     onClick: handleModalOpenForDropdownMenuActions.bind(this, addInvoiceTitle,
            //         <AddInvoice closeAction={ handleModalClose } { ...state } />),
            //     menuName: addInvoiceTitle,
            // },
            {
                subMenuIcon: mdiClipboardList,
                onClick: handleModalOpenForDropdownMenuActions.bind(this, addSupplierRequestsTitle,
                    <AddSupplierRequest handleCancel={handleModalCancel} onClose={handleModalClose} faultReportID={state?.reportid} {...state} />),
                menuName: addSupplierRequestsTitle,
                hideMenuItem: false
            },
        ]
    );

    function PrepareTopButtonsForTabs(props) {
        return (           
            <OmisCol xs={"auto"} sm={"auto"} key={`${props.id}_buttonsGrid`}>
                <OmisRow>
                    {
                        props.ExportCVSLink ?
                            <OmisCol style={{ height: '2.583em' }} xs={"auto"} sm={"auto"} key={`${props.id}_exportButtonGrid`}>
                                <OmisSecButtonWithStartIcon id={"exportCVSTopNavButton"} key={`${props.id}_exportButton`} state={state} iconpath={mdiInboxArrowUpOutline} component={RouterLink} to={props.ExportCVSLink} text={props.ExportCVSText ? props.ExportCVSText : "Export CVS"} size={"small"} />
                            </OmisCol>
                            :
                            null
                    }
                    {
                        props.AddButtonLink ?
                            <OmisCol style={{ height: '2.583em' }} xs={"auto"} sm={"auto"} key={`${props.id}_addButtonGrid`}>
                                <OmisPriButtonWithStartIcon id={"addButtonByNav"} key={`${props.id}_addButton`} state={state} iconpath={mdiPlusBoxOutline} component={RouterLink} to={props.AddButtonLink} text={props.AddButtonText} size={"small"} />
                            </OmisCol>
                            :
                            props.AddButtonOnClick ?
                                <OmisCol style={{ height: '2.583em' }} xs={"auto"} sm={"auto"} key={`${props.id}_addButtonGrid`}>
                                    <OmisPriButtonWithStartIcon id={"addButtonByOnClick"} sx={{ width:'fit-content'}} key={`${props.id}_addButton`} state={state} iconpath={mdiPlusBoxOutline} onClick={props.AddButtonOnClick} text={props.AddButtonText} size={"small"} />
                                </OmisCol>
                                :
                                null
                    }
                </OmisRow>
            </OmisCol>
        );
    }

    function GetTabByTitle(title,count) {
        var result = {};

        switch (title) {

            case subReportTitle:
                result ={
                    iconpath: mdiInboxFull,
                    iconposition: "start",
                    title: subReportTitle,
                                content:
                    <TabContent_SubReports state={state} />,
                        topButtonsContentList: [
                            <PrepareTopButtonsForTabs id={subReportTitle} key={subReportTitle} AddButtonLink={"/reports/details/"} AddButtonText={`${t("Action_Add")} new subtask`} />]
                };
                break;
            case linkedReportsTitle:
                result = {
                    iconpath: mdiLinkVariant,
                    iconposition: "start",
                    title: linkedReportsTitle,
                    content:
                        <TabContent_LinkedReports state={state} rowCountFromTab={ count} />,
                    topButtonsContentList: [
                        <PrepareTopButtonsForTabs id={addLinkedReportsTitle} key={addLinkedReportsTitle}
                            AddButtonOnClick={count < 20 ? handleModalOpenForAddButton.bind(this, addLinkedReportsTitle, <AddLinkedReport saveUrl={"/api/reports/savelinkreports"} handleCancel={handleModalCancel} onClose={handleModalClose} state={state} selecteableRowCount={20 - count} />) : null}
                            AddButtonText={addLinkedReportsTitle} />
                    ],                            
                    removeFromAddMenu: addLinkedReportsTitle
                };
                break;
            case documentTitle:
                result = {
                    iconpath: mdiFolderOutline,
                    iconposition: "start",
                    title: documentTitle,
                    content:
                        <TabContent_Documents state={state} />,
                    topButtonsContentList: [],
                    removeFromAddMenu: documentTitle
                };
                break;
            case notesTitle:
                result = {
                    iconpath: mdiFileDocumentMultiple,
                    iconposition: "start",
                    title: notesTitle,
                    content:
                        <TabContent_Notes state={state} changeCounter={changeCounter} />,
                    topButtonsContentList: [
                        <PrepareTopButtonsForTabs id={notesTitle} key={notesTitle}
                            AddButtonOnClick={handleModalOpenForAddButton.bind(this, `${t("Action_Add")} ${t("Note")}`, null)}
                            AddButtonText={`${t("Action_Add")} ${t("Note")}`} />],
                    removeFromAddMenu: addNoteTitle
                };
                break;
            case supplierRequestsTitle:
                result = {
                    iconpath: mdiClipboardList,
                    iconposition: "start",
                    title: supplierRequestsTitle,
                    content:
                        <TabContent_Tenders state={state} />,
                    topButtonsContentList: [
                        <PrepareTopButtonsForTabs id={supplierRequestsTitle} key={supplierRequestsTitle} AddButtonLink={"/reports/details/"} AddButtonText={`${t("Action_Add")} ${t("TenderList")}`} />]
                };
                break;
            case invoiceTitle:
                result = {
                    iconpath: mdiCreditCardChip,
                    iconposition: "start",
                    title: invoiceTitle,
                    content:
                        <TabContent_Invoices state={state} />,
                    topButtonsContentList: [
                        <PrepareTopButtonsForTabs id={invoiceTitle} key={invoiceTitle} AddButtonLink={"/reports/details/"} AddButtonText={`${t("Action_Add")} ${t("Invoice")}`} />]
                };
                break;
            case activityHistoryTitle:
                result = {
                    iconpath: mdiTimerOutline,
                    iconposition: "start",
                    title: activityHistoryTitle,
                    content:
                        <DetailsCardWithSkeleton
                            url={`/api/reports/getactivityhistory?id=${state?.reportid}`}
                            skeletonheight={"100px"}
                            content={
                                <TabContent_ActivityHistory state={state} />}{...state} />
                               
                };
                break;
            case additionalReportsTitle:
                result = {
                    iconpath: mdiHammerWrench,
                    iconposition: "start",
                    title: additionalReportsTitle,
                    content: <TabContent_AdditionalReports state={state} />
                                

                };
                break;
            default:
                break;
        }
        return result;
    }

    useEffect(() => {
        var tmp = [...tabs];
        httpclientService.get(`/api/reports/getreportdetailstabcounts?reportid=${state?.reportid}`)
            .then((tabcounts) => {
                if (tabcounts.length>0) {
                    tabcounts.map((tab) => {
                        if (tab.count > 0 && tmp.filter((item) => item.title === tab.title).length === 0) {                            
                            tmp.push(GetTabByTitle(tab.title, tab.count));//AuthService.hasRole(TRole.DMSFaultReport_40) add role check for tabs
                        }
                    });
                }   
            }).then(() => {
                httpclientService.get(`/api/reports/getaddcontractorpossible?reportid=${state?.reportid}`)
                    .then((addcontractorpossible) => {
                        var newChildItemsForAddMenu = [...childItemsForAddMenu];
                        var addTenderMenuItem = newChildItemsForAddMenu.filter(s => s.menuName === addSupplierRequestsTitle)[0];
                        if (addTenderMenuItem) {
                            addTenderMenuItem.hideMenuItem = !addcontractorpossible;
                            addTenderMenuItem.removeAddButton = !addcontractorpossible;
                            setChildItemsForAddMenu(newChildItemsForAddMenu);
                        }
                        var tab = tmp.filter(x => x.title === supplierRequestsTitle)[0];
                        if (tab) {
                            if (!addcontractorpossible) {
                                tab.topButtonsContentList = tab.topButtonsContentList.filter(item => item.props?.AddButtonText !== `${t("Action_Add")} ${t("TenderList")}`);
                            }
                        }
                        setTabs(tmp);
                    });
            });          
    },[]);

    return (
       
            <OmisContainer fluid>
                <div className="omis-header-box">
                    <OmisStack direction="horizontal" gap={5}>
                        <div className="me-auto">
                            <OmisRow className="gy-5">
                                <OmisCol xs={1}>
                                    <OmisIconButton id={"backButton"} iconcontent={
                                        <ArrowBackIcon fontSize="inherit" />} onClick={() => { navigate(location.key !== "default" ? -1 : "/reports"); }}
                                    />
                                </OmisCol>
                                <OmisCol xs={11}>
                                    <div className="omis-header-title-details" style={{ paddingLeft:'1.5em' }}>{t("Chosen_ReportDetails")} #{state?.reportid}</div>
                                </OmisCol>
                            </OmisRow>
                        </div>
                        <div className="ms-auto">{topButtons}</div>
                    </OmisStack>

                    <OmisTabsWithIcon tabs={tabs} onChange={TabChanged.bind(this, tabs, state, childItemsForDotsMenu, childItemsForAddMenu, setTopButtons)} />
                    <OmisDialog onClose={handleModalCancel} open={modalOpen} maxWidth={modalWidth} title={modalTitle} content={modalContent} />
                </div>
            </OmisContainer>
           
        
		);
}

function TabContent_Details(props) {

    const [modalWidth, setModalWidth] = useState("md");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);

    const [equiReportIDs, setEquiReportIDs] = useState([]);

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };


    useEffect(() => {
        if (props?.state?.equipid) {
            httpclientService.get(`/api/reports/getequipreportids?equipid=${props?.state?.equipid}`)
                .then((response) => {
                    if (response.length > 0) {
                        setEquiReportIDs(response);
                    }
                });
        }
    }, []);

    function showOpenReports() {
        setModalTitle(t("Widget_OpenReports"));
        setModalContent(
            <>
                {
                    equiReportIDs.map((reportItem) =>
                        <>
                        <OmisContainer fluid key={reportItem.faultReportID}>
                            <OmisRow>
                                <OmisCol xs={12}>
                                    <div className="userProfilCardText">{`${t("Report_Title")} #${reportItem.faultReportID}`}</div>
                                </OmisCol>
                                 <br></br>
                                <OmisCol xs={12}>
                                    <span className="userProfilCardText">{t("FaultDescription")}</span>
                                    <p className={"bold"}>{reportItem.faultDescription}</p>
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisStack direction="horizontal">
                                    <OmisCol xs={1}>
                                        <span>{t("Object")}</span>
                                        <p className={"bold"}>{reportItem.objectID}</p>
                                    </OmisCol>
                                    <OmisCol xs={2}>
                                        <span>{t("Equipment")}</span>
                                        <p className={"bold"}>{reportItem.equipID}</p>
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <span>{t("Status")}</span>
                                        <p className={"bold"}>{reportItem.currentStatus}</p>
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <span>{t("Annunciator")}</span>
                                        <OmisUserChip userFullName={reportItem.announciator_Name} userName={reportItem.announciatorUserName} userID={reportItem.announciatorID} />
                                    </OmisCol>
                                    <OmisCol xs={3}>
                                        <span>{t("FollowupConfiguration_1")}</span>
                                        <p className={"bold"}>{reportItem.dateToCompleteActionCustom ? reportItem.dateToCompleteActionCustom : reportItem.dateToCompleteAction}</p>
                                    </OmisCol>
                                </OmisStack>
                            </OmisRow>    
                        </OmisContainer>
                        </>
                    )
                }
            </>
        );
        handleModalOpen();
    }

    return (
        <>
            <OmisRow>
                <OmisCol xs={12}>
                    <br></br>
                    <DetailsCardWithSkeleton
                        url={`/api/reports/details?reportid=${props?.state?.reportid}`}
                        skeletonheight={"100px"}
                        content={
                            <ReportDetailsInfo {...props} />
                        }
                        {...props} />
                </OmisCol>

                {
                //AuthService.hasRole(TRole.DisableReactionTime) ?
                  //  null :
                    <OmisCol xs={12}>
                        <DetailsCardWithSkeleton
                            url={`/api/reports/getreactiontimepreview?reportid=${props?.state?.reportid}`}
                            skeletonheight={"100px"}
                            content={
                                <LoadStepper {...props} />}{...props} />
                    </OmisCol>
            }

            {
                    AuthService.hasRole(TRole.ObjectRead) ?
                        <OmisCol xs={12}>
                            <div className="CardText detailsCardTitle"><br></br>{t("Object_Details_Title")}</div>
                            <OmisCollapseCard buttonRowContent={<><span>{`${t("Object")}`}</span> <SpaceComponent /> <OmisObjectChip objectid={`${props.state.objectid}`} text={`${props.state.objectid}`} /> </>} variant={"outlined"} expand={true}>
                                <Object_TabContent_Details {...props} />
                            </OmisCollapseCard>
                        </OmisCol>
                        :
                        null
                }

                {
                    AuthService.hasRole(TRole.EquipmentRead) ?
                        props.state.equipid ?
                            <OmisCol xs={12}>
                                <span className="userProfilCardText detailsCardTitle">{`${t("Equipment")} ${t("Details")}`}</span>
                                <OmisCollapseCard buttonRowContent={<> <span>{`${t("Equipment")}`}</span> <SpaceComponent /> <OmisEquipmentChip equipid={`${props.state.equipid}`} text={`${props.state.equipid}`} /><SpaceComponent /> {equiReportIDs.length > 0 ? <Chip avatar={<Icon path={mdiInboxFull} />} clickable={true} onClick={showOpenReports} className={"reportChip"} label={`${equiReportIDs.length} ${t("Widget_OpenReports")}`} sx={{ width: "auto;" }} /> : null}</>} variant={"outlined"} expand={true}>
                                    <Equipment_TabContent_Details {...props} />
                                </OmisCollapseCard>
                            </OmisCol>
                            :
                            null
                        :
                        null
                }

                        <OmisCol xs={12}>
                        </OmisCol>

                <br></br>
                <br></br>
            </OmisRow>
            <OmisDialog onClose={handleModalClose} open={modalOpen}
                maxWidth={modalWidth}
                title={modalTitle}
                content={modalContent}
            />
            </>   );
}

export function ReportDetailsInfo(props) {
    const {data} = {...props}

    const [modalWidth, setModalWidth] = useState("sm");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [childItemsForStatus, setChildItemsForStatus] = useState([]);
    const [changeCounter, setChangeCounter] = useState(0);
    const navigate = useNavigate();

    const handleModalOpenContents = (title, content, e) => {
        setModalTitle(<PrepareModalTitle reportid={data.FaultReportID} title={title} />);
        setModalContent(content);
        handleModalOpen();
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        if (changeCounter > 0) {
            navigate(0); //refresh page
        }
    }, [changeCounter]);

    function handleStatusMenuClicked(selectedMessage, e) {
        if (selectedMessage === FaultReportMessage.RECLASSIFY || selectedMessage === FaultReportMessage.MANUAL_REQUEST) {
            navigate("/reports/reportsreclassify", { replace: true, state: { FaultReportID: data.FaultReportID, ObjectID: data.ObjectID, EquipID: data.EquipID, selectedMessage: selectedMessage, fromDetails : true } });
        } else {
            handleModalOpenContents(`${t("Status_Change_Message")}`, <ReportStatusChangeForm currentStatus={data.CurrentStatus ?? data.currentStatus} selectedMessage={selectedMessage} reportid={data.FaultReportID} reportnrextern={data.ReportNrExtern} handleModalCancel={handleModalCancel} handleModalClose={handleModalClose} />);
        }        
    }

    useEffect(() => {
        if (data && data.PossibleReportStatus && data.PossibleReportStatus.length > 0 && !props.hideStatusDropdown) {
            setChildItemsForStatus(data.PossibleReportStatus.map((status, index) =>
            ({
                content:
                    <MenuItem id={`${status.key}`} className={"statusDropdown"} onClick={handleStatusMenuClicked.bind(this, status.key)} key={`${status.key}`}>
                        {status.displayText}
                    </MenuItem>,
                className: `statusDropdown status${status.key}`        
            })));
        }
    }, []);

    return (
        <>

            <OmisCol xs={12}>
                <span className="userProfilCardText detailsCardTitle">{t("Details")}</span>
            </OmisCol>
            
            <OmisCollapseCard buttonRowContent={
                <OmisRow className="userProfilCardContent">
                    <OmisCol xs={1}>
                        <span className="userProfilCardContentText4">{`#${data.FaultReportID}`}</span>
                    </OmisCol>
                    {
                        childItemsForStatus.length > 0 ?
                            <OmisColCenteredContent xs={3}>
                                <OmisDropDownWithDynamicComponents horizontal={"center"} menutitle={`${t("Status")} ${data.CurrentStatus_Displayed ? `(${data.CurrentStatus_Displayed})` : ""}`} childitems={childItemsForStatus} showdownarrow={1} />

                            </OmisColCenteredContent> :
                            <OmisColCenteredContent xs={3}>
                                <span>{`${t("Status")} ${data.CurrentStatus_Displayed ? `(${data.CurrentStatus_Displayed})` : ""}`}</span>
                            </OmisColCenteredContent>
                    }
                    <OmisColCenteredContent xs={8}>
                        <OmisCol xs={3}>
                            {data.ImminentDanger ? <><Icon className="primaryColor" path={mdiAlertCircleOutline} size={1} /><span>{t("ImminentDanger")}</span></> : null}
                        </OmisCol>
                    </OmisColCenteredContent>
                </OmisRow>
            } variant={"outlined"} expand={true}>
                    
                <OmisCol xs={12} className="userProfilCardContent">
                    <span className="userProfilCardText">{t("FaultDescription")}</span>
                    <br></br><span className="userProfilCardText5">{data.FaultDescription}</span><br></br>
                </OmisCol>
                <OmisCol xs={12} className="userProfilCardContent">
                    <OmisRow>
                        <OmisCol xs={3}>
                            <Card variant={"outlined"}>
                            <span className="userProfilCardContentText3">{t("FaultReportKind")}</span>
                                <br></br><p className="userProfilCardContentText4 bold">{data.FaultReportKind_Description}</p>
                            </Card>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <Card variant={"outlined"}>
                            <span className="userProfilCardContentText3">{t("FaultEquiClassGroup")}</span>
                                <br></br><p className="userProfilCardContentText4 bold">{data.FaultEquiClassGroup_Description}</p>
                            </Card>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <Card variant={"outlined"}>
                            <span className="userProfilCardContentText3">{t("FaultReport_FaultEquiClassDef")}</span>
                                <br></br><p className="userProfilCardContentText4 bold">{data.FaultEquiClassDef_Description}</p>
                            </Card>
                        </OmisCol>
                        <OmisCol xs={3}>
                            <Card variant={"outlined"}>
                            <span className="userProfilCardContentText3">{t("FaultKind")}</span>
                                <br></br><p className="userProfilCardContentText4 bold">{data.FaultKind_Description}</p>
                            </Card>
                        </OmisCol>
                        <CustomerCheck allowed={[TCustomer.CUSTOMER_SUPPORT]}>
                            <OmisCol xs={3}>
                                <Card variant={"outlined"}>
                                    <span className="userProfilCardContentText3">{t("FaultReport_ReportNrExtern")}</span>
                                    <br></br><p className="userProfilCardText6 userProfilCardContentTextWB bold">
                                        {data.ReportNrExtern}
                                    </p>
                                </Card>
                            </OmisCol>  
                        </CustomerCheck>                    
                    </OmisRow>
                </OmisCol>

                <OmisCol xs={12}>
                    <span className="userProfilCardText">{t("FaultReport_ReactionTimeReal")}</span>
                </OmisCol>

                <Card variant={"outlined"}>
                    <OmisRow>
                        <OmisCol xs={4}>
                            <span className="userProfilCardText3">{t("FaultReport_AnnouncementDate")}</span>
                            <br></br><p className="userProfilCardText6 userProfilCardContentTextWB bold">
                                <OmisDateTime date={data.AnnouncementDate} />
                            </p>
                        </OmisCol>
                            {
                                AuthService.hasRole(TRole.DisableReactionTime) ?
                                    null :
                                    <>
                                        <OmisCol xs={4}>
                                            <span className="userProfilCardText3">{t("FaultReport_ReactionTimeReal")}</span>
                                            <br></br><p className="userProfilCardText6 userProfilCardContentTextWB bold">
                                                {data.ReactionTimeReal}
                                            </p>
                                        </OmisCol>
                                        <OmisCol xs={4}>
                                            <span className="userProfilCardText3">{t("FaultReport_DateToCompleteAction")}</span>
                                            <br></br><p className="userProfilCardText6 userProfilCardContentTextWB bold">
                                                <OmisDateTime date={data.DateToCompleteAction} />
                                            </p>
                                        </OmisCol>
                                    </>
                        }
                        </OmisRow>
                </Card>

                <OmisCol xs={12}>
                    <span className="userProfilCardText">Points of contact</span>
                </OmisCol>
                <Card variant={"outlined"}>
                    <OmisRow>
                        <OmisCol xs={3}>
                            <span className="userProfilCardText2">{t("Annunciator")}</span>
                            <br></br><div className="userProfilCardText2 userProfilCardContentTextWB bold"><OmisUserChip userFullName={data.Announciator_Name} userName={data.AnnounciatorUserName} userID={data.AnnounciatorID} /></div>
                        </OmisCol>

                        {data.AgentID ?
                            <OmisCol xs={3}>
                                <span className="userProfilCardText2">{t("AssignedTechnician")}</span>
                                <br></br><div className="userProfilCardText2 userProfilCardContentTextWB bold"><OmisUserChip userFullName={data.Agent_Name} userID={data.AgentID} /></div>
                            </OmisCol> : null} {data.CompanySiteID ?
                            <OmisCol xs={3}>
                                <span className="userProfilCardText2">{t("CompanySite")}</span>
                                <br></br><div className="userProfilCardText2 userProfilCardContentTextWB bold">{data.CompanySiteDescription}</div>
                            </OmisCol> : null}
                    </OmisRow>
                </Card>

                {
                    data.Severities ?
                        <OmisCol xs={12}>
                            <span className="userProfilCardText">{t("Severity")}</span>
                            <br></br><span className="userProfilCardText">{data.Severities}</span>
                        </OmisCol>
                        :
                        null
                }

            </OmisCollapseCard>
            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={modalWidth}
                title={modalTitle}
                content={modalContent}
                />
        </>);
}

function LoadStepper(props) {

    const [steps, setSteps] = useState([]);
    const [smallPreview, setSmallPreview] = useState([]);
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            setSteps(props.data.map((item, index) => ({
                    key: `${ index }_Label`,
                    label: `${ item.fromDayStr } ${ item.fromHour } ${ t("To") } ${ (item.toDayStr !== "") ? item.toDayStr + " " + item.toHour : item.toHour } ${ item.description !== "" ? `( ${ item.description } )` : "" }`,
                    customLabels:
                        <strong>{ item.timeSpan }</strong>,
                    isDone: (item.timeSpan !== "0h"),
                })));

            var maxLen = props.data.length - 1;
            if (maxLen > 2) {

                setSmallPreview([{
                    key: `${0}_Label`,
                    label: `${props.data[0].fromDayStr} ${props.data[0].fromHour} ${t("To")} ${(props.data[0].toDayStr !== "") ? props.data[0].toDayStr + " " + props.data[0].toHour : props.data[0].toHour} ${props.data[0].description !== "" ? `( ${props.data[0].description} )` : ""}`,
                    customLabels:
                        <strong>{props.data[0].timeSpan}</strong>,
                    isDone: (props.data[0].timeSpan !== "0h"),
                }, {
                    key: `dots_Label`,
                    label:
                        <OmisButtonPrimary id={"displayAllButton"} text={`...${t("Criteria_DisplayAll")} (${maxLen - 1})`} onClick={() => setShowAll(true)} />,
                    isGray: true,
                }, {
                    key: `${maxLen}_Label`,
                    label: `${props.data[maxLen].fromDayStr} ${props.data[maxLen].fromHour} ${t("To")} ${(props.data[maxLen].toDayStr !== "") ? props.data[maxLen].toDayStr + " " + props.data[maxLen].toHour : props.data[maxLen].toHour} ${props.data[maxLen].description !== "" ? `( ${props.data[maxLen].description} )` : ""}`,
                    customLabels:
                        <strong>{props.data[maxLen].timeSpan}</strong>,
                    isDone: (props.data[maxLen].timeSpan !== "0h"),
                }]);
            } else {
                setShowAll(true);
            }

        }
    }, [props.data]);

    return (
        steps.length > 0 ?
        <>

                <OmisCol xs={12}>
                    <br></br>
                    <span className="userProfilCardText detailsCardTitle">{t("FaultReport_ReactionTimeReal")}</span>
                </OmisCol>

                <OmisCol xs={12}>
                    {showAll ?
                        <Card sx={{
                            maxHeight: 300,
                            width: '100%',
                            overflow: 'auto'
                        }} elevation={0}>
                            <OmisStepper steps={steps} />
                        </Card>
                        :
                        <Card>
                            <OmisStepper steps={smallPreview} />
                        </Card>

                    }
                </OmisCol>

            </>
            :
            null
    );
}

export function PrepareModalTitle(props) {
    return (


            <OmisCol xs={12 }>
            <span>{props.title}</span>
                <SpaceComponent /><SpaceComponent />
                {
                    props.reportid ?
                        <OmisReportChip text={props.reportid} reportid={props.reportid} objectid={props.objectid} equipid={props.equipid} />
                        :
                        null
                }
            </OmisCol>

       );
}