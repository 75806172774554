import React, { useRef, useState } from "react";
import { t } from "i18next";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisCheckBox } from "../../shared/OmisInputs";
import { OmisLabel } from "../../shared/OmisDisplays";
import { Link as RouterLink } from "react-router-dom";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function EditVisibleStatesConfig(props) {

    const navigate = useNavigate();
    const formRef = useRef();

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    const [initialValues, setInitialValues] = useState({
        description: props.rowVals?.statusDescription ?? "",
        workflowUser_None: props.rowVals?.workflowUser_None ?? false,
        workflowUser_Contractor: props.rowVals?.workflowUser_Contractor ?? false,
        workflowUser_MaintenanceResponsible: props.rowVals?.workflowUser_MaintenanceResponsible ?? false,
        workflowUser_MaintenanceController: props.rowVals?.workflowUser_MaintenanceController ?? false,
        workflowUser_FaultClassResponsible: props.rowVals?.workflowUser_FaultClassResponsible ?? false,
        workflowUser_Dispatcher: props.rowVals?.workflowUser_Dispatcher ?? false,
        workflowUser_FSMMaster: props.rowVals?.workflowUser_FSMMaster ?? false,
        workflowUser_CommercialResponsible: props.rowVals?.workflowUser_CommercialResponsible ?? false
    });
    function handleSave(values) {
        httpclientService.post("/api/settings/settingsreports/editvisiblestatesconfig", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(-1);
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(-1);
        }
    }

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        <OmisContainer fluid>

                            <OmisLabel label={props.rowVals?.statusDescription_Displayed} />
                            <br></br>

                            <OmisRow>
                                <OmisCol xs={4}>
                                    <OmisCheckBox labeltext={t("WorkflowUser_None")} name="workflowUser_None" />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisCheckBox labeltext={t("WorkflowUser_Contractor")} name="workflowUser_Contractor" />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisCheckBox labeltext={t("WorkflowUser_MaintenanceResponsible")} name="workflowUser_MaintenanceResponsible" />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisCheckBox labeltext={t("WorkflowUser_CommercialResponsible")} name="workflowUser_CommercialResponsible" />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisCheckBox labeltext={t("WorkflowUser_MaintenanceController")} name="workflowUser_MaintenanceController" />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisCheckBox labeltext={t("WorkflowUser_FaultClassResponsible")} name="workflowUser_FaultClassResponsible" />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisCheckBox labeltext={t("WorkflowUser_Dispatcher")} name="workflowUser_Dispatcher" />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <OmisCheckBox labeltext={t("WorkflowUser_FSMMaster")} name="workflowUser_FSMMaster" />
                                </OmisCol>
                            </OmisRow>



                            <OmisRow>
                                <OmisCol xs={6}>
                                    {
                                        props.handleModalCancel ?
                                            <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={props.handleModalCancel}></OmisButtonSecondary>
                                            :
                                            <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} component={RouterLink} to={"/users"}></OmisButtonSecondary>
                                    }
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid || submitButtonDisabled} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>

                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}