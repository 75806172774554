import React from "react";
import { useLocation } from "react-router-dom";

export function NotificationEdit() {
    var {state} = useLocation();
    if (!state) {
        return (
            <div>New Notification!</div>
        );
    }
    return (
        <div>Edit Notification with UserName {state.userFullName}</div>
    );
}