import React, {useEffect, useState} from 'react';
import {OmisTextBox} from "../../shared/OmisInputs";
import * as Yup from "yup";
import {Form, Formik } from "formik";
import { OmisIconButton, OmisButtonPrimarySubmit } from "../../shared/OmisButtons";
import httpclientService from "../../../services/httpclient.service";
import {OmisLabel} from "../../shared/OmisDisplays";
import { Link as RouterLink } from "react-router-dom";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisCol, OmisRow } from '../../shared/OmisLayouts';

var state = { selectedtabvalue: TabConstants.System }
export default function ConnectionTest(props) {

    const [testResult, setTestResult] = useState('');

    const [initialValues, setInitialValues] = useState({
        phone: '',
        email: ''
    });

    const [emailDisabled, setEmailDisabled] = useState(false);
    const [phoneDisabled, setPhoneDisabled] = useState(false);

    const [testButtonDisabled, setTestButtonDisabled] = useState(false);

    function handleTestClick(values) {
        var testMethod = `email?recipient=${values.email}`;
        if (values.phone !== '') {
            testMethod = `voicecall?recipient=${values.phone}`;
        }
        httpclientService.post(`/api/settings/connectiontest/${testMethod}`).then((response) => {
            setTestResult(response.data ?? response);
            setTestButtonDisabled(false);
        });
    }

    useEffect(() => {
        if (initialValues.email.length > 0) {
            setPhoneDisabled(true);
        } else {
            setPhoneDisabled(false);
        }
        if (initialValues.phone.length > 0) {
            setEmailDisabled(true);
        } else {
            setEmailDisabled(false);
        }

        if (initialValues.email.length === 0 && initialValues.phone.length === 0) {
            setTestButtonDisabled(true);
        } else {
            setTestButtonDisabled(false);
        }

    }, [initialValues]);

    return (
        <>
            <div className="omis-header-box">
                <OmisCol xs={1}>
                    <OmisIconButton id={"backButton"}
                        iconcontent={<ArrowBackIcon fontSize="inherit" />}
                        text="Back" component={RouterLink} to={"/settings"} state={state ?? { selectedtabvalue: TabConstants.System }} />
                </OmisCol>
            </div>
            <h3>Verbindungstests</h3>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Bitte gültige Email eintragen'),
                    phone: Yup.string()
                        .matches(/\+\d{2,}([ ]?\d)+$|^$/, 'Bitte gültige Telefonnummer eintragen')
                })}
                onSubmit={(values) => {
                    setTestResult('');
                    setTestButtonDisabled(true);
                    handleTestClick(values)
                }}
            >
                <Form>
                    <OmisRow>
                        <OmisCol xs="3">
                            <OmisTextBox labeltext={"Email"} onChange={(e) => setInitialValues({ ...initialValues, email: e.target.value })} disabled={emailDisabled} name="email" placeholder={"office@omis.at"} />
                        </OmisCol>
                        <OmisCol xs="3">
                            <OmisTextBox labeltext={"Telefon"} onChange={(e) => setInitialValues({ ...initialValues, phone: e.target.value })} disabled={phoneDisabled} name="phone" placeholder={"+43772344600"} />
                        </OmisCol>
                    </OmisRow>
                    <OmisRow>
                        <OmisCol xs={"3"}>
                            <OmisButtonPrimarySubmit id={"submitButton"} disabled={testButtonDisabled} text={"Testen"}></OmisButtonPrimarySubmit>
                        </OmisCol>
                    </OmisRow>
                </Form>
            </Formik>
            <OmisRow>
                <OmisCol xs={12}>
                    <OmisLabel label={testResult} />
                </OmisCol>
            </OmisRow>
        </>
    );
}