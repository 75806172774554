import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisIconButton } from "../../shared/OmisButtons";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import Objects from "../../objects/Objects";
import { OmisCol } from "../../shared/OmisLayouts";

export function SettingsObjects(props) {

    return (
        <div className="omis-header-box">
            <OmisCol xs={1}>
                <OmisIconButton id={"backButton"}
                    iconcontent={<ArrowBackIcon fontSize="inherit" />}
                    text="Back" component={RouterLink} to={"/settings"} state={{ selectedtabvalue: TabConstants.Objects }} />
            </OmisCol>

            <Objects detailslink={"/settings/objects/objectsdetails"} />
        </div>
    );
}