import React, { useEffect, useRef, useState } from "react";
import httpclientService from "../../services/httpclient.service";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { t } from "i18next";
import { Form, Formik } from "formik";
import LanguageDropdown from "../profile/LanguageDropdown";
import { OmisButtonPrimarySubmit } from "../shared/OmisButtons";
import { OmisButtonSecondary } from "../shared/OmisButtons";
import { Box } from "@mui/material";
import { OmisPasswordArea, OmisTextBox } from "../shared/OmisInputs";
import { OmisDropdown, OmisDropdownMultiselectV2 } from "../shared/OmisDropdowns";
import OmisImageUploader from "../shared/OmisImageUploader";
import { Roles } from "./Roles";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../shared/OmisLayouts";

var api = "/api/profile/";

export default function AddNewUser(props) {

    const navigate = useNavigate();

    const formRef = useRef();
    const [userLanguage, setUserLanguage] = useState("de-AT");
    const [contractors, setContractors] = useState([]);
    const [initialValues, setInitialValues] = useState({
        username: '',
        firstName: '',
        lastName: '',
        password: '',
        email: '',
        mobile: '',
        phone: '',
        addressState: '',
        addressFederalState: '',
        addressCity: '',
        addressZipCode: '',
        addressStreet: '',
        groups: [],
        roles: [],
        selectedImage: null,
        userLanguage: userLanguage,
        lastChangedField: '',
        contractorID: props.contractorID ?? null,
        contractorGroupMember: false
    });

    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

    useEffect(() => {
        httpclientService.get(`${api}getgroups`).then((response) => {
            if (response && response.length) {
                setGroups(response.map((group) => ({
                    key: group.id, displayText: group.description
                })));
            }
        });

        httpclientService.get(`${api}getcontractorsfordropdown`).then((response) => {
            if (response && response.length > 0) {
                setContractors(response);
            }
        });
    }, []);

    useEffect(() => {
        formRef.current.setFieldValue("userLanguage", userLanguage);
    }, [userLanguage]);

    useEffect(() => {
        formRef.current.setFieldValue("groups", selectedGroups);
        formRef.current.validateField("groups");
    }, [selectedGroups]);

    const validationSchema = Yup.object({
        username: Yup.string()
            .required(t("GenericRequiredText"))
            .test("usernamevalidation", t("UsernameIsDuplicate"), function (value) { // Use function
                if (formRef.current.values.lastChangedField === "username") {
                    return httpclientService.get(`/api/profile/isvalueavailable?fieldType=${"username"}&value=${value}`).then((response) => {
                        return response;
                    });
                } else if (formRef.current.values.lastChangedField === null) {
                    return true;
                } else {
                    return !formRef.current.errors.username ?? true;
                }
            })
            .max(200, t('Error_TextTooLong', { 1: 200 })),
        firstName: Yup.string()
            .required(t("GenericRequiredText"))
            .max(60, t('Error_TextTooLong', { 1: 60 })),
        lastName: Yup.string()
            .required(t("GenericRequiredText"))
            .max(60, t('Error_TextTooLong', { 1: 60 })),
        password: Yup.string()
            .required(t("GenericRequiredText"))
            .max(40, t('Error_TextTooLong', { 1: 40 })),
        phone: Yup.string()
            .nullable()
            .max(20, t('Error_TextTooLong', { 1: 20 }))
            .matches(phoneRegExp, t("PhoneNumberFormatInvalid")),
        mobile: Yup.string()
            .nullable()
            .max(20, t('Error_TextTooLong', { 1: 20 }))
            .matches(phoneRegExp, t("PhoneNumberFormatInvalid")),
        email: Yup.string().email(t("OnlineImport_InvalidFormat"))
            .required(t("GenericRequiredText"))
            //.test("emailvalidation", t("EmailIsDuplicate"), function (value) { // Use function
            //    if (formRef.current.values.lastChangedField === "email") {
            //        return httpclientService.get(`/api/profile/isvalueavailable?fieldType=${"email"}&value=${value}`).then((response) => {
            //            return response;
            //        });
            //    } else if (formRef.current.values.lastChangedField === null) {
            //        return true;
            //    } else {
            //        return !formRef.current.errors.email ?? true;
            //    }
            //})
            .max(255, t('Error_TextTooLong', { 1: 255 })),
        groups: Yup.array()
            .min(1, t('GenericRequiredText')),
        contractorID: Yup.lazy(() => { // optional yup required. for rendering, there should be one field change on values, thats why i used currentInspectionStep
            if (formRef.current?.values?.contractorGroupMember) {
                return Yup.number()
                    .required(t("GenericRequiredText"))
                    .min(1, t('GenericRequiredText'));
            } else {
                return Yup.number().notRequired().nullable();
            }
        }),
    });

    function handleCreateUser(values) {
        httpclientService.post(`${api}createnewuser`, values).then((response) => {
            if (response.status && response.status !== 200) {
                alert(response.data.message);
            } else {
                if (props.handleModalClose) {
                    props.handleModalClose();
                } else {
                    navigate("/users");
                }
            }
        });
    }

    function handleGroupChange(event) {
        const {
            target: { value },
        } = event;

        // On autofill we get a stringified value.
        var _newArr = typeof value === 'string' ? value.split(',') : value;//gives selected values in array    
        setSelectedGroups(_newArr.sort((a, b) => a - b));
        formRef.current.setFieldValue("groups", _newArr.sort((a, b) => a - b));

        formRef.current.setFieldValue("contractorGroupMember", _newArr.indexOf(groups.filter(f => f.displayText === 'Dienstleister').map(x => x.key)[0]) > -1);
    }

    function handleImageUpload(selectedImage) {
        initialValues.selectedImage = selectedImage;
        setInitialValues(initialValues);
    }

    const handleSelectedRoles = (selectedItems) => {
        formRef.current.setFieldValue("roles", selectedItems);
    }

    const handleInput = (e) => {
        formRef.current.setFieldValue("lastChangedField", e.target.name);
    };

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    handleCreateUser(values)
                }}
            >
                {formik => (
                    <Form>
                        <div className="omis-header-box">
                            <OmisContainer fluid>
                                <OmisRow>
                                    <OmisCol xs={6} >
                                        <h1>
                                            {`${t("Action_New")} ${t("User")}`}
                                        </h1>
                                    </OmisCol>
                                    <OmisCol style={{ display: 'flex', justifyContent: 'right' }} xs={6}>
                                        <OmisStack direction="horizontal" gap={1}>
                                            {
                                                props.handleModalCancel ?
                                                    <div className="p-2"><OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={props.handleModalCancel}></OmisButtonSecondary></div>                                                    
                                                    :
                                                    <div className="p-2"><OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} component={RouterLink} to={"/users"}></OmisButtonSecondary></div>
                                            }
                                            <div className="p-2"><OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid || submitButtonDisabled} text={t("Action_Save")}></OmisButtonPrimarySubmit></div>
                                        </OmisStack>
                                    </OmisCol>
                                </OmisRow>
                            </OmisContainer>
                        </div>
                       
                        <OmisStack gap={3}>
                            <OmisContainer fluid className="usersGroupCreateCard">
                                <strong>
                                    {t("User")} {t("Details")}
                                </strong>
                                    <OmisRow>
                                        <OmisCol xs={5}>
                                            <OmisCol xs={11}>
                                                <OmisCol xs={12} className="usersAddUserUpload">
                                                    <Box component="span" >
                                                        <span className="usersAddUserUploadText" style={{ display: 'flex', justifyContent: 'center' }}>Profile photo</span>
                                                        <OmisImageUploader name={"selectedImage"} handleUploadClick={handleImageUpload} />
                                                    </Box>
                                                </OmisCol>
                                            <OmisCol xs={12}>
                                                <OmisTextBox labeltext={t("UserName")} name={"username"} placeholder={t("UserName")} onInput={handleInput} required />
                                                </OmisCol>
                                                <OmisCol xs={12}>
                                                <OmisTextBox labeltext={t("FirstName")} name={"firstName"} placeholder={t("FirstName")} onInput={handleInput} required />
                                                </OmisCol>
                                                <OmisCol xs={12}>
                                                <OmisTextBox labeltext={t("LastName")} name={"lastName"} placeholder={t("LastName")} onInput={handleInput} required />
                                                </OmisCol>
                                            </OmisCol>
                                        </OmisCol>
                                        <OmisCol xs={5}>
                                            <OmisCol xs={11}>
                                                <OmisCol xs={12}>
                                                    <Box component="span">
                                                    <OmisTextBox labeltext={t("Email")} name={"email"} placeholder={t("Email")} onInput={handleInput} required />
                                                    <OmisPasswordArea labeltext={t("Password")} name={"password"} placeholder={t("Password")} autoComplete={'new-password'} submitButtonDisabled={setSubmitButtonDisabled} onInput={handleInput} required />
                                                    </Box>
                                                </OmisCol>
                                            </OmisCol>
                                        </OmisCol>
                                    <OmisCol xs={2}>
                                        <br />
                                        <OmisDropdownMultiselectV2 id="groups" name={"groups"} className="groupsDropDown" items={groups} onChange={handleGroupChange} value={formik.values.groups} labeltext={t("Settings_UserGroup")} onInput={handleInput} required />

                                            <br />
                                        <LanguageDropdown languageSelectionCallback={setUserLanguage} userLanguage={userLanguage} onInput={handleInput} />

                                        <br />
                                        <br />
                                        <Roles isEdit selectedRoles={initialValues.groupRoles} handleSelectedRoles={handleSelectedRoles} onInput={handleInput} />
                                        {
                                            formik.values.contractorGroupMember ?
                                                <>
                                                    <br />
                                                    <OmisDropdown id="contractor" name={"contractorID"} className="contractorsDropDown" items={contractors} labeltext={t("Contractors")} required />
                                                </>
                                                :
                                                null
                                        }
                                        </OmisCol>
                                    </OmisRow>
                            </OmisContainer>
                        
                        
                        <OmisContainer fluid className="usersGroupCreateCard">
                            <strong>
                                {t("Contacts")}
                            </strong>
                            <OmisRow>
                                <OmisCol xs={4}>
                                        <OmisTextBox labeltext={t("Address_Mobile")} name={"mobile"} placeholder={t("Address_Mobile")} onInput={handleInput} />
                                </OmisCol>
                                <OmisCol xs={4}>
                                        <OmisTextBox labeltext={t("Phone")} name={"phone"} placeholder={t("Phone")} onInput={handleInput} />
                                </OmisCol>
                               
                            </OmisRow>
                        </OmisContainer>
                        <OmisContainer fluid className="usersGroupCreateCard">
                            <strong>
                                {t("Address")}
                            </strong>
                            <OmisRow>
                                <OmisCol xs={4}>
                                        <OmisTextBox labeltext={t("Address_State")} name={"addressState"} placeholder={t("Address_State")} onInput={handleInput} />
                                        <OmisTextBox labeltext={t("ZipCode")} name={"addressZipCode"} placeholder={t("ZipCode")} onInput={handleInput} />
                                </OmisCol>
                                <OmisCol xs={4}>
                                        <OmisTextBox labeltext={t("Address_FederalState")} name={"addressFederalState"} placeholder={t("Address_FederalState")} onInput={handleInput} />
                                        <OmisTextBox labeltext={t("Street")} name={"addressStreet"} placeholder={t("Street")} onInput={handleInput} />
                                </OmisCol>
                                <OmisCol xs={4}>
                                        <OmisTextBox labeltext={t("City")} name={"addressCity"} placeholder={t("City")} onInput={handleInput} />
                                </OmisCol>

                            </OmisRow>
                            </OmisContainer>
                        </OmisStack>
                    </Form>)}
            </Formik>           
        </>
    );
};