import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import { OmisTimeGap, OmisTimeGapWithWords } from "../shared/OmisDisplays";
import { OmisUserChip } from "../shared/OmisChips";
import { FilterTab } from "./Reports";
import DatehelperService from "../../services/datehelper.service";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../shared/OmisLayouts";

export function TabContent_ActivityHistory(props) {
    const [activities, setActivities] = useState(props.data);
    const [filteredActivities, setFilteredActivities] = useState([]);

    useEffect(() => {
        setFilteredActivities(activities);
    }, [activities]);

    let lastWrittenDate = new Date();

    if (!Array.isArray(filteredActivities)) {
        return <></>;
    }

    return (<>
        <br/>
		<Card>
            <FilterTab
                getSearchResultItems={ setFilteredActivities } listItems={ activities } listAttr={ ["userName", "activity"] }
            />
		</Card>
        <br/>

        {
            filteredActivities.map((item, index) => {
                let itemDate = new Date(item.date);
                let writeDate = false;
                if (!DatehelperService.IsSameDay(lastWrittenDate, itemDate)) {
                    lastWrittenDate = itemDate;
                    writeDate = true;
                }
                return (
                    <>
                        <Card>
                            <OmisContainer fluid key={index}>
                                <OmisRow>
                                    <OmisCol xs={12}>
                                        {
                                            writeDate ?
                                                <OmisCol xs={12}>
                                                    <strong className="detailsCardTitle"><OmisTimeGapWithWords date={item.date} /></strong>
                                                </OmisCol> : null
                                        }
                                    </OmisCol>
                                    <OmisCol xs={12}>
                                        <Card>
                                            <OmisStack direction="horizontal" gap={5}  >
                                                <OmisCol xs={10}>
                                                    <OmisUserChip userFullName={item.userName} userID={item.userID} /> &nbsp;
                                                    {item.activity}
                                                </OmisCol>
                                                <OmisCol xs={2} className="ms-auto justify-content-end">
                                                    <OmisTimeGap date={item.date} />
                                                </OmisCol>
                                            </OmisStack>
                                        </Card>
                                        <br></br>
                                    </OmisCol>
                                </OmisRow>
                            </OmisContainer>
                        </Card>
                        <br/>
                    </>
                   
                );
            })
        }
    </>);
}