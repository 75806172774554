import React, { useState } from "react";
import { t } from "i18next";
import { Link as RouterLink } from "react-router-dom";
import httpclientService from "../../../services/httpclient.service";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisButtonNewItem, OmisIconButton } from "../../shared/OmisButtons";
import { OmisDialog } from "../../shared/OmisDisplays";
import { OmisGrid } from "../../shared/OmisGrids";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { CreateEquiClass } from "./CreateEquiClass";
import { OmisCol, OmisContainer } from "../../shared/OmisLayouts";
import { AddEquiClass } from "../Reports/AddEquiClass";

export function SettingsEquiClasses(props) {
    var url = "/api/settings/settingsequipments/getequiclasses";
    const [modalOpen, setModalOpen] = useState(false);
    const [modalHeight, setModalHeight] = useState(300);
    const [modalWidth, setModalWidth] = useState("md");
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);

    if (props.faultEquiClassDefID) {
        url = `${url}?faultEquiClassDefID=${props.faultEquiClassDefID}`;
    }

    function handleEditClick(rowID, e) {
        if (e) {
            setModalTitle(t("Action_Edit"));
        } else {
            setModalTitle(null);
            if (typeof rowID !== 'number') {
                rowID = null
            }
        }

        var rowVals = { ...e };
        if (props.faultEquiClassDefID && props.faultEquiClassDefID > 0) {
            rowVals.faultEquiClassDefID = props.faultEquiClassDefID;
        }
        setModalHeight(300);
        setModalWidth("md");
        setModalContent(<CreateEquiClass onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID} rowVals={rowVals} />);
        handleModalOpen();
    }
    
    function handleAddEquiClassClick(rowID, e) {
        if (e) {
            setModalTitle(t("Action_Edit"));
        } else {
            setModalTitle(null);
            if (typeof rowID !== 'number') {
                rowID = null
            }
        }

        var rowVals = { ...e };
        if (props.faultEquiClassDefID && props.faultEquiClassDefID > 0) {
            rowVals.faultEquiClassDefID = props.faultEquiClassDefID;
        }
        setModalHeight(600);
        setModalWidth("lg");
        setModalContent(<AddEquiClass onClose={handleModalClose} handleEditCancel={handleModalCancel} id={rowID} rowVals={rowVals} />);
        handleModalOpen();
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setChangeCounter((prevState) => prevState + 1);
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    const handleDelete = (rowID) => {
        if (props.faultEquiClassDefID) {
            httpclientService.post(`/api/settings/settingsequipments/deleteequiclassfromequiclassdef?equiClassID=${rowID}&equiClassDefID=${props.faultEquiClassDefID}`).then((response) => {
                if (response) {
                    setChangeCounter((prevState) => prevState + 1);
                }
            });
        } else {
            httpclientService.post(`/api/settings/settingsequipments/deleteequiclass?id=${rowID}`).then((response) => {
                if (response) {
                    setChangeCounter((prevState) => prevState + 1);
                }
            });
        }
    }

    return (
        <OmisContainer fluid>
            {
                props.faultEquiClassDefID || props.hideBack ?
                    null
                    :
                    <div className="omis-header-box">
                        <OmisCol xs={1}>
                            <OmisIconButton id={"backButton"}
                                iconcontent={<ArrowBackIcon fontSize="inherit" />}
                                text="Back" component={RouterLink} to={"/settings"} state={{ selectedtabvalue: TabConstants.Equipment }} />
                        </OmisCol>
                    </div>
            }

            <OmisGrid actiondropdown={{ edit: handleEditClick, delete: handleDelete }} headerText={t("EquiClasses")}
                newButton={props.faultEquiClassDefID ? <OmisButtonNewItem id={"newEquiClassButton"} onClick={handleAddEquiClassClick} text={t("Action_New")} /> : <OmisButtonNewItem id={"newItemButton"} onClick={handleEditClick} text={t("Action_New")} />}
                apiUrl={url}
                change={changeCounter}
                noHeaderText={props.faultEquiClassDefID}
                noExportButton
                detailsroute="/settings/settingsequipments/equiclassdetails"
                hasdetailview={props.rowclickCallback ? false:true}
                {...props} />

            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                maxWidth={modalWidth}
                title={modalTitle}
                content={modalContent}
                contentHeight={modalHeight}
            />
        </OmisContainer>
    );
}