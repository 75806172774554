import React, { useState, useEffect } from "react";
import { OmisCircularProgress } from "../shared/OmisProgressDisplays";
import httpclientService from "../../services/httpclient.service";
import { OmisBox, OmisDateTime, OmisDialog } from "../shared/OmisDisplays";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { Chip } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { mdiDotsHorizontal } from "@mdi/js";
import { OmisButtonNewItem, OmisButtonPrimarySubmit, OmisButtonSecondary, OmisDropDownMenu, OmisIconButton } from "../shared/OmisButtons";
import { OmisCheckBoxNoForm } from "../shared/OmisInputs";
import { OmisCol, OmisContainer, OmisRow, OmisTabs } from "../shared/OmisLayouts";
import Reports, { FilterTab } from "../reports/Reports";
import Objects from "../objects/Objects";
import Equipment from "../equipment/Equipment";
import { SpaceComponent } from "../shared/Shared";
import { ProjectEdit } from "./ProjectEdit";

export default function Projects(props) {
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [endIndex, setEndIndex] = useState(20);
    const [startIndex, setStartIndex] = useState(0);
    const [projectDisplayList, setProjectDisplayList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [changeCounter, setChangeCounter] = useState(0);
    const showMoreCount = 5;
    const [selectedProject, setSelectedProject] = useState('');
    const [filteredVal, setFilteredVal] = useState([]);

    var url = `/api/projects/getprojects`
    if (props.reportid) {
        url = `${url}?reportid=${props.reportid}`;
    }

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        handleIncreaseCounter();
    };

    const handleModalCancel = () => {
        setModalOpen(false);
    };

    const handleIncreaseCounter = () => {
        setChangeCounter((prevState) => prevState + 1);
    };

    useEffect(() => {
        setProjectDisplayList(projectList.slice(startIndex, endIndex));
        setLoadingMore(false);
    }, [endIndex]);

    useEffect(() => {
        loadList();
    }, []);

    useEffect(() => {
        if (changeCounter > 0) {
            loadList();
        }
    }, [changeCounter]);

    useEffect(() => {
        setSelectedProject(props.selectedProject);
    }, [props.selectedProject]);

    function loadList() {
        httpclientService.get(url).then((response) => {
            setProjectList(response);
            setProjectDisplayList(response.slice(startIndex, endIndex));
            setFilteredVal(response.slice(startIndex, endIndex));
            setLoading(false);
            var possibleSelectedProjects = response.filter((item) => item.isSelectedProject);
            if (possibleSelectedProjects.length > 0) {
                setSelectedProject(possibleSelectedProjects[0].projectID.toString());
            }
        });

        return (() => {
            setProjectList([]);
            setProjectDisplayList([]);
        })
    }

    function handleSave(values) {
        httpclientService.post("/api/projects/saveproject", JSON.stringify(values)).then((response) => {
            if (response) {
                handleModalClose();
            }
        })
    }

    function CheckChange(event) {
        if (event.target.checked) {
            setSelectedProject(event.target.id);
        } else {
            setSelectedProject('');
        }
        props.checkChange(event);
    }

    return (
		<>
            <div className="omis-header-box">
                <OmisContainer fluid>
                    <OmisRow>
                        <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'left' }}>
                            <div className="omis-header-title-details">{t("Projects")}</div>
                        </OmisCol>
                        {
                            props.isSelectList ?
                                null :
                                <OmisCol xs={6} style={{ display: 'flex', justifyContent: 'right' }}>
                                    <OmisCol xs={3} style={{ display: 'flex', justifyContent: 'right' }}>
                                        <OmisButtonNewItem id={"newButton"} text={t("Action_Add")} onClick={handleModalOpen} />
                                    </OmisCol>
                                </OmisCol>
                        }
                    </OmisRow>
                </OmisContainer>
            </div>
            {
                loading ? <OmisCircularProgress size={40} /> : (
                    <>
                        <FilterTab getSearchResultItems={setFilteredVal} listItems={projectDisplayList} listAttr={['description']} />
                        <OmisContainer fluid>
                            <OmisCol xs={12}>
                                {
                                    filteredVal.map((n) => <ProjectRow key={n.projectID} handleSave={handleSave}
                                        isSelectList={props.isSelectList} selectedProject={selectedProject} checkChange={CheckChange} state={n} increaseCounterCallback={handleIncreaseCounter} {...n} />)
                                }
                            </OmisCol>
                        </OmisContainer>
                    </>
                )
            }
            <OmisContainer fluid>
                {
                    loadingMore ? <OmisCircularProgress size={40} /> : null
                }
                {
                    props.isSelectList ?
                        <OmisRow>
                            <OmisCol xs={6}><OmisButtonPrimarySubmit id={"submitButton"} text={t("Action_Save")} disabled={selectedProject === ''} onClick={() => { props.handleAddToProjectSave(selectedProject); }}></OmisButtonPrimarySubmit> </OmisCol>
                            <OmisCol xs={6}><OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={props.handleModalCancel}></OmisButtonSecondary> </OmisCol>
                        </OmisRow>
                        :
                        null
                }
            </OmisContainer>
            
            <OmisDialog onClose={handleModalCancel} open={modalOpen}
                fullWidth={true}
                maxWidth={'sm'}
                title={t("New_Project")}
                content={<ProjectEdit handleSave={handleSave} handleModalCancel={handleModalCancel} handleModalClose={handleModalClose} />}
                contentHeight={300}
            />
        </>
    );
}

function ProjectRow(props) {
    const [editModalOpen, setEditModalOpen] = useState(false);
    const navigate = useNavigate();

    const handleModalClose = () => {
        setEditModalOpen(false);
    };

    function handleEditOpen() {
        setEditModalOpen(true);
    }

    function handleArchive(projectID) {
        httpclientService.post(`/api/projects/archiveproject?projectID=${projectID}`).then((response) => {
            if (response && props.increaseCounterCallback) {
                props.increaseCounterCallback();
            }
        });
    }

    const childItemsForDotsMenu = [
        {
            menuName: t("Details"),
            toLink: "/projects/details",
            state: { ...props.state } 
        },
        {
            menuName: t("Action_Edit"),
            onClick: handleEditOpen,
            state: { ...props.state } 
        },
        {
            menuName: t("Action_Archive"),
            onClick: handleArchive.bind(this,props.projectID)
        }
    ]

    function handleSave(values) {
        if (props.handleSave) {
            props.handleSave(values);
        }

        if (editModalOpen) {
            handleModalClose();
        }
    }

    function handleBoxClick(projectID) {
        navigate("/projects/details", { replace: true, state: { ...props.state } });
    }

    function PrepareRowData() {
        return (
            <>
                <Chip clickable={true} variant="outlined" label={<strong>{props.description}</strong>} />
                <SpaceComponent />
                {
                    props.beginDate ?
                        <span>
                            {t("From")}
                            <SpaceComponent />
                            <strong><OmisDateTime date={new Date(props.beginDate)} /></strong>
                        </span>
                        :
                        null
                }
                <SpaceComponent />
                {
                    props.endDate ?
                        <span>
                            {t("To")}
                            <SpaceComponent />
                            <strong><OmisDateTime date={new Date(props.endDate)} /></strong>
                        </span>
                        :
                        null
                }
            </>        
        );
    }

    return (
        <OmisBox className={"omis-projects-row"}>
            {
                props.isSelectList ?
                    <OmisRow>
                        <OmisCol xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <OmisCheckBoxNoForm id={props.projectID.toString()} checked={props.selectedProject === props.projectID.toString()} onClick={props.checkChange} />
                            <PrepareRowData />
                        </OmisCol>
                    </OmisRow>
                    :
                    <OmisRow>
                        <OmisCol xs={10} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }} onClick={() => { handleBoxClick(props.projectID.toString()); } }>
                            <PrepareRowData />
                        </OmisCol>
                        <OmisCol xs={2} style={{ display: 'flex', justifyContent:'right', alignItems: 'center' }}>
                            <OmisDropDownMenu menuicon={mdiDotsHorizontal} childitems={childItemsForDotsMenu} />
                        </OmisCol>
                    </OmisRow>
            }

            <OmisDialog onClose={handleModalClose} open={editModalOpen}
                title={t("Action_Edit")}
                content={<ProjectEdit handleSave={handleSave} handleModalCancel={handleModalClose} handleModalClose={handleModalClose} state={props} />}
                contentHeight={300}
            />
        </OmisBox>
    );
}

export function ProjectDetails() {
    var { state } = useLocation();

    const tabs = [
        {
            title: t("FaultReports"),
            content: <TabContent_Reports state={state} />
        },
        {
            title: t("Objects"),
            content: <TabContent_Objects state={state} />
        },
        {
            title: t("Equipment"),
            content: <TabContent_Equipment state={state} />
        },

    ]
    return (
        <OmisContainer fluid>
            <OmisRow>
                <OmisCol xs={1}>
                    <OmisIconButton id={"backButton"} iconcontent={<ArrowBackIcon fontSize="inherit" />} text="Back" component={RouterLink} to={"/projects"} />
                </OmisCol>
                <OmisCol xs={4}>
                    <h5><strong>{state.description}</strong></h5>
                </OmisCol>
            </OmisRow>
            <OmisRow>
                <OmisTabs tabs={tabs} />
            </OmisRow>
        </OmisContainer>
    );
}

function TabContent_Reports(props) {
    var { state } = useLocation();

    return (
        <Reports noButtons noHeaderText projectid={state.projectID} />
    );
}

function TabContent_Objects(props) {
    var { state } = useLocation();

    return (
        <Objects noButtons noHeaderText projectid={state.projectID} />
    );
}

function TabContent_Equipment(props) {
    var { state } = useLocation();

    return (
        <Equipment noButtons noHeaderText projectid={state.projectID} />
    );
}