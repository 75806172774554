import React, { useRef, useState} from "react";
import "./OmisButtons.scss"
import { Link as RouterLink } from "react-router-dom";
import { Button, IconButton, Menu, MenuItem, Popover, LinearProgress } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import {mdiExportVariant, mdiFileUpload, mdiInboxFull, mdiPlusBox} from "@mdi/js";
import Icon from "@mdi/react";
import {useTranslation} from "react-i18next";
import { t } from "i18next";
import { mdiChevronDown, mdiChevronRight } from "@mdi/js";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Dropzone from 'react-dropzone'

export function OmisButtonPrimarySubmit(props) {
    var displayText = props.text;

    if (!props.id) {
        displayText = `${displayText} [ID missing]`;
    }
    return (

        <Button variant={"contained"} color={"primary"} size={"medium"} className="omis-button omis-button-primary LoginButton" type="submit" {...props}>{displayText}</Button>
    );
}

export function OmisButtonPrimary(props) {
    var displayText = props.text;

    if (!props.id) {
        displayText = `${displayText} [ID missing]`;
    }
    return (
        <Button component={"label"} variant={"contained"} color={"primary"} size={"medium"} className="omis-button omis-button-primary" {...props}>
            {displayText}
            {
                props.onChange ? props.isimage === 1 ? <input id="input-file-upload" type="file" accept="image/*" hidden onChange={props.onChange} disabled={props.disabled} /> : <input id="input-file-upload" type="file" multiple hidden onChange={props.onChange} disabled={props.disabled} /> : ""
            }
        </Button>
    );
}

export function OmisButtonSecondary(props) {
    var displayText = props.text;

    if (!props.id) {
        displayText = `${displayText} [ID missing]`;
    }
    return (
        <Button variant="contained" color={"secondary"} size={props.size ? props.size : "medium"} className="omis-button omis-button-secondary" {...props}>{displayText}</Button>
    );
}

export function OmisButtonError(props) {
    var displayText = props.text;

    if (!props.id) {
        displayText = `${displayText} [ID missing]`;
    }
    return (
        <Button variant="outlined" color={"error"} size={"medium"} className="omis-button" {...props}>{displayText}</Button>
    );
}

export function OmisButtonExportCSV(props) {
    const { t } = useTranslation();
    return (
        OmisButtonSecondary({
            text: t("ExcelExport"),
            startIcon: <Icon path={mdiExportVariant} size={1}></Icon>
        })
    );
}

function DragDropFile(props) {
    // drag state
    const [dragActive, setDragActive] = useState(false);

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    return (
        <div id="div-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()} >
            <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                <div>
                    <p>{props.labeltext}</p>
                    <OmisPrimaryButtonWithStartIcon id={"uploadFileButton"} disabled={props.disabled} onChange={props.onChange} text={t("Select_Files")} icon={<Icon path={mdiFileUpload} size={1}></Icon>} />
                </div>
            </label>
        </div>
    );
};

function DragDropFileV2(props) {

    return (
        <Dropzone onDrop={props.onChange} >
            {({ getRootProps, getInputProps }) => (
                <section className="documentUploadDiv" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>{props.labeltext}</p>
                    <Icon path={mdiFileUpload} size={1}></Icon>
                </section>
            )}
        </Dropzone>
    )
};

export function OmisButtonUploadFiles(props) {
    return (
        <DragDropFileV2 {...props} />
        //<OmisPrimaryButtonWithStartIcon onChange={props.onChange} text={t("Select_Files")} icon={<Icon path={mdiFileUpload} size={1}></Icon>} />
    );
}

export function OmisButtonUploadImages(props) {
    return (
        <OmisPrimaryButtonWithStartIcon onChange={props.onChange} text={t("Common_Select")} icon={<AddPhotoAlternateIcon />} {...props} />
    );
}

export function OmisButtonNewReport(props) {
    return (
        <OmisPrimaryButtonWithStartIcon {...props} text={t("Action_New_Report")} icon={<Icon path={mdiInboxFull} size={1}></Icon>} />
    );
}

export function OmisButtonDiscard(props) {
    var displayText = props.text;

    if (!props.id) {
        displayText = `${displayText} [ID missing]`;
    }
    return (
        <Button variant={"contained"} color={"error"} size={"medium"} className={"omis-button omis-button-primary"} {...props}>{displayText}</Button>
    );
}

export function OmisButtonNewItem(props) {
    return (
        <OmisPrimaryButtonWithStartIcon text={props.text} icon={<Icon path={mdiPlusBox} size={1}></Icon>} onClick={props.onClick} {...props} />
    );
}
export function OmisPriButtonWithStartIcon(props) {
    return (
        <OmisPrimaryButtonWithStartIcon text={props.text} icon={<Icon path={props.iconpath} size={1}></Icon>} onClick={props.onClick} {...props} state={props.state} />
    );
}

export function OmisSecButtonWithStartIcon(props) {
    return (
        <OmisSecondaryButtonWithStartIcon text={props.text} icon={<Icon path={props.iconpath} size={1}></Icon>} onClick={props.onClick} {...props} />
    );
}

export function OmisErrButtonWithStartIcon(props) {
    return (
        <OmisErrorButtonWithStartIcon text={props.text} icon={<Icon path={props.iconpath} size={1}></Icon>} onClick={props.onClick} {...props} />
    );
}

export function OmisLoadingButton(props) {
    return (
        <LoadingButton {...props} />
    );
}

export function OmisProgress(props) {
    return (
        <LinearProgress {...props} />
    );
}

function OmisPrimaryButtonWithStartIcon(props) {
    return (
        OmisButtonPrimary({
            onClick: props.onClick,
            text: props.text,
            startIcon: props.icon,
            onChange: props.onChange,
            ...props
        })
    );
}

function OmisSecondaryButtonWithStartIcon(props) {
    return (
        OmisButtonSecondary({
            onClick: props.onClick,
            text: props.text,
            startIcon: props.icon,
            onChange: props.onChange,
            size: props.size,
            ...props
        })
    );
}


function OmisErrorButtonWithStartIcon(props) {
    return (
        OmisButtonError({
            onClick: props.onClick,
            text: props.text,
            startIcon: props.icon,
            onChange: props.onChange,
            ...props
        })
    );
}

export function OmisIconButton(props) {
    var displayText = props.text;

    if (!props.id) {
        displayText = `${displayText} [ID missing]`;
    }
    return (
        <IconButton className="omis-button-icon" size="small"  {...props} onClick={props.onClick}>
            {props.iconcontent ? props.iconcontent : null}
            {displayText ? <span>{displayText}</span> : null}            
        </IconButton>
    );
}

export function OmisDropDownMenu(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const iconposition = props.iconposition ?? "left";
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        props.childitems && props.childitems.filter((f) => !f.hideMenuItem).length > 0 ?
            <div>
                <IconButton
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    size="small"
                    className="omis-dropdown-menu-button"
                >
                    {
                        props.menuicon && iconposition === "left" ?
                            <Icon path={props.menuicon} size={1} />
                            :
                            null
                    }
                    {props.menutitle ? <span>{props.menutitle}</span> : null}

                    {
                        props.menuicon && iconposition === "right" ?
                            <Icon path={props.menuicon} size={1} />
                            :
                            null
                    }
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onMouseLeave={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }} //can be set which side you want to open menu
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} //can be set the location of menu here when its expanded
                >
                    {
                        props.childitems !== null ? props.childitems.map((item, index) => (
                            index < props.childitems.length ?
                                item.hideMenuItem ?
                                    null
                                    :
                                    item.subMenuIcon ?
                                        item.onClick ?
                                            <MenuItem id={props.rowid} onClick={item.onClick.bind(this, props.rowid, props.rowVals)} state={item.state ? item.state : props.state} key={index.toString() + "-menuItem"}>
                                                <Icon path={item.subMenuIcon} size={1} className={ item.iconclassName } /> {item.menuName}
                                            </MenuItem>
                                            :
                                            item.toLink ?
                                                <MenuItem id={props.rowid} onMouseEnter={(e) => e.target.style.color = '#495054'} component={RouterLink} to={item.toLink} state={item.state ? item.state : props.state} {...props} key={index.toString() + "-menuItem"}>
                                                    <Icon path={item.subMenuIcon} size={1} className={item.iconclassName} /> {item.menuName}
                                                </MenuItem> :
                                                null
                                        : item.onClick ?
                                            <MenuItem id={props.rowid} onClick={item.onClick.bind(this, props.rowid, props.rowVals)} state={item.state ? item.state : props.state} key={index.toString() + "-menuItem"}>
                                                {item.menuName}
                                            </MenuItem>
                                            :
                                            item.toLink ?
                                                <MenuItem id={props.rowid} onMouseEnter={(e) => e.target.style.color = '#495054'} component={RouterLink} to={item.toLink} state={item.state ? item.state : props.state} {...props} key={index.toString() + "-menuItem"}>
                                                    {item.menuName}
                                                </MenuItem>
                                                :
                                                null
                                :
                                null
                        )
                        ) : null
                    }
                </Menu>
            </div>
            :
            null
    );
} 

export function OmisDropDownWithDynamicComponents(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="contained"
                size={props.size ? props.size : "medium"}
                className={props.filterscontainer === 1 ? null : "omis-button omis-button-secondary"}
                {...props}
                sx={{ height:'2.6015em !important'}}
            >
                {props.menuicon ? <Icon path={props.menuicon} style={{ overflow: 'visible' }} size={1} /> : null}
                {props.menutitle ? <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', minWidth:20 }}>{props.menutitle}</span> : null}
                {props.showdownarrow === 1 && props.childitems.length > 0 ? <Icon path={open ? mdiChevronDown : mdiChevronRight} style={{ overflow: 'visible' }} size={1}></Icon> : null}
            </IconButton>
            {
                props.childitems && props.childitems.length > 0 ?
                    <Popover
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        onMouseLeave={props.isfilter && props.isfilter ===1 ? null : handleClose}
                        transformOrigin={{ horizontal: props.horizontal ? props.horizontal: 'right', vertical: 'top' }} //can be set which side you want to open menu
                        anchorOrigin={{ horizontal: props.horizontal ? props.horizontal : 'right', vertical: 'bottom' }} //can be set the location of menu here when its expanded                
                    >

                        {
                            props.childitems !== null ? props.childitems.map((item, index) => (
                                index < props.childitems.length ?
                                    <div key={index.toString() + "-menuItem"} className={item.className ? item.className : ""}>
                                        {item.content}
                                    </div>
                                    : null
                            )
                            ) : null
                        }
                    </Popover>
                    :
                    null
            }
        </div>
    );
} 

export function OmisCopyButton(props) {

    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef(null);

    function copyClicked() {
        try {
            navigator.clipboard.writeText(props.text);
        } catch (err) {
            var textField = document.createElement('textarea');
            textField.innerText = props.text;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
        }
        setCopySuccess('Copied!');
        setTimeout(clearMessage, 3000);
    }

    function clearMessage() {
        setCopySuccess('');
    }

    return (
        <>
            <IconButton aria-label="delete" size="small" onClick={copyClicked}>
                <ContentCopyIcon fontSize="inherit" />
            </IconButton>
            <span className="text-success">{copySuccess}</span>
        </>
    );
}