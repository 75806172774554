import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { Link as RouterLink } from "react-router-dom";
import { Form, Formik } from "formik";
import { TabConstants } from "../../../constants/SettingConstants";
import { OmisButtonPrimarySubmit, OmisButtonSecondary, OmisIconButton } from "../../shared/OmisButtons";
import { OmisGrid } from "../../shared/OmisGrids";
import { Card } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { OmisTextBox } from "../../shared/OmisInputs";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

var state = { selectedtabvalue: TabConstants.FSMIntern }

export function SettingsUsageLogs(props) {
    var baseUrl = "/api/settings/settingsfsmintern/getusagelogs";

    const formRef = useRef();
    const [url, setUrl] = useState(baseUrl);
    const [changeCounter, setChangeCounter] = useState(0);

    const [initialValues, setInitialValues] = useState({
        hours: 1,
        username: "",
    });

    useEffect(() => {
        setChangeCounter((prevState) => prevState + 1);
    }, [url]);

    function applyFilter(values){
        setUrl(`${baseUrl}?userName=${values.username}&hours=${values.hours}`);
    }
    
    function handleDelete() {
        formRef.current.setFieldValue("hours", 1);
        formRef.current.setFieldValue("username", "");
        setUrl(baseUrl);
    }

    return (
        <OmisContainer fluid>

            <div className=" omis-header-box">

                <OmisCol xs={1}>
                    <OmisIconButton id={"backButton"}
                        iconcontent={<ArrowBackIcon fontSize="inherit" />}
                        text="Back" component={RouterLink} to={"/settings"} state={state ?? { selectedtabvalue: TabConstants.FSMIntern }} />
                </OmisCol>

                {
                    initialValues.id === null ?
                        <h1>{t("UsageLog")}</h1>
                        :
                        null
                }

                <OmisRow>
                    <Card>
                        <Formik
                            innerRef={formRef}
                            validateOnMount
                            enableReinitialize={true}
                            initialValues={initialValues}
                            onSubmit={(values) => { applyFilter(values) }}
                        >
                            {formik => (
                                <Form>

                                        <OmisRow>
                                            <OmisCol xs={3}>
                                                <OmisTextBox labeltext={t("DisplayFromLastXHours")} name="hours" />
                                            </OmisCol>
                                            <OmisCol xs={3}>
                                                <OmisTextBox labeltext={t("UserName")} name="username" />
                                            </OmisCol>
                                        </OmisRow>
                                        <OmisRow>
                                            <OmisCol xs={6}>
                                                <OmisButtonSecondary  id={"cancelButton"}text={t("Action_Delete")} onClick={handleDelete}></OmisButtonSecondary>
                                            </OmisCol>
                                            <OmisCol xs={6}>
                                                <OmisButtonPrimarySubmit id={"submitButton"} text={t("Action_Search")}></OmisButtonPrimarySubmit>
                                            </OmisCol>
                                        </OmisRow>
                                </Form>
                            )}
                        </Formik>
                    </Card>
                </OmisRow>

                <OmisGrid headerText={t("UsageLog")}
                    apiUrl={url}
                    change={changeCounter}
                    noExportButton
                    {...props} />
            </div>
        </OmisContainer>
    );
}