import React, { useEffect, useRef, useState } from "react";
import httpclientService from "../../services/httpclient.service";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { OmisButtonPrimarySubmit } from "../shared/OmisButtons";
import { OmisButtonSecondary } from "../shared/OmisButtons";
import { OmisPasswordArea, OmisTextBox } from "../shared/OmisInputs";
import { OmisDropdown, OmisDropdownMultiselectV2 } from "../shared/OmisDropdowns";
import { Roles } from "./Roles";
import LanguageDropdown from "../profile/LanguageDropdown";
import AuthService from "../../services/auth.service";
import { OmisCol, OmisContainer, OmisRow, OmisStack } from "../shared/OmisLayouts";

var api = "/api/profile/";

export default function EditUser(props) {

    const navigate = useNavigate();

    const formRef = useRef();
    const [userLanguage, setUserLanguage] = useState("");
    const [contractors, setContractors] = useState([]);
    const [initialValues, setInitialValues] = useState({
        username: '',
        firstName: '',
        lastName: '',
        password: '',
        email: '',
        mobile: '',
        phone: '',
        addressState: '',
        addressFederalState: '',
        addressCity: '',
        addressZipCode: '',
        addressStreet: '',
        groups: [],
        roles: [],
        selectedImage: null,
        userLanguage: userLanguage,
        lastChangedField: '',
        contractorID: props.contractorID ?? null,
        contractorGroupMember: false
    });

    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

    useEffect(() => {

        httpclientService.get(`${api}getgroups`).then((response) => {
            if (response && response.length) {
                var groupsList = response.map((group) => ({
                    key: group.id, displayText: group.description
                }));
                setGroups(groupsList);

                return groupsList;
            }
        }).then((groups) => {
            if (props.userID) {
                httpclientService.get(`${api}getuserinfo?userID=${props.userID}`).then((response) => {
                    if (response) {
                        var values = {
                            id: props.userID,
                            username: response.username ? response.username : '',
                            firstName: response.firstName ?? '',
                            lastName: response.lastName ?? '',
                            password: '',
                            email: response.email ?? '',
                            mobile: response.mobile ?? '',
                            phone: response.phone ??'',
                            addressState: response.addressState ?? '',
                            addressFederalState: response.addressFederalState ?? '',
                            addressCity: response.addressCity ?? '',
                            addressZipCode: response.addressZipCode ?? '',
                            addressStreet: response.addressStreet ?? '',
                            groups: response.groups ?? [],
                            roles: response.roles ?? [],
                            userLanguage: response.language ?? null,
                            contractorID: props.contractorID ?? response.contractorID ?? null,
                            contractorGroupMember: response.groups?.indexOf(groups.filter(f => f.displayText === 'Dienstleister').map(x => x.key)[0]) > -1
                        };
                        setInitialValues(values);

                        setUserLanguage(response.language ?? null);
                        setSelectedGroups(response.groups);
                        setSelectedRoles(response.roles.map((item) => ({ id: item.id, nameRole: '' })));
                        formRef.current.setValues(values);

                        const keys = Object.keys(values);
                        keys.forEach(key => {
                            if (values[key]) {
                                formRef.current.setFieldTouched(`${key}`, true, true);
                            }
                        });                        
                    }
                });
            }
        });

        httpclientService.get(`${api}getcontractorsfordropdown`).then((response) => {
            if (response && response.length > 0) {
                setContractors(response);
            }
        });
    }, []);

    useEffect(() => {
        formRef.current.setFieldValue("userLanguage", userLanguage);
    }, [userLanguage]);

    function handleEditUser(values) {
        httpclientService.post(`${api}edituser`, values).then((response) => {
            if (response) {
                if (response.status && response.status !== 200) {
                    alert(response.data.message);
                } else {
                    if (props.handleModalClose) {
                        props.handleModalClose();
                    } else {
                        navigate("/users");
                    }
                }
            }
        }).then(() => {
            AuthService.updateCurrentUser();
        });
    }

    function handleGroupChange(event) {
        const {
            target: { value },
        } = event;

        // On autofill we get a stringified value.
        var _newArr = typeof value === 'string' ? value.split(',') : value;//gives selected values in array    
        setSelectedGroups(_newArr.sort((a, b) => a - b));
        formRef.current.setFieldValue("groups", _newArr.sort((a, b) => a - b));

        formRef.current.setFieldValue("contractorGroupMember", _newArr.indexOf(groups.filter(f => f.displayText === 'Dienstleister').map(x => x.key)[0]) > -1);
    }

    const handleSelectedRoles = (selectedItems) => {
        formRef.current.setFieldValue("roles", selectedItems);
        setSelectedRoles(selectedItems);
    }


    const validationSchema = Yup.object({
        username: Yup.string()
            .required(t("GenericRequiredText"))
            .max(200, t('Error_TextTooLong', { 1: 200 })),
        firstName: Yup.string()
            .required(t("GenericRequiredText"))
            .max(60, t('Error_TextTooLong', { 1: 60 })),
        lastName: Yup.string()
            .required(t("GenericRequiredText"))
            .max(60, t('Error_TextTooLong', { 1: 60 })),
        phone: Yup.string()
            .nullable()
            .max(20, t('Error_TextTooLong', { 1: 20 }))
            .matches(phoneRegExp, t("PhoneNumberFormatInvalid")),
        mobile: Yup.string()
            .nullable()
            .max(20, t('Error_TextTooLong', { 1: 20 }))
            .matches(phoneRegExp, t("PhoneNumberFormatInvalid")),
        email: Yup.string().email(t("OnlineImport_InvalidFormat"))
            .required(t("GenericRequiredText"))
            //.test("emailvalidation", t("EmailIsDuplicate"), function (value) { // Use function
            //    if (formRef.current.values.lastChangedField === "email") {
            //        return httpclientService.get(`/api/profile/isvalueavailable?fieldType=${"email"}&value=${value}&userName=${formRef.current.values.username}`).then((response) => {
            //            return response;
            //        });
            //    } else if (formRef.current.values.lastChangedField === null) {
            //        return true;
            //    } else {
            //        return !formRef.current.errors.email ?? true;
            //    }
            //})
            .max(255, t('Error_TextTooLong', { 1: 255 })),
        groups: Yup.array()
            .min(1, t('GenericRequiredText')),
        contractorID: Yup.lazy(() => { // optional yup required. for rendering, there should be one field change on values, thats why i used currentInspectionStep
            if (formRef.current?.values?.contractorGroupMember) {
                return Yup.number()
                    .required(t("GenericRequiredText"))
                    .min(1, t('GenericRequiredText'));
            } else {
                return Yup.number().notRequired().nullable();
            }
        }),
    });

    const handleInput = (e) => {
        formRef.current.setFieldValue("lastChangedField", e.target.name);
    };

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                    handleEditUser(values)
                }}
            >
                {formik => (
                    <Form>
                        <OmisContainer fluid>
                            <OmisRow>                                
                                <OmisCol xs={12}>
                                    <strong>
                                        {t("User")} {t("Details")}
                                    </strong>
                                </OmisCol>
                                <OmisRow>
                                    <OmisStack gap={2} direction="horizontal">
                                        <OmisCol xs={4}>
                                            <OmisStack gap={2} direction="verical">
                                                <OmisCol xs={12}>
                                                    <OmisTextBox labeltext={t("UserName")} name={"username"} placeholder={t("UserName")} disabled={true} onInput={handleInput} required />
                                                </OmisCol>
                                                <OmisCol xs={12}>
                                                    <OmisTextBox labeltext={t("FirstName")} name={"firstName"} placeholder={t("FirstName")} onInput={handleInput} required />
                                                </OmisCol>
                                                <OmisCol xs={12}>
                                                    <OmisTextBox labeltext={t("LastName")} name={"lastName"} placeholder={t("LastName")} onInput={handleInput} required />
                                                </OmisCol>
                                            </OmisStack>
                                        </OmisCol>
                                        <OmisCol xs={4} style={{marginBottom:'10px'} }>
                                            <OmisStack gap={2} direction="verical">
                                                <OmisCol xs={12}>
                                                    <OmisTextBox labeltext={t("Email")} name={"email"} placeholder={t("Email")} onInput={handleInput} required />
                                                </OmisCol>
                                                <OmisCol xs={12}>
                                                    <OmisPasswordArea labeltext={t("Password")} name={"password"} placeholder={t("Password")} autoComplete={'new-password'} ignoreempty={1} submitButtonDisabled={setSubmitButtonDisabled} onInput={handleInput} />
                                                    {/*<OmisPasswordTextBox labeltext={t("Password")} name={"password"} placeholder={t("Password")} autoComplete={'new-password'} />*/}
                                                </OmisCol>
                                            </OmisStack>
                                        </OmisCol>

                                        <OmisCol xs={4} style={{ marginTop: '1em' }}>
                                            <OmisStack gap={2} direction="verical">
                                                <OmisCol xs={12}>
                                                    <OmisDropdownMultiselectV2 labeltext={t("Settings_UserGroup")} id="groups" name={"groups"} className="groupsDropDown" items={groups} onChange={handleGroupChange} value={selectedGroups} onInput={handleInput} required />
                                                </OmisCol>
                                                <OmisCol xs={12}>
                                                    <br/>
                                                    <LanguageDropdown languageSelectionCallback={setUserLanguage} userLanguage={formik.values.userLanguage} onInput={handleInput} />
                                                </OmisCol>
                                                <OmisCol xs={12}>
                                                    <br />
                                                    <Roles isEdit selectedRoles={selectedRoles} handleSelectedRoles={handleSelectedRoles} onInput={handleInput} />
                                                </OmisCol>

                                                {
                                                    formik.values.contractorGroupMember ?
                                                        <OmisCol xs={12}>
                                                            <br />
                                                            <OmisDropdown id="contractor" name={"contractorID"} className="contractorsDropDown" items={contractors} labeltext={t("Contractors")} required />
                                                        </OmisCol>
                                                        :
                                                        null
                                                }
                                            </OmisStack>
                                        </OmisCol>
                                    </OmisStack>
                                </OmisRow>
                                <OmisRow>
                                    <br />
                                    <br />
                                </OmisRow>

                                <OmisCol xs={12}>
                                    <strong>
                                        {t("Contacts")}
                                    </strong>
                                </OmisCol>
                                <OmisStack gap={2} direction="horizontal">
                                    <OmisCol xs={4}>
                                        <OmisTextBox labeltext={t("Address_Mobile")} name={"mobile"} placeholder={t("Address_Mobile")} onInput={handleInput} />
                                    </OmisCol>
                                    <OmisCol xs={4}>
                                        <OmisTextBox labeltext={t("Phone")} name={"phone"} placeholder={t("Phone")} onInput={handleInput} />
                                    </OmisCol>
                                </OmisStack>

                                <OmisCol xs={12}>
                                    <strong>
                                        {t("Address")}
                                    </strong>
                                </OmisCol>

                                <OmisStack gap={2} direction="horizontal">

                                    <OmisCol xs={4}>
                                        <OmisTextBox labeltext={t("Address_State")} name={"addressState"} placeholder={t("Address_State")} onInput={handleInput} />
                                    </OmisCol>
                                    <OmisCol xs={4}>
                                        <OmisTextBox labeltext={t("Address_FederalState")} name={"addressFederalState"} placeholder={t("Address_FederalState")} onInput={handleInput} />
                                    </OmisCol>
                                    <OmisCol xs={4}>
                                        <OmisTextBox labeltext={t("City")} name={"addressCity"} placeholder={t("City")} onInput={handleInput} />
                                    </OmisCol>
                                </OmisStack>
                                <OmisStack gap={2} direction="horizontal">
                                    <OmisCol xs={4}>
                                        <OmisTextBox labeltext={t("ZipCode")} name={"addressZipCode"} placeholder={t("ZipCode")} onInput={handleInput} />
                                    </OmisCol>
                                    <OmisCol xs={4}>
                                        <OmisTextBox labeltext={t("Street")} name={"addressStreet"} placeholder={t("Street")} onInput={handleInput} />
                                    </OmisCol>
                                    <OmisCol xs={4}>
                                        {/*<OmisTextBox labeltext={t("Address_SalesRegion")} name={"addressSalesRegion"} placeholder={t("Address_SalesRegion")} />*/}
                                    </OmisCol>
                                </OmisStack>
                            </OmisRow>

                            <OmisRow>
                                <OmisStack gap={2} direction="horizontal">
                                    <OmisCol xs={6}>
                                        {
                                            props.handleModalCancel ?
                                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={props.handleModalCancel}></OmisButtonSecondary>
                                                :
                                                <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} component={RouterLink} to={"/users"}></OmisButtonSecondary>
                                        }
                                    </OmisCol>
                                    <OmisCol xs={6}>
                                        <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid || submitButtonDisabled} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                    </OmisCol>
                                </OmisStack>
                            </OmisRow>
                        </OmisContainer>
                    </Form>)}
            </Formik>
        </>
    );
};