import React, { useEffect, useRef, useState } from "react";
import httpclientService from "../../../services/httpclient.service";
import * as Yup from "yup";
import { t } from "i18next";
import { Form, Formik } from "formik";
import { OmisButtonNewItem, OmisButtonPrimary, OmisButtonPrimarySubmit, OmisButtonSecondary, OmisIconButton, OmisProgress, OmisSecButtonWithStartIcon } from "../../shared/OmisButtons";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Card, IconButton } from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";
import Icon from "@mdi/react";
import { mdiTrashCan, mdiPlusBoxOutline, mdiPlusBox } from "@mdi/js";
import { OmisTextBox, OmisTextBoxNoForm } from "../../shared/OmisInputs";
import { DocumentUploadForNewReport } from "../../documents/Documents";
import { DMSDocumentReferer } from "../../../constants/DMSDocumentReferer";
import { OmisDropdown, OmisDropdownMultiselect } from "../../shared/OmisDropdowns";
import { OmisDialog, OmisLabel, OmisSelectedCatalogueCard } from "../../shared/OmisDisplays";
import { DynamicOpeningTimeBuilder } from "./DynamicOpeningTimeBuilder";
import { OmisCol, OmisContainer, OmisRow, OmisStack, OmisSticky } from "../../shared/OmisLayouts";
import { OmisGrid } from "../../shared/OmisGrids";
import { CreateObjectAttributeDef } from "../../settings/Objects/CreateObjectAttributeDef";
import { SetEmptyValuesToNull, generateUniqueRandom } from "../../shared/Shared";

export function ObjectCreate(props) {

    const formRef = useRef();
    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState(1);
    const [tradeLevelsList, setTradeLevelsList] = useState([]);
    const [operationKindsList, setOperationKindsList] = useState([]);
    const [operationLabelsList, setOperationLabelsList] = useState([]);
    const [salesAreasList, setSalesAreasList] = useState([]);
    const [statusList, setStatuslist] = useState([]);
    const [daysList, setDaysList] = useState([]);
    const [removeFromDaysList, setRemoveFromDaysList] = useState([]);
    const [openingTimes, setOpeningTimes] = useState([{ id: new Date().getTime(), openingTimeID: null, selectedDays: null, fromTime: null, toTime: null }]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [possibleContactList, setPossibleContactList] = useState([]);


    const defaultAttribute = [
        {
            id: 0,
            objectID:0,
            attributeVal: null,
            attributeDefID: null,
            attributeDefText: null,
        }
    ];

    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenForAttrDef, setModalOpenForAttrDef] = useState(false);
    const [modalContentForAttrDef, setModalContentForAttrDef] = useState(null);

    useEffect(() => {

        httpclientService.get("/api/objects/gettradelevels").then((response) => {
            if (response && response.length > 0) {
                setTradeLevelsList(response);
            }
        });

        httpclientService.get("/api/objects/getoperationkinds").then((response) => {
            if (response && response.length > 0) {
                setOperationKindsList(response);
            }
        });

        httpclientService.get("/api/objects/getoperationlabels").then((response) => {
            if (response && response.length > 0) {
                setOperationLabelsList(response);
            }
        });

        httpclientService.get("/api/shared/getweekdays?includePublicHoliday=true").then((response) => {
            if (response && response.length > 0) {
                setDaysList(response);
            }
        });

        httpclientService.get("/api/objects/getsalesareas").then((response) => {
            if (response && response.length > 0) {
                setSalesAreasList(response);
            }
        });

        httpclientService.get("/api/objects/getstatuses").then((response) => {
            if (response && response.length > 0) {
                setStatuslist(response);
            }
        });

        httpclientService.get("/api/objects/getpossibleaddresses").then((response) => {
            if (response && response.length > 0) {
                setPossibleContactList(response);
            }
        });

    }, []);

    function handleCreateObject(values) {
        SetEmptyValuesToNull(values);
        handleModalOpen();
        httpclientService.post("/api/objects/createobject", values).then((response) => {
            handleModalClose();
            if (response) {
                if (props.objectID && props.handleModalClose) {
                    props.handleModalClose();
                } else {
                    if (props.navigateto) {
                        navigate(props.navigateto);
                    } else {
                        navigate("/objects");
                    }
                }
            }
        });
    }

    const [initialValues, setInitialValues] = useState({
        objectID: null,
        tradeLevelID: '0',
        operationKindID: '0',
        operationLabelID: '0',
        salesAreaID: '0',
        statusID: '0',
        objectNrInternal: '',

        email: '',
        city: '',
        street: '',
        streetNr: '',
        zipCode: '',
        state: '',
        federalState: '',

        openingTimes: [],
        openingTimesValid: false,

        selectedObjUserAddressIDs: [],
        selectedGebUserAddressIDs: [],
        selectedTecUserAddressIDs: [],
        attachedFiles: [],
        
        attributes: [],
    });


    useEffect(() => {
        if (props.objectID) {
            httpclientService.get(`/api/objects/getobjectdetails?objectID=${props.objectID}`).then((response) => {
                if (response) {
                    var _openingTimes = response.openingTimes.map((item,index) => {
                        var tmp = {
                            id: new Date().getTime() + index,
                            openingTimeID: item.openingTimeID,
                            selectedDays: item.selectedDays,
                            fromTime: new Date(item.fromTime),
                            toTime: new Date(item.toTime)
                        };
                        return tmp;
                    }
                    ) ?? [];
                    setOpeningTimes(_openingTimes);
                    setInitialValues({
                        objectID: response.objectID,
                        tradeLevelID: response.tradeLevelID,
                        operationKindID: response.operationKindID,
                        operationLabelID: response.operationLabelID,
                        salesAreaID: response.salesAreaID,
                        statusID: response.statusID,
                        objectNrInternal: response.objectNrInternal,

                        email: response.email,
                        city: response.city,
                        street: response.street,
                        streetNr: response.streetNr,
                        zipCode: response.zipCode,
                        state: response.state,
                        federalState: response.federalState,

                        openingTimes: _openingTimes,
                        openingTimesValid: true,

                        selectedObjUserAddressIDs: response.selectedObjUserAddressIDs ?? [],
                        selectedGebUserAddressIDs: response.selectedGebUserAddressIDs ?? [],
                        selectedTecUserAddressIDs: response.selectedTecUserAddressIDs ?? [],
                        attributes: response.attributes ?? defaultAttribute
                    }
                    );
                }
            });
        }
    }, [props.objectID]);

    function handleUploadedFiles(file) {
        var newUploadedFiles = [...uploadedFiles, ...file];
        setUploadedFiles(newUploadedFiles);
        formRef.current.setFieldValue("attachedFiles", newUploadedFiles);
    }

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleCancel = () => {
        if (props.handleModalCancel) {
            props.handleModalCancel();
        } else {
            navigate(-1);
        }
    }

    const handleStepChange = (step) => {
        setCurrentStep(step);
    }

    /* Opening times logic */
    const handleAddOpeningTime = (index) => {

        if (openingTimes.length > 0) {
            setOpeningTimes([
                ...openingTimes,
                { id: new Date().getTime(), openingTimeID: null, selectedDays: null, fromTime: null, toTime: null, rowString: null }
            ]);
        } else {
            setOpeningTimes([
                { id: new Date().getTime(), openingTimeID: null, selectedDays: null, fromTime: null, toTime: null, rowString: null }
            ]);
        }
    }

    const handleRemoveOpeningTime = (removeID) => {
        var _newList = [...openingTimes.filter((item) => item.id !== removeID)];
        if (_newList.length < 1) {
            _newList = [{ id: new Date().getTime(), openingTimeID: null, selectedDays: null, fromTime: null, toTime: null, rowString: null }];
        }
        setOpeningTimes(_newList);
    }

    const handleOpeningTimeChange = (id, value, changeType) => {
        //formRef.current.setFieldValue("openingTimes", formRef.current.values.openingTimes.map((item, i) => {
        //    if (item.id === id) {
        //        switch (changeType) {
        //            case 'selectedDays':
        //                item.selectedDays = value;
        //                break;
        //            case 'fromTime':
        //                item.fromTime = value;
        //                break;
        //            case 'toTime':
        //                item.toTime = value;
        //                break;
        //            default:
        //                break;
        //        }
        //    }
        //    return item;
        //}));

        setOpeningTimes(
            openingTimes.map((item, i) => {
                if (item.id === id) {
                    switch (changeType) {
                        case 'selectedDays':
                            item.selectedDays = value;
                            break;
                        case 'fromTime':
                            item.fromTime = value;
                            break;
                        case 'toTime':
                            item.toTime = value;
                            break;
                        default:
                            break;
                    }
                }
                return item;
            })
        );
    }

    //function handleTimeConfirm() {
    //    setOpeningTimes( formRef.current.values.openingTimes );
    //}

    useEffect(() => {
        formRef.current.setFieldValue("openingTimes", openingTimes);
        var selectedDays = [];
        if (openingTimes.length > 0) {
            openingTimes.map((item) => {
                if (item.selectedDays?.length > 0) {
                    selectedDays = [
                        ...selectedDays,
                        ...item.selectedDays?.filter((f) => selectedDays.indexOf(f) === -1)
                    ];
                }
            });
            setRemoveFromDaysList(selectedDays);
        }
    }, [openingTimes]);

    useEffect(() => {
        formRef.current.setFieldValue("openingTimesValid", removeFromDaysList.filter((f)=> f !== 99 ).length === 7);
    }, [removeFromDaysList]);

    function handleAttributesListItemsChange(attributesListItems) {
        formRef.current.setFieldValue("attributes", attributesListItems);
    }

    const [schema1, setSchema1] = useState({
        objectNrInternal: Yup.string()
            .required(t("GenericRequiredText"))
            .max(25, t('Error_TextTooLong', { 1: 25 })),
        email: Yup.string().email(t("OnlineImport_InvalidFormat"))
            .required(t("GenericRequiredText"))
            .max(255, t('Error_TextTooLong', { 1: 255 })),
        tradeLevelID: Yup.number()
            .typeError(t("GenericRequiredText"))
            .required(t("GenericRequiredText"))
            .min(1),
        operationLabelID: Yup.number()
            .typeError(t("GenericRequiredText"))
            .required(t("GenericRequiredText"))
            .min(1),
        operationKindID: Yup.number()
            .typeError(t("GenericRequiredText"))
            .required(t("GenericRequiredText"))
            .min(1),
        salesAreaID: Yup.number()
            .typeError(t("GenericRequiredText"))
            .required(t("GenericRequiredText"))
            .min(1),
        openingTimes: Yup.array().of(
            Yup.object().shape({
                selectedDays: Yup.number().required(t("GenericRequiredText")),
                fromTime: Yup.date().required(t("GenericRequiredText")),
                toTime: Yup.date().required(t("GenericRequiredText")),
            }),
        ).min(1),
        openingTimesValid: Yup.bool().oneOf([true], t("GenericRequiredText"))
    });

    const [schema2, setSchema2] = useState({
        selectedTecUserAddressIDs: Yup.array().required(t("GenericRequiredText")).min(1)
    });

    const [validationSchema, setValidationSchema] = useState(Yup.object(schema1));

    useEffect(() => {
        if (currentStep === 1) {
            setValidationSchema(Yup.object(schema1));
        } else {
            setValidationSchema(Yup.object(schema2));
        }
    }, [currentStep]);

    useEffect(() => {
        formRef.current.validateForm();
    }, [validationSchema]);

    function deleteFileButtonClicked(file) {
        var filteredSelectedFiles = uploadedFiles.filter(s => s.name !== file);
        setUploadedFiles(filteredSelectedFiles);
        formRef.current.setFieldValue("attachedFiles", filteredSelectedFiles);
    }


    return (<>

        <Formik
            innerRef={formRef}
            validateOnMount
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                handleCreateObject(values)
            }}
            enableReinitialize={true}
        >
            {formik => (
                <Form>

                    <OmisSticky>
                        <div className="omis-header-box">
                            <OmisContainer fluid>
                                <OmisRow>
                                    <OmisCol xs={6} >
                                        <h1>
                                            {
                                                props.objectID ?
                                                    null
                                                    :
                                                    t("NewObject")
                                            }
                                        </h1>
                                    </OmisCol>
                                    <OmisCol style={{ display: 'flex', justifyContent: 'right' }} xs={6}>
                                        {
                                            currentStep === 1 ?
                                                <>
                                                    <OmisStack direction="horizontal" gap={1}>
                                                        <div className="p-2">
                                                            {
                                                                props.handleModalCancel ?
                                                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel} />
                                                                    :
                                                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} component={RouterLink} to={"/objects"}></OmisButtonSecondary>
                                                            }
                                                        </div>
                                                        <div className="p-2">
                                                            <OmisButtonPrimary id={"nextStepButton"} disabled={!formik.isValid} text={t("NextStep")} onClick={handleStepChange.bind(this, 2)}></OmisButtonPrimary>
                                                        </div>
                                                    </OmisStack>

                                                </>
                                                :
                                                currentStep === 2 ?
                                                    <>
                                                        <OmisStack direction="horizontal" gap={1}>
                                                            <div className="p-2">
                                                                <OmisButtonSecondary id={"previousStepButton"} text={t("PreviousStep")} onClick={handleStepChange.bind(this, 1)}></OmisButtonSecondary>
                                                            </div>
                                                            <div className="p-2">
                                                                <OmisButtonPrimary id={"nextStepButton"} disabled={!formik.isValid} text={t("NextStep")} onClick={handleStepChange.bind(this, 3)}></OmisButtonPrimary>
                                                            </div>
                                                        </OmisStack>

                                                    </>
                                                    :
                                                    <>
                                                        <OmisStack direction="horizontal" gap={1}>
                                                            <div className="p-2">
                                                                <OmisButtonSecondary id={"previousStepButton"} text={t("PreviousStep")} onClick={handleStepChange.bind(this, 2)}></OmisButtonSecondary>
                                                            </div>
                                                            <div className="p-2">
                                                                <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={props.objectID ? t("Action_Save") : t("Action_Create")}></OmisButtonPrimarySubmit>
                                                            </div>
                                                        </OmisStack>
                                                    </>
                                        }
                                    </OmisCol>
                                </OmisRow>
                                <OmisRow>
                                    <OmisCol> <OmisProgress variant="determinate" value={currentStep > 1 || formik.isValid ? 100 : 0} /></OmisCol>
                                    <OmisCol> <OmisProgress variant="determinate" value={currentStep > 2 && formik.isValid ? 100 : 0} /></OmisCol>
                                    <OmisCol> <OmisProgress variant="determinate" value={currentStep === 3 && formik.isValid ? 100 : 0} /></OmisCol>
                                </OmisRow>
                            </OmisContainer>
                        </div>
                    </OmisSticky>
                    {
                        currentStep === 1 ?
                            <FirstStep uploadedFiles={uploadedFiles} setUploadedFiles={handleUploadedFiles} deleteFileButtonClicked={deleteFileButtonClicked} openingTimes={formik.values.openingTimes} /*handleTimeConfirm={handleTimeConfirm} */ objectID={props.objectID} />
                            :
                            currentStep === 2 ?
                                <SecondStep possibleContactList={possibleContactList} />
                                :
                                <ThirdStep steps={formik.values.attributes} possibleContactList={possibleContactList} handleAttributesListItemsChange={handleAttributesListItemsChange} />
                    }
                </Form>)}
        </Formik>

        <OmisDialog open={modalOpen}
            maxWidth={"md"}
            title={""}
            content={<OmisProgress />}
        />
    </>);

    function FirstStep(props) {

        function fileUploadCallback(event) {
            props.setUploadedFiles(event);
        }

        return (
            <>
                <h3>
                    {t("Details")}
                </h3>
                <Card variant={ "outlined" }>
                    <OmisRow>
                        <OmisCol xs={ 6 }>
                            <Card variant={ "outlined" }>
                                <OmisRow>
                                    <OmisCol xs={12}>
                                        <OmisDropdown size={"small"} id={"tradelevel"} name={"tradeLevelID"} items={tradeLevelsList} labeltext={t("Object_TradeLevel")} required />
                                    </OmisCol>
                                    <OmisCol xs={12}>
                                        <OmisDropdown size={"small"} id={"operationKindID"} name={"operationKindID"} items={operationKindsList} labeltext={t("OperationKind_Title")} required />
                                    </OmisCol>
                                    <OmisCol xs={12}>
                                        <OmisDropdown size={"small"} id={"operationLabelID"} name={"operationLabelID"} items={operationLabelsList} labeltext={t("OperationLabel")} required />
                                    </OmisCol>
                                </OmisRow>
                            </Card>
                        </OmisCol>

                        <OmisCol xs={6}>
                            <Card variant={"outlined"}>
                                <OmisRow>
                                    <OmisCol xs={12}>
                                        <OmisTextBox labeltext={t("Object_ObjectNrInternal")} name={"objectNrInternal"} placeholder={t("Object_ObjectNrInternal")} required />
                                    </OmisCol>
                                    <OmisCol xs={12}>
                                        <OmisDropdown size={"small"} id={"statusID"} name={"statusID"} items={statusList} labeltext={t("Status")} />
                                    </OmisCol>
                                    <OmisCol xs={12}>
                                        <OmisDropdown size={"small"} id={"salesAreaID"} name={"salesAreaID"} items={salesAreasList} labeltext={t("Object_SalesArea")} required />
                                    </OmisCol>
                                </OmisRow>
                            </Card>
                        </OmisCol>
                    </OmisRow>
                </Card>
                <br />
                {
                    !props.objectID && (
                        <>
                            <Card variant={"outlined"}>
                                <OmisContainer fluid style={{ display: 'flex', justifyContent: 'center' }} className="faultReportCreateDetailsBox">
                                    <OmisRow style={{ display: 'flex', justifyContent: 'center' }}>
                                        <OmisCol xs={8}>
                                            <DocumentUploadForNewReport multiFilesUploadCallback={fileUploadCallback} referer={DMSDocumentReferer.Object} />
                                        </OmisCol>
                                        <OmisCol style={{ justifyContent: 'center', marginLeft: '3.5em' }} xs={8}>
                                            {
                                                props.uploadedFiles && props.uploadedFiles.length > 0 ?
                                                    <>
                                                        <span>{`${t("Import_UploadedFiles")} :`}</span>
                                                        {
                                                            props.uploadedFiles.map((file, index) =>
                                                                <OmisCol key={index.toString()} item xs={12}>
                                                                    <strong>{file.name}</strong>
                                                                    <IconButton id={"deleteUploadedFileButton"} variant={"outlined"}
                                                                        color={"error"}
                                                                        onClick={() => props.deleteFileButtonClicked(file.name)}
                                                                    >
                                                                        <Icon path={mdiTrashCan} size={1} />
                                                                    </IconButton>
                                                                </OmisCol>
                                                            )
                                                        }
                                                    </>
                                                    :
                                                    null
                                            }
                                        </OmisCol>
                                    </OmisRow>
                                </OmisContainer>
                            </Card>
                            <br />
                        </>
                    )
                }
                <Card variant={"outlined"}>
                    <OmisContainer>
                        <OmisRow>
                            <OmisCol xs={4}>
                                <OmisTextBox labeltext={t("Email")} name={"email"} placeholder={t("Email")} required />
                            </OmisCol>
                        </OmisRow>
                        <OmisRow>
                            <OmisCol xs={4}>
                                <OmisTextBox labeltext={t("City")} name={"city"} placeholder={t("City")} />
                            </OmisCol>
                            <OmisCol xs={4}>
                                <OmisTextBox labeltext={t("Street")} name={"street"} placeholder={t("Street")} />
                            </OmisCol>
                            <OmisCol xs={4}>
                                <OmisTextBox labeltext={t("StreetNr")} name={"streetNr"} placeholder={t("StreetNr")} />
                            </OmisCol>

                            <OmisCol xs={4}>
                                <OmisTextBox labeltext={t("ZipCode")} name={"zipCode"} placeholder={t("ZipCode")} />
                            </OmisCol>
                            <OmisCol xs={4}>
                                <OmisTextBox labeltext={t("Address_FederalState")} name={"state"} placeholder={t("Address_FederalState")} />
                            </OmisCol>
                            <OmisCol xs={4}>
                                <OmisTextBox labeltext={t("Address_State")} name={"federalState"} placeholder={t("Address_State")} />
                            </OmisCol>
                        </OmisRow>
                    </OmisContainer>
                </Card>

                <br />
                <Card variant={"outlined"}>
                    <OmisContainer>
                        <OmisRow>
                            <OmisCol xs={12}>
                                <OmisLabel label={<strong>{t("ObjectWizard_Step2_Header")}</strong>} required />
                            </OmisCol>
                            <OmisCol xs={12}>
                                <DynamicOpeningTimeBuilder removeFromDaysList={removeFromDaysList} daysList={daysList} openingTimes={props.openingTimes} handleRemoveOpeningTime={handleRemoveOpeningTime} handleOpeningTimeChange={handleOpeningTimeChange} disableTypingDate />
                            </OmisCol>
                        </OmisRow>
                        <br />
                        <OmisRow>
                            <OmisCol xs={3}>
                                <OmisSecButtonWithStartIcon id={"addOpeningTimeButton"} iconpath={mdiPlusBox } text={t("Action_Add")} onClick={handleAddOpeningTime} disabled={removeFromDaysList.length === 8} />
                            </OmisCol>
                            {/*<OmisCol xs={3}>*/}
                            {/*    <OmisPriButtonWithStartIcon iconpath={mdiCheckBold} text={t("Action_Commit")} onClick={props.handleTimeConfirm} />*/}
                            {/*</OmisCol>*/}
                        </OmisRow>
                    </OmisContainer>
                </Card>
            </>
        )
    }

    function SecondStep(props) {
        return (
            <>
                <br/>
                <Card>
                    <OmisRow>
                        <OmisCol xs={3}>
                            <OmisDropdownMultiselect size={"small"} id={"selectedObjUserAddressIDs"} name={"selectedObjUserAddressIDs"} items={props.possibleContactList} labeltext={t("Settings_UserWizard_ObjectUser")} />
                        </OmisCol>
                        <OmisCol xs={3}>
                            <OmisDropdownMultiselect size={"small"} id={"selectedGebUserAddressIDs"} name={"selectedGebUserAddressIDs"} items={props.possibleContactList} labeltext={t("MessagingParameter_AreaManager")} />
                        </OmisCol>
                        <OmisCol xs={3}>
                            <OmisDropdownMultiselect size={"small"} id={"selectedTecUserAddressIDs"} name={"selectedTecUserAddressIDs"} items={props.possibleContactList} labeltext={t("Technician")} required />
                        </OmisCol>
                    </OmisRow>
                </Card>
            </>
        );
    }

    function ThirdStep(props) {
        function addNewStep() {
            if (props.handleAttributesListItemsChange && props.steps?.length < 40) {
                var tmp = JSON.parse(JSON.stringify(defaultAttribute));
                if (props.steps?.length > 0) {
                    tmp[0].id = generateUniqueRandom(props.steps.map(x => x.id));
                }
                props.handleAttributesListItemsChange([...props.steps, ...tmp]);
            }
        };

        const handleValueOnChange = (e) => { /* onchange added because it was giving uncontrolled input error */

            var steps = props.steps;
            var currentStep = steps.filter(f => f.id === parseInt(e.target.id))[0];
            var columnName = e.target.name;

            currentStep[columnName] = e.target.value;
            if (props.handleAttributesListItemsChange) {
                props.handleAttributesListItemsChange(steps);
            }
        }

        const handleDeleteStep = (rowID) => {
            if (props.handleAttributesListItemsChange) {
                props.handleAttributesListItemsChange(props.steps?.filter(f => f.id !== rowID));
            }
        }

        function handleAttributeDefSelect(rowID, selection) {
            var steps = props.steps;
            var currentStep = steps.filter(f => f.id === rowID)[0];
            currentStep.attributeDefID = selection?.id ?? null;
            currentStep.attributeDefText = selection?.objattribdesc ?? null;

            if (props.handleAttributesListItemsChange) {
                props.handleAttributesListItemsChange(steps);
            }
        }

        function handleAttributeDefDeselect(rowID) {
            var steps = props.steps;
            var currentStep = steps.filter(f => f.id === rowID)[0];
            currentStep.attributeDefID = null;
            currentStep.attributeDefText = null;

            if (props.handleAttributesListItemsChange) {
                props.handleAttributesListItemsChange(steps);
            }
        }

        function handleNewAttDefClick(rowID, e) {
            setModalContentForAttrDef(<CreateObjectAttributeDef onClose={handleModalCloseForAttrDef} handleEditCancel={handleModalCancelForAttrDef} rowID={rowID } />);
            handleModalOpenForAttrDef();
        }

        function handleModalOpenForAttrDef() {
            setModalOpenForAttrDef(true);
        }

        const handleModalCloseForAttrDef = (response, rowID) => {
            if (response) {
                handleAttributeDefSelect(rowID,response);
            }
            setModalOpenForAttrDef(false);
        };

        const handleModalCancelForAttrDef = () => {
            setModalOpenForAttrDef(false);
        };

        return (
            <>
                <br />

                <OmisContainer fluid className="rowContainer">
                    <h3>
                        {t("Attributes")}
                    </h3>
                    <OmisRow className="rowContainer">
                        <OmisCol className="rowContainer">
                            {
                                props.steps !== null ? props.steps.map((step, index) => (
                                    <div key={index.toString() + "-cont"} >
                                        <Card>
                                            <OmisRow key={index.toString() + "-cont"} sx={{ paddingBottom: 2 }}>
                                                <OmisRow style={{ display: 'flex', justifyContent: 'right' }}>
                                                    <OmisCol style={{ display: 'flex', justifyContent: 'right' }} xs={1}>
                                                        <OmisIconButton id={"deleteAttrStepButton"} iconcontent={<ClearIcon fontSize="inherit" color={"error"} />} onClick={() => { handleDeleteStep(step.id); }} />
                                                    </OmisCol>
                                                </OmisRow>
                                                <OmisCol xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <OmisContainer>
                                                        <OmisRow>
                                                            <OmisLabel label={t("Value")} />
                                                        </OmisRow>
                                                        <OmisRow>
                                                            <OmisTextBoxNoForm id={step.id.toString()} key={step.id} name="attributeVal" value={step.attributeVal ?? ""} onChange={handleValueOnChange} />
                                                        </OmisRow>
                                                    </OmisContainer>
                                                </OmisCol>
                                                <OmisCol xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <OmisSelectedCatalogueCard
                                                        text={`${t("Settings_ObjectAttributeDef")} (${step.attributeDefText ?? ""})`}
                                                        id={step.attributeDefText}
                                                        handleDeselect={handleAttributeDefDeselect.bind(this, step.id)}
                                                    />
                                                </OmisCol>
                                                <OmisCol xs={12}>
                                                    {
                                                        step.attributeDefText === null ?
                                                            <OmisGrid rowclickCallback={handleAttributeDefSelect.bind(this, step.id)} noToolbar noHeaderText noExportButton
                                                                apiUrl={"/api/settings/settingsobjects/getobjectattributedefs"}
                                                                singleEntriesCallback={handleAttributeDefSelect.bind(this, step.id)}
                                                                newButton={<OmisButtonNewItem onClick={handleNewAttDefClick.bind(this, step.id)} text={t("Action_New")}
                                                                    name="attributeDef"
                                                                    id={step.id.toString()}
                                                                    key={step.id} />}
                                                            />
                                                            :
                                                            null
                                                    }
                                                </OmisCol>
                                            </OmisRow>
                                        </Card>
                                        <br />
                                    </div>
                                ))
                                    :
                                    null
                            }
                        </OmisCol>
                    </OmisRow>
                    {
                        props.steps?.length < 40 ?
                            <OmisRow className="justify-content-md-center">
                                <OmisCol xs={2}>
                                    <OmisSecButtonWithStartIcon id={"addAttrButton"} iconpath={mdiPlusBoxOutline} text={t("Action_Add")} onClick={() => addNewStep()}></OmisSecButtonWithStartIcon>
                                </OmisCol>
                            </OmisRow>
                            :
                            null
                    }
                </OmisContainer>    
                <OmisDialog open={modalOpenForAttrDef}
                    maxWidth={"md"}
                    content={modalContentForAttrDef}
                />
            </>
        );
    }
}