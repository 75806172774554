import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs } from "@mui/material";
import Icon from "@mdi/react";
import { Stack, Container, Col, Row } from "react-bootstrap";

export function OmisTabs(props) {
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (props.tabvalue != undefined && props.tabvalue != value) {
            setValue(props.tabvalue);
        }
    }, [props.tabvalue]);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(value);
        }
    }, [value]);

    return (
        <div>
            <Box sx={{ width: '100%' }}>

                {
                    props.tabs.filter((f) => !f.hideTab).length > 1 ?
                        <Box sx={{ borderBottom: 0, borderColor: 'divider', marginBottom: '1em', marginLeft:'-1em' }} display="flex" justifyContent={props.alignRight ? "flex-end" : "flex-start"}>
                            <Tabs value={value} onChange={(event, newValue) => setValue(newValue)} aria-label="omis settings tabs" variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile >
                                {
                                    props.tabs.filter((f) => !f.hideTab).map((tab, index) => < Tab key={`${tab.title}_${index}`} label={tab.title} disabled={tab.disabled} {...tabProps(index)} />)
                                }
                            </Tabs>
                        </Box>
                        :
                        null
                }

                {
                    props.tabs.filter((f) => !f.hideTab).map((tabpanel, index) => <TabPanel key={index} value={value} index={index}>{tabpanel.content}</TabPanel>)
                }
            </Box>
        </div>
    );
}

export function OmisTabsWithIcon(props) {
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (props.tabvalue !== undefined && props.tabvalue !== value) {
            let newTabValue = props.tabvalue;
            if (props.tabvalue >= props.tabs.filter((f) => !f.hideTab).length) {
                newTabValue = 0;
            }

            setValue(newTabValue);
        }
    }, [props.tabvalue]);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(value);
        }
    }, [value]);

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                {
                    props.tabs.filter((f) => !f.hideTab).length > 1 ?
                        <Box sx={{ borderBottom: 0, borderColor: 'divider', marginBottom: '1em' }} display="flex" justifyContent={props.alignRight ? "flex-end" : "flex-start"}>
                            <Tabs value={value} onChange={(event, newValue) => setValue(newValue)} aria-label="omis settings tabs" sx={{ marginLeft: 0 }} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile >
                                {
                                    props.tabs.filter((f) => !f.hideTab).map((tab, index) => tab.iconpath ? <Tab key={`${index}_${tab.title}`} label={tab.title} disabled={tab.disabled} icon={<Icon path={tab.iconpath} size={1}></Icon>} iconPosition={tab.iconposition} {...tabProps(index)} /> : <Tab key={`${index}_${tab.title}`} label={tab.title} {...tabProps(index)} />)
                                }
                            </Tabs>
                        </Box>
                        :
                        null
                }                
                {
                    props.tabs.filter((f) => !f.hideTab).map((tabpanel, index) => <TabPanel key={index} value={value} index={index}>{tabpanel.content}</TabPanel>)
                }
            </Box>
        </div>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function OmisColCenteredContent(props) {
    return (
        <OmisCol xs={props.xs} style={{ display: 'flex', alignItems: 'center' }}>
            {props.children}
        </OmisCol>
    );
}

export function OmisCol(props) {

    if (props.item) {
        console.log(props);
    }

    return (
        <Col {...props}>
            {props.children}
        </Col>
    );
}

export function OmisRow(props) {
    return (
        <Row {...props}>
            {props.children}
        </Row>
    );
}

export function OmisContainer(props) {
    return (
        <Container {...props}>
            {props.children}
        </Container>
    );
}

export function OmisStack(props) {
    return (
        <Stack {...props}>
            {props.children}
        </Stack>
    );
}

export function OmisSticky(props) {   

    return (
        <div className="stickyBar" {...props}>
            {props.children}
        </div>
    );
}