import React, { useRef, useState } from "react";
import { t } from "i18next";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisCheckBox } from "../../shared/OmisInputs";
import AuthService from "../../../services/auth.service";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function EditFilterDefs(props) {

    const navigate = useNavigate();
    const formRef = useRef();
    const [initialValues, setInitialValues] = useState({
        id: props.id ?? null,
        defaultVisible: props.rowVals?.defaultVisible ?? false,
        archiv: props.rowVals?.archiv ?? false
    });

    function handleSave(values) {
        httpclientService.post(`/api/settings/system/savefilterdef`, values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate("/settings/system/filterdefs");
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate("/settings/system/filterdefs");
        }
    }
    
    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>

                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={12}>
                                    <strong>{props.rowVals?.description ?? ''}</strong>
                                </OmisCol>

                                <OmisCol xs={6}>
                                    <br />
                                    <OmisCheckBox labeltext={t("Visible")} name="defaultVisible" />
                                </OmisCol>
                                {
                                    AuthService.isFSMMaster() ?
                                        <OmisCol xs={6}>
                                            <br />
                                            <OmisCheckBox labeltext={t("Archiv")} name="archiv" />
                                        </OmisCol>
                                        :
                                        null
                                }
                            </OmisRow>

                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>

                    </Form>
                )}
            </Formik>
        </>
    );
}