import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import * as Yup from "yup";
import httpclientService from "../../../services/httpclient.service";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Formik } from "formik";
import { OmisButtonPrimarySubmit, OmisButtonSecondary } from "../../shared/OmisButtons";
import { OmisCheckBox, OmisTextBox } from "../../shared/OmisInputs";
import { OmisDropdown } from "../../shared/OmisDropdowns";
import AuthService from "../../../services/auth.service";
import { OmisCol, OmisContainer, OmisRow } from "../../shared/OmisLayouts";

export function CreateEquiTestDate(props) {

    var { state } = useLocation();
    const navigate = useNavigate();
    const formRef = useRef();

    const strategyPlans = [
        {
            key: true,
            displayText: t("DatePlanned"),
        },
        {
            key: false,
            displayText: t("DateCompletion"),
        }
    ];

    const [periods, setPeriods] = useState([]);
    const [equiClasses, setEquiClasses] = useState([]);
    const [testDateOrigins, setTestDateOrigins] = useState([]);
    const [initialValues, setInitialValues] = useState({
        id: props.id ? props.id : null,
        description: props.rowVals?.description ?? "",
        periodicity: props.rowVals?.periodicity ?? 0,
        periodicityEnum: props.rowVals?.periodicityEnum ?? 0,
        tenderSpec: props.rowVals?.tenderSpec ?? "",
        allowedTimeBefore: props.rowVals?.allowedTimeBefore ?? 0,
        allowedTimeAfter: props.rowVals?.allowedTimeAfter ?? 0,
        strategyNewTDPlandate: props.rowVals?.strategyNewTDPlandate ?? false,
        equiClassID: props.rowVals?.equiClassID ?? state?.equiclassid ?? 0,
        testDateOriginID: props.rowVals?.testDateOriginID ?? 0,
        allowedYear: props.rowVals?.allowedYear ?? false,
        autogenerateNewAfterPeriodicity: props.rowVals?.autogenerateNewAfterPeriodicity ?? false,
        checklistCompatible: props.rowVals?.checklistCompatible ?? false,
        customerEditable: props.rowVals?.customerEditable ?? false,
        setToLastDayOfMonth: props.rowVals?.setToLastDayOfMonth ?? false,
    });

    useEffect(() => {
        httpclientService.get("/api/settings/settingsequipments/getequiclassesfordropdown").then((response) => {
            if (response && response.length > 0) {
                var equiClassID = props.rowVals?.equiClassID ?? state?.equiclassid ?? -1;
                setEquiClasses(response.map(x => {
                    return {
                        ...x,
                        disabled: (x.id !== equiClassID)
                    };
                }));
            }
        });

        httpclientService.get("/api/shared/getperiodselectionlist").then((response) => {
            setPeriods(response);
        });

        httpclientService.get("/api/settings/settingsequipments/gettestdateorigins").then((response) => {
            setTestDateOrigins(response);
        });

    }, []);

    function handleSave(values) {
        httpclientService.post("/api/settings/settingsequipments/saveequitestdate", values).then((response) => {
            if (response) {
                if (props.onClose) {
                    props.onClose();
                } else {
                    navigate(-1);
                }
            }
        })
    }

    function handleCancel() {
        if (props.handleEditCancel) {
            props.handleEditCancel();
        } else {
            navigate(-1);
        }
    }

    const [schema, setSchema] = useState({
        periodicity: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        periodicityEnum: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        allowedTimeBefore: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
        allowedTimeAfter: Yup.number()
            .required(t("GenericRequiredText"))
            .min(1),
    });

    const validationSchema = Yup.object(schema);

    return (
        <>
            <Formik
                innerRef={formRef}
                validateOnMount
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => { handleSave(values) }}
            >
                {formik => (
                    <Form>
                        {
                            initialValues.id === null ?
                                <h1>{t("TypeOfTestDates_CreateNew")}</h1>
                                :
                                null
                        }
                        <OmisContainer fluid>
                            <OmisRow>
                                <OmisCol xs={3}>
                                    <OmisTextBox labeltext={t("Description")} name="description" />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <OmisTextBox labeltext={t("Periodicity")} name={"periodicity"} placeholder={t("Periodicity")} required />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <OmisDropdown labeltext={t("SensorData_Unit")} name="periodicityEnum" items={periods} required />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <OmisTextBox labeltext={t("TenderSpec")} name={"tenderSpec"} placeholder={t("TenderSpec")} />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <OmisTextBox labeltext={t("TestDate_AllowedTimeBefore")} name={"allowedTimeBefore"} placeholder={t("TestDate_AllowedTimeBefore")} required />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <OmisTextBox labeltext={t("TestDate_AllowedTimeAfter")} name={"allowedTimeAfter"} placeholder={t("TestDate_AllowedTimeAfter")} required />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <OmisDropdown labeltext={t("TypeOfTestDate_StrategyNewTDPlanDate")} name="strategyNewTDPlandate" items={strategyPlans} required />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <OmisDropdown labeltext={t("EquiClass")} name="equiClassID" items={equiClasses} />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <OmisDropdown labeltext={t("TestDateOrigin")} name="testDateOriginID" items={testDateOrigins} />
                                </OmisCol>
                                <OmisCol xs={4}>
                                    <br />
                                    <OmisCheckBox labeltext={t("TestDate_AllowedYear")} name="allowedYear" />
                                </OmisCol>
                                <OmisCol xs={5}>
                                    <br />
                                    <OmisCheckBox labeltext={t("AutogenerateNewAfterPeriodicity")} name="autogenerateNewAfterPeriodicity" />
                                </OmisCol>
                                <OmisCol xs={3}>
                                    <OmisCheckBox labeltext={t("Action_Checklist")} name="checklistCompatible" />
                                </OmisCol>
                                {
                                    AuthService.isFSMMaster() ?
                                        <OmisCol xs={3}>
                                            <OmisCheckBox labeltext={t("CustomerEditable")} name="customerEditable" />
                                        </OmisCol>
                                        :
                                        null
                                }
                                <OmisCol xs={3}>
                                    <OmisCheckBox labeltext={t("SetToLastDayOfMonth")} name="setToLastDayOfMonth" />
                                </OmisCol>
                            </OmisRow>
                            <OmisRow>
                                <OmisCol xs={6}>
                                    <OmisButtonSecondary id={"cancelButton"} text={t("Action_Cancel")} onClick={handleCancel}></OmisButtonSecondary>
                                </OmisCol>
                                <OmisCol xs={6}>
                                    <OmisButtonPrimarySubmit id={"submitButton"} disabled={!formik.isValid} text={t("Action_Save")}></OmisButtonPrimarySubmit>
                                </OmisCol>
                            </OmisRow>
                        </OmisContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
}