import React from 'react'
import { Component } from 'react'
import { Navigate } from 'react-router-dom'
import AuthService from "../../services/auth.service"

export default class AuthorizeRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      authenticated: false,
      authorized: false
    };
  }

  componentDidMount() {
    this.populateAuthenticationState();
  }

    render() {
    const { ready, authenticated, authorized } = this.state;
    var link = document.createElement("a");
    link.href = this.props.path;
    const returnUrl = `${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `/login?returnUrl=${encodeURIComponent(returnUrl)}`;
    if (!ready) {
      return <div></div>;
    } else {
      const { element } = this.props;
      return (authenticated && authorized) ? element : <Navigate replace to={redirectUrl} />;
    }
  }

  async populateAuthenticationState() {
    const authenticated = await AuthService.isAuthenticated();
    let authorized = false;
    if (this.props.roles) {
      this.props.roles.forEach((role) => {
          if (AuthService.hasRole(role) || AuthService.isFSMMaster()) {
          authorized = true;
        }
      });
    } else {
      authorized = true;
    }
    this.setState({ ready: true, authenticated, authorized });
  }

  async authenticationChanged() {
    this.setState({ ready: false, authenticated: false, authorized: false });
    await this.populateAuthenticationState();
  }
}
