import React, { useState, useEffect } from 'react';
import { t } from "i18next";
import { useField, useFormikContext} from 'formik';
//import DatePicker, {registerLocale} from 'react-datepicker';
//import "react-datepicker/dist/react-datepicker.css";
import httpclientService from "../../services/httpclient.service";
import { StrengthMeter } from '../profile/ChangePassword';
import './OmisInputs.scss';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Checkbox, Chip, FormControlLabel, FormGroup, Grid, InputBase, styled, Switch, TextField, SwitchProps, InputAdornment, IconButton, Slider, alpha, Autocomplete } from "@mui/material";
import Icon from "@mdi/react";
import { Search as SearchIcon, Check as CheckIcon, Clear as ClearIcon, Edit as EditIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { mdiMagnify } from "@mdi/js";
import { OmisLabel, OmisErrorMessage } from "./OmisDisplays";
import { OmisButtonPrimarySubmit, OmisButtonSecondary, OmisIconButton } from "./OmisButtons";
import { red } from '@mui/material/colors';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment'
import dayjs from 'dayjs';
import { DatePicker, DateTimePicker, DateRangePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { deDE, enUS } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import AddIcon from '@mui/icons-material/Add';
import HttpClientService from '../../services/httpclient.service';
import { OmisDropdownMultiselectV2 } from './OmisDropdowns';
import { OmisCol, OmisContainer, OmisRow, OmisStack } from './OmisLayouts';
require('dayjs/locale/de')

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Berlin');

const englishLocale = enUS.components.MuiLocalizationProvider.defaultProps.localeText;
const germanLocale = deDE.components.MuiLocalizationProvider.defaultProps.localeText;
var localSessionLang = localStorage.getItem("i18nLng");

const IOSStyleSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
                ))(({ theme }) => ({
                width: 50,
                height: 20,
                margin:"10%;",
                padding: 0,

                '& .MuiSwitch-switchBase': {
                    padding: 0,
                    margin: 8,
                    transitionDuration: '300ms',
                    '&.Mui-checked': {
                        transform: 'translateX(20px)',
                        color: '#fff',
                        bottom:0,
                        '& + .MuiSwitch-track': {
                            backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                            opacity: 1,
                            border: 0,
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                            opacity: 0.5,
                        },
                    },
                    '&.Mui-focusVisible .MuiSwitch-thumb': {
                        color: '#33cf4d',
                        border: '6px solid #fff',
                    },
                    '&.Mui-disabled .MuiSwitch-thumb': {
                        color:
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[600],
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
                    },
                },
                '& .MuiSwitch-thumb': {
                    boxSizing: 'border-box',
                    width: 20,
                    height: 20,
                },
                '& .MuiSwitch-track': {
                    borderRadius: 20 / 2,
                    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
                    opacity: 1,
                    transition: theme.transitions.create(['background-color'], {
                        duration: 500,
                    }),
                },
                    "& + .MuiFormControlLabel-label": {
                        fontWeight: "bold",
                        fontSize: "1.25rem;",
                        margin: "-7%;"
                    }
}));



const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    height:'2.973em',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bottom:'1%'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '30ch',
        },
    },
}));

const StyledOmisTextBox = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
		color: red,
        [theme.breakpoints.up('md')]: {
            width: '30ch',
        },
    },
}));

export function OmisTextBox(props) {
    const [field, meta] = useField(props);
    return (
        <div>
            {
                props.labeltext ?
                    <OmisLabel label={props.labeltext} required={props.required && !props.disabled} sx={{ opacity: props.disabled ? "0.5" : "" }}></OmisLabel>
                    :
                    null
            }
            <TextField sx={{ minHeight: props.inputHeight }} variant="standard" color="warning" size={"small"} id={field.name} value={field?.value !== '' && field?.value !== null && field?.value !== undefined ? field.value : ''} onChange={field.onChange} className={`form-control ${props.disabled ? "disabledField":""}`} onBlur={field.onBlur} helperText={meta.touched && meta.error ? meta.error : ""} error={meta.touched && Boolean(meta.error)} data-testid={props.name} type="text" {...props} ></TextField>
        </div>
    );
}

export function OmisTextBoxEmail(props) {
    const [field, meta] = useField(props);
       
    return (
        <div disabled={ true}>
            <OmisLabel label={props.labeltext} required={props.required && !props.disabled} sx={{ opacity: props.disabled ? "0.5" : "" }}></OmisLabel>
            <TextField InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={props.addIconClick}>
                            <AddIcon />
                        </IconButton>
                    </InputAdornment>
                )
            }}
                variant="standard" color="warning" size={"small"} id={field.name} value={field.value} onChange={field.onChange} className={`form-control ${props.disabled ? "disabledField" : ""}`} onBlur={field.onBlur} helperText={meta.touched && meta.error ? meta.error : ""} error={meta.touched && Boolean(meta.error)} data-testid={props.name} type="text" ></TextField>
        </div>
    );
}

export function OmisTextBoxEmailSearch(props) {
    const [field, meta] = useField(props);
    const [searchResult, setSearchResult] = useState([]);
    const [selectedEmails, setSelectedEmails] = useState([]);

    function handleEmailSelectionChange(event) {
        const {
            target: { value },
        } = event;

        // On autofill we get a stringified value.
        var _newArr = typeof value === 'string' ? value.split(',') : value;//gives selected values in array    
        setSelectedEmails(_newArr.sort((a, b) => a - b));

        if (props.callbackFunc) {
            props.callbackFunc(_newArr.sort((a, b) => a - b));
        }
    }

    useEffect(() => {
        if (props.setFoundEmailsList) {
            props.setFoundEmailsList(searchResult);
        }
    }, [searchResult])

    return (
        <div disabled={props.disabled}>
            <OmisLabel label={props.labeltext} required={props.required && !props.disabled} sx={{ opacity: props.disabled ? "0.5" : "" }}></OmisLabel>
            <TextField InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={(e) => OmisTextBoxEmailSearchClicked(field.value, setSearchResult)}>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                )
            }} variant="standard" color="warning" size={"small"} id={field.name} value={field.value} onChange={(e) => {
                //OmisTextBoxEmailSearchClicked(e, setList);
                field.onChange(e);
                }} className={`form-control ${props.disabled ? "disabledField" : ""}`} onBlur={field.onBlur} helperText={meta.touched && meta.error ? meta.error : ""} error={meta.touched && Boolean(meta.error)} data-testid={props.name} type="text"></TextField> <div className="pull-right"></div>


            <OmisDropdownMultiselectV2 id="emailsList" className="groupsDropDown" items={searchResult} onChange={handleEmailSelectionChange} value={selectedEmails} />
        </div>
    );
}

export default function OmisTextBoxEmailSearchV2(props) {
    const [field] = useField(props);
    const [selected, setSelected] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [error, setError] = useState(false);

    const [options, setOptions] = React.useState([]);

    useEffect(() => {
        OmisTextBoxEmailSearchClicked("", setOptions);
    }, []);

    useEffect(() => {
        if (field.value && selected.length === 0) {
            setSelected([field.value]);
        }
    }, [field.value]);

    function onChange(e, value) {
        // error
        const errorEmail = value.find((email) => !regex.test(email));
        if (errorEmail) {
            // set value displayed in the textbox
            setInputValue(errorEmail);
            setError(true);
        } else {
            setError(false);
        }
        // Update state
        setSelected(value.filter((email) => regex.test(email)));
        if (props.onChange) {
            props.onChange(value.filter((email) => regex.test(email)).map(x => x).join(';'));
        }
    }

    function onDelete(value) {
        setSelected(selected.filter((e) => e !== value));
        if (props.onChange) {
            props.onChange(selected.filter((e) => e !== value).map(x => x).join(';'));
        }
    }

    function onInputChange(e, newValue) {
        setInputValue(newValue);
        OmisTextBoxEmailSearchClicked(newValue, setOptions);
    }

    return (
        <OmisStack spacing={3}>
            <Autocomplete
                multiple
                onChange={onChange}
                id="tags-filled"
                limitTags={1}
                value={selected}
                inputValue={inputValue}
                onInputChange={onInputChange}
                options={Array.isArray(options) ?
                    [
                        ...options.map((option) => option.displayText).filter(f => selected.indexOf(f) > -1).sort((a, b) => a - b),
                        ...options.map((option) => option.displayText).filter(f => selected.indexOf(f) === -1).sort((a, b) => a - b),
                    ]
                    :
                    []
                }
                freeSolo
                renderTags={(value: [], getTagProps) =>
                    value.map((option: string, index: number) => (
                        <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                            onDelete={() => onDelete(option)}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="filled"
                        label={props.labeltext}
                        placeholder={props.placeholder}
                        type="email"
                        error={error}
                        helperText={error && t("OnlineImport_InvalidFormat")}
                    />
                )}
            />
        </OmisStack>
    );
}

function OmisTextBoxEmailSearchClicked(value, callBackFunc) {
    HttpClientService.get(`/api/shared/searchallemails?searchText=${value}`).then((response) => {
        //console.log(response);
        if (callBackFunc) {
            callBackFunc(response.map((item) => {
                return { id: item.value, key: item.value, displayText: item.email }
            }).filter(f => f.key !== "" && f.displayText !== "" ));
        }
    });
}

export function OmisEditTextBox(props) {
    const [field, meta] = useField(props);
    const [isEditClicked, setIsEditClicked] = useState(false);
    const [textBoxValue, setTextBoxValue] = useState('');

    useEffect(() => {
        if (field.value) {
            setTextBoxValue(field.value);
        }
    }, [field.value]);

    function changeEditClickState() {
        setIsEditClicked((prevState) => !prevState);
    }

    function discardClick(event) {
        setTextBoxValue(props.value ?? '');
        changeEditClickState();
    }

    function handleOnChange(event) {
        setTextBoxValue(event.target.value);
    }

    function handleSave() {
        setIsEditClicked(false);
        if (field.onSave) {
            field.onSave(textBoxValue);
        }
    }

    return (
        <div>
            <OmisLabel label={props.labeltext} required={props.required && !props.disabled} sx={{ opacity: props.disabled ? "0.5" : "" }}></OmisLabel>
            <TextField
                variant="standard"
                color="warning"
                size={"small"}
                id={field.name}
                value={textBoxValue}
                onChange={handleOnChange}
                className={`form-control ${props.disabled ? "disabledField" : ""}`}
                onBlur={handleOnChange}
                helperText={meta.touched && meta.error ? meta.error : ""}
                error={meta.touched && Boolean(meta.error)}
                data-testid={props.name}
                type="text"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <OmisContainer fluid style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                {
                                    isEditClicked ?
                                        <OmisRow style={{ display: 'flex', alignItems: 'center', minWidth: '3.5em' }}>
                                            <OmisCol xs={6} style={{ display: 'flex', alignItems: 'center', paddingLeft: '0px !important' }}>
                                                <IconButton
                                                    onClick={handleSave}
                                                    onMouseDown={handleSave}
                                                >
                                                    <CheckIcon color={"success"} />
                                                </IconButton>
                                            </OmisCol>
                                            <OmisCol xs={6} style={{ display: 'flex', alignItems: 'center', paddingLeft: '5px !important' }}>
                                                <IconButton
                                                    onClick={discardClick}
                                                    onMouseDown={discardClick}
                                                >
                                                    <ClearIcon color={"error"} />
                                                </IconButton>
                                            </OmisCol>
                                        </OmisRow>
                                        :
                                        <OmisRow style={{ display: 'flex', alignItems: 'center' }}>
                                            <OmisCol xs={6}>
                                                <IconButton
                                                    onClick={changeEditClickState}
                                                    onMouseDown={changeEditClickState}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </OmisCol>
                                        </OmisRow>
                                }
                            </OmisContainer>
                        </InputAdornment>
                    )
                }}
                {...props} />
        </div>
    );
}

export function OmisPasswordTextBox(props) {
    const [field, meta] = useField(props);
    return (
        <div>
            <OmisLabel label={props.labeltext} required={props.required && !props.disabled} sx={{ opacity: props.disabled ? "0.5" : "" }}></OmisLabel>
            <TextField variant="standard" size={"small"} id={field.name} value={field.value} onChange={field.onChange} onInput={field.onInput} className={`form-control ${props.disabled ? "disabledField" : ""}`} onBlur={field.onBlur} helperText={meta.touched && meta.error ? meta.error : ""} error={meta.touched && Boolean(meta.error)} type="password" inputProps={{ autoComplete: `${props.autoComplete ? props.autoComplete : 'on'}` }} {...props}></TextField>
        </div>
    );
}

export function OmisPasswordTextBoxWithEye(props) {
    const [field, meta] = useField(props);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    return (
        <div>
            <OmisLabel label={props.labeltext} required={props.required && !props.disabled} sx={{ opacity: props.disabled ? "0.5" : "" }}></OmisLabel>
            <TextField
                variant="standard"
                size="large"
                data-testid={props.name}
                id={field.name}
                value={field.value}
                onChange={field.onChange}
                onInput={field.onInput}
                className={`form-control ${props.disabled ? "disabledField" : ""}`}
                helperText={meta.touched && meta.error ? meta.error : ""}
                error={meta.touched && Boolean(meta.error)}
                type={showPassword ? "text" : "password"}
                InputProps={{ // <-- This is where the toggle button is added.
                    autoComplete: `${props.autoComplete ? props.autoComplete : 'on'}`,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                sx={{ height:'2.9665em !important'} }
            />
        </div>
    );
}

export function OmisPasswordArea(props) {
    const [field, meta] = useField(props);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const [showPolicy, setShowPolicy] = useState(true);
    const [passwordPolicy, setPasswordPolicy] = useState(null);
    const [poorPassword, setPoorPassword] = useState(false);
    const [weakPassword, setWeakPassword] = useState(false);
    const [strongPassword, setStrongPassword] = useState(false);
    const [passwordError, setPasswordErr] = useState("");

    useEffect(() => {
        httpclientService.get("/api/profile/getpasswordpolicy").then((response) => {
            setPasswordPolicy(response);
        });
    }, []);

    useEffect(() => {
        passwordStrength(field.value);

        if (props.ignoreempty !== null && props.ignoreempty === 1 && field.value === "") {
            setShowPolicy(false);
        } else {
            if (!showPolicy) {
                setShowPolicy(true);
            }
        }

    }, [field.value]);

    const passwordStrength = (newPass) => {
        const passwordValue = newPass;
        var submitButtonDisabled = true;

        setPoorPassword(false);
        setWeakPassword(false);
        setStrongPassword(false);

        const passwordLength = passwordValue.length;
        const lowerRegExp = /[a-z]|[ä-ü]/;
        const upperRegExp = /[A-Z]|[Ä-Ü]/;
        const weakRegExp = /(?=.*?[0-9])/;
        const strongRegExp = /(?=.*?[#?!@$%^&*-+._,:])/;
        const whitespaceRegExp = /^$|\s+/;
        const hasLowerCase = lowerRegExp.test(passwordValue);
        const hasUpperCase = upperRegExp.test(passwordValue);
        const weakPassword = weakRegExp.test(passwordValue);
        const strongPassword = strongRegExp.test(passwordValue);
        const whiteSpace = whitespaceRegExp.test(passwordValue);

        if (passwordValue === '') {
            if (props.ignoreempty !== null && props.ignoreempty === 1) {
                setPasswordErr("");
                setPoorPassword(false);
                setWeakPassword(false);
                setStrongPassword(false);
                submitButtonDisabled=false;
            } else {
                setPasswordErr(t("Account_Password_Empty"));
            }
        } else {
            // to check whitespace
            if (whiteSpace) {
                setPasswordErr(t("PasswordWhiteSpace"));
            }
            // to check poor password
            if (passwordLength <= 3 || (hasLowerCase || hasUpperCase || weakPassword || strongPassword)) {
                setPoorPassword(true);
                setPasswordErr(t("PasswordIsPoor"));
            }
            // to check weak password
            if (passwordLength >= 4 && (hasLowerCase || hasUpperCase) && (weakPassword || strongPassword)) {
                setWeakPassword(true);
                setPasswordErr(t("PasswordIsWeak"));
            } else {
                setWeakPassword(false);
            }
            // to check strong Password
            if (passwordLength >= passwordPolicy.length && (hasLowerCase && weakPassword) && hasUpperCase && strongPassword) {
                setStrongPassword(true);
                submitButtonDisabled = false;
                setPasswordErr(t("PasswordIsStrong"));
            } else {
                setStrongPassword(false);
            }
        }

        if (props.submitButtonDisabled && props.submitButtonDisabled !== null) {
            props.submitButtonDisabled(submitButtonDisabled);
        }
    }

    return (
        <div style={{ height: 173 }}>
            <OmisLabel label={props.labeltext} required={props.required && !props.disabled} sx={{ opacity: props.disabled ? "0.5" : "" }}></OmisLabel>
            <TextField
                variant="standard"
                size={"small"}
                id={field.name}
                value={field.value}
                data-testid={props.name}
                onChange={field.onChange}
                onInput={field.onInput}
                className={`form-control ${props.disabled ? "disabledField" : ""}`}
                helperText={meta.touched && meta.error ? meta.error : ""}
                error={meta.touched && Boolean(meta.error)}
                type={showPassword ? "text" : "password"}
                InputProps={{ // <-- This is where the toggle button is added.
                    autoComplete: `${props.autoComplete ? props.autoComplete : 'on'}`,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                sx={{ height: '2.9665em !important' }}
            />
            <OmisCol xs={10}>
                <StrengthMeter poorPassword={poorPassword} weakPassword={weakPassword} strongPassword={strongPassword} passwordError={passwordError} />
            </OmisCol>
            {
                passwordPolicy && passwordPolicy !== null && showPolicy ?
                    <OmisCol xs={10}>
                        <p>{`${t("Important")}:
                        ${passwordPolicy.upperCase ? `${t('Account_Password_NoUpperCase', { 0: passwordPolicy.upperCase })},` : ''}
                        ${passwordPolicy.lowerCase ? `${t('Account_Password_NoLowerCase', { 0: passwordPolicy.lowerCase })},` : ''}
                        ${passwordPolicy.digits ? `${t('Account_Password_NoNumber', { 0: passwordPolicy.digits })},` : ''}
                        ${passwordPolicy.special ? `${t('Account_Password_Special', { 0: passwordPolicy.special })},` : ''}
                        ${passwordPolicy.length ? `${t('Account_Password_InvalidLength', { 0: passwordPolicy.length })},` : ''}
                        *`}</p>
                    </OmisCol>
                    :
                    null
            }
        </div>
    );
}

export function OmisTextArea(props) {
    const [field, meta] = useField(props);
    return <>
        <OmisLabel label={props.labeltext} required={props.required && !props.disabled} sx={{ opacity: props.disabled ? "0.5" : "" }} ></OmisLabel>
        <TextField variant="standard" multiline minRows={4} size={"small"} id={field.name} value={field.value} onChange={field.onChange} className={`form-control ${props.disabled ? "disabledField" : ""}`} onBlur={field.onBlur} helperText={meta.touched && meta.error ? meta.error : ""} error={meta.touched && Boolean(meta.error)} {...props}></TextField>
    </>
    ;
}

export function OmisAutosizeTextArea(props) {
    const [field, meta] = useField(props);
    return <>
        <OmisLabel label={props.labeltext} required={props.required && !props.disabled} sx={{ opacity: props.disabled ? "0.5" : "" }}></OmisLabel>
        <TextareaAutosize
            minRows={4} size={"small"} id={field.name} value={field.value} onChange={field.onChange} onBlur={field.onBlur} className={`form-control ${props.disabled ? "disabledField" : ""}`} {...props}           
        />
    </>
        ;
}

export function OmisAutosizeTextAreaV2(props) {
    const [field, meta] = useField(props);
    return <>
        <OmisLabel label={props.labeltext} required={props.required}></OmisLabel>
        <TextField
            minRows={4} size={"small"} id={field.name} value={field.value} onChange={field.onChange} onBlur={field.onBlur} className={`form-control ${props.disabled ? "disabledField" : ""}`}
            helperText={meta.touched && meta.error ? meta.error : ""} error={meta.touched && Boolean(meta.error)}
            InputProps={{
                inputComponent: TextareaAutosize,
                inputProps: {
                    style: {
                        resize: "auto",
                        width: "600px",
                        height: "100px",
                        minWidth: "200px",
                        minHeight: "25px",
                        maxWidth: "600px",
                        maxHeight: "225px",
                    }
                }
            }}
            {...props}
        />
    </>
        ;
}

export function OmisTextAreaNoForm(props) {
    return (
        <div>
            <OmisLabel label={props.labeltext} required={props.required && !props.disabled}></OmisLabel>
            <TextField className={`form-control ${props.disabled ? "disabledField" : ""}`} type="text" {...props}></TextField>
        </div>
    );
}

export function OmisAutosizeTextAreaNoForm(props) {
    return (
        <div>
            <OmisLabel label={props.label} required={props.required && !props.disabled}></OmisLabel>
            <TextareaAutosize minRows={3} size={"small"} className={`form-control ${props.disabled ? "disabledField" : ""}`} type="text" {...props}></TextareaAutosize>
        </div>
        );
}

export function OmisTextBoxNoForm(props) {
    return (
        <>
            {props.label ? <OmisLabel label={props.label} required={props.required && !props.disabled}></OmisLabel> : ""}
            <input className={`form-control ${props.disabled ? "disabledField" : ""}`} type="text" {...props}></input>
        </>
    );
}

export function OmisEditTextBoxNoForm(props) {
    const [isEditClicked, setIsEditClicked] = useState(false);
    const [textBoxValue, setTextBoxValue] = useState('');

    useEffect(() => {
        setTextBoxValue(props.value ?? '');
    }, [props.value]);

    function changeEditClickState() {
        setIsEditClicked((prevState) => !prevState);
    }

    function discardClick(event) {
        setTextBoxValue(props.value ?? '');
        changeEditClickState();
    }

    function handleOnChange(event) {
        setTextBoxValue(event.target.value);
    }

    function handleSave() {
        setIsEditClicked(false);
        if (props.onSave) {
            props.onSave(textBoxValue);
        }
    }

    return (
        <div>
            {props.labeltext ? <OmisLabel label={props.labeltext} required={props.required && !props.disabled} sx={{ opacity: props.disabled ? "0.5" : "" }} /> : ""}
            <TextField
                variant="standard"
                size={"small"}
                className={`form-control ${props.disabled ? "disabledField" : ""}`}
                data-testid={props.id}
                type="text"
                disabled={!isEditClicked}
                value={textBoxValue}
                onChange={handleOnChange}
                sx={props.sx}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <OmisContainer fluid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {
                                    isEditClicked ?
                                        <OmisRow style={{ display: 'flex', alignItems: 'center', minWidth: '3.5em' }}>
                                            <OmisCol xs={6} style={{ display: 'flex', alignItems: 'center', paddingLeft:'0px !important' }}>
                                                <IconButton
                                                    onClick={handleSave}
                                                    onMouseDown={handleSave}
                                                >
                                                    <CheckIcon color={"success"} />
                                                </IconButton>
                                            </OmisCol>
                                            <OmisCol xs={6} style={{ display: 'flex', alignItems: 'center', paddingLeft: '5px !important' }}>
                                                <IconButton
                                                    onClick={discardClick}
                                                    onMouseDown={discardClick}
                                                >
                                                    <ClearIcon color={"error"} />
                                                </IconButton>
                                            </OmisCol>
                                        </OmisRow>
                                        :
                                        <OmisRow style={{ display: 'flex', alignItems: 'center' }}>
                                            <OmisCol xs={6}>
                                                <IconButton
                                                    onClick={changeEditClickState}
                                                    onMouseDown={changeEditClickState}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </OmisCol>
                                        </OmisRow>
                                }
                            </OmisContainer>
                        </InputAdornment>
                    )
                }}
            />
        </div>
    );
}

export function OmisCheckBox(props) {
    const [field, meta] = useField(props);
    return (
        <FormGroup sx={props.sx}>
            <FormControlLabel className={""} control={<Checkbox {...props} id={field.name} checked={field.value} value={field.value} onChange={field.onChange} />} label={props.labeltext} />
        </FormGroup>
    );
}

export function OmisCheckBoxNoForm(props) {
    return (
        <FormGroup sx={props.sx}>
            <FormControlLabel className={""} control={<Checkbox {...props} />} label={props.labeltext} />
        </FormGroup>
    );
}

export function OmisSlider(props) {
    const [field, meta] = useField(props);
    return (
        <FormGroup className={"omis-slider"}>
            <FormControlLabel control={<Switch {...props} id={field.name} checked={field.value} onChange={field.onChange} />} label={props.labeltext} />
        </FormGroup>
    );
}

export function OmisSliderNoForm(props) {
    return (
        <FormGroup className={"omis-slider"}>
            <FormControlLabel control={<Switch {...props} onChange={props.onChange} />} label={props.labeltext} />
        </FormGroup>
    );
}

export function OmisSliderNoFormWithIOSStyle(props) {
    return (
        <FormGroup className={"omis-slider"}>
            <FormControlLabel control={<IOSStyleSwitch {...props} onChange={props.onChange} />} label={props.labeltext} />
        </FormGroup>
    );
}

export function OmisSearchBox(props) {
    return (
        <div className="form-group has-icon">
            <span className="form-control-feedback"><Icon path={mdiMagnify} size={1}/></span>
            <OmisTextBoxNoForm placeholder="Suche"/>
        </div>
    );
}


export function OmisYearPicker({ ...props }) {

    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props);

    if (localSessionLang?.includes("de")) {
        dayjs.locale('de')
    } else {
        dayjs.locale('en')
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="form-group has-icon">
                <DatePicker
                    id={field.name}
                    value={field.value && field.value !== null && field.value !== '' ? dayjs().year(field.value) : null}
                    onChange={(val) => setFieldValue(field.name, dayjs(val).$y)}
                    format="YYYY"
                    inputFormat="YYYY"
                    className={`form-control ${props.disabled ? "disabledField" : ""}`}
                    helperText={meta.touched && meta.error ? meta.error : ""}
                    error={meta.touched && Boolean(meta.error)}
                    data-testid={props.name}
                    views={['year']}
                    openTo="year"
                    slotProps={{
                        inputAdornment: {
                            position: 'start',
                        },
                    }}
                    {...props}
                />
            </div>
        </LocalizationProvider>
    );
}

//https://reactdatepicker.com/
export function OmisDatePicker({ ...props }) {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    if (localSessionLang?.includes("de")) {
        dayjs.locale('de')
    } else {
        dayjs.locale('en')
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localSessionLang?.includes("de") ? "de" : "en"}>
			<div className="form-group has-icon">
				<DatePicker
                    value={field.value && field.value !== null && field.value !== '' ? dayjs(field.value) : null}
                    onChange={(val) => setFieldValue(field.name, dayjs(val).$d)}
                    format={localSessionLang?.includes("de") ? "DD.MM.YYYY" :"DD/MM/YYYY"}
                    inputFormat={localSessionLang?.includes("de") ? "DD.MM.YYYY" : "DD/MM/YYYY"}
				    displayWeekNumber
                    localeText={localSessionLang?.includes("de") ? germanLocale : englishLocale}
                    slotProps={{
                        textField: {
                            className: "myDatePicker",
                            inputProps: { // not InputProps
                                readOnly: props.disableTyping,
                            }
                        },
                        inputAdornment: {
                            position: 'start',
                        },
                    }}
                    {...props}
				/>
        	</div>
    	</LocalizationProvider>
    );
}

export function OmisDateTimePicker({ ...props }) {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    if (localSessionLang?.includes("de")) {
        dayjs.locale('de')
    } else {
        dayjs.locale('en')
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localSessionLang?.includes("de") ? "de":"en"}>
			<div className="form-group has-icon">
                <DateTimePicker
                    value={field.value && field.value !== null && field.value !== '' ? dayjs(field.value) : null}
                    onChange={(val) => setFieldValue(field.name, dayjs(val).$d)}
                    format={localSessionLang?.includes("de") ? "DD.MM.YYYY HH:mm" : "DD/MM/YYYY HH:mm"}
                    inputFormat={localSessionLang?.includes("de") ? "DD.MM.YYYY HH:mm" : "DD/MM/YYYY HH:mm"}
                    displayWeekNumber={true}
                    localeText={localSessionLang?.includes("de") ? germanLocale : englishLocale}
                    slotProps={{
                        inputAdornment: {
                            position: 'start',
                        },
                    }}
                    {...props}
				/>
			</div>
		</LocalizationProvider>
    );
}

export function OmisDateTimePickerNoForm({ ...props }) {
    /*registerLocale("de", de);
    return (
        <div className="form-group has-icon">
            {props.label ? <OmisLabel label={props.label} {...props}></OmisLabel> : ""}
            <span className="form-control-feedback"><Icon path={mdiCalendarMonth} size={1} /></span>
            <DatePicker
                {...props}
                selected={(props.selectedvalue && new Date(props.selectedvalue)) || null}
                onChange={props.onChange}
                className={"form-control"}
                dateFormat={"dd.MM.yyyy HH:mm"}
                locale="de"
                highlightDates={[new Date()]}
                todayButton={"Heute"}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                showTimeInput
                timeInputLabel="Uhrzeit:"
                autoComplete='off'
                showWeekNumbers
            />
        </div>
    );*/
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={localSessionLang?.includes("de") ? "de":"en"}>
			<div className="form-group has-icon">
				{props.label ? <OmisLabel label={props.label} {...props}></OmisLabel> : ""}
				<DateTimePicker
				//label="Select Date"
                value={props.selectedvalue || props.selectedvalue !== '' ? props.selectedvalue : null}
  				onChange={props.onChange}
                format={localSessionLang?.includes("de") ? "DD.MM.YYYY HH:mm" : "DD/MM/YYYY HH:mm"}
                inputFormat={localSessionLang?.includes("de") ? "DD.MM.YYYY HH:mm" : "DD/MM/YYYY HH:mm"}
				displayWeekNumber
                localeText={localSessionLang?.includes("de") ? germanLocale :englishLocale}
                slotProps={{
                    inputAdornment: {
                        position: 'start',
                    },
                }}
                {...props}
				/>
			</div>
		</LocalizationProvider>
    );
}

export function OmisDateTimePickerForFilter({ ...props }) {
    /*registerLocale("de", de);
    return (
        <div className="form-group has-icon">
            <span className="form-control-feedback"><Icon path={mdiCalendarMonth} size={1} /></span>
            <DatePicker
                {...props}
                selected={(props.selectedvalue && new Date(props.selectedvalue)) || null}
                onChange={props.onChange.bind(this, props.changeType, props.selectedStartDate, props.selectedEndDate, props.setFieldValue)}
                className={"rmdp-mobile form-control datePickerStyle"}
                dateFormat={"dd.MM.yyyy HH:mm"}
                locale="de"
                highlightDates={[new Date()]}
                todayButton={"Heute"}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                showTimeInput
                timeInputLabel="Uhrzeit:"
                autoComplete='off'
                showWeekNumbers
            />
        </div>
    );*/
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={localSessionLang?.includes("de") ? "de":"en"}>
        <div className="form-group has-icon">
                <DateTimePicker
                    //label="Select Date"
                    value={props.selectedvalue || props.selectedvalue !== '' ? props.selectedvalue : null}
                    onChange={props.onChange.bind(this, props.changeType, props.selectedStartDate, props.selectedEndDate, props.setFieldValue)}
                    format={localSessionLang?.includes("de") ? "DD.MM.YYYY HH:mm" : "DD/MM/YYYY HH:mm"}
                    inputFormat={localSessionLang?.includes("de") ? "DD.MM.YYYY HH:mm" : "DD/MM/YYYY HH:mm"}
                    displayWeekNumber
                    localeText={localSessionLang?.includes("de") ? germanLocale : englishLocale}
                    variant="standard"
                    slotProps={{
                        inputAdornment: {
                            position: 'start',
                        },
                    }}
			/>
        </div>
		</LocalizationProvider>
    );
}


export function OmisTimeFilterBox(props) {
    const [startStr, setStartStr] = useState("start");
    const [endStr, setEndStr] = useState("end");

    return (
        <div className={"omisTimeBox"} id={props.id}>
            <OmisContainer fluid style={{ minWidth:'23em' }} >
                <OmisRow>
                    <OmisCol xs={12}>
                        <h6>{t("From")}</h6>
                    </OmisCol>
                    <OmisCol xs={11}>
                        <OmisDateTimePickerForFilter id={`${props.id}_fromDate`} changeType={startStr} selectedvalue={props.selectedStartDate} setFieldValue={props.setStartDate}
                            onChange={(dateType, startDate, endDate, callbackFunction, e) => {
                                return props.onChange.bind(e, dateType, startDate, endDate, callbackFunction);
                            }} {...props} />
                    </OmisCol>
                </OmisRow>
                <OmisRow >
                    <OmisCol xs={12}>
                        <h6>{t("To")}</h6>
                    </OmisCol>
                    <OmisCol xs={11}>
                        <OmisDateTimePickerForFilter id={`${props.id}_endDate`} changeType={endStr} selectedvalue={props.selectedEndDate} setFieldValue={props.setEndDate}
                            onChange={(dateType, startDate, endDate, callbackFunction, e) => {
                                return props.onChange.bind(e, dateType, startDate, endDate, callbackFunction);
                            }} {...props} />
                    </OmisCol>
                </OmisRow>
                <OmisStack direction="horizontal" gap={2 }>
                    <OmisCol xs={5}>
                        <OmisButtonPrimarySubmit id={`${props.id}_applyBtn`} onClick={props.applyClicked.bind(this, 'applyClick', props.columnField, props.filterID,
                            props.selectedStartDate, props.selectedEndDate, props.setStartDate, props.setEndDate)} text={t("ApplySelection")} />
                    </OmisCol>
                    <OmisCol xs={5}>
                        <OmisButtonSecondary id={`${props.id}_resetBtn`} onClick={props.applyClicked.bind(this, 'resetClick', props.columnField, props.filterID,
                            props.selectedStartDate, props.selectedEndDate, props.setStartDate, props.setEndDate)} text={t("Selection_Clear")} />
                    </OmisCol>
                </OmisStack>
            </OmisContainer>
        </div>
    );
}

export function OmisTimePicker({ ...props }) {
    /*registerLocale("de", de);
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
        <div className="form-group has-icon">
            <span className="form-control-feedback"><Icon path={mdiClockOutline} size={1}/></span>
            <DatePicker
                {...field}
                {...props}
                selected={(field.value && new Date(field.value)) || null}
                onChange={val => {setFieldValue(field.name, val);}}
                className={"form-control"}
                dateFormat={"HH:mm"}
                locale="de"
                showTimeSelectOnly
                showTimeInput
                timeInputLabel="Uhrzeit:"
                showWeekNumbers
            />
        </div>
    );*/
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props);

    if (localSessionLang?.includes("de")) {
        dayjs.locale('de')
    } else {
        dayjs.locale('en')
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localSessionLang?.includes("de") ? "de" : "en"}>
        <div className="form-group has-icon">
            <TimePicker
				label="Uhrzeit:"
                value={field.value ? dayjs(field.value) : null}
                onChange={(val) => setFieldValue(field.name, dayjs(val).$d)}
				format="HH:mm"
				inputFormat="HH:mm"
                slotProps={{
                    inputAdornment: {
                        position: 'start',
                    },
                }}
                localeText={localSessionLang?.includes("de") ? germanLocale : englishLocale}
                    slotProps={{ textField: { helperText: dayjs(field.value).isValid && meta.error ? meta.error : "", error: dayjs(field.value).isValid && Boolean(meta.error)  } }}
                {...props}
			/>
        </div>
		</LocalizationProvider>
    );
}

export function OmisTimePickerNoForm(props) {

    if (localSessionLang?.includes("de")) {
        dayjs.locale('de')
    } else {
        dayjs.locale('en')
    }

    function handleOnChange(e) {
        if (props.onChange) {
            const [h, m] = e.target.value.split(":");
            var newValue = dayjs().hour(parseInt(h)).minute(parseInt(m));
            if (newValue.toString() !== "Invalid Date") {
                props.onChange(newValue.$d);
            } else {
                props.onChange(null);
            }
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localSessionLang?.includes("de") ? "de" : "en"}>
            <div className="form-group has-icon"
                onKeyDown={props.onKeyDown}
                id={props.id}>
                <TimePicker
                    label="Uhrzeit:"
                    inputRef={props.inputRef}
                    value={props.value ? dayjs(props.value) : null}
                    onAccept={(val) => {
                        if (props.onAccept) {
                            props.onAccept(dayjs(val).$d);
                        }
                    }}
                    //onChange={(val) => {
                    //    if (props.onChange) {
                    //        props.onChange(dayjs(val).$d);
                    //    }
                    //}}
                    slotProps={{
                        textField: {
                            className:"myDatePicker" ,
                            inputProps: { // not InputProps
                                readOnly: props.disableTyping,
                                onBlur: handleOnChange,
                            }
                        },
                        inputAdornment: {
                            position: 'start',
                        },
                    }}
                    format="HH:mm"
                    inputFormat="HH:mm"
                    localeText={localSessionLang?.includes("de") ? germanLocale : englishLocale}
                    sx={{ height: '3em' }}
                    //{...props}
                />
            </div>
            {
                props.errorMessage ?
                    <OmisErrorMessage text={props.errorMessage} />
                    :
                    null
            }
        </LocalizationProvider>
    );
}

export function OmisDateRangePicker(props) {


    if (localSessionLang?.includes("de")) {
        dayjs.locale('de')
    } else {
        dayjs.locale('en')
    }

    const [value, setValue] = useState([
        props.fromDate ? dayjs(props.fromDate) : null,
        props.toDate ? dayjs(props.toDate) : null
    ]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={localSessionLang?.includes("de") ? "de" : "en"}>
            <div className="form-group has-icon">
                <DateRangePicker
                    defaultValue={value}
                    onChange={val => {
                        props.onChange({
                            fromDate: val[0] ? dayjs(val[0]).$d : val[0],
                            toDate: val[1] ? dayjs(val[1]).$d : val[1],
                        });
                    }}
                    format={localSessionLang?.includes("de") ? "DD.MM.YYYY" : "DD/MM/YYYY"}
                    inputFormat={localSessionLang?.includes("de") ? "DD.MM.YYYY" : "DD/MM/YYYY"}
                    localeText={localSessionLang?.includes("de") ? germanLocale : englishLocale}
                    slotProps={{
                        textField: {
                            required: props.required && !props.disabled,
                            opacity: props.disabled ? "0.5" : "",
                            size: 'small',
                        },
                        inputAdornment: {
                            position: 'start',
                        },
                    }}                    
                //{...props}
                />                
            </div>
        </LocalizationProvider>
    );
}

export function OmisDateRangePickerDayAndMonth(props) {

    if (localSessionLang?.includes("de")) {
        dayjs.locale('de')
    } else {
        dayjs.locale('en')
    }

    const [values, setValues] = useState(props.value ?? [null,null]);

    function onChange(isBegin, date) {
        var tmp = [...values];

        if (isBegin) {
            tmp[0] = date;
        }
        else {
            tmp[1] = date;
        }

        if (props.onChange) {
            props.onChange({
                fromDate: tmp[0] ? dayjs(tmp[0]).$d : tmp[0],
                toDate: tmp[1] ? dayjs(tmp[1]).$d : tmp[1],
            });
        }
        setValues(tmp);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localSessionLang?.includes("de") ? "de" : "en"}>
            <div className="form-group has-icon">
                <OmisRow>
                    <OmisCol xs={5}>
                        <OmisLabel label={t("BeginDate")} />
                        <DatePicker
                            views={['month', 'day']}
                            value={values.length > 0 ? dayjs(values[0]) : null}
                            onChange={(val) => onChange(true, dayjs(val).$d)}
                            format={"DD/MM"}
                            inputFormat={"DD/MM"}
                            displayWeekNumber={true}
                            maxDate={values[1] && values[1] != null ? dayjs(values[1]) : null}
                            localeText={localSessionLang?.includes("de") ? germanLocale : englishLocale}
                            slotProps={{
                                inputAdornment: {
                                    position: 'start',
                                },
                            }}
                        />
                    </OmisCol>
                    <OmisCol xs={2} style={{ display: 'flex', justifyContent:'center', alignItems: 'center' }}>-</OmisCol>
                    <OmisCol xs={5}>
                        <OmisLabel label={t("EndDate")} />
                        <DatePicker
                            views={['month', 'day']}
                            value={values.length > 1 ? dayjs(values[1]) : null}
                            onChange={(val) => onChange(false, dayjs(val).$d)}
                            format={"DD/MM"}
                            inputFormat={"DD/MM"}
                            displayWeekNumber={true}
                            minDate={values[0] && values[0] != null ? dayjs(values[0]) : null}
                            localeText={localSessionLang?.includes("de") ? germanLocale : englishLocale}
                            slotProps={{
                                inputAdornment: {
                                    position: 'start',
                                },
                            }}
                        />
                    </OmisCol>
                </OmisRow>
            </div>
        </LocalizationProvider>
    );
}

export function OmisSearchBar(props) {

    const [searchTerm, setSearchTerm] = useState("");
    const [listItems, setListItems] = useState([]);
    const [searchResultItems, setSearchResultItems] = useState([]);
    const [listAttr, setListAttr] = useState([]);

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };


    useEffect(() => {
        if (props.listItems) {
            setListItems(props.listItems);
        }
    }, [props.listItems]);

    useEffect(() => {
        if (props.listAttr) {
            setListAttr(props.listAttr);
        }
    }, [props.listAttr]);

    const filterArrayBySearchQuery = (array, searchQuery, attributes) => {
        return array.filter((item) => {
            // Concatenate all attributes into a single string to perform search
            var searchString = "";
            if (attributes && attributes.length > 0) {
                searchString = attributes
                    .map((attribute) => item[attribute])
                    .join(" ")
                    .toLowerCase();
            } else {
                searchString = item
                    .join(" ")
                    .toLowerCase();
            }
            return searchString.includes(searchQuery.toLowerCase());
        });
    };

    useEffect(() => {
        //TODO : this search term logic can improve for more generic searches
        setSearchResultItems(filterArrayBySearchQuery(listItems, searchTerm, listAttr));

        if (props.getSearchTerm && searchTerm != undefined) {
            props.getSearchTerm(searchTerm);
        }
    }, [searchTerm]);

    useEffect(() => {

        if (props.getSearchResultItems && searchResultItems !== undefined) {
            if (searchTerm !== "") {
                props.getSearchResultItems(searchResultItems);
            } else {
                props.getSearchResultItems(listItems);
            }
        }
    }, [searchResultItems]);

    function handleClearClick() {
        setSearchTerm("");
    }

    return ( 

        <Search className="omisSearchInputLong" sx={{ marginLeft:'0em !important' }}>
            <Grid container justifyContent="space-between" alignItems="flex-start" >
                <Grid item xs={2} sm={4} md={6} lg={8} xl={10}>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search"
                        inputProps={{ 'aria-label': 'search' }}
                        value={searchTerm}
                        onChange={handleChange}
                        onKeyDown={(e) => e.stopPropagation()}
                    />
                </Grid>
                {
                    searchTerm && searchTerm !== '' ?
                        <Grid item xs={10} sm={8} md={6} lg={4} xl={2}sx={{marginLeft: '0em !important'}}>
                            <OmisIconButton id={"searchButton"} sx={{marginLeft: '0em !important'}} iconcontent={<ClearIcon />} onClick={handleClearClick}></OmisIconButton>
                        </Grid>
                        : null
                }
            </Grid>
        </Search>
    );
}

export function OmisInputSlider(props) {

    const [color, setColor] = useState("primary");

    useEffect(() => {
        if (props.changecolorbyval) {
            if (props.value < 30) {
                setColor("error");
            } else if (props.value < 60) {
                setColor("warning");
            } else if (props.value < 101) {
                setColor("success");
            } else {
                setColor("error");
            }
        }
    }, [props.value]);

    return (
        <Slider size="small" color={props.changecolorbyval ? color : props.color} {...props} />
    );
}
