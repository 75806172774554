import React, { useEffect, useState } from "react";
import "./OmisStepper.css"
import { Grid } from "@mui/material";


function OmisStepper(props) {

    const [steps, setSteps] = useState([]);
    const [activeStep, setActiveStep] = useState();

    useEffect(() => {
        if (props.steps && props.steps.length > 0) {
            setSteps(props.steps);
            setActiveStep(props.steps[0]);
        }
    }, [props.steps]);

    const handleNext = () => {
        if (steps[steps.length - 1].key === activeStep.key) {
            alert('You have completed all steps.');
            return;
        }

        const index = steps.findIndex(x => x.key === activeStep.key);
        setSteps(prevStep => prevStep.map(x => {
            if (x.key === activeStep.key) x.isDone = true;
            return x;
        }))
        setActiveStep(steps[index + 1]);
    }

    const handleBack = () => {
        const index = steps.findIndex(x => x.key === activeStep.key);
        if (index === 0) return;

        setSteps(prevStep => prevStep.map(x => {
            if (x.key === activeStep.key) x.isDone = false;
            return x;
        }))
        setActiveStep(steps[index - 1]);
    }

    return (
        <div className="box">
            <div className="steps">
                <ul className="nav">
                    {steps.map((step, i) => {
                        return (
                            <div key={i+"_Div"} >
                                <li key={i} className={`${step.isDone ? 'done' : ''} ${step.isGray ? 'gray' : ''}`}>
                                    {
                                        step.label ?
                                            <div>{step.label}</div>
                                            :
                                            null
                                    }
                                </li>
                                {

                                    step.customLabels ?
                                        <Grid className="centeredDiv">
                                            {step.customLabels}
                                        </Grid>
                                        :
                                        null
                                }
                            </div>
                        )
                    })}
                </ul>
            </div>
            {
                activeStep && activeStep !== undefined && activeStep.component ?
                    <div className="step-component">
                        activeStep.component
                    </div>
                    :
                    null
            }
            {
                props.showButtons ?
                    <div className="btn-component">
                        <input type="button" value="Back" onClick={handleBack} disabled={steps && steps !== undefined && steps.length > 0 && steps[0].key === activeStep.key} />
                        <input type="button" value={steps && steps !== undefined && steps.length > 0 && steps[steps.length - 1].key !== activeStep.key ? 'Next' : 'Submit'} onClick={handleNext} />
                    </div>
                    :
                    null
            }
        </div>
    );
}

export default OmisStepper;